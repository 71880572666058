(function() {
    'use strict';

    angular
        .module('tvl.campaign')
        .controller('PlacementFinancialReportsModalController', PlacementFinancialReportsModalController);

    PlacementFinancialReportsModalController.$inject = [
        '$scope',
        '$uibModalInstance',
        'DTOptionsBuilder',
        'reports',
        'isUserAccountant',
        'isUserTrader',
        'isUserSupport',
        'currency',
        'tvlConstantUtils',
        'tvlAdVideoUtils',
    ];

    /* @ngInject */
    function PlacementFinancialReportsModalController(
        $scope,
        $uibModalInstance,
        DTOptionsBuilder,
        reports,
        isUserAccountant,
        isUserTrader,
        isUserSupport,
        currency,
        tvlConstantUtils,
        tvlAdVideoUtils
    ) {

        var vm = this;
        vm.placementFinancialReports = reports;
        vm.ui = {
            isUserAccountant: isUserAccountant,
            isUserTrader: isUserTrader,
            isUserSupport: isUserSupport
        };
        vm.currency = currency;
        vm.tvlConstantUtils = tvlConstantUtils;
        vm.tvlAdVideoUtils = tvlAdVideoUtils;

        function activate() {
            vm.dtOptions = DTOptionsBuilder.newOptions()
                .withOption('scrollY', true)
                .withOption('scrollX', true)
                .withOption('scrollCollapse', true)
                .withOption('paging', false)
                .withOption('bFilter', false)
                .withOption('bInfo', false)
                .withFixedColumns({
                    leftColumns: 2
                })
                .withBootstrap();
        }

        function cancel() {
            return $uibModalInstance.dismiss();
        }

        ////////////////

        vm.cancel = cancel;

        activate();
    }
}());
