(function() {
    'use strict';

    angular
        .module('tvl.common')
        .factory('UserRolesChecker', UserRolesChecker);

    UserRolesChecker.$inject = [];

    /**
     * This service is used to check the roles of a specific user.
     *
     * @ngInject
     */
    function UserRolesChecker() {

        const isClient = function(user) {
            return userHasRole(user, 'ROLE_CLIENT');
        };

        const isAdmin = function (user) {
            return userHasRole(user, 'ROLE_ADMIN');
        };

        const isTrader = function (user) {
            return userHasRole(user, 'ROLE_TRADING_OPS');
        };

        const isSupport = function (user) {
            return userHasRole(user, 'ROLE_SUPPORT');
        };

        const isCompanyManager = function (user) {
            return userHasRole(user, 'ROLE_MANAGER');
        };

        function userHasRole(user, role) {
            const roles = _.isNil(user) ? [] : user.roles;

            return _.includes(roles, role);
        }

        return {
            isClient: isClient,
            isAdmin: isAdmin,
            isTrader: isTrader,
            isSupport: isSupport,
            isCompanyManager: isCompanyManager,
        };
    }
})();
