(function() {
    'use strict';

    angular
        .module('tvl.campaign')
        .controller('CampaignSettingsModalController', CampaignSettingsModalController);

    CampaignSettingsModalController.$inject = [
        '$scope',
        '$uibModalInstance',
        'tvlCampaignUtils',
        'campaign',
        'tvlUtils',
        'user'
    ];

    /* @ngInject */
    function CampaignSettingsModalController(
        $scope,
        $uibModalInstance,
        tvlCampaignUtils,
        campaign,
        tvlUtils,
        user
    ) {
        var vm = this;
        vm.campaign = campaign;
        vm.displayCurrency = vm.campaign.displayCurrency;
        vm.dailyBudgetLimit = 0.01;
        vm.inverseAdwordsExchangeRate = vm.campaign.adwordsExchangeRate ? 1 / vm.campaign.adwordsExchangeRate : 0;
        vm.inverseFacebookExchangeRate = vm.campaign.facebookExchangeRate ? 1 / vm.campaign.facebookExchangeRate : 0;
        vm.biddingCurrencyFractionDigits = vm.campaign.adwordsBiddingCurrencyFractionDigits; // only for multi ad groups campaign => adwords
        vm.step = parseFloat("0.01").toFixed(vm.biddingCurrencyFractionDigits);
        vm.tvlCampaignUtils = tvlCampaignUtils;
        vm.isCpaCampaign = false;
        vm.customerAccountId = vm.campaign.customerAccount.id;
        vm.hasAccountPermission = hasAccountPermission;
        vm.user = user;
        vm.campaignGoal = {};

        $scope.campaignGoals = calculateGoals();

        /**
         * Callback for inverse Adwords exchange rate changes.
         */
        function oninverseAdwordsExchangeRateChange(newVal, oldVal) {
            if (newVal === oldVal) {
                return;
            }
            vm.campaign.adwordsExchangeRate = tvlUtils.roundNumber(1 / vm.inverseAdwordsExchangeRate, 8);
        }

        function getDailyBudgetLimit() {

            let totalDailyBudgetFromPlacements = 0;

            if (null === vm.campaign.dailyBudgetLimit) {
                _.forEach( vm.campaign.placements, function(placement) {
                    totalDailyBudgetFromPlacements += placement.dailyBudgetLimit;
                });
                vm.dailyBudgetLimit = totalDailyBudgetFromPlacements;
            } else {
                vm.dailyBudgetLimit = vm.campaign.dailyBudgetLimit;
            }

        }

        /**
         * Callback for inverse Facebook exchange rate changes.
         */
        function oninverseFacebookExchangeRateChange(newVal, oldVal) {
            if (newVal === oldVal) {
                return;
            }
            vm.campaign.facebookExchangeRate = tvlUtils.roundNumber(1 / vm.inverseFacebookExchangeRate, 8);
        }

        /**
         * Callback for the $destroy event.
         */
        function onDestroy() {
            vm.unregisterInverseAdwordsExchangeRateWatcher();
            vm.unregisterInverseFacebookExchangeRateWatcher();
        }

        function activate() {
            vm.unregisterInverseAdwordsExchangeRateWatcher = $scope.$watch(
                'vm.inverseAdwordsExchangeRate',
                oninverseAdwordsExchangeRateChange
            );
            vm.unregisterInverseFacebookExchangeRateWatcher = $scope.$watch(
                'vm.inverseFacebookExchangeRate',
                oninverseFacebookExchangeRateChange
            );
            $scope.$on('$destroy', onDestroy);

            isCpaCampaign();

            getDailyBudgetLimit();
        }

        function isCpaCampaign() {
            for (var i = 0; i < vm.campaign.placements.length; i++) {
                var ad = vm.campaign.placements[i].adGroups[0].ads[0];

                if (ad.isCpa) {
                    vm.isCpaCampaign = true;
                    return;
                }
            }
        }

        function calculateGoals() {
            // the fixedAmount input should appear only on rebate campaigns
            if (!isRebateCampaign()) {
                return {};
            }

            vm.campaign.placements.forEach(function(currentValue, index, arr) {
                let fixedCpa = currentValue.fixedCpa;
                let fixedCpc = currentValue.fixedCpc;
                let fixedCpm = currentValue.fixedCpm;
                let fixedCpv = currentValue.fixedCpv;

                if (fixedCpa !== null && !vm.campaignGoal.hasOwnProperty('cpa')) {
                    vm.campaignGoal.cpa = fixedCpa;
                    vm.fixedAmount = fixedCpa;
                }

                if (fixedCpc !== null && !vm.campaignGoal.hasOwnProperty('cpc')) {
                    vm.campaignGoal.cpc = fixedCpc;
                    vm.fixedAmount = fixedCpc;
                }

                if (fixedCpm !== null && !vm.campaignGoal.hasOwnProperty('cpm')) {
                    vm.campaignGoal.cpm = fixedCpm;
                    vm.fixedAmount = fixedCpm;
                }

                if (fixedCpv !== null && !vm.campaignGoal.hasOwnProperty('cpv')) {
                    vm.campaignGoal.cpv = fixedCpv;
                    vm.fixedAmount = fixedCpv;
                }

            });

            return vm.campaignGoal;
        }

        function isRebateCampaign() {
            return vm.campaign.rebate !== null;
        }

        function isMultiAdrgroupCampaign() {
            return vm.campaign.isImported;
        }

        function cancel() {
            return $uibModalInstance.dismiss();
        }

        function update() {
            var result = {};

            if (vm.hasAccountPermission('Campaign.update')) {
                result.name = vm.campaign.name;
                result.simulated = vm.campaign.simulated;
                result.automanaged = vm.campaign.automanaged;
                result.dailyBudgetLimit = vm.dailyBudgetLimit;

                // only update multiAdgroupFixedAmount on rebate campaigns
                if (isRebateCampaign() && isMultiAdrgroupCampaign()) {
                    result.multiAdgroupFixedAmount = vm.fixedAmount;
                }
            }

            $uibModalInstance.close(result);
        }

        function hasAccountPermission(permission) {
            return vm.user.hasAccountPermission(vm.customerAccountId, permission);
        }

        ////////////////

        vm.cancel = cancel;
        vm.update = update;

        activate();
    }
}());
