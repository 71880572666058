require('./fonts/Planner.ttf');
require('../node_modules/font-awesome/css/font-awesome.min.css');
require('../node_modules/bootstrap/dist/css/bootstrap.min.css');
require('../node_modules/themify-icons/css/themify-icons.css');
require('./css/icons.css');

// <!-- start: MAIN CSS -->
require('../node_modules/animate.css/animate.min.css');
require('../node_modules/perfect-scrollbar/min/perfect-scrollbar.min.css');
require('../node_modules/switchery/dist/switchery.min.css');
require('../node_modules/datatables.net-dt/css/jquery.dataTables.min.css');
require('../node_modules/datatables.net-fixedcolumns-dt/css/fixedColumns.dataTables.min.css');
require('../node_modules/angular-datatables/dist/css/angular-datatables.min.css');
require('../node_modules/angular-datatables/dist/plugins/bootstrap/datatables.bootstrap.min.css');
// <!-- end: MAIN CSS -->

// <!-- start: Theme CSS -->
require('./css/plugins.css');
require('./css/themes/theme-1.css');
require('./css/styles.css');
require('./css/theviralab.css');
require('./css/notifications.css');
// <!-- end: Theme CSS -->

require('../node_modules/flexslider-customized/flexslider.css');
require('../node_modules/angular-datepicker/dist/angular-datepicker.min.css');
require('../node_modules/sweetalert/dist/sweetalert.css');
require('../node_modules/twemoji-awesome/dist/twemoji-awesome.min.css');
require('../node_modules/angular-ui-select/dist/select.min.css');
require('../node_modules/bootstrap-touchspin/dist/jquery.bootstrap-touchspin.min.css');
require('../node_modules/angular-toastr/dist/angular-toastr.min.css');
require('../node_modules/angular-loading-bar/build/loading-bar.min.css');

require('../node_modules/flexslider-customized/flexslider.css');
require('../node_modules/angular-datepicker/dist/angular-datepicker.min.css');
require('../node_modules/sweetalert/dist/sweetalert.css');
require('../node_modules/twemoji-awesome/dist/twemoji-awesome.min.css');
require('../node_modules/angular-ui-select/dist/select.min.css');
require('../node_modules/bootstrap-touchspin/dist/jquery.bootstrap-touchspin.min.css');
require('../node_modules/angular-toastr/dist/angular-toastr.min.css');
require('../node_modules/angular-loading-bar/build/loading-bar.min.css');

require('../node_modules/flexslider-customized/flexslider.css');
require('../node_modules/angular-datepicker/dist/angular-datepicker.min.css');
require('../node_modules/sweetalert/dist/sweetalert.css');
require('../node_modules/twemoji-awesome/dist/twemoji-awesome.min.css');
require('../node_modules/angular-ui-select/dist/select.min.css');
require('../node_modules/bootstrap-touchspin/dist/jquery.bootstrap-touchspin.min.css');

import { Video } from 'video-metadata-thumbnails';

// {# LoginApp css #}
require('../node_modules/angular-toastr/dist/angular-toastr.min.css');
require('../node_modules/angular-loading-bar/build/loading-bar.min.css');

// {# campaigns css #}
require('../node_modules/flag-icon-css/css/flag-icon.min.css');
require('../node_modules/angular-ui-select/dist/select.min.css');
require('../node_modules/angular-toastr/dist/angular-toastr.min.css');
require('../node_modules/angular-loading-bar/build/loading-bar.min.css');
require('../node_modules/angular-xeditable/dist/css/xeditable.min.css');
require('../node_modules/angularjs-slider/dist/rzslider.min.css');
require('../node_modules/jqcloud2/dist/jqcloud.min.css');
require('../node_modules/bootstrap-daterangepicker/daterangepicker.css');
require('../node_modules/weather-icons/css/weather-icons.min.css');
require('./css/campaigns.css');
require('./css/wizardCampaign.css');
require('./css/listCampaign.css');
require('./css/weather-calendar.css');

// {# user css #}
require('../node_modules/angular-toastr/dist/angular-toastr.min.css');
require('../node_modules/angular-loading-bar/build/loading-bar.min.css');

// {# admin css #}
require('../node_modules/flag-icon-css/css/flag-icon.min.css');
require('../node_modules/angular-ui-select/dist/select.min.css');
require('../node_modules/angular-toastr/dist/angular-toastr.min.css');
require('../node_modules/angular-loading-bar/build/loading-bar.min.css');
require('../node_modules/angular-xeditable/dist/css/xeditable.min.css');
require('../node_modules/ng-table-bundle/ng-table.min.css');
require('./css/exchange-rates.css');
require('./css/channel-groups.css');
require('./css/admin.css');

// {# control-center css #}
require('../node_modules/angular-toastr/dist/angular-toastr.min.css');
require('../node_modules/angular-loading-bar/build/loading-bar.min.css');
require('./css/control-center-issues.css');

// {# brand-safety css #}
require('../node_modules/flag-icon-css/css/flag-icon.min.css');
require('../node_modules/angular-ui-select/dist/select.min.css');
require('../node_modules/angular-toastr/dist/angular-toastr.min.css');
require('../node_modules/angular-loading-bar/build/loading-bar.min.css');
require('../node_modules/angularjs-slider/dist/rzslider.min.css');
require('../node_modules/bootstrap-daterangepicker/daterangepicker.css');
require('../node_modules/ng-table-bundle/ng-table.min.css');
require('./css/brand-safety.css');

// {# competitive analysis css #}
require('../node_modules/angular-toastr/dist/angular-toastr.min.css');
require('../node_modules/angular-loading-bar/build/loading-bar.min.css');
require('../node_modules/bootstrap-daterangepicker/daterangepicker.css');
require('./css/competitives.css');

// <!-- TODO mirar estos scripts -->
// <!-- start: MAIN JAVASCRIPTS -->
// <!--    <script src="{{ asset('bundles/fosjsrouting/js/router.js') }}"></script>-->
//     <!--    <script src="{{ path('fos_js_routing_js', {'callback': 'fos.Router.setData'}) }}"></script>-->
require('../node_modules/jquery/dist/jquery.js');
require('../node_modules/angular/angular.js');
require('../node_modules/bootstrap/dist/js/bootstrap.js');
require('../node_modules/jquery.cookie/jquery.cookie.js');
require('../node_modules/datatables.net/js/jquery.dataTables.js');
require('../node_modules/datatables.net-fixedcolumns/js/dataTables.fixedColumns.js');
require('../node_modules/angular-datatables/dist/angular-datatables.js');
require('../node_modules/angular-datatables/dist/plugins/fixedcolumns/angular-datatables.fixedcolumns.js');
require('../node_modules/angular-datatables/dist/plugins/bootstrap/angular-datatables.bootstrap.js');
require('../node_modules/perfect-scrollbar/src/perfect-scrollbar.js');
window.Switchery = require('../node_modules/switchery/dist/switchery.js');
require('../node_modules/ng-switchery/src/ng-switchery.js');
require('../node_modules/angular-ui-router/release/angular-ui-router.js');
require('../node_modules/angular-local-storage/dist/angular-local-storage.js');
require('../node_modules/angular-resource/angular-resource.js');
require('../node_modules/angular-animate/angular-animate.js');
require('../node_modules/angular-sanitize/angular-sanitize.js');
require('../node_modules/angular-messages/angular-messages.js');


//TODO only import if prod?
import {init as SentryInit} from '@sentry/browser';
import {Angular as SentryAngularIntegration} from '@sentry/integrations';

// SENTRY
if (process.env.APP_ENV === 'prod') {
    // <!-- SENTRY ERROR COLLECTOR -->
    SentryInit({
        dsn: SENTRY_FRONTEND_DSN,
        environment: SENTRY_ENVIRONMENT,
        release: SENTRY_RELEASE,
        integrations: [
            new SentryAngularIntegration(),
        ],
    });
}

//HOTJAR
if (process.env.APP_ENV === 'prod') {
    (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid: HOTJAR_SITE_ID,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
}

require('../node_modules/angular-resource/angular-resource.js');
require('../node_modules/lodash/dist/lodash.js');
require('../node_modules/moment/src/moment.js');
require('../node_modules/angular-moment/angular-moment.js');
require('../node_modules/angular-file-saver/dist/angular-file-saver.bundle.js');
require('../node_modules/angular-sanitize/angular-sanitize.js');
require('../node_modules/angular-youtube-api-factory/dist/angular-youtube-api-factory.js');
require('../node_modules/angular-bootstrap/ui-bootstrap-tpls.js');
require('../node_modules/sweetalert/dev/sweetalert.es6.js');
require('../node_modules/angular-sweetalert/SweetAlert.js');
require('../node_modules/angular-toastr/dist/angular-toastr.tpls.js');
require('../node_modules/angular-loading-bar/build/loading-bar.js');
require('../node_modules/pusher-js/dist/web/pusher.js');
require('../node_modules/pusher-angular/lib/pusher-angular.js');
require('./js/filterNumber.js');
require('./app/common/common.module.js');
require('./templatesCache.js');
require('./app/common/user-permissions-checker.service.js');
require('./app/common/user-roles-checker.service.js');
require('./app/common/resources.factory.js');
require('./app/common/jwt.service.js');
require('./app/common/report-downloader.service.js');
require('./app/common/session.service.js');
require('./app/common/auth-interceptor.service.js');
require('./app/common/resource-response.service.js');
require('./app/common/authorization.service.js');
require('./app/common/csv-exporter.service.js');

// {# LoginApp javascripts #}
require('../node_modules/angular-toastr/dist/angular-toastr.tpls.js');
require('../node_modules/angular-loading-bar/build/loading-bar.js');
require('./app/user.module.js');
require('./app/login.module.js');
require('./app/login/login.controller.js');
require('./app/login/reset-password.controller.js');
require('./app/login/resetting.controller.js');

// {# Base javascript #}
require('../node_modules/lodash/dist/lodash.js');
require('../node_modules/moment/src/moment.js');
require('../node_modules/angular-moment/angular-moment.js');
require('../node_modules/angular-sanitize/angular-sanitize.js');
require('../node_modules/chart.js/dist/Chart.js');
require('../node_modules/angular-chart.js/dist/angular-chart.js');
require('../node_modules/flexslider-customized/jquery.flexslider.js');
require('../node_modules/angular-flexslider/angular-flexslider.js');
require('../node_modules/angular-datepicker/dist/angular-datepicker.js');
require('../node_modules/angular-bootstrap/ui-bootstrap-tpls.js');
require('../node_modules/sweetalert/dev/sweetalert.es6.js');
require('../node_modules/angular-sweetalert/SweetAlert.js');
require('../node_modules/angular-resource/angular-resource.js');
require('../node_modules/highcharts/highstock.js');
require('../node_modules/highcharts/modules/map.js');
require('../node_modules/highcharts/modules/data.js');
require('../node_modules/highcharts/modules/exporting.js');
require('../node_modules/angular-ui-select/dist/select.js');
require('../node_modules/bootstrap-touchspin/dist/jquery.bootstrap-touchspin.js');
require('../node_modules/ng-switchery/src/ng-switchery.js');
require('../node_modules/angular-youtube-api-factory/dist/angular-youtube-api-factory.js');
require('../node_modules/tld-browserified/tld.js');
require('../node_modules/pusher-js/dist/web/pusher.js');
require('../node_modules/pusher-angular/lib/pusher-angular.js');
require('./js/filterNumber.js');
require('./js/propsFilter.js');
require('./js/capitalizeFilter.js');
require('./app/common/common.module.js');
require('./app/common/error-handler.js');
require('./app/common/utils.service.js');
require('./app/common/constant-utils.service.js');
require('./app/common/ad-video-utils.service.js');
require('./app/common/tooltip-utils.service.js');
require('./app/common/google-display-ad-utils.service');
require('./app/common/wizard-data.service');
require('./app/common/wizard-initial-prices.service');
require('./app/common/date-utils.service.js');
require('./app/common/ui.service.js');
require('./app/common/user-permissions-checker.service.js');
require('./app/common/user-roles-checker.service.js');
require('./app/common/jwt.service.js');
require('./app/common/session.service.js');
require('./app/common/spinner.directive.js');
require('./app/common/platform-icon.directive.js');
require('./app/common/is-all-data-zero.filter.js');
require('./app/common/blankslate.directive.js');
require('./app/common/sweetalert.service.js');
require('./app/common/resource-response.service.js');
require('./app/common/announcement.directive.js');
require('./app/common/group-banner.directive.js');
require('./app/common/map.directive.js');
require('./app/common/video-length.directive.js');
require('./app/common/youtube.service.js');
require('./app/common/weather.service.js');
require('./app/common/video-preview.directive.js');
require('./app/common/youtube-video-thumbnail.directive.js');
require('./app/common/model-changes-updater.service.js');
require('./app/common/resources.factory.js');
require('./app/common/searcher.directive.js');
require('./app/common/channel.directive.js');
require('./app/common/video.directive.js');
require('./app/common/auth-interceptor.service.js');
require('./app/common/currencies.service.js');
require('./app/common/itemized-input.directive.js');
require('./app/common/network-name-input.directive.js');
require('./app/common/directives/newsletter-reports-form.directive.js');
require('./app/common/directives/init-form.directive.js');
require('./app/common/main-nav-bar.directive.js');
require('./app/common/nav-header.directive.js');
require('./app/common/countries.service.js');
require('./app/common/authorization.service.js');
require('./app/common/notification/list.directive.js');
import {Main} from './js/assets/main.js';

jQuery(document).ready(function(){
    Main().init();
});

const urlImgYoutubeSadFace = '/static/img/youtube_sad_face.png';

const loadjs = require('loadjs');

if (process.env.APP_ENV === 'prod') {
    var headWayConfig = {
        selector: ".latest-changes-notifications",
        trigger: ".latest-changes-action"
    };

    headWayConfig.account = HEADWAY_CHANGELOG_ACCOUNT;

    // Global const for headway
    global.HW_config = headWayConfig;

    loadjs('//cdn.headwayapp.co/widget.js', 'headway');
    loadjs.ready('headway', function() {});
}

// {# Campaigns javascript #}
require('../node_modules/angular-ui-select/dist/select.js');
require('../node_modules/angular-toastr/dist/angular-toastr.tpls.js');
require('../node_modules/jquery.sparkline.build/dist/jquery.sparkline.js');
require('../node_modules/angular-fcsa-number/src/fcsaNumber.js');
require('../node_modules/angular-loading-bar/build/loading-bar.js');
require('../node_modules/checklist-model/checklist-model.js');
require('../node_modules/angular-xeditable/dist/js/xeditable.js');
require('../node_modules/ng-table-bundle/ng-table.js');
require('../node_modules/angularjs-slider/dist/rzslider.js');
require('../node_modules/jqcloud2/dist/jqcloud.js');
require('../node_modules/angular-jqcloud/angular-jqcloud.js');
require('../node_modules/ng-file-upload/ng-file-upload-all.js');
require('../node_modules/bootstrap-daterangepicker/daterangepicker.js');
require('../node_modules/angular-daterangepicker/js/angular-daterangepicker.js');
require('../node_modules/angular-recursion/angular-recursion.js');
require('../node_modules/angular-drag-and-drop-lists/angular-drag-and-drop-lists.js');
require('./app/common/currency.directive.js');
require('./app/common/min-decimal-input.directive.js');
require('./app/common/file-types-input.directive.js');
require('./app/common/max-file-size-input.directive.js');
require('./app/common/max-image-width-input.directive.js');
require('./app/common/max-image-height-input.directive.js');
require('./app/common/max-date-range-input.directive.js');
require('./app/common/image-sizes-input.directive.js');
require('./app/common/matching-domain-input.directive.js');
require('./app/common/valid-https-input.directive.js');
require('./app/common/changelog.directive.js');
require('./app/common/error-log.directive.js');
require('./app/common/notes.directive.js');
require('./app/common/sparkline.directive.js');
require('./app/common/url-input.directive.js');
require('./app/common/uuid.service.js');
require('./app/common/url.service.js');
require('./app/common/ui.service.js');
require('./app/common/form.service.js');
require('./app/common/authorization.service.js');
require('./app/common/weather-calendar-widget.directive.js');
require('./app/common/weather-calendar.directive.js');
require('./app/common/list-detail-container.directive.js');
require('./app/common/list.directive.js');
require('./app/common/detail.directive.js');
require('./app/common/scroll-detector.directive.js');
require('./app/campaign.module.js');
require('./app/campaign/content-selector.directive.js');
require('./app/campaign/content-selector-list.directive.js');
require('./app/campaign/content-selector-modal.controller.js');
require('./app/campaign/content-selector-modal.service.js');
require('./app/campaign/keywords-selector.directive.js');
require('./app/campaign/keywords-selector-list.directive.js');
require('./app/campaign/keywords-list-preview.directive.js');
require('./app/campaign/textarea-max-lines.directive.js');
require('./app/campaign/keywords-details-modal.controller.js');
require('./app/campaign/keywords-selector-modal.controller.js');
require('./app/campaign/keywords-selector-modal.service.js');
require('./app/campaign/campaign-details.directive.js');
require('./app/campaign/status-badge.directive.js');
require('./app/campaign/increment.directive.js');
require('./app/campaign/campaign-utils.service.js');
require('./app/campaign/campaign-validation.service.js');
require('./app/campaign/operating-system.service.js');
require('./app/campaign/rules.service.js');
require('./app/campaign/rules/rule-condition.directive.js');
require('./app/campaign/rules/rule-condition-selector.directive.js');
require('./app/campaign/powerpoint-settings-modal.controller.js');
require('./app/campaign/hourly-schedule-modal.controller.js');
require('./app/campaign/wizard/advideo-modal.controller.js');
require('./app/campaign/wizard/google-display-image-ad-modal.controller.js');
require('./app/campaign/wizard/directives/fb-carousel-form.directive');
require('./app/campaign/wizard/directives/fb-asset-source-button.directive.js');
require('./app/campaign/wizard/directives/fb-asset-select-input.directive.js');
require('./app/campaign/wizard/directives/fb-asset-preview.directive.js');
require('./app/campaign/wizard/directives/fb-asset-upload-input.directive.js');
require('./app/campaign/list.controller.js');
require('./app/campaign/report.controller.js');
require('./app/campaign/wizard/targeting.controller.js');
require('./app/campaign/wizard/review.controller.js');
require('./app/campaign/wizard/user-access.controller.js');
require('./app/campaign/wizard/schedule.controller.js');
require('./app/campaign/wizard/events.controller.js');
require('./app/campaign/wizard/network-distribution.controller.js');
require('./app/campaign/wizard/media-distribution.controller.js');
require('./app/campaign/wizard/information.controller.js');
require('./app/campaign/wizard/ads.controller.js');
require('./app/campaign/wizard/wizard.controller.js');
require('./app/campaign/wizard/create-rule-modal.controller.js');
require('./app/campaign/note/create-note-modal.controller.js');
require('./app/campaign/note/edit-note-modal.controller.js');
require('./app/campaign/show/show.controller.js');
require('./app/campaign/show/placement-settings-modal.controller.js');
require('./app/campaign/show/update-advideo-modal.controller.js');
require('./app/campaign/show/update-facebook-advideo-modal.controller.js');
require('./app/campaign/show/update-facebook-advideo-modal-on-network.controller.js');
require('./app/campaign/show/campaign-settings-modal.controller.js');
require('./app/campaign/show/campaign-finance-settings-modal.controller.js');
require('./app/campaign/show/merge-campaign-modal.controller.js');
require('./app/campaign/show/copy-campaign-modal.controller.js');
require('./app/campaign/show/new-user-modal.controller.js');
require('./app/campaign/financial/report.controller.js');
require('./app/campaign/financial/placement-financial-reports-modal.controller.js');
require('./app/campaign/benchmarks/report.controller.js');
require('./app/campaign/benchmarks/placement-benchmark-reports-modal.controller.js');
require('./app/campaign/network-accounts-campaigns-dashboard/network-accounts-campaigns-dashboard.controller.js');
require('./app/campaign/rule-activations.directive.js');
require('./app/common/capitalize.filter.js');

// {# Group javascripts #}
require('./app/group.module.js');
require('./app/group/create-modal.controller.js');
require('./app/group/list.controller.js');
require('./app/group/show.controller.js');

// {# User javascripts #}
require('../node_modules/angular-toastr/dist/angular-toastr.tpls.js');
require('../node_modules/angular-loading-bar/build/loading-bar.js');
require('../node_modules/ng-file-upload/ng-file-upload-all.js');
require('./app/user.module.js');
require('./app/user/settings.controller.js');

// {# admin javascripts#}
require('../node_modules/angular-ui-select/dist/select.js');
require('../node_modules/angular-toastr/dist/angular-toastr.tpls.js');
require('../node_modules/angular-fcsa-number/src/fcsaNumber.js');
require('../node_modules/angular-loading-bar/build/loading-bar.js');
require('../node_modules/checklist-model/checklist-model.js');
require('../node_modules/angular-xeditable/dist/js/xeditable.js');
require('../node_modules/ng-table-bundle/ng-table.js');
require('../node_modules/ng-file-upload/ng-file-upload-all.js');
require('./app/common/selector-required.directive.js');
require('./app/common/selector-required-dependant.directive.js');
require('./app/common/item-img-title.directive.js');
require('./app/common/ng-indeterminate.directive.js');
require('./app/admin.module.js');
require('./app/maintenance-mode/maintenance_mode.controller.js');
require('./app/grafana-dashboard/grafana_dashboard.controller.js');
require('./app/exchange-rates/list.controller.js');
require('./app/accounts/accounts.controller.js');
require('./app/accounts/accounts-audit.modal.controller.js');
require('./app/accounts/accounts-new.modal.controller.js');
require('./app/accounts/accounts-edit.controller.js');
require('./app/accounts/accounts-form.directive.js');
require('./app/dashboard.controller.js');
require('./app/users/users.controller.js');
require('./app/companies/companies.controller.js');
require('./app/companies/companies-audit.modal.controller.js');
require('./app/companies/companies-new.modal.controller.js');
require('./app/companies/companies-edit.controller.js');
require('./app/companies/companies-form.directive.js');
require('./app/users/users-form.directive.js');
require('./app/users/users-new-modal.controller.js');
require('./app/users/users-edit.controller.js');
require('./app/users/users-audit.controller.js');
require('./app/categories/channel_categories.controller.js');
require('./js/fixed-header.js');

// {# control-center javascripts #}
require('../node_modules/angular-toastr/dist/angular-toastr.tpls.js');
require('../node_modules/angular-loading-bar/build/loading-bar.js');
require('./app/control-center/control-center.module.js');
require('./app/control-center/control-center.service.js');
require('./app/control-center/control-center-issues.directive.js');
require('./app/control-center/index.controller.js');
require('./app/control-center/issue-detail.controller.js');

// {# brand-safety javascript #}
require('../node_modules/angular-ui-select/dist/select.js');
require('../node_modules/angular-toastr/dist/angular-toastr.tpls.js');
require('../node_modules/angular-loading-bar/build/loading-bar.js');
require('../node_modules/checklist-model/checklist-model.js');
require('../node_modules/angularjs-slider/dist/rzslider.js');
require('../node_modules/angular-filter-count-to/src/angular-filter-count-to.js');
require('../node_modules/ng-file-upload/ng-file-upload-all.js');
require('../node_modules/ng-table-bundle/ng-table.js');
require('./app/brand-safety.module.js');
require('./app/brand-safety/brand-safety.service.js');
require('./app/brand-safety/placement-list-comparator.directive.js');
require('./app/brand-safety/analysis.controller.js');
require('./app/brand-safety/list-creator.controller.js');
require('./app/brand-safety/lists.controller.js');
require('./app/brand-safety/keywords-lists.controller.js');
require('./app/brand-safety/remove-list-modal.service.js');
require('./app/brand-safety/remove-list-modal.controller.js');
require('./app/brand-safety/create-list-modal.service.js');
require('./app/brand-safety/create-list-modal.controller.js');
require('./app/brand-safety/create-channel-modal.service.js');
require('./app/brand-safety/create-channel-modal.controller.js');
require('./app/brand-safety/country-lists.controller.js');
require('./app/brand-safety/create-curators-list-modal.controller');
require('./app/brand-safety/create-curators-list-modal.service');
require('./app/brand-safety/directive/channel-ids-selector.directive.js');
require('./app/brand-safety/directive/channel-names-selector.directive.js');

// {# static javascript #}
require('./app/static.module.js');

// {# charts javascript #}
require('./app/charts.module.js');
require('./app/charts/weather-calendar.controller');

// {# index combine module js #}
require('./app/index.module.js');
// <!-- end: MAIN JAVASCRIPTS -->
