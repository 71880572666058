(function() {
    'use strict';

    angular
        .module('tvl.common')
        .directive('tvlChannelGroupBanner', tvlChannelGroupBanner);

    tvlChannelGroupBanner.$inject = [];

    /* @ngInject */
    function tvlChannelGroupBanner() {
        var directive = {
            bindToController: true,
            controller: Controller,
            controllerAs: 'vm',
            templateUrl: 'common/group-banner.html',
            link: link,
            restrict: 'E',
            scope: {
            	group: '=',
            	small: '=?'
            }
        };
        return directive;

        function link(scope, element, attrs) {
        }
    }

    /* @ngInject */
    function Controller() {
        var vm = this;

        vm.getBannerImgUrl = getBannerImgUrl;
        vm.getGroupCreationDate = getGroupCreationDate;

        /**
         * Get the CSS style for the group banner.
         *
         * @return {string}
         */
        function getBannerImgUrl() {
            var bannerImgUrl = '';

            if (! vm.group) {
                return bannerImgUrl;
            }

            if (
                vm.group.youtubeChannel
                && vm.group.youtubeChannel.bannerUrl != null
                && vm.group.youtubeChannel.bannerUrl != ""
            ) {
                bannerImgUrl = vm.group.youtubeChannel.bannerUrl;
            } else if (
                vm.group.facebookChannel
                && vm.group.facebookChannel.bannerUrl != null
                && vm.group.facebookChannel.bannerUrl != ""
            ) {
                bannerImgUrl = vm.group.facebookChannel.bannerUrl;
            }

            return bannerImgUrl;
        }

        /**
         * Return the creation date of the group as a
         * string, taking into account different date formats.
         *
         * This is a temporary function which handles different
         * ways of serializing datetimes in the backend. Ideally
         * this will not be needed anymore once all entities are
         * serialized using the same serializer.
         *
         * @return {string}
         */
        function getGroupCreationDate() {
            if (typeof vm.group.createdAt === 'string') {
                return vm.group.createdAt;
            }

            if (vm.group.createdAt.hasOwnProperty('timestamp')) {
                return vm.group.createdAt.timestamp * 1000;
            }

            return null;
        }
    }
})();
