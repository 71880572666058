(function() {

    angular
        .module('tvl.common')
        .directive('tvlWeatherCalendar', tvlWeatherCalendar);

    tvlWeatherCalendar.$inject = [];

    /**
     * @ngInject
     */
    function tvlWeatherCalendar() {
        var directive = {
            link: link,
            restrict: 'E',
            templateUrl: "common/weather-calendar.html",
            scope: {
                startDate: '=',
                endDate: '=',
                weeks: '='
            }
        };

        return directive;

        function link(scope) {
            /**
             * get condition icon label
             * @param  {String} condition
             * @return {String}
             */
            scope.getConditionIconLabel = function(condition) {
                switch (condition) {
                    case 'clear':
                        return 'day-sunny';
                    case 'snow':
                    case 'rain':
                    case 'sleet':
                    case 'cloudy':
                        return condition;
                    case 'foggy':
                        return 'fog';
                }
            };

            scope.select = function(day, index) {
                var cuantos = angular.element(document.querySelectorAll('[id^="expand"]'));
                for (i = 0; i < cuantos.length; i++) {
                    if (i !== index) {
                        var closing = angular.element('#' + cuantos[i].id);
                        closing.collapse('hide');
                    }
                }
                if (scope.startDate <= day.date && scope.endDate >= day.date) {
                    scope.selected = day;
                    scope.selected.weather.conditionIcon = scope.getConditionIconLabel(scope.selected.weather.condition);
                    var expandable = angular.element('#expand' + index);
                    expandable.collapse('show');
                }
            };

            scope.close = function(index) {
                var closing = angular.element('#expand' + index);
                closing.collapse('hide');
                scope.selected = null;
            };
        }
    }
})();
