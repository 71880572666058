(function() {
    'use strict';

    angular
        .module('tvl.common')
        .directive('mainNavBar', function() {
            return {
                templateUrl: 'common/main-nav-bar.html',
                restrict: 'E',
                controller: function($scope, tvlSession) {
                    $scope.loggedUser = null;

                    tvlSession.getSession().then(function(user) {
                        $scope.loggedUser = user;
                        toggleActiveMenuByPath();
                    });

                    $scope.openedMenu = null;
                    $scope.toggle = toggle;

                    function toggleActiveMenuByPath() {
                        // Post render AngularJS.
                        setTimeout(function () {
                            $('.main-navigation-menu li').removeClass('active').removeClass('open');
                            var element = $('a[href$="'+window.location.pathname+window.location.search+'"]').parent("li");
                            var elementParent = element.parent("ul");
                            if(elementParent.hasClass('sub-menu')){
                                element.addClass('active');
                                elementParent.parent('li').addClass('active open');
                            }else{
                                element.addClass('active open');
                            }
                        }, 600);
                    }

                    function toggle(item) {
                        toggleActiveMenuByPath();

                        if ($('.main-navigation-menu li[data-name="'+item+'"]').hasClass('active')) {
                            return;
                        }

                        if ($scope.openedMenu === item) {
                            $scope.openedMenu = null;
                        } else {
                            $scope.openedMenu = item;
                        }
                    }
                }
            };
        });

})();
