(function () {
    angular
        .module('tvl.campaign')
        .directive('tvlFacebookAssetPreview', tvlFacebookAssetPreview);

    /** @ngInject */
    function tvlFacebookAssetPreview() {
        return {
            restrict: 'E',
            replace: true,
            scope: {
                facebookAdVideo: '=',
            },
            templateUrl: 'campaign/wizard/directives/fb-asset-preview.html',
        };
    }
})();