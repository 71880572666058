(function() {
    'use strict';

    angular
        .module('tvl.common')
        .factory('tvlAuthorization', tvlAuthorization);

    /**
     * This service returns if a user is authorized to do
     * some actions inside the application.
     */
    function tvlAuthorization() {

        return {
            isUserAuthorized: isUserAuthorized
        };

        ////////////////

        /**
         * Check if the user has permission to access the state given
         *
         * @param sessionInfo
         * @param destinationState
         * @returns {boolean}
         */
        function isUserAuthorized(sessionInfo, destinationState) {
            switch (destinationState.name) {
                case 'campaign.wizard':
                case 'campaign.wizard.ads':
                case 'campaign.wizard.information':
                case 'campaign.wizard.media-distribution':
                case 'campaign.wizard.network-distribution':
                case 'campaign.wizard.review':
                case 'campaign.wizard.schedule':
                case 'campaign.wizard.targeting':
                case 'campaign.wizard.events':
                case 'campaign.wizard.user-access':
                    if (sessionInfo.hasPermission('Campaign.create')) {
                        return true;
                    }
                    break;
                case 'campaign.brand-safety':
                    if (sessionInfo.roles.indexOf('ROLE_ADMIN') !== -1) {
                        return true;
                    }
                    break;
                default:
                    return true;
            }

        }
    }
})();
