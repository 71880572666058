(function() {
    'use strict';

    angular
        .module('tvl.campaign')
        .controller('PlacementSettingsModalController', PlacementSettingsModalController);

    PlacementSettingsModalController.$inject = [
        '$scope',
        '$uibModalInstance',
        'toastr',
        'tvlUtils',
        'tvlAdVideoUtils',
        'Placement',
        'placement',
        'campaign',
        'loggedUser',
    ];

    /* @ngInject */
    function PlacementSettingsModalController(
        $scope,
        $uibModalInstance,
        toastr,
        tvlUtils,
        tvlAdVideoUtils,
        Placement,
        placement,
        campaign,
        loggedUser
    ) {
        var vm = this;
        vm.tvlAdVideoUtils = tvlAdVideoUtils;
        vm.placement = placement;

        vm.campaign = campaign;
        vm.loggedUser = loggedUser;
        vm.hasAccountPermission = hasAccountPermission;
        vm.showAdvancedSettings = showAdvancedSettings;
        vm.onChangeOnlyAcceptNumbers = onChangeOnlyAcceptNumbers;

        vm.disabledSubmit = vm.placement.status && (vm.placement.status === 'creating' || placement.updating);

        vm.minBid = vm.campaign.customerAccount[vm.placement.network + "MinBiddingStep"];
        vm.step = vm.minBid;
        vm.fixedStep = tvlUtils.getMinNumberWithSameFractionDigits(vm.minBid);

        vm.fixedCpv = null;
        vm.fixedCpm = null;
        vm.fixedCpc = null;
        vm.fixedCpa = null;
        vm.biddingMaxCpv = vm.placement.biddingMaxCpv;
        vm.biddingMaxCpm = vm.placement.biddingMaxCpm;
        vm.biddingMaxCpc = vm.placement.biddingMaxCpc;
        vm.biddingMaxCpa = vm.placement.biddingMaxCpa;
        vm.dailyBudgetLimit = vm.placement.dailyBudgetLimit;
        vm.automanaged = vm.placement.automanaged;

        vm.facebookCampaignId = vm.placement.facebookCampaignId;
        vm.adwordsCampaignId = vm.placement.adwordsCampaignId;
        vm.adwordsAdGroupId = vm.placement.adGroups[0].adwordsAdGroupId;

        // Following statements will change since new formats with new billing methods are comming
        // Now goal is in adVideo but it will be part of campaign or placement in the future
        var ad = vm.placement.adGroups[0].ads[0];
        vm.cpvAvailable = tvlAdVideoUtils.isCpvAdVideo(ad);
        vm.cpmAvailable = tvlAdVideoUtils.isCpmAdVideo(ad);
        vm.cpcAvailable = tvlAdVideoUtils.isCpcAdVideo(ad);
        vm.cpaAvailable = tvlAdVideoUtils.isCpaAdVideo(ad);
        vm.hasFixedCpv = false;
        vm.hasFixedCpm = false;
        vm.hasFixedCpc = false;
        vm.ui = {
            updating: false,
            campaignNamesAreLoading: false,
            goal: getGoalLabel(),
        };

        vm.showBiddingCurrency = false;
        vm.biddingCurrency = vm.campaign[vm.placement.network + 'BiddingCurrency'];
        vm.displayCurrency = vm.campaign.displayCurrency;
        vm.exchangeRate = vm.campaign[vm.placement.network + 'ExchangeRate'];
        vm.biddingPrecision = tvlUtils.countDecimals(vm.minBid);
        vm.biddingCurrencyFractionDigits = vm.campaign[vm.placement.network + 'BiddingCurrencyFractionDigits'];

        vm.networkName = vm.placement.networkName;
        vm.adGroupNetworkName = vm.placement.adGroups[0].networkName;
        vm.adNetworkName = vm.placement.adGroups[0].ads[0].networkName;

        vm.showingAdvancedSettings = false;

        if (vm.placement.facebookPositions && vm.placement.instagramPositions) {
            if (vm.placement.facebookPositions.length > 0) {
                vm.facebookPositions = vm.placement.facebookPositions
            }

            if (vm.placement.instagramPositions.length > 0 ) {
                vm.instagramPositions = vm.placement.instagramPositions
            }

            if (vm.placement.facebookPositions.length === 0 && vm.placement.instagramPositions.length === 0) {
                vm.placement.facebookPositions = 'Automatic';
                vm.placement.instagramPositions = 'Automatic';
            }
        }

        if (vm.placement.facebookObjective) {
            vm.facebookObjective = (vm.placement.facebookObjective === 'objective_thruplays') ?
                'Thruplay views' : '3 seconds views';
        }

        if (vm.placement.facebookOptimizationGoal) {
            vm.facebookOptimizationGoal =
                tvlAdVideoUtils.getTitleForFacebookOptimizationGoal(vm.placement.facebookOptimizationGoal)
        }
        if (vm.placement.facebookFrequencyCapImpressions) {
            vm.facebookFrequencyCapImpressions = vm.placement.facebookFrequencyCapImpressions;
        }
        if (vm.placement.facebookFrequencyCapIntervalDays) {
            vm.facebookFrequencyCapIntervalDays = vm.placement.facebookFrequencyCapIntervalDays;
        }

        initMarkup();

        vm.cancel = cancel;
        vm.update = update;

        $scope.$watch('vm.showBiddingCurrency', onShowBiddingCurrencyChange, true);

        $scope.$watch('vm', onCpaChange, true);

        $scope.onlyNumbersGoogleAccountsId = /^\d{0,12}$/;
        $scope.onlyNumbersFacebookAccountsId = /^\d{0,18}$/;

        activate();

        ////////////////

        function activate() {
            determineFixedCpas();
            vm.fixedLowerThanMax = isFixedLowerThanMax();
            vm.fixedLowerThanMin = isFixedLowerThanMin();
        }

        /**
         * Determine the fixed Cost-Per-Actions (CPAs) for this placement, if
         * any.
         */
        function determineFixedCpas() {
            var cpas = ['cpv', 'cpm', 'cpc', 'cpa'];
            for (var i in cpas) {
                var cpa = cpas[i];
                var cpau = _.capitalize(cpa);
                vm['fixed' + cpau] = vm.placement['fixed' + cpau] || null;
                vm['hasFixed' + cpau] = vm['fixed' + cpau] !== null;
            }
        }

        function cancel() {
            return $uibModalInstance.dismiss();
        }

        function update() {
            if (vm.showBiddingCurrency) {
                vm.dailyBudgetLimit = tvlUtils.roundNumber(vm.dailyBudgetLimit * vm.exchangeRate, vm.biddingPrecision);
            }

            vm.placement.dailyBudgetLimit = vm.dailyBudgetLimit;

            if (vm.cpvAvailable) {
                if (vm.showBiddingCurrency) {
                    vm.fixedCpv = tvlUtils.roundNumber(vm.fixedCpv * vm.exchangeRate, vm.biddingPrecision);
                    vm.biddingMaxCpv = tvlUtils.roundNumber(vm.biddingMaxCpv * vm.exchangeRate, vm.biddingPrecision);
                }

                vm.placement.fixedCpv = vm.fixedCpv;
                vm.placement.biddingMaxCpv = vm.biddingMaxCpv;
            }

            if (vm.cpcAvailable) {
                if (vm.showBiddingCurrency) {
                    vm.fixedCpc = tvlUtils.roundNumber(vm.fixedCpc * vm.exchangeRate, vm.biddingPrecision);
                    vm.biddingMaxCpc = tvlUtils.roundNumber(vm.biddingMaxCpc * vm.exchangeRate, vm.biddingPrecision);
                }

                vm.placement.fixedCpc = vm.fixedCpc;
                vm.placement.biddingMaxCpc = vm.biddingMaxCpc;
            }

            if (vm.cpmAvailable) {
                if (vm.showBiddingCurrency) {
                    vm.fixedCpm = tvlUtils.roundNumber(vm.fixedCpm * vm.exchangeRate, vm.biddingPrecision);
                    vm.biddingMaxCpm = tvlUtils.roundNumber(vm.biddingMaxCpm * vm.exchangeRate, vm.biddingPrecision);
                }

                vm.placement.fixedCpm = vm.fixedCpm;
                vm.placement.biddingMaxCpm = vm.biddingMaxCpm;
            }

            if (vm.cpaAvailable) {
                if (vm.showBiddingCurrency) {
                    vm.fixedCpa = tvlUtils.roundNumber(vm.fixedCpa * vm.exchangeRate, vm.biddingPrecision);
                    vm.biddingMaxCpa = tvlUtils.roundNumber(vm.biddingMaxCpa * vm.exchangeRate, vm.biddingPrecision);
                }

                vm.placement.fixedCpa = vm.fixedCpa;
                vm.placement.biddingMaxCpa = vm.biddingMaxCpa;
            }

            vm.placement.networkName = vm.networkName;
            vm.placement.adGroupNetworkName = vm.adGroupNetworkName;
            vm.placement.adNetworkName = vm.adNetworkName;
            if (vm.userCanUpdateMarkup && null !== vm.markup) {
                vm.placement.markup = 1 + (vm.markup / 100);
            }
            vm.placement.automanaged = vm.automanaged;

            vm.placement.facebookCampaignId = vm.facebookCampaignId;
            vm.placement.adwordsCampaignId = vm.adwordsCampaignId;
            vm.placement.adwordsAdGroupId = vm.adwordsAdGroupId;

            return $uibModalInstance.dismiss();
        }

        function onShowBiddingCurrencyChange(newVal, oldVal) {
            if (newVal === oldVal) {
                return;
            }

            if (newVal) {
                vm.displayCurrency = vm.biddingCurrency;
                vm.dailyBudgetLimit = tvlUtils.roundNumber(vm.dailyBudgetLimit * (1 / vm.exchangeRate), vm.biddingCurrencyFractionDigits);
                vm.minBid = tvlUtils.roundNumber((1 / vm.exchangeRate) * vm.minBid, vm.biddingCurrencyFractionDigits);
                vm.step = vm.minBid;
                vm.fixedStep = vm.step;

                if (vm.cpvAvailable) {
                    if (vm.fixedCpv) {
                        vm.fixedCpv = tvlUtils.roundNumber(vm.fixedCpv * (1 / vm.exchangeRate), vm.biddingCurrencyFractionDigits);
                    }
                    vm.biddingMaxCpv = tvlUtils.roundNumber(vm.biddingMaxCpv * (1 / vm.exchangeRate), vm.biddingCurrencyFractionDigits);
                }

                if (vm.cpcAvailable) {
                    if (vm.fixedCpc) {
                        vm.fixedCpc = tvlUtils.roundNumber(vm.fixedCpc * (1 / vm.exchangeRate), vm.biddingCurrencyFractionDigits);
                    }
                    vm.biddingMaxCpc = tvlUtils.roundNumber(vm.biddingMaxCpc * (1 / vm.exchangeRate), vm.biddingCurrencyFractionDigits);
                }

                if (vm.cpmAvailable) {
                    if (vm.fixedCpm) {
                        vm.fixedCpm = tvlUtils.roundNumber(vm.fixedCpm * (1 / vm.exchangeRate), vm.biddingCurrencyFractionDigits);
                    }
                    vm.biddingMaxCpm = tvlUtils.roundNumber(vm.biddingMaxCpm * (1 / vm.exchangeRate), vm.biddingCurrencyFractionDigits);
                }

                if (vm.cpaAvailable) {
                    if (vm.fixedCpa) {
                        vm.fixedCpa = tvlUtils.roundNumber(vm.fixedCpa * (1 / vm.exchangeRate), vm.biddingCurrencyFractionDigits);
                    }
                    vm.biddingMaxCpa = tvlUtils.roundNumber(vm.biddingMaxCpa * (1 / vm.exchangeRate), vm.biddingCurrencyFractionDigits);
                }

            } else {
                vm.displayCurrency = vm.campaign.displayCurrency;
                vm.dailyBudgetLimit = tvlUtils.roundNumber(vm.dailyBudgetLimit * vm.exchangeRate, 2);
                vm.minBid = vm.campaign.customerAccount[vm.placement.network + "MinBiddingStep"];
                vm.step = vm.minBid;
                vm.fixedStep = tvlUtils.getMinNumberWithSameFractionDigits(vm.minBid);

                if (vm.cpvAvailable) {
                    if (vm.fixedCpv) {
                        vm.fixedCpv = tvlUtils.roundNumber(vm.fixedCpv * vm.exchangeRate, vm.biddingPrecision);
                    }
                    vm.biddingMaxCpv = tvlUtils.roundNumber(vm.biddingMaxCpv * vm.exchangeRate, vm.biddingPrecision);
                }

                if (vm.cpcAvailable) {
                    if (vm.fixedCpc) {
                        vm.fixedCpc = tvlUtils.roundNumber(vm.fixedCpc * vm.exchangeRate, vm.biddingPrecision);
                    }
                    vm.biddingMaxCpc = tvlUtils.roundNumber(vm.biddingMaxCpc * vm.exchangeRate, vm.biddingPrecision);
                }

                if (vm.cpmAvailable) {
                    if (vm.fixedCpm) {
                        vm.fixedCpm = tvlUtils.roundNumber(vm.fixedCpm * vm.exchangeRate, vm.biddingPrecision);
                    }
                    vm.biddingMaxCpm = tvlUtils.roundNumber(vm.biddingMaxCpm * vm.exchangeRate, vm.biddingPrecision);
                }

                if (vm.cpaAvailable) {
                    if (vm.fixedCpa) {
                        vm.fixedCpa = tvlUtils.roundNumber(vm.fixedCpa * vm.exchangeRate, vm.biddingPrecision);
                    }
                    vm.biddingMaxCpa = tvlUtils.roundNumber(vm.biddingMaxCpa * vm.exchangeRate, vm.biddingPrecision);
                }
            }
        }

        function onCpaChange(newVal, oldVal)
        {
            vm.fixedLowerThanMax = isFixedLowerThanMax();
            vm.fixedLowerThanMin = isFixedLowerThanMin();
        }

        function isFixedLowerThanMax()
        {
            if (vm.cpvAvailable && vm.fixedCpv) {
                return vm.fixedCpv < vm.biddingMaxCpv;
            }
            if (vm.cpcAvailable && vm.fixedCpc) {
                return vm.fixedCpc < vm.biddingMaxCpc;
            }
            if (vm.cpmAvailable && vm.fixedCpm) {
                return vm.fixedCpm < vm.biddingMaxCpm;
            }
            if (vm.cpaAvailable && vm.fixedCpa) {
                return vm.fixedCpa < vm.biddingMaxCpa;
            }
            return false;
        }

        function isFixedLowerThanMin()
        {
            if (vm.cpvAvailable && vm.fixedCpv !== null) {
                return vm.fixedCpv < vm.minBid;
            }
            if (vm.cpcAvailable && vm.fixedCpc !== null) {
                return vm.fixedCpc < vm.minBid;
            }
            if (vm.cpmAvailable && vm.fixedCpm !== null) {
                return vm.fixedCpm < vm.minBid;
            }
            if (vm.cpaAvailable && vm.fixedCpa !== null) {
                return vm.fixedCpa < vm.minBid;
            }
            return false;
        }

        function hasAccountPermission(permission) {
            return vm.loggedUser.hasAccountPermission(vm.campaign.customerAccount.id, permission);
        }

        function showAdvancedSettings() {
            vm.showingAdvancedSettings = true;
        }

        function onChangeOnlyAcceptNumbers(text) {
            let newText = '';
            const numbers = '^[0-9]';
            for (let i = 0; i < text.length; i++) {
                if (numbers.indexOf(text[i] > -1)) {
                    newText += text[i];
                }
                this.setState({ currencyValue: newText });
            }
        }

        function getGoalLabel() {
            if (vm.cpvAvailable) {
                return 'CPV';
            }
            if (vm.biddingMaxCpm) {
                return 'CPM';
            }
            if (vm.biddingMaxCpc) {
                return 'CPC';
            }
            return 'CPA';
        }

        function initMarkup() {
            vm.markup = (vm.placement.markup) ? _.round(((vm.placement.markup - 1) * 100), 2) : null;
            vm.userCanViewMarkup = vm.loggedUser.hasPermission('Campaign.view_markup');
            vm.userCanUpdateMarkup = vm.loggedUser.hasPermission('Campaign.update_markup');
        }
    }
}());
