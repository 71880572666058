(function() {
    angular
        .module('tvl.common')
        .factory('Account', Account)
        .factory('AccountCampaignNames', AccountCampaignNames)
        .factory('AdNetworkConfiguration', AdNetworkConfiguration)
        .factory('Campaign', Campaign)
        .factory('ErrorLog', ErrorLog)
        .factory('Group', Group)
        .factory('CampaignNotes', CampaignNotes)
        .factory('Category', Category)
        .factory('Channel', Channel)
        .factory('Video', Video)
        .factory('ChannelAnalysis', ChannelAnalysis)
        .factory('Company', Company)
        .factory('Constants', Constants)
        .factory('ExchangeRate', ExchangeRate)
        .factory('ContentsList', ContentsList)
        .factory('FacebookBusiness', FacebookBusiness)
        .factory('Formats', Formats)
        .factory('ImageAsset', ImageAsset)
        .factory('Interest', Interest)
        .factory('KeywordsList', KeywordsList)
        .factory('Location', Location)
        .factory('Notification', Notification)
        .factory('Placement', Placement)
        .factory('CampaignPlacement', CampaignPlacement)
        .factory('AdVideo', AdVideo)
        .factory('PlacementStat', PlacementStat)
        .factory('ContentStat', ContentStat)
        .factory('ContentStatPerformance', ContentStatPerformance)
        .factory('Rule', Rule)
        .factory('Targeting', Targeting)
        .factory('Topic', Topic)
        .factory('tvlResource', TvlResource)
        .factory('Login', Login)
        .factory('Token', Token)
        .factory('ResetPassword', ResetPassword)
        .factory('ApiKeys', ApiKeys)
        .factory('Logout', Logout)
        .factory('User', User)
        .factory('UserRoles', UserRoles)
        .factory('UserSettings', UserSettings)
        .factory('UrlUtil', UrlUtil)
        .factory('MaintenanceMode', MaintenanceMode)
        .factory('NetworkAccounts', NetworkAccounts)
        .factory('ControlCenterIssues', ControlCenterIssues)
        .factory('PlacementAverageStats', PlacementAverageStats)
        .factory('Permissions', Permissions)
        .factory('BrandSafetyChannel', BrandSafetyChannel);

    Account.$inject = ['tvlResource'];
    function Account(tvlResource) {
        return tvlResource('/api/accounts/:id', {
            id: '@id'
        }, {
            getChangelog: {
                method: 'GET',
                url: '/api/accounts/:id/changelog'
            },
            summary: {
                method: 'GET',
                url: '/api/accounts/summary'
            },
            tags: {
                method: 'GET',
                url: '/api/accounts/tags'
            },
            getNonVideoCampaigns: {
                method: 'GET',
                url: '/api/accounts/:id/adnetwork/adwords/campaigns'
            },
            conversions: {
                method: 'GET',
                url: '/api/accounts/conversions'
            },
            updateCustomInterest: {
                method: 'GET',
                url: '/api/accounts/:id/custom_interests'
            },
        });
    }

    AccountCampaignNames.$inject = ['tvlResource'];
    function AccountCampaignNames(tvlResource) {
        return tvlResource('/api/accounts/:id/campaign_names', {
            id: '@id'
        });
    }

    Company.$inject = ['tvlResource'];
    function Company(tvlResource) {
        return tvlResource(
            '/api/companies/:id', {
            id: '@id'
        }, {
            getAllCompanies: {
                method: 'GET',
                url: '/api/companies'
            },
            updateCompany: {
                method: 'PUT',
                url: '/api/companies/:id'
            },
            getNetworkAccounts: {
                method: 'GET',
                url: '/api/companies/:id/network_accounts?items_per_page=1000'
            },
            getChangelog: {
                method: 'GET',
                url: '/api/companies/:id/changelog'
            },
            summary: {
                method: 'GET',
                url: '/api/companies/summary'
            }
        }
        );
    }

    AdNetworkConfiguration.$inject = ['tvlResource'];
    function AdNetworkConfiguration(tvlResource) {
        return tvlResource('/api/ad_network_configurations');
    }

    CampaignNotes.$inject = ['tvlResource'];
    function CampaignNotes(tvlResource) {
        return tvlResource(
            '/api/campaigns/:id/notes/:noteId', {
                id: '@id',
                noteId: '@noteId',
            }, {
                getNotes: {
                    method: 'GET',
                    url: '/api/campaigns/:id/notes'
                },
                createNote: {
                    method: 'POST',
                    url: '/api/campaigns/:id/notes'
                },
                updateNote: {
                    method: 'PUT',
                    url: '/api/campaigns/:id/notes/:noteId'
                }
            }
        );
    }

    Campaign.$inject = ['tvlResource'];
    function Campaign(tvlResource) {
        return tvlResource(
            '/api/campaigns/:id', {
                id: '@id'
            }, {
                getCounters: {
                    method: 'GET',
                    url: '/api/campaigns/count'
                },
                getStats: {
                    method: 'GET',
                    url: '/api/campaigns/:id/stats'
                },
                getChangelog: {
                    method: 'GET',
                    url: '/api/campaigns/:id/changelog'
                },
                getErrorLog: {
                    method: 'GET',
                    url: '/api/campaigns/:id/error-log'
                },
                getChangelogFilters: {
                    method: 'GET',
                    url: '/api/campaigns/:id/changelog/filters'
                },
                getRulesLog: {
                    method: 'GET',
                    url: '/api/campaigns/:id/rules_log'
                },
                refreshJob: {
                    method: 'POST',
                    url: '/api/campaigns/refresh_job'
                },
                merge: {
                    method: 'POST',
                    url: '/api/campaigns/merge'
                },
                copy: {
                    method: 'POST',
                    url: '/api/campaigns/:id'
                },
                getFinancialReports: {
                    method: 'GET',
                    url: '/api/campaigns/financial_reports'
                },
                getBenchmarkReports: {
                    method: 'GET',
                    url: '/api/campaigns/benchmark_reports'
                },
                getBenchmarkPerformanceReports: {
                    method: 'GET',
                    url: '/api/campaigns/benchmark_reports/performance'
                },
                getLast12MonthsBenchmarkPerformanceReports: {
                    method: 'GET',
                    url: '/api/campaigns/benchmark_reports/performance/last-12-months'
                },
                getDayOfTheWeekBenchmarkPerformanceReports: {
                    method: 'GET',
                    url: '/api/campaigns/benchmark_reports/performance/week-days'
                },
                getCountries: {
                    method: 'GET',
                    url: '/api/campaigns/countries',
                    isArray: true
                },
                getDisplayReport: {
                    method: 'GET',
                    url: '/api/campaigns/display/report'
                },
                importCampaign: {
                    method: 'GET',
                    url: '/api/campaigns/import'
                },
                postImportCampaign: {
                    method: 'POST',
                    url: '/api/campaigns/import'
                }
            }
        );
    }

    ErrorLog.$inject = ['tvlResource'];
    function ErrorLog(tvlResource) {
        return tvlResource(
            '/api/error-log/:id', {
                id: '@id'
            }, {
                updateStatus: {
                    method: 'POST',
                    url: '/api/error-log/:id/update-status',
                    isArray: false
                }
            }
        );
    }

    Group.$inject = ['tvlResource'];
    function Group(tvlResource) {
        return tvlResource(
            '/api/groups/:id', {
                id: '@id'
            }, {}
        );
    }

    Category.$inject = ['tvlResource'];
    function Category(tvlResource) {
        return tvlResource('/api/categories/:id', {
            id: '@id'
        }, {
            getChoices: {
                method: 'GET',
                url: '/api/category/choices',
                isArray: true
            },
            getContentTypeChoices: {
                method: 'GET',
                url: '/api/content_type/choices',
                isArray: true
            }
        });
    }

    Channel.$inject = ['tvlResource'];
    function Channel($resource) {
        return $resource(
            '/api/channels/:id', {
                id: '@id'
            }, {
                searchPlatformChannels: {
                    method: 'GET',
                    url: '/api/channels/search'
                }
            }
        );
    }

    Video.$inject = ['tvlResource'];
    function Video($resource) {
        return $resource(
            '/api/videos/:id', {
                id: '@id'
            }, {
                searchPlatformVideos: {
                    method: 'GET',
                    url: '/api/videos/search'
                },
            }
        );
    }

    ChannelAnalysis.$inject = ['tvlResource'];
    function ChannelAnalysis($resource) {
        return $resource(
            '/api/channelanalyses/:id', {
                id: '@id'
            }, {
                getPlacements: {
                    method: 'GET',
                    url: '/api/channelanalyses'
                },
                getKpis: {
                    method: 'GET',
                    url: '/api/channelanalyses/kpi'
                },
                postComparisons: {
                    method: 'POST',
                    url: '/api/channelanalyses/comparisons'
                },
                putChannel: {
                    method: 'PUT',
                    url: '/api/channelanalyses/:id'
                },
                getChannelsInfo: {
                    method: 'POST',
                    url: '/api/channelanalyses/channels/infos'
                },
                postChannelsCurate: {
                    method: 'POST',
                    url: '/api/channelanalyses/channels/curates'
                },
            }
        );
    }

    Constants.$inject = ['tvlResource'];
    function Constants($resource) {
        return $resource(
            '/api/constants', {}, {
                getCountries: {
                    method: 'GET',
                    url: '/api/constants/countries',
                    isArray: true
                },
                getLanguages: {
                    method: 'GET',
                    url: '/api/constants/languages',
                    isArray: true
                },
                getTimezones: {
                    method: 'GET',
                    url: '/api/constants/timezones',
                    isArray: true
                }
            }
        );
    }

    FacebookBusiness.$inject = ['tvlResource'];
    function FacebookBusiness($resource) {
        return $resource(
            '/api/facebook-business', {}, {
                getPostAccessibility: {
                    method: 'GET',
                    url: '/api/facebook-business/post-accessibility/:accountId/:postCreativeId'
                },
                getAccessiblePages: {
                    method: 'GET',
                    url: '/api/facebook-business/accessible-pages/:accountId'
                },
                getAccessiblePosts: {
                    method: 'GET',
                    url: '/api/facebook-business/accessible-pages/:accountId/:pageId/posts'
                },
                getAccessibleInstagramAccount: {
                    method: 'GET',
                    url: '/api/facebook-business/accessible-pages/:accountId/:pageId/instagram-accounts'
                },
                getInstagramAccountMedia: {
                     method: 'GET',
                     url: '/api/facebook-business/accessible-instagram-accounts/:accountId/:instagramId/media'
                },
                getAdVideos: {
                    method: 'GET',
                    url: '/api/facebook-business/ad-videos',
                    isArray: true
                },
                getAdImages: {
                    method: 'GET',
                    url: '/api/facebook-business/ad-images',
                    isArray: true
                },
                getPostInfoByIdAndType: {
                    method: 'GET',
                    url: '/api/facebook-business/post-creative/:accountId/:postCreativeId'
                },
                getMinimumBudgets: {
                    method: 'GET',
                    url: '/api/facebook-business/:accountId/:adAccountId/minimum-budgets/:currency',
                },
                getCallToActionButtonValues: {
                    method: 'GET',
                    url: '/api/facebook-business/ad-creative-cta-types',
                },
                getAssociatedIGId: {
                    method: 'GET',
                    url: '/api/facebook-business/accessible-pages/:accountId/:pageId/related-ig-account',
                }
            }
        );
    }

    Formats.$inject = ['tvlResource'];
    function Formats(tvlResource) {
        return tvlResource('/api/formats', {}, {
            getGoogleAdsFormats: {
                method: 'GET',
                url: '/api/formats/adwords'
            }
        });
    }

    ImageAsset.$inject = ['tvlResource'];
    function ImageAsset(tvlResource) {
        return tvlResource('/api/image_assets/:id', {
            id: '@id'
        });
    }

    Interest.$inject = ['tvlResource'];
    function Interest(tvlResource) {
        return tvlResource('/api/interests');
    }

    Location.$inject = ['tvlResource'];
    function Location(tvlResource) {
        return tvlResource(
            '/api/locations/:id', {
                id: '@id'
            }, {
                getCities: {
                    method: 'GET',
                    url: '/api/locations/:id/cities'
                },
                getCountries: {
                    method: 'GET',
                    url: '/api/locations/countries'
                },
            }
        );
    }

    Placement.$inject = ['tvlResource'];
    function Placement(tvlResource) {
        return tvlResource(
            '/api/placements/:id', {
                id: '@id'
            }, {
                getLocations: {
                    method: 'GET',
                    url: '/api/placements/:id/locations',
                    isArray: true
                },
                getInitialBidding: {
                    method: 'GET',
                    url: '/api/placement/initial_bidding_value',
                }
            }
        );
    }

    CampaignPlacement.$inject = ['tvlResource'];
    function CampaignPlacement(tvlResource) {
        return tvlResource(
            '/api/campaigns/:id/placement', {
                id: '@id'
            }, {
                create: {
                    method: 'POST',
                    url: '/api/campaigns/:id/placement'
                },
            }
        );
    }

    AdVideo.$inject = ['tvlResource'];
    function AdVideo(tvlResource) {
        return tvlResource('/api/advideos/:id', {id: '@id'});
    }

    PlacementStat.$inject = ['tvlResource'];
    function PlacementStat($resource) {
        return $resource(
            '/api/placements/:id/stats', {
                id: '@id'
            }, {
                get: {
                    isArray: true
                }
            }
        );
    }

    ContentStat.$inject = ['tvlResource'];
    function ContentStat($resource) {
        return $resource(
            '/api/placements/:id/content_stats', {
                id: '@id'
            }, {
                get: {
                    isArray: true
                }
            }
        );
    }

    ContentStatPerformance.$inject = ['tvlResource'];
    function ContentStatPerformance($resource) {
        return $resource(
            '/api/placements/:id/content_stats_performance', {
                id: '@id'
            }, {
                get: {
                    isArray: true
                }
            }
        );
    }

    Rule.$inject = ['tvlResource'];
    function Rule(tvlResource) {
        return tvlResource(
            '/api/rules/:id', {
                id: '@id'
            }, {
                getLocations: {
                    method: 'GET',
                    url: '/api/rules/:id/locations',
                    isArray: true
                },
                getStats: {
                    method: 'GET',
                    url: '/api/rules/:id/stats',
                    isArray: false
                },
                createRule: {
                    method: 'POST',
                    url: '/api/campaigns/:id/rules',
                    isArray: false
                }
            }
        );
    }

    Targeting.$inject = ['tvlResource'];
    function Targeting(tvlResource) {
        return tvlResource('/api/targetings/:id', {id: '@id'});
    }

    Topic.$inject = ['tvlResource'];
    function Topic(tvlResource) {
        return tvlResource('/api/topics');
    }

    TvlResource.$inject = ['$resource'];
    function TvlResource($resource) {
        return function(url, params, methods) {
            var defaults = {
                update: {
                    method: 'put',
                    isArray: false
                },
                updatePartial: {
                    method: 'patch',
                    isArray: false
                },
                create: {
                    method: 'post'
                }
            };

            methods = angular.extend(defaults, methods);

            var resource = $resource(url, params, methods);

            resource.prototype.$save = function(params) {
                if (!this.id) {
                    return this.$create(params);
                } else {
                    return this.$update(params);
                }
            };

            /**
             * Sometimes we may need to use PATCH instead of PUT method.
             */
            resource.prototype.$patch = function(params) {
                return this.$updatePartial(params);
            };

            return resource;
        };
    }

    Login.$inject = ['tvlResource'];
    function Login(tvlResource) {
        return tvlResource(
            '/api/login', {}, {}
        );
    }

    Token.$inject = ['tvlResource'];
    function Token(tvlResource) {
        return tvlResource(
            '/api/token', {}, {
                refresh: {
                    method: 'POST',
                    url: '/api/token/refresh'
                },
            }
        );
    }

    Logout.$inject = ['tvlResource'];
    function Logout(tvlResource) {
        return tvlResource(
            '/api/logout', {}, {}
        );
    }

    ResetPassword.$inject = ['tvlResource'];
    function ResetPassword(tvlResource) {
        return tvlResource(
            '/resetting/send-email-front', {}, {
                reset: {
                    method: 'POST',
                    url: '/resetting/send-email'
                }
            }
        );
    }

    ApiKeys.$inject = ['tvlResource'];
    function ApiKeys(tvlResource) {
        return tvlResource(
            '/api/keys', {}, {
                get: {
                    method: 'GET',
                    url: '/api/keys'
                }
            }
        );
    }

    User.$inject = ['tvlResource'];
    function User(tvlResource) {
        return tvlResource(
            '/api/users/:id', {
                id: '@id'
            }, {
                me: {
                    method: 'GET',
                    url: '/api/users/me'
                },
                getChangelog: {
                    method: 'GET',
                    url: '/api/users/:id/changelog'
                },
                summary: {
                    method: 'GET',
                    url: '/api/users/summary'
                },
                delete: {
                    method: 'DELETE',
                    url: '/api/users/:id'
                },
                resetUserPassword: {
                    method: 'PATCH',
                    isArray: false,
                    url: '/api/users/reset-password/:resetToken',
                },
                changePassword: {
                    method: 'PATCH',
                    isArray: false,
                    url: '/api/users/change-password',
                },
                sendPasswordResetEmail: {
                    method: 'POST',
                    url: '/api/users/send-password-reset-email'
                }
            }
        );
    }

    UserRoles.$inject = ['tvlResource'];
    function UserRoles($resource) {
        return $resource('/api/user-roles/:id', {
            id: '@id'
        }, {
            mixPermissions: {
                method: 'GET',
                url: '/api/user-roles',
            }
        });
    }

    UserSettings.$inject = ['tvlResource'];
    function UserSettings(tvlResource) {
        return tvlResource('/api/users/me', {}, {
            delete: {
                method: 'DELETE'
            }
        });
    }

    UrlUtil.$inject = ['tvlResource'];
    function UrlUtil(tvlResource) {
        return tvlResource(
            '/api/url_utils', {}, {
                info: {
                    method: 'GET',
                    url: '/api/url_utils/info'
                }
            }
        );
    }

    ExchangeRate.$inject = ['tvlResource'];
    function ExchangeRate(tvlResource) {
        return tvlResource(
            '/api/exchangerates/:id', {
                id: '@id',
                originCurrency: '@originCurrency',
                destinationCurrency: '@destinationCurrency'
            }, {
                getCurrencyPair: {
                    method: 'GET',
                    url: '/api/exchangerates/pair/:originCurrency/:destinationCurrency',
                    isArray: false
                }
            }
        );
    }

    ContentsList.$inject = ['tvlResource'];
    function ContentsList(tvlResource) {
        var resource = tvlResource(
            '/api/contentslists/:id', {
                id: '@id'
            }, {
                import: {
                    method: 'POST',
                    url: '/api/contentslists/import'
                },
                channelsForCountry: {
                    method: 'POST',
                    url: '/api/contentslists/channels'
                },
                getFiltersMain: {
                    method: 'GET',
                    url: '/api/contentslist/filters/main'
                },
                getFitlersByFormat: {
                    method: 'GET',
                    url: '/api/contentslist/filters/format'
                },
            }
        );
        return resource;
    }

    KeywordsList.$inject = ['tvlResource'];
    function KeywordsList(tvlResource) {
        var resource = tvlResource('/api/keywordslists/:id', {
            id: '@id'
        });
        return resource;
    }

    Notification.$inject = ['tvlResource'];
    function Notification(tvlResource) {
        var resource = tvlResource('/api/notifications/:id', {
            id: '@id'
        });
        return resource;
    }

    MaintenanceMode.$inject = ['tvlResource'];
    function MaintenanceMode(tvlResource) {
        return tvlResource(
            '/api/app-settings/maintenance', {}, {
                getMaintenanceInfo: {
                    method: 'GET',
                    url: '/api/app-settings/maintenance'
                },
                updateMaintenanceInfo: {
                    method: 'PUT',
                    url: '/api/app-settings/maintenance'
                }
            }
        );
    }

    NetworkAccounts.$inject = ['tvlResource'];
    function NetworkAccounts(tvlResource) {
        return tvlResource(
            '/api/network_accounts', {}, {
                getNetworkAccounts: {
                    method: 'GET',
                    url: '/api/network_accounts'

                }
            }
        );
    }

    ControlCenterIssues.$inject = ['tvlResource'];
    function ControlCenterIssues(tvlResource) {
        return tvlResource(
            '/api/control-center/issues', {q: '@q', id: '@id'}, {
                search: {
                    method: 'GET',
                    url: '/api/control-center/issues?:q'
                },
                resolve: {
                    method: 'PUT',
                    url: '/api/control-center/issues/:id'
                }
                ,
                get: {
                    method: 'GET',
                    url: '/api/control-center/issues/:id'
                }
            }
        );
    }

    PlacementAverageStats.$inject = ['tvlResource'];
    function PlacementAverageStats($resource) {
        return $resource(
            '/api/placements/:id/stats/average', {
                id: '@id'
            }, {
                get: {
                    isArray: true
                }
            }
        );
    }

    Permissions.$inject = ['tvlResource'];
    function Permissions(tvlResource) {
        return tvlResource('/api/permissions', {});
    }

    BrandSafetyChannel.$inject = ['tvlResource'];
    function BrandSafetyChannel(tvlResource) {
        return tvlResource(
            '/api/brandsafetychannels/:channelId', {
                channelId: '@channelId'
            }, {
                create: {
                    method: 'POST',
                    url: '/api/brandsafetychannels'
                },
                update: {
                    method: 'PATCH',
                    url: '/api/brandsafetychannels/:channelId'
                },
                delete: {
                    method: 'DELETE',
                    url: '/api/brandsafetychannels/:channelId'
                },
            }
        );
    }

})();
