(function() {
    'use strict';

    angular
        .module('tvl.administration')
        .controller('UsersEditController', UsersEditController);

    UsersEditController.$inject = [
        '$rootScope',
        '$scope',
        '$state',
        '$uibModal',
        'User',
        'user',
        'resourceResponse',
        'toastr',
        'tvlSweetAlert',
        'tvlSession',
    ];

    /* @ngInject */
    function UsersEditController(
        $rootScope,
        $scope,
        $state,
        $uibModal,
        User,
        user,
        resourceResponse,
        toastr,
        tvlSweetAlert,
        tvlSession
    ) {
        $scope.user = user;
        $scope.isSaving = false;
        $scope.auditUser = auditUser;
        $scope.saveUser = saveUser;
        $scope.backToList = backToList;
        $scope.onSaveSuccess = onSaveSuccess;
        $scope.onSaveError = onSaveError;
        $scope.onSaving = onSaving;
        $scope.isValid = false;
        $scope.onFormValid = onFormValid;
        $scope.onFormInvalid = onFormInvalid;
        $scope.deleteUser = deleteUser;
        $scope.loggedUser = null;

        tvlSession.getSession().then(function(user) {
            // get info for logged user, need to know its role to know which roles can create
            $scope.loggedUser = user;
        });

        function saveUser() {
            $rootScope.$broadcast("UsersForm.Save");
        }

        function onSaveSuccess(user) {
            $scope.backToList();
        }

        function onSaveError(user) {
            $scope.isSaving = false;
        }

        function onSaving() {
            $scope.isSaving = true;
        }

        function auditUser() {
            $uibModal.open({
                controller: 'AuditUserModalController as modal',
                templateUrl: 'users/users-audit.modal.html',
                size: 'lg',
                resolve: {
                    user: function () {
                        return user;
                    }
                }
            }).result;
        }

        function onFormValid() {
            $scope.isValid = true;
        }

        function onFormInvalid() {
            $scope.isValid = false;
        }

        function backToList() {
            $state.go('admin.users');
        }

        function deleteUser() {
            var msg = 'Are you sure you want to delete this user? This action can not be undone.';

            tvlSweetAlert.confirm('Delete user?', msg).then(function () {
                $scope.isSaving = true;
                User.delete({id: user.id}).$promise.then(function() {
                    toastr.success('User was removed successfully.');
                    backToList();
                }, function (error) {
                    $scope.isSaving = false;
                    resourceResponse.error(error);
                });
            });
        }
    }
}());
