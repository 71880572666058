(function () {
    'use strict';

    angular
        .module('tvl.campaign')
        .controller('CopyCampaignModalController', CopyCampaignModalController);

    CopyCampaignModalController.$inject = [
        '$scope',
        '$uibModalInstance',
        'toastr',
        'resourceResponse',
        'Campaign',
        'sourceCampaign',
        'Location',
        'tvlCampaignUtils',
        'oldCampaignLocations',
        'tvlDateUtils',
        'tvlConstantUtils',
        'Placement'
    ];

    /* @ngInject */
    function CopyCampaignModalController(
        $scope,
        $uibModalInstance,
        toastr,
        resourceResponse,
        Campaign,
        sourceCampaign,
        Location,
        tvlCampaignUtils,
        oldCampaignLocations,
        tvlDateUtils,
        tvlConstantUtils,
        Placement
) {
        var avm = this;
        avm.availableLocations = [];
        avm.locationTypeSelected = 'country';

        $scope.avm = avm;
        $scope.sourceCampaign = sourceCampaign;
        $scope.oldCampaignLocations = oldCampaignLocations;
        $scope.cancel = cancel;
        $scope.copyCampaign = copyCampaign;
        $scope.loading = false;

        $scope.isFeeCampaign = isFeeCampaign;
        $scope.getSeventyPercentOfNumber = getSeventyPercentOfNumber;
        $scope.isShortTermMessageNeeded = isShortTermMessageNeeded;
        $scope.isGreaterThanFixed = isGreaterThanFixed;

        // Todo: Get this from the api to have a unique place determining the precision.
        $scope.defaultInitialBidding = (sourceCampaign.displayCurrency === 'COP') ? 3 : 0.03;

        avm.initialBiddingValues = [
            'google',
            'facebook',
        ];

        avm.fixedPrices = {
            "cpv": null,
            "cpm": null,
            "cpc": null,
            "cpa": null,
        };

        avm.isFacebookGoalClick = false;

        avm.initialBiddingValuesForInfo = {};

        $scope.scheduleOptions = {
            opens: 'right',
            locale: {
                format: "MMM DD, YYYY"
            },
            minDate: moment(),
        };

        $scope.copyModel = {
            campaignName: null,
            budget: null,
            initial_bidding: {'single_country': {} },
            schedule: {
                startDate: moment(),
                endDate: moment()
            }
        };



        $scope.searchLocations = searchLocations;
        $scope.keepTargeteableLocations = keepTargeteableLocations;
        $scope.getFilteredLocationsByType = getFilteredLocationsByType;
        $scope.checkLocationsOverlap = checkLocationsOverlap;
        $scope.onLocationSelected = onLocationSelected;
        $scope.onLocationRemoved = onLocationRemoved;
        $scope.canTargetLocation = canTargetLocation;
        $scope.isCampaignMultiNetwork = isCampaignMultiNetwork;
        $scope.campaignGoalsByCountry = calculatePlacementsGoals();
        $scope.campaignGoals = Object.values($scope.campaignGoalsByCountry)[0];

        activate();

        ////////////////

        function activate() {
            setInitialBiddingValues();
            calculateFixedPrices();
            calculateIsFacebookClickGoal();

            $scope.$watch('copyModel.budget', watchBudgetPrice, true);
        }

        function watchBudgetPrice() {
            $scope.copyForm.budget.$invalid = avm.isFacebookGoalClick && $scope.copyModel.budget < 5;
        }

        function cancel() {
            return $uibModalInstance.dismiss();
        }

        function copyCampaign(startAsPaused) {
            $scope.loading = true;

            var copyParams = {
                id: $scope.sourceCampaign.id,
                campaign_name: $scope.copyModel.campaignName,
                budget: $scope.copyModel.budget,
                initial_bidding: $scope.copyModel.initial_bidding,
                start_date: $scope.copyModel.schedule.startDate.format('YYYY-MM-DD [00:00:00]'),
                end_date: $scope.copyModel.schedule.endDate.format('YYYY-MM-DD [23:59:59]'),
                start_as_paused: startAsPaused,
                locations: _.map(sourceCampaign.locations, 'id')
            };

            return Campaign.copy(copyParams)
                .$promise
                .then(onSuccess)
                .catch(resourceResponse.error)
                .finally(onFinally);

            function onSuccess(result) {
                return $uibModalInstance.close(result.data);
            }

            function onFinally() {
                $scope.loading = false;
            }
        }

        function isRebateCampaign() {
            return sourceCampaign.rebate !== null;
        }

        function searchLocations(searchTerm) {
            avm.locationTypeSelected = 'country';

            var params = {
                'name': searchTerm,
                'types[]':avm.locationTypeSelected
            };

            return Location.get(params).$promise
                .then(onGetLocationsSuccess)
                .catch(onGetLocationsError);

            function onGetLocationsSuccess(data) {
                var locations = _.toArray(data.items);

                if (null !== avm.locationTypeSelected) {
                    locations = getFilteredLocationsByType(locations, avm.locationTypeSelected);
                }
                locations = keepTargeteableLocations(locations);

                avm.availableLocations = locations;
            }

            function onGetLocationsError(reason) {
                toastr.error('Our apologies, we have been unable to retrieve locations for you. Please, try again in a few moments.');
            }
        }

        function keepTargeteableLocations(locations) {
            var targeteableLocations = [];

            for (var key in locations) {
                var location = locations[key];

                if (canTargetLocation(location)) {
                    targeteableLocations.push(location)
                }
            }

            return targeteableLocations;
        }

        function getFilteredLocationsByType(locations, type) {
            var filteredLocations = [];

            for (var key in locations) {
                var location = locations[key];

                if (type === location.type) {
                    filteredLocations.push(location);
                }
            }

            return filteredLocations;
        }

        /**
         * Check locations overlap.
         */
        function checkLocationsOverlap() {
            avm.locationTypeSelected = 'country';
            avm.availableLocations = getFilteredLocationsByType(avm.availableLocations, avm.locationTypeSelected);
        }

        function onLocationSelected(item, model) {
            checkLocationsOverlap();
        }

        function onLocationRemoved(item, model) {
            checkLocationsOverlap();
        }

        function canTargetLocation(location) {
            if (location === undefined) {
                return false;
            }

            var network = location.network;
            return tvlCampaignUtils.appliesToNetwork(network, sourceCampaign);
        }

        function isGreaterThanFixed(goal, country) {
            // this is not needed if fee campaign
            if (isFeeCampaign()) {
                return false;
            }

            if (!$scope.copyModel.initial_bidding.hasOwnProperty(country)) {
                return false;
            }

            let initialBidding = $scope.copyModel.initial_bidding[country][goal];

            if (!initialBidding || initialBidding === 0) {
                return false;
            }

            return initialBidding > avm.fixedPrices[goal];
        }

        function calculateIsFacebookClickGoal() {
            $scope.sourceCampaign.placements.forEach(function(placement, index, arr) {
                var ad = placement.adGroups[0].ads[0];
                if (placement.network === 'facebook' && ad.goal === 'clicks') {
                    avm.isFacebookGoalClick = true;
                }
            });
        }

        function calculateFixedPrices(){
            $scope.sourceCampaign.placements.forEach(function(currentValue, index, arr) {
                if (currentValue.fixedCpv !== null) {
                    avm.fixedPrices['cpv'] = currentValue.fixedCpv;
                }

                if (currentValue.fixedCpm !== null) {
                    avm.fixedPrices['cpm'] = currentValue.fixedCpm;
                }

                if (currentValue.fixedCpc !== null) {
                    avm.fixedPrices['cpc'] = currentValue.fixedCpc;
                }

                if (currentValue.fixedCpa !== null) {
                    avm.fixedPrices['cpa'] = currentValue.fixedCpa;
                }
            });
        }

        function isCampaignMultiNetwork() {
            // this is a hack to disable the autofill of chrome
            if (document.querySelector('#searchLocationOnModal > div.ui-select-container input.ui-select-search')){
                document.querySelector('#searchLocationOnModal > div.ui-select-container input.ui-select-search').autocomplete = 'no-autocomplete';
            }

            var locationNetworks = _.uniq(_.map(oldCampaignLocations, 'network'));

            return locationNetworks.length > 1;
        }

        function calculatePlacementsGoals() {
            let goalsByCountry = {};

            $scope.sourceCampaign.placements.forEach(function(currentValue, index, arr) {
                let country = currentValue.country.toLowerCase();

                let biddingCpa = currentValue.biddingMaxCpa;
                let biddingCpc = currentValue.biddingMaxCpc;
                let biddingCpm = currentValue.biddingMaxCpm;
                let biddingCpv = currentValue.biddingMaxCpv;

                if(typeof goalsByCountry[country]  ===  "undefined") {
                    goalsByCountry[country] = [];
                }

                if (biddingCpa !== null && !goalsByCountry[country].includes('cpa')) {
                    goalsByCountry[country].push('cpa');
                }

                if (biddingCpc !== null && !goalsByCountry[country].includes('cpc')) {
                    goalsByCountry[country].push('cpc');
                }

                if (biddingCpm !== null && !goalsByCountry[country].includes('cpm')) {
                    goalsByCountry[country].push('cpm');
                }

                if (biddingCpv !== null && !goalsByCountry[country].includes('cpv')) {
                    goalsByCountry[country].push('cpv');
                }

            });

            return goalsByCountry;
        }

        function calculateAction(biddingStrategyType, goal) {
            if (biddingStrategyType === tvlConstantUtils.biddingStrategyTypes.MANUAL_CPM) {
                return 'cpm';
            }

            if (biddingStrategyType === tvlConstantUtils.biddingStrategyTypes.MANUAL_CPC || biddingStrategyType === tvlConstantUtils.biddingStrategyTypes.TARGET_SPEND) {
                return 'cpc';
            }

            if (biddingStrategyType === tvlConstantUtils.biddingStrategyTypes.TARGET_CPA) {
                return 'cpa';
            }

            if (biddingStrategyType === tvlConstantUtils.biddingStrategyTypes.MANUAL_CPV) {
                return 'cpv';
            }

            return normalizeAction(goal);
        }

        function setInitialBiddingValues() {
            for (let placementIndex in sourceCampaign.placements) {
                let placement = sourceCampaign.placements[placementIndex];
                let ad = placement.adGroups[0].ads[0];

                let network = placement.network;
                let country = placement.country;
                let action = calculateAction(placement.biddingStrategyType, ad.goal);
                let format = ad.format;

                updateInitialBiddingValue(network, country, action, format);
            }
        }

        function updateInitialBiddingValue(network, country, action, format) {
            action = normalizeAction(action);

            let params = {
                'network': network,
                'country': country,
                'action': action,
                'format': format,
                'customerAccountId': sourceCampaign.customerAccount.id,
                'campaignName': sourceCampaign.name,
                'isFee': (sourceCampaign.fee !== null),
            };

            Placement.getInitialBidding(params).$promise.then(function (response) {
                network = normalizeNetwork(response.network);
                country = response.country.toLowerCase();
                action = normalizeAction(response.action);

                initInitialBiddingValues(network, country, action, response.bidding);
            });
        }

        function initInitialBiddingValues(network, country, action, bidding) {
            if ($scope.copyModel.initial_bidding.hasOwnProperty(country) === false) {
                $scope.copyModel.initial_bidding[country] = {};
            }

            $scope.copyModel.initial_bidding[country][action] = parseFloat(bidding);
        }

        function normalizeNetwork (network) {
            if (network === 'adwords') {
                return 'youtube';
            }

            return network;
        }

        function normalizeAction(action) {
            if (action === 'views') {
                return 'cpv';
            } else if (action === 'impressions') {
                return 'cpm';
            } else if (action === 'clicks') {
                return 'cpc';
            } else if (action === 'action') {
                return 'cpa';
            }
            return action;
        }

        // returns true if campaign duration is 7 days or less
        // and only if is Rebate
        function isShortDurationCampaign() {
            if (!isRebateCampaign()) {
                return false;
            }


            let startDate = $scope.copyModel.schedule.startDate;
            let endDate = $scope.copyModel.schedule.endDate;

            let differenceInDays = tvlDateUtils.countDaysInDatesRange(startDate, endDate);

            return differenceInDays <= 7;
        }

        // this message appears only on rebate campaigns
        function isShortTermMessageNeeded(goal, country) {
            if (isShortDurationCampaign() === false) {
                return false;
            }

            if (!isRebateCampaign()) {
                return false;
            }

            if (!$scope.copyModel.initial_bidding.hasOwnProperty(country)) {
                return false;
            }

            let initialBidding = $scope.copyModel.initial_bidding[country][goal];

            let seventyPercent = getSeventyPercentOfNumber(avm.fixedPrices[goal]);

            return initialBidding !== seventyPercent;
        }

        function getSeventyPercentOfNumber(number) {
            // avoids NaN to be returned
            if (!number) {
                return 0;
            }

            // get the 70% of number
            let percentage = (number / 100) * 70;

            // round the number to 2 decimals
            return Math.round((percentage + Number.EPSILON) * 100) / 100;
        }

        function isFeeCampaign() {
            return sourceCampaign.fee !== null;
        }
    }
})();
