(function() {
    'use strict';

    angular
        .module('tvl.campaign')
        .directive('tvlContentSelectorList', tvlContentSelectorList);

    tvlContentSelectorList.$inject = ['tvlSweetAlert'];

    /* @ngInject */
    function tvlContentSelectorList(tvlSweetAlert) {
        var directive = {
            link: link,
            restrict: 'EA',
            templateUrl: 'campaign/content-selector-list.html',
            scope: {
                items: '=',
                readonly: '=?',
            }
        };

        return directive;

        function link(scope, element, attrs) {
            scope.getMediaContent = getMediaContent;
            scope.getUrlContent = getUrlContent;
            scope.getListContent = getListContent;
            scope.removeContent = removeContent;
            scope.showRemoveMediaContentsModal = showRemoveMediaContentsModal;
            scope.showRemoveUrlContentsModal = showRemoveUrlContentsModal;
            scope.showRemoveListContentsModal = showRemoveListContentsModal;

            activate();

            function activate() {
                if (scope.readonly === undefined || scope.readonly === false || scope.readonly === 'false') {
                    scope.readonly = false;
                } else {
                    scope.readonly = true;
                }
            }

            function isMedia(content) {
                return ['video', 'channel'].indexOf(content.type) >= 0;
            }

            function isUrl(content) {
                return content.type === 'url';
            }

            function isList(content) {
                return content.type === 'list';
            }

            function getMediaContent() {
                return _.filter(scope.items, isMedia);
            }

            function getUrlContent() {
                return _.filter(scope.items, isUrl);
            }

            function getListContent() {
                return _.filter(scope.items, isList);
            }

            function removeContent(item) {
                var index = scope.items.indexOf(item);
                if (index > -1) {
                    scope.items.splice(index, 1);
                }
            }

            /**
             * Remove the media content.
             */
            function removeAllMediaContents() {
                _.remove(scope.items, isMedia);
            }

            /**
             * Remove the URL content.
             */
            function removeAllUrlContents() {
                _.remove(scope.items, isUrl);
            }

            /**
             * Remove the list content.
             */
            function removeAllListContents() {
                _.remove(scope.items, isList);
            }

            /**
             * Shows the remove all the URLs confirmation modal and removes the selected content if confirmed
             *
             * @return {Promise}
             */
            function showRemoveUrlContentsModal() {
                var title = 'Remove all URLs?';
                var msg = 'Are you sure you want to remove all the URLs of this campaign?';
                return tvlSweetAlert
                    .confirm(title, msg, 'Yes, remove all', 'No')
                    .then(function(){
                        removeAllUrlContents();
                    });
            }

            /**
             * Shows the remove all the media items confirmation modal and removes the selected content if confirmed
             *
             * @return {Promise}
             */
            function showRemoveMediaContentsModal() {
                var title = 'Remove all media items?';
                var msg = 'Are you sure you want to remove all the media items of this campaign?';
                return tvlSweetAlert
                    .confirm(title, msg, 'Yes, remove all', 'No')
                    .then(function(){
                        removeAllMediaContents();
                    });
            }

            /**
             * Shows the remove all list items confirmation modal and removes the selected content if confirmed
             *
             * @return {Promise}
             */
            function showRemoveListContentsModal() {
                var title = 'Remove all lists?';
                var msg = 'Are you sure you want to remove all lists from this campaign?';
                return tvlSweetAlert
                    .confirm(title, msg, 'Yes, remove all', 'No')
                    .then(function(){
                        removeAllListContents();
                    });
            }
        }
    }
})();
