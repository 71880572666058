(function() {
    'use strict';

    angular
        .module('tvl.administration')
        .controller('AuditCompanyModalController', AuditCompanyModalController);

    AuditCompanyModalController.$inject = [
        '$scope',
        '$uibModalInstance',
        'Company',
        'company'
    ];

    /** @ngInject */
    function AuditCompanyModalController(
        $scope,
        $uibModalInstance,
        Company,
        company
    ) {
        var vm = this;

        vm.data = null;
        vm.resource = Company;
        vm.resourceName ='Company';
        vm.company = company;
        vm.ui = {
            loadingChangelog: true,
        };
        vm.cancel = cancel;

        vm.unregisterCurrentPageWatcher = $scope.$watch(
            'modal.currentPage',
            getCompanyChangelog
        );

        vm.currentPage = 1;

        $scope.$on('$destroy', onDestroy);

        function onDestroy() {
            vm.unregisterCurrentPageWatcher();
        }

        function cancel() {
            return $uibModalInstance.dismiss();
        }

        function getCompanyChangelog(page) {

            vm.ui.loadingChangelog = true;

            var params = {
                id: vm.company.id,
                page: page ? page : vm.currentPage
            };

            return Company.getChangelog(params)
                .$promise
                .then(onGetCompanyChangelogSuccess)
                .finally(onGetCompanyChangelogFinally);

            function onGetCompanyChangelogSuccess(changelog) {
                vm.data = changelog;
            }

            function onGetCompanyChangelogFinally() {
                vm.ui.loadingChangelog = false;
            }
        }
    }
}());
