(function() {
    'use strict';

    angular
        .module('tvl.administration')
        .controller('ExchangeRateListController', ExchangeRateListController);

    ExchangeRateListController.$inject = [
        '$scope',
        '$state',
        'toastr',
        'tvlSession',
        'ExchangeRate',
        'exchangeRates',
        'tvlCurrencies'
    ];

    /* @ngInject */
    function ExchangeRateListController(
        $scope,
        $state,
        toastr,
        tvlSession,
        ExchangeRate,
        exchangeRates,
        tvlCurrencies) {
        var vm = this;
        vm.exchangeRates = {items: []};
        vm.sessionInformation = {
            roles: [],
            account: {
                type: null
            }
        };
        vm.ui = {
            gettingExchangeRates: false,
            datepickerOpen: false
        };
        vm.currencies = tvlCurrencies.getCurrencies();
        vm.currencyOptions = [];
        vm.onAfterExchangeRateChange = onAfterExchangeRateChange;
        vm.addNewExchangeRate = addNewExchangeRate;
        vm.createNewExchangeRate = createNewExchangeRate;
        vm.cancelNewExchangeRateCreation = cancelNewExchangeRateCreation;
        vm.openDatepicker = openDatepicker;
        vm.validForm = validForm;
        vm.isUserAdminOrFinance = isUserAdminOrFinance;
        vm.creating = false;

        activate();

        ////////////////

        function activate() {
            // get session information
            getSessionInfo();
            clearNewExchangeRateForm();
            vm.currencyOptions = generateCurrencyOptions(vm.currencies);
            // process exchangeRates to show human readable rates
            vm.exchangeRates = exchangeRates;
        }

        /**
         * Retrieve exchange rates from the API.
         *
         * @return {Promise}
         */
        function getExchangeRates() {
            var params = {};

            vm.ui.gettingExchangeRates = true;
            return ExchangeRate.get(params)
                .$promise
                .then(onGetExchangeRatesSuccess)
                .finally(onGetExchangeRatesFinally);

            function onGetExchangeRatesSuccess(exchangeRates) {
                vm.exchangeRates = exchangeRates;

                // update query params
                $state.transitionTo(
                    'admin.exchangeRates',
                    {},
                    {notify: false}
                );
            }

            function onGetExchangeRatesFinally() {
                vm.ui.gettingExchangeRates = false;
            }
        }

        function getSessionInfo() {
            tvlSession.getSession().then(onGetSessionSuccess);

            function onGetSessionSuccess(data) {
                vm.sessionInformation = data;
            }
        }

        /**
         * Check if user is admin
         *
         * @returns {boolean}
         */
        function isUserAdminOrFinance() {
            if (
                vm.sessionInformation.roles.indexOf('ROLE_ADMIN') !== -1 ||
                vm.sessionInformation.roles.indexOf('ROLE_FINANCE') !== -1
            ) {
                return true;
            }
            return false;
        }

        function onAfterExchangeRateChange(exchangeRate, idx) {
            var data = [];
            data['id'] = exchangeRate.id;
            data['rate'] = exchangeRate.rate;
            var exchangeRate = new ExchangeRate(data);
            (exchangeRate.$save())
                .then(onSuccess)
                .catch(onError)
                .finally(onFinally);

            function onSuccess(exchangeRate) {
                vm.exchangeRates.items[idx] = exchangeRate;
                return exchangeRate;
            }

            function onError() {
                toastr.error('Our apologies! We have been unable to update this exchange rate. Please, try again in a few minutes.');
            }

            function onFinally() {
                getExchangeRates();
            }

        }

        function addNewExchangeRate() {
            vm.creating = true;
        }

        function clearNewExchangeRateForm() {
            vm.newExchangeRateForm = {
                originCurrency: null,
                destinationCurrency: null,
                rate: null,
                effectiveStartDate: new Date()
            };
        }

        function generateCurrencyOptions(currencies) {
            var result = [];
            for (var i in currencies) {
                result.push(currencies[i]);
            }
            return result;
        }

        function createNewExchangeRate() {

            var data = {
                originCurrency: vm.newExchangeRateForm.originCurrency.code,
                destinationCurrency: vm.newExchangeRateForm.destinationCurrency.code,
                rate: vm.newExchangeRateForm.rate,
                effectiveStartDate: moment(vm.newExchangeRateForm.effectiveStartDate).format('YYYY-MM-DD')
            };

            var exchangeRate = new ExchangeRate(data);
            (exchangeRate.$save())
                .then(onSuccess)
                .catch(onError)
                .finally(onFinally);

            function onSuccess(exchangeRate) {
                toastr.success('Congrats! A new exchange rate has been created.');
            }

            function onError() {
                toastr.error('Our apologies! We have been unable to save this exchange rate. Please, try again in a few minutes.');
            }

            function onFinally() {
                clearNewExchangeRateForm();
                vm.creating = false;
                getExchangeRates();
            }
        }

        function validForm() {
            return vm.newExchangeRateForm.rate > 0
                && vm.newExchangeRateForm.originCurrency
                && vm.newExchangeRateForm.destinationCurrency
                && vm.newExchangeRateForm.effectiveStartDate;
        }

        function cancelNewExchangeRateCreation() {
            clearNewExchangeRateForm();
            vm.creating = false;
        }

        function openDatepicker() {
            vm.ui.datepickerOpen = true;
        }
    }
})();
