const moment = require('moment');

(function () {
    'use strict';

    /**
     * ngAnimate is used by toastr
     * ngSanitize is used by ui.select
     * P.S. Make sure to add libraries to the template as well.
     */
    angular
        .module('tvl.user', [
            'ngAnimate',
            'ngSanitize',
            'ui.router',
            'ui.select',
            'toastr',
            'angular-loading-bar',
            'ngFileUpload',
            'tvl.common'
        ])
        .constant('moment', moment)
        .config(configure);

    configure.$inject = [
        '$locationProvider',
        '$urlRouterProvider',
        '$stateProvider',
        '$httpProvider',
        'cfpLoadingBarProvider'
    ];

    /* @ngInject */
    function configure(
        $locationProvider,
        $urlRouterProvider,
        $stateProvider,
        $httpProvider,
        cfpLoadingBarProvider
    ) {
        $locationProvider.html5Mode(true);
        cfpLoadingBarProvider.includeSpinner = false;

        $stateProvider.state({
            name: 'user',
            abstract: true,
            templateUrl: 'base.html',
            resolve: {
                session: function (tvlSession) {
                    return tvlSession.getSession().$promise;
                }
            }
        });
        $stateProvider.state({
            name: 'user.settings',
            url: '/user/settings',
            controller: 'UserSettingsController as vm',
            templateUrl: 'user/settings.html',
            resolve: {
            loggedUser: function (tvlSession) {
                return tvlSession.getSession();
            }
        }
        });
    }

})();
