(function() {
    'use strict';

    angular
        .module('tvl.administration')
        .controller('AdminDashboardController', AdminDashboardController);

    AdminDashboardController.$inject = [
        '$scope',
        '$state',
        'toastr',
        'Company',
        'Account',
        'User',
        'tvlSession'
    ];

    /* @ngInject */
    function AdminDashboardController(
        $scope,
        $state,
        toastr,
        Company,
        Account,
        User,
        tvlSession
    ) {
        var vm = this;

        vm.user = null;

        vm.userDoughnutChart = {
            loading: true,
            colors: [],
            labels: [],
            data: [],
            legend: []
        };

        vm.companyDoughnutChart = {
            loading: true,
            colors: [],
            labels: [],
            data: [],
            legend: []
        };

        vm.accountDoughnutChart = {
            loading: true,
            colors: [],
            labels: [],
            data: [],
            legend: []
        };

        vm.doughnutCommonChart = {
            options: {
                responsive: true,
                animation: {
                    easing: 'easeOutBounce'
                }
            }
        };

        activate();

        ////////////////

        function activate() {
            $scope.$on('$destroy', onDestroy);


            tvlSession.getSession()
                .then(function(user) {
                    vm.user = user;
                    fillCharts();
                });
        }

        /**
         * Callback to be invoked when this controller is being destroyed.
         */
        function onDestroy() {
        }

        function fillCharts() {
            if (vm.user.hasAccessToCompaniesSection) {
                fillCompanyDoughnutChart();
            }
            fillUserDoughnutChart();
            fillAccountDoughnutChart();
        }

        function fillUserDoughnutChart() {
            User.summary().$promise.then(function(response) {
                var data = response.data;
                vm.userDoughnutChart.loading = false;

                vm.userDoughnutChart.data.push(data.enabled);
                vm.userDoughnutChart.colors.push('#46BFBD');
                vm.userDoughnutChart.labels.push('Enabled');
                vm.userDoughnutChart.legend.push({key: 'Enabled', value: data.enabled});

                vm.userDoughnutChart.data.push(data.disabled);
                vm.userDoughnutChart.colors.push('#F7464A');
                vm.userDoughnutChart.labels.push('Disabled');
                vm.userDoughnutChart.legend.push({key: 'Disabled', value: data.disabled});
            }).catch(function() {
                toastr.error('Our apologies, we have been unable to recover users summary. Please, try again later.');
            });
        }

        function fillCompanyDoughnutChart() {
            Company.summary().$promise.then(function(response) {
                var data = response.data;
                vm.companyDoughnutChart.loading = false;

                vm.companyDoughnutChart.data.push(data.enabled);
                vm.companyDoughnutChart.colors.push('#46BFBD');
                vm.companyDoughnutChart.labels.push('Enabled');
                vm.companyDoughnutChart.legend.push({key: 'Enabled', value: data.enabled});

                vm.companyDoughnutChart.data.push(data.disabled);
                vm.companyDoughnutChart.colors.push('#F7464A');
                vm.companyDoughnutChart.labels.push('Disabled');
                vm.companyDoughnutChart.legend.push({key: 'Disabled', value: data.disabled});

            }).catch(function() {
                toastr.error('Our apologies, we have been unable to recover companies summary. Please, try again later.');
            });
        }

        function fillAccountDoughnutChart() {
            Account.summary().$promise.then(function(response) {
                var data = response.data;
                vm.accountDoughnutChart.loading = false;

                vm.accountDoughnutChart.data.push(data.enabled);
                vm.accountDoughnutChart.colors.push('#46BFBD');
                vm.accountDoughnutChart.labels.push('Enabled');
                vm.accountDoughnutChart.legend.push({key: 'Enabled', value: data.enabled});

                vm.accountDoughnutChart.data.push(data.disabled);
                vm.accountDoughnutChart.colors.push('#F7464A');
                vm.accountDoughnutChart.labels.push('Disabled');
                vm.accountDoughnutChart.legend.push({key: 'Disabled', value: data.disabled});
            }).catch(function() {
                toastr.error('Our apologies, we have been unable to recover accounts summary. Please, try again later.');
            });
        }
    }
})();
