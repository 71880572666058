const moment = require('moment');

(function() {
    'use strict';

    angular
        .module('tvl.user')
        .controller('UserSettingsController', UserSettingsController);

    UserSettingsController.$inject = [
        '$scope',
        '$window',
        'toastr',
        'tvlSession',
        'tvlSweetAlert',
        'UserSettings',
        'Constants',
        'Upload',
        'resourceResponse',
        'loggedUser'
    ];

    function UserSettingsController(
        $scope,
        $window,
        toastr,
        tvlSession,
        tvlSweetAlert,
        UserSettings,
        Constants,
        Upload,
        resourceResponse,
        loggedUser
    ) {
        var vm = this;

        vm.reportFormats = [
            'excel',
            // 'powerpoint',
        ];
        vm.timeZones = [];
        vm.hours = [
            '00:00', '00:30', '01:00', '01:30', '02:00', '02:30', '03:00', '03:30',
            '04:00', '04:30', '05:00', '05:30', '06:00', '06:30', '07:00', '07:30',
            '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30',
            '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30',
            '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30',
            '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30',
        ];

        vm.displayTime = '00:00';
        vm.maxFileSize = 500000; //bytes decimal ->500KB
        vm.selectedTimeZone = null;

        vm.ui = {
            saving: false,
        };
        vm.loggedUser = {
            'id': '',
            'name': '',
            'email': '',
            'photo': null,
            'newsletterFrequency': {
                'campaignReport': false,
                'formatReport': ['excel'],
                'timezone': 'UTC',
                'timeSlot': '00:00',
                'endOfCampaignReport': false
            },
        };
        vm.userIsClient = true;
        vm.userForm = {
            'password': null,
            'confirmPassword': null,
        };

        vm.passwordMinLength = 8;

        vm.updateReportTime = updateReportTime;
        vm.onUpdateTimeZone = onUpdateTimeZone;
        vm.saveUser = saveUser;
        vm.askForAccountDeletionConfirmation = askForAccountDeletionConfirmation;
        vm.onCampaignReportFlagChange = onCampaignReportFlagChange;
        vm.onPhotoChange = onPhotoChange;

        /**
         * Controller's main function.
         */
        function activate() {
            vm.loggedUser = loggedUser;
            vm.userIsClient = vm.loggedUser.isClient();
            getTimezones();

            $scope.$watchGroup(['vm.userForm.password','vm.userForm.confirmPassword'], function() {
                validateConfirmPassword();
            });
        }

        function validateConfirmPassword() {
            // values
            var val1 = vm.userForm.password;
            var val2 = vm.userForm.confirmPassword;

            if(val1 !== null && val2 !== null && val1 === val2) {
                $scope.changePassword.confirmPassword.$invalid = false;

            } else {
                $scope.changePassword.confirmPassword.$invalid = true;
                $scope.changePassword.$invalid = true;
            }
        }

        /**
         * Get timezone choices.
         */
        function getTimezones() {
            Constants.getTimezones().$promise.then(onGetTimezonesSuccess);

            function onGetTimezonesSuccess(data) {
                vm.timeZones = data;

                // Set initial timezone.
                if (vm.loggedUser.newsletterFrequency.timezone) {
                    vm.timeZones.forEach(function(item){
                        if (vm.loggedUser.newsletterFrequency.timezone === item.id) {
                            vm.selectedTimeZone = item;
                        }
                    });
                } else {
                    vm.selectedTimeZone = vm.timeZones[vm.timeZones.length - 1];
                }

                // Set initial display time.
                vm.displayTime = moment(vm.loggedUser.newsletterFrequency.timeSlot, 'HH:mm')
                    .subtract(vm.selectedTimeZone.offset * -1, 'hours')
                    .format('HH:mm');
            }
        }

        /**
         * Updates report time by display time and current time-zone offset.
         */
        function updateReportTime() {
            vm.loggedUser.newsletterFrequency.timeSlot = moment(vm.displayTime, 'HH:mm')
                .subtract(vm.selectedTimeZone.offset, 'hours')
                .format('HH:mm');
        }

        /**
         * Updates time-zone value.
         */
        function onUpdateTimeZone() {
            vm.loggedUser.newsletterFrequency.timezone = vm.selectedTimeZone.id;
            vm.updateReportTime();
        }

        /**
         * Send PUT to user's endpoint.
         * @returns {*}
         */
        function saveUser() {
            vm.ui.saving = true;

            var settings = new UserSettings({
                'id': vm.loggedUser.id,
                'name': vm.loggedUser.name,
                'email': vm.loggedUser.email,
                'photo': vm.loggedUser.photo,
                'password': vm.userForm.password,
                'newsletterFrequency': vm.loggedUser.newsletterFrequency,
            });

            return settings.$patch()
                .then(onSaveSuccess, onSaveError)
                .finally(onSaveFinally);

            function onSaveSuccess(data) {
                toastr.success('User ' + settings.name + ' has been successfully saved.');
                vm.loggedUser = data;
                vm.userIsClient = !!(vm.loggedUser.isClient());
                clearPasswordInput();
                setFormPristine();
            }

            function onSaveError(err) {
                if (err.data && err.data.errors) {
                    toastr.error(err.data.errors[0].message);
                } else {
                    toastr.error('Our apologies, we have been unable to save this user. Please, try again later.');
                }
            }

            function onSaveFinally() {
                vm.ui.saving = false;
                tvlSession.refresh();
            }
        }

        function onPhotoChange($files, $file) {

            if (null !== $file) {
                if ($file.size <= vm.maxFileSize) {
                    Upload.base64DataUrl($files).then(onUploadFileSuccess);
                } else {
                    $scope.personalDetailsForm.$invalid = true;
                }
            } else {
                vm.loggedUser.photo = null;
            }

            function onUploadFileSuccess(urls) {
                vm.loggedUser.photo = urls[0];
                $scope.personalDetailsForm.$invalid = false;
            }
        }

        function clearPasswordInput() {
            vm.userForm.password = null;
            vm.userForm.confirmPassword = null;
        }

        function setFormPristine() {
            $scope.changePassword.$setPristine();
            $scope.personalDetailsForm.$setPristine();
            $scope.newsletterFrequencyForm.$setPristine();
        }

        /**
         * Show the account deletion confirmation dialog and if the user confirms,
         * disable the account.
         */
        function askForAccountDeletionConfirmation() {
            tvlSweetAlert.confirm(
                'Are you sure?',
                'Deleting your account is an irreversible action. If you click OK, there is no turning back!',
                {confirmButtonText: 'Yes, delete my account'}
            ).then(disableAccount);

            /**
             * Disable the user's account.
             */
            function disableAccount() {
                UserSettings.delete().$promise
                    .then(function () {
                        toastr.success('Your user has been deleted.');
                        setTimeout(function () {
                            tvlSession.refresh();// this request will trigger the redirect to login.
                        }, 1000);
                    }, resourceResponse.error);
            }
        }

        /**
         * Before hiding report's sub-form, make sure that there is not any invalid field inside.
         */
        function onCampaignReportFlagChange() {
            if (!vm.loggedUser.newsletterFrequency.campaignReport) {
                if (!vm.loggedUser.newsletterFrequency.formatReport || !(vm.loggedUser.newsletterFrequency.formatReport.length > 0)) {
                    vm.loggedUser.newsletterFrequency.formatReport = ['excel'];
                }
            }
        }

        activate();
    }

})();
