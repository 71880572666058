(function() {
    'use strict';

    angular
        .module('tvl.login')
        .controller('ResetPasswordController', ResetPasswordController);

    ResetPasswordController.$inject = [
        '$rootScope',
        '$scope',
        '$state',
        'ResetPassword',
        'resourceResponse'
    ];

    function ResetPasswordController(
        $rootScope,
        $scope,
        $state,
        ResetPassword,
        resourceResponse
    ) {
        $rootScope.bodyClass = 'login';
        $scope.model = {username: null};

        $scope.reset = function() {
            ResetPassword.reset({
                username: $scope.model.username
            }).$promise.then(function() {
                $state.go('login.check-email');
            }, resourceResponse.error);
        };

    }
})();
