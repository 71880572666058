(function() {
    'use strict';

    angular
        .module('tvl.administration')
        .controller('CategoriesController', CategoriesController)
        .controller('CreateCategoryModalController', CreateCategoryModalController)
        .controller('CategoryRemovalConfirmationModalController', CategoryRemovalConfirmationModalController);

    CategoriesController.$inject = [
        '$scope',
        '$uibModal',
        '$window',
        'toastr',
        'Category',
        'loggedUser'
    ];

    /* @ngInject */
    function CategoriesController(
        $scope,
        $uibModal,
        $window,
        toastr,
        Category,
        loggedUser
    ) {
        var vm = this;

        vm.loggedUser = loggedUser;
        vm.parents = null;
        vm.editForm = {};
        vm.searchTerm = null;
        vm.selectedCategory = null;
        vm.ui = {
            saving: false,
            showRoleOptions: false,
            updating: false
        };
        vm.unregisterCurrentPageWatcher = null;
        vm.clearSelection = clearSelection;
        vm.createCategory = createCategory;
        vm.getCategories = getCategories;
        vm.searchCategories = searchCategories;
        vm.selectCategory = selectCategory;
        vm.saveCategory = saveCategory;
        vm.refreshParentList = refreshParentList;
        vm.removeCategory = removeCategory;

        vm.dynamicPopover = {
            templateUrl: 'myPopoverTemplate.html',
            title: 'Options'
        };

        vm.BrandSafetyCategoriesPermissions = {
            create: null,
            update: null,
            delete: null
        };

        activate();

        function activate() {
            getCategories(1, null);
            vm.unregisterCurrentPageWatcher = $scope.$watch(
                'vm.users.currentPage',
                onCurrentPageChange
            );
            $scope.$on('$destroy', onDestroy);
            checkUserPermissions();
        }

        function clearSelection() {
            vm.selectedCategory = null;
            vm.editForm = {};
        }

        function checkUserPermissions() {

            var userCanCreateBrandSafetyCategory = vm.loggedUser.hasPermission('ChannelCategory.create');
            var userCanUpdateBrandSafetyCategory = vm.loggedUser.hasPermission('ChannelCategory.update');
            var userCanDeleteBrandSafetyCategory = vm.loggedUser.hasPermission('ChannelCategory.delete');

            if ( true === userCanCreateBrandSafetyCategory) {
                vm.BrandSafetyCategoriesPermissions.create = true;
            }

            if ( true === userCanUpdateBrandSafetyCategory) {
                vm.BrandSafetyCategoriesPermissions.update = true;
            }

            if ( true === userCanDeleteBrandSafetyCategory) {
                vm.BrandSafetyCategoriesPermissions.delete = true;
            }
        }

        function createCategory() {

            return showCreationModal().then(onCreateSuccess);

            function onCreateSuccess(category) {
                toastr.success('Category ' + category.name + ' has been successfully created.');
                vm.searchTerm = null;
                return getCategories(1);
            }
        }

        function getCategories(page, search) {
            vm.ui.updating = true;

            var params = {
                page: page,
                term: search || null,
                itemsPerPage: 5000
            };

            return Category.get(params).$promise
                .then(onGetCategoriesSuccess, onGetCategoriesError)
                .finally(onGetCategoriesFinally);

            function onGetCategoriesSuccess(categories) {
                vm.cat = [];
                vm.categories = categories;
                var newCategory;

                for (var i = 0; i < vm.categories.items.length; i++) {
                    if (vm.categories.items[i].parent === null) {
                        var existIndex = vm.cat.findIndex(function(x) {
                            return x.id === vm.categories.items[i].id;
                        });

                        if (existIndex === -1) {
                            newCategory = vm.categories.items[i];
                            newCategory.parent = {};
                            newCategory.parent.id = 0;
                            newCategory.childs = [];
                            vm.cat.push(newCategory);
                        }
                    } else {
                        var parentIndex = vm.cat.findIndex(function(x) {
                            return x.id === vm.categories.items[i].parent.id;
                        });

                        if (parentIndex === -1) {
                            newCategory = vm.categories.items[i].parent;
                            newCategory.parent = {};
                            newCategory.parent.id = 0;
                            newCategory.childs = [];
                            parentIndex = vm.cat.push(newCategory) - 1;
                        }

                        vm.cat[parentIndex].childs.push(vm.categories.items[i]);
                    }
                }

                vm.cat.sort(function(a, b) {
                    if (a.name < b.name) { return -1; }
                    if (a.name > b.name) { return 1; }
                    return 0;
                });
            }

            function onGetCategoriesError(error) {
                toastr.error('Our apologies, we have been unable to recover any categories. Please, try again later.');
            }

            function onGetCategoriesFinally() {
                vm.ui.updating = false;
            }
        }

        function searchCategories(search) {
            return getCategories(1, search);
        }

        function selectCategory(category) {
            $window.scrollTo(0, 0);
            vm.selectedCategory = category;

            vm.detailsFormTitle = 'Edit ' + vm.selectedCategory.name;
            vm.editForm = {
                id: vm.selectedCategory.id,
                name: vm.selectedCategory.name,
                parent: typeof(vm.selectedCategory.childs) !== 'undefined' && vm.selectedCategory.childs.length ? null : vm.selectedCategory.parent
            };
        }

        function saveCategory() {
            vm.ui.saving = true;

            if (vm.editForm.parent) {
                vm.editForm.parent = vm.editForm.parent.id;
            }
            var category = new Category(vm.editForm);
            return category.$save()
                .then(onSaveSuccess, onSaveError)
                .then(onSaveFinally);

            function onSaveSuccess(category) {
                toastr.success('Category ' + category.name + ' has been successfully saved.');
                clearSelection();
                getCategories(vm.categories.currentPage, vm.searchTerm);
            }

            function onSaveError(error) {
                toastr.error('Our apologies, we have been unable to save this category. Please, try again later.');
                clearSelection();
            }

            function onSaveFinally() {
                vm.ui.saving = false;
            }
        }
        //
        function showCreationModal() {
            return $uibModal.open({
                controller: 'CreateCategoryModalController as modal',
                templateUrl: 'categories/create-category-modal.html',
                backdrop: 'static'
            }).result;
        }
        //
        function showRemovalConfirmationModal(category) {
            return $uibModal.open({
                controller: 'CategoryRemovalConfirmationModalController as modal',
                templateUrl: 'categories/category-removal-confirmation-modal.html',
                resolve: {
                    category: function () {
                        return category;
                    }
                }
            }).result;
        }
        //
        function deleteCategory(category) {
            return Category.delete({id: category.id}).$promise;
        }

        function refreshParentList(search) {
            var params = {
                term: search || null,
                parent: 'true'
            };
            return Category.get(params).$promise
                .then(onGetParentsSuccess, onGetParentsError);

            function onGetParentsSuccess(categories) {
                vm.parents = categories.items;
            }

            function onGetParentsError(error) {
                toastr.error('Our apologies, we have been unable to recover your categories. Please, try again later.');
            }
        }

        function removeCategory(cat) {
            var category = vm.editForm;

            if (angular.equals({}, category)) {
                category = cat;
            }

            return showRemovalConfirmationModal(category)
                .then(
                    function() {
                        deleteCategory(category)
                            .then(onDeleteSuccess, onDeleteError)
                            .finally(getCategories.bind(null, vm.categories.currentPage, vm.searchTerm))
                    },
                    cancelRemove
                );

            function onDeleteSuccess() {
                clearSelection();
                toastr.success('Category ' + category.name + ' has been successfully removed.');
            }

            function onDeleteError() {
                clearSelection();
                toastr.error('Our apologies, we have been unable to remove this category. Please, try again later.');
            }

            function cancelRemove() {
            }
        }

        function onCurrentPageChange(newVal, oldVal) {
            if (newVal !== oldVal) {
                getCategories(newVal, vm.searchTerm);
            }
        }

        function onDestroy() {
            vm.unregisterCurrentPageWatcher();
        }
    }

    CreateCategoryModalController.$inject = [
        '$scope',
        '$uibModalInstance',
        'toastr',
        'Category'
    ];

    /** @ngInject */
    function CreateCategoryModalController(
        $scope,
        $uibModalInstance,
        toastr,
        Category
    ) {
        var vm = this;
        vm.parentList = null;

        vm.createForm = {
            name: null,
            parent: null
        };

        vm.creating = false;

        vm.create = create;
        vm.cancel = cancel;
        vm.refreshParentList = refreshParentList;

        function create() {
            vm.creating = true;

            if (vm.createForm.parent) {
                vm.createForm.parent = vm.createForm.parent.id;
            }

            var category = new Category(vm.createForm);
            category.$save().then(onCreateSuccess, onCreateError);

            function onCreateSuccess(category) {
                $uibModalInstance.close(category);
            }

            function onCreateError(error) {
                toastr.error('Our apologies, we have been unable to create this category. Please, try again later.');
                vm.creating = false;
            }
        }

        function cancel() {
            return $uibModalInstance.dismiss();
        }

        function refreshParentList(searchTerm) {
            Category.get({'term': searchTerm, 'parent': 'true'})
                .$promise
                .then(function (categories) {
                    vm.parentList = categories.items;
                });
        }
    }

    CategoryRemovalConfirmationModalController.$inject = [
        '$scope',
        '$uibModalInstance',
        'category'
    ];

    /** @ngInject */
    function CategoryRemovalConfirmationModalController(
        $scope,
        $uibModalInstance,
        category
    ) {
        var vm = this;
        vm.category = category;
        vm.confirm = confirm;
        vm.cancel = cancel;

        function confirm() {
            return $uibModalInstance.close(category);
        }

        function cancel() {
            return $uibModalInstance.dismiss();
        }
    }
}());
