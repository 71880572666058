(function() {
    'use strict';

    angular
        .module('tvl.brandSafety')
        .constant('COUNT_ANIMATION_DURATION', 2000)
        .constant('MAX_LIST_RESULTS', 19900)
        .controller('ListCreatorController', ListCreatorController);

    ListCreatorController.$inject = [
        '$scope',
        '$timeout',
        '$state',
        '$window',
        'toastr',
        'Account',
        'ChannelAnalysis',
        'Location',
        'Interest',
        'ContentsList',
        'MAX_LIST_RESULTS',
        'Category',
        'COUNT_ANIMATION_DURATION',
        'languages',
        'placementLists',
        'keywordLists',
    ];

    /* @ngInject */
    function ListCreatorController(
        $scope,
        $timeout,
        $state,
        $window,
        toastr,
        Account,
        ChannelAnalysis,
        Location,
        Interest,
        ContentsList,
        MAX_LIST_RESULTS,
        Category,
        COUNT_ANIMATION_DURATION,
        languages,
        placementLists,
        keywordLists
    ) {
        var vm = this;

        $(() => {
            var offsetFromTop = 95; // 65px for the navbar, 30px for the content area padding
            var item = $('.tvl-list-form-metrics');
            var itemWidth = item.width();
            var itemTop = item.offset().top;

            $(window).scroll(() => {
                var currentScroll = $(window).scrollTop();
                if (currentScroll >= (itemTop - offsetFromTop)) {
                    item.css({position: 'fixed', top: offsetFromTop});
                    item.width(itemWidth);
                } else {
                    // reset styles
                    item.css({position: '', top: ''});
                }
            });
        });

        vm.listForm = {};
        vm.availableLocations = [];
        vm.availableInterests = [];
        vm.availableLanguages = [];
        vm.availableAlphabets = [{
            id: 'latin',
            name: 'Latin',
            character: 'a'
        }, {
            id: 'arabic',
            name: 'Arabic',
            character: 'ش'
        }, {
            id: 'cyrillic',
            name: 'Cyrillic',
            character: 'Д'
        }, {
            id: 'hebrew',
            name: 'Hebrew',
            character: 'א'
        }, {
            id: 'georgian',
            name: 'Georgian',
            character: 'მ'
        }, {
            id: 'hiragana',
            name: 'Hiragana',
            character: 'あ'
        }, {
            id: 'katakana',
            name: 'Katakana',
            character: 'ア'
        }, {
            id: 'han',
            name: 'Han',
            character: '漢'
        }, {
            id: 'syriac',
            name: 'Syrica',
            character: ''
        }, {
            id: 'mongolian',
            name: 'Mongolian',
            character: 'ᠠ'
        }, {
            id: 'thai',
            name: 'Thai',
            character: 'ฌ'
        }, {
            id: 'tibetan',
            name: 'Tibetan',
            character: 'ཏ'
        }, {
            id: 'malayam',
            name: 'Malayam',
            character: 'يو'
        }, {
            id: 'greek',
            name: 'Greek',
            character: 'β'
        }, {
            id: 'armenian',
            name: 'Armenian',
            character: 'Ա'
        }, {
            id: 'myanmar',
            name: 'Myanmar',
            character: 'တ'
        }, {
            id: 'lao',
            name: 'Lao',
            character: 'ພ'
        }, {
            id: 'ethiopic',
            name: 'Ethiopic',
            character: 'ሀ'
        }, {
            id: 'emoji',
            name: 'Emoji',
            character: '😀'
        } ];
        vm.availableBrands = [
            '3m',
            'accenture',
            'adidas',
            'adobe',
            'allianz',
            'amazon',
            'apple',
            'armani',
            'asics',
            'audi',
            'avon',
            'axa',
            'barclays',
            'blackberry',
            'bmw',
            'budweiser',
            'burberry',
            'canon',
            'carrefour',
            'cartier',
            'caterpillar',
            'cisco',
            'citi',
            'colgate',
            'danone',
            'dell',
            'disney',
            'ebay',
            'fanta',
            'ferrari',
            'ford',
            'gap',
            'gillete',
            'google',
            'gucci',
            'harley',
            'heineken',
            'heinz',
            'hermes',
            'honda',
            'hp',
            'hsbc',
            'htc',
            'huawei',
            'hyundai',
            'h&m',
            'ibm',
            'ikea',
            'intel',
            'jeep',
            'kawasaki',
            'kellogs',
            'kfc',
            'kleenex',
            'lacoste',
            'levis',
            'loreal',
            'mahou',
            'mcdonalds',
            'mercadona',
            'mercedes',
            'microsoft',
            'mtv',
            'nescafe',
            'nestle',
            'nike',
            'nintendo',
            'nisan',
            'nivea',
            'nokia',
            'opel',
            'oracle',
            'panasonic',
            'pepsi',
            'peugeot',
            'philips',
            'porsche',
            'quicksilver',
            'rayban',
            'reebok',
            'samsung',
            'santander',
            'sap',
            'sega',
            'shell',
            'siemens',
            'smirnoff',
            'sony',
            'sprite',
            'starbucks',
            'toyota',
            'ups',
            'vespa',
            'visa',
            'volkswagen',
            'xerox',
            'xiaomi',
            'yahoo',
            'zara',
            'zurich'
        ];
        vm.availablePlacementLists = placementLists.items;
        vm.availableKeywordLists = keywordLists.items;
        vm.lastSearchedLocation = null;
        vm.ageSliderOptions = {
            floor: 13,
            ceil: 65,
            hideLimitLabels: true
        };
        vm.nWhitelistedChannels = null;
        vm.nWhitelistedUserPlacements = null;
        vm.nBlacklistedUserKeywords = null;
        // these are required for the count animation
        vm.nWhitelistedChannelsPrev = null;
        vm.nWhitelistedUserPlacementsPrev = null;
        vm.nBlacklistedUserKeywordsPrev = null;
        vm.kpis = {
            cpv: null,
            cpvPrev: null, // required for the count animation
            vtr: null,
            vtrPrev: null, // required for the count animation
        };
        vm.ui = {
            switcheryOptions: {
                size: 'small',
                color: '#007aff'
            },
            creating: false,
            updatingSummary: false,
            updatingKpis: false,
        };
        vm.unregisterFns = [];
        vm.accounts = [];
        vm.MAX_LIST_RESULTS = MAX_LIST_RESULTS;

        vm.createList = createList;
        vm.searchLocations = searchLocations;
        vm.searchInterests = searchInterests;
        vm.refreshAccountList = refreshAccountList;

        // Categories / subcategories
        vm.availableCategories = [];
        vm.searchCategories = searchCategories;

        activate();

        ////////////////

        function activate() {
            initListForm();
            setUpLanguages(languages);
            vm.unregisterFns = [
                $scope.$watch('vm.listForm', onFormChange, true),
                $scope.$watch('vm.listForm.placementList', onPlacementListChange),
                $scope.$watch('vm.listForm.keywordList', onKeywordListChange),
                $scope.$watch('vm.listForm.allowExplicitNudity', onAllowExplicitNudityChange),
                $scope.$watch('vm.listForm.allowSuggestiveContent', onAllowSuggestiveContentChange),
                $scope.$watch('vm.listForm.allowAdultContent', onAllowAdultContentChange),
            ];
            $scope.$on('$destroy', onDestroy);

            refreshAccountList();
        }

        function initListForm() {
            vm.listForm = {
                minAge: 13,
                maxAge: 65,
                genders: [],
                locations: [],
                interests: [],
                verifiedOnly: false,
                minSubscribers: null,
                minLikePct: null,
                sentimentAnalysis: 'happy',
                allowExplicitNudity: false,
                nudity: false,
                graphicMaleNudity: false,
                graphicFemaleNudity: false,
                sexualActivity: false,
                partialNudity: false,
                allowSuggestiveContent: false,
                maleUnderwear: false,
                femaleUnderwear: false,
                revealingClothes: false,
                allowAdultContent: false,
                alcohol: false,
                tobacco: false,
                gambling: false,
                violence: false,
                religion: false,
                breakingNews: false,
                allowBadLanguage: false,
                languages: [],
                alphabets: [],
                userTags: [],
                placementList: null,
                keywordList: null,
                excludedBrands: [],
                maxCpv: null,
                maxCpm: null,
                minVtr: null,
                minCtr: null,
                account: ''
            };
        }

        /**
         * Convert the current filters into an object that can be used to invoke
         * the Brand Safety API.
         *
         * @return {Object}
         */
        function buildQueryParams(form) {
            var queryParams =  {
                'languagesDetected[]': form.languages,
                'alphabetsDetected[]': form.alphabets,
                'userTags[]': form.userTags,
                'ages[]': ageRangeToAges(form.minAge, form.maxAge),
                'genders[]': form.genders,
                'locations[]': form.locations,
                'interests[]': _(form.interests).map(function(i) {
                    return 'uservertical::' + i;
                }).value(),
                'subscriberCountGt': form.minSubscribers,
                'subscriberCountLt': form.maxSubscribers,
                'viewRateGt': form.minVtr,
                'likesPercentGt': form.minLikePct,
                'verified': form.verifiedOnly === true ? 1 : null
            };
            if (form.allowBadLanguage == false) {
                queryParams.videosWithBadTerms = 0;
            }
            if (form.excludedBrands) {
                angular.extend(queryParams, {'excludedBrands[]' : form.excludedBrands});
            }

            return queryParams;
        }

        function onCreateListSuccess(list) {
            toastr.success('Your list has been successfully created.');
            $state.transitionTo('brand-safety.contents-lists');
        }

        function onCreateListError(error) {
            toastr.error('Our apologies, we have been unable to create your list. Please, try again in a few minutes.');
        }

        function onCreateListFinally()  {
            vm.ui.creating = false;
        }

        function createList() {
            vm.ui.creating = true;
            var params = angular.extend({
                name: vm.listForm.name
            }, buildQueryParams(vm.listForm));
            params = angular.extend({account: vm.listForm.account}, params);
            return ContentsList.import({
                    type: 'dynamic'
                }, params)
                .$promise
                .then(onCreateListSuccess, onCreateListError)
                .finally(onCreateListFinally);
        }

        /**
         * Get the age ranges that correspond to the given min and max ages.
         *
         * @param {Number} minAge
         * @param {Number} maxAge
         * @return {Array}
         */
        function ageRangeToAges(minAge, maxAge) {
            var ranges = [{
                from: 18,
                to: 24,
                text: '18-24'
            }, {
                from: 25,
                to: 34,
                text: '25-34'
            }, {
                from: 35,
                to: 44,
                text: '35-44'
            }, {
                from: 45,
                to: 54,
                text: '45-54'
            }, {
                from: 55,
                to: 64,
                text: '55-64'
            }, {
                from: 65,
                to: null,
                text: '65+'
            }, ];

            var selectedRanges = [];
            for (var i in ranges) {
                var range = ranges[i];

                if (range.to) {
                    if (minAge <= range.to && range.from <= maxAge) {
                        selectedRanges.push(range.text);
                    }
                } else if (ranges[i].from >= minAge && ranges[i].from <= maxAge) {
                    selectedRanges.push(range.text);
                }
            }

            return selectedRanges;
        }

        function onSearchLocationsSuccess(data) {
            vm.availableLocations = data.items;
        }

        /**
         * Search for locations matching the given name.
         *
         * @param {String} name
         * @return {Promise}
         */
        function searchLocations(name) {
            if (name !== vm.lastSearchedLocation) {
                vm.lastSearchedLocation = name;
                return Location.get({
                    name: name,
                    'types[]' : 'country'
                }).$promise.then(onSearchLocationsSuccess);
            }
        }

        function onSearchInterestsSuccess(data) {
            vm.availableInterests = data.items;
        }

        /**
         * Search for interests matching the given name.
         *
         * Currently only searchs for AdWords interests as the brand safety
         * report only targets this network for the time being.
         *
         * @param {String} name
         * @return {Promise}
         */
        function searchInterests(name) {
            return Interest.get({
                    searchTerm: name,
                    network: 'adwords',
                    enabled: 1
                })
                .$promise
                .then(onSearchInterestsSuccess);
        }

        /**
         * Search for categories matching the given name.
         *
         *
         * @param {String} name
         * @return {Promise}
         */
        function searchCategories(name) {
            return Category.get({
                term: name,
                itemsPerPage: 1000
            })
                .$promise
                .then(onSearchCategoriesSuccess, onSearchCategoriesError);

            function onSearchCategoriesSuccess(data) {
                vm.availableCategories = data;
            }

            function onSearchCategoriesError(error) {
                toastr.error('Our apologies, we have been unable to recover any categories. Please, try again later.');
            }
        }

        /**
         * Map all available languages so that an array of languages such as
         * this:
         *
         * [{"code": "es", "name": "Spanish", ...}, ...]
         *
         * Becomes an object keyed by the languages' codes:
         *
         * {"es": {"name": "Spanish", ...}, ...}
         */
        function setUpLanguages(languages) {
            vm.availableLanguages = _.chain(languages)
                .map(function(i) {
                    return [i.code, i];
                })
                .fromPairs()
                .value();
        }

        function updateSummary(params) {
            ChannelAnalysis.getPlacements(params).$promise
                .then(function(data) {
                    vm.nWhitelistedChannels = data.total;
                    // this is required for the count animation
                    $timeout(function() {
                        vm.nWhitelistedChannelsPrev = data.total;
                    }, COUNT_ANIMATION_DURATION);
                })
                .catch(function(error) {
                    toastr.warning('Real-time summary metrics are currently unavailable.');
                })
                .finally(function() {
                    vm.ui.updatingSummary = false;
                });
        }

        function updateKpis(params) {
            ChannelAnalysis.getKpis(params).$promise
                .then(function(data) {
                    vm.kpis.cpv = data.cpv / 1000000 / 18.9;
                    vm.kpis.vtr = data.viewRate * 100;

                    $timeout(function() {
                        vm.kpis.cpvPrev = vm.kpis.cpv;
                    }, COUNT_ANIMATION_DURATION);
                    $timeout(function() {
                        vm.kpis.vtrPrev = vm.kpis.vtr;
                    }, COUNT_ANIMATION_DURATION);
                })
                .catch(function(error) {
                    toastr.warning('Real-time KPI metrics are currently unavailable.');
                })
                .finally(function() {
                    vm.ui.updatingKpis = false;
                });
        }

        function onFormChange(newVal, oldVal) {
            var newParams = buildQueryParams(newVal);
            var oldParams = buildQueryParams(oldVal);
            if (angular.equals(newParams, oldParams)) {
                return;
            }

            // update summary and KPI panels
            vm.ui.updatingKpis = true;
            vm.ui.updatingSummary = true;

            if (vm.summaryUpdateTimerPromise) {
                $timeout.cancel(vm.summaryUpdateTimerPromise);
            }
            vm.summaryUpdateTimerPromise = $timeout(
                function() {
                    updateSummary(newParams);
                    updateKpis(newParams);
                },
                500
            );
        }

        function onAllowExplicitNudityChange(newVal, oldVal) {
            if (newVal === false) {
                angular.extend(
                    vm.listForm, {
                        nudity: false,
                        graphicMaleNudity: false,
                        graphicFemaleNudity: false,
                        sexualActivity: false,
                        partialNudity: false
                    }
                );
            }
        }

        function onAllowSuggestiveContentChange(newVal, oldVal) {
            if (newVal === false) {
                angular.extend(
                    vm.listForm, {
                        maleUnderwear: false,
                        femaleUnderwear: false,
                        revealingClothes: false
                    }
                );
            }
        }

        function onAllowAdultContentChange(newVal, oldVal) {
            if (newVal === false) {
                angular.extend(
                    vm.listForm, {
                        alcohol: false,
                        tobacco: false,
                        gambling: false,
                        violence: false,
                        religion: false
                    }
                );
            }
        }

        function onPlacementListChange(newVal, oldVal) {
            if (newVal !== oldVal) {
                if (newVal) {
                    vm.nWhitelistedUserPlacements = newVal.nItems;
                } else {
                    vm.nWhitelistedUserPlacements = 0;
                }
                $timeout(
                    function() {
                        vm.nWhitelistedUserPlacementsPrev = vm.nWhitelistedUserPlacements;
                    },
                    COUNT_ANIMATION_DURATION
                );
            }
        }

        function onKeywordListChange(newVal, oldVal) {
            if (newVal !== oldVal) {
                if (newVal) {
                    vm.nBlacklistedUserKeywords = newVal.nItems;
                } else {
                    vm.nBlacklistedUserKeywords = 0;
                }
                $timeout(
                    function() {
                        vm.nBlacklistedUserKeywordsPrev = vm.nBlacklistedUserKeywords;
                    },
                    COUNT_ANIMATION_DURATION
                );
            }
        }

        function onDestroy() {
            for (var i in vm.unregisterFns) {
                vm.unregisterFns[i]();
            }
        }

        function refreshAccountList(search) {
            var params = {
                term: search || null,
                items_per_page: 50
            };
            return Account.get(params).$promise
                .then(onGetAccountsSuccess, onGetAccountsError);

            function onGetAccountsSuccess(accounts) {
                vm.accounts = accounts.items;
            }

            function onGetAccountsError(error) {
                toastr.error('Our apologies, we have been unable to recover your accounts. Please, try again later.');
            }
        }
    }
})();
