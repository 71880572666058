'use strict';
var Main = function() {
	var $html = $('html'), $win = $(window), wrap = $('.app-aside'), MEDIAQUERY = {}, app = $('#app');

	MEDIAQUERY = {
		desktopXL: 1200,
		desktop: 992,
		tablet: 768,
		mobile: 480
	};
	$(".current-year").text((new Date).getFullYear());

	var sidebarCollapseHandler = function () {
		var app = $('#app');
		if (sizeForCollapsingSidebar()) {
			app.removeClass('app-sidebar-fixed');
			app.addClass('app-sidebar-closed');
			app.addClass('logo-hidden');
		} else {
			app.removeClass('app-sidebar-closed');
			app.removeClass('logo-hidden');
			app.addClass('app-sidebar-fixed');
		}
	};
	// navbar collapse
	var navbarHandler = function() {
		var navbar = $('.navbar-collapse > .nav');
		var pageHeight = $win.innerHeight() - $('header').outerHeight();
		var collapseButton = $('#menu-toggler');
		if(isSmallDevice()) {
			navbar.css({
				height: pageHeight
			});
		} else {
			navbar.css({
				height: 'auto'
			});
		};
		$(document).on("mousedown touchstart", toggleNavbar);
		function toggleNavbar(e) {
			if(navbar.has(e.target).length === 0//checks if descendants of $box was clicked
			&& !navbar.is(e.target)//checks if the $box itself was clicked
			&& navbar.parent().hasClass("collapse in"))  {
				collapseButton.trigger("click");
				//$(document).off("mousedown touchstart", toggleNavbar);
			}
		};
	};
	// tooltips handler
	var tooltipHandler = function() {
		$('[data-toggle="tooltip"]').tooltip();
	};
	// popovers handler
	var popoverHandler = function() {
		$('[data-toggle="popover"]').popover();
	};
	// perfect scrollbar
	var perfectScrollbarHandler = function() {
		var pScroll = $(".perfect-scrollbar");

		if(!isMobile() && pScroll.length) {
			pScroll.perfectScrollbar({
				suppressScrollX: true
			});
			pScroll.on("mousemove", function() {
				$(this).perfectScrollbar('update');
			});

		}
	};
	//toggle class
	var toggleClassOnElement = function() {
		var toggleAttribute = $('*[data-toggle-class]');
		toggleAttribute.each(function() {
			var _this = $(this);
			var toggleClass = _this.attr('data-toggle-class');
			var outsideElement;
			var toggleElement;
			typeof _this.attr('data-toggle-target') !== 'undefined' ? toggleElement = $(_this.attr('data-toggle-target')) : toggleElement = _this;
			_this.on("click", function(e) {
				if(_this.attr('data-toggle-type') !== 'undefined' && _this.attr('data-toggle-type') == "on") {
					toggleElement.addClass(toggleClass);
				} else if(_this.attr('data-toggle-type') !== 'undefined' && _this.attr('data-toggle-type') == "off") {
					toggleElement.removeClass(toggleClass);
				} else {
					toggleElement.toggleClass(toggleClass);
				}
				e.preventDefault();
				if(_this.attr('data-toggle-click-outside')) {

					outsideElement = $(_this.attr('data-toggle-click-outside'));
					$(document).on("mousedown touchstart", toggleOutside);

				};

			});

			var toggleOutside = function(e) {
				if(outsideElement.has(e.target).length === 0//checks if descendants of $box was clicked
				&& !outsideElement.is(e.target)//checks if the $box itself was clicked
				&& !toggleAttribute.is(e.target) && toggleElement.hasClass(toggleClass)) {

					toggleElement.removeClass(toggleClass);
					$(document).off("mousedown touchstart", toggleOutside);
				}
			};

		});
	};

	//search form
	var searchHandler = function() {
		var elem = $('.search-form');
		var searchForm = elem.children('form');
		var formWrap = elem.parent();

		$(".s-open").on('click', function(e) {
			searchForm.prependTo(wrap);
			e.preventDefault();
			$(document).on("mousedown touchstart", closeForm);
		});
		$(".s-remove").on('click', function(e) {
			searchForm.appendTo(elem);
			e.preventDefault();
		});
		var closeForm = function(e) {
			if(!searchForm.is(e.target) && searchForm.has(e.target).length === 0) {
				$(".s-remove").trigger('click');
				$(document).off("mousedown touchstart", closeForm);
			}
		};
	};
	// settings
	var settingsHandler = function() {
		var clipSetting = new Object, appSetting = new Object;
		clipSetting = {
			fixedHeader: true,
			fixedSidebar: true,
			closedSidebar: false,
			fixedFooter: false,
			theme: 'theme-1'
		};
		if($.cookie) {
			if($.cookie("clip-setting")) {
				appSetting = jQuery.parseJSON($.cookie("clip-setting"));
			} else {
				appSetting = clipSetting;
			}
		};

		appSetting.fixedHeader ? app.addClass('app-navbar-fixed') : app.removeClass('app-navbar-fixed');
		appSetting.fixedSidebar ? app.addClass('app-sidebar-fixed') : app.removeClass('app-sidebar-fixed');
		appSetting.closedSidebar ? app.addClass('app-sidebar-closed') : app.removeClass('app-sidebar-closed');
		appSetting.fixedFooter ? app.addClass('app-footer-fixed') : app.removeClass('app-footer-fixed');
		app.hasClass("app-navbar-fixed") ? $('#fixed-header').prop('checked', true) : $('#fixed-header').prop('checked', false);
		app.hasClass("app-sidebar-fixed") ? $('#fixed-sidebar').prop('checked', true) : $('#fixed-sidebar').prop('checked', false);
		app.hasClass("app-sidebar-closed") ? $('#closed-sidebar').prop('checked', true) : $('#closed-sidebar').prop('checked', false);
		app.hasClass("app-footer-fixed") ? $('#fixed-footer').prop('checked', true) : $('#fixed-footer').prop('checked', false);
		$('#skin_color').attr("href", "/static/css/themes/" + appSetting.theme + ".css");
		$('input[name="setting-theme"]').each(function() {
			$(this).val() == appSetting.theme ? $(this).prop('checked', true) : $(this).prop('checked', false);
		});
		switchLogo(appSetting.theme);

		$('input[name="setting-theme"]').change(function() {
			var selectedTheme = $(this).val();
			$('#skin_color').attr("href", "/static/css/themes/" + selectedTheme + ".css");
			switchLogo(selectedTheme);
			appSetting.theme = selectedTheme;
			$.cookie("clip-setting", JSON.stringify(appSetting));

		});

		$('#fixed-header').change(function() {
			$(this).is(":checked") ? app.addClass("app-navbar-fixed") : app.removeClass("app-navbar-fixed");
			appSetting.fixedHeader = $(this).is(":checked");
			$.cookie("clip-setting", JSON.stringify(appSetting));
		});
		$('#fixed-sidebar').change(function() {
			$(this).is(":checked") ? app.addClass("app-sidebar-fixed") : app.removeClass("app-sidebar-fixed");
			appSetting.fixedSidebar = $(this).is(":checked");
			$.cookie("clip-setting", JSON.stringify(appSetting));
		});
		$('#closed-sidebar').change(function() {
			$(this).is(":checked") ? app.addClass("app-sidebar-closed") : app.removeClass("app-sidebar-closed");
			appSetting.closedSidebar = $(this).is(":checked");
			$.cookie("clip-setting", JSON.stringify(appSetting));
		});
		$('#fixed-footer').change(function() {
			$(this).is(":checked") ? app.addClass("app-footer-fixed") : app.removeClass("app-footer-fixed");
			appSetting.fixedFooter = $(this).is(":checked");
			$.cookie("clip-setting", JSON.stringify(appSetting));
		});
		function switchLogo(theme) {
			switch (theme) {
				case "theme-2":
				case "theme-3":
				case "theme-5":
				case "theme-6":
					$(".navbar-brand img").attr("src", "assets/images/logo2.png");
					break;

				default:
					$(".navbar-brand img").attr("src", "assets/images/logo.png");
					break;
			};
		};
		function defaultSetting() {
			$('#fixed-header').prop('checked', true);
			$('#fixed-sidebar').prop('checked', true);
			$('#closed-sidebar').prop('checked', false);
			$('#fixed-footer').prop('checked', false);
			$('#skin_color').attr("href", "/static/css/themes/theme-1.css");
			$(".navbar-brand img").attr("src", "assets/images/logo.png");

		};
	};
	// function to allow a button or a link to open a tab
	var showTabHandler = function(e) {
		if($(".show-tab").length) {
			$('.show-tab').on('click', function(e) {
				e.preventDefault();
				var tabToShow = $(this).attr("href");
				if($(tabToShow).length) {
					$('a[href="' + tabToShow + '"]').tab('show');
				}
			});
		};
	};
	// function to enable panel scroll with perfectScrollbar
	var panelScrollHandler = function() {
		var panelScroll = $(".panel-scroll");
		if(panelScroll.length && !isMobile()) {
			panelScroll.perfectScrollbar({
				suppressScrollX: true
			});
		}
	};
	//function to activate the panel tools
	var panelToolsHandler = function() {

		// panel close
		$('body').on('click', '.panel-close', function(e) {
			var panel = $(this).closest('.panel');

                destroyPanel();

                function destroyPanel() {
                    var col = panel.parent();
                    panel.fadeOut(300, function () {
                        $(this).remove();
                        if (col.is('[class*="col-"]') && col.children('*').length === 0) {
                            col.remove();
                        }
                    });
                }
			e.preventDefault();
		});
		// panel refresh
		$('body').on('click', '.panel-refresh', function(e) {
			var $this = $(this), csspinnerClass = 'csspinner', panel = $this.parents('.panel').eq(0), spinner = $this.data('spinner') || "load1";
			panel.addClass(csspinnerClass + ' ' + spinner);
			
			window.setTimeout(function() {
				panel.removeClass(csspinnerClass);
			}, 1000);
			e.preventDefault();
		});

		// panel collapse //todo: this cannot be global at all.
		$('body').on('click', '.panel-collapse', function(e) {

			// hacky ':panel-collapse as selector gets all the children so every click is prevented
			// we check here if the trigger comes from an input we want to control.
			if (e.target.id === 'uploadFacebookImageInput' || e.target.id === 'uploadFacebookVideoInput') {
				return;
			}
			e.preventDefault();
			var el = $(this);
			var panel = jQuery(this).closest(".panel");
			var bodyPanel = panel.children(".panel-body");
			bodyPanel.slideToggle(200, function() {
				panel.toggleClass("collapses");
			});

		});

	};
	// function to activate the Go-Top button
    var goTopHandler = function(e) {
        $('.go-top').on('click', function(e) {
            $("html, body").animate({
                scrollTop: 0
            }, "slow");
            e.preventDefault();
        });
    };
	// Window Resize Function
	var resizeHandler = function(func, threshold, execAsap) {
		$(window).resize(function() {
			sidebarCollapseHandler();
			navbarHandler();
		});
	};

	function sizeForCollapsingSidebar() {
		return $win.width() < MEDIAQUERY.desktopXL;
	}

	function isSmallDevice() {
		return $win.width() < MEDIAQUERY.desktop;
	}

	function isMobile() {
		if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
			return true;
		} else {
			return false;
		};
	}
	return {
		init: function() {
			settingsHandler();
			toggleClassOnElement();
			navbarHandler();
			searchHandler();
			tooltipHandler();
			popoverHandler();
			perfectScrollbarHandler();
			resizeHandler();
			showTabHandler();
			panelScrollHandler();
			panelToolsHandler();
			//customSelectHandler();
			goTopHandler();
		}
	};
};

export {Main};
