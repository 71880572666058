(function() {
    'use strict';

    angular
        .module('tvl.administration')
        .controller('CompaniesNewModalController', CompaniesNewModalController);

    CompaniesNewModalController.$inject = [
        '$rootScope',
        '$scope',
        '$uibModalInstance'
    ];

    /* @ngInject */
    function CompaniesNewModalController(
        $rootScope,
        $scope,
        $uibModalInstance
    ) {
        $scope.create = create;
        $scope.creating = false;
        $scope.isValid = false;
        $scope.onFormValid = onFormValid;
        $scope.onFormInvalid = onFormInvalid;
        $scope.cancel = cancel;
        $scope.onSaveSuccess = onSaveSuccess;
        $scope.onSaveError = onSaveError;
        $scope.onSaving = onSaving;

        function create() {
            $rootScope.$broadcast("CompaniesForm.Save");
        }

        function onSaveSuccess(company) {
            $uibModalInstance.close(company);
        }

        function onSaveError(error) {
            $scope.creating = false;
        }

        function onSaving() {
            $scope.creating = true;
        }

        function onFormValid() {
            $scope.isValid = true;
        }

        function onFormInvalid() {
            $scope.isValid = false;
        }

        function cancel() {
            $uibModalInstance.dismiss();
        }
    }
}());
