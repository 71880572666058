(function() {
    'use strict';

    angular
        .module('tvl.administration')
        .controller('AuditUserModalController', AuditUserModalController);

    AuditUserModalController.$inject = ['$scope', '$uibModalInstance', 'User', 'user'];

    /** @ngInject */
    function AuditUserModalController($scope, $uibModalInstance, User, user) {
        var vm = this;

        vm.data = null;
        vm.resource = User;
        vm.resourceName = 'User';

        vm.unregisterCurrentPageWatcher = $scope.$watch(
            'modal.currentPage',
            getUserChangelog
        );

        vm.currentPage = 1;

        $scope.$on('$destroy', onDestroy);

        function onDestroy() {
            vm.unregisterCurrentPageWatcher();
        }

        vm.user = user;
        vm.ui = {
            loadingChangelog: true,
        };
        vm.cancel = cancel;

        function cancel() {
            return $uibModalInstance.dismiss();
        }

        function getUserChangelog(page) {

            vm.ui.loadingChangelog = true;

            var params = {
                id: vm.user.id,
                page: page ? page : vm.currentPage
            };

            return User.getChangelog(params)
                .$promise
                .then(onGetUserChangelogSuccess)
                .finally(onGetUserChangelogFinally);

            function onGetUserChangelogSuccess(changelog) {
                vm.data = changelog;
            }

            function onGetUserChangelogFinally() {
                vm.ui.loadingChangelog = false;
            }
        }
    }

}());
