const moment = require('moment');

(function() {
    'use strict';

    angular
        .module('tvl.common')
        .directive('tvlNewsletterReportsForm', tvlNewsletterReportsForm);

    /** @ngInject */
    function tvlNewsletterReportsForm() {
        return {
            bindToController: true,
            controller: TvlNewsletterReportsFormController,
            controllerAs: 'vm',
            restrict: 'E',
            scope: {
                user: '='
            },
            templateUrl: 'common/directives/newsletter-reports-form.html'
        };
    }

    TvlNewsletterReportsFormController.$inject = ['$scope', 'Constants', 'UserRolesChecker'];

    /* @ngInject */
    function TvlNewsletterReportsFormController($scope, Constants, UserRolesChecker) {
        var vm = this;

        vm.timeZones = [];
        vm.reportFormats = [
            'excel',
            // 'powerpoint',
        ];
        vm.hours = [
            '00:00', '00:30', '01:00', '01:30', '02:00', '02:30', '03:00', '03:30',
            '04:00', '04:30', '05:00', '05:30', '06:00', '06:30', '07:00', '07:30',
            '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30',
            '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30',
            '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30',
            '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30',
        ];
        vm.displayTime = '00:00';
        vm.selectedTimeZone = null;

        vm.updateReportTime = updateReportTime;
        vm.onUpdateTimeZone = onUpdateTimeZone;
        vm.onCampaignReportFlagChange = onCampaignReportFlagChange;
        vm.userIsClient = userIsClient;

        function activate() {
            $scope.$watch('vm.user.email', function (newValue, oldValue) {
                // Reset form data on user change.
                if (newValue !== oldValue) {
                    resetUserTimezone();
                    resetUserHour();
                }
            });

            $scope.$watch('vm.user.roles', function () {
                // Reset form data on user change.
                if (userIsClient()) {
                    resetEndOfCampaignValue();
                }
            });

            getTimezones();
        }

        activate();

        /**
         * Set initial timezone.
         * Also update when user is changed.
         */
        function resetUserTimezone() {
            if (vm.user.newsletterFrequency && vm.user.newsletterFrequency.timezone) {
                vm.timeZones.forEach(function(item){
                    if (vm.user.newsletterFrequency.timezone === item.id) {
                        vm.selectedTimeZone = item;
                    }
                });
            } else {
                vm.selectedTimeZone = vm.timeZones[vm.timeZones.length - 1];
            }
        }

        /**
         * Set initial display time.
         * Also update when user is changed.
         */
        function resetUserHour() {
            if (vm.user.newsletterFrequency && vm.user.newsletterFrequency.timeSlot) {
                vm.displayTime = moment(vm.user.newsletterFrequency.timeSlot, 'HH:mm')
                    .subtract(vm.selectedTimeZone.offset * -1, 'hours')
                    .format('HH:mm');
            }
        }

        function resetEndOfCampaignValue() {
            if (vm.user.newsletterFrequency && vm.user.newsletterFrequency.endOfCampaignReport) {
                vm.user.newsletterFrequency.endOfCampaignReport = false;
            }
        }

        /**
         * Update timezones.
         */
        function getTimezones() {
            Constants.getTimezones().$promise.then(onGetTimezonesSuccess);

            function onGetTimezonesSuccess(data) {
                vm.timeZones = data;
                resetUserTimezone();
                resetUserHour();
            }
        }

        /**
         * Updates report time by display time and current time-zone offset.
         */
        function updateReportTime() {
            vm.user.newsletterFrequency.timeSlot = moment(vm.displayTime, 'HH:mm')
                .subtract(vm.selectedTimeZone.offset, 'hours')
                .format('HH:mm');
        }

        /**
         * Updates timezone value.
         */
        function onUpdateTimeZone() {
            vm.user.newsletterFrequency.timezone = vm.selectedTimeZone.id;
            vm.updateReportTime();
        }

        /**
         * Before hiding report's sub-form, make sure that there is not any invalid field inside.
         */
        function onCampaignReportFlagChange() {
            if (!vm.user.newsletterFrequency.campaignReport) {
                if (!vm.user.newsletterFrequency.formatReport || !(vm.user.newsletterFrequency.formatReport.length > 0)) {
                    vm.user.newsletterFrequency.formatReport = ['excel'];
                }
            }
        }

        function userIsClient() {
            return UserRolesChecker.isClient(vm.user);
        }

        return vm;
    }

})();
