(function() {
    'use strict';

    angular
        .module('tvl.common')
        .directive('selectorRequired', function() {
        return {
            require: 'ngModel',
            link: function(scope, elm, attrs, ctrl) {
                ctrl.$validators.integer = function(modelValue, viewValue) {
                    return false === ctrl.$isEmpty(modelValue);
                };
            }
        };
    });
}());
