(function() {
    'use strict';

    angular
        .module('tvl.brandSafety')
        .controller('RemovalConfirmationModalController', RemovalConfirmationModalController);

    RemovalConfirmationModalController.$inject = ['$scope', '$uibModalInstance', 'list', 'tag'];

    /* @ngInject */
    function RemovalConfirmationModalController($scope, $uibModalInstance, list, tag) {
        var vm = this;
        vm.list = list;
        vm.tag = tag;

        vm.confirm = confirm;
        vm.cancel = cancel;

        function confirm() {
            return $uibModalInstance.close(list);
        }

        function cancel() {
            return $uibModalInstance.dismiss();
        }
    }
})();
