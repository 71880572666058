(function() {
    'use strict';

    angular
        .module('tvl.campaign')
        .directive('tvlContentSelector', tvlContentSelector);

    tvlContentSelector.$inject = ['tvlContentSelectorModal'];

    /* @ngInject */
    function tvlContentSelector(tvlContentSelectorModal) {
        var directive = {
            link: link,
            restrict: 'EA',
            templateUrl: 'campaign/content-selector.html',
            scope: {
                included: '=',
                excluded: '=',
                allowExclude: '=',
                countries: '=',
                readonly: '=?'
            }
        };

        return directive;

        function link(scope, element, attrs) {
            scope.activeTab = 'included';
            scope.openSelectorModal = openSelectorModal;

            function onSelectorModalSaved(items) {
                if (scope[scope.activeTab] === undefined) {
                    scope[scope.activeTab] = [];
                }
                scope[scope.activeTab] = scope[scope.activeTab].concat(items);
            }

            function openSelectorModal() {
                tvlContentSelectorModal
                    .openSelectorModal(scope[scope.activeTab], scope.countries)
                    .then(onSelectorModalSaved);
            }
        }
    }
})();
