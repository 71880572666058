(function() {
    angular
        .module('tvl.common')
        .directive('tvlUrlInput', tvlUrlInput);

    /** @ngInject */
    function tvlUrlInput() {
        var directive = {
            restrict: 'E',
            replace: true,
            scope: {
                ngModel: '=',
                ngBlur: '&',
                tvlPlaceholder: '@?',
                tvlId: '@?',
                tvlName: '@?',
                securedProtocolOnly: '='
            },
            templateUrl: "common/url-input.html",
            link: link
        };
        return directive;

        function link(scope, element, attrs, ngModel) {
            if (scope.securedProtocolOnly !== '' && scope.securedProtocolOnly !== 'Undefined' && scope.securedProtocolOnly) {
                scope.protocols = ['https://'];
                scope.protocol = 'https://';
            } else {
                scope.protocols = ['http://', 'https://'];
                scope.protocol = 'http://';
            }

            scope.url = '';

            scope.setProtocol = setProtocol;

            activate();

            function activate() {
                if (scope.ngModel !== '' && typeof(scope.ngModel) !== 'undefined') {
                    var urlParts = scope.ngModel.split('://');
                    if (urlParts.length > 1) {
                        scope.protocol = urlParts[0] + '://';
                        scope.url = urlParts[1];
                    } else {
                        scope.url = urlParts[0];
                    }
                } else {
                    scope.ngModel = '';
                }

                scope.unregisterProtocolWatcher = scope.$watch('protocol', function(newValue, oldValue) {
                    if (newValue !== oldValue) {
                        changeModel();
                    }
                });

                scope.unregisterUrlWatcher = scope.$watch('url', function(newValue, oldValue) {
                    if (newValue !== oldValue) {
                        var urlParts = newValue.split('://');
                        if (urlParts.length > 1) {
                            scope.protocol = urlParts[0] + '://';
                            scope.url = urlParts[1];
                        }
                        changeModel();
                    }
                });

                scope.$on('$destroy', onDestroy);
            }

            function setProtocol(protocol) {
                scope.protocol = protocol;
            }

            function changeModel() {
                if (scope.url !== '') {
                    scope.ngModel = scope.protocol + scope.url;
                } else {
                    scope.ngModel = '';
                }
            }

            function onDestroy() {
                scope.unregisterProtocolWatcher();
                scope.unregisterUrlWatcher();
            }
        }
    }
})();
