(function() {
    'use strict';

    angular
        .module('tvl.common')
        .directive('tvlMinDecimalInput', tvlMinDecimalInput);

    tvlMinDecimalInput.$inject = [];

    /* @ngInject */
    function tvlMinDecimalInput() {
        return {
            restrict: 'A',
            require: 'ngModel',
            scope: {
                tvlMinDecimalInput: '='
            },
            link: function(scope, elm, attrs, ctrl) {
                ctrl.$validators.tvlMinDecimalInput = function(modelValue, viewValue) {
                    var isValid = false;

                    if (viewValue >= parseFloat(scope.tvlMinDecimalInput)) {
                        isValid = true;
                    }

                    return isValid;
                };
            }
        };
    }
})();
