const moment = require('moment');

(function() {
    'use strict';

    angular
        .module('tvl.common')
        .directive('tvlChangelog', tvlChangelog);

    /* @ngInject */
    function tvlChangelog() {
        var directive = {
            bindToController: true,
            controller: TvlChangelogController,
            controllerAs: 'vm',
            restrict: 'E',
            scope: {
                changelog: '=',
                item: '=',
                resource: '=',
                resourceId: '=',
                resourceName: '=',
                selectUsers: '=',
                selectCampaigns: '=',
            },
            templateUrl: 'common/changelog.html'
        };
        return directive;
    }

    TvlChangelogController.$inject = ['$filter', '$scope', 'NgTableParams', 'Campaign', 'Account', 'Company', 'User'];

    /* @ngInject */
    function TvlChangelogController($filter, $scope, NgTableParams, Campaign, Account, Company, User) {
        var vm = this;
        vm.getActionClasses = getActionClasses;
        vm.getFieldNameForHumans = getFieldNameForHumans;
        vm.getValueForHumans = getValueForHumans;
        vm.getUsername = getUsername;
        vm.getActionInPast = getActionInPast;
        vm.unregisterChangelogWatcher = null;
        vm.changelogGrouped = null;
        vm.nameSelectCampaign = 'All Items';
        vm.nameSelectUser = 'All Users';
        vm.datepickerOptions = {
            opens: 'right',
            timePicker: true,
            timePicker24Hour: true,
            timePickerIncrement: 5,
            showCustomRangeLabel: true,
            locale: {
                format: "MMM DD, YYYY HH:mm"
            },
        };
        vm.dateRange = {
            startDate: moment().subtract(1, 'months'),
            endDate: moment(),
        };

        activate();

        /**
         * Return the appropriate CSS classes for the
         * given entry action.
         *
         * @param {Object} event
         * @return {string}
         */
        function getActionClasses(action) {
            switch (action) {
                case 'add':
                    return 'success';
                case 'remove':
                    return 'danger';
                default:
                case 'update':
                    return 'info';
            }
        }

        function activate() {
            initTable();

            vm.unregisterDateRangeWatcher = $scope.$watch(
                'vm.dateRange',
                onDateRangeChange
            );

            function onDateRangeChange(newVal, oldVal) {
                if (newVal !== oldVal) {
                    vm.dateRange.startDate = newVal.startDate;
                    vm.dateRange.endDate = newVal.endDate;

                    vm.startDate = new Date(vm.dateRange.startDate);
                    vm.endDate = new Date(vm.dateRange.endDate);
                }
                initTable();
            }

            function initTable(){
                vm.tableParams = new NgTableParams(
                    {
                        count: 25,
                        sorting: {loggedAt: 'desc'},
                        filter:{campaign: vm.nameSelectCampaign, username: vm.nameSelectUser},
                    },
                    {
                        counts: [25, 50, 75, 100],
                        getData: getDataTableData
                    }
                );

                vm.tableParams.reload();
            }

            function getDataTableData(params){
                if(params.filter().campaign == 'All Items'){
                    vm.searchCampaign = undefined;
                }else{
                    vm.searchCampaign = params.filter().campaign;
                }

                if(params.filter().username == 'All Users'){
                    vm.searchUser = undefined;
                }else{
                    vm.searchUser = params.filter().username;
                }

                vm.changelogParams = {
                    id: vm.resourceId,
                    page: params.page(),
                    items_per_page: params.count(),
                    searchUser: vm.searchUser,
                    searchCampaign: vm.searchCampaign,
                    startDate: vm.startDate,
                    endDate: vm.endDate,
                    sorting: params.sorting().loggedAt,
                };

                return getChangelogs().then(function(data){
                    params.total(data.totalItems);
                    vm.changelog.totalItems = data.totalItems;
                    /* Gets clean object type for example if Campaign, Targeting... */
                    vm.objectType = [];
                    data.items.forEach(getObjectType);
                    /* Redirect to table page when results not found */
                    redirectAudit(data.totalItems);
                    return data;
                }).then(onResourceGetSuccess);
            }

            function getObjectType(item){
                if(item.objectType.indexOf('Campaign') != -1){
                    vm.objectType.push('Campaign');
                }else if(item.objectType.indexOf('Placement') != -1){
                    vm.objectType.push('Ad Set');
                }else if(item.objectType.indexOf('Ad') != -1 && item.objectType.indexOf('Placement') == -1){
                    vm.objectType.push('Ad');
                }else if(item.objectType.indexOf('Targeting') != -1){
                    vm.objectType.push('Targeting');
                }
            }

            function getChangelogs(){
                vm.changelogsPromise = vm.resource.getChangelog(vm.changelogParams).$promise;
                return vm.changelogsPromise;
            }

            function onResourceGetSuccess(changelogs) {
                vm.dataset = changelogs.items;
                return vm.dataset;
            }

            function redirectAudit(itemsLength){
                if(itemsLength === 0){
                    setTimeout(function(){
                        location.reload();
                    },2100);
                }
            }

            vm.unregisterChangelogWatcher = $scope.$watch(
                'vm.changelog',
                function (newVal, oldVal) {
                    if (newVal !== oldVal) {
                        vm.changelogGrouped = groupConcurrentEntries();
                    }
                }
            );

            $scope.$on('$destroy', onDestroy);
        }

        function getFieldNameForHumans(fieldName) {

            fieldName = fieldName.replace(/(?:^|\.?)([A-Z])/g, function (x,y) {
                return " " + y
            }).replace(/^ /, "");

            return _.capitalize(fieldName)
        }

        function getValueForHumans(value, fieldName) {
            var valueForHumans = Array.isArray(value) ? value.join(", ") : value;

            if (typeof(valueForHumans) === 'boolean') {
                valueForHumans = (valueForHumans) ? 'Yes' : 'No';
            }

            return valueForHumans;
        }

        function getActionInPast(action) {
            var lastChar = action.slice(-1);
            if (lastChar=="e") {
                return action + "d";
            } else {
                return action + "ed";
            }
        }

        function groupConcurrentEntries() {
            var entriesGrouped = [];
            for (var i in vm.changelog.items) {

                var lastLoggedAt = null;
                var lastEntry = null;
                var entry = vm.changelog.items[i];
                var group = entriesGrouped[entriesGrouped.length - 1];

                if (group) {
                    lastEntry = group[group.length - 1];
                    lastLoggedAt = lastEntry ? lastEntry.loggedAt : null;
                    if (lastLoggedAt == entry.loggedAt) {
                        entriesGrouped[entriesGrouped.length - 1].push(entry);
                    } else {
                        entriesGrouped.push([entry]);
                    }
                } else {
                   entriesGrouped.push([entry]);
                }
            }

            return entriesGrouped;
        }

        function getUsername(username) {
            if (username) {
                return username;
            } else {
                return 'system';
            }
        }

        /**
         * Callback to be invoked when this controller is being destroyed.
         */
        function onDestroy() {
            vm.unregisterChangelogWatcher();
        }
    }
})();
