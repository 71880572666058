(function() {
    'use strict';

    angular
        .module('tvl.brandSafety')
        .constant('YOUTUBE_CHANNEL_PREFIX', 'https://www.youtube.com/channel/')
        .controller('CreateCuratorsListModalController', CreateCuratorsListModalController);

    CreateCuratorsListModalController.$inject = [
        '$uibModalInstance',
        'toastr',
        'YOUTUBE_CHANNEL_PREFIX',
        'tvlCsvExporter',
        'ContentsList',
        'ChannelAnalysis'
    ];

    function CreateCuratorsListModalController(
        $uibModalInstance,
        toastr,
        YOUTUBE_CHANNEL_PREFIX,
        tvlCsvExporter,
        ContentsList,
        ChannelAnalysis
    ) {
        var vm = this;

        vm.createForm = {
            listFile: null,
            language: true,
            genders: true,
            subscribers: true,
            comments: true,
            quality: true,
            type: true,
        };

        vm.uploadForm = {};

        vm.ui = {
            creating: false,
            showOptions: false,
        };
        vm.noChannelsOnFile = false;
        vm.channelsForCurationNum = 0;
        vm.processed = false;
        vm.csvHeaders = {
            channelId: 'ID',
            url: 'Url',
            channelName: 'Channel Name',
            category: 'Category',
            subcategory: 'Subcategory',
            language: 'Language',
            gender: 'Gender',
            subscribers: 'Subscribers',
            comments: 'Additional info',
            quality: 'Content Quality',
            type: 'Type of content'
        };
        vm.curatedFileHasId = false;
        vm.processingXlsxFile = false;

        vm.createCuratorsList = createCuratorsList;
        vm.uploadCuratorsList = uploadCuratorsList;
        vm.cancelCreation = cancelCreation;
        vm.onFileChange = onFileChange;
        vm.onCuratedFileChange = onCuratedFileChange;
        vm.downloadFile = downloadFile;
        vm.openOptions = openOptions;


        function extractChannelIdsFromDataSet(dataSet) {
            return dataSet.map(function(channel) {
                return channel.channelId;
            });
        }

        function uploadCuratorsList() {
            if (vm.curatedFileHasId) {
                ChannelAnalysis.postChannelsCurate({'channels': vm.uploadForm.channels}).$promise
                    .then(onpostCurateSuccess, onpostCurateError);
            }

            function onpostCurateSuccess() {
                toastr.info('We have just upload your data to our Brand safety system, it will be processed soon');
                $uibModalInstance.close();
            }
            function onpostCurateError(error) {
                toastr.error('Our apologies, we have been unable to upload your curated file. Please, try again later or contact system admin.');
            }

        }

        function createCuratorsList() {
            var params = {};
            vm.ui.creating = true;
            params['channel_ids'] = [];

            if (vm.channelsOnList) {
                vm.channelsOnList.forEach(function(channel_id){
                    params['channel_ids'].push(channel_id);
                });
            }

            if (!params['channel_ids'].length) {
                vm.channelsForCuration = [];
                vm.channelsForCurationNum = vm.channelsForCuration.length;
                vm.noChannelsOnFile = true;
                vm.ui.creating = false;
                vm.processed = true;
                return;
            }

            ContentsList.channelsForCountry(params).$promise
                .then(onGetChannelsSuccess, onGetChannelsError);

            function onGetChannelsSuccess(channels) {
                var channelIdsFound = extractChannelIdsFromDataSet(channels.items);
                vm.channelsForCuration = (_.difference(vm.channelsOnList, channelIdsFound));
                vm.channelsForCuration = vm.channelsForCuration.map(function(channelId) {
                    return {channelId: channelId, url: YOUTUBE_CHANNEL_PREFIX + channelId};
                });


                //after get the non existence channels on system, get the non curated channels on the system and merge
                // the two sets for the curators list
                params['categories'] = (vm.createForm.categories) ? ['none'] : null;
                params['content_qualities'] = (vm.createForm.quality) ?  ['None'] : null;
                params['content_types'] = (vm.createForm.type) ? ['None'] : null;
                params['languages'] = (vm.createForm.language) ? ['None'] : null;
                params['min_subscribers'] = (vm.createForm.subscribers) ? 0 : null;
                params['owner_genders'] = (vm.createForm.genders) ? ['None'] : null;

                ContentsList.channelsForCountry(params).$promise
                    .then(onGetIncompleteChannelsSuccess, onGetChannelsError);
            }
            function onGetIncompleteChannelsSuccess(channels) {
                vm.ui.creating = false;
                vm.processed = true;
                var incompleteChannelsFound = channels.items.map(function(channel) {
                    return {channelId: channel.channelId, url: YOUTUBE_CHANNEL_PREFIX + channel.channelId, channelName: channel.channelName};
                    // in case we want to return found curated data to curators list
                    // return {
                    //     channelId: channel.channelId,
                    //     url: YOUTUBE_CHANNEL_PREFIX + channel.channelId,
                    //     channelName: channel.channelName,
                    //     category: '',
                    //     subcategory: '',
                    //     country: (channel.importedCountry) ? channel.importedCountry : '',
                    //     language: '',
                    //     gender: (channel.importedGender) ? channel.importedGender : '',
                    //     subscribers: (channel.subscribers) ? channel.subscribers : '',
                    //     comments: (channel.additionalInfo) ? channel.additionalInfo : '',
                    //     quality: '',
                    //     type: ''
                    // };
                });
                vm.channelsForCuration = _.concat(incompleteChannelsFound, vm.channelsForCuration);
                vm.channelsForCurationNum = vm.channelsForCuration.length;
            }

            function onGetChannelsError(error) {
                vm.ui.creating = false;
                toastr.error('Our apologies, we have been unable to generate the list. Please, try again later.');
            }
        }

        function onFileChange($files, $file) {
            vm.noChannelsOnFile = true;
            vm.processed = false;
            vm.csvErrorsNum = 0;
            if ($file === null) {
                vm.createForm.listFile = null;
                vm.channelsForCurationNum = null;
                vm.csvErrors = null;
            } else {
                vm.curatorsFileName = ($file.name.substr(-4) == '.csv') ? $file.name.substring(0, $file.name.length - 4) : $file.name;
                vm.curatorsFileName = vm.curatorsFileName + '_curators';
                var reader = new FileReader();
                vm.channelsOnList = [];

                reader.onload = function (e) {
                    vm.externalItemsNum = e.target.result.split("\n").length;

                    var regex = /^(https|http):\/\/(www\.)?youtube\.com\/channel\/.+/;
                    e.target.result.split("\n").forEach(function (item, index) {
                        if (item) {
                            var checkItem = regex.exec(item);
                            if (checkItem === null) {
                                vm.csvErrors += vm.csvErrors ? "\n" + item : item;
                                vm.csvErrorsNum += 1;
                            } else {
                                var urlSlices = item.split('/');
                                var channelId = urlSlices.pop().trim();
                                if (channelId.length == 24) {
                                    vm.channelsOnList.push(channelId);
                                    vm.noChannelsOnFile = false;
                                } else {
                                    vm.csvErrors += vm.csvErrors ? "\n" + item : item;
                                    vm.csvErrorsNum += 1;
                                }
                            }
                        }
                    });
                };

                reader.readAsText($file);
            }
        }

        function excelToJson(file) {
            var reader = new FileReader();

            if (file.type !== 'text/csv') {
                reader.onload = function(e) {
                    var data = e.target.result;
                    var workbook = XLSX.read(data, {
                        type: 'binary'
                    });

                    workbook.SheetNames.forEach(function(sheetName) {
                        // Here is your object
                        var XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
                        // var json_object = JSON.stringify(XL_row_object);
                        vm.uploadForm.channels = XL_row_object;
                        vm.processingXlsxFile = false;
                        vm.curatedFileHasId = 'ID' in vm.uploadForm.channels[0];
                    });

                };

                reader.onerror = function(ex) {
                    console.log(ex);
                };

                reader.readAsBinaryString(file);
            }
        };

        function onCuratedFileChange($files, $file) {
            // supports xlx, xlxs files, convert data to objects in order to post tvl api
            if ($file === null) {
                vm.uploadForm.channels = null;
                vm.processingXlsxFile = false;
            } else {
                vm.processingXlsxFile = true;
                excelToJson($file);
            }
        }

        function downloadFile() {
            tvlCsvExporter.exportCSVFile(vm.csvHeaders,vm.channelsForCuration, vm.curatorsFileName);
            $uibModalInstance.close(vm.channelsForCuration);
        }

        function openOptions() {
            vm.ui.showOptions = !vm.ui.showOptions;
        }

        function cancelCreation() {
            return $uibModalInstance.dismiss();
        }
    }

})();
