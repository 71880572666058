const moment = require('moment');

(function () {
    'use strict';

    angular
        .module('tvl.group', [
            'ngAnimate',
            'ngSanitize',
            'ngMessages',
            'ngFileUpload',
            'ngTable',
            'ui.router',
            'ui.bootstrap',
            'ui.select',
            'chart.js',
            'angularMoment',
            'toastr',
            'NgSwitchery',
            'oitozero.ngSweetAlert',
            'angular-loading-bar',
            'checklist-model',
            'xeditable',
            'jtt_youtube',
            'rzModule',
            'fcsa-number',
            'datePicker',
            'daterangepicker',
            'angular-jqcloud',
            'RecursionHelper',
            'dndLists',
            'datatables',
            'datatables.fixedcolumns',
            'datatables.bootstrap',
            'tvl.common'
        ])
        .constant('moment', moment)
        .config(configure)
        .run(run);

    configure.$inject = [
        '$locationProvider',
        '$urlRouterProvider',
        '$stateProvider',
        '$httpProvider',
        'cfpLoadingBarProvider'
    ];

    /* @ngInject */
    function configure(
        $locationProvider,
        $urlRouterProvider,
        $stateProvider,
        $httpProvider,
        cfpLoadingBarProvider
    ) {
        $locationProvider.html5Mode(true);
        cfpLoadingBarProvider.includeSpinner = false;
        $stateProvider.state({
            name: 'group',
            abstract: true,
            templateUrl: 'base.html',
            resolve: {
                session: function (tvlSession) {
                    return tvlSession.getSession().$promise;
                }
            }
        }).state({
            name: 'group.list',
            url: '/groups',
            controller: 'GroupListController',
            templateUrl: 'group/list.html',
            reloadOnSearch: false,
            resolve: {
                session: function (tvlSession) {
                    return tvlSession.getSession();
                }
            }
        }).state({
            name: 'group.show',
            url: '/groups/{id:[0-9a-f]{24}}',
            controller: 'GroupShowController',
            templateUrl: 'group/show.html',
            reloadOnSearch: false,
            resolve: {
                group: getGroup,
                session: function (tvlSession) {
                    return tvlSession.getSession();
                }
            }
        });
    }

    run.$inject = ['$rootScope'];

    /* @ngInject */
    function run($rootScope) {
        $rootScope.bodyClass = '';
    }

    function getGroup($state, $stateParams, toastr, Group) {
        return Group.get({id: $stateParams.id}).$promise
            .catch(function (res) {
                if (res.status === 404) {
                    toastr.error('The requested group does not exist.');
                }
                $state.go('group.list');
            });
    }
})();
