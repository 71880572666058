(function() {
    angular
        .module('tvl.common')
        .directive('tvlBlankSlate', tvlBlankSlate);

    /** @ngInject */
    function tvlBlankSlate() {
        var directive = {
            restrict: 'E',
            replace    : true,
            scope      : {
                headerText: "@",
                bodyText: "@",
                icon: "@"
            },
            templateUrl: "common/blankslate.html",
            link: link
        };
        return directive;

        function link($scope, element, attrs) {
        }
    }
})();
