(function() {
    'use strict';

    angular
        .module('tvl.campaign')
        .controller('WizardUserAccessController', WizardUserAccessController);

    WizardUserAccessController.$inject = [
        '$scope',
        '$q',
        'toastr',
        'tvlForm',
        'Account',
        'User'
    ];

    /* @ngInject */
    function WizardUserAccessController(
        $scope,
        $q,
        toastr,
        tvlForm,
        Account,
        User
    ) {
        var vm = this;
        vm.parent = $scope.$parent;
        vm.campaignForm = vm.parent.campaignForm;
        vm.customerAccount = vm.parent.wizardData.customerAccount;
        vm.unregisterFns = [];

        $scope.newUserForm = {
            name: null
        };

        vm.restartWizardUserAccess = restartWizardUserAccess;

        activate();

        ////////////////

        function activate() {
            vm.unregisterFns = [
                $scope.$on('tvlValidate', function(event, step) {
                    if (step.key === 'users') {
                        vm.parent.$emit('tvlValidationEnd', {'valid' : validate()});
                    }
                })
            ];
        }

        function restartWizardUserAccess() {
            $scope.showNewEmailError = false;
            $scope.showNewEmail = false;
            $scope.stepOneCreateUser = false;
            $scope.disableUserSelect = false;

            $scope.newUserForm = {
                email: null,
                name: null,
            };

            $scope.showNewUser = false;
            $scope.disableNewUserCreateButton = true;
            $scope.newUserCreateButtonText = 'Create';
        };

        // Validate

        function validate() {
            // This step is optional now, I've commented the following code since maybe this is not a final decision

            // if (vm.campaignForm.selectedUsersOrAccounts.length > 0) {
            //     $scope.needOneUserToAdvance = false;
            //     return true;
            // } else {
            //     $scope.needOneUserToAdvance = true;
            // }
            // return false;
            $scope.needOneUserToAdvance = false;
            return true;
        }

        $scope.refreshAccountList = function (searchTerm) {
            Account.get({'term': searchTerm})
                .$promise
                .then(function (accounts) {
                    $scope.newUserForm.newUserAccount = accounts.items[0] || null;
                })
                .finally(function () {
                    $scope.ui.loadingAccounts = false;
                });
        };

        $scope.refreshAccountAndUserList = function (searchTerm) {
            var promises = [];
            promises.push(Account.get({'term': searchTerm}).$promise);
            promises.push(User.get({'term': searchTerm}).$promise);
            $q.all(promises).then(onAccountAndUserSearchSuccess);

            function onAccountAndUserSearchSuccess(data) {
                $scope.accountAndUserList = [];
                angular.forEach(data, function (response) {

                    // We filter customer account since has been selected previously and user can not link that account again
                    var items = response.items.filter(function(item) {
                        return item.id != vm.customerAccount.id;
                    });

                    $scope.accountAndUserList = $scope.accountAndUserList.concat(items);
                });
            }
        };

        $scope.groupByUsersOrAccounts = function (item) {
            if (item.email) {
                return 'Users';
            } else {
                return 'Accounts';
            }
        };

        $scope.createNewTag = function (newUser) {
            vm.restartWizardUserAccess();

            if (!newUser.id) {
                $scope.newUserForm.email = newUser.email;
                if (tvlForm.validateEmail($scope.newUserForm.email)) {
                    $scope.showNewEmail = true;
                    $scope.disableUserSelect = true;
                } else {
                    $scope.showNewEmailError = true;
                }
            }
        };

        $scope.addNewUserStepOne = function () {
            $scope.stepOneCreateUser = true;
            $scope.disableUserSelect = true;
            if (!$scope.newUserForm.email) {
                $scope.showNewUser = true;
            }
        };

        $scope.cancelNewUserCreation = function () {
            if (vm.campaignForm.selectedUsersOrAccounts.length > 0) {
                var lastTagIndex = vm.campaignForm.selectedUsersOrAccounts.length - 1;
                if (!vm.campaignForm.selectedUsersOrAccounts[lastTagIndex].id) {
                    vm.campaignForm.selectedUsersOrAccounts.splice(lastTagIndex, 1);
                }
            }
            vm.restartWizardUserAccess();
        };

        $scope.createNewUser = function () {
            $scope.disableNewUserCreateButton = true;
            $scope.newUserCreateButtonText = 'Creating...';

           if (tvlForm.validateEmail($scope.newUserForm.email) && $scope.newUserForm.name) {
               saveUser().then(onUserCreationSuccess).catch(onUserCreationError);
           } else {
                onUserCreationError();
            }

            function saveUser() {
                var user = new User({
                    email: $scope.newUserForm.email,
                    name: $scope.newUserForm.name,
                    company: vm.customerAccount.company.id,
                    roles: ['ROLE_CLIENT']
                });
                return user.$save();
            }


            function onUserCreationSuccess(user) {
                updateSelectedUsersOrAccounts(user);
                toastr.success('An email has been sent to ' + $scope.newUserForm.email + ' in order to set up a new account.');
                vm.restartWizardUserAccess();
            }

            function onUserCreationError(error) {
                $scope.disableNewUserCreateButton = false;
                $scope.newUserCreateButtonText = 'Create';
                switch (error.status) {
                    case 400:
                        var message = error.data.errors[0].message;
                        toastr.error(message);
                        break;
                    case 403:
                        toastr.error('You are not authorized to complete that action.');
                        break;
                    default:
                        toastr.error('Our apologies, an error occurred while attempting to create that user. Please, try again in a few moments.');
                }
            }

        };

        function updateSelectedUsersOrAccounts(user) {
            if ($scope.showNewUser) {
                vm.campaignForm.selectedUsersOrAccounts.push({
                    id: user.id,
                    email: user.email,
                    name: user.name
                    });
            } else {
                var tagIndex = vm.campaignForm.selectedUsersOrAccounts.length - 1;
                vm.campaignForm.selectedUsersOrAccounts[tagIndex].id = user.id;
                vm.campaignForm.selectedUsersOrAccounts[tagIndex].email = user.email;
                vm.campaignForm.selectedUsersOrAccounts[tagIndex].name = user.name;
            }
        }

        $scope.checkNewUserFormFields = function () {
            if (tvlForm.validateEmail($scope.newUserForm.email) && $scope.newUserForm.name) {
                if (!$scope.newAccountDiv || ($scope.newAccountDiv && $scope.newUserForm.accountName)) {
                    $scope.disableNewUserCreateButton = false;
                }
            } else {
                $scope.disableNewUserCreateButton = true;
            }
        };

        $scope.onNewTag = function (email) {
            if (!tvlForm.validateEmail(email)) {
                return null;
            }
            return {id: null, name: null, email: email};
        };

        $scope.checkExistTag = function () {
            return tvlForm.validateEmail($scope.newUserForm.email);
        };

        $scope.$on('$destroy', function () {
            for(var i in vm.unregisterFns) {
                vm.unregisterFns[i]();
            }
        });
    }
})();
