(function () {
    'use strict';

    tvlAdVideoUtils.$inject = ['tvlConstantUtils'];

    /**
     * AdVideo utils service.
     *
     * Validations:
     * - Target URL: Required.
     * - Display URL: Required.
     *     Must have the same domain as Target URL.
     *     Maximum 255 characters allowed.
     * - Descriptions:
     *     Required. Maximum 35 characters.
     *     They must comply with Google Ads policy (see "compliesAdwordsPolicy" method).
     * - Discovery ads:
     *     Headline - required. Maximum 100 characters.
     * - InStream non-skippable ads:
     *     Minimum video duration - 7 seconds.
     *     Maximum video duration - 15 seconds.
     * - Bumper ads:
     *     Maximum video duration - 6 seconds.
     * - TrueView for action:
     *     Headline - required. Maximum 15 characters.
     *     Call to action - required. Maximum 10 characters.
     *     Minimum video duration - 10 seconds.
     */
    function tvlAdVideoUtils(tvlConstantUtils) {

        var self = this;

        self.ADVIDEO_FORMAT_ADWORDS_IN_STREAM = 'adwords_in_stream';
        self.ADVIDEO_FORMAT_ADWORDS_IN_STREAM_TARGET_CPM = 'adwords_in_stream_target_cpm';
        self.ADVIDEO_FORMAT_ADWORDS_IN_STREAM_NON_SKIPPABLE = 'adwords_in_stream_non_skippable';
        self.ADVIDEO_FORMAT_ADWORDS_BUMPER = 'adwords_bumper';
        self.ADVIDEO_FORMAT_ADWORDS_VIDEO_DISCOVERY = 'adwords_video_discovery';
        self.ADVIDEO_FORMAT_ADWORDS_TRUEVIEW_FOR_ACTION = 'adwords_in_stream_true_view_for_action';
        self.ADVIDEO_FORMAT_FACEBOOK_ANY = 'facebook_any';
        self.ADVIDEO_FORMAT_FACEBOOK_EXTERNAL_MEDIA = 'facebook_external_media';
        self.ADVIDEO_FORMAT_FACEBOOK_CAROUSEL_EXTERNAL_MEDIA = 'facebook_carousel_external_media';
        self.ADVIDEO_FORMAT_INSTAGRAM_STORY = 'instagram_story';

        self.ADDISPLAY_FORMAT_ADWORDS_IMAGE = 'adwords_display_image';

        self.FACEBOOK_PLACEMENT_POSITIONS_TYPE_MANUAL = 'manual';
        self.FACEBOOK_PLACEMENT_POSITIONS_TYPE_AUTOMATIC = 'automatic';

        self.YOUTUBE_DESCRIPTION_MAX_CHARACTERS = 35;
        self.FACEBOOK_DESCRIPTION_MAX_CHARACTERS = 125;
        self.TRUEVIEW_CALL_TO_ACTION_MAX_LENGTH = 10;
        self.TRUEVIEW_HEADLINE_MAX_LENGTH = 15;
        self.TRUEVIEW_LONG_HEADLINE_MAX_LENGTH = 90;
        self.TRUEVIEW_DESCRIPTION_MAX_LENGTH = 70;
        self.FACEBOOK_MIN_CPV_DAILY_BUDGET_USD = 5;
        self.FACEBOOK_MIN_CPC_DAILY_BUDGET_USD = 5;
        self.DISCOVERY_HEADLINE_MAX_LENGTH = 100;
        self.DISPLAY_URL_MAX_CHARACTERS_ALLOWED = 255;

        self.FACEBOOK_VIDEO_SOURCE_FROM_MEDIA = 'source_external';
        self.FACEBOOK_VIDEO_SOURCE_FROM_MEDIA_PHOTO = 'source_external_photo';
        self.FACEBOOK_VIDEO_SOURCE_FROM_MEDIA_CAROUSEL = 'source_external_carousel';
        self.FACEBOOK_VIDEO_SOURCE_FROM_POST = 'source_post';
        self.FACEBOOK_VIDEO_SOURCE_FROM_INSTAGRAM_POST = 'source_instagram_post';

        self.GOAL_VIEWS = 'views';
        self.GOAL_CLICKS = 'clicks';
        self.GOAL_IMPRESSIONS = 'impressions';
        self.GOAL_INTERACTIONS = 'interactions';
        // TODO (dCalzado): This goal is 'conversions' in backend. Change here, please
        self.GOAL_ACTION = 'action';
        self.GOAL_CONVERSIONS = 'conversions';

        self.FACEBOOK_OBJECTIVE_3_SECONDS = 'objective_3_seconds';
        self.FACEBOOK_OBJECTIVE_THRUPLAYS = 'objective_thruplays';

        self.FACEBOOK_OPTIMIZATION_GOAL_REACH = 'optimization_reach';
        self.FACEBOOK_OPTIMIZATION_GOAL_IMPRESSIONS = 'optimization_impressions';
        self.FACEBOOK_OPTIMIZATION_GOAL_POST_ENGAGEMENT = 'optimization_post_engagement';
        self.FACEBOOK_OPTIMIZATION_GOAL_AD_RECALL_LIFT = 'optimization_ad_recall_lift';
        self.FACEBOOK_OPTIMIZATION_GOAL_THRUPLAY = 'optimization_thruplay';
        self.FACEBOOK_OPTIMIZATION_GOAL_2_SECONDS_VIEW = 'optimization_two_seconds_view';

        self.FACEBOOK_CREATIVE_POST_PHOTO = 'photo';
        self.FACEBOOK_CREATIVE_POST_VIDEO = 'video';
        self.FACEBOOK_CREATIVE_POST_SHARE = 'share';
        self.FACEBOOK_CREATIVE_POST_MULTI_SHARE = 'multi_share';
        self.INSTAGRAM_CREATIVE_POST_VIDEO = 'ig_video';
        self.INSTAGRAM_CREATIVE_POST_PHOTO = 'ig_photo';

        self.FACEBOOK_PLACEMENT_POSITION_FEED = 'feed';
        self.FACEBOOK_PLACEMENT_POSITION_RIGHT_HAND_COLUMN = 'right_hand_column';
        self.FACEBOOK_PLACEMENT_POSITION_INSTANT_ARTICLE = 'instant_article';
        self.FACEBOOK_PLACEMENT_POSITION_MARKETPLACE = 'marketplace';
        self.FACEBOOK_PLACEMENT_POSITION_VIDEO_FEEDS = 'video_feeds';
        self.FACEBOOK_PLACEMENT_POSITION_STORY = 'story';
        self.FACEBOOK_PLACEMENT_POSITION_SEARCH = 'search';
        self.FACEBOOK_PLACEMENT_POSITION_INSTREAM_VIDEO = 'instream_video';

        self.INSTAGRAM_PLACEMENT_POSITION_STREAM = 'stream';
        self.INSTAGRAM_PLACEMENT_POSITION_STORY = 'story';
        self.INSTAGRAM_PLACEMENT_POSITION_EXPLORE = 'explore';

        self.FACEBOOK_MIN_FREQUENCY_CAP_IMPRESSIONS = 1;
        self.FACEBOOK_MAX_FREQUENCY_CAP_IMPRESSIONS = 90;
        self.FACEBOOK_MIN_FREQUENCY_CAP_INTERVAL_DAYS = 1;
        self.FACEBOOK_MAX_FREQUENCY_CAP_INTERVAL_DAYS = 90;

        self.getAdVideoFormats = function () {
            return [
                self.ADVIDEO_FORMAT_ADWORDS_IN_STREAM,
                self.ADVIDEO_FORMAT_ADWORDS_IN_STREAM_TARGET_CPM,
                self.ADVIDEO_FORMAT_ADWORDS_IN_STREAM_NON_SKIPPABLE,
                self.ADVIDEO_FORMAT_ADWORDS_BUMPER,
                self.ADVIDEO_FORMAT_ADWORDS_VIDEO_DISCOVERY,
                self.ADVIDEO_FORMAT_ADWORDS_TRUEVIEW_FOR_ACTION,
                self.ADVIDEO_FORMAT_FACEBOOK_ANY,
                self.ADVIDEO_FORMAT_FACEBOOK_EXTERNAL_MEDIA,
                self.ADVIDEO_FORMAT_FACEBOOK_CAROUSEL_EXTERNAL_MEDIA,
            ];
        };

        self.getAdDisplayFormats = function () {
            return [
                self.ADDISPLAY_FORMAT_ADWORDS_IMAGE,
            ];
        };

        self.getAdFormats = function () {
            return self.getAdVideoFormats().concat(self.getAdDisplayFormats());
        };

        self.getFormatsByNetwork = function (network) {
            var formats = [];

            switch (network) {
                case tvlConstantUtils.NETWORK_YOUTUBE:
                case tvlConstantUtils.NETWORK_ADWORDS:
                    formats = [
                        self.ADVIDEO_FORMAT_ADWORDS_IN_STREAM,
                        self.ADVIDEO_FORMAT_ADWORDS_IN_STREAM_TARGET_CPM,
                        self.ADVIDEO_FORMAT_ADWORDS_IN_STREAM_NON_SKIPPABLE,
                        self.ADVIDEO_FORMAT_ADWORDS_BUMPER,
                        self.ADVIDEO_FORMAT_ADWORDS_VIDEO_DISCOVERY,
                        self.ADVIDEO_FORMAT_ADWORDS_TRUEVIEW_FOR_ACTION,
                    ];
                    break;
                case tvlConstantUtils.NETWORK_INSTAGRAM:
                case tvlConstantUtils.NETWORK_FACEBOOK:
                    formats = [
                        self.ADVIDEO_FORMAT_FACEBOOK_ANY,
                        self.ADVIDEO_FORMAT_INSTAGRAM_STORY,
                    ];
                    break;
                case tvlConstantUtils.NETWORK_GOOGLE_DISPLAY:
                    formats = [
                        self.ADDISPLAY_FORMAT_ADWORDS_IMAGE,
                    ];
                    break;
            }

            return formats;
        };

        self.isInStreamAdVideoFormat = function (format) {
            return format === self.ADVIDEO_FORMAT_ADWORDS_IN_STREAM;
        };

        self.isInStreamTargetCpmAdVideoFormat = function (format) {
            return format === self.ADVIDEO_FORMAT_ADWORDS_IN_STREAM_TARGET_CPM;
        };

        self.isInStreamNonSkippableAdVideoFormat = function (format) {
            return format === self.ADVIDEO_FORMAT_ADWORDS_IN_STREAM_NON_SKIPPABLE;
        };

        self.isBumperAdVideoFormat = function (format) {
            return format === self.ADVIDEO_FORMAT_ADWORDS_BUMPER;
        };

        self.isVideoDiscoveryAdVideoFormat = function (format) {
            return format === self.ADVIDEO_FORMAT_ADWORDS_VIDEO_DISCOVERY;
        };

        self.isFacebookAnyAdVideoFormat = function (format) {
            return format === self.ADVIDEO_FORMAT_FACEBOOK_ANY;
        };

        self.isFacebookExternalAdVideoFormat = function (format) {
            return format === self.ADVIDEO_FORMAT_FACEBOOK_EXTERNAL_MEDIA;
        };

        self.isFacebookCarouselExternalAdVideoFormat = function (format) {
            return format === self.ADVIDEO_FORMAT_FACEBOOK_CAROUSEL_EXTERNAL_MEDIA;
        };

        self.isInstagramStoryAdVideoFormat = function (format) {
            return format === self.ADVIDEO_FORMAT_INSTAGRAM_STORY;
        };

        self.isTrueViewForActionAdVideoFormat = function (format) {
            return format === self.ADVIDEO_FORMAT_ADWORDS_TRUEVIEW_FOR_ACTION;
        };

        self.isAdwordsImageAdDisplayFormat = function (format) {
            return format === self.ADDISPLAY_FORMAT_ADWORDS_IMAGE;
        };

        self.isAdVideoInStreamFamily = function (format) {
            return [
                self.ADVIDEO_FORMAT_ADWORDS_IN_STREAM,
                self.ADVIDEO_FORMAT_ADWORDS_IN_STREAM_TARGET_CPM,
                self.ADVIDEO_FORMAT_ADWORDS_IN_STREAM_NON_SKIPPABLE,
                self.ADVIDEO_FORMAT_ADWORDS_BUMPER,
                self.ADVIDEO_FORMAT_ADWORDS_TRUEVIEW_FOR_ACTION
            ].indexOf(format) !== -1;
        };

        self.isCpmAdVideo = function (adVideo) {
            if (!adVideo) {
                // Force campaign rendering even if there are placements without ads
                return false;
            }
            if (_.isArray(adVideo)) {
                adVideo = adVideo[0];
            }
            if (typeof adVideo.isCpm !== 'undefined' && typeof adVideo.isCpm !== 'function') {
                return adVideo.isCpm;
            }

            // TODO (dCalzado): Unnecessary code. To remove but first check that we have always defined adVideo.isCpm
            if (self.isBumperAdVideoFormat(adVideo.format) ||
                self.isInStreamTargetCpmAdVideoFormat(adVideo.format) ||
                self.isInStreamNonSkippableAdVideoFormat(adVideo.format)) {
                return true;
            }

            if ((self.isFacebookAnyAdVideoFormat(adVideo.format)
                    || self.isFacebookExternalAdVideoFormat(adVideo.format)
                    || self.isFacebookCarouselExternalAdVideoFormat(adVideo.format)
                    || self.isInstagramStoryAdVideoFormat(adVideo.format))
                && (adVideo.goal === self.GOAL_IMPRESSIONS || adVideo.goal === self.GOAL_INTERACTIONS)) {
                return true;
            }
            return false;
        };

        self.isCpcAdVideo = function (adVideo) {
            if (_.isArray(adVideo)) {
                adVideo = adVideo[0];
            }
            if (typeof adVideo.isCpc !== 'undefined' && typeof adVideo.isCpc !== 'function') {
                return adVideo.isCpc;
            }

            // TODO (dCalzado): Unnecessary code. To remove but first check that we have always defined adVideo.isCpc
            if ((self.isFacebookAnyAdVideoFormat(adVideo.format)
                    || self.isFacebookExternalAdVideoFormat(adVideo.format)
                    || self.isFacebookCarouselExternalAdVideoFormat(adVideo.format)
                    || self.isInStreamNonSkippableAdVideoFormat(adVideo.format))
                && adVideo.goal === self.GOAL_CLICKS) {
                return true;
            }
            return false;
        };

        self.isCpaAdVideo = function (adVideo) {
            if (_.isArray(adVideo)) {
                adVideo = adVideo[0];
            }
            if (typeof adVideo.isCpa !== 'undefined' && typeof adVideo.isCpa !== 'function') {
                return adVideo.isCpa;
            }

            // TODO (dCalzado): Unnecessary code. To remove but first check that we have always defined adVideo.isCpa
            return self.isTrueViewForActionAdVideoFormat(adVideo.format) || adVideo.goal === self.GOAL_ACTION;
        };

        self.isCpvAdVideo = function (adVideo) {
            if (!adVideo) {
                // Force campaign rendering even if there are placements without ads
                return false;
            }

            if (_.isArray(adVideo)) {
                adVideo = adVideo[0];
            }
            if (typeof adVideo.isCpv !== 'undefined' && typeof adVideo.isCpv !== 'function') {
                return adVideo.isCpv;
            }

            // TODO (dCalzado): Unnecessary code. To remove but first check that we have always defined adVideo.isCpa
            return (!self.isCpmAdVideo(adVideo) && !self.isCpcAdVideo(adVideo) && !self.isCpaAdVideo(adVideo));
        };

        self.getGoalByFormat = function (format) {
            // TODO (dCalzado): Unnecessary code, should return adVideo.goal only
            //  TODO (dCalzado):  To remove but first check that we have always defined adVideo.goal
            if (self.isInStreamAdVideoFormat(format) ||
                self.isVideoDiscoveryAdVideoFormat(format)) {
                return self.GOAL_VIEWS;
            }
            if (self.isInStreamTargetCpmAdVideoFormat(format) ||
                self.isInStreamNonSkippableAdVideoFormat(format) ||
                self.isBumperAdVideoFormat(format)) {
                return self.GOAL_IMPRESSIONS;
            }
            if (self.isTrueViewForActionAdVideoFormat(format)) {
                return self.GOAL_ACTION;
            }
        };

        self.isCpmGoal = function (goal) {
            return goal === (self.GOAL_IMPRESSIONS || self.GOAL_INTERACTIONS);
        };

        self.isCpvGoal = function (goal) {
            return goal === self.GOAL_VIEWS;
        };

        self.isCpcGoal = function (goal) {
            return goal === self.GOAL_CLICKS;
        };

        self.isCpaGoal = function (goal) {
            return goal === self.GOAL_ACTION;
        };

        self.getIconByFormat = function (format) {
            if (self.isInStreamAdVideoFormat(format)) {
                return 'play-circle-o';
            }
            if (self.isInStreamTargetCpmAdVideoFormat(format)) {
                return 'bullseye';
            }
            if (self.isInStreamNonSkippableAdVideoFormat(format)) {
                return 'caret-square-o-right';
            }
            if (self.isBumperAdVideoFormat(format)) {
                return 'clock-o';
            }
            if (self.isVideoDiscoveryAdVideoFormat(format)) {
                return 'compass';
            }
            if (self.isTrueViewForActionAdVideoFormat(format)) {
                return 'superpowers';
            }
            if (self.isFacebookAnyAdVideoFormat(format)) {
                return 'camera';
            }
            if (self.isFacebookExternalAdVideoFormat(format)) {
                return 'camera';
            }
            if (self.isInstagramStoryAdVideoFormat(format)) {
                return 'history';
            }
            if (self.isAdwordsImageAdDisplayFormat(format)) {
                return 'picture-o';
            }
            if (self.isFacebookCarouselExternalAdVideoFormat(format)) {
                return 'columns';
            }
        };

        self.getTitleByFormat = function (format, type = null) {
            if (self.isInStreamAdVideoFormat(format)) {
                return 'In Stream';
            }
            if (self.isInStreamTargetCpmAdVideoFormat(format)) {
                return 'In Stream Target CPM';
            }
            if (self.isInStreamNonSkippableAdVideoFormat(format)) {
                return 'In Stream Non Skippable';
            }
            if (self.isBumperAdVideoFormat(format)) {
                return 'Bumper';
            }
            if (self.isVideoDiscoveryAdVideoFormat(format)) {
                return 'In Feed';
            }
            if (self.isTrueViewForActionAdVideoFormat(format)) {
                return 'Video Action';
            }
            if (self.isFacebookAnyAdVideoFormat(format)) {
                return 'Facebook/Instagram Post';
            }
            if (self.isFacebookExternalAdVideoFormat(format)) {
                if (type === self.FACEBOOK_CREATIVE_POST_PHOTO) {
                    return 'Facebook/Instagram Image';
                } else {
                    return 'Facebook/Instagram Video';
                }
            }
            if (self.isInstagramStoryAdVideoFormat(format)) {
                return 'Instagram Stories';
            }
            if (self.isAdwordsImageAdDisplayFormat(format)) {
                return 'Image';
            }
            if (self.isFacebookCarouselExternalAdVideoFormat(format)) {
                return 'Facebook/Instagram Carousel';
            }
        };

        self.videoDurationCheckRequired = function (format) {
            return [
                self.ADVIDEO_FORMAT_ADWORDS_IN_STREAM,
                self.ADVIDEO_FORMAT_ADWORDS_IN_STREAM_TARGET_CPM,
                self.ADVIDEO_FORMAT_ADWORDS_TRUEVIEW_FOR_ACTION,
                self.ADVIDEO_FORMAT_ADWORDS_IN_STREAM_NON_SKIPPABLE,
                self.ADVIDEO_FORMAT_ADWORDS_BUMPER
            ].includes(format);
        };

        self.getAdVideoThumbnail = function (adVideo) {
            if (!adVideo) {
                // Force campaign rendering even if there are placements without ads
                return '';
            }
            return adVideo.isDisplay ? adVideo.imageContent : adVideo.video.thumbnail;
        };

        self.getAdVideoTitle = function (adVideo) {
            if (!adVideo) {
                // Force campaign rendering even if there are placements without ads
                return '';
            }
            return adVideo.isDisplay ? adVideo.imageName : adVideo.video.title;
        };

        self.isDisplay = function (ad) {
            return self.isAdwordsImageAdDisplayFormat(ad.format);
        };

        function BaseAdVideo(data) {
            this.videoUrl = data['videoUrl'] || '';
            this.trackingUrl = data['trackingUrl'] || '';
            BaseAd.call(this, data);
        }

        function BaseAd(data) {
            this.id = data['id'] || null;


            // Formats
            this.isInStream = function () {
                return self.isInStreamAdVideoFormat(this.format);
            };
            this.isInStreamTargetCPM = function () {
                return self.isInStreamTargetCpmAdVideoFormat(this.format);
            };
            this.isInStreamNonSkippable = function () {
                return self.isInStreamNonSkippableAdVideoFormat(this.format);
            };
            this.isBumper = function () {
                return self.isBumperAdVideoFormat(this.format);
            };
            this.isVideoDiscovery = function () {
                return self.isVideoDiscoveryAdVideoFormat(this.format);
            };
            this.isTrueViewForAction = function () {
                return self.isTrueViewForActionAdVideoFormat(this.format);
            };
            this.isFacebookAny = function () {
                return self.isFacebookAnyAdVideoFormat(this.format);
            };
            this.isFacebookExternal = function () {
                return self.isFacebookExternalAdVideoFormat(this.format);
            };
            this.isFacebookExternalCarousel = function () {
                return self.isFacebookCarouselExternalAdVideoFormat(this.format);
            };
            this.isInstagramStory = function () {
                return self.isInstagramStoryAdVideoFormat(this.format);
            };
            this.isInStreamFamily = function () {
                return self.isAdVideoInStreamFamily(this.format);
            };


            // Goals
            this.isCpm = function () {
                return self.isCpmGoal(this.goal);
            };
            this.isCpv = function () {
                return self.isCpvGoal(this.goal);
            };
            this.isCpc = function () {
                return self.isCpcGoal(this.goal);
            };
            this.isCpa = function () {
                return self.isCpaGoal(this.goal);
            };
            this.isDisplay = function () {
                return self.isDisplay(this);
            };

            // Various
            this.getIcon = function () {
                return self.getIconByFormat(this.format);
            };
            this.getTitle = function () {
                return self.getTitleByFormat(this.format);
            };
        }

        function BaseFacebookAdVideo(data) {
            this.platform = tvlConstantUtils.NETWORK_FACEBOOK;
            this.network = tvlConstantUtils.NETWORK_FACEBOOK;
            BaseAdVideo.call(this, data);
            this.linkUrl = data['linkUrl'] || '';
            this.linkTitle = data['linkTitle'] || '';
            this.linkDescription = data['linkDescription'] || '';
            this.targetUrl = data['targetUrl'] || '';
            this.displayLink = data['displayLink'] || '';
            this.description = data['description'] || '';
            this.postId = data['postId'] || '';
            this.instagramId = data['instagramId'] || '';
            this.facebookObjective = data['facebookObjective'] || '';
            this.facebookOptimizationGoal = data['facebookOptimizationGoal'] || '';
            this.facebookFrequencyCapImpressions = data['facebookFrequencyCapImpressions'] || null;
            this.facebookFrequencyCapIntervalDays = data['facebookFrequencyCapImpressions'] || null;
            this.callToActionButton = data['callToActionButton'] || null;
            this.callToActionType = data['callToActionType'] || null;
            this.customerAccountId = data['customerAccountId'] || null;
        }

        function BaseYoutubeAdVideo(data) {
            this.platform = tvlConstantUtils.NETWORK_YOUTUBE;
            this.network = tvlConstantUtils.NETWORK_ADWORDS;
            BaseAdVideo.call(this, data);
        }

        self.InStreamAdVideo = function (data) {
            data = data || {};
            this.format = self.ADVIDEO_FORMAT_ADWORDS_IN_STREAM;
            this.goal = self.GOAL_VIEWS;
            BaseYoutubeAdVideo.call(this, data);
            this.targetUrl = data['targetUrl'] || '';
            this.displayUrl = data['displayUrl'] || '';
            this.companionBanner = data['companionBanner'] || null;
            this.normalize = function () {
                /**
                 * This methods are used to convert objects to dictionaries before send them to api.
                 */
                return {
                    companionBanner: this.companionBanner,
                    displayUrl: this.displayUrl,
                    format: this.format,
                    id: this.id,
                    platform: this.platform,
                    targetUrl: this.targetUrl,
                    trackingUrl: this.trackingUrl,
                    videoUrl: this.videoUrl
                };
            };
        };

        self.InStreamTargetCpmAdVideo = function (data) {
            data = data || {};
            this.format = self.ADVIDEO_FORMAT_ADWORDS_IN_STREAM_TARGET_CPM;
            this.goal = self.GOAL_IMPRESSIONS;
            BaseYoutubeAdVideo.call(this, data);
            this.targetUrl = data['targetUrl'] || '';
            this.displayUrl = data['displayUrl'] || '';
            this.companionBanner = data['companionBanner'] || null;
            this.normalize = function () {
                return {
                    companionBanner: this.companionBanner,
                    displayUrl: this.displayUrl,
                    format: this.format,
                    id: this.id,
                    platform: this.platform,
                    targetUrl: this.targetUrl,
                    trackingUrl: this.trackingUrl,
                    videoUrl: this.videoUrl
                };
            };
        };

        self.InStreamNonSkippableAdVideo = function (data) {
            data = data || {};
            this.format = self.ADVIDEO_FORMAT_ADWORDS_IN_STREAM_NON_SKIPPABLE;
            this.goal = self.GOAL_IMPRESSIONS;
            BaseYoutubeAdVideo.call(this, data);
            this.targetUrl = data['targetUrl'] || '';
            this.displayUrl = data['displayUrl'] || '';
            this.companionBanner = data['companionBanner'] || null;
            this.normalize = function () {
                return {
                    companionBanner: this.companionBanner,
                    displayUrl: this.displayUrl,
                    format: this.format,
                    id: this.id,
                    platform: this.platform,
                    targetUrl: this.targetUrl,
                    trackingUrl: this.trackingUrl,
                    videoUrl: this.videoUrl
                };
            };
        };

        self.BumperAdVideo = function (data) {
            data = data || {};
            this.format = self.ADVIDEO_FORMAT_ADWORDS_BUMPER;
            this.goal = self.GOAL_IMPRESSIONS;
            BaseYoutubeAdVideo.call(this, data);
            this.targetUrl = data['targetUrl'] || '';
            this.displayUrl = data['displayUrl'] || '';
            this.companionBanner = data['companionBanner'] || null;
            this.normalize = function () {
                return {
                    companionBanner: this.companionBanner,
                    displayUrl: this.displayUrl,
                    format: this.format,
                    id: this.id,
                    platform: this.platform,
                    targetUrl: this.targetUrl,
                    trackingUrl: this.trackingUrl,
                    videoUrl: this.videoUrl
                };
            };
        };

        self.TrueViewForActionAdVideo = function (data) {
            data = data || {};
            this.format = self.ADVIDEO_FORMAT_ADWORDS_TRUEVIEW_FOR_ACTION;
            this.goal = self.GOAL_ACTION;
            BaseYoutubeAdVideo.call(this, data);
            this.targetUrl = data['targetUrl'] || '';
            this.displayUrl = data['displayUrl'] || '';
            this.companionBanner = data['companionBanner'] || null;
            this.callToAction = data['callToAction'] || '';
            this.headline = data['headline'] || '';
            this.longHeadline = data['longHeadline'] || '';
            this.description = data['description'] || '';
            this.normalize = function () {
                return {
                    callToAction: this.callToAction,
                    companionBanner: this.companionBanner,
                    displayUrl: this.displayUrl,
                    format: this.format,
                    headline: this.headline,
                    longHeadline: this.longHeadline,
                    description: this.description,
                    id: this.id,
                    platform: this.platform,
                    targetUrl: this.targetUrl,
                    trackingUrl: this.trackingUrl,
                    videoUrl: this.videoUrl
                };
            };
        };

        self.DiscoveryAdVideo = function (data) {
            data = data || {};
            this.format = self.ADVIDEO_FORMAT_ADWORDS_VIDEO_DISCOVERY;
            this.goal = self.GOAL_VIEWS;
            BaseYoutubeAdVideo.call(this, data);
            this.headline = data['headline'] || '';
            this.firstDescription = data['firstDescription'] || '';
            this.secondDescription = data['secondDescription'] || '';
            this.normalize = function () {
                return {
                    firstDescription: this.firstDescription,
                    format: this.format,
                    headline: this.headline,
                    id: this.id,
                    landingPage: '',
                    platform: this.platform,
                    secondDescription: this.secondDescription,
                    trackingUrl: this.trackingUrl,
                    videoUrl: this.videoUrl
                };
            };
        };

        /**
         * Fields may vary by goal, may be we should implement sub-classes for each goal separately.
         * See "convertFacebookInstagramAdToAdVideo()" in review.controller.js
         */
        self.FacebookAnyAdVideo = function (data) {
            data = data || {};
            this.format = self.ADVIDEO_FORMAT_FACEBOOK_ANY;
            this.goal = data['goal'] || self.GOAL_VIEWS;
            this.facebookPositions = data['facebookPositions'] || [];
            this.instagramPositions = data['instagramPositions'] || [];
            BaseFacebookAdVideo.call(this, data);
            this.normalize = function () {
                return {
                    description: this.description,
                    format: this.format,
                    goal: this.goal,
                    id: this.id,
                    platform: this.platform,
                    facebookPositions: this.facebookPositions,
                    instagramPositions: this.instagramPositions,
                    postId: this.postId,
                    targetUrl: this.targetUrl,
                    trackingUrl: this.trackingUrl,
                    displayLink: this.displayLink,
                    videoUrl: this.videoUrl,
                    facebookObjective: this.facebookObjective,
                    facebookOptimizationGoal: this.facebookOptimizationGoal,
                    facebookFrequencyCapImpressions: this.facebookFrequencyCapImpressions,
                    facebookFrequencyCapIntervalDays: this.facebookFrequencyCapIntervalDays,
                    callToActionButton: this.callToActionButton,
                    callToActionType: this.callToActionType
                };
            };
        };

        self.FacebookExternalAdVideo = function (data) {
            data = data || {};
            this.format = self.ADVIDEO_FORMAT_FACEBOOK_EXTERNAL_MEDIA;
            this.goal = data['goal'] || self.GOAL_VIEWS;
            this.facebookPositions = data['facebookPositions'] || [];
            this.instagramPositions = data['instagramPositions'] || [];
            BaseFacebookAdVideo.call(this, data);
            this.normalize = function () {
                return {
                    description: this.description,
                    format: this.format,
                    goal: this.goal,
                    id: this.id,
                    platform: this.platform,
                    facebookPositions: this.facebookPositions,
                    instagramPositions: this.instagramPositions,
                    postId: this.postId,
                    targetUrl: this.targetUrl,
                    displayUrl: this.displayUrl,
                    displayLink: this.displayLink,
                    trackingUrl: this.trackingUrl,
                    videoId: this.videoId,
                    facebookObjective: this.facebookObjective,
                    facebookOptimizationGoal: this.facebookOptimizationGoal,
                    facebookFrequencyCapImpressions: this.facebookFrequencyCapImpressions,
                    facebookFrequencyCapIntervalDays: this.facebookFrequencyCapIntervalDays,
                    callToActionButton: this.callToActionButton,
                    callToActionType: this.callToActionType,
                    customerAccountId: this.customerAccountId,
                };
            };
        };

        self.FacebookCarouselExternalAdVideo = function (data) {
            data = data || {};
            this.format = self.ADVIDEO_FORMAT_FACEBOOK_CAROUSEL_EXTERNAL_MEDIA;
            this.goal = data['goal'] || self.GOAL_VIEWS;
            this.facebookPositions = data['facebookPositions'] || [];
            this.instagramPositions = data['instagramPositions'] || [];
            this.creatives = data['creatives'] || [];
            this.primaryText = data['primaryText'] || '';
            this.seeMoreUrl = data['seeMoreUrl'] || '';
            this.seeMoreDisplayLink = data['seeMoreDisplayLink'] || '';
            this.addLastSlide = data['addLastSlide'] || false;
            this.orderSlidesByPerformance = data['orderSlidesByPerformance'] || false;

            BaseFacebookAdVideo.call(this, data);
            this.normalize = function () {
                return {
                    description: this.description,
                    format: this.format,
                    goal: this.goal,
                    id: this.id,
                    platform: this.platform,
                    facebookPositions: this.facebookPositions,
                    instagramPositions: this.instagramPositions,
                    postId: this.postId,
                    targetUrl: this.targetUrl,
                    displayUrl: this.displayUrl,
                    trackingUrl: this.trackingUrl,
                    videoId: this.videoId,
                    facebookObjective: this.facebookObjective,
                    facebookOptimizationGoal: this.facebookOptimizationGoal,
                    facebookFrequencyCapImpressions: this.facebookFrequencyCapImpressions,
                    facebookFrequencyCapIntervalDays: this.facebookFrequencyCapIntervalDays,
                    callToActionButton: this.callToActionButton,
                    creatives: this.creatives,
                    primaryText: this.primaryText,
                    seeMoreUrl: this.seeMoreUrl,
                    seeMoreDisplayLink: this.seeMoreDisplayLink,
                    addLastSlide: this.addLastSlide,
                    orderSlidesByPerformance: this.orderSlidesByPerformance,
                };
            };
        };

        self.DisplayImageAd = function (data) {
            data = data || {};
            BaseAd.call(this, data);
            this.format = self.ADDISPLAY_FORMAT_ADWORDS_IMAGE;
            this.platform = tvlConstantUtils.NETWORK_GOOGLE_DISPLAY;
            this.network = tvlConstantUtils.NETWORK_ADWORDS;
            this.goal = data['goal'] || self.GOAL_VIEWS;
            this.finalUrl = data['finalUrl'] || '';
            this.biddingType = data['biddingType'] || '';
            this.trackingUrl = data['trackingUrl'] || '';
            this.displayUrl = data['displayUrl'] || '';
            this.imageName = data['imageName'] || '';
            this.imageContent = data['imageContent'] || '';
            this.id = data['id'] || '';
            this.isDisplay = this.isDisplay(this);
            this.normalize = function () {
                return {
                    format: this.format,
                    goal: this.goal,
                    id: this.id,
                    platform: this.platform,
                    network: this.network,
                    imageContent: this.imageContent,
                    targetUrl: this.targetUrl,
                    finalUrl: this.finalUrl,
                    biddingType: this.biddingType,
                    trackingUrl: this.trackingUrl,
                    displayUrl: this.displayUrl,
                    imageName: this.imageName,
                    isDisplay: this.isDisplay,
                };
            };

        };

        /**
         * Initializes and returns an AdVideo object based on given dictionary.
         */
        self.buildAd = function (data) {
            if (!data) {
                throw 'buildAd method needs data to build the adVideo!';
            }
            var format = data['format'];
            if (format === self.ADVIDEO_FORMAT_ADWORDS_IN_STREAM) {
                return new self.InStreamAdVideo(data);
            }
            if (format === self.ADVIDEO_FORMAT_ADWORDS_IN_STREAM_TARGET_CPM) {
                return new self.InStreamTargetCpmAdVideo(data);
            }
            if (format === self.ADVIDEO_FORMAT_ADWORDS_IN_STREAM_NON_SKIPPABLE) {
                return new self.InStreamNonSkippableAdVideo(data);
            }
            if (format === self.ADVIDEO_FORMAT_ADWORDS_BUMPER) {
                return new self.BumperAdVideo(data);
            }
            if (format === self.ADVIDEO_FORMAT_ADWORDS_VIDEO_DISCOVERY) {
                return new self.DiscoveryAdVideo(data);
            }
            if (format === self.ADVIDEO_FORMAT_ADWORDS_TRUEVIEW_FOR_ACTION) {
                return new self.TrueViewForActionAdVideo(data);
            }
            if (format === self.ADVIDEO_FORMAT_FACEBOOK_ANY) {
                return new self.FacebookAnyAdVideo(data);
            }
            if (format === self.ADVIDEO_FORMAT_FACEBOOK_EXTERNAL_MEDIA) {
                return new self.FacebookExternalAdVideo(data);
            }
            if (format === self.ADVIDEO_FORMAT_FACEBOOK_CAROUSEL_EXTERNAL_MEDIA) {
                return new self.FacebookCarouselExternalAdVideo(data);
            }
            if (format === self.ADDISPLAY_FORMAT_ADWORDS_IMAGE) {
                return new self.DisplayImageAd(data);
            }
            throw 'Unsupported format ' + data['format'] + '!';
        };

        /**
         * Get video minimum and maximum duration in seconds by format
         * @param format
         * @returns {[null,null]|[null,number]|[number,number]}
         */
        self.getVideoDurationLimits = function (format) {
            switch (format) {
                case self.ADVIDEO_FORMAT_ADWORDS_IN_STREAM_NON_SKIPPABLE:
                    return [6, 15];
                case self.ADVIDEO_FORMAT_ADWORDS_BUMPER:
                    return [null, 7];
            }
            return [null, null];
        }

        // Validations

        /**
         * This function determines if input text complies with AdWords ad policy.
         * Applies to headlines and descriptions.
         * @param text
         * @returns {boolean}
         */
        self.compliesAdwordsPolicy = function (text) {
            var rules = [
                new RegExp(/(?:^|[^a-z])([A-Z]+)(?:[^a-z]|$)/g), // Bad use of capital letters
                new RegExp(/[,.!?:;][,.!?:;]+/g), // Bad use of puntuation
                new RegExp(/(\s\s)+/g) // Extra spaces
            ];

            for (var ruleIdx in rules) {
                if (rules[ruleIdx].test(text)) {
                    return false;
                }
            }

            return true;
        };

        self.isValidYoutubeHeadline = function (youtubeAdVideo) {
            var exists = (youtubeAdVideo.headline !== undefined) ? youtubeAdVideo.headline.length > 0 : false;
            if (!exists) {
                return false;
            }
            if (youtubeAdVideo.isTrueViewForAction() && youtubeAdVideo.headline.length > self.TRUEVIEW_HEADLINE_MAX_LENGTH) {
                return false;
            }
            if (youtubeAdVideo.isVideoDiscovery() && youtubeAdVideo.headline.length > self.DISCOVERY_HEADLINE_MAX_LENGTH) {
                return false;
            }

            return self.compliesAdwordsPolicy(youtubeAdVideo.headline);
        };

        self.isValidYoutubeDescription = function (text) {
            var exists = (text !== undefined) ? text.length > 0 : false;
            if (!exists) {
                return false;
            }
            if (text.length > self.YOUTUBE_DESCRIPTION_MAX_CHARACTERS) {
                return false;
            }

            return self.compliesAdwordsPolicy(text);
        };

        self.getFacebookObjectives = function () {
            return [
                {
                    value: self.FACEBOOK_OBJECTIVE_THRUPLAYS,
                    name: 'Thruplay views'
                }
            ];
        };

        /**
         * Get Facebook Optimization goal delivery by goal
         * @param goal
         * @param source
         * @returns {[]}
         */
        self.getFacebookOptimizationGoalOptions = function (goal, source = undefined) {
            var optimizationGoal = [];
            switch (goal) {
                case self.GOAL_IMPRESSIONS:
                    if (source === self.FACEBOOK_VIDEO_SOURCE_FROM_MEDIA_CAROUSEL) {
                        optimizationGoal = [
                            // {
                            //     value: self.FACEBOOK_OPTIMIZATION_GOAL_IMPRESSIONS,
                            //     name: 'Impressions'
                            // },
                            {
                                value: self.FACEBOOK_OPTIMIZATION_GOAL_REACH,
                                name: 'Reach'
                            },
                            {
                                value: self.FACEBOOK_OPTIMIZATION_GOAL_AD_RECALL_LIFT,
                                name: 'Ad Recall Lift'
                            }
                        ];
                    } else {
                        optimizationGoal = [
                            // {
                            //     value: self.FACEBOOK_OPTIMIZATION_GOAL_IMPRESSIONS,
                            //     name: 'Impressions'
                            // },
                            {
                                value: self.FACEBOOK_OPTIMIZATION_GOAL_REACH,
                                name: 'Reach'
                            },
                            // {
                            //     value: self.FACEBOOK_OPTIMIZATION_GOAL_POST_ENGAGEMENT,
                            //     name: 'Post Engagement'
                            // },
                            {
                                value: self.FACEBOOK_OPTIMIZATION_GOAL_AD_RECALL_LIFT,
                                name: 'Ad Recall Lift'
                            }
                        ];
                    }
                    break;

                case self.GOAL_VIEWS:
                    optimizationGoal = [
                        {
                            value: self.FACEBOOK_OPTIMIZATION_GOAL_THRUPLAY,
                            name: 'ThruPlay'
                        },
                        {
                            value: self.FACEBOOK_OPTIMIZATION_GOAL_2_SECONDS_VIEW,
                            name: '2-Seconds Continuous Video Views'
                        }
                    ];
                    break;
            }

            return optimizationGoal;
        };

        /**
         * Get title for facebook optimization goal
         * @param goal
         * @returns {string}
         */
        self.getTitleForFacebookOptimizationGoal = function (goal) {
            switch (goal) {
                case self.FACEBOOK_OPTIMIZATION_GOAL_POST_ENGAGEMENT:
                    return 'Post Engagement';
                case self.FACEBOOK_OPTIMIZATION_GOAL_AD_RECALL_LIFT:
                    return 'Ad Recall Lift';
                case self.FACEBOOK_OPTIMIZATION_GOAL_REACH:
                    return 'Reach';
                case self.FACEBOOK_OPTIMIZATION_GOAL_IMPRESSIONS:
                    return 'Impressions';
                case self.FACEBOOK_OPTIMIZATION_GOAL_THRUPLAY:
                    return 'Thruplay';
                case self.FACEBOOK_OPTIMIZATION_GOAL_2_SECONDS_VIEW:
                    return '2-Seconds Continuous Video Views';
                default:
                    return 'Not available';
            }
        };

        self.getFacebookOptimizationGoalOptionsForCpm = function (source = undefined) {
            return self.getFacebookOptimizationGoalOptions(self.GOAL_IMPRESSIONS, source);
        };

        self.getFacebookOptimizationGoalOptionsForCpv = function () {
            return self.getFacebookOptimizationGoalOptions(self.GOAL_VIEWS);
        };

        /**
         * Get facebook positions
         * @param {bool} isMobile
         * @returns {[]}
         */
        self.getFacebookPositions = function (isMobile) {
            var positions = [
                {
                    value: self.FACEBOOK_PLACEMENT_POSITION_FEED,
                    name: 'Facebook News Feed',
                    device: 'all',
                },
                {
                    value: self.FACEBOOK_PLACEMENT_POSITION_MARKETPLACE,
                    name: 'Facebook Marketplace',
                    device: 'all',
                    mustInclude: self.FACEBOOK_PLACEMENT_POSITION_FEED,
                },
                {
                    value: self.FACEBOOK_PLACEMENT_POSITION_VIDEO_FEEDS,
                    name: 'Facebook Video Feeds',
                    device: 'mobile',
                },
                // {
                //     value: self.FACEBOOK_PLACEMENT_POSITION_RIGHT_HAND_COLUMN,
                //     name: 'Facebook Right Column',
                //     device: 'all',
                // },
                {
                    value: self.FACEBOOK_PLACEMENT_POSITION_STORY,
                    name: 'Facebook Stories',
                    device: 'mobile',
                    mustInclude: self.FACEBOOK_PLACEMENT_POSITION_FEED,
                },
                {
                    value: self.FACEBOOK_PLACEMENT_POSITION_INSTREAM_VIDEO,
                    name: 'Facebook In-Stream Videos',
                    device: 'all',
                    mustInclude: self.FACEBOOK_PLACEMENT_POSITION_FEED,
                },
                {
                    value: self.FACEBOOK_PLACEMENT_POSITION_SEARCH,
                    name: 'Facebook Search Results',
                    device: 'all',
                    mustInclude: self.FACEBOOK_PLACEMENT_POSITION_FEED,
                },
                {
                    value: self.FACEBOOK_PLACEMENT_POSITION_INSTANT_ARTICLE,
                    name: 'Facebook Instant Articles',
                    device: 'mobile',
                    mustInclude: self.FACEBOOK_PLACEMENT_POSITION_FEED,
                },
            ];

            if (!isMobile) {
                positions = positions.filter(function (position) {
                    return position.device === 'all';
                });
            }

            return positions;
        };

        /**
         * Get instagram positions
         * @returns {[]}
         */
        self.getInstagramPositions = function () {
            var positions = [
                {
                    value: self.INSTAGRAM_PLACEMENT_POSITION_STREAM,
                    name: 'Instagram Feed',
                    device: 'mobile'
                },
                {
                    value: self.INSTAGRAM_PLACEMENT_POSITION_EXPLORE,
                    name: 'Instagram Explore',
                    device: 'mobile'
                },
                {
                    value: self.INSTAGRAM_PLACEMENT_POSITION_STORY,
                    name: 'Instagram Stories',
                    device: 'mobile'
                },
            ];

            return positions;
        };

        /**
         *
         * @param goal
         * @returns {[string, string]|[string, string, string, string]|*[]}
         */
        self.getFacebookPostTypesByGoal = function (goal) {
            switch (goal) {
                case  self.GOAL_VIEWS:
                    return [
                        self.FACEBOOK_CREATIVE_POST_VIDEO,
                    ];
                case self.GOAL_CLICKS:
                    return [
                        self.FACEBOOK_CREATIVE_POST_VIDEO,
                        self.FACEBOOK_CREATIVE_POST_PHOTO,
                        self.FACEBOOK_CREATIVE_POST_MULTI_SHARE
                    ];
                case self.GOAL_IMPRESSIONS:
                case self.GOAL_INTERACTIONS:
                case self.GOAL_ACTION:
                    return [
                        self.FACEBOOK_CREATIVE_POST_VIDEO,
                        self.FACEBOOK_CREATIVE_POST_PHOTO,
                        self.FACEBOOK_CREATIVE_POST_SHARE,
                        self.FACEBOOK_CREATIVE_POST_MULTI_SHARE
                    ];
                default:
                    return [];
            }
        };

        /**
         *
         * @returns {[string, string, string]|[string, string, string, string]|*[]}
         * @param optimizationGoal
         */
        self.getFacebookPostTypesByOptimizationGoal = function (optimizationGoal) {
            switch (optimizationGoal) {
                case self.FACEBOOK_OPTIMIZATION_GOAL_REACH:
                case self.FACEBOOK_OPTIMIZATION_GOAL_IMPRESSIONS:
                    return [
                        'video',
                        'photo',
                        'share',
                        'multi_share'
                    ];
                case self.FACEBOOK_OPTIMIZATION_GOAL_POST_ENGAGEMENT:
                case self.FACEBOOK_OPTIMIZATION_GOAL_AD_RECALL_LIFT:
                    return [
                        'video',
                        'photo',
                        'share',
                    ];
                case self.FACEBOOK_OPTIMIZATION_GOAL_THRUPLAY:
                case self.FACEBOOK_OPTIMIZATION_GOAL_2_SECONDS_VIEW:
                    return [
                        'video',
                    ];
                default:
                    return [];
            }
        };

        /**
         * Get icons array for AdVideo
         *
         * @param {Object} adVideo
         * @param {bool} isMobile
         * @returns {[]}
         */
        self.getNetworkIconsForAdVideo = function (adVideo, isMobile) {
            var icons = [];

            if (adVideo.network === tvlConstantUtils.NETWORK_ADWORDS) {
                icons.push({
                    title: tvlConstantUtils.TITLE_SUBNETWORK_ADWORDS_VIDEO,
                    icon: tvlConstantUtils.ICON_SUBNETWORK_ADWORDS_VIDEO,
                    network: tvlConstantUtils.NETWORK_YOUTUBE,
                });
            }

            if (adVideo.network === tvlConstantUtils.NETWORK_GOOGLE_DISPLAY) {
                icons.push({
                    title: tvlConstantUtils.TITLE_SUBNETWORK_ADWORDS_DISPLAY,
                    icon: tvlConstantUtils.ICON_SUBNETWORK_ADWORDS_DISPLAY,
                    network: tvlConstantUtils.NETWORK_GOOGLE_DISPLAY,
                });
            }

            if (adVideo.network === tvlConstantUtils.NETWORK_FACEBOOK) {
                if (adVideo.instagramPositions.length > 0 && adVideo.facebookPositions.length > 0) {
                    icons.push({
                        title: tvlConstantUtils.TITLE_SUBNETWORK_FACEBOOK_INSTAGRAM,
                        icon: tvlConstantUtils.ICON_SUBNETWORK_FACEBOOK_INSTAGRAM,
                        network: tvlConstantUtils.NETWORK_FACEBOOK,
                    });
                    icons.push({
                        title: tvlConstantUtils.TITLE_SUBNETWORK_FACEBOOK_FACEBOOK,
                        icon: tvlConstantUtils.ICON_SUBNETWORK_FACEBOOK_FACEBOOK,
                        network: tvlConstantUtils.NETWORK_FACEBOOK,
                    });
                } else if (adVideo.instagramPositions.length > 0) {
                    icons.push({
                        title: tvlConstantUtils.TITLE_SUBNETWORK_FACEBOOK_INSTAGRAM,
                        icon: tvlConstantUtils.ICON_SUBNETWORK_FACEBOOK_INSTAGRAM,
                        network: tvlConstantUtils.NETWORK_FACEBOOK,
                    });
                } else if (adVideo.facebookPositions.length > 0) {
                    icons.push({
                        title: tvlConstantUtils.TITLE_SUBNETWORK_FACEBOOK_FACEBOOK,
                        icon: tvlConstantUtils.ICON_SUBNETWORK_FACEBOOK_FACEBOOK,
                        network: tvlConstantUtils.NETWORK_FACEBOOK,
                    });
                } else if (adVideo.instagramPositions.length === 0 && adVideo.facebookPositions.length === 0) {
                    if (isMobile) {
                        icons.push({
                            title: tvlConstantUtils.TITLE_SUBNETWORK_FACEBOOK_INSTAGRAM,
                            icon: tvlConstantUtils.ICON_SUBNETWORK_FACEBOOK_INSTAGRAM,
                            network: tvlConstantUtils.NETWORK_FACEBOOK,
                        });
                    }

                    icons.push({
                        title: tvlConstantUtils.TITLE_SUBNETWORK_FACEBOOK_FACEBOOK,
                        icon: tvlConstantUtils.ICON_SUBNETWORK_FACEBOOK_FACEBOOK,
                        network: tvlConstantUtils.NETWORK_FACEBOOK,
                    });
                }
            }

            return icons;
        };

        self.normalizeFacebookAdImagesData = function (adVideos) {
            adVideos.forEach(function (adVideo) {
                if (adVideo.name && !adVideo.title) {
                    adVideo.id = adVideo.hash;
                    adVideo.title = adVideo.name;
                    adVideo.picture = adVideo.url;
                }
            });

            return adVideos;
        };

        self.getPositions = function (facebookAdVideo) {
            var positions = '';
            facebookAdVideo.facebookPositions.forEach(function (position) {
                positions += position.name + ', ';
            });
            facebookAdVideo.instagramPositions.forEach(function (position) {
                positions += position.name + ', ';
            });

            return (positions) ? positions.slice(0, -2) : 'Automatic Placements';
        };

        return self;
    }

    angular
        .module('tvl.common')
        .service('tvlAdVideoUtils', tvlAdVideoUtils);

}());
