(function() {
    angular
        .module('tvl.common')
        .directive('tvlSpinner', tvlSpinner)
        .constant('TVL_DEFAULT_SPINNER_IMG_URL', '/static/img/logo-spinner.gif');

    /** @ngInject */
    function tvlSpinner(TVL_DEFAULT_SPINNER_IMG_URL) {
        return {
            restrict: "E",
            replace: true,
            scope: {
                url: '@',
                showMessage: '=?'
            },
            templateUrl: "common/spinner.html",
            link: function ($scope, element, attrs) {
                $scope.showMessage = _.get($scope, 'showMessage', true);
                if (typeof $scope.url === 'undefined') {
                    $scope.url = TVL_DEFAULT_SPINNER_IMG_URL;
                }
            }
        };
    }
})();
