(function () {
    'use strict';

    angular
        .module('tvl.campaign')
        .controller('WizardNetworkDistributionController', WizardNetworkDistributionController);

    WizardNetworkDistributionController.$inject = [
        '$scope',
        'Placement',
        'tvlUtils',
        'tvlAdVideoUtils',
        'tvlForm',
        'tvlDateUtils',
        'googleDisplayAdUtils',
        'tvlCampaignUtils',
        'tvlConstantUtils',
        'tvlWizardInitialPrices',
    ];

    /* @ngInject */
    function WizardNetworkDistributionController(
        $scope,
        Placement,
        tvlUtils,
        tvlAdVideoUtils,
        tvlForm,
        tvlDateUtils,
        googleDisplayAdUtils,
        tvlCampaignUtils,
        tvlConstantUtils,
        tvlWizardInitialPrices
    ) {
        var vm = this;

        vm.tvlAdVideoUtils = tvlAdVideoUtils;
        vm.googleDisplayAdUtils = googleDisplayAdUtils;
        vm.initialCpChanged = initialCpChanged;
        vm.getFormInputId = getFormInputId;
        vm.isFeeCampaign = isFeeCampaign;
        vm.getSeventyPercentOfNumber = getSeventyPercentOfNumber;
        vm.isShortTermMessageNeeded = isShortTermMessageNeeded;

        vm.parent = $scope.$parent;
        vm.wizardData = vm.parent.wizardData;
        vm.campaignForm = vm.parent.campaignForm;
        vm.networkPrices = vm.parent.wizardData.networkPrices;
        vm.displayCurrency = vm.parent.wizardData.customerAccount.currency;
        vm.customerAccountId = vm.parent.wizardData.customerAccount.id;
        vm.grossBudget = vm.campaignForm.budget;
        vm.initialBiddingValues = vm.parent.campaignForm.initialBiddingValues;
        vm.initialBiddingValuesForInfo = {};

        // Todo: Get this from the api to have a unique place determining the precision.
        vm.defaultInitialBidding = (vm.displayCurrency === 'COP') ? 3 : 0.03;

        vm.unregisterFns = [];
        vm.ui = {
            showFixedBiddingValues: false,
            initialGoogleBidding: {
                'cpm': false,
                'cpc': false,
                'cpa': false,
            },
            initialYoutubeBidding: {
                'cpv': false,
                'cpm': false,
                'cpa': false,
            },
            initialFacebookBidding: {
                'cpv': false,
                'cpm': false,
                'cpc': false,
                'cpa': false,
            },
        };

        vm.facebookIcons = tvlCampaignUtils.getNetworkIconsForCampaignInWizard(
            vm.campaignForm, tvlConstantUtils.NETWORK_FACEBOOK
        );
        vm.youtubeIcons = tvlCampaignUtils.getNetworkIconsForCampaignInWizard(
            vm.campaignForm, tvlConstantUtils.NETWORK_YOUTUBE
        );
        vm.googleDisplayIcons = tvlCampaignUtils.getNetworkIconsForCampaignInWizard(
            vm.campaignForm, tvlConstantUtils.NETWORK_GOOGLE_DISPLAY
        );

        vm.networkToFixed = {
            youtube: {
                cpv: 'youtubeFixedCpv',
                cpm: 'youtubeFixedCpm',
                cpc: 'youtubeFixedCpc',
                cpa: 'youtubeFixedCpa',
            },
            googleDisplay: {
                cpv: 'googleDisplayFixedCpv',
                cpm: 'googleDisplayFixedCpm',
                cpc: 'googleDisplayFixedCpc',
                cpa: 'googleDisplayFixedCpa',
            },
            facebook: {
                cpv: 'facebookFixedCpv',
                cpm: 'facebookFixedCpm',
                cpc: 'facebookFixedCpc',
                cpa: 'facebookFixedCpa',
            },
        };

        vm.onGrossBudgetChange = onGrossBudgetChange;

        // True if campaign is 7 days or lower
        vm.isShortDurationCampaign = isShortDurationCampaign();

        activate();

        ////////////////

        function activate() {
            if (vm.parent.importCampaignId && !vm.grossBudget) {
                vm.grossBudget = 0;
            }

            vm.unregisterFns = [
                $scope.$watch('campaignForm.media', validate, true),
                $scope.$watch('vm.networkPrices.youtubeFixedCpv', validateInitialBidding.bind(null, 'youtube', 'cpv'), true),
                $scope.$watch('vm.networkPrices.youtubeFixedCpm', validateInitialBidding.bind(null, 'youtube', 'cpm'), true),
                $scope.$watch('vm.networkPrices.youtubeFixedCpc', validateInitialBidding.bind(null, 'youtube', 'cpc'), true),
                $scope.$watch('vm.networkPrices.youtubeFixedCpa', validateInitialBidding.bind(null, 'youtube', 'cpa'), true),
                $scope.$watch('vm.networkPrices.googleDisplayFixedCpm', validateInitialBidding.bind(null, 'googleDisplay', 'cpm'), true),
                $scope.$watch('vm.networkPrices.googleDisplayFixedCpc', validateInitialBidding.bind(null, 'googleDisplay', 'cpc'), true),
                $scope.$watch('vm.networkPrices.googleDisplayFixedCpa', validateInitialBidding.bind(null, 'googleDisplay', 'cpa'), true),
                $scope.$watch('vm.networkPrices.facebookFixedCpv', validateInitialBidding.bind(null, 'facebook', 'cpv'), true),
                $scope.$watch('vm.networkPrices.facebookFixedCpm', validateInitialBidding.bind(null, 'facebook', 'cpm'), true),
                $scope.$watch('vm.networkPrices.facebookFixedCpc', validateInitialBidding.bind(null, 'facebook', 'cpc'), true),
                $scope.$watch('vm.networkPrices.facebookFixedCpa', validateInitialBidding.bind(null, 'facebook', 'cpa'), true),
                $scope.$on('tvlValidate', function (event, step) {
                    if (step.key === 'networkDistribution') {
                        vm.parent.$emit('tvlValidationEnd', {
                            'valid': validate()
                        });
                    }
                }),
                $scope.$on('tvlInit', function (event, step) {
                    // do something
                })
            ];
            vm.ui.showFixedBiddingValues = showFixedBiddingValues();

            vm.networkPrices = tvlWizardInitialPrices.getInitialPricingValues(
                vm.campaignForm,
                vm.networkPrices,
                vm.parent.wizardData.customerAccount
            );

            setInitialBiddingValues();
        }

        function validateInitialBidding(network, type, newVal, oldVal) {

            if (newVal !== oldVal && vm.campaignForm.fee === null) {
                let countryId, country, minBid;

                for (countryId in vm.campaignForm.countries) {
                    country = vm.toLower(vm.campaignForm.countries[countryId]);
                    minBid = vm.initialBiddingValues[network][country][type];
                    $scope.wizardNetworkBudgetDistributionForm[getFormInputId(network, type, country)].$invalid = newVal < minBid;
                }
            }
        }

        function getSeventyPercentOfNumber(number) {
            // avoids NaN to be returned
            if (!number) {
                return 0;
            }

            // get the 70% of number
            let percentage = (number / 100) * 70;

            // round the number to 2 decimals
            return Math.round((percentage + Number.EPSILON) * 100) / 100;
        }

        function showFixedBiddingValues() {
            return vm.campaignForm.fee === null;
        }

        function isFeeCampaign() {
            return vm.campaignForm.fee !== null;
        }

        function initialCpChanged(country, type, network) {
            let isFee = vm.campaignForm.fee !== null;

            if (isFee) {
                // Fee campaigns has not limit
                return;
            }

            let newPrice = vm.initialBiddingValues[`${network}`][country][type];

            let fixedPrice = vm.networkPrices[vm.networkToFixed[network][type]];

            $scope.wizardNetworkBudgetDistributionForm[getFormInputId(network, type, country)].$invalid = newPrice > fixedPrice;
        }

        function getFormInputId(network, type, country) {

            return _.camelCase(network + ' ' + type + ' ' + country);
        }


        /**
         * Todo (ika): Check if we are using this method.
         *
         * If for a platform we have just one ad video and country we will set
         * the whole network assigned budget for that placement.
         */
        function prefillNetworkBudgetDistribution() {
            if (vm.campaignForm.countries.length === 1) {
                for (var network in vm.campaignForm.media) {
                    if (vm.campaignForm.media[network] > 0) {
                        if (vm.campaignForm.ads[network].length === 1) {
                            var adVideoId = vm.campaignForm.ads[network][0].id;
                            var country = vm.campaignForm.countries[0];
                            // We overwrite any previous distribution
                            vm.campaignForm.adDistribution[network] = {};
                            vm.campaignForm.adDistribution[network][adVideoId] = {};
                            vm.campaignForm.adDistribution[network][adVideoId][country] = vm.campaignForm.media[network];
                        }
                    }
                }
            }
        }

        $scope.setDefaultNetworkDistribution = function (network) {
            if (!vm.campaignForm.media[network]) {
                var dist = Number.parseFloat($scope.getDefaultNetworkDistribution(network));
                vm.campaignForm.media[network] = dist;
            }
        };

        $scope.getDefaultNetworkDistribution = function (network) {
            var num = 0;
            var totalBudget = Number.parseFloat(vm.campaignForm.budget);
            var choosenNetworks = [];

            for (var key in vm.campaignForm.networks) {
                if (vm.campaignForm.networks[key]) {
                    num++;
                    choosenNetworks.push(key)
                }
            }

            var dist = tvlUtils.roundToTwoDecimalPlaces(totalBudget / num);

            if (num == 1) {
                return dist;
            }

            if (choosenNetworks.indexOf(network) == (choosenNetworks.length - 1)) {
                return tvlUtils.roundToTwoDecimalPlaces(totalBudget - (num - 1) * dist);
            } else {
                return dist;
            }

        };

        $scope.getMinStep = function (value) {
            return 0.001;
            // return tvlUtils.getMinNumberWithSameFractionDigits(value);
        };

        vm.toLower = function (text) {
            return text.toLowerCase();
        };

        vm.normalizeNetwork = function (network) {
            if (network === 'adwords') {
                return 'youtube';
            }
            return network;
        };

        vm.normalizeAction = normalizeAction;

        function normalizeAction(action) {
            if (action === 'views') {
                return 'cpv';
            } else if (action === 'impressions') {
                return 'cpm';
            } else if (action === 'clicks') {
                return 'cpc';
            } else if (action === 'action') {
                return 'cpa';
            }
            return action;
        }

        function updateInitialBiddingValue(network, country, action, format) {
            action = normalizeAction(action);

            let params = {
                'network': network,
                'country': country,
                'action': action,
                'format': format,
                'customerAccountId': vm.customerAccountId,
                'campaignName': vm.campaignForm.name,
                'isFee': (vm.campaignForm.fee !== null),
            };

            Placement.getInitialBidding(params).$promise.then(function (response) {
                network = vm.normalizeNetwork(response.network);
                country = vm.toLower(response.country);
                action = vm.normalizeAction(response.action);
                initInitialBiddingValues(network, country, action, response.bidding);
            });
        }

        function initInitialBiddingValues(network, country, action, bidding) {
            if (isFeeCampaign()) {
                vm.initialBiddingValues[network][country][action] = parseFloat(bidding);
            } else {
                let initialBidding = vm.networkPrices[vm.networkToFixed[network][action]];

                if (isShortDurationCampaign()) {
                    initialBidding = getSeventyPercentOfNumber(initialBidding);
                    bidding = initialBidding;
                }

                vm.initialBiddingValues[network][country][action] =
                    (parseFloat(bidding) > initialBidding) ? initialBidding : parseFloat(bidding);
            }

            vm.initialBiddingValuesForInfo[network][country][action] = parseFloat(bidding);
        }

        function setInitialBiddingValues() {
            var ad, countryId, country;

            // Google
            if (vm.campaignForm.ads.googleDisplay.length > 0) {
                if (!vm.initialBiddingValues['googleDisplay']) {
                    vm.initialBiddingValues['googleDisplay'] = {};
                }

                if (!vm.initialBiddingValuesForInfo['googleDisplay']) {
                    vm.initialBiddingValuesForInfo['googleDisplay'] = {};
                }

                for (countryId in vm.campaignForm.countries) {
                    country = vm.campaignForm.countries[countryId];
                    if (!vm.initialBiddingValues.googleDisplay[country.toLowerCase()]) {
                        vm.initialBiddingValues.googleDisplay[country.toLowerCase()] = {};
                    }
                    if (!vm.initialBiddingValuesForInfo.googleDisplay[country.toLowerCase()]) {
                        vm.initialBiddingValuesForInfo.googleDisplay[country.toLowerCase()] = {};
                    }
                }

                for (var googleId in vm.campaignForm.ads.googleDisplay) {
                    ad = vm.campaignForm.ads.googleDisplay[googleId];
                    if (ad.biddingStrategyType === 'MANUAL_CPM') {
                        vm.ui.initialGoogleBidding.cpm = true;
                        for (countryId in vm.campaignForm.countries) {
                            country = vm.campaignForm.countries[countryId];
                            if (!vm.initialBiddingValues.googleDisplay[country.toLowerCase()]['cpm']) {
                                vm.initialBiddingValues.googleDisplay[country.toLowerCase()]['cpm'] = vm.defaultInitialBidding;
                                updateInitialBiddingValue(ad.network, country, 'cpm', ad.format);
                            }
                        }
                    } else if (ad.biddingStrategyType === 'MANUAL_CPC' || ad.biddingStrategyType === 'TARGET_SPEND') {
                        vm.ui.initialGoogleBidding.cpc = true;
                        for (countryId in vm.campaignForm.countries) {
                            country = vm.campaignForm.countries[countryId];
                            if (!vm.initialBiddingValues.googleDisplay[country.toLowerCase()]['cpc']) {
                                vm.initialBiddingValues.googleDisplay[country.toLowerCase()]['cpc'] = vm.defaultInitialBidding;
                                updateInitialBiddingValue(ad.network, country, 'cpc', ad.format);
                            }
                        }
                    } else if (ad.biddingStrategyType === 'TARGET_CPA') {
                        vm.ui.initialGoogleBidding.cpa = true;
                        for (countryId in vm.campaignForm.countries) {
                            country = vm.campaignForm.countries[countryId];
                            if (!vm.initialBiddingValues.googleDisplay[country.toLowerCase()]['cpa']) {
                                vm.initialBiddingValues.googleDisplay[country.toLowerCase()]['cpa'] = vm.defaultInitialBidding;
                                updateInitialBiddingValue(ad.network, country, 'cpa', ad.format);
                            }
                        }
                    }
                }
            }

            // Youtube
            if (vm.campaignForm.ads.youtube.length > 0) {
                if (!vm.initialBiddingValues['youtube']) {
                    vm.initialBiddingValues['youtube'] = {};
                }

                if (!vm.initialBiddingValuesForInfo['youtube']) {
                    vm.initialBiddingValuesForInfo['youtube'] = {};
                }

                for (countryId in vm.campaignForm.countries) {
                    country = vm.campaignForm.countries[countryId];
                    if (!vm.initialBiddingValues.youtube[country.toLowerCase()]) {
                        vm.initialBiddingValues.youtube[country.toLowerCase()] = {};
                    }
                    if (!vm.initialBiddingValuesForInfo.youtube[country.toLowerCase()]) {
                        vm.initialBiddingValuesForInfo.youtube[country.toLowerCase()] = {};
                    }
                }

                for (var youtubeId in vm.campaignForm.ads.youtube) {
                    ad = vm.campaignForm.ads.youtube[youtubeId];
                    if (ad.isCpv()) {
                        vm.ui.initialYoutubeBidding.cpv = true;
                        for (countryId in vm.campaignForm.countries) {
                            country = vm.campaignForm.countries[countryId];
                            if (!vm.initialBiddingValues.youtube[country.toLowerCase()]['cpv']) {
                                vm.initialBiddingValues.youtube[country.toLowerCase()]['cpv'] = vm.defaultInitialBidding;
                                updateInitialBiddingValue(ad.network, country, ad.goal, ad.format);
                            }
                        }
                    } else if (ad.isCpm()) {
                        vm.ui.initialYoutubeBidding.cpm = true;
                        for (countryId in vm.campaignForm.countries) {
                            country = vm.campaignForm.countries[countryId];
                            if (!vm.initialBiddingValues.youtube[country.toLowerCase()]['cpm']) {
                                vm.initialBiddingValues.youtube[country.toLowerCase()]['cpm'] = vm.defaultInitialBidding;
                                updateInitialBiddingValue(ad.network, country, ad.goal, ad.format);
                            }
                        }
                    } else if (ad.isCpa()) {
                        vm.ui.initialYoutubeBidding.cpa = true;
                        for (countryId in vm.campaignForm.countries) {
                            country = vm.campaignForm.countries[countryId];
                            if (!vm.initialBiddingValues.youtube[country.toLowerCase()]['cpa']) {
                                vm.initialBiddingValues.youtube[country.toLowerCase()]['cpa'] = vm.defaultInitialBidding;
                                updateInitialBiddingValue(ad.network, country, ad.goal, ad.format);
                            }
                        }
                    }
                }
            }

            // Facebook
            if (vm.campaignForm.ads.facebook.length > 0) {
                if (!vm.initialBiddingValues['facebook']) {
                    vm.initialBiddingValues['facebook'] = {};
                }
                if (!vm.initialBiddingValuesForInfo['facebook']) {
                    vm.initialBiddingValuesForInfo['facebook'] = {};
                }

                for (countryId in vm.campaignForm.countries) {
                    country = vm.campaignForm.countries[countryId];
                    if (!vm.initialBiddingValues.facebook[country.toLowerCase()]) {
                        vm.initialBiddingValues.facebook[country.toLowerCase()] = {};
                    }
                    if (!vm.initialBiddingValuesForInfo.facebook[country.toLowerCase()]) {
                        vm.initialBiddingValuesForInfo.facebook[country.toLowerCase()] = {};
                    }
                }

                for (var fid in vm.campaignForm.ads.facebook) {
                    ad = vm.campaignForm.ads.facebook[fid];

                    if (ad.isCpv()) {
                        vm.ui.initialFacebookBidding.cpv = true;
                        for (countryId in vm.campaignForm.countries) {
                            country = vm.campaignForm.countries[countryId];
                            if (!vm.initialBiddingValues.facebook[country.toLowerCase()]['cpv']) {
                                vm.initialBiddingValues.facebook[country.toLowerCase()]['cpv'] = vm.defaultInitialBidding;
                                updateInitialBiddingValue(ad.network, country, ad.goal, ad.format);
                            }
                        }
                    } else if (ad.isCpm()) {
                        vm.ui.initialFacebookBidding.cpm = true;
                        for (countryId in vm.campaignForm.countries) {
                            country = vm.campaignForm.countries[countryId];
                            if (!vm.initialBiddingValues.facebook[country.toLowerCase()]['cpm']) {
                                vm.initialBiddingValues.facebook[country.toLowerCase()]['cpm'] = vm.defaultInitialBidding;
                                updateInitialBiddingValue(ad.network, country, ad.goal, ad.format);
                            }
                        }
                    } else if (ad.isCpc()) {
                        vm.ui.initialFacebookBidding.cpc = true;
                        for (countryId in vm.campaignForm.countries) {
                            country = vm.campaignForm.countries[countryId];
                            if (!vm.initialBiddingValues.facebook[country.toLowerCase()]['cpc']) {
                                vm.initialBiddingValues.facebook[country.toLowerCase()]['cpc'] = vm.defaultInitialBidding;
                                updateInitialBiddingValue(ad.network, country, ad.goal, ad.format);
                            }
                        }
                    } else if (ad.isCpa()) {
                        vm.ui.initialFacebookBidding.cpa = true;
                        for (countryId in vm.campaignForm.countries) {
                            country = vm.campaignForm.countries[countryId];
                            if (!vm.initialBiddingValues.facebook[country.toLowerCase()]['cpa']) {
                                vm.initialBiddingValues.facebook[country.toLowerCase()]['cpa'] = vm.defaultInitialBidding;
                                updateInitialBiddingValue(ad.network, country, ad.goal, ad.format);
                            }
                        }
                    }
                }
            }
        }

        // this message appears only on rebate campaigns
        function isShortTermMessageNeeded(fixedValue, currentValue) {
            if (vm.isShortDurationCampaign === false) {
                return false;
            }

            if (!isRebateCampaign()) {
                return false;
            }

            let seventyPercent = getSeventyPercentOfNumber(fixedValue);

            return currentValue !== seventyPercent;
        }

        // returns true if campaign duration is 7 days or less
        // and only if is Rebate
        function isShortDurationCampaign() {
            if (!isRebateCampaign()) {
                return false;
            }

            let startDate = vm.campaignForm.startDate;
            let endDate = vm.campaignForm.endDate;

            let differenceInDays = tvlDateUtils.countDaysInDatesRange(startDate, endDate);

            return differenceInDays <= 7;
        }

        function isRebateCampaign() {
            return vm.campaignForm.rebate !== null;
        }

        function onGrossBudgetChange() {
            if (!vm.parent.importCampaignId) {
                return;
            }

            var grossBudget = parseFloat(vm.grossBudget);

            vm.wizardData.applyGrossBudgetToCampaign(grossBudget);

            var budget = parseFloat(vm.campaignForm.budget);

            /* If we have skipped media distribution step, assign all media to Selected Networks */
            var media = vm.campaignForm.media;
            var mediaDistributionSum = media.facebook + media.googleDisplay + media.instagram + media.youtube;

            /* No GoogleDisplay here because we do not allow multi network campaigns with Display or Search */
            var multipleNetworks = (vm.campaignForm.isFacebook === true || vm.campaignForm.isInstagram === true) &&
                vm.campaignForm.isYoutube === true;

            if (mediaDistributionSum !== budget) {
                if (multipleNetworks) {
                    /* This method is empty on purpose to force an error if we open the option without implement here:
                       Redistribute gross budget equally between networks
                       We do not allow placements creation with multi network campaigns for now
                     */
                } else {
                    vm.campaignForm.media = {
                        'facebook': vm.campaignForm.isFacebook ? budget : 0,
                        'googleDisplay': vm.campaignForm.isGoogleDisplay ? budget : 0,
                        'instagram': 0, // Instagram shares the budget with Facebook.
                        'youtube': vm.campaignForm.isYoutube ? budget : 0,
                    };
                }
            }
        }

        // Validation

        function validate() {
            var validCpas = true;

            // Youtube
            if ($scope.wizardNetworkBudgetDistributionForm.youtubeMaxCpv &&
                $scope.wizardNetworkBudgetDistributionForm.youtubeMaxCpv.$invalid) {
                validCpas = false;
            }
            if ($scope.wizardNetworkBudgetDistributionForm.youtubeFixedCpv &&
                $scope.wizardNetworkBudgetDistributionForm.youtubeFixedCpv.$invalid) {
                validCpas = false;
            }
            if ($scope.wizardNetworkBudgetDistributionForm.youtubeMaxCpm &&
                $scope.wizardNetworkBudgetDistributionForm.youtubeMaxCpm.$invalid) {
                validCpas = false;
            }
            if ($scope.wizardNetworkBudgetDistributionForm.youtubeFixedCpm &&
                $scope.wizardNetworkBudgetDistributionForm.youtubeFixedCpm.$invalid) {
                validCpas = false;
            }
            if ($scope.wizardNetworkBudgetDistributionForm.youtubeMaxCpa &&
                $scope.wizardNetworkBudgetDistributionForm.youtubeMaxCpa.$invalid) {
                validCpas = false;
            }
            if ($scope.wizardNetworkBudgetDistributionForm.youtubeFixedCpa &&
                $scope.wizardNetworkBudgetDistributionForm.youtubeFixedCpa.$invalid) {
                validCpas = false;
            }

            // Google display
            if ($scope.wizardNetworkBudgetDistributionForm.googleDisplayMaxCpm &&
                $scope.wizardNetworkBudgetDistributionForm.googleDisplayMaxCpm.$invalid) {
                validCpas = false;
            }
            if ($scope.wizardNetworkBudgetDistributionForm.googleDisplayFixedCpm &&
                $scope.wizardNetworkBudgetDistributionForm.googleDisplayFixedCpm.$invalid) {
                validCpas = false;
            }
            if ($scope.wizardNetworkBudgetDistributionForm.googleDisplayMaxCpa &&
                $scope.wizardNetworkBudgetDistributionForm.googleDisplayMaxCpa.$invalid) {
                validCpas = false;
            }
            if ($scope.wizardNetworkBudgetDistributionForm.googleDisplayFixedCpa &&
                $scope.wizardNetworkBudgetDistributionForm.googleDisplayFixedCpa.$invalid) {
                validCpas = false;
            }
            if ($scope.wizardNetworkBudgetDistributionForm.googleDisplayMaxCpc &&
                $scope.wizardNetworkBudgetDistributionForm.googleDisplayMaxCpc.$invalid) {
                validCpas = false;
            }
            if ($scope.wizardNetworkBudgetDistributionForm.googleDisplayFixedCpc &&
                $scope.wizardNetworkBudgetDistributionForm.googleDisplayFixedCpc.$invalid) {
                validCpas = false;
            }

            // Facebook
            if ($scope.wizardNetworkBudgetDistributionForm.facebookMaxCpv &&
                $scope.wizardNetworkBudgetDistributionForm.facebookMaxCpv.$invalid) {
                validCpas = false;
            }
            if ($scope.wizardNetworkBudgetDistributionForm.facebookFixedCpv &&
                $scope.wizardNetworkBudgetDistributionForm.facebookFixedCpv.$invalid) {
                validCpas = false;
            }
            if ($scope.wizardNetworkBudgetDistributionForm.facebookMaxCpc &&
                $scope.wizardNetworkBudgetDistributionForm.facebookMaxCpc.$invalid) {
                validCpas = false;
            }
            if ($scope.wizardNetworkBudgetDistributionForm.facebookFixedCpc &&
                $scope.wizardNetworkBudgetDistributionForm.facebookFixedCpc.$invalid) {
                validCpas = false;
            }
            if ($scope.wizardNetworkBudgetDistributionForm.facebookMaxCpm &&
                $scope.wizardNetworkBudgetDistributionForm.facebookMaxCpm.$invalid) {
                validCpas = false;
            }
            if ($scope.wizardNetworkBudgetDistributionForm.facebookFixedCpm &&
                $scope.wizardNetworkBudgetDistributionForm.facebookFixedCpm.$invalid) {
                validCpas = false;
            }

            if ($scope.wizardNetworkBudgetDistributionForm.facebookMaxCpa &&
                $scope.wizardNetworkBudgetDistributionForm.facebookMaxCpa.$invalid) {
                validCpas = false;
            }

            if ($scope.wizardNetworkBudgetDistributionForm.facebookFixedCpa &&
                $scope.wizardNetworkBudgetDistributionForm.facebookFixedCpa.$invalid) {
                validCpas = false;
            }

            // Instagram
            if ($scope.wizardNetworkBudgetDistributionForm.instagramMaxCpv &&
                $scope.wizardNetworkBudgetDistributionForm.instagramMaxCpv.$invalid) {
                validCpas = false;
            }
            if ($scope.wizardNetworkBudgetDistributionForm.instagramFixedCpv &&
                $scope.wizardNetworkBudgetDistributionForm.instagramFixedCpv.$invalid) {
                validCpas = false;
            }
            if ($scope.wizardNetworkBudgetDistributionForm.instagramMaxCpc &&
                $scope.wizardNetworkBudgetDistributionForm.instagramMaxCpc.$invalid) {
                validCpas = false;
            }
            if ($scope.wizardNetworkBudgetDistributionForm.instagramFixedCpc &&
                $scope.wizardNetworkBudgetDistributionForm.instagramFixedCpc.$invalid) {
                validCpas = false;
            }
            if ($scope.wizardNetworkBudgetDistributionForm.instagramMaxCpm &&
                $scope.wizardNetworkBudgetDistributionForm.instagramMaxCpm.$invalid) {
                validCpas = false;
            }
            if ($scope.wizardNetworkBudgetDistributionForm.instagramFixedCpm &&
                $scope.wizardNetworkBudgetDistributionForm.instagramFixedCpm.$invalid) {
                validCpas = false;
            }

            if ($scope.wizardNetworkBudgetDistributionForm.instagramMaxCpa &&
                $scope.wizardNetworkBudgetDistributionForm.instagramMaxCpa.$invalid) {
                validCpas = false;
            }

            if ($scope.wizardNetworkBudgetDistributionForm.instagramFixedCpa &&
                $scope.wizardNetworkBudgetDistributionForm.instagramFixedCpa.$invalid) {
                validCpas = false;
            }

            $scope.validNetworkDistribution = false;
            var sumOfBudgets = 0;
            var totalBudget = Number.parseFloat(vm.campaignForm.budget);

            if (vm.campaignForm.networks.youtube) {
                sumOfBudgets += vm.campaignForm.media.youtube;
            }
            if (vm.campaignForm.networks.googleDisplay) {
                sumOfBudgets += vm.campaignForm.media.googleDisplay;
            }
            if (vm.campaignForm.networks.facebook) {
                sumOfBudgets += vm.campaignForm.media.facebook;
            }
            if (vm.campaignForm.networks.instagram) {
                sumOfBudgets += vm.campaignForm.media.instagram;
            }

            sumOfBudgets = Number.parseFloat(sumOfBudgets);
            if (Math.abs(sumOfBudgets - totalBudget) < 0.01) {
                $scope.validNetworkDistribution = true;
            }

            return tvlForm.validate($scope['wizardNetworkBudgetDistributionForm']) &&
                $scope.validNetworkDistribution &&
                validCpas;
        }

        $scope.$on('$destroy', function () {
            for (var i in vm.unregisterFns) {
                vm.unregisterFns[i]();
            }
        });
    }
})();
