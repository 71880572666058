(function() {
    angular
        .module('tvl.common')
        .directive('tvlList', tvlList);

    /** @ngInject */
    function tvlList() {
        var directive = {
            restrict: 'EA',
            transclude: true,
            templateUrl: "common/list.html"
        };
        return directive;
    }
})();
