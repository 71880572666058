(function() {
    'use strict';

    tvlCampaignUtils.$inject = ['operatingSystemService', 'tvlAdVideoUtils', 'tvlConstantUtils'];

    /**
     * A service with campaign-related utility functions.
     *
     * @ngInject
     */
    function tvlCampaignUtils(operatingSystemService, tvlAdVideoUtils, tvlConstantUtils) {
        return {
            getNetworkDisplayName: getNetworkDisplayName,
            thumbnailCellApplies: thumbnailCellApplies,
            placementHeaderApplies: placementHeaderApplies,
            countryCellApplies: countryCellApplies,
            getCountryCellRowspan: getCountryCellRowspan,
            getThumbnailCellRowspan: getThumbnailCellRowspan,
            isCampaignInAdwords: isCampaignInAdwords,
            isCampaignInFacebook: isCampaignInFacebook,
            getVideoIdFromUrl: getVideoIdFromUrl,
            getChannelIdFromUrl: getChannelIdFromUrl,
            getUsernameFromUrl: getUsernameFromUrl,
            getContentType: getContentType,
            appliesToAdwordsFormats: appliesToAdwordsFormats,
            appliesToGoogleDisplayFormats: appliesToGoogleDisplayFormats,
            appliesToNetwork: appliesToNetwork,
            appliesToAdwords: appliesToAdwords,
            appliesToGoogleDisplay: appliesToGoogleDisplay,
            appliesToFacebook: appliesToFacebook,
            appliesToInstagram: appliesToInstagram,
            appliesToTablets: appliesToTablets,
            appliesToDesktops: appliesToDesktops,
            appliesToMobiles: appliesToMobiles,
            getNumberOfAdwordsVideos: getNumberOfAdwordsVideos,
            getNumberOfFacebookVideos: getNumberOfFacebookVideos,
            getNumberOfGoogleDisplay: getNumberOfGoogleDisplay,
            getNumberOfInstagramVideos: getNumberOfInstagramVideos,
            getUniqueVideos: getUniqueVideos,
            getBudgetForCountryAndMedia: getBudgetForCountryAndMedia,
            getCampaignBusinessModels: getCampaignBusinessModels,
            getNetworks: getNetworks,
            getAvailableSubNetworks: getAvailableSubNetworks,
            getStatuses: getStatuses,
            getNotCanceledStatuses: getNotCanceledStatuses,
            getGenders: getGenders,
            getDevices: getDevices,
            mapContent: mapContent,
            mapContents: mapContents,
            placementsToContents: placementsToContents,
            contentsToPlacements: contentsToPlacements,
            isScheduled: isScheduled,
            mapKeywords: mapKeywords,
            isCampaignPresentInAdwords: isCampaignPresentInAdwords,
            isCampaignPresentInGoogleDisplay: isCampaignPresentInGoogleDisplay,
            isCampaignPresentInFacebook: isCampaignPresentInFacebook,
            isCampaignPresentInInstagram: isCampaignPresentInInstagram,
            getNetworkIconsForCampaign: getNetworkIconsForCampaign,
            getNetworkIconsForCampaignInWizard: getNetworkIconsForCampaignInWizard,
            getNetworkIconsForBudgetsInWizard: getNetworkIconsForBudgetsInWizard
        };

        /**
         * Get budget for a given campaign, filtering by country and media
         * @param  {object} campaign
         * @param  {string} country
         * @param  {string} videoId
         * @return {number}
         */
        function getBudgetForCountryAndMedia(campaign, country, videoId, network, operatingSystem) {
            var ad = getPlacementsFirstAd(campaign.placements[0]);
            if (ad.isVideo) {
                return _.reduce(
                    campaign.placements,
                    function(currentBudget, placement, key) {
                        ad = getPlacementsFirstAd(placement);

                        if (placement.country === country &&
                            ad.video.videoId === videoId &&
                            placement.network === network &&
                            placement.operatingSystem === operatingSystem.value
                        ) {
                            return currentBudget + placement.budget;
                        }
                        return currentBudget;
                    },
                    0
                );
            }

            if (ad.isDisplay) {
                return _.reduce(
                    campaign.placements,
                    function(currentBudget, placement, key) {
                        ad = getPlacementsFirstAd(placement);

                        if (placement.country === country &&
                            ad.id === videoId &&
                            placement.network === network &&
                            placement.operatingSystem === operatingSystem.value
                        ) {
                            return currentBudget + placement.budget;
                        }
                        return currentBudget;
                    },
                    0
                );
            }
        }

        /**
         * Get number of videos on Adwords network
         * @param  {object} campaign
         * @return {boolean}
         */
        function getNumberOfAdwordsVideos(campaign) {
            return getNumberOfVideosInNetwork('adwords', campaign);
        }

        /**
         * Get number of videos on Adwords network
         * @param  {object} campaign
         * @return {boolean}
         */
        function getNumberOfGoogleDisplay(campaign) {
            return getNumberOfAdsInNetwork(tvlConstantUtils.NETWORK_GOOGLE_DISPLAY, campaign);
        }

        /**
         * Get number of videos on Facebook network
         * @param  {object} campaign
         * @return {boolean}
         */
        function getNumberOfFacebookVideos(campaign) {
            return getNumberOfVideosInNetwork('facebook', campaign);
        }

        /**
         * Get number of videos on Instagram network
         * @param  {object} campaign
         * @return {boolean}
         */
        function getNumberOfInstagramVideos(campaign) {
            return getNumberOfVideosInNetwork('instagram', campaign);
        }

        /**
         * Get number of videos on given network
         * @param  {object} campaign
         * @return {boolean}
         */
        function getNumberOfVideosInNetwork(network, campaign) {
            return _.chain(campaign.placements)
                .filter({
                    network: network
                })
                .map('adVideo.video.videoId')
                .uniq()
                .size();
        }

        /**
         * Get number of videos on given network
         * @param  {string} network
         * @param  {object} campaign
         * @return {boolean}
         */
        function getNumberOfAdsInNetwork(network, campaign) {
            var formatsForNetwork = tvlAdVideoUtils.getFormatsByNetwork(network);

            return _.chain(campaign.placements)
                .find(function (placement) {

                    var ad = getPlacementsFirstAd(placement);

                    return formatsForNetwork.indexOf(ad.format) >= 0;
                })
                .map('adVideo.id')
                .uniq()
                .size();
        }

        function getPlacementsFirstAd(placement) {
            var ad = null;
            if (placement.hasOwnProperty('adGroups') && placement.adGroups.length > 0) {
                var adGroup = placement.adGroups[0];
                if (adGroup.hasOwnProperty('ads') && adGroup.ads.length > 0) {
                    ad = placement.adGroups[0].ads[0];
                }
            }
            if (ad === null) {
                ad = placement.adVideo;
                if (_.isArray(ad)) {
                    ad = ad[0];
                }
            }
            return ad;
        }

        /**
         * Get number of unique videos for a given campaign
         * @param  {object} campaign
         * @return {string[]}
         */
        function getUniqueVideos(campaign) {
            var creativities = {};

            for (var p in campaign.placements) {
                var placement = campaign.placements[p];
                var ad = getPlacementsFirstAd(placement);
                if (!ad) {
                    // Force campaign rendering even if there are placements without ads
                    continue;
                }

                if (ad.isVideo) {
                    var video = ad.video;
                    video.network = placement.network;
                    video.isVideo = ad.isVideo;
                    video.isDisplay = ad.isDisplay;
                    video.operatingSystem = operatingSystemService.findOperatingSystemByValue(placement.operatingSystem);
                    video.icons = tvlConstantUtils.getNetworkIconsForPlacement(placement);

                    creativities[video.network + '_' + video.operatingSystem.value + '_' + video.videoId] = video;
                }

                if (ad.isDisplay) {
                    var image = ad;
                    image.network = placement.network;
                    image.operatingSystem = operatingSystemService.findOperatingSystemByValue(placement.operatingSystem);
                    image.thumbnail = image.imageContent;
                    image.title = image.imageName;
                    image.facebookPublisherPlatforms = null;
                    image.icons = tvlConstantUtils.getNetworkIconsForPlacement(placement);
                    image.videoId = image.id;

                    creativities[image.network + '_' + image.operatingSystem.value + '_' + image.id] = image;
                }
            }

            return creativities;
        }

        /**
         * If given campaign applies to Adwords
         * @param  {object} campaign
         * @return {boolean}
         */
        function appliesToAdwords(campaign) {
            return appliesToNetwork('adwords', campaign);
        }

        /**
         * If given campaign applies to Adwords
         * @param  {object} campaign
         * @return {boolean}
         */
        function appliesToAdwordsFormats(campaign) {
            return appliesToNetworkByFormats(tvlConstantUtils.NETWORK_ADWORDS, campaign);
        }

        function appliesToGoogleDisplay(campaign) {
            return appliesToNetwork(tvlConstantUtils.NETWORK_GOOGLE_DISPLAY, campaign);
        }

        function appliesToGoogleDisplayFormats(campaign) {
            return appliesToNetworkByFormats(tvlConstantUtils.NETWORK_GOOGLE_DISPLAY, campaign);
        }

        /**
         * If given campaign applies to Facebook
         * @param  {object} campaign
         * @return {boolean}
         */
        function appliesToFacebook(campaign) {
            return appliesToNetwork('facebook', campaign);
        }

        /**
         * If given campaign applies to Instagram
         * @param  {object} campaign
         * @return {boolean}
         */
        function appliesToInstagram(campaign) {
            return appliesToNetwork('instagram', campaign);
        }

        /**
         * If given campaign has a mobile targeted placement
         * @param  {object} campaign
         * @return {boolean}
         */
        function appliesToMobiles(campaign) {
            return appliesToDevice('Mobile', campaign);
        }

        /**
         * If given campaign has a tablet targeted placement
         * @param  {object} campaign
         * @return {boolean}
         */
        function appliesToTablets(campaign) {
            return appliesToDevice('Tablet', campaign);
        }

        /**
         * If given campaign has desktop targeted placement
         * @param  {object} campaign
         * @return {boolean}
         */
        function appliesToDesktops(campaign) {
            return appliesToDevice('Computer', campaign);
        }

        /**
         * If given campaign applies to a given network
         * @param  {object} campaign
         * @return {boolean}
         */
        function appliesToNetwork(network, campaign) {
            for (var p in campaign.placements) {
                var placement = campaign.placements[p];
                if (placement.network === network) {
                    return true;
                }
            }

            return false;
        }

        /**
         * If given campaign applies to a given network
         * @param  {string} network
         * @param  {object} campaign
         * @return {boolean}
         */
        function appliesToNetworkByFormats(network, campaign) {
            var formats = tvlAdVideoUtils.getFormatsByNetwork(network);

            for (var p in campaign.placements) {
                var placement = campaign.placements[p];
                var ad = getPlacementsFirstAd(placement);

                if (formats.indexOf(ad.format) >= 0) {
                    return true;
                }
            }
            return false;
        }

        /**
         * If given campaign has a given device targeted placement
         * @param  {object} campaign
         * @return {boolean}
         */
        function appliesToDevice(device, campaign) {
            if (campaign.devices) {
                return campaign.devices.indexOf(device) >= 0;
            }

            return false;
        }

        /**
         * Return the appropriate display name for the given ad network.
         *
         * @param {String} network
         * @return {String}
         */
        function getNetworkDisplayName(network) {
            var names = {
                adwords: 'YouTube',
                youtube: 'YouTube', // 'adwords' is sometimes referred to as 'youtube'
                facebook: 'Facebook',
                instagram: 'Instagram',
                googleDisplay: 'Google Display',
            };
            return names[network];
        }

        /**
         * get type of content, this is used for campaign targeting
         *
         * @param  {Object} content
         * @return {String}
         */
        function getContentType(content) {
            if (!content.id) { // URL
                return 'url';
            } else if (content.hasOwnProperty('nItems')) { // list
                return 'list';
            }
            // channel or video
            return (content.id === content.channelId) ? 'channel' : 'video';
        }

        /**
         * Return whether a header row must be displayed before the row
         * representing the placement at the given position.
         *
         * Currently this only occurs when the placement is the first of each
         * CPV/CPM set.
         *
         * @param {Object} campaign
         * @param {Number} placementIdx
         * @return {boolean}
         */
        function placementHeaderApplies(campaign, placementIdx) {
            if (placementIdx === 0) {
                return true;
            }

            var thisPlacement = campaign.placements[placementIdx];
            var previousPlacement = campaign.placements[placementIdx - 1];

            var currentAd = getPlacementsFirstAd(thisPlacement);
            var previousAd = getPlacementsFirstAd(previousPlacement);

            return (tvlAdVideoUtils.isCpmAdVideo(currentAd) && tvlAdVideoUtils.isCpvAdVideo(previousAd))
                || (tvlAdVideoUtils.isCpvAdVideo(currentAd) && tvlAdVideoUtils.isCpmAdVideo(previousAd));
        }

        /**
         * Return whether the cell containing the video thumbnail
         * must be displayed for the placement with the given
         * position within the specified campaign.
         *
         * @param {Object} campaign
         * @param {Number} placementIdx
         * @return {boolean}
         */
        function thumbnailCellApplies(campaign, placementIdx) {
            if (placementIdx === 0) {
                return true;
            }

            var thisPlacement = campaign.placements[placementIdx];
            var previousPlacement = campaign.placements[placementIdx - 1];

            var currentAd = getPlacementsFirstAd(thisPlacement);
            var previousAd = getPlacementsFirstAd(previousPlacement);

            return placementHeaderApplies(campaign, placementIdx) || currentAd.id !== previousAd.id;
        }

        /**
         * Return whether the cell containing the placement country
         * must be displayed for the placement with the given
         * position within the specified campaign.
         *
         * @param {Object} campaign
         * @param {Number} placementIdx
         * @return {boolean}
         */
        function countryCellApplies(campaign, placementIdx) {
            if (placementIdx === 0) {
                return true;
            }

            var thisPlacement = campaign.placements[placementIdx];
            var previousPlacement = campaign.placements[placementIdx - 1];

            return thumbnailCellApplies(campaign, placementIdx) ||
                thisPlacement.country !== previousPlacement.country;
        }

        /**
         * Return the amount of rows that the cell containing the
         * country thumbnail must span for the placement located
         * within the given campaign at the specified position.
         *
         *
         * @param {Object} campaign
         * @param {Number} placementIdx
         * @return {Number}
         */
        function getCountryCellRowspan(campaign, placementIdx) {
            var thisPlacement = campaign.placements[placementIdx];
            var span = 1;
            for (var i = placementIdx + 1; i < campaign.placements.length; i++) {
                var placement = campaign.placements[i];
                if (placementHeaderApplies(campaign, i) ||
                    thumbnailCellApplies(campaign, i) ||
                    thisPlacement.country !== placement.country) {
                    break;
                }
                span++;
            }

            return span;
        }

        /**
         * Return the amount of rows that the cell containing the
         * video thumbnail must span for the placement located
         * within the given campaign at the specified position.
         *
         *
         * @param {Object} campaign
         * @param {Number} placementIdx
         * @return {Number}
         */
        function getThumbnailCellRowspan(campaign, placementIdx) {
            var thisPlacement = campaign.placements[placementIdx];
            var thisAd = getPlacementsFirstAd(thisPlacement);
            var span = 1;
            for (var i = placementIdx + 1; i < campaign.placements.length; i++) {
                var placement = campaign.placements[i];
                var ad = getPlacementsFirstAd(placement);
                if (placementHeaderApplies(campaign, i) || thisAd.id !== ad.id) {
                    break;
                }
                span++;
            }

            return span;
        }

        /**
         * Return whether the given campaign has at least one
         * placement in the specified network.
         *
         * @param {Object} campaign
         * @param {string} network
         * @return {boolean}
         */
        function isCampaignInNetwork(campaign, network) {
            for (var i in campaign.placements) {
                if (campaign.placements[i].network === network) {
                    return true;
                }
            }
            return false;
        }

        /**
         * Return whether the given campaign has at least one
         * placement in Adwords.
         *
         * @param {Object} campaign
         * @return {boolean}
         */
        function isCampaignInAdwords(campaign) {
            return isCampaignInNetwork(campaign, 'adwords');
        }

        /**
         * Return whether the given campaign has at least one
         * placement in Facebook.
         *
         * @param {Object} campaign
         * @return {boolean}
         */
        function isCampaignInFacebook(campaign) {
            return isCampaignInNetwork(campaign, 'facebook');
        }

        /**
         * Return video id from a given url and platform
         *
         * @param  {string} platform
         * @param  {string} url
         * @return {string|null}
         */
        function getVideoIdFromUrl(platform, url) {
            var regex = null;
            switch (platform) {
                case 'youtube':
                    regex = /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/ ]{11})/i;
                    break;
                case 'facebook':
                case 'instagram':
                    regex = /(?:facebook\.com\/(?:.*\/videos\/)?)(?:(\d*))/i;
                    break;
            }
            return regex && url.match(regex) ? url.match(regex)[1] : null;
        }

        /**
         * Return channel id from a given url and platform
         *
         * @param  {string} platform
         * @param  {string} url
         * @return {string|null}
         */
        function getChannelIdFromUrl(platform, url) {
            var regex = null;
            switch (platform) {
                case 'youtube':
                    regex = /(?:youtube\.com\/channel\/)([\w\-]{1,})/i;
                    break;
                case 'facebook':
                    regex = /(?:facebook\.com\/)([\w\-\.]*)/i;
                    break;
            }
            return regex && url.match(regex) ? url.match(regex)[1] : null;
        }

        /**
         * Return username from a given Youtube url
         *
         * @param   {string} url
         * @returns {string|null}
         */
        function getUsernameFromUrl(url) {
            var regex = /(?:youtube\.com\/user\/)([\w\-]{1,})/i;
            return (regex && url.match(regex)) ?
                url.match(regex)[1] :
                null;
        }

        function getCampaignBusinessModels() {
            return [{
                key: 'Fee',
                value: 'fee'
            }, {
                key: 'Rebate',
                value: 'rebate'
            }];
        }

        function getNetworks() {
            return [{
                key: 'Youtube',
                value: 'adwords'
            }, {
                key: 'Facebook / Instagram',
                value: 'facebook'
            }, {
                key: 'Google Display',
                value: 'adwords_display'
            }];
        }

        function getAvailableSubNetworks() {
            return [
                {
                    name: 'YouTube',
                    key: 'adwords_video',
                    icon: 'fa-youtube-play'
                },
                {
                    name: 'Google Display',
                    key: 'adwords_display',
                    icon: 'fa-google'
                },
                {
                    name: 'Google Search',
                    key: 'adwords_search',
                    icon: 'fa-google'
                },
                {
                    name: 'Facebook',
                    key: 'facebook_facebook',
                    icon: 'fa-facebook'
                },
                {
                    name: 'Instagram',
                    key: 'facebook_instagram',
                    icon: 'fa-instagram'
                }

            ];
        }

        function getStatuses() {
            return [{
                key: 'Active',
                value: 'active'
            }, {
                key: 'Canceled',
                value: 'canceled'
            }, {
                key: 'Creating',
                value: 'creating'
            }, {
                key: 'Finished',
                value: 'finished'
            }, {
                key: 'Paused',
                value: 'paused'
            }, {
                key: 'Pending',
                value: 'pending'
            }, {
                key: 'Pending authorization',
                value: 'pending_authorization'
            }, {
                key: 'Rejected',
                value: 'rejected'
            }];
        }

        function getNotCanceledStatuses() {
            return [{
                key: 'Active',
                value: 'active'
            }, {
                key: 'Creating',
                value: 'creating'
            }, {
                key: 'Finished',
                value: 'finished'
            }, {
                key: 'Paused',
                value: 'paused'
            }, {
                key: 'Pending',
                value: 'pending'
            }, {
                key: 'Pending authorization',
                value: 'pending_authorization'
            }, {
                key: 'Rejected',
                value: 'rejected'
            }];
        }

        function getGenders() {
            return [{
                key: 'Male',
                value: 'male'
            }, {
                key: 'Female',
                value: 'female'
            }, {
                key: 'Both',
                value: 'both'
            }];
        }

        function getDevices() {
            return [{
                key: 'Computer',
                value: 'Computer'
            }, {
                key: 'Tablet',
                value: 'Tablet'
            }, {
                key: 'Mobile',
                value: 'Mobile'
            }, {
                key: 'TV Screen',
                value: 'TV Screen'
            }];
        }


        /**
         * Receives the content object and maps it into final format (removes
         * the unnecessary fields, also adds it's type).
         *
         * @param {Object} content
         * @returns {Object}
         */
        function mapContent(content) {
            var contentData = angular.copy(content);
            delete contentData.countries;
            delete contentData.id;
            switch (contentData.type) {
                case 'channel':
                case 'video':
                    delete contentData.media;
                    break;
                case 'url':
                    delete contentData.url;
                    break;
                case 'list':
                    contentData.list = contentData.list.id;
                    break;
            }

            return contentData;
        }

        /**
         * Just like mapContent() but with an array of contents.
         *
         * @param {Array} contents
         * @returns {Array}
         */
        function mapContents(contents) {
            return _.map(
                contents,
                function(content) {
                    return mapContent(content);
                }
            );
        }

        /**
         * Extract the contents from the targeting of the provided placements,
         * and return a unified array where each content includes the countries
         * where it applies.
         *
         * If the `included` flag is true this function returns the included
         * contents, and the excluded ones otherwise.
         *
         * @param {Array} placements - An array of placements as returned by
         * the TVL API.
         * @param {Boolean} included
         */
        function placementsToContents(placements, included) {

            var key = included ? 'includedContents' : 'excludedContents';
            var aggregated = {};
            var uniqId;

            placements.forEach(function (placement) {
                if (placement.targeting && placement.targeting[key]) {
                    placement.targeting[key].forEach(function (content) {
                        if (!content.list && !content.contentId) {
                            return;
                        }
                        if (content.list && content.list.id) {
                            uniqId = 'list' + content.list.id;
                        } else {
                            uniqId = content.type + content.contentId;
                        }
                        if (!aggregated.hasOwnProperty(uniqId)) {
                            aggregated[uniqId] = angular.copy(content);
                            aggregated[uniqId].countries = [];
                        }
                        aggregated[uniqId].countries.push(placement.country);
                    });
                }
            });

            var result = [];
            for (var i in aggregated) {
                var current = aggregated[i];
                /**
                 * If the content applies to all countries in this campaign,
                 * then set countries to null.
                 */
                current.countries = _.uniq(current.countries);
                if (current.countries.length === placements.length) {
                    current.countries = null;
                }
                result.push(current);
            }

            return result;
        }

        /**
         * Return a copy of the provided placements with their targetings
         * modified so that they reflect the given included and excluded
         * contents.
         *
         * Any content targeting not matching the given contents will not be
         * present in the returning copy.
         *
         * The rest of attributes will not be modified.
         *
         * @param {Array} placements
         * @param {Array} includedContents
         * @param {Array} excludedContents
         */
        function contentsToPlacements(placements, includedContents, excludedContents) {
            // this allows us to access these params in a loop with dynamically
            // named variables
            var contents = {includedContents: includedContents, excludedContents: excludedContents};

            var newPlacements = angular.copy(placements);
            newPlacements.forEach(function (placement) {
                ['includedContents', 'excludedContents'].forEach(function (k) {
                    placement.targeting[k] = [];

                    contents[k].forEach(function (content) {
                        if (content.countries && content.countries.indexOf(placement.country) < 0) {
                            return;
                        }

                        placement.targeting[k].push(mapContent(content));
                    });
                });
            });
            return newPlacements;
        }

        /**
         * Returns true if we need to show "scheduled" status instead of "active".
         * Otherwise returns false
         *
         * Receives any object with "startDate" field
         *
         * @param item
         * @returns {boolean}
         */
        function isScheduled(item) {
            if (item.startDate) {
                var startDate = item.startDate;
                if ((startDate instanceof Date) === false) {
                    try {
                        startDate = new Date(startDate);
                    } catch (e) {
                        startDate = null;
                    }
                }
                if (startDate && startDate instanceof Date && startDate.getTime() > (new Date()).getTime()) {
                    return true;
                }
            }
            return false;
        }

        /**
         * Just like mapKeyword() but with an array of keywordsTargeting.
         *
         * @param {Array} keywords
         * @returns {Array}
         */
        function mapKeywords(keywords) {
            /**
             * Receives the keywordsTargeting object and maps it into final format
             *
             * @param {Object} keyword
             * @returns {Object}
             */
            function mapKeyword(keyword) {
                if ('list' in keyword && keyword.list.id) { // list
                    var list = keyword.list.id;
                    return {'list': list};
                } else if ('items' in keyword) { // custom keywords
                    var items = keyword.items;
                    return {'items': items, 'list': null};
                }
            }

            var mappedKeywords = _.map(
                keywords,
                function (keyword) {
                    if (mapKeyword(keyword)) {
                        return mapKeyword(keyword);
                    }
                }
            );
            mappedKeywords.forEach(function (item, key) {
                if (!item) {
                    mappedKeywords.splice(key, 1);
                }
            });

            return mappedKeywords;
        }

        /**
         * @param campaign
         * @param property
         *
         * @return {boolean}
         */
        function isCampaignPresentByAdVideoProperty(campaign, property) {
            var found = _.find(campaign.placements, function(placement) {
                var ad = getPlacementsFirstAd(placement);
                if (!ad) {
                    // Force campaign rendering even if there are placements without ads
                    return false;
                }

                return placement.network === tvlConstantUtils.NETWORK_ADWORDS && ad[property];
            });

            return false === _.isNil(found);
        }

        /**
         * Return whether the given campaign has at least one
         * placement in the specified publisher platform.
         *
         * @param {Object} campaign
         * @param {string} network
         * @return {boolean}
         */
        function isCampaignPresentInFacebookPublisherPlatforms(campaign, network) {
            for (var i in campaign.placements) {
                if (typeof campaign.placements[i].facebookPublisherPlatforms === 'undefined') {
                    return false;
                }

                if (campaign.placements[i].facebookPublisherPlatforms.includes(network)) {
                    return true;
                }
            }
            return false;
        }

        /**
         * Return whether the given campaign has at least one
         * placement in Adwords.
         *
         * @param {Object} campaign
         * @return {boolean}
         */
        function isCampaignPresentInAdwords(campaign) {
            return isCampaignPresentByAdVideoProperty(campaign, 'isVideo');
        }

        /**
         * Return whether the given campaign has at least one
         * placement in Google Display.
         *
         * @param {Object} campaign
         * @return {boolean}
         */
        function isCampaignPresentInGoogleDisplay(campaign) {
            return isCampaignPresentByAdVideoProperty(campaign, 'isDisplay') ||
                isCampaignPresentByAdVideoProperty(campaign, 'isSearch');
        }


        /**
         * Return whether the given campaign has at least one
         * placement in Facebook.
         *
         * @param {Object} campaign
         * @return {boolean}
         */
        function isCampaignPresentInFacebook(campaign) {
            return isCampaignPresentInFacebookPublisherPlatforms(campaign, 'facebook');
        }

        /**
         * Return whether the given campaign has at least one
         * placement in Instagram.
         *
         * @param {Object} campaign
         * @return {boolean}
         */
        function isCampaignPresentInInstagram(campaign) {
            return isCampaignPresentInFacebookPublisherPlatforms(campaign, 'instagram');
        }

        /**
         * Get icons array for campaigns
         *
         * @param {Object} campaign
         * @returns {[]}
         */
        function getNetworkIconsForCampaign(campaign)
        {
            var icons = [];

            if (isCampaignPresentInAdwords(campaign)) {
                icons.push(tvlConstantUtils.ICON_SUBNETWORK_ADWORDS_VIDEO);
            }

            if (isCampaignPresentInGoogleDisplay(campaign)) {
                icons.push(tvlConstantUtils.ICON_SUBNETWORK_ADWORDS_DISPLAY);
            }

            if (isCampaignPresentInFacebook(campaign)) {
                icons.push(tvlConstantUtils.ICON_SUBNETWORK_FACEBOOK_FACEBOOK);
            }

            if (isCampaignPresentInInstagram(campaign)) {
                icons.push(tvlConstantUtils.ICON_SUBNETWORK_FACEBOOK_INSTAGRAM);
            }

            return icons;
        }

        /**
         * Get icons array for campaigns in wizard
         *
         * @param {Object} campaign
         * @param {string} network
         * @returns {[]}
         */
        function getNetworkIconsForCampaignInWizard(campaign, network)
        {
            var icons = [];

            if (campaign.networks.youtube === true) {
                icons.push({
                    title: tvlConstantUtils.TITLE_SUBNETWORK_ADWORDS_VIDEO,
                    icon: tvlConstantUtils.ICON_SUBNETWORK_ADWORDS_VIDEO,
                    network: tvlConstantUtils.NETWORK_YOUTUBE,
                });
            }

            if (campaign.networks.googleDisplay === true) {
                icons.push({
                    title: tvlConstantUtils.TITLE_SUBNETWORK_ADWORDS_DISPLAY,
                    icon: tvlConstantUtils.ICON_SUBNETWORK_ADWORDS_DISPLAY,
                    network: tvlConstantUtils.NETWORK_GOOGLE_DISPLAY,
                });
            }

            if (campaign.networks.facebook === true) {
                campaign.ads[tvlConstantUtils.NETWORK_FACEBOOK].forEach(function(ad) {
                    if (ad.instagramPositions.length > 0 && ad.facebookPositions.length > 0) {
                        pushIconIfNotExists(icons,{
                            title: tvlConstantUtils.TITLE_SUBNETWORK_FACEBOOK_INSTAGRAM,
                            icon: tvlConstantUtils.ICON_SUBNETWORK_FACEBOOK_INSTAGRAM,
                            network: tvlConstantUtils.NETWORK_FACEBOOK,
                        });
                        pushIconIfNotExists(icons,{
                            title: tvlConstantUtils.TITLE_SUBNETWORK_FACEBOOK_FACEBOOK,
                            icon: tvlConstantUtils.ICON_SUBNETWORK_FACEBOOK_FACEBOOK,
                            network: tvlConstantUtils.NETWORK_FACEBOOK,
                        });
                    } else if (ad.instagramPositions.length > 0) {
                        pushIconIfNotExists(icons,{
                            title: tvlConstantUtils.TITLE_SUBNETWORK_FACEBOOK_INSTAGRAM,
                            icon: tvlConstantUtils.ICON_SUBNETWORK_FACEBOOK_INSTAGRAM,
                            network: tvlConstantUtils.NETWORK_FACEBOOK,
                        });
                    } else if (ad.facebookPositions.length > 0) {
                        pushIconIfNotExists(icons,{
                            title: tvlConstantUtils.TITLE_SUBNETWORK_FACEBOOK_FACEBOOK,
                            icon: tvlConstantUtils.ICON_SUBNETWORK_FACEBOOK_FACEBOOK,
                            network: tvlConstantUtils.NETWORK_FACEBOOK,
                        });
                    } else if (ad.instagramPositions.length === 0 && ad.facebookPositions.length === 0) {
                        if (campaign.devices.mobile) {
                            pushIconIfNotExists(icons,{
                                title: tvlConstantUtils.TITLE_SUBNETWORK_FACEBOOK_INSTAGRAM,
                                icon: tvlConstantUtils.ICON_SUBNETWORK_FACEBOOK_INSTAGRAM,
                                network: tvlConstantUtils.NETWORK_FACEBOOK,
                            });
                        }
                        pushIconIfNotExists(icons,{
                            title: tvlConstantUtils.TITLE_SUBNETWORK_FACEBOOK_FACEBOOK,
                            icon: tvlConstantUtils.ICON_SUBNETWORK_FACEBOOK_FACEBOOK,
                            network: tvlConstantUtils.NETWORK_FACEBOOK,
                        });
                    }
                });
            }

            var validIcons = icons;
            if (typeof network !== 'undefined') {
                validIcons = icons.filter(function(icon) {
                    return icon.network === network;
                });
            }

            return validIcons;
        }

        /**
         * Get icons array for campaigns in wizard
         *
         * @param {[]} icons
         * @param {Object} newIcon
         */
        function pushIconIfNotExists(icons, newIcon)
        {
            var index = icons.findIndex(function(icon) {
                return icon.icon === newIcon.icon;
            });

            if (index === -1){
                icons.push(newIcon);
            }
        }

        /**
         * Get icons array for budgets in wizard
         *
         * @param {[]} budgets
         * @param {string} network
         * @param {bool} isMobile
         * @returns {[]}
         */
        function getNetworkIconsForBudgetsInWizard(budgets, network, isMobile)
        {
            var icons = [];

            budgets.forEach(function(budget) {
                if (budget.network === tvlConstantUtils.NETWORK_ADWORDS) {
                    pushIconIfNotExists(icons,{
                        title: tvlConstantUtils.TITLE_SUBNETWORK_ADWORDS_VIDEO,
                        icon: tvlConstantUtils.ICON_SUBNETWORK_ADWORDS_VIDEO,
                        network: tvlConstantUtils.NETWORK_ADWORDS,
                    });
                }

                if (budget.network === tvlConstantUtils.NETWORK_GOOGLE_DISPLAY) {
                    pushIconIfNotExists(icons,{
                        title: tvlConstantUtils.TITLE_SUBNETWORK_ADWORDS_DISPLAY,
                        icon: tvlConstantUtils.ICON_SUBNETWORK_ADWORDS_DISPLAY,
                        network: tvlConstantUtils.NETWORK_GOOGLE_DISPLAY,
                    });
                }

                if (budget.network === tvlConstantUtils.NETWORK_FACEBOOK) {
                    if (budget.instagramPositions.length > 0 && budget.facebookPositions.length > 0) {
                        pushIconIfNotExists(icons,{
                            title: tvlConstantUtils.TITLE_SUBNETWORK_FACEBOOK_INSTAGRAM,
                            icon: tvlConstantUtils.ICON_SUBNETWORK_FACEBOOK_INSTAGRAM,
                            network: tvlConstantUtils.NETWORK_FACEBOOK,
                        });
                        pushIconIfNotExists(icons,{
                            title: tvlConstantUtils.TITLE_SUBNETWORK_FACEBOOK_FACEBOOK,
                            icon: tvlConstantUtils.ICON_SUBNETWORK_FACEBOOK_FACEBOOK,
                            network: tvlConstantUtils.NETWORK_FACEBOOK,
                        });
                    } else if (budget.instagramPositions.length > 0) {
                        pushIconIfNotExists(icons,{
                            title: tvlConstantUtils.TITLE_SUBNETWORK_FACEBOOK_INSTAGRAM,
                            icon: tvlConstantUtils.ICON_SUBNETWORK_FACEBOOK_INSTAGRAM,
                            network: tvlConstantUtils.NETWORK_FACEBOOK,
                        });
                    } else if (budget.facebookPositions.length > 0) {
                        pushIconIfNotExists(icons,{
                            title: tvlConstantUtils.TITLE_SUBNETWORK_FACEBOOK_FACEBOOK,
                            icon: tvlConstantUtils.ICON_SUBNETWORK_FACEBOOK_FACEBOOK,
                            network: tvlConstantUtils.NETWORK_FACEBOOK,
                        });
                    } else if (budget.instagramPositions.length === 0 && budget.facebookPositions.length === 0) {
                        if (isMobile) {
                            pushIconIfNotExists(icons,{
                                title: tvlConstantUtils.TITLE_SUBNETWORK_FACEBOOK_INSTAGRAM,
                                icon: tvlConstantUtils.ICON_SUBNETWORK_FACEBOOK_INSTAGRAM,
                                network: tvlConstantUtils.NETWORK_FACEBOOK,
                            });
                        }
                        pushIconIfNotExists(icons,{
                            title: tvlConstantUtils.TITLE_SUBNETWORK_FACEBOOK_FACEBOOK,
                            icon: tvlConstantUtils.ICON_SUBNETWORK_FACEBOOK_FACEBOOK,
                            network: tvlConstantUtils.NETWORK_FACEBOOK,
                        });
                    }
                }
            });

            var validIcons = icons;
            if (typeof network !== 'undefined') {
                validIcons = icons.filter(function(icon) {
                    return icon.network === network;
                });
            }

            return validIcons;
        }
    }

    angular
        .module('tvl.campaign')
        .service('tvlCampaignUtils', tvlCampaignUtils);
}());
