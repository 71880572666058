(function() {
    'use strict';

    angular
        .module('tvl.brandSafety')
        .directive('tvlPlacementListComparator', tvlPlacementListComparator);

    tvlPlacementListComparator.$inject = ['$timeout', 'ChannelAnalysis'];

    /**
     *
     * @ngInject
     */
    function tvlPlacementListComparator($timeout, ChannelAnalysis) {
        var directive = {
            link: link,
            restrict: 'EA',
            templateUrl: 'brand-safety/placement-list-comparator.html',
            scope: {
                items: '='
            }
        };

        return directive;

        function link(scope, element, attrs) {
            scope.itemsInBrandSafety = null;
            scope.unregisterFns = [];

            scope.unregisterFns = [scope.$watch('items', onItemsChange)];
            scope.$on('$destroy', onDestroy);

            /**
             * Callback function invoked when the list items are changed.
             */
            function onItemsChange(newVal, oldVal) {
                scope.itemsInBrandSafety = null;

                if (newVal && newVal !== oldVal) {
                    scope.itemsInBrandSafety = {
                        total: scope.items.analyzed,
                        pct: scope.items.analyzed * 100 / scope.items.list
                    };
                 }
            }

            function onDestroy() {
                for (var i in scope.unregisterFns) {
                    scope.unregisterFns[i]();
                }
            }
        }
    }
})();
