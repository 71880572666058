(function() {
    'use strict';

    angular
        .module('tvl.brandSafety')
        // max number of items allowed in a list
        .constant('MAX_LIST_RESULTS', 19900)
        // min number of items over which we display a notice regarding list creation time
        .constant('LONG_TIME_NOTICE_THRESHOLD', 50000)
        .controller('BrandSafetyAnalysisController', BrandSafetyAnalysisController)
        .controller('CreateContentsListModalController', CreateContentsListModalController);

    BrandSafetyAnalysisController.$inject = [
        '$scope',
        '$state',
        '$stateParams',
        '$uibModal',
        'toastr',
        'tvlUi',
        'tvlBrandSafety',
        'Interest',
        'Location',
        'MAX_LIST_RESULTS',
        'languages',
        'orderByFilter',
        '$filter',
        'Category',
        'ChannelAnalysis'
    ];

    /* @ngInject */
    function BrandSafetyAnalysisController(
        $scope,
        $state,
        $stateParams,
        $uibModal,
        toastr,
        tvlUi,
        tvlBrandSafety,
        Interest,
        Location,
        MAX_LIST_RESULTS,
        languages,
        orderBy,
        $filter,
        Category,
        ChannelAnalysis
    ) {
        var vm = this;
        vm.MAX_LIST_RESULTS = MAX_LIST_RESULTS;
        vm.tvlUi = tvlUi;
        vm.results = null;
        vm.currentPage = $stateParams.page || 1;
        vm.availableAges = [
            '18-24',
            '25-34',
            '35-44',
            '45-54',
            '55-64',
            '65+'
        ];
        vm.availableGenders = {
            male: 'Male',
            female: 'Female'
        };
        vm.availableInterests = [];
        vm.availableLanguages = {};
        vm.availableLocations = [];
        vm.lastSearchedLocation = null;
        vm.filters = {
            title: $stateParams.title,
            language: $stateParams.language,
            age: $stateParams['age[]'] ||  [],
            gender: $stateParams['gender[]'] ||  [],
            location: $stateParams['location[]'] ||  [],
            interest: $stateParams['interest[]'] ||  [],
            minSubscribers: $stateParams['minSubscribers'],
            maxSubscribers: $stateParams['maxSubscribers'],
            minViewRate: $stateParams['minViewRate'],
            maxViewRate: $stateParams['maxViewRate'],
            minLikePct: $stateParams['minLikePct'],
            maxLikePct: $stateParams['maxLikePct'],
            userTags: $stateParams['userTags[]'] ||  []
        };
        vm.ui = {
            filtersCollapsed: false,
            selectedChannelId: null,
            updating: false
        };

        vm.saveList = saveList;
        vm.showChannelDetails = showChannelDetails;
        vm.searchInterests = searchInterests;
        vm.searchLocations = searchLocations;
        vm.submitForm = submitForm;

        vm.propertyName = 'title'; // Default sort.
        vm.reverse = false;
        vm.sortBy = sortBy;
        vm.deleteTag = deleteTag;
        vm.getRandomColor = getRandomColor;
        vm.materialColors = ['#f44336', '#E91E63', '#9C27B0', '#673AB7', '#3F51B5', '#2196F3', '#03A9F4'];
        vm.showInputs = showInputs;
        vm.visibleInputs = false;
        vm.f = {
            title: '',
            subscribers: {min: 0, max: 10000}
        };
        vm.filterResults = filterResults;
        vm.copyResults;

        vm.addTag = addTag;
        vm.onSelectedTag = onSelectedTag;
        vm.onRemovedTag = onRemovedTag;
        vm.dynamicPopover = {
            templateUrl: 'myPopoverTemplate.html',
            title: 'Add new tag'
        };

        vm.newTags = [];
        vm.newCategories = [];
        vm.saveComment = saveComment;

        // Categories / subcategories
        vm.availableCategories = [];
        vm.searchCategories = searchCategories;

        vm.subscribersSlider = {
            minValue: 0,
            maxValue: 1000000,
            options: {
                floor: 0,
                ceil: 1000000,
                step: 1000,
                onEnd: function() {
                     // console.log('min', vm.subscribersSlider.minValue, 'max', vm.subscribersSlider.maxValue);
                     vm.filters.minSubscribers = vm.subscribersSlider.minValue;
                     vm.filters.maxSubscribers = vm.subscribersSlider.maxValue;
                     vm.submitForm(1);
                }
            }
    };

        activate();

        ////////////////

        function activate() {

            setUpLanguages(languages);

            $scope.unregisterFns = [
                $scope.$watch('vm.currentPage', onCurrentPageChange),
            ];
            $scope.$on('$destroy', onDestroy);

            // perform initial lookup
            submitForm(vm.currentPage);
        }

        /**
         * Map all available languages so that an array of languages such as
         * this:
         *
         * [{"code": "es", "name": "Spanish", ...}, ...]
         *
         * Becomes an object keyed by the languages' codes:
         *
         * {"es": {"name": "Spanish", ...}, ...}
         */
        function setUpLanguages(languages) {
            vm.availableLanguages = _.chain(languages)
                .map(function(i) {
                    return [i.code, i];
                })
                .fromPairs()
                .value();
        }

        /**
         * Convert the current filters into an object that can be used to invoke
         * the Brand Safety API.
         *
         * @return {Object}
         */
        function buildQueryParams() {
            return {
                'page': vm.currentPage,
                'itemsPerPage': 20,
                'title': vm.filters.title,
                'languagesDetected[]': vm.filters.language,
                'ages[]': vm.filters.age,
                'genders[]': vm.filters.gender,
                'locations[]': vm.filters.location,
                'interests[]': _(vm.filters.interest).map(function(i) {
                    return 'uservertical::' + i;
                }).value(),
                'subscriberCountGt': vm.filters.minSubscribers,
                'subscriberCountLt': vm.filters.maxSubscribers,
                'viewRateGt': vm.filters.minViewRate,
                'viewRateLt': vm.filters.maxViewRate,
                'likesPercentGt': vm.filters.minLikePct,
                'likesPercentLt': vm.filters.maxLikePct,
                'userTags[]': vm.filters.user_tags,
            };
        }

        /**
         * Toggle the display of a placement detail row.
         *
         * @param {String} channelId
         */
        function showChannelDetails(channelId) {
            vm.ui.selectedChannelId = (channelId === vm.ui.selectedChannelId) ? null : channelId;
        }

        function onSearchInterestsSuccess(data) {
            vm.availableInterests = data.items;
        }

        /**
         * Search for interests matching the given name.
         *
         * Currently only searchs for AdWords interests as the brand safety
         * report only targets this network for the time being.
         *
         * @param {String} name
         * @return {Promise}
         */
        function searchInterests(name) {
            return Interest.get({
                    searchTerm: name,
                    network: 'adwords'
                })
                .$promise
                .then(onSearchInterestsSuccess);
        }

        function onSearchLocationsSuccess(data) {
            vm.availableLocations = data.items;
        }

        /**
         * Search for locations matching the given name, just countries.
         *
         * @param {String} name
         * @return {Promise}
         */
        function searchLocations(name) {
            if (name !== vm.lastSearchedLocation) {
                vm.lastSearchedLocation = name;
                return Location.get({
                    name: name,
                    'types[]' : 'country'
                }).$promise.then(onSearchLocationsSuccess);
            }
        }

        function onGetPlacementsSuccess(data) {
            vm.results = data;
            vm.results.items = orderBy(vm.results.items, vm.propertyName, vm.reverse);
            for (var i = 0; i < vm.results.items.length; i++) {
                var userTags = [];
                for (var j = 0; j < vm.results.items[i].user_tags.length; j++) {
                    userTags.push({info: vm.results.items[i].user_tags[j], color: getRandomColor(vm.results.items[i].user_tags[j].name)});
                }
                vm.results.items[i].user_tags = userTags;
            }

            vm.copyResults = vm.results.items;
            updateQueryParams();
            return data;
        }

        function onGetPlacementsError(error) {
            toastr.error('Our apologies, we have been unable to get brand safety data. Please, try again later.');
        }

        function onGetPlacementsFinally() {
            vm.ui.updating = false;
        }

        /**
         * Submit the search form, optionally specifying the page to retrieve
         * from the result set.
         *
         * @param {Number} page
         * @return {Promise}
         */
        function submitForm(page) {

            vm.ui.updating = true;

            var query = buildQueryParams();
            vm.currentPage = page;
            return ChannelAnalysis.getPlacements(query, page).$promise
                .then(onGetPlacementsSuccess)
                .catch(onGetPlacementsError)
                .finally(onGetPlacementsFinally);
        }

        /**
         * Populate query params with the current state of the form's filters.
         */
        function updateQueryParams() {
            $state.transitionTo(
                'brand-safety.placement-analysis', {
                    page: vm.currentPage,
                    language: vm.filters.language,
                    'age[]': vm.filters.age,
                    'gender[]': vm.filters.gender,
                    'location[]': vm.filters.location,
                    'interest[]': vm.filters.interest,
                    'minSubscribers': vm.filters.minSubscribers,
                    'maxSubscribers': vm.filters.maxSubscribers,
                    'minViewRate': vm.filters.minViewRate,
                    'maxViewRate': vm.filters.maxViewRate,
                    'minLikePct': vm.filters.minLikePct,
                    'maxLikePct': vm.filters.maxLikePct,
                }, {
                    notify: false
                }
            );
        }

        function showListCreationModal() {
            return $uibModal.open({
                controller: 'CreateContentsListModalController as modal',
                templateUrl: 'brand-safety/create-list-modal.html',
                resolve: {
                    params: buildQueryParams(),
                    nItems: vm.results.total
                }
            }).result;
        }

        function onSaveListSuccess(list) {
            toastr.success('List ' + list.name + ' has been successfully created.');
            $state.transitionTo('brand-safety.contents-lists');
        }

        /**
         * Show the list creation modal and redirect the user to the lists view
         * upon success.
         */
        function saveList() {
            return showListCreationModal().then(onSaveListSuccess);
        }

        /**
         * Callback function invoked when the current page is changed.
         *
         * @param {Number} newVal
         * @param {Number} oldVal
         */
        function onCurrentPageChange(newVal, oldVal) {
            if (parseInt(newVal) === parseInt(oldVal)) {
                return;
            }
            submitForm(newVal);
        }

        /**
         * Callback function invoked before this controller is destroyed.
         */
        function onDestroy() {
            angular.forEach($scope.unregisterFns, function(fn) {
                fn();
            });
        }

        /* New methods */

        /**
         * Method that sorts a column of the table up or down depending
         * on the name of the specified column.
         *
         * @param {String} propertyName
         */
        function sortBy(propertyName) {
            // console.log('BrandSafetyAnalysisController::sortBy() | method called', propertyName);
            vm.reverse = (propertyName !== null && vm.propertyName === propertyName)
                ? !vm.reverse : false;
            vm.propertyName = propertyName;
            vm.results.items = orderBy(vm.results.items, vm.propertyName, vm.reverse);
        };

        /**
         * Method that removes a tag from the list of tags added by the user.
         *
         * @param {String} tag
         * @param {Object} item
         */
        function deleteTag(tag, item) {
            // console.log('BrandSafetyAnalysisController::deleteTag() | method called', tag, item);
            var userTags = [];
            var index = -1;
            for (var i = 0; i < item.user_tags.length; i++) {
                // console.log('item.user_tags[i].info.name', item.user_tags[i].info.name);
                if (item.user_tags[i].info.name !== tag.info.name) {
                    userTags.push(item.user_tags[i].info.id);
                }
                else {
                    index = i;
                }
            }
            if (index !== -1) item.user_tags.splice(index, 1);

            ChannelAnalysis.putChannel({id: item.id}, {"userTags": userTags}).$promise
            .then(onGetPutChannelSuccess)
            .catch(onGetPutChannelError);
        };

        /**
         * Method that generates a random color. This random color is one of the previously defined
         * in materialColors array.
         */
        function getRandomColor(tagName) {
            // console.log('BrandSafetyAnalysisController::getRandomColor() | method called');
            /*
            var min = 0;
            var max = vm.materialColors.length;
            var index = Math.floor(Math.random() * (max-min) + min);
            */
           var index = tagName.length % vm.materialColors.length;
            return vm.materialColors[index];
        }

        /**
         * Show inputs.
         *
         */
        function showInputs() {
            // console.log('BrandSafetyAnalysisController::showInputs() | method called');
            vm.visibleInputs = !vm.visibleInputs;
        };

        /**
         * Method that filters the results depending on the value specified in the column.
         *
         * @param {String} columnName
         */
        function  filterResults(columnName) {

            if (columnName === 'title') {
                vm.filters.title = vm.f.title;
                setTimeout(function() {
                    submitForm(vm.currentPage);
                }, 2000);
            }
        }

        function onGetPutChannelSuccess(data) {
            vm.newTags = [];
        }

        function onGetPutChannelError(error) {
            toastr.error('Our apologies, we have been unable to update brand safety data. Please, try again later.');
        }

        /**
         * Method that adds a new tag to the user's tag list.
         *
         * @param {Object} result
         */
       function addTag(result) {
            // console.log('BrandSafetyAnalysisController::addTag() | method called');

            if (typeof vm.newTags != "undefined") {
                for (var i = 0; i < vm.newTags.length; i++) {
                    result.user_tags.push({info: vm.newTags[i], color: getRandomColor( vm.newTags[i].name)});
                }
            }

            var userTags = [];
            for (var i = 0; i < result.user_tags.length; i++) {
                userTags.push(result.user_tags[i].info.id);
            }

            ChannelAnalysis.putChannel({id: result.id}, {"userTags": userTags}).$promise
                .then(onGetPutChannelSuccess)
                .catch(onGetPutChannelError);
        }

         /**
         * Method that controls when a tag or tags has been selected to filter the results.
         *
         * @param {Object} item
         */
        function onSelectedTag(item) {
            // console.log('BrandSafetyAnalysisController::onSelectedTag() | method called', item);
            var userTags = [];
            for (var i = 0; i < vm.newCategories.length; i++) {
                userTags.push(vm.newCategories[i].id);
            }
            vm.filters.user_tags = userTags;
            setTimeout(function() {
                submitForm(1);
            }, 2000);
        }

         /**
         * Method that controls when a tag or tags has been deleted to filter the results.
         *
         * @param {Object} item
         */
        function onRemovedTag(item) {
            // console.log('BrandSafetyAnalysisController::onRemovedTag() | method called', item, vm.newCategories);
            if (vm.newCategories.length === 0) {
                vm.filters.user_tags = vm.newCategories;
                setTimeout(function() {
                    submitForm(1);
                }, 2000);
            }
        }

         /**
         * Save comment.
         *
         */
        function saveComment(result) {
            // console.log('BrandSafetyAnalysisController::saveComment() | method called');
            ChannelAnalysis.putChannel({id: result.id}, {"userComments": result.user_comments}).$promise
            .then(onGetPutChannelSuccess)
            .catch(onGetPutChannelError);
        }

        /**
         * Search for categories matching the given name.
         *
         *
         * @param {String} name
         * @return {Promise}
         */
        function searchCategories(name) {
            return Category.get({
                    term: name,
                    itemsPerPage: 1000
                })
                .$promise
                .then(onSearchCategoriesSuccess, onSearchCategoriesError);

            function onSearchCategoriesSuccess(data) {
                // console.log('onSearchCategoriesSuccess', data);
                vm.availableCategories = data;
            }

            function onSearchCategoriesError(error) {
                toastr.error('Our apologies, we have been unable to recover any categories. Please, try again later.');
            }
        }
    }

    CreateContentsListModalController.$inject = [
        '$scope',
        '$uibModalInstance',
        'toastr',
        'ContentsList',
        'LONG_TIME_NOTICE_THRESHOLD',
        'params',
        'nItems'
    ];

    /** @ngInject */
    function CreateContentsListModalController(
        $scope,
        $uibModalInstance,
        toastr,
        ContentsList,
        LONG_TIME_NOTICE_THRESHOLD,
        params,
        nItems
    ) {
        var vm = this;
        vm.LONG_TIME_NOTICE_THRESHOLD = LONG_TIME_NOTICE_THRESHOLD;
        vm.createForm = {
            name: null
        };
        vm.creating = false;
        vm.nItems = nItems;

        vm.create = create;
        vm.cancel = cancel;

        function create() {
            vm.creating = true;

            var data = angular.extend({}, params, vm.createForm);
            var list = ContentsList.import({
                    type: 'dynamic'
                }, data)
                .$promise
                .then(onCreateSuccess, onCreateError);

            function onCreateSuccess(list) {
                $uibModalInstance.close(list);
            }

            function onCreateError(error) {
                toastr.error('Our apologies, we have been unable to create this list. Please, try again later.');
                vm.creating = false;
            }
        }

        function cancel() {
            return $uibModalInstance.dismiss();
        }
    }


}());
