(function() {
    'use strict';

    angular
        .module('tvl.control-center')
        .controller('ControlCenterIssueDetailController', ControlCenterIssueDetailController);

    ControlCenterIssueDetailController.$inject = [
        '$scope',
        '$stateParams',
        'tvlControlCenterService',
        'toastr'
    ];

    /* @ngInject */
    function ControlCenterIssueDetailController(
        $scope,
        $stateParams,
        tvlControlCenterService,
        toastr
    ) {
        $scope.issueId = $stateParams.issueId;
        $scope.issue = null;
        $scope.loadingIssue = true;

        loadIssueDetail($scope.issueId);

        function loadIssueDetail(issueId) {
            $scope.loadingIssue = true;

            tvlControlCenterService.issueDetail(issueId, onGetIssueSuccess, onGetIssueError);

            function onGetIssueSuccess(responseContent) {
                $scope.issue = responseContent.data;
                $scope.loadingIssue = false;
            }

            function onGetIssueError(responseContent) {
                toastr.error('Can not fetch any issue, please try again later.');
                $scope.loadingIssue = false;
                //TODO go to list
            }
        }

        $scope.resolveIssue = function() {
            tvlControlCenterService.resolveIssue(
                $scope.issue,
                onResolveIssueSuccess,
                onResolveIssueError,
                preCallback
            );

            function onResolveIssueSuccess(responseContent) {
                toastr.success('Issue '+$scope.issue.supportName+' marked as resolved.');
                loadIssueDetail($scope.issue.id);
            }

            function onResolveIssueError(responseContent) {
                toastr.error('Issue '+$scope.issue.supportName+' could not be resolved, please try again later.');
                $scope.loadingIssue = false;
            }

            function preCallback() {
                $scope.loadingIssue = true;
            }
        };

        $scope.formatFullDate = tvlControlCenterService.formatFullDate;
        $scope.formatDate = tvlControlCenterService.formatDate;
        $scope.formatTime = tvlControlCenterService.formatTime;
        $scope.copyIssueSupportNameToClipboard = tvlControlCenterService.copyIssueSupportNameToClipboard;
    }
})();
