(function () {
    'use strict';

    angular
        .module('tvl.control-center', [
            'ngSanitize',
            'ui.router',
            'angular-loading-bar',
            'toastr',
            'tvl.common'
        ])
        .config(configure);

    configure.$inject = [
        '$locationProvider',
        '$urlRouterProvider',
        '$stateProvider',
        '$httpProvider',
        'cfpLoadingBarProvider'
    ];

    /* @ngInject */
    function configure(
        $locationProvider,
        $urlRouterProvider,
        $stateProvider,
        $httpProvider,
        cfpLoadingBarProvider
    ) {
        $locationProvider.html5Mode(true);
        cfpLoadingBarProvider.includeSpinner = false;
        $stateProvider.state({
            name: 'control-center',
            abstract: true,
            templateUrl: 'base.html',
        });

        $stateProvider.state({
            name: 'control-center.index',
            url: '/control-center/issues',
            controller: 'ControlCenterIndexController as vm',
            templateUrl: 'control-center/index.html',
            reloadOnSearch: false,
            resolve: {
            }
        });

        $stateProvider.state({
            name: 'control-center.issue-detail',
            url: '/control-center/issues/{issueId}',
            controller: 'ControlCenterIssueDetailController as vm',
            templateUrl: 'control-center/issue-detail.html',
            reloadOnSearch: false,
            resolve: {
            }
        });
    }
})();
