(function() {
    'use strict';

    angular
        .module('tvl.brandSafety')
        .constant('BRAND_SAFETY_API_ENDPOINT', 'https://brandsafety.theviralab.com/api')
        .constant('BRAND_SAFETY_API_TOKEN', '12345')
        .service('tvlBrandSafety', tvlBrandSafety);

    tvlBrandSafety.$inject = ['$http', '$timeout', 'BRAND_SAFETY_API_ENDPOINT', 'BRAND_SAFETY_API_TOKEN'];

    /**
     * A client service to communicate with our Brand Safety API.
     *
     * @ngInject
     */
    function tvlBrandSafety($http, $timeout, BRAND_SAFETY_API_ENDPOINT, BRAND_SAFETY_API_TOKEN) {
        return {
            getPlacements: getPlacements,
            getKpis: getKpis,
            compare: compare
        };

        /**
         * Return brand-safety details about placements matching the given
         * query parameters.
         *
         * @param {Object} query
         * @param {Number} page
         * @return {Promise}
         */
        function getPlacements(query, page) {
            if (page === undefined) {
                page = 1;
            }
            query.page = page;
            query.itemsPerPage = 50;
            query.accessToken = BRAND_SAFETY_API_TOKEN;

            return $http({
                method: 'GET',
                url: BRAND_SAFETY_API_ENDPOINT + '/channel',
                params: query
            }).then(function (response) {
                for (var i in response.data.items) {
                    /*
                     * Let's fake the 'safe' flag for the time being.
                     *
                     * The API will set this value eventually.
                     */
                    response.data.items[i].safe = Math.random() >= 0.5;

                    var likesAndDislikes = response.data.items[i].likes + response.data.items[i].dislikes;
                    response.data.items[i].likeRate = response.data.items[i].likes * 100 / likesAndDislikes;
                    response.data.items[i].dislikeRate = response.data.items[i].dislikes * 100 / likesAndDislikes;

                    response.data.items[i].analyzedVideosPct = response.data.items[i].analyzed_videos * 100 / response.data.items[i].video_count;
                }
                response.data.itemsPerPage = 10;
                return response.data;
            });
        }

        /**
         * Return global KPIs for placements matching the given query parameters.
         *
         * @param {Object} query
         * @return {Promise}
         */
        function getKpis(query) {
            query.accessToken = BRAND_SAFETY_API_TOKEN;

            return $http({
                method: 'GET',
                url: BRAND_SAFETY_API_ENDPOINT + '/channels/kpi',
                params: query
            }).then(function (response) {
                return response.data;
            });
        }

        /**
         * Return how many of the given channel IDs are already tracked by the
         * brand safety service.
         *
         * @param {String[]}
         * @return {Promise}
         */
        function compare(channelIds) {
            var query = {accessToken: BRAND_SAFETY_API_TOKEN};

            return $http({
                method: 'POST',
                url: BRAND_SAFETY_API_ENDPOINT + '/channels/comparison',
                params: query,
                data: {channelIds: channelIds}
            }).then(function (response) {
                return response.data;
            });
        }
    }
}());
