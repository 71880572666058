(function() {
    'use strict';

    angular
        .module('tvl.common')
        .factory('tvlYoutube', tvlYoutube);

    tvlYoutube.$inject = ['tvlSession', 'youtubeFactory'];

    /* @ngInject */
    /**
     * A convenience service that wraps angular-youtube-api-factory.
     */
    function tvlYoutube(tvlSession, youtubeFactory) {
        var service = {
            getVideoById: getVideoById,
            getVideosFromChannelById: getVideosFromChannelById,
            getVideosFromSearchByParams: getVideosFromSearchByParams,
            getVideosFromPlaylistById: getVideosFromPlaylistById,
            getChannelById: getChannelById,
            getChannelsById: getChannelsById,
            getChannelByUsername: getChannelByUsername,
            extractChannelIdFromString: extractChannelIdFromString,
            extractMultipleChannelIdsFromText: extractMultipleChannelIdsFromText,
        };
        return service;

        ////////////////

        function getVideoById(params) {
            return tvlSession.getApiKeyFromSessionStorage('YOUTUBE_API_KEY').then(function (key) {
                params.key = key;
                return youtubeFactory.getVideoById(params);
            });
        }

        function getVideosFromChannelById(params) {
            return tvlSession.getApiKeyFromSessionStorage('YOUTUBE_API_KEY').then(function (key) {
                params.key = key;
                return youtubeFactory.getVideosFromChannelById(params);
            });
        }

        function getVideosFromSearchByParams(params) {
            return tvlSession.getApiKeyFromSessionStorage('YOUTUBE_API_KEY').then(function (key) {
                params.key = key;
                return youtubeFactory.getVideosFromSearchByParams(params);
            });
        }

        function getVideosFromPlaylistById(params) {
            return tvlSession.getApiKeyFromSessionStorage('YOUTUBE_API_KEY').then(function (key) {
                params.key = key;
                return youtubeFactory.getVideosFromPlaylistById(params);
            });
        }

        /**
         * Warning: This method is not working properly, see it's library
         * @param {object} params
         */
        function getChannelById(params) {
            return tvlSession.getApiKeyFromSessionStorage('YOUTUBE_API_KEY').then(function (key) {
                params.key = key;
                return youtubeFactory.getChannelById(params);
            });
        }

        /**
         * Returns the user's channel by username from Youtube
         *
         * @param {string} username
         * @returns {promise}
         */
        function getChannelByUsername(username) {
            return tvlSession.getApiKeyFromSessionStorage('YOUTUBE_API_KEY').then(function (key) {
                return youtubeFactory.getChannelByUsername({
                    forUsername: username,
                    key: key
                });
            });
        }

        /**
         * Returns a list channel with the given ids
         *
         * @param {string[]} ids
         * @returns {promise}
         */
        function getChannelsById(ids) {
            return tvlSession.getApiKeyFromSessionStorage('YOUTUBE_API_KEY').then(function (key) {
                return youtubeFactory.getChannelsById({
                    id: ids.join(','),
                    key: key
                });
            });
        }

        /**
         * Extracts valid channel id from string, also used for validations.
         * @param str
         * @returns {*}
         */
        function extractChannelIdFromString(str) {
            //todo review pattern for yt channels, channel list 6016e82e3be6e6553b17fb02 doesn fullfill this pattern
            var pattern = /(\bUC[a-zA-Z0-9_-]{22}\b)/;
            var matches = str.match(pattern);
            if (matches && matches.length > 0) {
                return matches[matches.length - 1];
            }
            return null;
        }

        function extractMultipleChannelIdsFromText(text) {
            var pattern = /(\bUC[a-zA-Z0-9_-]{22}\b)/g;
            var matches = text.match(pattern);
            if (matches && matches.length > 0) {
                return matches;
            }
            return null;
        }
    }
})();
