(function() {
    'use strict';

    angular
        .module('tvl.common')
        .directive('matchingDomain', matchingDomain);

    matchingDomain.$inject = ['tvlUrl'];

    /* @ngInject */
    function matchingDomain(tvlUrl) {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function(scope, elm, attrs, ctrl) {
                ctrl.$validators.matchingDomain = function (modelValue, viewValue) {
                    var isValid = false;
                    var otherFieldValue = attrs.matchingDomain;

                    if (_.isNil(modelValue) || match(modelValue)) {
                        isValid = true;
                    }

                    /**
                     * @param modelValue
                     *
                     * @return {boolean}
                     */
                    function match(modelValue) {

                        return false === _.isEmpty(modelValue)
                            && false === _.isEmpty(otherFieldValue)
                            && tvlUrl.domainsMatch(otherFieldValue, modelValue);
                    }

                    return isValid;
                };

                attrs.$observe('matchingDomain', function(comparisonModel){
                    // Whenever the comparison model changes we'll re-validate
                    ctrl.$validate();
                });
            }
        };
    }
})();
