(function() {
    'use strict';

    angular
        .module('tvl.brandSafety')
        .service('tvlCreateCuratorsListModal', tvlCreateCuratorsListModal);

    tvlCreateCuratorsListModal.$inject = ['$uibModal'];

    /**
     * A service to interact with the channel creation modal.
     *
     * @ngInject
     */
    function tvlCreateCuratorsListModal($uibModal) {
        return {
            openCreationModal: openCreationModal,
            openUpdateModal: openUpdateModal
        };

        /**
         * Open the curators list creation modal.
         *
         * @return {Promise}
         */
        function openCreationModal() {
            var modal = $uibModal.open({
                templateUrl: 'brand-safety/create-curators-list-modal.html',
                controller: 'CreateCuratorsListModalController as vm',
                bindToController: true,
                backdrop: 'static',
            });

            return modal.result;
        }

        /**
         * Open the curators list creation modal.
         *
         * @return {Promise}
         */
        function openUpdateModal() {
            var modal = $uibModal.open({
                templateUrl: 'brand-safety/upload-curators-list-modal.html',
                controller: 'CreateCuratorsListModalController as vm',
                bindToController: true,
                backdrop: 'static',
            });

            return modal.result;
        }
    }
}());
