(function() {
    'use strict';

    angular
        .module('tvl.campaign')
        .controller('CampaignWizardController', CampaignWizardController);

    CampaignWizardController.$inject = [
        '$state',
        '$scope',
        '$location',
        '$log',
        'toastr',
        'SweetAlert',
        'tvlSession',
        'tvlAdVideoUtils',
        'tvlWizardData',
        'CampaignPlacement',
        'Constants',
        'operatingSystemService',
    ];

    /* @ngInject */
    function CampaignWizardController(
        $state,
        $scope,
        $location,
        $log,
        toastr,
        SweetAlert,
        tvlSession,
        tvlAdVideoUtils,
        tvlWizardData,
        CampaignPlacement,
        Constants,
        operatingSystemService
    ) {
        var parent = this;

        parent.unregisterFns = [];
        $scope.wizardData = tvlWizardData;
        $scope.customerAccount = tvlWizardData.customerAccount;

        /*
         * It's important to refresh the form data every time the wizard controller is loaded.
         * Otherwise it will come filled with previously created campaign.
         */
        $scope.wizardData.refreshData();

        $scope.campaignForm = tvlWizardData.campaignForm;
        var initialStep = 0;

        // Patch start
        // $scope.campaignForm = tvlWizardData.getDevelopmentCampaign();
        // initialStep = 2;
        // Patch end

        /* Campaign id for placements creation */
        $scope.importCampaignId = $location.search().campaignId || null;

        // Session information
        $scope.sessionInformation = [];
        $scope.userIsGlobal = false;
        $scope.userPermissions = {};
        $scope.userAccounts = {};
        $scope.userEmail = null;

        parent.nextStep = nextStep;
        parent.prevStep = prevStep;
        parent.goToStep = goToStep;
        parent.save = save;

        activate();

        function activate() {
            tvlSession.getSession().then(
                function(data) {
                    $scope.sessionInformation = data;
                    $scope.userIsGlobal = data.isGlobal;
                    $scope.userPermissions = data.permissions;
                    $scope.userAccounts = data.accounts;
                    $scope.userEmail = data.email;
                }
            );

            parent.unregisterFns = [
                $scope.$on('tvlValidationEnd', function(event, data) {
                    if (data.valid) {
                        $scope.currentStep = $scope.formStepArray[$scope.currentStep.idx + 1];
                        $state.go($scope.currentStep.route);
                    }
                    $scope.continueAvailable = true;
                }),
                $scope.$on('$locationChangeStart', function(event, next) {
                    // @todo is this working actually?
                    if ($scope.currentStep.form && $scope.currentStep.form.$dirty) {
                        event.preventDefault();
                        SweetAlert.swal({
                            title: "Leaving Campaign Creator...",
                            text: "If you leave this page now, you will not be able to recover the campaign data",
                            type: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#DD6B55",
                            confirmButtonText: "Yes",
                            cancelButtonText: "No",
                            closeOnConfirm: true,
                            closeOnCancel: true
                        }, function(isConfirm) {
                            if (isConfirm) {
                                window.location.href = next;
                            }
                        });
                    }
                }),
                $scope.$on('$locationChangeSuccess', function(event, next) {
                    // Before send init event we reset some ui variables
                    // For each step we should send it an init event
                    $scope.$broadcast('tvlInit', $scope.currentStep);
                }),
            ];

            if ($scope.importCampaignId) {
                /* Load campaign for placements creation */
                $scope.wizardData.loadCampaignData($scope.importCampaignId);
            }
        }

        $scope.ui = {
            loadingCountries: true,
            loadingLanguages: true,
            disableFinalSubmit: false,
            disableContinue: false,
            networkNamesError: false,
            duplicatedNetworkNamesError: false
        };

        /*
         * List of Facebook videos for which TVL hasn't been yet given
         * permissions to create ads.
         */
        $scope.videosWithMissingPermissions = [];

        $scope.formStepArray = [{
            key: 'info',
            title: 'Information',
            subtitle: 'You\'re in the right place to create your campaign. Start by choosing the objective that describes what you\'d like to do.',
            route: 'campaign.wizard.information',
            formName: 'wizardInformation',
        }, {
            key: 'targeting',
            title: 'Targeting',
            subtitle: 'Choose among many available options your desired target.',
            route: 'campaign.wizard.targeting',
            formName: 'wizardTargeting',
        }, {
            key: 'ads',
            title: 'Ads set',
            subtitle: 'Select media, text and links to create one or multiple ads.',
            route: 'campaign.wizard.ads',
            formName: 'wizardAds',
        }, {
            key: 'networkDistribution',
            title: 'Network distribution',
            subtitle: 'How do you want to distribute your budget between networks.',
            route: 'campaign.wizard.network-distribution',
            formName: 'wizardNetworkDistribution',
        }, {
            key: 'mediaDistribution',
            title: 'Media distribution',
            subtitle: 'How do you want to distribute your budget between videos and countries.',
            route: 'campaign.wizard.media-distribution',
            formName: 'wizardMediaDistribution',
        }, {
            key: 'schedule',
            title: 'Schedule',
            subtitle: 'Fine tune the time period when each ad will run',
            route: 'campaign.wizard.schedule',
            formName: 'wizardSchedule',
        }, {
            key: 'events',
            title: 'Rules & events',
            subtitle: 'Make your campaign react to real-time events and apply a set of optimization rules',
            route: 'campaign.wizard.events',
            formName: null,
        }, {
            key: 'users',
            title: 'User access',
            subtitle: 'Select people who will have access to your campaign.',
            route: 'campaign.wizard.user-access',
            formName: 'wizardUserAccess',
        }, {
            key: 'review',
            title: 'Review',
            subtitle: 'Here\'s what you\'ll be creating. You can modify these objects in the previous steps.',
            formName: null,
            route: 'campaign.wizard.review',
        }];

        if ($scope.importCampaignId) {
            $scope.formStepArray = [
                $scope.formStepArray[2],
                $scope.formStepArray[3],
                $scope.formStepArray[4],
                $scope.formStepArray[5],
                $scope.formStepArray[8],
            ];
        }

        // dynamically populate the step indices
        for (var i in $scope.formStepArray) {
            $scope.formStepArray[i].idx = Number.parseInt(i);
        }

        $scope.currentStep = $scope.formStepArray[initialStep];

        /**
         * If current route doest not mach with our currentStep route
         * we have to redirect the user to the right step
         */
        if ($state.current.name !== $scope.currentStep.route) {
            $state.go($scope.currentStep.route);
        }

        $scope.$broadcast('tvlInit', $scope.currentStep);

        $scope.countries = [];
        $scope.languages = [];
        $scope.interests = [];
        $scope.topics = [];
        $scope.excludedTopics = [];

        // actual campaign model
        $scope.campaign = null;

        Constants.getLanguages()
            .$promise
            .then(function(languages) {
                $scope.languageList = languages;
                languages.forEach(function(language) {
                    $scope.languages[language.code] = language.name;
                });
                $scope.ui.loadingLanguages = false;
            })
            .catch(onConstantsLoadError);

        function onConstantsLoadError() {
            toastr.error('Our apologies, an unexpected error occurred while loading this form. Please, try again later.');
        }

        $scope.minAge = 13;
        $scope.maxAge = 65;
        $scope.continueAvailable = true;

        $scope.validNetworkDistribution = false;
        $scope.validMediaDistribution = {
            googleDisplay: false,
            youtube: false,
            facebook: false,
            instagram: false,
            total: false
        };

        $scope.validNetworksMedia = true;
        $scope.validCountryMedia = true;
        $scope.disableUserSelect = false;
        $scope.showValidEmailText = true;
        $scope.validYoutube = true;
        $scope.validFacebookInstagram = true;
        $scope.disableNewUserCreateButton = true;
        $scope.colorSpaceError = false;
        $scope.countryMediaList = {};

        $scope.getSelectedOperatingSystems = function() {
            var selectedOperatingSystems = {};

            for (var operatingSystemKey in $scope.campaignForm.operatingSystems) {
                var operatingSystem = $scope.campaignForm.operatingSystems[operatingSystemKey];

                if (operatingSystem.selected) {
                    selectedOperatingSystems[operatingSystemKey] = operatingSystem;
                }
            }

            return selectedOperatingSystems;
        };

        $scope.selectAllOperatingSystems = function() {
            $scope.resetOperatingSystems();
            $scope.campaignForm.operatingSystems['all'].selected = true;
        };

        $scope.isAnyNonNullOperatingSystemSelected = function() {
            var anyNonNullSelected = false;

            for (var operatingSystem in $scope.campaignForm.operatingSystems) {
                var item = $scope.campaignForm.operatingSystems[operatingSystem];

                if (null !== item.value && item.selected) {
                    anyNonNullSelected = true;
                }
            }

            return anyNonNullSelected;
        };

        $scope.deselectAllOperatingSystems = function () {
            $scope.campaignForm.operatingSystems['all'].selected = false;
        };

        $scope.checkOperatingSystems = function() {
            if ($scope.isAnyNonNullOperatingSystemSelected()) {
                $scope.deselectAllOperatingSystems();
            } else {
                $scope.selectAllOperatingSystems();
            }
        };

        $scope.resetOperatingSystems = function() {
            for (var operatingSystem in $scope.campaignForm.operatingSystems) {
                $scope.campaignForm.operatingSystems[operatingSystem].selected = false;
            }
        };

        var originalOperatingSystems = operatingSystemService.getAvailableOperatingSystems();
        $scope.campaignForm.operatingSystems = JSON.parse(JSON.stringify(originalOperatingSystems));
        $scope.selectAllOperatingSystems();

        // the mocked campaign structure to pass onto the
        // last wizard step (review)
        $scope.campaignStub = null;

        function goToStep(stepIndex) {
            if (stepIndex < $scope.currentStep.idx) {
                $scope.currentStep = $scope.formStepArray[stepIndex];
                $state.go($scope.currentStep.route);
            }
        };

        /**
         * Move to the next step in the wizard.
         *
         * If a validation function is set on the current step,
         * it will be run first before determining whether the
         * wizard can move on to the next one.
         */
        function nextStep() {
            if ($scope.currentStep.idx === $scope.formStepArray.length - 1) {
                $log.warning('Already at last step, cannot go forward any further');
            } else {
                // @todo move this property to ui
                $scope.continueAvailable = false;
                $scope.$broadcast('tvlValidate', $scope.currentStep);
            }
        }

        /**
         * Move to the previous step in the wizard.
         */
        function prevStep() {
            if ($scope.currentStep.idx === 0) {
                $log.warning('Already at first step, cannot go backwards any further');
            } else {
                $scope.currentStep = $scope.formStepArray[$scope.currentStep.idx - 1];
                $state.go($scope.currentStep.route);
            }
        }

        function save(startAsPaused) {

            /* Create placements */
            if ($scope.importCampaignId && $scope.createPlacements) {
                var params = {
                    'id': $scope.importCampaignId,
                };

                var responses = 0;

                var isAtLeastOneSuccess = false;

                for (var key in $scope.createPlacements) {
                    CampaignPlacement.create(params, $scope.createPlacements[key])
                        .$promise
                        .then(function() {
                            isAtLeastOneSuccess = true;
                            toastr.info('Yay! Your new placements have been scheduled for creation and should be online very soon.');
                        }, function() {
                            toastr.error('Our apologies, we have been unable to create one of your placements. Please, try again in a few minutes.', {
                                closeButton: true,
                                tapToDismiss: true,
                                timeOut: 0,
                            });
                        }).finally(function() {
                            responses++;
                            if (responses == $scope.createPlacements.length && isAtLeastOneSuccess) {
                                let campaignId = $scope.importCampaignId;
                                window.location.href = '/campaigns/' + campaignId;
                            }
                        });
                }
                return;
            }

            $scope.ui.disableFinalSubmit = true;
            var campaignRules = [];
            $scope.campaign.rules.forEach(function(rule) {
                var ruleLocations = [];
                rule.locations.forEach(function(location) {
                    ruleLocations.push(location.id);
                });
                rule.locations = ruleLocations;
                campaignRules.push(rule);
            });
            $scope.campaign.rules = campaignRules;
            $scope.campaign.startAsPaused = startAsPaused;
            return $scope.campaign.$save()
                .then(onSaveSuccess)
                .catch(onSaveError);

            function onSaveSuccess(campaign, putResponseHeaders) {

                let campaignId = campaign.id;
                window.location.href = '/campaigns/' + campaignId;
            }

            function onSaveError(err) {
                $scope.ui.disableFinalSubmit = false;
                if (err.data && err.data.errors) {
                    toastr.error(err.data.errors[0].message);
                } else {
                    toastr.error('Our apologies, we have been unable to create your campaign. Please, try again in a few minutes.');
                }
            }
        }

        // Methods for general purpose

        // @todo move to base step class? campaign form utils?
        $scope.youtubeFormatExists = function(format) {
            for (i in $scope.campaignForm.ads.youtube) {
                if ($scope.campaignForm.ads.youtube[i]['format'] === format) {
                    return true;
                }
            }
            return false;
        };
        $scope.youtubeGoalExists = function(goal) {
            for (var i in $scope.campaignForm.ads.youtube) {
                if ($scope.campaignForm.ads.youtube[i]['goal'] === goal) {
                    return true;
                }
            }
            return false;
        };

        $scope.googleDisplayBiddingTypeExists = function(biddingType) {
            for (var i in $scope.campaignForm.ads.googleDisplay) {
                if ($scope.campaignForm.ads.googleDisplay[i]['biddingStrategyType'] === biddingType) {
                    return true;
                }
            }
            return false;
        };

        /**
         * Checks if Youtube ads contain at lease one in-stream type ad video.
         */
        $scope.youtubeInStreamAdVideoExists = function() {
            for (i in $scope.campaignForm.ads.youtube) {
                if (tvlAdVideoUtils.isAdVideoInStreamFamily($scope.campaignForm.ads.youtube[i]['format'])) {
                    return true;
                }
            }
            return false;
        };

        $scope.googleDisplayExists = function() {
            return 0 < $scope.campaignForm.ads.googleDisplay.length;
        };

        $scope.instagramFormatExists = function(format) {
            for (i in $scope.campaignForm.ads.instagram) {
                if ($scope.campaignForm.ads.instagram[i]['format'] === format) {
                    return true;
                }
            }
            return false;
        };

        // @todo move to base step class?
        $scope.facebookGoalExists = function(goal) {
            for (i in $scope.campaignForm.ads.facebook) {
                if ($scope.campaignForm.ads.facebook[i]['goal'] === goal) {
                    return true;
                }
            }
            return false;
        };

        $scope.instagramGoalExists = function(goal) {
            for (i in $scope.campaignForm.ads.instagram) {
                if ($scope.campaignForm.ads.instagram[i]['goal'] === goal) {
                    return true;
                }
            }
            return false;
        };

        $scope.isGoogleNetwork = function(network) {
            return network === 'googleDisplay' || network === 'youtube';
        };

        $scope.isYoutubeSubNetwork = function(network) {
            return network === 'youtube';
        };
        /**
         * Checks is dates diff 24 hours.
         *
         * @param endDate
         * @param startDate
         *
         * @returns boolean
         */
        $scope.isDatesDiffAtLeast24Hours = function(endDate, startDate) {
            var oneDayMilliseconds = 24*60*60*1000;

            return endDate - startDate >= oneDayMilliseconds;
        };

        /**
         * Checks if date is more than 180 days from now
         *
         * @param date
         * @returns {boolean}
         */
        $scope.ensureDateLessThan180Days = function(date) {
            var dateToCheck = moment(date);
            var now = moment();

           return dateToCheck.endOf('day').diff(now.startOf('day'), 'days') <= 180;
        };

        $scope.$on('$destroy', function() {
            for (var i in parent.unregisterFns) {
                parent.unregisterFns[i]();
            }
        });
    }
})();
