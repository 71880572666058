(function() {
    'use strict';

    angular
        .module('tvl.group')
        .controller('GroupListController', GroupListController);

    GroupListController.$inject = [
        '$scope',
        'Group',
        'resourceResponse',
        '$uibModal'
    ];

    /* @ngInject */
    function GroupListController(
        $scope,
        Group,
        resourceResponse,
        $uibModal
    ) {
        $scope.groups = [];
        $scope.loading = true;
        $scope.createGroup = createGroup;

        getGroups();

        function getGroups() {
            return Group.get()
                .$promise
                .then(success)
                .catch(resourceResponse.error)
                .finally(loadFinished);

            function success(result) {
                $scope.groups = result.data;
            }

            function loadFinished() {
                $scope.loading = false;
            }
        }

        function createGroup() {
            return showCreationModal().then(onCreateSuccess);

            function onCreateSuccess(group) {}
        }

        function showCreationModal() {
            return $uibModal.open({
                controller: 'GroupCreateModalController',
                templateUrl: 'group/create-modal.html',
                bindToController: true,
                backdrop: 'static'
            }).result;
        }
    }
})();
