(function() {
    'use strict';

    angular
        .module('tvl.campaign')
        .controller('WizardTargetingController', WizardTargetingController);

    WizardTargetingController.$inject = [
        '$scope',
        'toastr',
        'tvlForm',
        'campaignValidation',
        'Interest',
        'Location',
        'Topic'
    ];

    /* @ngInject */
    function WizardTargetingController(
        $scope,
        toastr,
        tvlForm,
        campaignValidation,
        Interest,
        Location,
        Topic
    ) {
        var vm = this;
        vm.parent = $scope.$parent;
        vm.campaignForm = vm.parent.campaignForm;
        vm.wizardUtils = vm.parent.wizardData.utils;
        vm.customerAccount = vm.parent.wizardData.customerAccount;

        vm.unregisterFns = [];
        vm.locations = [];
        vm.previousLocations = [];
        vm.locationTypeSelected = null;
        vm.allowsKeywordTargeting = false;
        vm.ui = {
            ageSliderOptions: {floor: 13, ceil: 65, hideLimitLabels: true},
            ageSliderOptionsGY: {floor: 18, ceil: 65, hideLimitLabels: true},
        };

        vm.FREQUENCY_CAP_VIEWS = 'views';
        vm.FREQUENCY_CAP_IMPRESSIONS = 'impressions';
        vm.FREQUENCY_CAP_MAX_VALUE = 2147483647;

        vm.canTargetLocation = canTargetLocation;
        vm.searchInterests = searchInterests;
        vm.searchLocations = searchLocations;
        vm.searchTopics = searchTopics;
        vm.onLocationSelected = onLocationSelected;
        vm.onLocationRemoved = onLocationRemoved;

        vm.frequencyCapDiscardValues = null;
        vm.onFrequencyCapValueChanged = onFrequencyCapValueChanged;
        vm.switchShowFrequencyCapForm = switchShowFrequencyCapForm;

        activate();

        ////////////////

        function activate() {
            vm.unregisterFns = [
                $scope.$on('tvlValidate', onValidate),
                $scope.$on('tvlInit', onInit),
                $scope.$watch('vm.campaignForm.targeting.locations', validateLocationMultiNetwork, true),
            ];

            $scope.$on('$destroy', function () {
                for(var i in vm.unregisterFns) {
                    vm.unregisterFns[i]();
                }

                /**
                 * Avoid elements with empty "items" node
                 */
                vm.campaignForm.targeting.selectedKeywords.included = _.filter(vm.campaignForm.targeting.selectedKeywords.included, function(keyword){
                    if (keyword.hasOwnProperty('items')) {
                        return keyword.items.length > 0;
                    }
                    return true;
                });
                vm.campaignForm.targeting.selectedKeywords.excluded = _.filter(vm.campaignForm.targeting.selectedKeywords.excluded, function(keyword){
                    if (keyword.hasOwnProperty('items')) {
                        return keyword.items.length > 0;
                    }
                    return true;
                });
            });
        }

        /**
         * Return whether the given location can be targeted with the current
         * campaign configuration.
         *
         * A location can be targeted if it is available in all the networks
         * where the campaign will run.
         *
         * @param {Object} location
         * @return {Boolean}
         */
        function canTargetLocation(location) {
            var isInFacebookAny = vm.wizardUtils.isAnyFacebookNetworkSelected() && 'facebook' === location.network;
            var isInYoutube = vm.wizardUtils.isAnyGoogleNetworkSelected() && 'adwords' === location.network;

            return isInFacebookAny || isInYoutube;
        }

        // Validation

        function validate() {
            return tvlForm.validate($scope['wizardTargeting']);
        }

        function searchInterests(searchTerm) {
            var advertisesInBothNetworks = vm.wizardUtils.isAnyGoogleNetworkSelected() &&
                vm.wizardUtils.isAnyFacebookNetworkSelected();

            var networkToSearch = advertisesInBothNetworks ? null :
                (vm.wizardUtils.isAnyGoogleNetworkSelected() ? 'adwords' : 'facebook');

            var params = {
                searchTerm: searchTerm,
                network: networkToSearch,
                enabled: 1,
                items_per_page: 100,
                campaignNetworks: vm.campaignForm.networks
            };

            if (vm.customerAccount.adwordsConfiguration && vm.customerAccount.adwordsConfiguration.accountId) {
                params['clientId'] = vm.customerAccount.adwordsConfiguration.accountId;
            }

            return Interest.get(params).$promise
                .then(onGetInterestsSuccess)
                .catch(onGetInterestsError);

            function onGetInterestsSuccess(data) {
                vm.parent.availableInterests = data.items;
            }

            function onGetInterestsError(reason) {
                toastr.error('Our apologies, we have been unable to retrieve interests for you. Please, try again in a few moments.');
            }
        }

        function searchLocations(name) {
            var params = {name: name};
            var campaignOnFacebook = vm.wizardUtils.isAnyFacebookNetworkSelected();
            var campaignOnGoogle = vm.wizardUtils.isAnyGoogleNetworkSelected();
            var multiNetwork = campaignOnGoogle && campaignOnFacebook;
            if (!multiNetwork) {
                params['network'] = (campaignOnFacebook) ? 'facebook' : 'adwords';
            }

            return Location.get(params).$promise.then(onSearchLocationsSuccess);
        }

        function onSearchLocationsSuccess(data) {
            var locations = data.items;
            locations = keepTargeteableLocations(locations);

            vm.locations = locations;
        }

        function keepTargeteableLocations(locations) {
            var targeteableLocations = [];

            for (var key in locations) {
                var location = locations[key];

                if (canTargetLocation(location)) {
                    targeteableLocations.push(location)
                }
            }

            return targeteableLocations;
        }

        function getFilteredLocationsByType(locations, type) {
            var filteredLocations = [];

            for (var key in locations) {
                var location = locations[key];

                if (type === location.type) {
                    filteredLocations.push(location);
                }
            }

            return filteredLocations;
        }

        function searchTopics(searchTerm) {
            var advertisesInBothNetworks = vm.wizardUtils.isAnyGoogleNetworkSelected() &&
                vm.wizardUtils.isAnyFacebookNetworkSelected();

            var params = {
                searchTerm: searchTerm,
                network: advertisesInBothNetworks ? null : (vm.wizardUtils.isAnyGoogleNetworkSelected() ? 'adwords' : 'facebook'),
                enabled: 1,
                items_per_page: 20
            };

            return Topic.get(params).$promise
                .then(onGetTopicsSuccess)
                .catch(onGetTopicsError);

            function onGetTopicsSuccess(data) {
                vm.parent.availableTopics = data.items;
            }

            function onGetTopicsError(reason) {
                toastr.error('Our apologies, we have been unable to retrieve topics for you. Please, try again in a few moments.');
            }
        }

        function syncCampaignCountries() {
            var countries = _.uniq(_.map(vm.campaignForm.targeting.locations, 'country'));
            vm.campaignForm.countries = countries;

            _.forEach(vm.campaignForm.adDistribution, function (networkValue, networkKey) {
                _.forEach(networkValue, function (adValue, adKey) {
                    _.forEach(adValue, function(countryValue, countryKey) {
                        if (countries.indexOf(countryKey) === -1) {
                            delete vm.campaignForm.adDistribution[networkKey][adKey][countryKey];
                        }
                    });
                });
            });
        }

        function onLocationSelected(item, model) {
            syncCampaignCountries();
            checkLocationsOverlap();
        }

        function onLocationRemoved(item, model) {
            syncCampaignCountries();
            checkLocationsOverlap();
        }

        /**
         * Check locations overlap.
         */
        function checkLocationsOverlap() {
            vm.locationTypeSelected = null;
            var isFacebookNetwork = vm.campaignForm.networks.facebook || vm.campaignForm.networks.instagram;

            if (isFacebookNetwork && vm.campaignForm.targeting.locations.length > 0) {
                vm.locationTypeSelected = vm.campaignForm.targeting.locations[0].type;
                vm.locations = getFilteredLocationsByType(vm.locations, vm.locationTypeSelected);
            }
        }

        function removeDifferentLevelLocationsOnFacebook() {
            var added = _.differenceBy(vm.campaignForm.targeting.locations, vm.previousLocations, 'networkId');

            //check if added location is from facebook, if so, delete different level previous locations for the same country
            if (_.isObject(added[0])) {
                var correctLocations = [];

                if (added[0].network === 'facebook') {
                    correctLocations = _.remove(vm.campaignForm.targeting.locations, function(location) {
                        return !(
                            added[0].country === location.country
                            && added[0].type !== location.type
                            && location.network === 'facebook'
                        );
                    });
                    vm.campaignForm.targeting.locations = correctLocations;
                }
            }

            vm.previousLocations = vm.campaignForm.targeting.locations;
        }


        function validateLocationMultiNetwork() {

            removeDifferentLevelLocationsOnFacebook();

            var networks = vm.campaignForm.networks;
            var multiNetwork = networks.youtube && (networks.facebook || networks.instagram);
            var locationNetworks = _.uniq(
                _.map(vm.campaignForm.targeting.locations, 'network')
            );

            if (!multiNetwork || locationsHaveAllNetworks()) {
                $scope.wizardTargeting.locations.$setValidity('locationMultiNetwork', true);
            } else  {
                $scope.wizardTargeting.locations.$setValidity('locationMultiNetwork', false);
            }

            function locationsHaveAllNetworks() {
                return multiNetwork && locationNetworks.indexOf('facebook') !== -1 && locationNetworks.indexOf('adwords') !== -1;
            }
        }

        function onInit(event, step) {
            /*
             * Currently, we only allow keyword-based targeting for Adwords
             * campaigns, so we check this every time this step is loaded.
             * If it is not allowed we clear any keyword that might have been
             * already set.
             */
            // @todo: wtf, then dont allow keywords on fb campaigns, instead of doing this.
            vm.allowsKeywordTargeting = vm.wizardUtils.isAnyGoogleNetworkSelected();
            if (!vm.allowsKeywordTargeting) {
                vm.campaignForm.targeting.selectedKeywords = [];
            }
        }

        function onValidate(event, step) {
            if (step.key === 'targeting') {
                vm.parent.$emit(
                    'tvlValidationEnd',
                    {valid: validate() && campaignValidation.validateCreationTargeting(vm.campaignForm)}
                );
            }
        }

        /**
         * Hide/show frequency cap's form
         */
        function switchShowFrequencyCapForm() {
            if (vm.ui.showFrequencyCapForm) {
                removeFrequencyCapValues();
                vm.ui.showFrequencyCapForm = false;
            } else {
                vm.ui.showFrequencyCapForm = true;
            }
        }

        /**
         * Changes frequency cap value
         */
        function onFrequencyCapValueChanged(type) {
            var value = (type === vm.FREQUENCY_CAP_VIEWS)
                ? vm.campaignForm.frequencyCapViews
                : vm.campaignForm.frequencyCapImpressions;
            value = parseInt(value);
            if (isNaN(value)) {
                value = 0;
            }
            if (value > vm.FREQUENCY_CAP_MAX_VALUE) {
                value = vm.FREQUENCY_CAP_MAX_VALUE;
            } else if (value < 0) {
                value = 0;
            }

            if (type === vm.FREQUENCY_CAP_VIEWS) {
                vm.campaignForm.frequencyCapViews = value;
                if (vm.campaignForm.frequencyCapViews === 0) {
                    vm.campaignForm.frequencyCapViews = null;
                    vm.campaignForm.frequencyCapViewsTime = null;
                } else if (vm.campaignForm.frequencyCapViewsTime === null) {
                    vm.campaignForm.frequencyCapViewsTime = 'day';
                }
            } else {
                vm.campaignForm.frequencyCapImpressions = value;
                if (vm.campaignForm.frequencyCapImpressions === 0) {
                    vm.campaignForm.frequencyCapImpressions = null;
                    vm.campaignForm.frequencyCapImpressionsTime = null;
                } else if (vm.campaignForm.frequencyCapImpressionsTime === null) {
                    vm.campaignForm.frequencyCapImpressionsTime = 'day';
                }
            }
        }

        /**
         * Remove frequency cap values
         */
        function removeFrequencyCapValues() {
            vm.campaignForm.frequencyCapViews = null;
            vm.campaignForm.frequencyCapViewsTime = null;
            vm.campaignForm.frequencyCapImpressions = null;
            vm.campaignForm.frequencyCapImpressionsTime = null;
        }
    }
})();
