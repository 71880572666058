(function() {
    angular
        .module('tvl.common')
        .directive("tvlItemImgTitle", tvlItemImgTitle);

    function tvlItemImgTitle(tvlUi) {
        var directive = {
            restrict   : "EA",
            replace    : true,
            scope      : {
                item: '='
            },
            templateUrl: "common/item-img-title.html",
            link       : link

        };

        function link($scope, element, attrs) {
            $scope.tvlUi = tvlUi;
            $scope.dataAvailableSince = $scope.item.dataAvailableSince ? $scope.item.dataAvailableSince : $scope.item.videoDataAvailableSince;
        }

        return directive;
    }
})();
