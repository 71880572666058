const moment = require('moment');

(function () {
    'use strict';

    angular
        .module('tvl.campaign')
        .controller('NetworkAccountCampaignsDashboard', NetworkAccountCampaignsDashboard)
        .controller('ImportedCampaignsModalController', ImportedCampaignsModalController)
        .controller('NetworkAccountsCampaignsModalController', NetworkAccountsCampaignsModalController);

    NetworkAccountCampaignsDashboard.$inject = [
        '$scope',
        'Account',
        'Campaign',
        'toastr',
        'resourceResponse',
        '$uibModal',
        'NgTableParams',
        'forImport'
    ];

    /* @ngInject */
    function NetworkAccountCampaignsDashboard(
        $scope,
        Account,
        Campaign,
        toastr,
        resourceResponse,
        $uibModal,
        NgTableParams,
        forImport
    ) {
        var vm = this;
        vm.forImport = forImport;
        vm.loading = true;
        vm.accounts = null;
        vm.accountCampaigns = null;
        vm.tableParams = null;
        vm.dataset = null;
        vm.importing = false;
        vm.importing_index = null;

        vm.filters = {
            account: null,
            searchTerm: null,
            type: ['DISPLAY','SEARCH'],
        };
        vm.campaignParams = {
            page: 1,
            items_per_page: 10,
            id: null
        };

        vm.endOfTime = '2037-12-30';

        vm.searchCampaigns = searchCampaigns;
        vm.searchAccounts = searchAccounts;
        vm.showDateFilterReportsModal = showDateFilterReportsModal;
        vm.showImportedCampaignModal = showImportedCampaignModal;
        vm.formatEndDate = formatEndDate;
        vm.reset = reset;

        searchAccounts(null);

        function reset() {
            vm.accountCampaigns = null;
            vm.tableParams = null;
            vm.filters.searchTerm = null;
        }

        function searchAccounts(searchTerm) {
            var params = {
                term: searchTerm || null,
                enabled: true,
                items_per_page: 40,
            };

            Account.get(params).$promise
                .then(onGetAccountsSuccess, onGetAccountsError)
                .finally(function () {
                    vm.loading = false;
                });

            function onGetAccountsSuccess(accounts) {
                vm.accounts = accounts.items;
            }

            function onGetAccountsError(error) {
                toastr.error('Our apologies, we have been unable to recover your accounts. Please, try again later.');
            }
        }

        function searchCampaigns() {

            vm.loading = true;
            vm.tableParams = null;
            return initDatatable();

        }

        function initDatatable() {
            vm.tableParams = new NgTableParams(
                {
                    count: 10,
                },
                {
                    counts: [10, 25, 50, 100],
                    getData: getDataTableData
                }
            );
            vm.tableParams.reload();
        }

        /**
         * Method invoked by the datatable component everytime it needs to
         * fetch new data from the API.
         *
         * @param {Object} params
         * @return {Promise}
         */
        function getDataTableData(params) {

            // if (vm.forImport) {
            //     vm.filters.type = ['DISPLAY'];
            // }

            vm.campaignParams = {
                page: params.page(),
                items_per_page: params.count(),
                id: vm.filters.account.id,
                'type[]': vm.filters.type,
                searchTerm: vm.filters.searchTerm,
            };

            return refreshAccountCampaigns()
                .then(function (data) {
                    params.total(data.totalItems);
                    return data;
                })
                .then(onCampaignsGetSuccess)
                .finally(onCampaignsGetFinally);
        }

        function refreshAccountCampaigns() {
            return getAccountCampaigns(vm.campaignParams);
        }

        function getAccountCampaigns(params) {
            return Account.getNonVideoCampaigns(params).$promise;
        }

        function onCampaignsGetSuccess(campaigns) {
            vm.dataset = campaigns.items;
            return vm.dataset;
        }

        function onCampaignsGetFinally() {
            vm.loading = false;
        }

        function formatEndDate(endDate) {

            if (vm.endOfTime === endDate) {
                return '-';
            } else {
                return endDate;
            }
        }

        function showDateFilterReportsModal(campaign, account) {

            return $uibModal.open({
                controller: 'NetworkAccountsCampaignsModalController as vm',
                templateUrl: 'campaign/network-accounts-campaigns-dashboard/network-accounts-campaigns-dashboard-display-report-modal.html',
                size: 's',
                resolve: {
                    campaign: function () {
                        return campaign;
                    },
                    account: function() {
                        return account;
                    }
                }
            }).result;
        }

        function showImportedCampaignModal(campaign, account, element_index) {

            var params = {
                accountId: account.id,
                campaignId: campaign.campaignId
            };

            vm.importing = true;
            vm.importing_index = element_index;

            Campaign.importCampaign(params)
                .$promise
                .then(function(campaign) {
                    vm.importing = false;
                    vm.importing_index = null;

                    return $uibModal.open({
                    controller: 'ImportedCampaignsModalController as vm',
                    templateUrl: 'campaign/network-accounts-campaigns-dashboard/imported-campaign-modal.html',
                    size: 's',
                    resolve: {
                            campaign: function () {
                                return campaign;
                            }
                        }
                    }).result;
                }, function(error) {
                    resourceResponse.error(error);
                    vm.importing = false;
                    vm.importing_index = null;
                })
        }
    }

    NetworkAccountsCampaignsModalController.$inject = [
        '$scope',
        '$uibModalInstance',
        'campaign',
        'account',
        'reportDownloader'
    ];

    /** @ngInject */
    function NetworkAccountsCampaignsModalController(
        $scope,
        $uibModalInstance,
        campaign,
        account,
        reportDownloader
    ) {
        var vm = this;
        vm.campaign = campaign;
        vm.account = account;
        vm.CAMPAIGN_REPORT_URL = '/api/campaigns/display/report?networkAccountId=_NETWORK_ACCOUNT_ID_&accountId=_ACCOUNT_ID_&campaignId=_CAMPAIGN_ID_&type=_TYPE_&startDate=_STARTDATE_&endDate=_ENDDATE_&';
        vm.endOfTime = '2037-12-30';
        vm.filters = {
            dateRange: {
                startDate: moment(vm.campaign.startDate),
                endDate:  filterEndDate(vm.campaign.endDate),
            }
        };

        vm.downloading = false;

        vm.datepickerOptions = {
            opens: 'center',
            format: 'YYYY-MM-DD',
            locale: {
                format: 'MMM DD, YYYY'
            },
            minDate: vm.filters.dateRange.startDate,
            maxDate: vm.filters.dateRange.endDate,
            parentEl: '#modal-body'
        };

        vm.reportDownload = reportDownload;
        vm.cancel = cancel;

        function filterEndDate(endDate) {
            if(vm.endOfTime === endDate) {
                return moment();
            } else {
                return moment(endDate);
            }
        }

        /**
         * Generates report download link.
         *
         * @returns {string}
         */
        function reportDownload() {

            var startDate = vm.filters.dateRange.startDate > moment(campaign.startDate) ? vm.filters.dateRange.startDate.format('YYYY-MM-DD') : campaign.startDate;
            var endDate = vm.filters.dateRange.endDate < moment(campaign.endDate) ? vm.filters.dateRange.endDate.format('YYYY-MM-DD') : campaign.endDate;

            var link = vm.CAMPAIGN_REPORT_URL;

            link = link.replace('_CAMPAIGN_ID_', vm.campaign.campaignId);
            link = link.replace('_NETWORK_ACCOUNT_ID_', vm.campaign.clientId);
            link = link.replace('_ACCOUNT_ID_', vm.account.id);
            link = link.replace('_TYPE_', vm.campaign.type);
            link = link.replace('_STARTDATE_', startDate);
            link = link.replace('_ENDDATE_', endDate);

            vm.downloading = true;
            reportDownloader.download(link).then(function() {
                vm.downloading = false;
                $uibModalInstance.dismiss();
            });
        }

        function cancel() {
            return $uibModalInstance.dismiss();
        }
    }

    ImportedCampaignsModalController.$inject = [
        '$scope',
        '$uibModalInstance',
        '$state',
        'Campaign',
        'campaign',
        'toastr',
        'resourceResponse'
    ];

    /** @ngInject */
    function ImportedCampaignsModalController(
        $scope,
        $uibModalInstance,
        $state,
        Campaign,
        campaign,
        toastr,
        resourceResponse
    ) {
        var vm = this;
        vm.campaign = campaign;
        vm.budget = null;
        vm.userBudget = null;
        vm.importing = false;
        vm.fixedAmount = null;
        vm.endDate = campaign.endDate;
        vm.cancel = cancel;
        vm.endOfTime = '2037-12-30';
        vm.today= new Date();


        vm.openDatePicker = openDatePicker;
        vm.importCampaign = importCampaign;
        vm.validateBidding = validateBidding;
        vm.unregisterFns = [
            $scope.$watch('vm.budget', onBiddingBudgetChange, true),
            $scope.$watch('vm.endDate', onEndDateChange, true),

        ];
        function onBiddingBudgetChange() {
            vm.userBudget = _.round(vm.budget / (1 - vm.campaign.customerAccount.campaignFee), 3);
        }

        /**
         * This method was created to avoid importing facebook campaigns without endDate.
         * Originally we allowed only campaigns with endDate < 5 months to achieve this.
         *
         * I did increase this to one year, to allow campaigns that finishes in a year.
         */
        function onEndDateChange() {
            vm.endDate = moment(vm.endDate);
            var fiveMonthsAfter = moment().add(360,'days');
            vm.isOverFiveMonths = vm.endDate > fiveMonthsAfter;
        }

        /**
         * This method should be part of a common component
         */
        function openDatePicker($event, elementOpened) {
            console.log($event);
            console.log(elementOpened);

            $event.preventDefault();
            $event.stopPropagation();
            vm.ui.datePickersOpened[elementOpened] = !vm.ui.datePickersOpened[elementOpened];
        }

        function cancel() {
            return $uibModalInstance.dismiss();
        }

        function importCampaign() {
            if (vm.fixedAmount) {
                vm.campaign.adGroups.forEach( function(value, index) {
                    vm.campaign.adGroups[index].fixedAmount = vm.fixedAmount;
                });
            }
            vm.campaign.budget = vm.budget;
            vm.campaign.endDate = vm.endDate.format('YYYY-MM-DD');

            var params = {
                campaignData: vm.campaign
            };

            Campaign.postImportCampaign(params)
                .$promise
                .then(function (campaign) {
                    let campaignId = campaign.id;
                    window.location.href = '/campaigns/' + campaignId;
                    $uibModalInstance.dismiss();
                }, function(error) {
                    resourceResponse.error(error);
                });
        }

        function validateBidding(bidding, index, form) {
            if (vm.fixedAmount != null) {
                if (vm.fixedAmount < bidding.biddingAmount) {
                    form['biddingAmount'+index].$setValidity('fixedLowerThanBidding', false);
                } else {
                    form['biddingAmount'+index].$setValidity('fixedLowerThanBidding', true);
                }
            } else if (vm.budget != null) {
                if (vm.budget < bidding.biddingAmount) {
                    form['biddingAmount'+index].$setValidity('budgetLowerThanBidding', false);
                } else {
                    form['biddingAmount'+index].$setValidity('budgetLowerThanBidding', true);
                }
            }
        }

        $scope.$on('$destroy', function () {
            for(var i in vm.unregisterFns) {
                vm.unregisterFns[i]();
            }
        });


    }
})();
