(function() {
    'use strict';

    tvlTooltipUtils.$inject = ['tvlConstantUtils', 'tvlAdVideoUtils'];

    function tvlTooltipUtils(tvlConstantUtils, tvlAdVideoUtils) {

        var self = this;

        self.getNetworkTooltip = function(placement) {
            var network = placement.network;
            var subnetworks = placement.subnetworks;

            if (network === 'facebook') {
                if (subnetworks.includes('facebook_facebook') && subnetworks.includes('facebook_instagram')) {
                    return 'Facebook / Instagram';
                } else if (subnetworks.includes('facebook_facebook')) {
                    return 'Facebook';
                } else if (subnetworks.includes('facebook_instagram')) {
                    return 'Instagram';
                }
            } else if (network === 'adwords') {
                if (subnetworks.includes('adwords_video')) {
                    return 'YouTube';
                } else if (subnetworks.includes('adwords_display')) {
                    return 'Google Display';
                } else if (subnetworks.includes('adwords_search')) {
                    return 'Google Search';
                }
            }

            return '';
        };

        self.getFormatTooltip = function(placement) {
            var ad = placement.adGroups[0].ads.length ? placement.adGroups[0].ads[0] : null;
            if (!ad) {
                // Force campaign rendering even if there are placements without ads
                return '';
            }

            var adVideoFormat = ad.format;
            var adVideoType = (ad.video !== undefined)
                ? ad.video.type
                : null;

            if (tvlAdVideoUtils.isInStreamAdVideoFormat(adVideoFormat)) {
                return 'In Stream';
            }
            if (tvlAdVideoUtils.isInStreamTargetCpmAdVideoFormat(adVideoFormat)) {
                return 'In Stream Target CPM';
            }
            if (tvlAdVideoUtils.isInStreamNonSkippableAdVideoFormat(adVideoFormat)) {
                return 'In Stream Non Skippable';
            }
            if (tvlAdVideoUtils.isBumperAdVideoFormat(adVideoFormat)) {
                return 'Bumper';
            }
            if (tvlAdVideoUtils.isVideoDiscoveryAdVideoFormat(adVideoFormat)) {
                return 'In Feed';
            }
            if (tvlAdVideoUtils.isTrueViewForActionAdVideoFormat(adVideoFormat)) {
                return 'Video Action';
            }
            if (tvlAdVideoUtils.isFacebookAnyAdVideoFormat(adVideoFormat)) {
                return 'Facebook/Instagram Post';
            }
            if (tvlAdVideoUtils.isFacebookCarouselExternalAdVideoFormat(adVideoFormat)) {
                return 'Facebook/Instagram Carousel';
            }
            if (tvlAdVideoUtils.isFacebookExternalAdVideoFormat(adVideoFormat)) {
                if (adVideoType === self.FACEBOOK_CREATIVE_POST_PHOTO) {
                    return 'Facebook/Instagram Image';
                } else {
                    return 'Facebook/Instagram Video';
                }
            }
            if (tvlAdVideoUtils.isInstagramStoryAdVideoFormat(adVideoFormat)) {
                return 'Instagram Stories';
            }
            if (tvlAdVideoUtils.isAdwordsImageAdDisplayFormat(adVideoFormat)) {
                /* For Google campaigns we return bidding model (business requirement) */
                return self.getBiddingStrategyTooltip(placement);
            }

            return '';
        };

        /**
         * Returns bidding strategy type name if available.
         *
         * @param placement
         * @returns {*}
         */
        self.getBiddingStrategyTooltip = function(placement) {
            var ad = placement.adGroups[0].ads[0];
            var biddingStrategy = (ad['biddingStrategyType']) ? ad.biddingStrategyType : null;
            if (biddingStrategy) {
                if (tvlConstantUtils.biddingStrategyTypes.hasOwnProperty(biddingStrategy)) {
                    return tvlConstantUtils.biddingStrategyTypes[biddingStrategy];
                } else {
                    return biddingStrategy;
                }
            }

            return '';
        };

        return self;
    }

    angular
        .module('tvl.common')
        .service('tvlTooltipUtils', tvlTooltipUtils);
}());
