$(window).scroll(function(){
    if ($(window).scrollTop() >= 180) {
       var width = $('#page-navbar').width();
       $('#page-navbar').addClass('tvl-fixed-header');
       $('#page-navbar').width(width);
    }
    else {
       $('#page-navbar').removeClass('tvl-fixed-header');
       $('#page-navbar').width('auto');
    }
});
