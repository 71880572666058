(function() {
    angular
        .module('tvl.common')
        .directive('tvlDetail', tvlDetail);

    /** @ngInject */
    function tvlDetail() {
        var directive = {
            restrict: 'EA',
            transclude: true,
            templateUrl: "common/detail.html",
            scope: {
                title: '=',
                showTitle: '='
            }
        };
        return directive;
    }
})();
