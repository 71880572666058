(function() {
    'use strict';

    angular
        .module('tvl.common')
        .directive('notificationList', function () {
            return {
                templateUrl: 'common/notification/list.html',
                restrict: 'E',
                controller: function (
                    $scope,
                    $filter,
                    $q,
                    $log,
                    $pusher,
                    $window,
                    tvlSession,
                    Notification
                ) {
                    var vm = this;

                    $scope.campaignNotifications = [];
                    $scope.notifications = null;
                    $scope.getMediaIcon= getMediaIcon;
                    $scope.getTextStyle = getTextStyle;
                    $scope.getNotificationLabel = getNotificationLabel;
                    $scope.markAsRead = markAsRead;
                    $scope.areThereUnreadNotifications = areThereUnreadNotifications;
                    $scope.pushConfig = {};
                    $scope.onLoad = false;

                    activate();

                    ////////////////

                    function activate() {
                        loadNotifications();
                        subscribe();
                    }

                    /**
                     * Establish a connection to the Pusher channel and bind a callback
                     * to the notification event.
                     */
                    function subscribe() {
                    }

                    function onNotification(notification) {
                        $scope[notification.type + 'Notifications'].unshift(notification);
                    }

                    function loadNotifications() {
                        getNotifications().then(function(res) {
                            $scope.notifications = res;
                            $scope.campaignNotifications = _.filter($scope.notifications.items, {type: 'campaign'});
                            $scope.onLoad = true;
                        });
                    }

                    function getNotifications(callback) {
                        return Notification.get({})
                            .$promise
                            .catch(function (res) {
                                $log.error('Something wrong happened!');
                            });
                    }

                    function getMediaIcon(notification) {
                        switch(notification.messageId) {
                            case 'active':
                                return 'fa-play';
                                break;
                            case 'paused':
                                return 'fa-pause';
                                break;
                            case 'error':
                                return 'fa-error-circle';
                                break;
                            default:
                                break;
                        }
                    }

                    function getTextStyle(notification) {
                        switch(notification.messageId) {
                            case 'active':
                                return 'text-success';
                                break;
                            case 'paused':
                                return 'text-warning';
                                break;
                            case 'error':
                                return 'text-danger';
                                break;
                            default:
                                break;
                        }
                    }

                    function getNotificationLabel(notification) {
                        switch(notification.messageId) {
                            case 'active':
                                return 'has started now';
                                break;
                            case 'paused':
                                return 'has been paused';
                                break;
                            case 'error':
                                return 'has an error!';
                                break;
                            default:
                                break;
                        }
                    }

                    function areThereUnreadNotifications(type) {
                        return _.filter($scope[type + 'Notifications'], {read: false}).length > 0;
                    }

                    function markAsRead(notification) {

                        var notificationIndex = _.findIndex($scope[notification.type + 'Notifications'], {'id': notification.id});
                        $scope[notification.type + 'Notifications'][notificationIndex].read = true;

                        var data = {'id': notification.id, 'read': true};
                        var updatedNotification = new Notification(data);
                        return updatedNotification.$save()
                            .catch(function (res) {
                                $log.error('Something wrong happened!');
                            })
                            .then(function (res) {
                                $window.parent.postMessage("decCounter","*");
                            });
                    }

                },
            };
        });
})();
