(function() {
    'use strict';

    angular
        .module('tvl.campaign')
        .controller('CampaignBenchmarkReportController', CampaignBenchmarkReportController);

    CampaignBenchmarkReportController.$inject = [
        '$scope',
        '$state',
        '$stateParams',
        '$uibModal',
        '$filter',
        'toastr',
        'DTOptionsBuilder',
        'tvlCampaignUtils',
        'tvlAdVideoUtils',
        'tvlUi',
        'Campaign',
        'Account',
        'Constants',
        'Location',
        'resourceResponse',
        'reportDownloader',
        'Company',
        'tvlConstantUtils'
    ];

    /* @ngInject */
    function CampaignBenchmarkReportController(
        $scope,
        $state,
        $stateParams,
        $uibModal,
        $filter,
        toastr,
        DTOptionsBuilder,
        tvlCampaignUtils,
        tvlAdVideoUtils,
        tvlUi,
        Campaign,
        Account,
        Constants,
        Location,
        resourceResponse,
        reportDownloader,
        Company,
        tvlConstantUtils
    ) {
        var vm = this;
        vm.tvlUi = tvlUi;
        vm.tvlAdVideoUtils = tvlAdVideoUtils;
        vm.accountOptions = null;
        vm.companyOptions = null;
        vm.tagsOptions = null;
        vm.countryOptions = null;
        vm.languageOptions = null;
        vm.campaignBenchmarkReports = null;
        vm.genderBenchmarkPerformanceReport = null;
        vm.getBenchmarkPerformanceReportsLast12Months = null;
        vm.getBenchmarkPerformanceReportsDayOfTheWeek = null;
        vm.demographicBenchmarkReports = null;
        vm.dateRange = {
            startDate: moment().subtract(3, 'months'),
            endDate: moment()
        };
        vm.filters = {
            tags: [],
            name: null,
            account: [],
            company: [],
            startDate: vm.dateRange.startDate,
            endDate: vm.dateRange.endDate,
            location: [],
            subnetwork: [],
            adFormat: [],
            minAge: 13,
            maxAge: 65,
            gender: [],
            language: [],
            device: []
        };
        vm.maxRangePeriod = {
            time : 12,
            timeUnit : 'months'
        };

        vm.datepickerOptions = {
            opens: 'right',
            locale: {
                format: "MMM DD, YYYY"
            },
            maxDate: vm.filters.endDate
        };
        vm.subnetworks = tvlCampaignUtils.getAvailableSubNetworks();
        vm.genders = tvlCampaignUtils.getGenders();
        vm.devices = tvlCampaignUtils.getDevices();
        vm.ui = {
            gettingCampaignBenchmarkReports: false,
            gettingCampaignBenchmarkPerformanceReports: false,
            gettingCampaignBenchmarkPerformanceReports12Months: false,
            showTvlCosts: false,
            ageSliderOptions: {
                floor: 13,
                ceil: 65,
                hideLimitLabels: true,
                boundPointerLabels: true,
                translate: function(value) {
                    if (value === 65) {
                        return value + ' +';
                    }
                    return value;
                }
            }
        };

        vm.applyFilters = applyFilters;
        vm.getAccounts = getAccounts;
        vm.getCompanies = getCompanies;
        vm.getCountryFlag = getCountryFlag;
        vm.downloading = false;
        vm.reportDownload = reportDownload;
        vm.showCampaignPlacementBenchmarkReportsModal = showCampaignPlacementBenchmarkReportsModal;
        vm.sortTable = sortTable;
        vm.tvlConstantUtils = tvlConstantUtils;

        activate();

        ////////////////

        function activate() {
            vm.dtOptions = DTOptionsBuilder.newOptions()
                .withOption('scrollY', '400px')
                .withOption('scrollX', true)
                .withOption('scrollCollapse', true)
                .withOption('paging', false)
                .withOption('bFilter', false)
                .withOption('bInfo', false)
                .withFixedColumns({
                    leftColumns: 3
                })
                .withBootstrap();

            checkFilters();

            vm.unregisterDateRangeWatcher = $scope.$watch(
                'vm.dateRange',
                onDateRangeChange
            );
            getAccounts();
            getCompanies();
            getTags();
            getCountries();
            getLanguages();

            vm.isValidPeriodRangeForExcelreport = checkPeriodRangeForExcelReport(vm.dateRange);

            $scope.$on('$destroy', onDestroy);
        }

        function checkFilters() {
            var searchBenchmarkReports = true;
            if (
                $state.params['tags[]'] === undefined &&
                $state.params.name === undefined &&
                $state.params['account[]'] === undefined &&
                $state.params['company[]'] === undefined &&
                $state.params.startDate === undefined &&
                $state.params.endDate === undefined &&
                $state.params['location[]'] === undefined &&
                $state.params['subnetwork[]'] === undefined &&
                $state.params['adFormat[]'] === undefined &&
                $state.params.minAge === undefined &&
                $state.params.maxAge === undefined &&
                $state.params['gender[]'] === undefined &&
                $state.params['language[]'] === undefined &&
                $state.params['device[]'] === undefined
            ) {
                searchBenchmarkReports = false;
            }

            if ($stateParams.startDate !== undefined && $stateParams.endDate !== undefined) {
                vm.dateRange = {
                    startDate: moment($stateParams.startDate),
                    endDate: moment($stateParams.endDate)
                };
            }

            vm.filters = {
                tags: $stateParams['tags[]'] !== undefined ? (_.isArray($stateParams['tags[]']) ? $stateParams['tags[]'] : [$stateParams['tags[]']]) : [],
                name: $stateParams.name,
                account: $stateParams['account[]'] !== undefined ? (_.isArray($stateParams['account[]']) ? $stateParams['account[]'] : [$stateParams['account[]']]) : [],
                company: $stateParams['company[]'] !== undefined ? (_.isArray($stateParams['company[]']) ? $stateParams['company[]'] : [$stateParams['company[]']]) : [],
                startDate: vm.dateRange.startDate,
                endDate: vm.dateRange.endDate,
                location: $stateParams['location[]'] !== undefined ? (_.isArray($stateParams['location[]']) ? $stateParams['location[]'] : [$stateParams['location[]']]) : [],
                subnetwork: $stateParams['subnetwork[]'] !== undefined ? (_.isArray($stateParams['subnetwork[]']) ? $stateParams['subnetwork[]'] : [$stateParams['subnetwork[]']]) : [],
                adFormat: $stateParams['adFormat[]'] !== undefined ? (_.isArray($stateParams['adFormat[]']) ? $stateParams['adFormat[]'] : [$stateParams['adFormat[]']]) : [],
                minAge: $stateParams.minAge || 13,
                maxAge: $stateParams.maxAge || 65,
                gender: $stateParams['gender[]'] !== undefined ? (_.isArray($stateParams['gender[]']) ? $stateParams['gender[]'] : [$stateParams['gender[]']]) : [],
                language: $stateParams['language[]'] !== undefined ? (_.isArray($stateParams['language[]']) ? $stateParams['language[]'] : [$stateParams['language[]']]) : [],
                device: $stateParams['device[]'] !== undefined ? (_.isArray($stateParams['device[]']) ? $stateParams['device[]'] : [$stateParams['device[]']]) : []
            };

            if (searchBenchmarkReports) {
                getCampaignBenchmarkReports();
            }
        }

        function applyFilters() {
            getCampaignBenchmarkReports();
        }

        function getAccounts(search) {
            var params = {
                term: search || null
            };
            Account.get(params)
                .$promise
                .then(onGetAccountsSuccess);

            function onGetAccountsSuccess(accounts) {
                vm.accountOptions = _.map(accounts.items, 'name');
            }
        }

        function getCompanies(search) {
            var params = {
                term: search || null
            }

            Company.get(params).$promise
                .then(onGetCompaniesSuccess);

            function onGetCompaniesSuccess(companies) {
                vm.companyOptions = _.map(companies.items, 'name');
            }
        }

        /**
         * Retrieve campaign benchmark reports from the API.
         *
         * @return {Promise}
         */
        function getCampaignBenchmarkReports() {
            var params = {
                'tags[]': vm.filters.tags,
                name: vm.filters.name,
                'account[]': vm.filters.account,
                'company[]': vm.filters.company,
                startDate: vm.filters.startDate.format('YYYY-MM-DD'),
                endDate: vm.filters.endDate.format('YYYY-MM-DD'),
                'location[]': vm.filters.location,
                'subnetwork[]': vm.filters.subnetwork,
                'adFormat[]': vm.filters.adFormat,
                minAge: vm.filters.minAge,
                maxAge: vm.filters.maxAge,
                'gender[]': vm.filters.gender,
                'language[]' : vm.filters.language,
                'device[]': vm.filters.device
            };

            if (vm.sortBy && vm.sortDirection) {
                params.sortBy = vm.sortBy;
                params.sortDirection = vm.sortDirection;
            }

            vm.ui.gettingCampaignBenchmarkReports = true;
            return Campaign.getBenchmarkReports(params)
                .$promise
                .then(onGetReportsSuccess)
                .then(getBenchmarkPerformanceReports(params))
                .then(getBenchmarkPerformanceReportsLast12Months(params))
                .then(getBenchmarkPerformanceReportsDayOfTheWeek(params))
                .catch(onGetReportsError)
                .finally(onGetReportsFinally);

            function onGetReportsSuccess(campaignBenchmarkReports) {
                vm.campaignBenchmarkReports = campaignBenchmarkReports;

                // update query params
                $state.transitionTo(
                    'campaign.benchmark-report',
                    params,
                    {notify: false}
                );

                var totals = vm.campaignBenchmarkReports.totals;
                vm.ui.showTvlCosts = (
                    totals.hasOwnProperty('totalTheviralabCpc') &&
                    totals.hasOwnProperty('totalTheviralabCpm') &&
                    totals.hasOwnProperty('totalTheviralabCpv')
                );
            }

            function onGetReportsError() {
                toastr.error('Our apologies, we have been unable to retrieve your benchmark reports data. Please, try again later.');
            }

            function onGetReportsFinally() {
                vm.ui.gettingCampaignBenchmarkReports = false;
            }
        }

        function getBenchmarkPerformanceReportsLast12Months (params) {
            vm.ui.gettingCampaignBenchmarkPerformanceReportsLast12Months = true;

            return Campaign.getLast12MonthsBenchmarkPerformanceReports(params)
                .$promise
                .then(onGetBenchmarkPerformanceReportsLast12MonthsSuccess)
                .catch(onGetBenchmarkPerformanceReportsLast12MonthsError)
                .finally(onGetBenchmarkPerformanceReportsLast12MonthsFinally);

            function onGetBenchmarkPerformanceReportsLast12MonthsSuccess(performanceReports) {
                vm.getBenchmarkPerformanceReportsLast12Months = performanceReports.data;
            }

            function onGetBenchmarkPerformanceReportsLast12MonthsError() {
                toastr.error('Our apologies, we have been unable to retrieve your performance reports data. Please, try again later.');
            }

            function onGetBenchmarkPerformanceReportsLast12MonthsFinally() {
                vm.ui.gettingCampaignBenchmarkPerformanceReportsLast12Months = false;
            }
        }

        function getBenchmarkPerformanceReportsDayOfTheWeek(params) {

            vm.ui.gettingCampaignBenchmarkPerformanceReportsDayOfTheWeek = true;

            return Campaign.getDayOfTheWeekBenchmarkPerformanceReports(params)
                .$promise
                .then(onGetBenchmarkPerformanceReportsDayOfTheWeekSuccess)
                .catch(onGetBenchmarkPerformanceReportsDayOfTheWeekError)
                .finally(onGetBenchmarkPerformanceReportsDayOfTheWeekFinally);

            function onGetBenchmarkPerformanceReportsDayOfTheWeekSuccess(performanceReports) {
                vm.getBenchmarkPerformanceReportsDayOfTheWeek = performanceReports.data;
            }

            function onGetBenchmarkPerformanceReportsDayOfTheWeekError() {
                toastr.error('Our apologies, we have been unable to retrieve your performance reports data. Please, try again later.');
            }

            function onGetBenchmarkPerformanceReportsDayOfTheWeekFinally() {
                vm.ui.gettingCampaignBenchmarkPerformanceReportsDayOfTheWeek = false;
            }
        }

        function getBenchmarkPerformanceReports(params) {
            vm.ui.gettingCampaignBenchmarkPerformanceReports = true;

            return Campaign.getBenchmarkPerformanceReports(params)
                .$promise
                .then(onGetPerformanceReportsSuccess)
                .catch(onGetPerformanceReportsError)
                .finally(onGetPerformanceReportsFinally);

            function onGetPerformanceReportsSuccess(performanceReports) {
                vm.genderBenchmarkPerformanceReport = performanceReports.gender;
                vm.demographicBenchmarkPerformanceReport = performanceReports.demographic;
                vm.tagsBenchmarkPerformanceReport = performanceReports.tags;

                vm.genderMixedChart = getPerformanceChartDefaultTemplate('CPV');
                [vm.genderMixedChart.labels, vm.genderMixedChart.data] = getBenchmarkPerformanceChartDataAndLabels(performanceReports.gender, 'cpv');
                vm.genderMixedChart.options.title.text = 'OVERALL: CPV & VR BY GENDER';

                vm.genderMixedChartCpm = getPerformanceChartDefaultTemplate('CPM');
                [vm.genderMixedChartCpm.labels, vm.genderMixedChartCpm.data] = getBenchmarkPerformanceChartDataAndLabels(performanceReports.gender, 'cpm');
                vm.genderMixedChartCpm.options.title.text = 'OVERALL: CPM & VR BY GENDER';

                vm.genderMixedChartCpc = getPerformanceChartDefaultTemplate('CPC');
                [vm.genderMixedChartCpc.labels, vm.genderMixedChartCpc.data] = getBenchmarkPerformanceChartDataAndLabels(performanceReports.gender, 'cpc');
                vm.genderMixedChartCpc.options.title.text = 'OVERALL: CPC & VR BY GENDER';

                vm.genderMixedChartCtr = getPerformanceChartDefaultTemplate('CTR');
                [vm.genderMixedChartCtr.labels, vm.genderMixedChartCtr.data] = getBenchmarkPerformanceChartDataAndLabels(performanceReports.gender, 'ctr');
                vm.genderMixedChartCtr.options.title.text = 'OVERALL: CTR & VR BY GENDER';

                vm.demographicMixedChart = getPerformanceChartDefaultTemplate('CPV');
                [vm.demographicMixedChart.labels, vm.demographicMixedChart.data] = getBenchmarkPerformanceChartDataAndLabels(performanceReports.demographic, 'cpv');
                vm.demographicMixedChart.options.title.text = 'OVERALL: CPV & VR BY AGE';

                vm.demographicMixedChartCpm = getPerformanceChartDefaultTemplate('CPM');
                [vm.demographicMixedChartCpm.labels, vm.demographicMixedChartCpm.data] = getBenchmarkPerformanceChartDataAndLabels(performanceReports.demographic, 'cpm');
                vm.demographicMixedChartCpm.options.title.text = 'OVERALL: CPM & VR BY AGE';

                vm.demographicMixedChartCpc = getPerformanceChartDefaultTemplate('CPC');
                [vm.demographicMixedChartCpc.labels, vm.demographicMixedChartCpc.data] = getBenchmarkPerformanceChartDataAndLabels(performanceReports.demographic, 'cpc');
                vm.demographicMixedChartCpc.options.title.text = 'OVERALL: CPC & VR BY AGE';

                vm.demographicMixedChartCtr = getPerformanceChartDefaultTemplate('CTR');
                [vm.demographicMixedChartCtr.labels, vm.demographicMixedChartCtr.data] = getBenchmarkPerformanceChartDataAndLabels(performanceReports.demographic, 'ctr');
                vm.demographicMixedChartCtr.options.title.text = 'OVERALL: CTR & VR BY AGE';

                vm.tagsMixedChart = getPerformanceChartDefaultTemplate('CPV');
                [vm.tagsMixedChart.labels, vm.tagsMixedChart.data] = getBenchmarkPerformanceChartDataAndLabels(performanceReports.tags, 'cpv');
                vm.tagsMixedChart.options.title.text = 'OVERALL: CPV & VR BY TAGS';
            }

            function onGetPerformanceReportsError() {
                toastr.error('Our apologies, we have been unable to retrieve your performance reports data. Please, try again later.');
            }

            function onGetPerformanceReportsFinally() {
                vm.ui.gettingCampaignBenchmarkPerformanceReports = false;
            }
        }

        function getBenchmarkPerformanceChartDataAndLabels(performanceReports, cpa) {
            var labels = [];
            var data = [[], []];
            _.forEach(performanceReports, function(value, key) {
                var label = key === 'undetermined' ? 'Undefined' : $filter('tvlCapitalize')(key);
                var cpx = value[cpa];
                labels.push(label);
                data[0].push(value.viewRate.toFixed(2));
                data[1].push(cpx.toFixed(4));
            });

            return [labels, data];
        }

        function getPerformanceChartDefaultTemplate(cpa) {
            return {
                colors: [tvlUi.getThemeColorForPosition(0), tvlUi.getThemeColorForPosition(1)],
                datasetOverride: [
                    {
                        label: "VR",
                        borderWidth: 1,
                        type: 'bar',
                        yAxisID: 'y-axis-1'
                    },
                    {
                        label: cpa,
                        borderWidth: 3,
                        type: 'line',
                        yAxisID: 'y-axis-2'
                    }
                ],
                options: {
                    title: {
                        display: true,
                        position: 'top'
                    },
                    legend: {
                        display: true,
                        position: 'top'
                    },
                    scales: {
                        xAxes: [
                            {
                                ticks: {
                                    minRotation: 45
                                }
                            }
                        ],
                        yAxes: [
                            {
                                id: 'y-axis-1',
                                type: 'linear',
                                display: true,
                                position: 'left',
                                ticks: {
                                    callback: function(value) {
                                        return value.toFixed(2) + '%';
                                    },
                                    min: 0
                                }
                            },
                            {
                                id: 'y-axis-2',
                                type: 'linear',
                                display: true,
                                position: 'right',
                                ticks: {
                                    callback: function(value) {
                                        return '$' + value.toFixed(4);
                                    },
                                    min: 0
                                }
                            }
                        ]
                    }
                }
            };
        }

        function getTags() {
            Account.tags().$promise.then(function(response) {
                var data = response.data;
                vm.tagsOptions = data;
            }).catch(function() {
                toastr.error('Our apologies, we have been unable to recover the tags. Please, try again later.');
            });
        }

        function getCountries() {
            Location.getCountries()
                .$promise
                .then(onGetCountriesSuccess)
                .catch(resourceResponse.error);

            function onGetCountriesSuccess(response) {
                vm.countryOptions = response.data;
            }
        }

        function getCountryFlag(country) {
            return 'flag-icon-' + country.toLowerCase();
        }

        function getLanguages() {
            Constants.getLanguages()
                .$promise
                .then(onGetLanguagesSuccess);

            function onGetLanguagesSuccess(languages) {
                vm.languageOptions = languages;
            }
        }

        function reportDownload(format) {
            var url = '/api/campaigns/benchmark_reports?' +
                'format=' + format +
                '&startDate=' + vm.filters.startDate.format('YYYY-MM-DD') +
                '&endDate=' + vm.filters.endDate.format('YYYY-MM-DD');

            if (vm.filters.tags.length > 0) {
                _.forEach(vm.filters.tags, function (tag) {
                    url += '&tags[]=' + tag;
                });
            }

            if (vm.filters.name) {
                url += '&name=' + vm.filters.name;
            }

            if (vm.filters.account.length > 0) {
                _.forEach(vm.filters.account, function (account) {
                    url += '&account[]=' + account;
                });
            }

            if (vm.filters.company.length > 0) {
                _.forEach(vm.filters.company, function (company) {
                    url += '&company[]=' + encodeURIComponent(company);
                });
            }

            if (vm.filters.location.length > 0) {
                _.forEach(vm.filters.location, function (location) {
                    url += '&location[]=' + location;
                });
            }

            if (vm.filters.subnetwork.length > 0) {
                _.forEach(vm.filters.subnetwork, function (subnetwork) {
                    url += '&subnetwork[]=' + subnetwork;
                });
            }

            if (vm.filters.adFormat.length > 0) {
                _.forEach(vm.filters.adFormat, function (adFormat) {
                    url += '&adFormat[]=' + adFormat;
                });
            }

            if (vm.filters.minAge) {
                url += '&minAge=' + vm.filters.minAge;
            }

            if (vm.filters.maxAge) {
                url += '&maxAge=' + vm.filters.maxAge;
            }

            if (vm.filters.gender.length > 0) {
                _.forEach(vm.filters.gender, function (gender) {
                    url += '&gender[]=' + gender;
                });
            }

            if (vm.filters.language.length > 0) {
                _.forEach(vm.filters.language, function (language) {
                    url += '&language[]=' + language;
                });
            }

            if (vm.filters.device.length > 0) {
                _.forEach(vm.filters.device, function (device) {
                    url += '&device[]=' + device;
                });
            }

            vm.downloading = true;
            reportDownloader.download(url).then(function () {
                vm.downloading = false;
            });
        }

        function onDateRangeChange(newVal, oldVal) {
            if (newVal !== oldVal) {

                vm.isValidPeriodRangeForExcelreport = checkPeriodRangeForExcelReport(newVal);

                vm.filters.startDate = newVal.startDate;
                vm.filters.endDate = newVal.endDate;
            }
        }

        function checkPeriodRangeForExcelReport(newVal) {

            var endDate = newVal.endDate.startOf('day');
            var startDate = newVal.startDate.startOf('day');
            var diff = endDate.diff(startDate, 'months', true);

            return diff <= 12;
        }

        function showCampaignPlacementBenchmarkReportsModal(placementBenchmarkReports) {
            $uibModal.open({
                size: 'xl',
                templateUrl: 'campaign/benchmarks/placement-benchmark-reports-modal.html',
                controller: 'PlacementBenchmarkReportsModalController as vm',
                bindToController: true,
                resolve: {
                    reports: function () {
                        return placementBenchmarkReports;
                    },
                    showTvlCosts: function() {
                        return vm.ui.showTvlCosts;
                    }
                }
            });
        }

        function sortTable(column) {
            if (vm.sortBy === column) {
                vm.sortDirection = vm.sortDirection === 'desc' ? 'asc' : 'desc';
            } else {
                vm.sortBy = column;
                vm.sortDirection = 'desc';
            }

            getCampaignBenchmarkReports();
        }

        /**
         * Callback for the $destroy event.
         */
        function onDestroy() {
            vm.unregisterDateRangeWatcher();
        }
    }
})();
