(function() {
    'use strict';

    angular
        .module('tvl.administration')
        .controller('UsersController', UsersController);

    UsersController.$inject = [
        '$scope',
        '$state',
        '$uibModal',
        '$window',
        'Upload',
        'Account',
        'Company',
        'User',
        'roles',
        'orderByFilter',
        'tvlSession',
        'resourceResponse'
    ];

    /* @ngInject */
    function UsersController(
        $scope,
        $state,
        $uibModal,
        $window,
        Upload,
        Account,
        Company,
        User,
        roles,
        orderBy,
        tvlSession,
        resourceResponse
    ) {
        var vm = this;

        vm.users = [];
        vm.searchTerm = null;
        vm.currentPage = 1;
        vm.totalItems = null;
        vm.itemsPerPage = 20;

        vm.unregisterCurrentPageWatcher = null;
        vm.unregisterWatcherFns = [];

        vm.createUser = createUser;
        vm.getUsers = getUsers;
        vm.searchUsers = searchUsers;
        vm.selectUser = selectUser;
        vm.getRoleNameByValue = getRoleNameByValue;

        vm.propertyName = 'email'; // Default sort.
        vm.reverse = false;
        vm.isLoading = false;


        activate();

        ////////////////

        function activate() {

            tvlSession.getSession().then(function(user) {
                // get info for logged user, need to know its role to know which roles can create
                vm.loggedUser = user;
            });

            getUsers();
        }

        function createUser() {
            return showCreationModal().then(onCreateSuccess);

            function onCreateSuccess(user) {
                resetSearchTerm();
                resetPagination();

                return getUsers();
            }
        }

        function showCreationModal() {
            return $uibModal.open({
                controller: 'UsersNewModalController',
                templateUrl: 'users/users-new-modal.html',
                bindToController: true,
                backdrop: 'static',
                resolve: {
                    user: function() {
                        return null;
                    }
                }
            }).result;
        }

        function getUsers() {
            vm.isLoading = true;

            var params = {
                page: vm.currentPage,
                term: vm.searchTerm || null,
                items_per_page: vm.itemsPerPage
            };

            return User.get(params).$promise
                .then(onGetUsersSuccess, resourceResponse.error);

            function onGetUsersSuccess(users) {
                vm.users = users.items;
                vm.totalItems = users.totalItems;
                vm.currentPage = users.currentPage;
                vm.itemsPerPage = users.itemsPerPage;
                vm.isLoading = false;
            }
        }

        function searchUsers() {
            resetPagination();

            return getUsers();
        }

        function resetPagination() {
            vm.currentPage = 1;
        }

        function resetSearchTerm() {
            vm.searchTerm = null;
        }

        function selectUser(user) {
            $state.go('admin.usersEdit', {id: user.id});
        }

        /**
         * @param value
         * @returns {string}
         */
        function getRoleNameByValue(value)
        {
            const role = _.find(roles, {value: value});

            return role.key;
        }
    }
}());
