(function() {
    angular
        .module('tvl.campaign')
        .service('campaignValidation', campaignValidation);

    campaignValidation.$inject = ['toastr'];

    function campaignValidation(toastr) {
        /**
         * Maximum number of targeting elements supported on YouTube network.
         * @type {number}
         */
        const MAX_TARGETING_ITEMS = 19900;
        /**
         * Maximum number of excluded contents supported on YouTube network,
         * they are less than full targeting count supported.
         * @type {number}
         */
        const MAX_EXCLUDED_CONTENTS = 10000;

        return {
            validateCreationTargeting: validateCreationTargeting,
            validateUpdateTargeting: validateUpdateTargeting,
            validateEndDate: validateEndDate,
            countNumberElements: countNumberElements
        };

        /**
         * @param campaignForm
         * @returns {boolean}
         */
        function validateCreationTargeting(campaignForm) {
            if (! campaignForm.networks.youtube) {
                // we dont validate targeting for other networks.
                return true;
            }

            var numElements = 0;

            if (campaignForm.hasOwnProperty('selectedInterests')) {
                numElements += campaignForm.selectedInterests.length;
            }
            if (campaignForm.hasOwnProperty('selectedTopics')) {
                numElements += campaignForm.selectedTopics.length;
            }
            if (campaignForm.hasOwnProperty('selectedExcludedTopics')) {
                numElements += campaignForm.selectedExcludedTopics.length;
            }
            if (campaignForm.hasOwnProperty('includedContents')) {
                numElements += getNumberOfContents(campaignForm.includedContents);
            }
            if (campaignForm.hasOwnProperty('excludedContents')) {
                var numExcludedContents = getNumberOfContents(campaignForm.excludedContents);
                numElements += numExcludedContents;
                if (numExcludedContents >= MAX_EXCLUDED_CONTENTS) {
                    return false;
                }

            }
            if (campaignForm.hasOwnProperty('selectedKeywords')) {
                numElements += getNumberOfKeywords(campaignForm.selectedKeywords.included);
                numElements += getNumberOfKeywords(campaignForm.selectedKeywords.excluded);
            }
            if (numElements > MAX_TARGETING_ITEMS) {
                targetingExceededErrorMessage();
                return false;
            }

            return true;
        }

        /**
         * @param campaign
         * @param includedContents
         * @param excludedContents
         * @param previousItems
         * @returns {boolean}
         */
        function validateUpdateTargeting(campaign, includedContents, excludedContents, previousItems) {
            if (previousItems > MAX_TARGETING_ITEMS) {
                return true;
            }
            var numElements = countNumberElements(campaign, includedContents, excludedContents);
            if (numElements > MAX_TARGETING_ITEMS) {
                targetingExceededErrorMessage();
                return false;
            }
            if (numElements === -1) {
                excludedContentsExceededErrorMessage();
                return false;
            }
            return true;
        }

        function validateEndDate(endDate) {
            var endDateDate = new Date(endDate);
            // We handle here to avoid saving end date before today
            if (endDateDate < new Date()) {
                endDateBeforeTodayErrorMessage();
                return false;
            }
            return true;
        }

        /**
         * @param campaign
         * @param includedContents
         * @param excludedContents
         * @returns {number}
         */
        function countNumberElements(campaign, includedContents, excludedContents) {
            var numElements = 0;

            if (campaign.hasOwnProperty('interests')) {
                numElements += campaign.interests.length;
            }

            if (campaign.hasOwnProperty('topics')) {
                numElements += campaign.topics.length;
            }

            if (campaign.hasOwnProperty('excludedTopics')) {
                numElements += campaign.excludedTopics.length;
            }

            if (includedContents) {
                numElements += getNumberOfContents(includedContents);
            }

            if (excludedContents) {
                var numExcludedContents = getNumberOfContents(excludedContents);
                if (numExcludedContents >= MAX_EXCLUDED_CONTENTS) {
                    return -1;
                }
                numElements += numExcludedContents;
            }

            if (campaign.hasOwnProperty('targeting')) {
                if (campaign.targeting.hasOwnProperty('keywords')) {
                    numElements += getNumberOfKeywords(campaign.keywords);
                }

                if (campaign.targeting.hasOwnProperty('excludedKeywords')) {
                    numElements += getNumberOfContents(campaign.excludedKeywords);
                }
            }

            return numElements;
        }

        /**
         * @param contents
         * @returns {number}
         */
        function getNumberOfContents(contents) {
            var numberElements = 0;
            for (var i = 0; i < contents.length; i++) {
                if (contents[i].type === 'url') {
                    numberElements++;
                } else if (contents[i].type === 'list') {
                    numberElements += contents[i].list.nItems;
                }
            }
            return numberElements;
        }

        /**
         * @param keywords
         * @returns {number}
         */
        function getNumberOfKeywords(keywords) {
            var numberElements = 0;
            for (var i = 0; i < keywords.length; i++) {
                if (keywords[i].hasOwnProperty('items')) {
                    numberElements += keywords[i].items.length;
                } else if (keywords[i].hasOwnProperty('list')) {
                    numberElements += keywords[i].list.nItems;
                }
            }
            return numberElements;
        }

        function endDateBeforeTodayErrorMessage() {
            toastr.error('You have introduced an end date before today.');
        }

        function targetingExceededErrorMessage() {
            toastr.error('Introduced elements exceed Google Adwords targeting limit (' + MAX_TARGETING_ITEMS + ' items)');
        }

        function excludedContentsExceededErrorMessage() {
            toastr.error('Introduced content exclusions exceed Google Adwords targeting limit (' + MAX_EXCLUDED_CONTENTS + ' items)');
        }
    }
})();
