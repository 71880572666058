(function() {
    'use strict';

    angular
        .module('tvl.administration')
        .controller('CompaniesController', CompaniesController);

    CompaniesController.$inject = [
        '$scope',
        '$uibModal',
        '$window',
        '$state',
        'Company',
        'resourceResponse'
    ];

    /* @ngInject */
    function CompaniesController(
        $scope,
        $uibModal,
        $window,
        $state,
        Company,
        resourceResponse
    ) {
        var vm = this;

        vm.companies = [];
        vm.searchTerm = null;
        vm.currentPage = 1;
        vm.totalItems = null;
        vm.itemsPerPage = 20;
        vm.createCompany = createCompany;
        vm.getCompanies = getCompanies;
        vm.unregisterCurrentPageWatcher = null;
        vm.selectCompany = selectCompany;
        vm.searchCompanies = searchCompanies;
        vm.isLoading = true;

        activate();

        ////////////////

        function activate() {
            getCompanies();
        }

        function createCompany() {
            $uibModal.open({
                controller: 'CompaniesNewModalController',
                templateUrl: 'companies/companies-new.modal.html',
                backdrop: 'static'
            }).result.then(onCreateSuccess);

            function onCreateSuccess(company) {
                resetSearchTerm();
                resetPagination();

                return getCompanies();
            }
        }

        function resetPagination() {
            vm.currentPage = 1;
        }

        function resetSearchTerm() {
            vm.searchTerm = null;
        }

        function searchCompanies() {
            resetPagination();

            return getCompanies();
        }

        function getCompanies() {
            vm.isLoading = true;

            var params = {
                page: vm.currentPage,
                term: vm.searchTerm || null,
                items_per_page: vm.itemsPerPage
            };

            return Company.get(params).$promise
                .then(onGetCompaniesSuccess, resourceResponse.error);

            function onGetCompaniesSuccess(companies) {
                vm.companies = companies.items;
                vm.totalItems = companies.totalItems;
                vm.currentPage = companies.currentPage;
                vm.itemsPerPage = companies.itemsPerPage;
                vm.isLoading = false;
            }
        }

        function selectCompany(company) {
            $state.go('admin.companiesEdit', {id: company.id});
        }
    }
}());
