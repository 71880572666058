(function() {
    'use strict';

    angular
        .module('tvl.common')
        .directive('tvlVideoLength', tvlVideoLength);

    tvlVideoLength.$inject = ['$timeout'];

    /* @ngInject */
    function tvlVideoLength($timeout) {
        var directive = {
            link: link,
            restrict: 'A'
        };
        return directive;

        function link(scope, element, attrs) {
            $timeout(formatLength);

            function formatLength() {
                var length, formatted;
                length = formatted = element.text();

                if (! /\d+:\d+/.test(length)) {
                    var m = _.padStart(Math.floor(length / 60), 2, '0');
                    var s = _.padStart(Math.floor(length % 60), 2, '0');
                    formatted = m + ':' + s;
                }

                element.text(formatted);
            }
        }
    }
})();
