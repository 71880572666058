(function() {
    'use strict';

    /**
     * Common constants service.
     */
    function tvlConstantUtils() {

        var self = this;

        self.NETWORK_ADWORDS = 'adwords';
        self.NETWORK_FACEBOOK = 'facebook';
        // TODO (dCalzado): Deprecated NETWORKS => USE SUBNETWORK INSTEAD
        self.NETWORK_YOUTUBE = 'youtube';
        self.NETWORK_GOOGLE_DISPLAY = 'googleDisplay';
        self.NETWORK_INSTAGRAM = 'instagram';

        self.ICON_SUBNETWORK_ADWORDS_VIDEO = 'fa-youtube-play';
        self.ICON_SUBNETWORK_ADWORDS_DISPLAY = 'fa-google';
        self.ICON_SUBNETWORK_ADWORDS_SEARCH = 'fa-google';
        self.ICON_SUBNETWORK_FACEBOOK_FACEBOOK = 'fa-facebook-square';
        self.ICON_SUBNETWORK_FACEBOOK_INSTAGRAM = 'fa-instagram';

        self.TITLE_SUBNETWORK_ADWORDS_VIDEO = 'YouTube';
        self.TITLE_SUBNETWORK_ADWORDS_DISPLAY = 'Google Display';
        self.TITLE_SUBNETWORK_ADWORDS_SEARCH = 'Google Search';
        self.TITLE_SUBNETWORK_FACEBOOK_FACEBOOK = 'Facebook';
        self.TITLE_SUBNETWORK_FACEBOOK_INSTAGRAM = 'Instagram';

        self.SUBNETWORK_ADWORDS_VIDEO = 'adwords_video';
        self.SUBNETWORK_ADWORDS_DISPLAY = 'adwords_display';
        self.SUBNETWORK_ADWORDS_SEARCH = 'adwords_search';
        self.SUBNETWORK_FACEBOOK_FACEBOOK = 'facebook_facebook';
        self.SUBNETWORK_FACEBOOK_INSTAGRAM = 'facebook_instagram';

        self.biddingStrategyTypes = {
            'MANUAL_CPC': 'Manual CPC',
            'MANUAL_CPV': 'Manual CPV',
            'MANUAL_CPM': 'Manual CPM',
            'PAGE_ONE_PROMOTED': 'Page one promoted',
            'TARGET_SPEND': 'Target spend',
            'TARGET_CPA': 'Target CPA',
            'TARGET_ROAS': 'Target ROAS',
            'MAXIMIZE_CONVERSIONS': 'Maximize conversions',
            'MAXIMIZE_CONVERSION_VALUE': 'Maximize conversion value',
            'TARGET_OUTRANK_SHARE': 'Target outrank share',
            'NONE': 'None',
            'UNKNOWN': 'Unknown',
        };


        self.isNetworkAdwords = function(network) {
            return network === self.NETWORK_ADWORDS;
        };

        self.isNetworkYoutube = function(network) {
            return network === self.NETWORK_YOUTUBE;
        };

        self.isNetworkFacebook = function(network) {
            return network === self.NETWORK_FACEBOOK;
        };

        self.isNetworkInstagram = function(network) {
            return network === self.NETWORK_INSTAGRAM;
        };

        /**
         * Backward compatible method with back, we need to send Youtube instead Adwords or Google Display.
         *
         * @param network
         *
         * @return {string}
         */
        self.networkToPlatform = function(network) {
            var transformedNetwork = network;

            if (network === self.NETWORK_ADWORDS || network === self.NETWORK_GOOGLE_DISPLAY) {
                transformedNetwork = self.NETWORK_YOUTUBE;
            }

            return transformedNetwork;
        };

        self.platformToNetwork = function(platform) {
            var transformedPlatform = platform;

            if (platform === self.NETWORK_YOUTUBE || platform === self.NETWORK_GOOGLE_DISPLAY) {
                transformedPlatform = self.NETWORK_ADWORDS;
            }

            return transformedPlatform;
        };

        function getPlacementsFirstAd(placement) {
            var ad = null;
            if (placement.hasOwnProperty('adGroups') && placement.adGroups.length > 0) {
                var adGroup = placement.adGroups[0];
                if (adGroup.hasOwnProperty('ads') && adGroup.ads.length > 0) {
                    ad = placement.adGroups[0].ads[0];
                }
            }
            if (ad === null) {
                ad = placement.adVideo;
            }
            return ad;
        }

        function isPlacementMultiAdGroup(placement) {
            var isMultiAdGroup = false;
            var ad = null;

            if (placement.hasOwnProperty('adGroups') && placement.adGroups.length > 0) {
                var adGroup = placement.adGroups[0];
                if (adGroup.hasOwnProperty('ads') && adGroup.ads.length > 0) {
                    ad = placement.adGroups[0].ads[0];
                    isMultiAdGroup = adGroup.ads.length > 1;
                }
            }
            if (ad === null) {
                isMultiAdGroup = (placement.adVideo instanceof Array);
            }

            return isMultiAdGroup;
        }

        /**
         * Get icons array for a placement
         *
         * @param {Object} placement
         * @returns {[]}
         */
        self.getNetworkIconsForPlacement = function(placement) {
            var icons = [];

            var ad = getPlacementsFirstAd(placement);

            if (placement.network === self.NETWORK_ADWORDS && ad.isVideo) {
                icons.push(self.ICON_SUBNETWORK_ADWORDS_VIDEO);
            }

            if (placement.network === self.NETWORK_ADWORDS && (ad.isDisplay || ad.isSearch)) {
                icons.push(self.ICON_SUBNETWORK_ADWORDS_DISPLAY);
            }

            if (typeof placement.facebookPublisherPlatforms !== 'undefined') {
                if (placement.facebookPublisherPlatforms.includes(self.NETWORK_FACEBOOK)) {
                    icons.push(self.ICON_SUBNETWORK_FACEBOOK_FACEBOOK);
                }

                if (placement.facebookPublisherPlatforms.includes(self.NETWORK_INSTAGRAM)) {
                    icons.push(self.ICON_SUBNETWORK_FACEBOOK_INSTAGRAM);
                }
            }

            return icons;
        };

        self.getIconBySubnetwork = function(subnetwork) {
            var icon = '';

            if (subnetwork === self.SUBNETWORK_ADWORDS_VIDEO) {
                icon = self.ICON_SUBNETWORK_ADWORDS_VIDEO;
            } else if (subnetwork === self.SUBNETWORK_ADWORDS_DISPLAY) {
                icon = self.ICON_SUBNETWORK_ADWORDS_DISPLAY;
            } else if (subnetwork === self.SUBNETWORK_ADWORDS_SEARCH) {
                icon = self.ICON_SUBNETWORK_ADWORDS_DISPLAY;
            } else if (subnetwork === self.SUBNETWORK_FACEBOOK_FACEBOOK) {
                icon = self.ICON_SUBNETWORK_FACEBOOK_FACEBOOK;
            } else if (subnetwork === self.SUBNETWORK_FACEBOOK_INSTAGRAM) {
                icon = self.ICON_SUBNETWORK_FACEBOOK_INSTAGRAM;
            }

            return icon;
        }

        self.getTitleBySubnetwork = function(subnetwork) {
            var title = '';

            if (subnetwork === self.SUBNETWORK_ADWORDS_VIDEO) {
                title = self.TITLE_SUBNETWORK_ADWORDS_VIDEO;
            } else if (subnetwork === self.SUBNETWORK_ADWORDS_DISPLAY) {
                title = self.TITLE_SUBNETWORK_ADWORDS_DISPLAY;
            } else if (subnetwork === self.SUBNETWORK_ADWORDS_SEARCH) {
                title = self.TITLE_SUBNETWORK_ADWORDS_DISPLAY;
            } else if (subnetwork === self.SUBNETWORK_FACEBOOK_FACEBOOK) {
                title = self.TITLE_SUBNETWORK_FACEBOOK_FACEBOOK;
            } else if (subnetwork === self.SUBNETWORK_FACEBOOK_INSTAGRAM) {
                title = self.TITLE_SUBNETWORK_FACEBOOK_INSTAGRAM;
            }

            return title;
        }

        return self;
    }

    angular
        .module('tvl.common')
        .service('tvlConstantUtils', tvlConstantUtils);

}());
