(function() {
    'use strict';

    angular
        .module('tvl.common')
        .directive('maxImageHeight', maxImageHeight);

    maxImageHeight.$inject = ['$q'];

    /* @ngInject */
    function maxImageHeight($q) {
        return {
            restrict: 'A',
            require: 'ngModel',
            replace: false,
            link: function(scope, elm, attrs, ctrl) {
                ctrl.$asyncValidators.maxImageHeight = function(modelValue, viewValue) {
                    var deferred = $q.defer();

                    check(modelValue);

                    /**
                     * @param modelValue
                     *
                     * @return {boolean|void}
                     */
                    function check(modelValue) {
                        if (null === modelValue) {
                            deferred.resolve();
                            return;
                        }

                        return isValidHeight(modelValue);
                    }

                    /**
                     * @param modelValue
                     */
                    function isValidHeight(modelValue) {
                        var reader = new FileReader();
                        reader.onload = function(e)
                        {
                            var img = new Image();
                            img.onload = function() {
                                if(parseInt(this.height) <= parseInt(attrs.maxImageHeight)) {
                                    deferred.resolve();
                                } else {
                                    deferred.reject();
                                }
                            };
                            img.src = reader.result;
                        };
                        reader.readAsDataURL(modelValue);
                    }

                    return deferred.promise;
                };


            }
        };
    }
})();
