(function() {
    'use strict';

    angular
        .module('tvl.campaign')
        .controller('PowerpointSettingsModalController', PowerpointSettingsModalController);

    PowerpointSettingsModalController.$inject = [
        '$uibModalInstance',
        '$window',
        'campaign'
    ];

    function PowerpointSettingsModalController(
        $uibModalInstance,
        $window,
        campaign
    ) {

        var vm = this;

        vm.campaign = campaign;
        vm.availableLocations = vm.campaign.locations;
        vm.selectedLocations = [];

        vm.submit = submit;
        vm.cancel = cancel;

        function submit() {

            var url = '/api/campaigns/'
                + vm.campaign.id
                + '/report?format=powerpoint&startDate='
                + vm.campaign.startDate
                + '&endDate='
                + vm.campaign.endDate;

            if (vm.selectedLocations.length > 0) {
                var locations = vm.selectedLocations.map(function(elem){
                    return elem.id;
                }).join(',');
                url += '&locations=' + locations;
            }

            $window.open(url);

            $uibModalInstance.dismiss();

            return false;
        }

        function cancel() {
            return $uibModalInstance.dismiss();
        }
    }

}());
