const moment = require('moment');

(function () {
    'use strict';

    /* @ngInject */
    function configure(
        $locationProvider,
        $urlRouterProvider,
        $stateProvider,
        $httpProvider,
        cfpLoadingBarProvider
    ) {
        $locationProvider.html5Mode(true);
        cfpLoadingBarProvider.includeSpinner = false;
        $stateProvider.state({
            name: 'admin',
            abstract: true,
            templateUrl: 'base.html',
            resolve: {
                session: function (tvlSession) {
                    return tvlSession.getSession().$promise;
                }
            }
        }).state({
            name: 'admin.dashboard',
            url: '/administration/dashboard',
            controller: 'AdminDashboardController as vm',
            templateUrl: 'dashboard.html',
        }).state({
            name: 'admin.exchangeRates',
            url: '/administration/exchange-rates',
            controller: 'ExchangeRateListController as vm',
            templateUrl: 'exchange-rates/list.html',
            reloadOnSearch: false,
            resolve: {
                /* @ngInject */
                exchangeRates: function ($stateParams, ExchangeRate) {
                    return ExchangeRate.get({}).$promise;
                }
            }
        }).state({
            name: 'admin.brands',
            url: '/administration/brands',
            controller: 'BrandsController as vm',
            templateUrl: 'brands/brands.html'
        }).state({
            name: 'admin.accounts',
            url: '/administration/accounts',
            controller: 'AccountsController as vm',
            templateUrl: 'accounts/accounts.html'
        }).state({
            name: 'admin.accountsEdit',
            url: '/administration/accounts/:id',
            controller: 'AccountsEditController',
            templateUrl: 'accounts/accounts-edit.html',
            resolve: {
                /* @ngInject */
                account: function(Account, $stateParams){
                    return Account.get({id: $stateParams.id}).$promise;
                }
            }
        }).state({
            name: 'admin.users',
            url: '/administration/users',
            controller: 'UsersController as vm',
            templateUrl: 'users/users.html',
        }).state({
            name: 'admin.usersEdit',
            url: '/administration/users/:id',
            controller: 'UsersEditController',
            templateUrl: 'users/users-edit.html',
            resolve: {
                /* @ngInject */
                user: function(User, $stateParams){
                    return User.get({id: $stateParams.id}).$promise;
                }
            }
        })
        .state({
            name: 'admin.categories',
            url: '/administration/categories',
            controller: 'CategoriesController as vm',
            templateUrl: 'categories/channel_categories.html',
            resolve: {
                /* @ngInject */
                loggedUser: function (tvlSession) {
                    return tvlSession.getSession();
                }
            }
        }).state({
            name: 'admin.companies',
            url: '/administration/companies',
            controller: 'CompaniesController as vm',
            templateUrl: 'companies/companies.html'
        }).state({
            name: 'admin.companiesEdit',
            url: '/administration/companies/:id',
            controller: 'CompaniesEditController',
            templateUrl: 'companies/companies-edit.html',
            resolve: {
                /* @ngInject */
                company: function(Company, $stateParams){
                    return Company.get({id: $stateParams.id}).$promise;
                }
            }
        }).state({
            name: 'admin.maintenanceMode',
            url: '/administration/maintenance-mode',
            controller: 'MaintenanceModeController as vm',
            templateUrl: 'maintenance-mode/maintenance_mode.html',
            resolve: {
                /* @ngInject */
                maintenanceMode: function (MaintenanceMode) {
                    return MaintenanceMode.get().$promise;
                }
            }
        }).state({
            name: 'admin.grafanaDashboard',
            url: '/administration/grafana-dashboard',
            controller: 'GrafanaDashboardController as vm',
            templateUrl: 'grafana-dashboard/grafana_dashboard.html'
        });
    }

    configure.$inject = [
        '$locationProvider',
        '$urlRouterProvider',
        '$stateProvider',
        '$httpProvider',
        'cfpLoadingBarProvider'
    ];

    angular
        .module('tvl.administration', [
            'ngAnimate',
            'ngSanitize',
            'ngFileUpload',
            'ui.router',
            'ui.bootstrap',
            'ui.select',
            'angularMoment',
            'toastr',
            'oitozero.ngSweetAlert',
            'angular-loading-bar',
            'checklist-model',
            'xeditable',
            'fcsa-number',
            'ngTable',
            'filterNumber',
            'NgSwitchery',
            'tvl.common',
            'chart.js'
        ])
        .constant('moment', moment)
        .constant('accountTypes', [{key: 'Agency', value:'agency'}, {key: 'Client', value:'client'}])
        .constant('languages', [{key: 'English', value: 'en'}, {key: 'Spanish', value: 'es'}, {key: 'French', value: 'fr'}])
        .constant('roles', [
            {
                key: 'Administrator',
                value: 'ROLE_ADMIN',
                compatible_with: null
            },
            {
                key: 'Company Manager',
                value: 'ROLE_MANAGER',
                compatible_with: [
                    'ROLE_BRAND_SAFETY_ADMIN',
                    'ROLE_BRAND_SAFETY_USER',
                ]
            },
            {
                key: 'Support',
                value: 'ROLE_SUPPORT',
                compatible_with: [
                    'ROLE_TRADING_OPS',
                    'ROLE_FINANCE',
                    'ROLE_BRAND_SAFETY_ADMIN',
                    'ROLE_BRAND_SAFETY_USER',
                ]
            },
            {
                key: 'Trading Operations',
                value: 'ROLE_TRADING_OPS',
                compatible_with: [
                    'ROLE_FINANCE',
                    'ROLE_SUPPORT',
                    'ROLE_BRAND_SAFETY_ADMIN',
                    'ROLE_BRAND_SAFETY_USER',
                ]
            },
            {
                key: 'Finance',
                value: 'ROLE_FINANCE',
                compatible_with: [
                    'ROLE_TRADING_OPS',
                    'ROLE_SUPPORT',
                    'ROLE_BRAND_SAFETY_ADMIN',
                    'ROLE_BRAND_SAFETY_USER',
                ]
            },
            {
                key: 'Partner',
                value: 'ROLE_PARTNER',
                compatible_with: [
                    'ROLE_CLIENT',
                ]
            },
            {
                key: 'Client',
                value: 'ROLE_CLIENT',
                compatible_with: [
                    'ROLE_PARTNER',
                ]
            },
            {
                key: 'Brand Safety Admin',
                value: 'ROLE_BRAND_SAFETY_ADMIN',
                compatible_with: [
                    'ROLE_MANAGER',
                    'ROLE_SUPPORT',
                    'ROLE_FINANCE',
                    'ROLE_TRADING_OPS',
                ]
            },
            {
                key: 'Brand Safety User',
                value: 'ROLE_BRAND_SAFETY_USER',
                compatible_with: [
                    'ROLE_MANAGER',
                    'ROLE_SUPPORT',
                    'ROLE_FINANCE',
                    'ROLE_TRADING_OPS',
                ]
            },
        ])
        .config(configure);
}());
