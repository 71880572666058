(function () {
    'use strict';

    angular
        .module('tvl.campaign')
        .controller('UpdateFacebookAdVideoModalController', UpdateFacebookAdVideoModalController);

    UpdateFacebookAdVideoModalController.$inject = [
        '$scope',
        '$uibModalInstance',
        'tvlAdVideoUtils',
        'tvlUuid',
        'Upload',
        'adInfo'
    ];

    /* @ngInject */
    function UpdateFacebookAdVideoModalController(
        $scope,
        $uibModalInstance,
        tvlAdVideoUtils,
        tvlUuid,
        Upload,
        adInfo
    ) {

        $scope.updateAdVideo = updateAdVideo;
        $scope.cancel = cancel;
        $scope.onImageChange = onImageChange;

        $scope.maxFileSize = 1000000; //1MB
        $scope.validImageTypes = ['image/jpeg', 'image/png']; //jpg png
        $scope.facebookAdVideoModel = tvlAdVideoUtils.buildAd(adInfo);
        $scope.imageSource = null;

        activate();

        function activate() {
            $scope.imageSource = adInfo.videoThumbnail;
            $scope.facebookAdVideoModel.title = adInfo.videoTitle;
            $scope.facebookAdVideoModel.imageContent = null;
        }

        function onImageChange($files, $file) {
            $scope.facebookAdVideoModel.imageContent = null;
            $scope.uploadedImage = null;

            if ($file) {
                $scope.uploadedImage = {
                    name: $file.name,
                    size: $file.size,
                    type: $file.type,
                    imageContent: null
                };
                Upload.base64DataUrl($files).then(onUploadFileUpdateSuccess);
            }

            function onUploadFileUpdateSuccess(urls) {
                $scope.facebookAdVideoModel.imageContent = urls[0];
                $scope.imageSource = urls[0];
            }

        }

        function updateAdVideo(adVideo) {
            if (!adVideo.id) {
                adVideo.id = tvlUuid.new();
            }
            //To avoid errors in placements.html
            // if we add more fields to this update modal we need to rethink this
            delete adVideo.videoUrl;
            delete adVideo.targetUrl;
            delete adVideo.trackingUrl;

            $uibModalInstance.close({adVideo: adVideo, accessible: true});
        }

        function cancel() {
            return $uibModalInstance.dismiss();
        }
    }
}());