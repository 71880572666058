(function() {
    'use strict';

    angular
        .module('tvl.campaign')
        .service('tvlContentSelectorModal', tvlContentSelectorModal);

    tvlContentSelectorModal.$inject = ['$uibModal'];

    /**
     * A service to interact with the content selector modal.
     *
     * @ngInject
     */
    function tvlContentSelectorModal($uibModal) {
        return {
            openSelectorModal: openSelectorModal
        };

        /**
         * Open the content selector modal.
         *
         * This function returns a promise that will be resolved with the items
         * selected in the modal.
         *
         * This function also expects two parameters:
         *  - An array of items which represent the current selection, so that
         *    the modal is aware of what has been previously chosen.
         *  - An array of country codes which allow limiting the application of
         *    content lists to placements of those countries only.
         *
         * @param {Array} currentSelection
         * @param {Array} availableCountries
         * @return {Promise}
         */
        function openSelectorModal(currentSelection, availableCountries) {
            var modal = $uibModal.open({
                size: 'lg',
                templateUrl: 'campaign/content-selector-modal.html',
                controller: 'ContentSelectorModalController as vm',
                bindToController: true,
                resolve: {
                    /** @ngInject */
                    lists: function(ContentsList) {
                        return ContentsList.get({
                            items_per_page: 10,
                            processingFile: false,
                            enabled: true
                        }).$promise;
                    },
                    currentSelection: function() {
                        return currentSelection;
                    },
                    availableCountries: function () {
                        return availableCountries;
                    },
                }
            });

            return modal.result;
        }
    }
}());
