(function() {
    'use strict';

    angular
        .module('tvl.campaign')
        .directive('tvlFacebookCarouselForm', tvlFacebookCarouselForm);

    /** @ngInject */
    function tvlFacebookCarouselForm() {
        return {
            bindToController: true,
            controller: TvlFacebookCarouselFormController,
            controllerAs: 'vm',
            restrict: 'E',
            scope: {
                model: '=',
                callToActionValues: '=',
                facebookPage: '=',
                facebookAccountCredentials: '='
            },
            templateUrl: 'campaign/wizard/directives/fb-carousel-form.html'
        };
    }

    TvlFacebookCarouselFormController.$inject = ['$scope', 'tvlUrl', 'tvlUuid', 'FacebookBusiness', 'tvlAdVideoUtils', 'toastr'];

    /* @ngInject */
    function TvlFacebookCarouselFormController($scope, tvlUrl, tvlUuid, FacebookBusiness, tvlAdVideoUtils, toastr) {

        var vm = this;

        vm.isModalBlocked = isModalBlocked;
        vm.getAvailableFacebookAdVideos = getAvailableFacebookAdVideos;
        vm.addCard = addCard;
        vm.isButtonDisabled = isButtonDisabled;
        vm.oneAtATime = true;
        vm.isLoading = false;
        vm.closOthers= false;
        vm.tvlUrl = tvlUrl;
        vm.showAssets = false;
        vm.isBlocked = false;
        vm.facebookCallToActionButtonValues = vm.callToActionValues;
        vm.minNumOfCards = 2;
        vm.maxNumOfCards = 10;
        vm.isVideo = false; //for now, this option is not available for Fb Carousel Ads
        vm.isPhoto = true; //this the only option.
        vm.creativeSourceType = 'photo'; //for now, this option is not available for Fb Carousel Ads //todo select menu

        vm.creativeTemplate = {
            videoId: null,
            type: '',
            imageUrl: '',
            name: '',
            description: '',
            headLine:'',
            link: '',
            showAssets: false,
            asset: null
        };

        vm.unregisterFns = [];

        function activate() {
            initCarouselCards();
            initWatchers();
            getAvailableFacebookAdVideos();
            $scope.$on('$destroy', function() {
                destroyWatchers();
            });
        }

        activate();

        function initCarouselCards() {
            var i = 0;
            do {
                var template = Object.assign({}, vm.creativeTemplate);
                template.cardId = tvlUuid.new();
                vm.model.creatives.push(template);
                i += 1;
            } while (i < vm.minNumOfCards);
        }

        function onCreativeAssetChange(creative) {
            return function (newValue, oldValue) {
                if (newValue === oldValue) {
                    return;
                }
                if (creative.asset) {
                    creative.videoId = creative.asset.id;
                    creative.imageUrl = creative.asset.permalink_url;
                    creative.name = creative.asset.name;
                } else {
                    creative.videoId = '';
                    creative.imageUrl = '';
                    creative.name = '';
                }
            };
        }

        function initWatchers() {
            vm.model.creatives.forEach(function(creative) {
                vm.unregisterFns.push($scope.$watch(function() {return creative.asset;}, onCreativeAssetChange(creative) ,true));
                vm.unregisterFns.push($scope.$watch(function() {return creative.showAssets;},onCreativeShowAssetsChange(creative), true));
            });
        }

        function destroyWatchers() {
            for(var i in vm.unregisterFns) {
                vm.unregisterFns[i]();
            }
        }

        function onCreativeShowAssetsChange(creative) {
            return function (newVal, oldVal) {
                if (newVal === oldVal) {
                    return;
                }
                creative.asset = null;
            };
        }

        function addCard() {
            if (vm.model.creatives.length === vm.maxNumOfCards) {
                toastr.error(
                    'The minimum number of Cards for a Facebook Carousel Ad is '+ vm.minNumOfCards +'.'
                );
                return;
            }

            var template = Object.assign({}, vm.creativeTemplate);
            vm.model.creatives.push(template);
            destroyWatchers();
            initWatchers();
        }

        function isButtonDisabled () {
            return vm.model.creatives.length <=2;
        }

        vm.removeCard = function (index, event) {
            event.preventDefault();
            if (vm.model.creatives.length <= vm.minNumOfCards) {
                toastr.error(
                    'The minimum number of Cards for a Facebook Carousel Ad is '+ vm.minNumOfCards +'.'
                );
            } else {
                vm.model.creatives.splice(index, 1);
                destroyWatchers();
                initWatchers();
            }
        };

        function getAvailableFacebookAdVideos() {
            vm.isLoading = true;
            switch (vm.creativeSourceType) {
                case 'photo':
                    return FacebookBusiness.getAdImages({
                        accountId: vm.facebookAccountCredentials.accountId,
                        adAccountId: vm.facebookAccountCredentials.adAccountId,
                        adPosition: vm.model.facebookPositions
                    })
                        .$promise
                        .then(onGetAdVideosSuccess)
                        .catch(onGetAdVideosError)
                        .finally(onGetAdVideosFinally);
            }

            function onGetAdVideosSuccess(adVideos) {
                if ( vm.creativeSourceType === 'photo') {
                    adVideos = tvlAdVideoUtils.normalizeFacebookAdImagesData(adVideos);
                }

                vm.availableAssets = adVideos;
                //vm.ui.facebookAdVideoAccessible = true; ???
            }

            function onGetAdVideosError(error) {
                toastr.error(
                    'Our apologies, we have been unable to find ad videos for this Facebook account.'
                );
            }

            function onGetAdVideosFinally() {
                vm.isLoading = false;
            }
        }

        /**
         * todo find a way to block everything while uploading
         * Disable modal controls when necessary.
         * @returns {boolean}
         */
        function isModalBlocked() {
            return vm.isLoading || vm.isBlocked;
        }
    }

})();
