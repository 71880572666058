(function() {
    'use strict';

    angular
        .module('tvl.login')
        .controller('LoginController', LoginController);

    LoginController.$inject = [
        '$rootScope',
        '$scope',
        '$window',
        '$state',
        'toastr',
        'jwt',
        'resourceResponse',
        'Login',
        'tvlSession',
        'ApiKeys'
    ];

    function LoginController(
        $rootScope,
        $scope,
        $window,
        $state,
        toastr,
        jwt,
        resourceResponse,
        Login,
        tvlSession,
        ApiKeys
    ) {
        $rootScope.bodyClass = 'login';
        $scope.loginModel = {
            username: null,
            password: null,
        };
        $scope.error = null;
        $scope.maintenance = false;

        $scope.login = function() {
            var dataToLogin = {
                email: $scope.loginModel.username,
                password: $scope.loginModel.password
            };
            Login.create(dataToLogin).$promise
                .then(function(response) {
                    $scope.error = null;
                    jwt.saveTokenToSessionStorage(response.data.token);

                    ApiKeys.get().$promise.then(function(response) {
                        //TODO move these to build webpack.
                        tvlSession.saveApiKeys(response.data);

                        tvlSession.getSession().then(function (user) {
                            redirectByPermission(user);
                        }, onError);
                    }, onError);
                }, onError);

                function onError(response) {
                    $scope.error = resourceResponse.defaultErrorMessage();

                    if (403 === response.status) {
                        $scope.error = resourceResponse.getErrorFromResponse(response).message;
                        $scope.maintenance = resourceResponse.getErrorFromResponse(response).key === 'maintenance_enabled';
                    }

                    if (401 === response.status) {
                        $scope.error = resourceResponse.getErrorFromResponse(response).message;
                    }
                }
        };

        function redirectByPermission(user) {
            if (user['hasToChangePassword']) {
                $state.go('login.change');
            } else if (user.hasPermission('Campaign.view')) {
                window.location.href = '/campaigns/list?status%5B%5D=paused&status%5B%5D=active';
            } else {
                $state.go('admin.dashboard');
            }
        }

        window.sessionStorage.removeItem("tvl.user");
        window.localStorage.removeItem("tvl.datepicker.startDate");
        window.localStorage.removeItem("tvl.datepicker.endDate");
    }
})();
