(function () {
    'use strict';

    angular
        .module('tvl.common')
        .service('tvlAuthInterceptor', tvlAuthInterceptor);

    tvlAuthInterceptor.$inject = ['$q', '$window', '$injector', 'jwt'];

    function tvlAuthInterceptor($q, $window, $injector, jwt) {
        var service = this;
        var replays = [];
        var refreshTokenPromise;

        service.responseError = responseError;

        service.request = function (config) {
            var token = jwt.getTokenFromSessionStorage();

            if (null !== token && isOurApiUrl(config.url)) {
                config.headers.Authorization = 'Bearer ' + token;
            }

            return config;
        };

        function isOurApiUrl(url) {
            return url.substr(0, 4) === '/api';
        }

        function isRefreshTokenUrl(url) {
            return url.substr(0, 18) === '/api/token/refresh';
        }

        // Intercept 401s and redirect you to login
        function responseError(response) {
            if (isOurApiUrl(response.config.url) &&
                response.status == 403 &&
                false === isRefreshTokenUrl(response.config.url) &&
                false === _.isNil(response.data.errors[0]['key']) &&
                response.data.errors[0]['key'] === 'maintenance_enabled'
            ) {
                cancelRequestsAndRedirect();
            }

            if (isOurApiUrl(response.config.url) &&
                response.status == 401 &&
                false === isRefreshTokenUrl(response.config.url)
            ) {
                return checkAuthorization(response);
            }

            return $q.reject(response);

            /////////

            function checkAuthorization(res) {
                return $q(function (resolve, reject) {

                    var replay = {
                        success: function () {
                            $injector.get('$http')(res.config).then(resolve, reject);
                        },

                        cancel: function () {
                            reject(res);
                        }
                    };

                    replays.push(replay);

                    if (!refreshTokenPromise) {
                        var Token = $injector.get('Token');

                        refreshTokenPromise = Token.refresh().$promise
                            .then(clearRefreshTokenPromise)
                            .then(replayRequests)
                            .catch(cancelRequestsAndRedirect);
                    }
                });

                ////////////

                function clearRefreshTokenPromise(auth) {
                    jwt.saveTokenToSessionStorage(auth.data.token);
                    refreshTokenPromise = null;

                    return auth;
                }

                function replayRequests(auth) {
                    replays.forEach(function (replay) {
                        replay.success();
                    });

                    replays.length = 0;

                    return auth;
                }
            }

            function cancelRequestsAndRedirect() {
                refreshTokenPromise = null;
                replays.forEach(function (replay) {
                    replay.cancel();
                });

                replays.length = 0;

                jwt.clearSession();

                var $state = $injector.get('$state');
                $state.go('login.login');
            }
        }
    }
}());
