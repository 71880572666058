(function(tld) {
    angular
        .module('tvl.common')
        .service('tvlCsvExporter', tvlCsvExporter);

    function tvlCsvExporter() {
        return {
            exportCSVFile: exportCSVFile,
        };


        function exportCSVFile(headers, items, fileName) {
            if (headers) {
                var itemsCopy = angular.copy(items)
                itemsCopy.unshift(headers);
            }

            const jsonObject = angular.toJson(itemsCopy);

            const csv = convertToCSV(jsonObject);

            const exportName = fileName + ".csv" || "export.csv";

            const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
            if (navigator.msSaveBlob) {
                navigator.msSaveBlob(blob, exportName);
            } else {
                const link = document.createElement("a");
                if (link.download !== undefined) {
                    const url = URL.createObjectURL(blob);
                    link.setAttribute("href", url);
                    link.setAttribute("download", exportName);
                    link.style.visibility = "hidden";
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            }
        }

        function convertToCSV(objArray) {
            const array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
            var str = "";

            for (var i = 0; i < array.length; i++) {
                var line = "";
                for (var index in array[i]) {
                    if (line != "") line += ";";

                    line += "\""+array[i][index]+"\"";
                }

                str += line + "\r\n";
            }

            return str;
        }
    }
})();
