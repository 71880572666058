(function () {
    angular
        .module('tvl.campaign')
        .directive('tvlFacebookAssetSourceButton', tvlFacebookAssetSourceButton);

    /** @ngInject */
    function tvlFacebookAssetSourceButton() {
        return {
            link: link,
            restrict: 'E',
            replace: true,
            scope: {
                ngModel: '=',
                isModalBlocked: '=',
            },
            templateUrl: 'campaign/wizard/directives/fb-asset-source-button.html',
        };
        function link(scope, element, attrs) {
        }
    }
})();
