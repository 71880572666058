(function() {
    angular
        .module('tvl.common')
        .service('tvlUi', tvlUi);

    function tvlUi() {
        return {
            getThemeColors: getThemeColors,
            getThemeColorsForWordsCloud: getThemeColorsForWordsCloud,
            getThemeColorForPosition: getThemeColorForPosition,
            getRibbonColor: getRibbonColor,
            getChannelOrVideoUrl: getChannelOrVideoUrl,
            getHighmapMapForCountryCode: getHighmapMapForCountryCode,
            getFacebookReactions: getFacebookReactions,
            getPlatformDisplayName: getPlatformDisplayName,
            getPlatformIcon: getPlatformIcon,
            getPlatformIconStyle: getPlatformIconStyle,
            getSubscribersNameForPlatform: getSubscribersNameForPlatform,
            getFlagForLanguage: getFlagForLanguage,
        };

        /**
         * Return the set of colors used in our
         * app's theme.
         *
         * Colors are returned as hex RGB.
         *
         * @return {string[]}
         */
        function getThemeColors() {
            return [
                '#239DDD',
                '#9754B0',
                '#677AC6',
                '#DF068C',
                '#E4E8F5',
                '#64B3E4',
                '#ABCEED',
                '#BD83C6',
                '#DDB7DD'
            ];
        }

        /**
         * Returns the set of colors used in cloudwords
         * Colors are returned as hex RGB.
         *
         * @returns {string[]}
         */
        function getThemeColorsForWordsCloud() {
            return [
                '#F6511D',
                '#FFB400',
                '#239DDD',
                '#7FB800',
                '#0D2C54'
            ];
        }

        /**
         * Return the theme color to be used with an
         * item at the given position.
         *
         * This is a convenience function to determine
         * a consistent color to be used in a given position,
         * allowing to wrap around the set of theme colors.
         *
         * The chosen color will be returned as hex RGB.
         *
         * @param {Number} i
         * @return {string}
         */
        function getThemeColorForPosition(i) {
            colors = getThemeColors();
            return colors[i % colors.length];
        }

        /**
         * Get the correct color of the ribbon depending of the text
         * @param Campaign
         * @return {string} The color in string format.
         */
        function getRibbonColor(campaign) {
            switch (campaign.status) {
                case 'active':
                    return 'green';
                case 'rejected':
                case 'finished':
                    return 'red';
                case 'pending':
                    return 'blue';
            }
        }

        /**
         * Get the url to access a channel or video
         * @param item
         * @returns {*|{dest}|string} Channel or video url
         */
        function getChannelOrVideoUrl(item) {
            if (item.videoId) {
                return '/video/' + item.videoId;
            }
            return '/channel/'+item.channelId;
        }

        /**
         * Return the appropriate Highmaps map identifier for the given country
         * code.
         *
         * @param {string} countryCode
         * @returns {string}
         */
        function getHighmapMapForCountryCode(countryCode) {
            var key = null;
            switch (countryCode) {
                case 'fr':
                    key = 'custom/fr-all-mainland';
                    break;
                case 'gb':
                    key = 'custom/gb-countries';
                    break;
                default:
                    key = countryCode + '-all';
            }

            return 'countries/' + countryCode + '/' + key;
        }

        /**
         * Return the set of facebook reactions.
         *
         * Reactions are returned as JSON objects with
         * key, emoji code and name to display.
         *
         * @returns {object[]}
         */
        function getFacebookReactions() {
            return [
                {key: 'like', emoji: '1f44d', displayName: 'Like'},
                {key: 'hearts', emoji: '2665', displayName: 'Love'},
                {key: 'hahas', emoji: '1f602', displayName: 'Haha'},
                {key: 'wows', emoji: '1f62e', displayName: 'Wow'},
                {key: 'sorries', emoji: '1f622', displayName: 'Sad'},
                {key: 'angers', emoji: '1f621', displayName: 'Angry'}
            ];
        }

        /**
         * Return the appropriate display name for the given platform.
         *
         * @param {String} platform
         * @return {String}
         */
        function getPlatformDisplayName(platform) {
            switch (platform) {
                case 'theviralab':
                    return 'theviralab.com';
                case 'youtube':
                    return 'YouTube';
                case 'facebook':
                    return 'Facebook';
                default:
                    return null;
            }
        }

        /**
         * Return the appropriate icon class for the given platform.
         *
         * @param {String} platform
         * @return {String}
         */
        function getPlatformIcon(platform) {
            switch (platform) {
                case 'theviralab':
                    return 'tvl-icon-tvl';
                case 'youtube':
                    return 'fa-youtube-play';
                case 'facebook':
                    return 'fa-facebook-square';
                default:
                    return null;
            }
        }

        /**
         *
         * @param {String} platform
         * @returns {String}
         */
        function getPlatformIconStyle(platform) {
            switch (platform) {
                case 'theviralab':
                    return '';
                case 'youtube':
                    return 'color: #ff0000;';
                case 'facebook':
                    return 'color: #3b5998;';
                default:
                    return null;
            }
        }

        /**
         * Return the appropriate word to refer to subscribers for the given
         * platform.
         *
         * @param {String} platform
         * @return {String}
         */
        function getSubscribersNameForPlatform(platform) {
            switch (platform) {
                case 'facebook':
                    return 'fans';
                default:
                    return 'subscribers';
            }
        }

        /**
         * Return the appropriate flag for the given language, given as an ISO
         * code.
         *
         * @param {String} language
         * @return {String}
         */
        function getFlagForLanguage(language) {
            var map = {
                'ca': 'es-ct',
                'en': 'gb',
                'en-gb': 'gb',
                'en-us': 'us',
            };
            language = language.toLowerCase();
            return map[language] || language;
        }
    }
})();
