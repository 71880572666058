/**
 * Update a given model with changes from Pusher messages
 */

(function() {
    'use strict';

    angular
        .module('tvl.common')
        .factory('tvlModelChangesUpdater', tvlModelChangesUpdater);

    function tvlModelChangesUpdater(tvlSession, $pusher) {
        var client = null;
        return {
            subscribe: function(model, modelName, callback) {
            },
            unsubscribe: function(model, modelName) {
            }
        };
    }

})();
