(function() {
    'use strict';

    angular
        .module('tvl.charts')
        .controller('WeatherCalendarController', WeatherCalendarController);

    WeatherCalendarController.$inject = ['$scope', '$location'];

    /* @ngInject */
    function WeatherCalendarController($scope, $location) {
        $scope.width = $location.search().w;
        $scope.height = $location.search().h;
        $scope.startDate = $location.search().sd;
        $scope.endDate = $location.search().ed;
        $scope.weeks = decodeURIComponent($location.search().wd);
    }
})();
