(function () {
    'use strict';

    angular
        .module('tvl.campaign')
        .controller('WizardInformationController', WizardInformationController);

    WizardInformationController.$inject = [
        '$scope',
        '$window',
        'toastr',
        'Account',
        'Company',
        'tvlUtils',
        'tvlConstantUtils',
        'tvlForm',
        'tvlSession'
    ];

    /* @ngInject */
    function WizardInformationController(
        $scope,
        $window,
        toastr,
        Account,
        Company,
        tvlUtils,
        tvlConstantUtils,
        tvlForm,
        tvlSession
    ) {
        var vm = this;
        vm.parent = $scope.$parent;
        vm.wizardData = vm.parent.wizardData;
        vm.campaignForm = vm.parent.campaignForm;
        vm.wizardUtils = vm.wizardData.utils;

        vm.unregisterFns = [];
        vm.isValidNetworkDates = true;
        vm.isValidNetworkMaxStartDate = true;
        vm.isCompatibleNetworks = true;
        vm.loggedUser = null;
        vm.isAnyNetworkSelected = false;

        vm.ui = {
            today: new Date(),
            loadingAccounts: false
        };

        vm.refreshAccountList = refreshAccountList;
        vm.refreshCompanyList = refreshCompanyList;
        vm.onCompanyChange = onCompanyChange;
        vm.mustSeeCompanySelector = mustSeeCompanySelector;
        vm.canChangeTechnologyFee = canChangeTechnologyFee;

        activate();

        ////////////////

        function activate() {

            tvlSession.getSession().then(
                function (data) {
                    vm.loggedUser = data;
                    if (!vm.loggedUser.isGlobal) {
                        // Todo (ika): Check this, id to company object?
                        vm.wizardData.company = vm.loggedUser.company.id;
                    }

                    /*
                     * Watching primitive values doesn't really work because when you change the value of a primitive
                     * you get a new reference. To get around this, you have to wrap your primitive in an object
                     * and watch the object.
                     *
                     * Important: Do not bind the customer-account to the vm directly.
                     */
                    vm.unregisterFns = [
                        $scope.$watch('campaignForm.fee', onFeeChange),
                        $scope.$watch('campaignForm.grossBudget', onGrossBudgetChange),
                        $scope.$watch('wizardData.customerAccount', onCustomerAccountChange),
                        $scope.$watch('campaignForm.devices.mobile', onSelectedDevicesMobileChange),
                        $scope.$watch('campaignForm.devices.desktop', onSelectedDevicesDesktopChange),
                        $scope.$watch('campaignForm.operatingSystems', onOperatingSystemChange, true),
                        $scope.$watch('campaignForm.endDate', applyCampaignDatesConstraints),
                        $scope.$watch('campaignForm.startDate', applyCampaignDatesConstraints),
                        // clear media distribution when selected networks change
                        $scope.$watch('campaignForm.networks', onSelectedNetworksChange, true),
                        $scope.$on('tvlValidate', function (event, step) {
                            if (step.key === 'info') {
                                vm.parent.$emit('tvlValidationEnd', {'valid': validate()});
                            }
                        })
                    ];

                    checkNetworkConfigurationExists();
                }
            );

        }

        function mustSeeCompanySelector() {
            return vm.parent.userIsGlobal;
        }

        function canChangeTechnologyFee() {
            var hasPermissionToChange = false;

            if (false === _.isNil(vm.wizardData.customerAccount)) {
                hasPermissionToChange = vm.loggedUser.hasAccountPermission(
                    vm.wizardData.customerAccount.id,
                    'Campaign.can_change_technology_fee'
                );
            }

            return hasPermissionToChange;
        }

        function refreshAccountList(search, company) {
            vm.ui.loadingAccounts = true;
            $scope.$parent.ui.disableContinue = true;

            var id = null;

            if (company) {
                id = company.id;
            }

            var params = {
                term: search || null,
                enabled: true,
                'company[]': id || null
            };

            return Account.get(params).$promise
                .then(onGetAccountsSuccess, onGetAccountsError)
                .finally(function () {
                    vm.ui.loadingAccounts = false;
                    $scope.$parent.ui.disableContinue = false;
                });

            function onGetAccountsSuccess(accounts) {
                vm.parent.accountList = accounts.items;
            }

            function onGetAccountsError(error) {
                toastr.error('Our apologies, we have been unable to recover your accounts. Please, try again later.');
            }
        }

        function refreshCompanyList(search) {
            var params = {
                term: search || null,
                status: 'enabled'
            };
            return Company.get(params).$promise
                .then(onGetCompaniesSuccess, onGetCompaniesError);

            function onGetCompaniesSuccess(companies) {
                vm.companies = companies.items;
            }

            function onGetCompaniesError(error) {
                toastr.error('Our apologies, we have been unable to recover your companies. Please, try again later.');
            }
        }

        function onCompanyChange() {
            vm.wizardData.customerAccount = null;
            refreshAccountList(null, vm.wizardData.company);
        }

        function applyCampaignDatesConstraints() {
            ensureDateGreaterThanToday();
            disableModifyHoursIfGoogleNetwork();
            vm.isValidNetworkDates = isValidFacebookDates();
            vm.isValidNetworkMaxStartDate = isValidFacebookMaxStartDate();

            // verify if any network is selected
            isAnyNetworkSelected();
        }

        /*
         * This removes the enableHoursModification in this scenario:
         * in multicampaign you enable facebook and google and then the enableHoursModification
         * afterwards you unselect facebook.
         */
        function disableModifyHoursIfGoogleNetwork() {
            let isGoogleNetworkSelected = vm.campaignForm.networks.googleDisplay || vm.campaignForm.networks.youtube;
            let isFacebookNetworkSelected = vm.campaignForm.networks.facebook || vm.campaignForm.networks.instagram;

            if (isGoogleNetworkSelected && !isFacebookNetworkSelected) {
                vm.campaignForm.enableHoursModification = false;
            }
        }

        function isAnyNetworkSelected() {
            if (vm.campaignForm.networks.facebook ||
                vm.campaignForm.networks.instagram ||
                vm.campaignForm.networks.googleDisplay ||
                vm.campaignForm.networks.youtube) {
                vm.isAnyNetworkSelected = true;
                return true;
            }

            vm.isAnyNetworkSelected = false;
            return false;
        }

        /**
         * Check if campaign start date and end date are greater than today
         */
        function ensureDateGreaterThanToday() {
            var today = new Date();
            today.setHours(0, 0, 0);
            if (vm.campaignForm.startDate && vm.campaignForm.startDate.getTime() < today.getTime()) {
                vm.campaignForm.startDate.setDate(today.getDate());
            }
        }

        function onCustomerAccountChange(newVal, oldVal) {
            if (newVal && newVal.hasOwnProperty('id')) {
                var isSameCustomerAccount = (oldVal && oldVal.hasOwnProperty('id') && oldVal.id === newVal.id);
                if (isSameCustomerAccount !== true) {
                    vm.wizardData.setCustomerAccount(newVal);
                    checkNetworkConfigurationExists();
                }
            } else {
                /**
                 * There is no customer account selected:
                 * Set default fee and rebate values
                 */
                vm.campaignForm.fee = null;
                vm.campaignForm.rebate = null;
            }

            onGrossBudgetChange(vm.campaignForm.grossBudget);
        }

        function checkNetworkConfigurationExists() {
            var isDefinedCustomerAccount = false === _.isNil(vm.wizardData.customerAccount);

            $scope.facebookConfigurationExists = isDefinedCustomerAccount &&
                false === _.isNil(vm.wizardData.customerAccount.facebookConfiguration);
            $scope.adwordsConfigurationExists = isDefinedCustomerAccount &&
                false === _.isNil(vm.wizardData.customerAccount.adwordsConfiguration);
        }

        function onGrossBudgetChange(newVal, oldVal) {
            if (newVal !== oldVal) {
                vm.wizardData.applyGrossBudgetToCampaign(newVal);
            }
        }

        function onFeeChange() {
            vm.wizardData.applyGrossBudgetToCampaign(vm.campaignForm.grossBudget);
        }

        function onSelectedNetworksChange(newVal, oldVal) {
            if (angular.equals(newVal, oldVal)) {
                return;
            }

            // available interests depend on the selected networks, so every
            // time these change we gotta clear them
            vm.parent.availableInterests = [];

            // also remove selected interests of networks which are no longer
            // selected
            var i = vm.campaignForm.targeting.selectedInterests.length;
            while (i--) {
                var interest = vm.campaignForm.targeting.selectedInterests[i];
                if ((interest.network === tvlConstantUtils.NETWORK_ADWORDS && !newVal.youtube) ||
                    (interest.network === tvlConstantUtils.NETWORK_FACEBOOK && (!newVal.facebook || !newVal.instagram))) {
                    vm.campaignForm.targeting.selectedInterests.splice(i, 1);
                }
            }

            // validate budget distribution across networks
            for (var network in newVal) {
                if (newVal[network] === false) {
                    delete vm.campaignForm.adDistribution[network];
                    vm.campaignForm.media[network] = 0;
                }
            }

            deleteUnusedNetworkLocations();

            // @todo why we are calling this? Anyway this should be done let's figure out how
            // validateNetworkBudgetDistribution();
            // validateMediaBudgetDistribution();
            applyCampaignDatesConstraints();
        }

        /**
         * Search and delete unused networks' locations.
         */
        function deleteUnusedNetworkLocations() {
            if (!vm.campaignForm.targeting.locations || !vm.campaignForm.targeting.locations.length) {
                return;
            }

            var location;
            var filteredLocations = [];
            for (var i = 0; i < vm.campaignForm.targeting.locations.length; i++) {
                location = vm.campaignForm.targeting.locations[i];
                if (isLocationAllowedByNetwork(location)) {
                    filteredLocations.push(location);
                }
            }
            vm.campaignForm.targeting.locations = filteredLocations;
        }

        /**
         * Checks if current location is allowed by it's network.
         * @param location
         * @returns {boolean}
         */
        function isLocationAllowedByNetwork(location) {
            if (tvlConstantUtils.isNetworkAdwords(location.network) && isGoogleNetworkEnabled()) {
                return true;
            }
            if (tvlConstantUtils.isNetworkFacebook(location.network) && isFacebookNetworkEnabled()) {
                return true;
            }
            return false;
        }

        /**
         * Checks if Google Ads network is enabled.
         * @returns {boolean}
         */
        function isGoogleNetworkEnabled() {
            return (vm.campaignForm.networks.googleDisplay || vm.campaignForm.networks.youtube);
        }

        /**
         * Checks if Facebook network is enabled.
         * @returns {boolean}
         */
        function isFacebookNetworkEnabled() {
            return (vm.campaignForm.networks.facebook || vm.campaignForm.networks.instagram);
        }

        /**
         * Event triggered when os changed.
         *
         * @param newVal
         * @param oldVal
         */
        function onOperatingSystemChange(newVal, oldVal) {
            vm.parent.checkOperatingSystems();
            if (vm.parent.isAnyNonNullOperatingSystemSelected()) {
                vm.campaignForm.devices.desktop = false;
            }
        }

        function onSelectedDevicesDesktopChange(newVal, oldVal) {
            if (!newVal && !vm.campaignForm.devices.mobile) {
                vm.campaignForm.networks.youtube = false;
                vm.campaignForm.networks.facebook = false;
            }

            if (newVal) {
                vm.parent.selectAllOperatingSystems();
            }
        }

        function onSelectedDevicesMobileChange(newVal, oldVal) {
            if (!newVal) {
                vm.campaignForm.networks.instagram = false;
                if (!vm.campaignForm.devices.desktop) {
                    vm.campaignForm.networks.youtube = false;
                    vm.campaignForm.networks.facebook = false;
                }
            }

            if (newVal && newVal !== oldVal) {
                vm.parent.selectAllOperatingSystems();
            }
        }

        /**
         * Checks is invalid scheduled dates for facebook network. Must be 24 hours at least.
         */
        function isValidFacebookDates() {
            var isAnyNetworkSelected = vm.campaignForm.networks.facebook || vm.campaignForm.networks.instagram;
            var isAtLeast24Hours = vm.parent.isDatesDiffAtLeast24Hours(
                vm.campaignForm.endDate,
                vm.campaignForm.startDate
            );
            var isValidDates = true;

            if (isAnyNetworkSelected && false === isAtLeast24Hours) {
                isValidDates = false;
            }

            return isValidDates;
        }

        function isValidFacebookMaxStartDate() {
            var isAnyNetworkSelected = vm.campaignForm.networks.facebook || vm.campaignForm.networks.instagram;
            var isLessThan180Days = vm.parent.ensureDateLessThan180Days(vm.campaignForm.startDate);

            var isValidStartDate = true;

            if (isAnyNetworkSelected && false === isLessThan180Days) {
                isValidStartDate = false;
            }

            return isValidStartDate;

        }

        // Validation

        function validate() {
            $scope.devicesMissingError = true;
            $scope.networksMissingError = true;
            $scope.customerAccountError = true;
            $scope.networkDatesError = true;
            $scope.networkStartDateError = true;
            $scope.compatibleNetworksError = true;
            $scope.invalidEndDate = true;
            $scope.grossBudgetError = true;
             
            if (vm.campaignForm.devices.desktop === true || vm.campaignForm.devices.mobile === true) {
                $scope.devicesMissingError = false;
            }

            if (vm.wizardUtils.checkAnyNetworkSelected()) {
                $scope.networksMissingError = false;
            }

            if (vm.wizardData.customerAccount && vm.wizardData.customerAccount.currency) {
                $scope.customerAccountError = false;
            }

            if (vm.isValidNetworkDates) {
                $scope.networkDatesError = false;
            }

            if (vm.isValidNetworkMaxStartDate) {
                $scope.networkStartDateError = false;
            }

            if (vm.wizardUtils.isDisplayCompatibleNetworks()) {
                $scope.compatibleNetworksError = false;
            }

            if(vm.wizardData.isDailyBudgetValid(vm.campaignForm.grossBudget, vm.campaignForm.startDate, vm.campaignForm.endDate)){
                $scope.grossBudgetError = false
            }
            

            if (tvlForm.validate($scope['wizardInformation'])
                && !$scope.devicesMissingError
                && !$scope.networksMissingError
                && !$scope.customerAccountError
                && !$scope.networkDatesError
                && !$scope.networkStartDateError
                && !$scope.compatibleNetworksError
                && !$scope.grossBudgetError
            ) {
                return true;
            }

            return false;
        }

        $scope.$on('$destroy', function () {
            for (var i in vm.unregisterFns) {
                vm.unregisterFns[i]();
            }
        });
    }
})();
