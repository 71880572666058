(function() {
    'use strict';

    angular
        .module('tvl.administration')
        .directive('accountsForm', AccountsForm);

    AccountsForm.$inject = [
        '$state',
        '$uibModal',
        '$window',
        'toastr',
        'Upload',
        'Account',
        'Company',
        'tvlSession',
        'tvlCurrencies',
        'resourceResponse'
    ];

    /** @ngInject */
    function AccountsForm(
        $state,
        $uibModal,
        $window,
        toastr,
        Upload,
        Account,
        Company,
        tvlSession,
        tvlCurrencies,
        resourceResponse
    ) {
        return {
            restrict: "E",
            scope: {
                account: '=',
                onSaveSuccess: '&',
                onSaveError: '&',
                onSaving: '&',
                onFormValid: '&',
                onFormInvalid: '&'
            },
            templateUrl: "accounts/accounts-form.html",
            link: function ($scope, element, attrs) {
                var account = $scope.account;

                $scope.loggedUser = null;
                $scope.ui = {
                    showImportCustomAudiences: false,
                };

                tvlSession.getSession().then(function(user) {
                    $scope.loggedUser = user;
                    $scope.ui.showImportCustomAudiences = $scope.loggedUser.hasPermission('Account.update_custom_interests');
                    init();
                });

                $scope.companies = [];

                $scope.isNew = _.isNil(account);
                const isRebate = _.get(account, 'campaignRebate', null) === 0;
                var markup = _.get(account, 'markup', null);
                markup = (markup) ? _.round(((markup - 1) * 100), 2) : null;

                $scope.accountModel = {
                    id: _.get(account, 'id', null),
                    enabled: _.get(account, 'enabled', false),
                    name: _.get(account, 'name', ''),
                    currency: _.get(account, 'currency', ''),
                    campaignModality: isRebate ? 'rebate' : 'fee',
                    company: _.get(account, 'company', null),
                    campaignFee: _.round(_.get(account, 'campaignFee', 0) * 100, 2),
                    campaignRebate: _.get(account, 'campaignRebate', 0),
                    adwordsNetworkAccounts: _.get(account, 'adwordsConfiguration', null),
                    facebookNetworkAccounts: _.get(account, 'facebookConfiguration', null),
                    tags: _.get(account, 'tags', []),
                    logo: _.get(account, 'customization.logos.platform.positive', null),
                    logoNegative: _.get(account, 'customization.logos.platform.negative', null),
                    markup: markup,
                };

                $scope.availableTags = [];
                $scope.adwordsNetworkAccounts = null;
                $scope.facebookNetworkAccounts = null;
                $scope.showNegativeThumbnail = false;
                $scope.textNegativeThumbnail = 'Show black background thumbnail';
                $scope.saveAccount = saveAccount;
                $scope.filterAdwordsNetworkAccounts = filterAdwordsNetworkAccounts;
                $scope.filterFacebookNetworkAccounts = filterFacebookNetworkAccounts;
                $scope.refreshCompanyList = refreshCompanyList;
                $scope.onLogoChange = onLogoChange;
                $scope.onSelectedCompany = onSelectedCompany;
                $scope.onLogoNegativeChange = onLogoNegativeChange;
                $scope.currencies = tvlCurrencies.getCurrencies();
                $scope.campaignModalityChange = campaignModalityChange;
                $scope.networkAccountRequired = networkAccountRequired;
                $scope.importCustomInterests = importCustomInterests;
                $scope.currencyOptions = _.map($scope.currencies, function(currency) { return currency; });
                $scope.availableCampaignModalities = [
                    'fee',
                    'rebate'
                ];

                function init() {
                    refreshCompanyList();
                    getTags();
                    initMarkup();
                }

                function onSelectedCompany(selectedItem) {
                    clearNetworkAccounts();
                    getNetworkAccounts(selectedItem.id);
                }

                function clearNetworkAccounts() {
                    $scope.accountModel.adwordsNetworkAccounts = null;
                    $scope.accountModel.facebookNetworkAccounts = null;
                }

                function getNetworkAccounts(id) {
                    return Company.getNetworkAccounts({id: id}).$promise
                            .then(onGetNetworkAccountsSuccess, resourceResponse.error);

                    function onGetNetworkAccountsSuccess(data) {
                        $scope.adwordsNetworkAccounts = data.items.filter(function(network) {
                            return network.network.toLowerCase() === 'adwords'
                        });

                        $scope.facebookNetworkAccounts = data.items.filter(function(network) {
                            return network.network.toLowerCase() === 'facebook'
                        });
                    }
                }

                function refreshCompanyList(search) {
                    var params = {
                        term: search || null
                    };
                    return Company.get(params).$promise
                        .then(onGetCompaniesSuccess, resourceResponse.error);

                    function onGetCompaniesSuccess(companies) {
                        $scope.companies = companies.items;
                    }
                }

                function filterAdwordsNetworkAccounts(search) {
                        if ($scope.adwordsNetworkAccounts !== null) {
                        var newAdwordsNetworkAccounts = $scope.adwordsNetworkAccounts.slice();
                        if (search === '') {
                            return $scope.adwordsNetworkAccounts.slice();
                        }
                        else {
                            return newAdwordsNetworkAccounts.filter(function(network) {
                                var toSearchFieldsConcatenated = network.name + network.accountId;

                                return toSearchFieldsConcatenated.toLowerCase().indexOf(search.toLowerCase()) !== -1
                            });
                        }
                    }
                }

                function filterFacebookNetworkAccounts(search) {
                    if ($scope.facebookNetworkAccounts !== null) {
                        var newFacebookNetworkAccounts = $scope.facebookNetworkAccounts.slice();
                        if (search === '') {
                            return $scope.facebookNetworkAccounts.slice();
                        }
                        else {
                            return newFacebookNetworkAccounts.filter(function(network) {
                                var toSearchFieldsConcatenated = network.name + network.accountId;

                                return toSearchFieldsConcatenated.toLowerCase().indexOf(search.toLowerCase()) !== -1
                            });
                        }
                    }
                }

                function getTags()
                {
                    Account.tags().$promise.then(function(response) {
                        var data = response.data;
                        $scope.availableTags = data;
                    }).catch(resourceResponse.error);
                }

                function onLogoChange($files, $file) {
                    if ($file === null) {
                       $scope.accountModel.logo = null;
                    } else {
                        Upload.base64DataUrl($files).then(onUploadFileSuccess);
                    }

                    function onUploadFileSuccess(urls) {
                        $scope.accountModel.logo = urls[0];
                    }
                }

                function onLogoNegativeChange($files, $file) {
                    if ($file === null) {
                        $scope.accountModel.logoNegative = null;
                    } else {
                        Upload.base64DataUrl($files).then(onUploadFileSuccess);
                    }

                    function onUploadFileSuccess(urls) {
                        $scope.accountModel.logoNegative = urls[0];
                    }
                }

                function campaignModalityChange() {
                    if ($scope.accountModel.campaignModality === 'rebate') {
                        $scope.accountModel.campaignRebate = 0;
                        $scope.accountModel.campaignFee = null;
                    } else {
                        $scope.accountModel.campaignRebate = null;
                    }
                }

                function networkAccountRequired() {
                    if (_.isNil($scope.accountModel.adwordsNetworkAccounts) && _.isNil($scope.accountModel.facebookNetworkAccounts)) {
                       return true;
                    }

                    return false;
                }

                function importCustomInterests() {
                    var params = {
                        'id': $scope.accountModel.id,
                    };
                    Account.updateCustomInterest(params).$promise.then(function(response) {
                        var interestsNumber = response.interestsUpdated;
                        toastr.success(interestsNumber + ' custom interests imported successfully!');
                    }, function(errorResponse) {
                        var message = 'Error importing custom interests';
                        if (errorResponse.data.errors.length) {
                            message += ': ' + errorResponse.data.errors[0].message;
                        }
                        toastr.error(message);
                    });
                }

                $scope.$on("AccountsForm.Save", function(evt, data){
                    $scope.saveAccount();
                });

                function saveAccount() {
                    $scope.onSaving();

                    const isRebate = _.get($scope.accountModel, 'campaignModality') === 'rebate';

                    var data = {
                        id: _.get($scope.accountModel, 'id', null),
                        company: _.get($scope.accountModel, 'company.id', null),
                        customization: {
                            logos: {
                                platform: {
                                    positive: _.get($scope.accountModel, 'logo', ''),
                                    negative: _.get($scope.accountModel, 'logoNegative', ''),
                                }
                            }
                        },
                        enabled: _.get($scope.accountModel, 'enabled', false),
                        name: _.get($scope.accountModel, 'name', ''),
                        currency: _.get($scope.accountModel, 'currency', ''),
                        tags: _.get($scope.accountModel, 'tags', []),
                        networkAccounts: [],
                    };

                    // Adds to request the fee or rebate model fields.
                    if (false === isRebate) {
                        data.campaignFee = _.get($scope.accountModel, 'campaignFee', 0);
                        data.campaignFee = data.campaignFee / 100; // % value must be sent as per one value
                    } else {
                        data.campaignRebate = 0;
                    }

                    // Adds to request the network accounts.
                    if (false === _.isNil($scope.accountModel.adwordsNetworkAccounts)) {
                        data.networkAccounts.push($scope.accountModel.adwordsNetworkAccounts);
                    }
                    if (false === _.isNil($scope.accountModel.facebookNetworkAccounts)) {
                        data.networkAccounts.push($scope.accountModel.facebookNetworkAccounts);
                    }

                    if ($scope.userCanUpdateMarkup) {
                        var markup = _.get($scope.accountModel, 'markup', null);
                        data.markup = 1 + (markup / 100);
                    }

                    var account = new Account(data);

                    return account.$save()
                        .then(onSaveSuccess, onSaveError);

                    function onSaveSuccess(account) {
                        toastr.success('Account ' + account.name + ' has been successfully saved.');
                        $scope.onSaveSuccess({account: account});
                    }

                    function onSaveError(error) {
                        resourceResponse.error(error);
                        $scope.onSaveError({error: error});
                    }
                }

                $scope.$watchGroup(['accountForm.$dirty', 'accountForm.$valid'], function () {
                    if ($scope.accountForm.$valid && $scope.accountForm.$dirty) {
                        $scope.onFormValid();
                    } else {
                        $scope.onFormInvalid();
                    }
                }, true);

                function initMarkup() {
                    $scope.userCanViewMarkup = $scope.loggedUser.hasPermission('Account.view_markup');
                    $scope.userCanUpdateMarkup = $scope.loggedUser.hasPermission('Account.update_markup');
                }
            }
        };
    }
}());
