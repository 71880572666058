(function() {
    'use strict';

    angular
        .module('tvl.common')
        .factory('tvlWeather', tvlWeather);

    tvlWeather.$inject = ['tvlSession', '$http'];

    /**
     * A convenience service which connects with weather API and gets data.
     *
     * @ngInject
     */
    function tvlWeather(tvlSession, $http) {
        var service = {
            getHistoricalWeather: getHistoricalWeather
        };
        return service;

        ////////////////

        /**
         * Return the historical weather data for a location (lat, long) for the given dates
         *
         * @param {String} lat
         * @param {String} long
         * @param {String} dateFrom
         * @param {String} dateTo
         * @return {Object}
         */
        function getHistoricalWeather(lat, long, dateFrom, dateTo) {
            return tvlSession.getApiKeyFromSessionStorage('WEATHER_API_URL').then(function (weatherApiUrl) {
                return tvlSession.getApiKeyFromSessionStorage('WEATHER_API_KEY').then(function (weatherApiKey) {
                    return $http({
                        method: 'GET',
                        url: weatherApiUrl + lat + ',' + long + '/historical_data?dateFrom=' + dateFrom + '&dateTo=' + dateTo + '&accessToken=' + weatherApiKey
                    }).then(function successCallback(response) {
                        return response.data;
                    });
                });
            });

            // var response = [];
            // Mock response until real service implementation
            // var initDate = moment('09/07/2017', 'DD/MM/YYYY', true);
            // var conditions = ['cloudy', 'foggy', 'clear', 'rain', 'snow'];
            // for (var i = 1; i <= 10; i++) {
            //     var date = initDate.clone().add(i, "days").format('Y-MM-DD');
            //     var coordinates = {"lng" : 2.3522, "lat" : 48.8566};

            //     var report = {
            //         "date" : date,
            //         "coordinates" : coordinates,
            //         "maxTemp" : 25 + i,
            //         "minTemp" : 16 - i,
            //         "totalPrecipitation" : 225 + i,
            //         "maxWindSpeed" : 31 + i,
            //         "condition" : conditions[Math.floor(Math.random()*conditions.length)]
            //     };

            //     response.push(report);
            // }

            // return response;
        }
    }
})();
