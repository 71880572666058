(function() {
    'use strict';

    angular
        .module('tvl.campaign')
        .service('tvlKeywordsSelectorModal', tvlKeywordsSelectorModal);

    tvlKeywordsSelectorModal.$inject = ['$uibModal'];

    /**
     * A service to interact with the content selector modal.
     *
     * @ngInject
     */
    function tvlKeywordsSelectorModal($uibModal) {
        return {
            openSelectorModal: openSelectorModal
        };

        /**
         * Open the keywords list selector modal.
         *
         * This function returns a promise that will be resolved with the items
         * selected in the modal.
         *
         * An optional array of items can be passed to the modal, so that it is
         * aware of previous selections.
         *
         * @param {Array} currentSelection
         * @return {Promise}
         */
        function openSelectorModal(currentSelection) {
            var modal = $uibModal.open({
                size: 'lg',
                templateUrl: 'campaign/keywords-selector-modal.html',
                controller: 'KeywordsSelectorModalController as vm',
                bindToController: true,
                resolve: {
                    /** @ngInject */
                    lists: function (KeywordsList) {
                        return KeywordsList.get({items_per_page: 10}).$promise;
                    },
                    currentSelection: function () {
                        return currentSelection;
                    }
                }
            });

            return modal.result;
        }
    }
}());
