(function() {
    'use strict';

    angular
        .module('tvl.brandSafety')
        .controller('BrandSafetyListsController', BrandSafetyListsController);

    BrandSafetyListsController.$inject = [
        'tvlRemoveListModal',
        'tvlCreateListModal',
        '$scope',
        '$window',
        'toastr',
        'Upload',
        'ContentsList',
        'Interest',
        'Location',
        'ChannelAnalysis',
        'NgTableParams',
        'lists',
        'languages',
        '$stateParams',
        '$state',
        'loggedUser',
        'jwt'
    ];

    /* @ngInject */
    function BrandSafetyListsController(
        tvlRemoveListModal,
        tvlCreateListModal,
        $scope,
        $window,
        toastr,
        Upload,
        ContentsList,
        Interest,
        Location,
        ChannelAnalysis,
        NgTableParams,
        lists,
        languages,
        $stateParams,
        $state,
        loggedUser,
        jwt
    ) {
        var vm = this;
        vm.loggedUser = loggedUser;
        vm.lists = lists;
        vm.currentPage = $stateParams.page || 1;
        vm.selectedList = null;
        vm.editForm = {};
        vm.searchTerm = null;
        vm.token = jwt.getTokenFromSessionStorage();

        vm.ui = {
            gettingDetails: false,
            saving: false,
            selectedChannelId: null,
            updating: false
        };

        vm.availableAges = [
            '18-24',
            '25-34',
            '35-44',
            '45-54',
            '55-64',
            '65+'
        ];
        vm.availableGenders = {
            male: 'Male',
            female: 'Female'
        };
        vm.availableInterests = [];
        vm.availableLanguages = {};
        vm.availableLocations = [];
        vm.results = [];
        vm.lastSearchedLocation = null;

        vm.BrandSafetyChannelPermissions = {
            view: null,
        };

        vm.itemsAnalyzed = {};

        activate();

        vm.clearSelection = clearSelection;
        vm.removeList = removeList;
        vm.saveList = saveList;
        vm.selectList = selectList;
        vm.createList = createList;
        vm.searchInterests = searchInterests;
        vm.searchLocations = searchLocations;
        vm.showChannelDetails = showChannelDetails;
        vm.onFileChange = onFileChange;
        vm.searchLists = searchList;
        vm.modifyFromChannelsSearch = modifyFromChannelsSearch;


        ////////////////

        function activate() {
            setUpLanguages(languages);
            $scope.unregisterFns = [
                $scope.$watch('vm.lists.currentPage', onCurrentPageChange),
            ];
            $scope.$on('$destroy', onDestroy);
            checkUserPermissions();

            if ($stateParams.listId) {
                selectList({
                    id: $stateParams.listId,
                });
            } else {
                updateQueryParams();
            }
        }

        /**
         * Map all available languages so that an array of languages such as
         * this:
         *
         * [{"code": "es", "name": "Spanish", ...}, ...]
         *
         * Becomes an object keyed by the languages' codes:
         *
         * {"es": {"name": "Spanish", ...}, ...}
         */
        function setUpLanguages(languages) {
            vm.availableLanguages = _.chain(languages)
                .map(function(i) {
                    return [i.code, i];
                })
                .fromPairs()
                .value();
        }

        /**
         * Populate query params with the current page on the list .
         */
        function updateQueryParams() {
            $state.transitionTo(
                'brand-safety.contents-lists', {
                    page: vm.currentPage
                }, {
                    notify: false
                }
            );
        }

        function checkUserPermissions () {
            var userCanViewBrandSafetyChannel = vm.loggedUser.hasPermission('BrandSafetyChannel.view');

            if ( true === userCanViewBrandSafetyChannel) {
                vm.BrandSafetyChannelPermissions.view = true;
            }
        }

        function getInterestName(interestId) {
            var name = '';
            angular.forEach(vm.availableInterests, function(value) {
                var compare = 'uservertical::' + value.networkId;

                if (compare === interestId) {
                    name = value.name;
                }
            });
            return name;
        }

        function clearSelection() {
            vm.selectedList = null;
            vm.editForm = {};
        }

        function getList(id) {
            return ContentsList.get({
                id: id
            }).$promise;
        }

        function onGetListsSuccess(lists) {
            vm.lists = lists;
        }

        function onGetListsError(error) {
            toastr.error('Our apologies, we have been unable to retrieve your contents lists. Please, try again later.');
        }

        function getLists(page, search) {
            page = (page < 1) ? 1 : page;
            var params = {
                page: page,
                searchTerm: search || null
            };
            vm.currentPage = page;
            updateQueryParams();
            return ContentsList.get(params)
                .$promise
                .then(onGetListsSuccess, onGetListsError);
        }

        function onGetListSuccess(list) {
            $window.scrollTo(0, 0);
            vm.detailedItems = list.items;
            vm.selectedList = list;
            submitForm(1);

            vm.detailsFormTitle = 'Edit ' + list.name;
            vm.editForm = {
                id: list.id,
                name: list.name,
                items: list.items,
                filters: list.filters,
                file: null,
            };
            if (list.filters) {
                var interests = [];
                angular.forEach(vm.editForm.filters.interests, function(value, key) {
                    interests.push({
                        'networkId': value,
                        'name': getInterestName(value)
                    });
                });
                delete vm.editForm.filters.interests;
                vm.editForm.filters.interests = interests;
            }
            return list;
        }

        function onGetListFinally() {
            vm.ui.gettingDetails = false;
        }

        function selectList(list) {
            if (vm.selectedList && vm.selectedList.id === list.id) {
                return;
            }

            vm.ui.gettingDetails = true;
            return getList(list.id)
                .then(onGetListSuccess)
                .finally(onGetListFinally);
        }

        function saveList() {
            vm.ui.saving = true;

            var list = new ContentsList(vm.editForm);
            delete list.items;

            if (list.filters) {
                list.languagesDetected = list.filters.languagesDetected;
                list.likesPercentGt = list.filters.likesPercentGt;
                list.likesPercentLt = list.filters.likesPercentLt;
                list.subscriberCountGt = list.filters.subscriberCountGt;
                list.subscriberCountLt = list.filters.subscriberCountLt;
                list.viewRateGt = list.filters.viewRateGt;
                list.viewRateLt = list.filters.viewRateLt;
                list.ages = list.filters.ages;
                list.genders = list.filters.genders;
                list.locations = list.filters.locations;
                list.userTags = list.filters.userTags;
                var interests = [];
                angular.forEach(list.filters.interests, function(value, key) {
                    if (value.networkId) {
                        interests.push(value.networkId);
                    } else {
                        if (value.substr(0, 12) === 'uservertical') {
                            interests.push(value);
                        } else {
                            interests.push('uservertical::' + value);
                        }
                    }
                });
                list.interests = interests;
                delete list.filters;
            }

            return list.$save()
                .then(onSaveSuccess, onSaveError)
                .then(onSaveFinally);

            function onSaveSuccess(list) {
                toastr.success('List ' + list.name + ' has been successfully saved.');
                clearSelection();
                getLists(vm.lists.currentPage);
            }

            function onSaveError(error) {
                toastr.error('Our apologies, we have been unable to save this list. Please, try again later.');
            }

            function onSaveFinally() {
                vm.ui.saving = false;
            }
        }

        function showStaticListCreationModal() {
            return tvlCreateListModal.openCreationModal(ContentsList, 'list');
        }

        /**
         * Show the static list creation modal and redirect the user to the lists view
         * upon success.
         */
        function createList() {
            return showStaticListCreationModal().then(onSaveListSuccess);
        }

        function onSaveListSuccess(list) {
            toastr.success('List ' + list.name + ' has been successfully created.');
            onGetListSuccess(list);
            getLists();
        }

        function showRemovalConfirmationModal(list) {
            return tvlRemoveListModal.openRemoveModal(list, 'list')
        }

        function deleteList(list) {
            return ContentsList.delete({
                id: list.id
            }).$promise;
        }

        function removeList() {
            var list = vm.editForm;

            return showRemovalConfirmationModal(list)
                .then(
                    function() {
                        deleteList(list)
                            .then(onDeleteSuccess, onDeleteError)
                            .finally(getLists.bind(null, vm.currentPage));
                    },
                    cancelRemove
                );

            function onDeleteSuccess() {
                clearSelection();
                toastr.success('List ' + list.name + ' has been successfully removed.');
            }

            function onDeleteError(error) {
                switch (error.status) {
                    case 400:
                        var message = 'You are not authorized to remove this list. This may happen if this list has been used in at least one campaign.';
                        toastr.error(message);
                        break;
                    case 403:
                        toastr.error('You are not authorized to complete that action.');
                        break;
                    default:
                        toastr.error('Our apologies, we have been unable to remove this list. Please, try again later.');
                }
            }

            function cancelRemove() {

            }
        }

        function onCurrentPageChange(newVal, oldVal) {
            if (newVal !== oldVal) {
                vm.currentPage = newVal;
                getLists(newVal, vm.searchTerm);
            }
        }

        /**
         * Callback function invoked before this controller is destroyed.
         */
        function onDestroy() {
            angular.forEach($scope.unregisterFns, function(fn) {
                fn();
            });
        }

        function searchList(search) {
            return getLists(1, search);
        }

        function onSearchInterestsSuccess(data) {
            vm.availableInterests = data.items;
        }

        /**
         * Search for interests matching the given name.
         *
         * Currently only searchs for AdWords interests as the brand safety
         * report only targets this network for the time being.
         *
         * @param {String} name
         * @return {Promise}
         */
        function searchInterests(name) {
            return Interest.get({
                    searchTerm: name,
                    network: 'adwords'
                })
                .$promise
                .then(onSearchInterestsSuccess);
        }

        function onSearchLocationsSuccess(data) {
            vm.availableLocations = data.items;
        }

        /**
         * Search for locations matching the given name, just countries
         *
         * @param {String} name
         * @return {Promise}
         */
        function searchLocations(name) {
            if (name !== vm.lastSearchedLocation) {
                vm.lastSearchedLocation = name;
                return Location.get({
                    name: name,
                    'types[]' : 'country'
                }).$promise.then(onSearchLocationsSuccess);
            }
        }


        function onGetPlacementsSuccess(data) {
            vm.results = data;
            vm.itemsAnalyzed = {
                'list': vm.selectedList.items.length,
                'analyzed': data.items.length,
            };
            var enrichedChannels = [];

            vm.results.items.forEach(function(entry) {
                enrichedChannels.push(entry.channel_id)
            });

            vm.selectedList.items.forEach(function(filteredChannel) {
                if (!enrichedChannels.includes(filteredChannel)) {
                    vm.results.items.push({
                        'channel_id' : filteredChannel,
                        'no_tracked': true,
                        'channelPerformance': {
                            'impressions': 0,
                            'views': 0,
                            'clicks': 0,
                            'cost': 0,
                            'cpm': 0,
                            'cpv': 0,
                            'vtr': 0
                        }
                    });
                }
            });


            vm.tableParams = new NgTableParams(
                {
                    count: 50,  // initial page size of 50 items
                },
                {
                    counts: [10, 25, 50, 100],
                    dataset: vm.results.items,
                }
            );


            return data;
        }

        function onGetPlacementsError(error) {
            toastr.error('Our apologies, we have been unable to get brand safety data. Please, try again later.');
        }

        function onGetPlacementsFinally() {
            vm.ui.updating = false;
        }

        /**
         * Submit the search form, optionally specifying the page to retrieve
         * from the result set.
         *
         * @param {Number} page
         * @return {Promise}
         */
        function submitForm(page) {
            vm.ui.updating = true;
            vm.itemsAnalyzed = null;

            extractChannelIdsFromItems(vm.selectedList.items);

            return ChannelAnalysis.getChannelsInfo({'channelId': vm.selectedList.items}).$promise
                .then(onGetPlacementsSuccess)
                .catch(onGetPlacementsError)
                .finally(onGetPlacementsFinally);
        }

        /**
         * Convert the current page of channel's URLs into an object that can be used to invoke
         * the Brand Safety API.
         *
         * @param {array} channelURLs
         *
         * @return {Object}
         */
        function extractChannelIdsFromItems(channelURLs) {
            var filters = channelURLs.map(function (item) {
                return item.substr(-24);
            });
            vm.selectedList.items = filters;
        }

        /**
         * Toggle the display of a placement detail row.
         *
         * @param {String} channelId
         */
        function showChannelDetails(channelId) {
            vm.ui.selectedChannelId = (channelId === vm.ui.selectedChannelId) ? null : channelId;
        }

        function onFileChange($files, $file) {
            if ($file === null) {
                vm.editForm.file = null;
            } else {
                Upload.base64DataUrl($files).then(onUploadFileSuccess);
            }

            function onUploadFileSuccess(urls) {
                vm.editForm.file = urls[0];
            }
        }

        function modifyFromChannelsSearch() {
            var params = {
                listId: vm.selectedList.id,
            };

            $state.go('brand-safety.country-lists', params);
        }

    }
}());
