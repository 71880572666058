(function() {
    'use strict';

    angular
        .module('tvl.group')
        .controller('GroupShowController', GroupShowController);

    GroupShowController.$inject = [
        '$scope',
        'group',
        '$state'
    ];

    /* @ngInject */
    function GroupShowController(
        $scope,
        group,
        $state
    ) {
        $scope.group = group.data;
        $scope.isGroup = isGroup;
        $scope.backToList = backToList;

        function isGroup(item) {
            return false === _.isEmpty(item.campaigns);
        }

        function backToList() {
            $state.go('group.list');
        }
    }
})();
