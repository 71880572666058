(function() {
    'use strict';

    angular
        .module('tvl.brandSafety')
        .service('tvlCreateListModal', tvlCreateListModal);

    tvlCreateListModal.$inject = ['$uibModal'];

    /**
     * A service to interact with the list creation modal.
     *
     * @ngInject
     */
    function tvlCreateListModal($uibModal) {
        return {
            openCreationModal: openCreationModal
        };

        /**
         * Open the list creation modal.
         *
         * This function returns a promise that will be resolved with the list to be created
         *
         * This function also expects three parameters:
         *  - The entity to be created.
         *  - The tipe of list (Keywords, ContentLists aka list)
         *  - list items can be passed to modal from third service, these items will be used on list creation (optional)
         *
         * @param entity
         * @param tag
         * @param items list items can be passed to modal from third service, these items will be used on list creation
         * @return {Promise}
         */
        function openCreationModal(entity, tag, items) {
            var modal = $uibModal.open({
                templateUrl: 'brand-safety/create-list-modal.html',
                controller: 'CreateListModalController as vm',
                bindToController: true,
                backdrop: 'static',
                resolve: {
                    entity: function () {
                        return entity;
                    },
                    tag: function () {
                        return tag;
                    },
                    items: function () {
                        return items;
                    }
                }
            });

            return modal.result;
        }
    }
}());
