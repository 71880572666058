(function() {
    'use strict';

    angular
        .module('tvl.common')
        .directive('tvlInitForm', tvlInitForm);

    /** @ngInject */
    function tvlInitForm() {
        return {
            link: postLink,
            require: 'form',
            scope: {
                formUpdate: '@', //Bool Form is update
                formField: '@',  //string
            },
        };
    }

    /**
     * This directive is aimed to mark as $dirty form fields for dynamic forms created when
     * ngModel changes. NgFormController does not mark as touched changes in model by default.
     * @param scope
     * @param elem
     * @param attrs
     * @param form
     */
    function postLink(scope,elem,attrs,form) {

        if (scope.formUpdate && true === scope.formUpdate) {
            return;
        }
        //This directive runs at form creation, so model is not yet initiated (angular instantiates model as Number.NaN).
        // We must wait. If not we mark field as dirty always, not only when model changes.

        setTimeout(function() {
            //To avoid Firefox weird behavior with timeouts we need to add another timeout
            if (form[scope.formField].$viewValue && !form[scope.formField].$modelValue) {
                setTimeout(function() {
                    if (form[scope.formField].$modelValue) {
                        form[scope.formField].$setDirty();
                    }
                }, 0);
            }

            if (form[scope.formField].$modelValue) {
                form[scope.formField].$setDirty();
            }

        }, 0);
    }

})();
