(function() {
    'use strict';

    angular
        .module('tvl.control-center')
        .controller('ControlCenterIndexController', ControlCenterIndexController);

    ControlCenterIndexController.$inject = [
        '$scope'
    ];

    /* @ngInject */
    function ControlCenterIndexController(
        $scope
    ) {
        activate();

        ////////////////

        function activate() {
        }
    }
})();
