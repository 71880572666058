(function() {
    'use strict';

    angular
        .module('tvl.administration')
        .controller('MaintenanceModeController', MaintenanceModeController);

    MaintenanceModeController.$inject = ['$scope', 'MaintenanceMode', 'toastr'];

    /* @ngInject */
    function MaintenanceModeController($scope, MaintenanceMode, toastr) {
        var vm = this;
        vm.detailsFormTitle = null;
        vm.maintenanceForm = {
            message: null,
            enabled: false,
        };
        vm.save = save;

        vm.ui = {
            saving: false,
        };

        init();

        function init() {
            getMaintenanceModeInfo();
        }

        function save() {
            vm.ui.saving = true;
            console.log(vm.maintenanceForm);

            return MaintenanceMode.updateMaintenanceInfo(vm.maintenanceForm).$promise
                .then(onSaveSuccess, onSaveError)
                .then(onSaveFinally);

            function onSaveSuccess(maintenanceMode) {
                toastr.success('Maintenance mode info has been successfully updated.');
            }

            function onSaveError(error) {
                toastr.error('Our apologies, we have been unable to update maintenance mode info. Please, try again later.');
            }

            function onSaveFinally() {
                vm.ui.saving = false;
            }
        }

        function getMaintenanceModeInfo() {

            return MaintenanceMode.getMaintenanceInfo().$promise
                .then(onGetMaintenanceInfoSuccess, onGetMaintenanceInfoError);

            function onGetMaintenanceInfoSuccess(data) {
                console.log('data', data);
                vm.maintenanceForm.message = data.message;
                vm.maintenanceForm.enabled = data.enabled;
            }

            function onGetMaintenanceInfoError(error) {
                toastr.error('Our apologies, we have been unable to recover any maintenance info. Please, try again later.');
            }
        }
    }

}());
