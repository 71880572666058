(function() {
    'use strict';

    angular
        .module('tvl.campaign')
        .controller('KeywordsSelectorModalController', KeywordsSelectorModalController);

    KeywordsSelectorModalController.$inject = [
        '$scope',
        '$q',
        '$uibModalInstance',
        'toastr',
        'KeywordsList',
        'lists',
        'currentSelection'
    ];

    /* @ngInject */
    function KeywordsSelectorModalController(
        $scope,
        $q,
        $uibModalInstance,
        toastr,
        KeywordsList,
        lists,
        currentSelection
    ) {
        var vm = this;

        vm.ADWORDS_MAX_ALLOWED_KEYWORDS = 10000;

        vm.alreadySelected = {lists: []};
        vm.lists = lists;
        vm.selectedLists = [];
        vm.ui = {adding: false};
        vm.searchTerm = null;

        vm.addItems = addItems;
        vm.cancel = cancel;
        vm.isListSelected = isListSelected;
        vm.selectList = selectList;
        vm.removeListFromSelection = removeListFromSelection;

        activate();

        function activate() {
            processCurrentSelection(currentSelection);
            vm.unregisterFns = [
                $scope.$watch('vm.lists.currentPage', onCurrentPageChange),
                $scope.$watch('vm.searchTerm', onSearchTermChange),
            ];
            $scope.$on('$destroy', onDestroy);
        }

        function isList(content) {
            return content.id;
        }

        function processCurrentSelection(currentSelection) {
            _.forEach(currentSelection, function (item) {
                if (item.list && isList(item.list)) {
                    vm.alreadySelected.lists.push(item.list.id);
                }
            });
        }

        function getSelectedLists() {
            return vm.selectedLists;
        }

        /**
         * Resolve the modal with the selected items.
         */
        function addItems() {
            vm.ui.adding = true;
            return $q.all({

                lists: getSelectedLists()

            }).then(function (data) {
                var selectedLists = [];
                _.forEach(data.lists, function (item) {
                    selectedLists.push({list: item});
                });
                var allItems = selectedLists;
                $uibModalInstance.close(allItems);
            }).catch(function (err) {
                toastr.error('Oops! Something happened while adding your items. Please, try again later.');
                vm.ui.adding = false;
            });
        }

        /**
         * Dismiss the modal.
         */
        function cancel() {
            return $uibModalInstance.dismiss();
        }

        function isListSelected(list) {
            return _.find(vm.selectedLists, {id: list.id}) !== undefined;
        }

        function selectList(list) {
            if (!isListSelected(list) && vm.alreadySelected.lists.indexOf(list.id) < 0) {
                if (getKeywordCount(list) < vm.ADWORDS_MAX_ALLOWED_KEYWORDS) {
                    vm.selectedLists.push(list);
                }  else {
                    toastr.warning('The maximum number of keywords allowed by AdWords has been reached. Please remove some list before adding more.');
                }
            }
        }

        function removeListFromSelection(list) {
            var idx = _.findIndex(vm.selectedLists, {id: list.id});
            vm.selectedLists.splice(idx, 1);
        }

        /**
         * Callback function invoked when pagination for the lists is modified.
         */
        function onCurrentPageChange(newVal, oldVal) {
            if (newVal !== oldVal) {
                vm.lists.currentPage = newVal;
                getLists(newVal);
            }
        }

        function onGetListsSuccess(lists) {
            vm.lists = lists;
        }

        function onGetListsError(error) {
            toastr.error('Our apologies, we have been unable to retrieve your keywords lists. Please, try again later.');
        }

        function onSearchTermChange(newValue, oldValue) {
            if (newValue !== oldValue) {
                getLists(1);
            }
        }

        function getLists(page) {
            if (!page) {
                page = 1;
            }

            var params = {
                page: page,
                items_per_page: 10,
            };
            if (vm.searchTerm) {
                params.searchTerm = encodeURI(vm.searchTerm);
            }
            vm.currentPage = page;

            return KeywordsList.get(params)
                .$promise
                .then(onGetListsSuccess, onGetListsError);
        }

        function getKeywordCount(selectedList) {
            var count = selectedList.nItems;

            vm.alreadySelected.lists.forEach(function(listId) {
               count = count + getListKeywordCount(listId);
            });

            vm.selectedLists.forEach(function(list) {
               count = count + getListKeywordCount(list.id);
            });

            return count;
        }

        function getListKeywordCount(listId) {
            var count = 0;

            vm.lists.items.forEach(function(list) {
                if (list.id == listId) {
                    count =  list.nItems;
                }

            });

            return count;
        }

        function onDestroy() {
            for (var i in vm.unregisterFns) {
                vm.unregisterFns[i]();
            }
        }
    }
})();
