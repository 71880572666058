const moment = require('moment');

(function () {
    'use strict';

    angular
        .module('tvl.campaign')
        .controller('GoogleDisplayImageAdModalController', GoogleDisplayImageAdModalController);

    GoogleDisplayImageAdModalController.$inject = [
        '$scope',
        '$uibModalInstance',
        'toastr',
        'tvlCampaignUtils',
        'tvlAdVideoUtils',
        'tvlUrl',
        'tvlUuid',
        'tvlYoutube',
        'tvlConstantUtils',
        'FacebookBusiness',
        'UrlUtil',
        'Upload',
        'googleDisplayAdUtils',
        'adInfo',
        'update'
    ];

    /* @ngInject */
    function GoogleDisplayImageAdModalController(
        $scope,
        $uibModalInstance,
        toastr,
        tvlCampaignUtils,
        tvlAdVideoUtils,
        tvlUrl,
        tvlUuid,
        tvlYoutube,
        tvlConstantUtils,
        FacebookBusiness,
        UrlUtil,
        Upload,
        googleDisplayAdUtils,
        adInfo,
        update
    ) {
        $scope.create = create;
        $scope.updateAdVideo = updateAdVideo;
        $scope.cancel = cancel;
        $scope.onImageChange = onImageChange;
        $scope.onMoreAdsAdd = onMoreAdsAdd;
        $scope.hideMoreAdsButton = hideMoreAdsButton;
        $scope.imageSizes = [
            {width: 200, height: 200},
            {width: 240, height: 400},
            {width: 250, height: 250},
            {width: 250, height: 360},
            {width: 300, height: 250},
            {width: 336, height: 280},
            {width: 580, height: 400},
            {width: 120, height: 600},
            {width: 160, height: 600},
            {width: 300, height: 600},
            {width: 300, height: 1050},
            {width: 468, height: 60},
            {width: 728, height: 90},
            {width: 930, height: 180},
            {width: 970, height: 90},
            {width: 970, height: 250},
            {width: 980, height: 120},
            {width: 300, height: 50},
            {width: 320, height: 50},
            {width: 320, height: 100},
        ];

        $scope.update = update;
        $scope.maxFileSize = 150000; //KB
        $scope.validImageTypes = ['image/jpeg', 'image/png', 'image/gif']; //KB
        $scope.biddingTypeOptions = googleDisplayAdUtils.getBiddingTypesOptions();
        $scope.uploadedImage = null;
        $scope.activeTab = 0;
        $scope.pristineTabIndex = 0; //todo this should be a flag
        $scope.removeTabSentence = 'Please, close this tab to continue.';

        $scope.googleDisplayImageAdModelTemplate = {
            displayUrl: '',
            finalUrl: '',
            trackingUrl: '',
            imageName: '',
            image: '',
            biddingType: '',
            imageContent: null,
            hideImage: false,
            isPristineNewTab: true,
        };

        if ($scope.update) {
            var ad = tvlAdVideoUtils.buildAd(adInfo);
            ad.biddingType = googleDisplayAdUtils.getBiddingTypesObjectByType(ad.biddingType);
            $scope.googleDisplayImageAdModel = [ad];
        } else {
            var template = Object.assign({}, $scope.googleDisplayImageAdModelTemplate);
            $scope.googleDisplayImageAdModel = [template];
            $scope.pristineTabIndex = 1;
        }

        function onMoreAdsAdd() {
            if ($scope.pristineTabIndex >= 1) {
                return;
            }

            var template = Object.assign({}, $scope.googleDisplayImageAdModelTemplate);
            $scope.googleDisplayImageAdModel.push(template);
            setTimeout(function () {
                $scope.pristineTabIndex = 1;
                $scope.activeTab = $scope.googleDisplayImageAdModel.length - 1;
            }, 0);
        }

        function hideMoreAdsButton() {
            return $scope.update || $scope.pristineTabIndex == 1;
        }

        function onImageChange($files, $file) {
            if ($scope.update) {
                changeImageOnUpdate($file, $files);
            } else {
                changeImageOnCreate($file, $files);
            }
        }

        function changeImageOnUpdate($file, $files) {
            $scope.googleDisplayImageAdModel.imageContent = null;
            $scope.uploadedImage = null;

            if ($file) {
                $scope.uploadedImage = {
                    name: $file.name,
                    size: $file.size,
                    type: $file.type,
                    imageContent: null
                };
                $scope.googleDisplayImageAdModel.imageName = $file.name;
                Upload.base64DataUrl($files).then(onUploadFileUpdateSuccess);
            }

            function onUploadFileUpdateSuccess(urls) {
                $scope.googleDisplayImageAdModel.imageContent = urls[0];
                $scope.uploadedImage.imageContent = urls[0];
            }
        }

        function changeImageOnCreate($file, $files) {

            var currentModelIndex = $scope.googleDisplayImageAdModel.length - 1;

            //To avoid empty forms, and let the user pass the validation
            // we get current form values and the restart the model
            var storedDisplayUrl = $scope.googleDisplayImageAdModel[currentModelIndex] ? $scope.googleDisplayImageAdModel[currentModelIndex].displayUrl || '' : '';
            var storedFinalUrl = $scope.googleDisplayImageAdModel[currentModelIndex] ? $scope.googleDisplayImageAdModel[currentModelIndex].finalUrl || '' : '';
            var storedTrackingUrl = $scope.googleDisplayImageAdModel[currentModelIndex] ? $scope.googleDisplayImageAdModel[currentModelIndex].trackingUrl || '' : '';
            var storedBiddingType = $scope.googleDisplayImageAdModel[currentModelIndex] ? $scope.googleDisplayImageAdModel[currentModelIndex].biddingType || '' : '';

            $scope.googleDisplayImageAdModel.splice(currentModelIndex, 1);
            $scope.pristineTabIndex = 0;

            angular.forEach($files, function (value) {

                var imageAdModel = {
                    displayUrl: storedDisplayUrl,
                    finalUrl: storedFinalUrl,
                    trackingUrl: storedTrackingUrl,
                    imageName: null,
                    image: null,
                    biddingType: storedBiddingType,
                    imageContent: null,
                };

                if (value) {
                    var imageId = tvlUuid.new();
                    $scope.uploadedImage = null === $scope.uploadedImage ? {} : $scope.uploadedImage;

                    $scope.uploadedImage[imageId] = {
                        name: value.name,
                        size: value.size,
                        type: value.type,
                        imageContent: null,
                    };

                    imageAdModel.image = value;
                    imageAdModel.imageName = value.name;
                    Upload.base64DataUrl(value).then(onUploadFileSuccess);
                    imageAdModel.hideImage = true;
                    imageAdModel.imageId = imageId;
                }

                function onUploadFileSuccess(urls) {
                    imageAdModel.imageContent = urls;
                    $scope.uploadedImage[imageId].imageContent = urls;
                    onErrorImageUpload(value);

                    if ('undefined' === typeof imageAdModel.image) {
                        imageAdModel.image = value;
                    }
                }

                imageAdModel.isPristineNewTab = false;
                $scope.googleDisplayImageAdModel.push(imageAdModel);
                $scope.googleDisplayImageAdModel['errorOnImage'] = false;
            });

            setTimeout(function () {
                $scope.activeTab = $scope.googleDisplayImageAdModel.length - 1;
            }, 0);

        }

        $scope.removeTab = function (index, event) {
            event.preventDefault();
            if ($scope.googleDisplayImageAdModel.length === 1) {

                var template = Object.assign({}, $scope.googleDisplayImageAdModelTemplate);

                $scope.googleDisplayImageAdModel.length = 0;
                $scope.googleDisplayImageAdModel.push(template);
                $scope.pristineTabIndex = 1;

                setTimeout(function () {
                    $scope.googleDisplayImageAdFormParent.$setPristine();
                    $scope.activeTab = 0;
                }, 0);

            } else {
                if ($scope.googleDisplayImageAdModel[index].isPristineNewTab === true) {
                    $scope.pristineTabIndex = 0;
                }
                $scope.googleDisplayImageAdModel.splice(index, 1);

                if ($scope.activeTab === index) {
                    $scope.activeTab = index === 0 ? 0: index - 1;
                }
            }
        };

        function onErrorImageUpload(value) {
            var file = value;
            var reader = new FileReader();
            reader.onload = function(event) {
                var image = new Image();

                image.onerror = function() {
                    // Image failed to load, possibly corrupted or damaged
                    $scope.googleDisplayImageAdModel['errorOnImage'] = true;
                };

                image.src = event.target.result;
            };

            reader.onerror = function() {
                // FileReader encountered an error
               $scope.googleDisplayImageAdModel['errorOnImage'] = true;
            };

            reader.readAsDataURL(file);
        }

        function cancel() {
            return $uibModalInstance.dismiss();
        }

        function create() {
            var ads = [];

            angular.forEach($scope.googleDisplayImageAdModel, function (adModel) {
                var ad = {
                    'id': tvlUuid.new(),
                    'biddingStrategyType': adModel.biddingType.key,
                    'format': tvlAdVideoUtils.ADDISPLAY_FORMAT_ADWORDS_IMAGE,
                    'platform': tvlConstantUtils.NETWORK_YOUTUBE,//todo change to platform adwords when refactor networkToPlatform method.
                    'goal': googleDisplayAdUtils.getGoalByBiddingType(adModel.biddingType.key),
                    'imageContent': adModel.imageContent,
                    'imageName': adModel.imageName,
                    'finalUrl': adModel.finalUrl,
                    'trackingUrl': adModel.trackingUrl,
                    'displayUrl': adModel.displayUrl,
                    'network': tvlConstantUtils.NETWORK_GOOGLE_DISPLAY,
                };

                ads.push(ad);
            });

            $uibModalInstance.close(ads);
        }

        function updateAdVideo(adVideo) {
            var ad = adVideo[0];
            if (!ad.id) {
                ad.id = tvlUuid.new();
            }
            ad.biddingStrategyType = ad.biddingType.key;

            $uibModalInstance.close({adVideo: ad, accessible: true});
        }
    }
}());
