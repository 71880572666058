(function() {
    angular
        .module('tvl.common')
        .service('resourceResponse', resourceResponse);

    resourceResponse.$inject = ['toastr'];

    function resourceResponse(toastr) {
        return {
            error: error,
            getErrorFromResponse: getErrorFromResponse,
            defaultErrorMessage: defaultErrorMessage,
        };

        /**
         * @param response
         */
        function error(response) {
            var defaultMessage = defaultErrorMessage();
            switch (response.status) {
                case 400:
                case 404:
                    var message = getErrorFromResponse(response).message;
                    toastr.error(message);
                    break;
                case 401:
                    toastr.error('Invalid credentials.');
                    break;
                case 403:
                    toastr.error('You are not authorized to complete that action.');
                    break;
                default:
                    toastr.error(defaultMessage);
            }
        }

        function getErrorFromResponse(response) {
            return response.data.errors[0];
        }

        function defaultErrorMessage() {
            return 'Our apologies, we have been unable to perform the request. Please, try again later.';
        }
    }
})();
