(function() {
    'use strict';


    angular
        .module('tvl.brandSafety')
        .controller('CreateListModalController', CreateListModalController);


CreateListModalController.$inject = [
    '$uibModalInstance',
    'toastr',
    '$window',
    'Upload',
    'Account',
    'MAX_KEYWORD_LIST_SIZE',
    'MAX_CONTENT_LIST_SIZE',
    'entity',
    'tag',
    'items'
];

/** @ngInject */
function CreateListModalController(
    $uibModalInstance,
    toastr,
    $window,
    Upload,
    Account,
    MAX_KEYWORD_LIST_SIZE,
    MAX_CONTENT_LIST_SIZE,
    entity,
    tag,
    items
) {
    var vm = this;
    vm.tag = tag;
    vm.createForm = {
        name: null,
        file: null,
        items: []
    };
    vm.externalItemsNum = 0;
    vm.creating = false;
    vm.accounts = [];
    vm.create = create;
    vm.cancel = cancel;
    vm.onFileChange = onFileChange;
    vm.refreshAccountList = refreshAccountList;
    vm.csvErrors = "";

    activate();

    function activate() {
        // set different limits on lists for keywords (10k) and contents (20k)
        vm.MAX_LIST_SIZE = MAX_KEYWORD_LIST_SIZE;
        if (tag == 'list') {
            vm.MAX_LIST_SIZE = MAX_CONTENT_LIST_SIZE;
        }

        if (items) {
            vm.externalItemsNum = items.length;
        }

        // get info for logged user, need to know its role to know which roles can create
        refreshAccountList();
    }

    function onFileChange($files, $file) {
        if ($file === null) {
            vm.createForm.file = null;
        } else {
            var reader = new FileReader();

            reader.onload = function (e) {
                vm.externalItemsNum = e.target.result.split("\n").length;

                var regex = /^(https|http):\/\/(www\.)?youtube\.com\/channel\/.+/;
                e.target.result.split("\n").forEach(function (item, index) {
                    if (item) {
                        var checkItem = regex.exec(item);
                        if (checkItem === null) {
                            vm.csvErrors += vm.csvErrors ? "\n" + item : item;
                        }
                    }
                })
            };

            reader.readAsText($file);
            Upload.base64DataUrl($files).then(onUploadFileSuccess);
        }

        function onUploadFileSuccess(urls) {
            vm.createForm.file = urls[0];
        }
    }

    function create() {
        vm.creating = true;
        var list = angular.copy(vm.createForm);

        if (items) {
            list.items = items;
        }

        entity.save(list)
            .$promise
            .then(onCreateSuccess, onCreateError);

        function onCreateSuccess(list) {
            $uibModalInstance.close(list);
        }

        function onCreateError(error) {
            toastr.error('Our apologies, we have been unable to create this ' + vm.tag + '. Please, try again later.');
            vm.creating = false;
        }
    }

    function cancel() {
        return $uibModalInstance.dismiss();
    }

    function refreshAccountList(search) {
        var params = {
            term: search || null,
            items_per_page: 50
        };
        return Account.get(params).$promise
            .then(onGetAccountsSuccess, onGetAccountsError);

        function onGetAccountsSuccess(accounts) {
            vm.accounts = accounts.items;
        }

        function onGetAccountsError(error) {
            toastr.error('Our apologies, we have been unable to recover your accounts. Please, try again later.');
        }
    }
}
})();
