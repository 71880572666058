(function() {
    'use strict';

    angular
        .module('tvl.common')
        .directive('tvlYoutubeVideoThumbnail', tvlYoutubeVideoThumbnail);

    tvlYoutubeVideoThumbnail.$inject = ['tvlYoutube'];

    /** @ngInject */
    function tvlYoutubeVideoThumbnail(tvlYoutube) {
        return {
            restrict: 'E',
            replace: true,
            scope: {
                videoUrl: '=',
                videoFound: '='
            },
            templateUrl: "common/youtube-video-thumbnail.html",
            link: function($scope, element, attrs) {
                $scope.videoPreview = {
                    title: '',
                    thumbnail: '',
                    description: '',
                    channelTitle: '',
                    channelUrl: ''
                };
                $scope.searchingVideoPreview = false;

                function init() {
                    searchVideoInYoutube();
                    $scope.unregisterVideoUrlWatcher = $scope.$watch('videoUrl', onVideoUrlChange);
                    $scope.$on('$destroy', onDestroy);
                }

                init();

                function searchVideoInYoutube() {
                    if (!$scope.searchingVideoPreview) {
                        clearData();
                        $scope.searchingVideoPreview = true;
                        var videoId = getVideoIdFromUrl($scope.videoUrl);
                        tvlYoutube.getVideoById({part: 'snippet', videoId: videoId})
                            .then (function(response) {
                                if (response.data.items.length > 0) {
                                    $scope.videoPreview.title = response.data.items[0].snippet.title;
                                    $scope.videoPreview.thumbnail = response.data.items[0].snippet.thumbnails.medium.url;
                                    $scope.videoPreview.description = response.data.items[0].snippet.description;
                                    $scope.videoPreview.channelTitle = response.data.items[0].snippet.channelTitle;
                                    $scope.videoPreview.channelUrl = '/channel/'+response.data.items[0].snippet.channelId;
                                    $scope.videoFound = true;
                                } else {
                                    $scope.videoFound = false;
                                }
                            })
                            .catch (function() {
                                $scope.videoFound = false;
                            })
                            .finally (function() {
                                $scope.searchingVideoPreview = false;
                            });
                    }
                }

                function clearData() {
                    $scope.videoPreview = {
                        title: '',
                        thumbnail: '',
                        description: '',
                        channelTitle: '',
                        channelUrl: ''
                    };
                }

                function getVideoIdFromUrl(url) {
                    var regex = /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/ ]{11})/i;
                    return url.match(regex)[1];
                }

                function isValidYoutubeVideoUrl(videoUrl) {
                    var longUrlRegex = new RegExp(/^((?:http|https):\/\/(?:www\.)?youtube.com\/watch\?v=([A-Z0-9_-]{11})$)/i);
                    var shortUrlRegex = new RegExp(/^((?:http|https):\/\/youtu.be\/([A-Z0-9_-]{11})$)/i);
                    return isValidUrl(videoUrl, [longUrlRegex, shortUrlRegex]);
                }

                function isValidUrl(videoUrl, patterns) {
                    if (typeof videoUrl === 'undefined' || videoUrl.length < 1) {
                        return false;
                    }

                    for (var i = 0; i < patterns.length; i++) {
                        if (patterns[i].test(videoUrl)) {
                            return true;
                        }
                    }

                    return false;
                }

                function onVideoUrlChange(newValue, oldValue) {
                    if (newValue !== oldValue) {
                        searchVideoInYoutube();
                    }
                }

                function onDestroy() {
                    $scope.unregisterVideoUrlWatcher();
                }
            }
        };
    }
})();
