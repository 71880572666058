(function() {
    'use strict';

    angular
        .module('tvl.common')
        .directive('maxDateRangePeriod', maxDateRangePeriod);

    maxDateRangePeriod.$inject = [];

    /* @ngInject */
    function maxDateRangePeriod() {
        return {
            restrict: 'A',
            require: 'ngModel',
            replace: false,
            link: function(scope, elm, attrs, ctrl) {
                ctrl.$validators.maxDateRangePeriod = function(modelValue, viewValue) {
                    var isValid = false;
                    if (null === modelValue || undefined === modelValue || isValidDateRangePeriod(modelValue)) {
                        isValid = true;
                    }

                    /**
                     * @param modelValue
                     *
                     * @return {boolean}
                     */
                    function isValidDateRangePeriod(modelValue) {

                        var endDate = modelValue.endDate.startOf('day');
                        var startDate = modelValue.startDate.startOf('day');

                        var diff = endDate.diff(startDate, attrs.maxDateRangePeriodTimeUnit, true);
                        return diff <=  attrs.maxDateRangePeriodTime;
                    }
                    return isValid;
                };
            }
        };
    }
})();