(function() {
    'use strict';

    angular
        .module('tvl.campaign')
        .controller('HourlyScheduleModalController', HourlyScheduleModalController);

    HourlyScheduleModalController.$inject = [
        '$scope',
        '$uibModalInstance',
        'timeSlots'
    ];

    /* @ngInject */
    function HourlyScheduleModalController(
        $scope,
        $uibModalInstance,
        timeSlots
    ) {
        var vm = this;
        vm.timeSlots = timeSlots;
        vm.days = [1, 2, 3, 4, 5, 6, 7];
        vm.hours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24];
        vm.newTimeslotForm = {};
        vm.newTimeslotFormErrors = [];

        vm.addTimeslot = addTimeslot;
        vm.deleteTimeslot = deleteTimeslot;
        vm.resetTimeslotForm = resetTimeslotForm;
        vm.dayToText = dayToText;
        vm.hourToText = hourToText;
        vm.save = save;
        vm.cancel = cancel;

        activate();

        function activate() {
            resetTimeslotForm();
        }

        /**
         * Submit the new timeslot form and add it to the list of timeslots.
         */
        function addTimeslot() {
            var slot = angular.copy(vm.newTimeslotForm);
            if (validateTimeslot(slot)) {
                vm.timeSlots.push(slot);
                resetTimeslotForm();
            }
        }

        /**
         * Delete the timeslot at the specified position.
         *
         * @param {Number} idx
         */
        function deleteTimeslot(idx) {
            vm.timeSlots.splice(idx, 1);
        }

        /**
         * Return the printable representation of the given day.
         *
         * @param {Number} day
         * @return {String}
         */
        function dayToText(day) {
            return [
                null,
                'Mondays',
                'Tuesdays',
                'Wednesdays',
                'Thurdsays',
                'Fridays',
                'Saturdays',
                'Sundays'
            ][day];
        }

        /**
         * Return the printable, clock-like representation of the given hour,
         * which is basically the hour padded with zeros followed by the
         * minutes.
         *
         * The actual representation depends on whether the specified hour is
         * treated as an start or an end time. For example, the hour 5 is
         * displayed as "05:00" if it is a start time, but "4:59" if it is an
         * end time.
         *
         * @param {Number} hour
         * @param {Boolean} isEndDate
         * @return {String}
         */
        function hourToText(hour, isEndDate) {
            // one-liner left 0-pad, as seen in
            // https://stackoverflow.com/questions/10073699/pad-a-number-with-leading-zeros-in-javascript#comment33639551_10073699
            return ('00' + (isEndDate ? hour - 1 : hour)).substr(-2, 2) + (isEndDate ? ':59' : ':00');
        }

        /**
         * Reset the new timeslot form, clearing any potential error.
         */
        function resetTimeslotForm() {
            vm.newTimeslotForm = {
                dayOfWeek: null,
                from: null,
                to: null
            };
            vm.newTimeslotFormErrors = [];
            if ($scope.newTimeslotForm) {
                $scope.newTimeslotForm.$setPristine();
            }
        }

        /**
         * Validate the given timeslot.
         *
         * This method returns true if the timeslot is valid, and false
         * otherwise. If invalid, this method will also populate the relevant
         * error messages.
         *
         * @param {Object} slot
         * @return {Boolean}
         */
        function validateTimeslot(slot) {
            for (var i in vm.timeSlots) {
                var t = vm.timeSlots[i];
                if (t.dayOfWeek !== slot.dayOfWeek) {
                    continue;
                }
                if ((t.from <= slot.from && t.to > slot.from)
                    || (t.from < slot.to && t.to >= slot.to)
                    || (t.from >= slot.from && t.to <= slot.to)
                ) {
                    vm.newTimeslotFormErrors.push('This time slot overlaps with an existing one');
                    return false;
                }
            }
            return true;
        }

        /**
         * Save the timeslots and close the modal.
         */
        function save() {
            return $uibModalInstance.close({timeSlots: vm.timeSlots});
        }

        /**
         * Dismiss the modal.
         */
        function cancel() {
            return $uibModalInstance.dismiss();
        }
    }
})();
