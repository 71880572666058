(function() {
    'use strict';

    angular
        .module('tvl.campaign')
        .controller('CreateRuleModalController', CreateRuleModalController);

    CreateRuleModalController.$inject = ['$uibModalInstance', 'tvlRules', 'Placement', 'Location', 'placements', 'locations'];

    /* @ngInject */
    function CreateRuleModalController($uibModalInstance, tvlRules, Placement, Location, placements, locations) {
        var vm = this;
        vm.condition = null;
        vm.action = null;
        vm.conditions = tvlRules.getAllConditions();
        vm.actions = tvlRules.getAllActions();
        vm.locations = locations;
        vm.locationIds = [];
        vm.selectedLocations = [];
        vm.selectedLocationsIds = [];
        vm.showLocations = false;

        vm.onSelectedLocationsChange = onSelectedLocationsChange;

        vm.showAdvanced = showAdvanced;
        vm.cancel = cancel;
        vm.createRule = createRule;
        vm.searchLocationsDescendants = searchLocationsDescendants;

        activate();

        ////////////////

        function activate() {

            if (vm.locations) {
                vm.locationIds = _.map(vm.locations, 'id');
                searchLocationsDescendants(null);
            }

            placements.forEach(function(placement) {
                Placement.getLocations({id: placement})
                    .$promise
                    .then(onGetLocationsSuccess, onGetLocationsError)
                    .finally(onGetLocationsFinally);
            });

        }

        function onGetLocationsSuccess(locations) {
            vm.locationIds = [];
            locations.forEach(function(location) {
                vm.locations.push(location);
                vm.locationIds.push(location.id);
            });
            vm.locations = _.uniqBy(vm.locations, 'id');
            searchLocationsDescendants(null);
        }

        function onGetLocationsError(error) {
        }

        function onGetLocationsFinally() {
        }

        function searchLocationsDescendants(searchTerm) {
            var params = {
                'parentIds[]': vm.locationIds,
                'name': searchTerm
            };

            return Location.get(params).$promise
                .then(onGetDescendantsSuccess)
                .catch(onGetDescendantsError);

            function onGetDescendantsSuccess(data) {
                vm.locations = vm.locations.concat(_.toArray(data.items));
                vm.locations = _.uniqBy(vm.locations, 'id');
            }

            function onGetDescendantsError(reason) {
            }
        }

        function cancel() {
            return $uibModalInstance.dismiss();
        }

        /**
         * Create the rule and resolve this modal.
         */
        function createRule() {
            var rule = {
                condition: cleanCondition(vm.condition),
                action:  cleanAction(vm.action),
                locations: vm.selectedLocations
            };

            $uibModalInstance.close(rule);
        }

        /**
         * Return a copy of the given action with any extra attributes removed
         * so that it can be safely sent to the API.
         *
         * @param {Object} action
         * @return {Object}
         */
        function cleanAction(action) {
            return cleanThing(action, 'action');
        }

        /**
         * Return a copy of the given condition with any extra attributes
         * removed so that it can be safely sent to the API.
         *
         * @param {Object} condition
         * @return {Object}
         */
        function cleanCondition(condition) {
            return cleanThing(condition, 'condition');
        }

        /**
         * Return a copy of the given thing, which may be either an action or a
         * condition (as specified in the provided type), with any extra
         * attributes removed so that it can be  safely sent to the API.
         *
         * @param {Object} sourceThing
         * @param {String} type
         * @return {Object}
         */
        function cleanThing(sourceThing, type) {
            var thing = angular.copy(sourceThing);
            if (type === 'condition' && thing.type === 'compound') {
                for (var i in thing.conditions) {
                    thing.conditions[i] = cleanCondition(sourceThing.conditions[i]);
                }
            } else {
                if (type === 'condition') {
                    var params = tvlRules.getCondition(thing.type).params;
                } else {
                    var params = tvlRules.getAction(thing.type).params;
                }

                var paramNames = _.map(params, 'name');

                for (var i in thing) {
                    if (i !== 'type' && paramNames.indexOf(i) < 0) {
                        delete thing[i];
                    }
                }
            }
            return thing;
        }

        function onSelectedLocationsChange() {
            vm.selectedLocationsIds = [];
            vm.selectedLocations.forEach(function (location) {
                vm.selectedLocationsIds.push(location.id);
            });
        }

        function showAdvanced() {
            vm.showLocations = true;
        }
    }
}());
