(function() {
    'use strict';

    angular
        .module('tvl.administration')
        .controller('GrafanaDashboardController', GrafanaDashboardController);

        GrafanaDashboardController.$inject = ['$scope', '$window'];

    /* @ngInject */
    function GrafanaDashboardController($scope, $window) {
        var vm = this;

        goToDashboardPage();

        function goToDashboardPage() {
            $window.open('https://grafana.prod.tech.theviralab.com', '_blank');
        }
    }

}());
