(function () {
    'use strict';

    angular
        .module('tvl.campaign')
        .controller('NewUserModalController', NewUserModalController);

    NewUserModalController.$inject = [
        '$scope',
        '$uibModalInstance',
        'toastr',
        'tvlForm',
        'User',
        'user',
        'company'
    ];

    /* @ngInject */
    function NewUserModalController(
        $scope,
        $uibModalInstance,
        toastr,
        tvlForm,
        User,
        user,
        company
    ) {
        var vm = this;

        vm.newUserForm = {
            email: null,
            name: null
        };
        vm.disableNewUserCreateButton = true;
        vm.isEmailGiven = false;
        vm.newUserCreateButtonText = 'Create';

        vm.cancel = cancel;
        vm.checkNewUserFormFields = checkNewUserFormFields;
        vm.createNewUser = createNewUser;

        vm.company = company;

        activate();


        ////////////////

        function activate() {
            if (user && user.email) {
                vm.newUserForm.email = user.email;
                vm.isEmailGiven = true;
            }
        }

        function cancel() {
            return $uibModalInstance.dismiss();
        }

        function checkNewUserFormFields() {
            if (tvlForm.validateEmail(vm.newUserForm.email) && vm.newUserForm.name) {
                vm.disableNewUserCreateButton = false;
            } else {
                vm.disableNewUserCreateButton = true;
            }
        }

        function createNewUser() {
            vm.disableNewUserCreateButton = true;
            vm.newUserCreateButtonText = 'Creating...';

            if (tvlForm.validateEmail(vm.newUserForm.email) && vm.newUserForm.name) {
                saveUser().then(onUserCreationSuccess).catch(onUserCreationError);
            } else {
                onUserCreationError();
            }

            function saveUser() {
                var user = new User({
                    email: vm.newUserForm.email,
                    name: vm.newUserForm.name,
                    company: vm.company.id,
                    roles: ['ROLE_CLIENT']
                });
                return user.$save();
            }

            function onUserCreationSuccess(user) {
                toastr.success('An email has been sent to ' + user.email + ' in order to set up a new account.');
                $uibModalInstance.close(user);
            }

            function onUserCreationError(error) {
                vm.disableNewUserCreateButton = false;
                vm.newUserCreateButtonText = 'Create';
                switch (error.status) {
                    case 400:
                        var message = error.data.errors[0].message;
                        toastr.error(message);
                        break;
                    case 403:
                        toastr.error('You are not authorized to complete that action.');
                        break;
                    default:
                        toastr.error('Our apologies, an error occurred while attempting to create that user. Please, try again in a few moments.');
                }
            }

        }

    }
}());
