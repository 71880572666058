(function() {
    'use strict';

    angular
        .module('tvl.common')
        .directive('tvlSparkline', tvlSparkline);

    tvlSparkline.$inject = ['$timeout'];

    /* @ngInject */
    function tvlSparkline($timeout) {
        // Usage:
        //
        // Creates:
        //
        var directive = {
            require: 'ngModel',
            link: link,
            restrict: 'A',
            scope: {
            }
        };
        return directive;

        function link(scope, elem, attrs, ngModel) {
            var opts = {};
            //TODO: Use $eval to get the object
            opts.type = attrs.type || 'line';
            opts.barColor = attrs.barColor || '#000000';
            opts.height = attrs.height || '35px';
            opts.width = attrs.width || '35px';
            opts.barWidth = attrs.barWidth || '5px';
            opts.barSpacing = attrs.barSpacing || '2px';
            opts.zeroAxis = attrs.zeroAxis || 'true';
            opts.resize = attrs.resize || false;
            opts.fillColor = attrs.fillColor || '#cdf';
            opts.lineWidth = attrs.lineWidth || 1;
            opts.lineColor = attrs.lineColor || '#00f';
            opts.spotColor = attrs.spotColor || '#f80';
            opts.spotRadius = attrs.spotRadius || 1.5;
            opts.minSpotColor = attrs.minSpotColor || '#f80';
            opts.maxSpotColor = attrs.maxSpotColor || '#f80';
            opts.highlightSpotColor = attrs.highlightSpotColor || '#5f5';
            opts.highlightLineColor = attrs.highlightLineColor || '#f22';

            scope.$watch(attrs.ngModel, function() {
                /*
                 * This delay allows Angular to modify the DOM
                 * before initializing Sparklines, so that they use
                 * the correct container width.
                 */
                $timeout(function() {
                    render();
                }, 500);
            });

            var render = function() {
                var model;
                if(attrs.opts)
                    angular.extend(opts, angular.fromJson(attrs.opts));

                if (ngModel.$viewValue) {
                    // Trim trailing comma if we are a string
                    angular.isString(ngModel.$viewValue) ? model = ngModel.$viewValue.replace(/(^,)|(,$)/g, "") : model = ngModel.$viewValue;
                    var data;
                    // Make sure we have an array of numbers
                    data = angular.isArray(model) ? model : model.split(',');
                    $(elem).sparkline(data, opts);
                }

            };

            // function to initiate Sparkline
            var sparkResize;
            $(window).resize(function(e) {
                if (opts.resize) {
                    clearTimeout(sparkResize);
                    sparkResize = setTimeout(render, 500);
                }
            });
        }
    }
})();
