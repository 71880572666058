(function() {
    'use strict';

    angular
        .module('tvl.common')
        .service('reportDownloader', reportDownloader);

    reportDownloader.$inject = [
        '$http',
        'resourceResponse',
        'FileSaver',
        'Blob'
    ];

    function reportDownloader(
        $http,
        resourceResponse,
        FileSaver,
        Blob
    ) {
        return {
            download: download,
            isDownloadAllowed: isDownloadAllowed,
        };

        ////////////////

        function isDownloadAllowed(url) {
            return $http.get(url);
        }

        function download(url) {
            return $http.get(url, { responseType: "arraybuffer" }).then(
                function (response) {
                    var type = response.headers('Content-Type');
                    var disposition = response.headers('Content-Disposition');
                    var defaultFileName = '';
                    if (disposition) {
                        var match = disposition.match(/.*filename=\"?([^;\"]+)\"?.*/);
                        if (match[1]) {
                            defaultFileName = match[1];
                        }
                    }
                    defaultFileName = defaultFileName.replace(/[<>:"\/\\|?*]+/g, '_');
                    var blob = new Blob([response.data], { type: type });
                    FileSaver.saveAs(blob, defaultFileName);
                }, resourceResponse.error);
        }
    }
})();
