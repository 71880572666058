(function() {
    'use strict';

    angular
        .module('tvl.common')
        .directive('maxFileSize', maxFileSize);

    maxFileSize.$inject = [];

    /* @ngInject */
    function maxFileSize() {
        return {
            restrict: 'A',
            require: 'ngModel',
            replace: false,
            link: function(scope, elm, attrs, ctrl) {
                ctrl.$validators.maxFileSize = function(modelValue, viewValue) {
                    var isValid = false;
                    if (null === modelValue || undefined === modelValue || isValidSize(modelValue)) {
                        isValid = true;
                    }

                    /**
                     * @param modelValue
                     *
                     * @return {boolean}
                     */
                    function isValidSize(modelValue) {
                        return null !== modelValue && modelValue.size < parseFloat(attrs.maxFileSize);
                    }

                    return isValid;
                };

            }
        };
    }
})();
