const moment = require('moment');

(function () {
    'use strict';

    angular
        .module('tvl.campaign')
        .controller('AdVideoModalController', AdVideoModalController);

    AdVideoModalController.$inject = [
        '$scope',
        '$uibModalInstance',
        '$window',
        '$timeout',
        'toastr',
        'tvlCampaignUtils',
        'tvlAdVideoUtils',
        'tvlUrl',
        'tvlUuid',
        'tvlYoutube',
        'tvlConstantUtils',
        'FacebookBusiness',
        'UrlUtil',
        'Upload',
        'ImageAsset',
        'adVideoInfo',
        'isConversionEnabled',
        'userEmail',
        'facebookPages',
        'facebookAdVideos',
        'areFacebookAndInstagramSelected',
        'youtubeTrueViewForActionAdExists',
        'youtubeNonTrueViewForActionAdExists',
        'accountId',
        'facebookAdAccountId',
        'devices',
        'resourceResponse'
    ];

    /* @ngInject */
    function AdVideoModalController(
        $scope,
        $uibModalInstance,
        $window,
        $timeout,
        toastr,
        tvlCampaignUtils,
        tvlAdVideoUtils,
        tvlUrl,
        tvlUuid,
        tvlYoutube,
        tvlConstantUtils,
        FacebookBusiness,
        UrlUtil,
        Upload,
        ImageAsset,
        adVideoInfo,
        isConversionEnabled,
        userEmail,
        facebookPages,
        facebookAdVideos,
        areFacebookAndInstagramSelected,
        youtubeTrueViewForActionAdExists,
        youtubeNonTrueViewForActionAdExists,
        accountId,
        facebookAdAccountId,
        devices,
        resourceResponse
    ) {
        var vm = this;

        vm.isConversionEnabled = isConversionEnabled;
        vm.userEmail = userEmail;
        vm.youtubeTrueViewForActionAdExists = youtubeTrueViewForActionAdExists;
        vm.youtubeNonTrueViewForActionAdExists = youtubeNonTrueViewForActionAdExists;
        vm.devices = devices;

        vm.DISPLAY_URL_MAX_CHARACTERS_ALLOWED = 255;

        vm.tvlAdVideoUtils = tvlAdVideoUtils;

        vm.facebookInstagramAdVideo = new tvlAdVideoUtils.FacebookAnyAdVideo();
        vm.youtubeAdVideo = new tvlAdVideoUtils.InStreamAdVideo();

        vm.showYoutubeAdVideoPreview = false;
        vm.showFacebookInstagramAdVideoPreview = false;
        vm.submitButtonText = null;
        vm.modalTitle = null;
        vm.tvlUrl = tvlUrl;

        /* Video duration validations */
        vm.isValidYoutubeVideoDuration = false;
        vm.minVideoDuration = null;
        vm.maxVideoDuration = null;
        vm.currentVideoDuration = null;

        vm.ui = {
            checkingFacebookVideoAccessibility: false,
            facebookAdVideoAccessible: false,
            loadingFacebookPosts: false,
            loadingInstagramPosts: false,
            postSearch: false,
            loadingFacebookAdVideos: false,
            validationErrors: {
                youtubeAdVideo: null
            },
            selectedGoal: tvlAdVideoUtils.GOAL_VIEWS,
            isValidYoutubeAdVideo: false,
            nonMobileDeviceFound: nonMobileDeviceFound(),
            adVideoSource: tvlAdVideoUtils.FACEBOOK_VIDEO_SOURCE_FROM_POST,
            facebookVideoTargetUrlOption: false,
            isValidFacebookPositions: true,
            hideIntervalDays: false,
            hideFrequencyCapImpressions: false,
            isFacebookPostMultiShare : false,
            showUploadAssetsSection: false,
            assetsUploadInProgress: false,
            isModalBlocked: isModalBlocked,
        };

        vm.unregisterFns = [];
        vm.videosFound = {
            youtube: false,
            // FB is currently unused, as videos are selected via dropdowns
            facebookOrInstagram: false
        };
        vm.videoNetwork = tvlConstantUtils.NETWORK_YOUTUBE;
        vm.facebookPage = null;
        vm.InstagramId = null;
        vm.facebookPost = null;
        vm.ui.isFacebookPostMultiShare = false;
        vm.facebookAdVideo = null;
        vm.InstagramStoryAdVideo = null;
        vm.availableFacebookPages = facebookPages;
        vm.availableFacebookPosts = null;
        vm.availableInstagramPosts = null;
        vm.availableFacebookAdVideos = null;
        vm.areFacebookAndInstagramSelected = areFacebookAndInstagramSelected;
        vm.createInBothNetworks = false;
        vm.facebookObjective = null;
        vm.facebookOptimizationGoal = null;
        vm.beforePagePostRequest = null;

        //fb button related
        vm.facebookPages = null;
        vm.uid = null;
        vm.accessToken = null;
        vm.instagramAccount = null;
        vm.facebookAccountCredentials = {
            accountId: accountId,
            adAccountId: facebookAdAccountId
        };
        window.checkLoginState = checkLoginState;

        vm.cancel = cancel;
        vm.canCreateInFacebookAndInstagram = canCreateInFacebookAndInstagram;
        vm.checkYoutubeTargetAndDisplayUrlDomains = checkYoutubeTargetAndDisplayUrlDomains;
        vm.checkFacebookTargetAndDisplayUrlDomains = checkFacebookTargetAndDisplayUrlDomains;
        vm.checkFacebookLinkUrlAndDisplayUrlDomains = checkFacebookLinkUrlAndDisplayUrlDomains;
        vm.hasHttpErrorYoutubeTargetUrl = hasHttpErrorYoutubeTargetUrl;
        vm.createOrUpdateAdVideo = createOrUpdateAdVideo;
        vm.isValidFacebookInstagramLinkUrl = isValidFacebookInstagramLinkUrl;
        vm.isValidFacebookInstagramTargetUrl = isValidFacebookInstagramTargetUrl;
        vm.isValidFacebookInstagramDisplayUrl = isValidFacebookInstagramDisplayUrl;
        vm.isValidTrackingUrl = isValidTrackingUrl;
        vm.isValidSecuredTrackingUrl = isValidSecuredTrackingUrl;
        vm.isValidYoutubeDisplayUrl = isValidYoutubeDisplayUrl;
        vm.isValidYoutubeTargetUrl = isValidYoutubeTargetUrl;
        vm.onCompanionBannerChange = onCompanionBannerChange;
        vm.validFacebookInstagramAdVideo = validFacebookInstagramAdVideo;
        vm.isYoutubeNetwork = isYoutubeNetwork;
        vm.isFacebookNetwork = isFacebookNetwork;
        vm.isInstagramNetwork = isInstagramNetwork;
        vm.areFormatsCompatible = areFormatsCompatible;
        vm.isFacebookPhotoPost = isFacebookPhotoPost;
        vm.isFacebookVideoPost = isFacebookVideoPost;
        vm.isAdVideoFacebookPhoto = isAdVideoFacebookPhoto;
        vm.isAdVideoFacebookVideo = isAdVideoFacebookVideo;
        vm.showFacebookPrimaryText = showFacebookPrimaryText;
        vm.showFacebookPageSelector = showFacebookPageSelector;
        vm.showFacebookOptionsForCPCGoal = showFacebookOptionsForCPCGoal;
        vm.getAvailableFacebookAdVideos = getAvailableFacebookAdVideos;
        vm.onFacebookAdSourceClick = onFacebookAdSourceClick;
        vm.accountId = accountId;
        vm.postIdToSearch = null;
        vm.postNotFound = false;
        vm.sugestedPost = null;
        vm.postFoundNotValid = null;
        vm.adAccountId = facebookAdAccountId;
        vm.facebookObjectiveOptions = tvlAdVideoUtils.getFacebookObjectives();
        vm.facebookOptimizationGoalOptionsForCpm = tvlAdVideoUtils.getFacebookOptimizationGoalOptionsForCpm();
        vm.facebookOptimizationGoalOptionsForCpv = tvlAdVideoUtils.getFacebookOptimizationGoalOptionsForCpv();
        vm.facebookCallToActionButtonValues = null;

        vm.facebookPositionsType = vm.tvlAdVideoUtils.FACEBOOK_PLACEMENT_POSITIONS_TYPE_AUTOMATIC;
        vm.availableFacebookPositions = tvlAdVideoUtils.getFacebookPositions(vm.devices.mobile);
        vm.availableInstagramPositions = tvlAdVideoUtils.getInstagramPositions();

        activate();

        ////////////////

        function activate() {
            vm.videoNetwork = adVideoInfo.network;
            if (adVideoInfo.adVideo) {
                /**
                 * This code is obsolete.
                 * In wizard edit ad-video in campaign creation currently is disabled.
                 * Remove this code if the edit functionality is not returned.
                 */
                vm.modalTitle = 'Edit Ad';
                vm.submitButtonText = 'Update Ad';
                if (tvlConstantUtils.isNetworkYoutube(adVideoInfo.network)) {
                    vm.youtubeAdVideo = adVideoInfo.adVideo;
                    vm.youtubeAdVideo.videoUrl = tvlUrl.getYoutubeVideoUrlById(vm.youtubeAdVideo.video.videoId);
                    vm.showYoutubeAdVideoPreview = true;
                    checkYoutubeVideoUrl(vm.youtubeAdVideo.videoUrl);
                    checkYoutubeTargetAndDisplayUrlDomains();
                } else {
                    vm.facebookInstagramAdVideo = adVideoInfo.adVideo;
                    checkFacebookInstagramVideoUrl(vm.facebookInstagramAdVideo.videoUrl);
                }
            } else {
                vm.modalTitle = 'New Ad';
                vm.submitButtonText = 'Create Ad';
            }

            getFacebookCallToActionButtonValues(vm.facebookInstagramAdVideo.goal);

            /**
             * Currently Romario can't optimize TFA campaigns, and campaigns with TFA ad can't have automananged
             * option active. So if there is at least one TFA ad, we don't allow creation of other formats.
             */
            if (vm.youtubeTrueViewForActionAdExists) {
                vm.ui.selectedGoal = tvlAdVideoUtils.GOAL_ACTION;
                vm.youtubeAdVideo.format = vm.tvlAdVideoUtils.ADVIDEO_FORMAT_ADWORDS_TRUEVIEW_FOR_ACTION;
            }

            vm.unregisterFns = [
                $scope.$watch('vm.facebookInstagramAdVideo.videoUrl', onFacebookInstagramVideoUrlChange),
                $scope.$watch('vm.youtubeAdVideo.videoUrl', onYoutubeVideoUrlChange),
                $scope.$watch('vm.youtubeAdVideo.targetUrl', onYoutubeTargetOrDisplayUrlChange),
                $scope.$watch('vm.youtubeAdVideo.format', onYoutubeVideoFormatChange),
                $scope.$watchGroup([
                    'vm.isValidYoutubeVideoDuration',
                    'vm.youtubeAdVideo.format',
                    'vm.youtubeAdVideo.videoUrl',
                    'vm.youtubeAdVideo.targetUrl',
                    'vm.youtubeAdVideo.displayUrl',
                    'vm.youtubeAdVideo.trackingUrl',
                    'vm.youtubeAdVideo.headline',
                    'vm.youtubeAdVideo.firstDescription',
                    'vm.youtubeAdVideo.secondDescription',
                ], validateYoutubeAdVideo),
                $scope.$watch('vm.facebookInstagramAdVideo.goal', onFacebookInstagramAdVideoGoalChange),
                $scope.$watch('vm.facebookInstagramAdVideo.format', onfacebookInstagramAdVideoVideoFormatChange),
                $scope.$watch('vm.facebookInstagramAdVideo.facebookPositions', onFacebookPositionsChange),
                $scope.$watch('vm.facebookPage', onFacebookPageChange),
                $scope.$watch('vm.facebookPost', onFacebookPostChange),
                $scope.$watch('vm.postIdToSearch', onFacebookPostIdChange),
                $scope.$watch('vm.facebookAdVideo', onFacebookAdVideoChange),
                $scope.$watch('vm.facebookObjective', onFacebookObjectiveChange),
                $scope.$watch('vm.facebookOptimizationGoal', facebookOptimizationGoalChange),
                $scope.$watch('vm.ui.selectedGoal', onGoalChange),
                $scope.$watch('vm.ui.adVideoSource', onFacebookAdVideoSourceChange),
                $scope.$watch('vm.ui.facebookVideoTargetUrlOption', facebookVideoTargetUrlChange),
                $scope.$watch('vm.facebookPositionsType', facebookPositionsTypeChange),
                $scope.$watch('vm.ui.showUploadAssetsSection', onFacebookAssetSourceChange),
            ];
        }

        function checkLoginState(){

            // hacky, on local developement uncomment getfacebookopages here before checking connected
            // getFacebookPages();

            FB.getLoginStatus(function(response) {
                if (response.status === 'connected') {
                    getFacebookPages();
                }
            });
        }

        function facebookLogout() {
            if (window.FB) {
                FB.logout(function(response) {
                });
                $uibModalInstance.dismiss();
            }
        }

        function getFacebookCredentials() {
            if (window.FB) {
                FB.getLoginStatus(function(response) {   // See the onlogin handler
                    if (response.status === 'connected') {
                        vm.uid = response.authResponse.userID;
                        vm.accessToken = response.authResponse.accessToken;
                    } else if (response.status === 'not_authorized') {
                        FB.logout(function(response) {
                        });
                        $uibModalInstance.dismiss();
                    } else {


                        // here: use your accesstoken and user id here on local environment for simulate loged user data instead no logged error
                        // console.log('hacky stuff')
                        // vm.accessToken = '---';
                        // vm.uid = '----';

                        toastr.error(
                            'We can not reach your facebook login data. Please logout from Facebook login again'
                        );
                    }
                });
            }
        }

        function getFacebookPages() {
            getFacebookCredentials();

            // fill for local use
            // vm.accessToken = '';
            // vm.uid = '';



            var params = {
                accountId: vm.accountId,
                userFbToken: vm.accessToken,
                userFbId:vm.uid
            };

            return FacebookBusiness.getAccessiblePages(params)
                .$promise
                .then(function (res) {
                    vm.availableFacebookPages = res.data;
                });
        }



        function cancel() {
            return $uibModalInstance.dismiss();
        }

        function canCreateInFacebookAndInstagram() {
            return vm.facebookInstagramAdVideo.isFacebookAny() &&
                vm.facebookInstagramAdVideo.isCpv() &&
                vm.areFacebookAndInstagramSelected;
        }

        function checkFacebookInstagramVideoUrl(url) {
            if (vm.tvlUrl.isValidFacebookInstagramVideoUrl(url)) {
                vm.showFacebookInstagramAdVideoPreview = true;
                vm.facebookInstagramAdVideo.isInstagram = false;
                if (
                    vm.facebookInstagramAdVideo.postType === tvlAdVideoUtils.INSTAGRAM_CREATIVE_POST_PHOTO ||
                    vm.facebookInstagramAdVideo.postType === tvlAdVideoUtils.INSTAGRAM_CREATIVE_POST_VIDEO
                ) {
                    vm.facebookInstagramAdVideo.isInstagram = true;
                }
                checkFacebookVideoAccessibility(url);
            } else {
                vm.showFacebookInstagramAdVideoPreview = false;
            }
        }

        function getFacebookCallToActionButtonValues(goal) {
            FacebookBusiness.getCallToActionButtonValues({adGoal:goal})
                .$promise
                .then(onGetFacebookCallToActionButtonValuesSuccess)
                .catch(onGetFacebookCallToActionButtonValuesError);

            function onGetFacebookCallToActionButtonValuesSuccess (response) {
                vm.facebookCallToActionButtonValues = response.data.map(function(callToAction) {
                    return {
                        value: callToAction,
                        name:  _.startCase(_.toLower(callToAction)).replace('_', ' ')
                    };
                });
            }

            function onGetFacebookCallToActionButtonValuesError (error) {
                toastr.error('Our apologies, we have been unable to get the available Facebook call to action buttons. Please, try again later.');
            }
        }

        function showFacebookPageSelector() {

            return !((vm.tvlAdVideoUtils.isCpvGoal(vm.facebookInstagramAdVideo.goal) || vm.tvlAdVideoUtils.isCpmGoal(vm.facebookInstagramAdVideo.goal)) &&
                vm.facebookOptimizationGoal === null);
        }

        function showFacebookOptionsForCPCGoal() {

            return vm.facebookInstagramAdVideo.goal === 'clicks' &&
                vm.ui.isFacebookPostMultiShare === false &&
                !vm.facebookInstagramAdVideo.isFacebookExternalCarousel();
        }

        /*
         * relocate where appropriate
         */
        function checkFacebookVideoAccessibility(videoUrl) {

            vm.ui.checkingFacebookVideoAccessibility = true;
            var postType = (!vm.facebookInstagramAdVideo.postType) ?
                tvlAdVideoUtils.FACEBOOK_CREATIVE_POST_VIDEO :
                vm.facebookInstagramAdVideo.postType;

            // true if share or multi_share
            var videoId = (postType.toLowerCase().indexOf(tvlAdVideoUtils.FACEBOOK_CREATIVE_POST_SHARE) !== -1 || postType.toLowerCase().indexOf(tvlAdVideoUtils.INSTAGRAM_CREATIVE_POST_VIDEO) !== -1 || postType.toLowerCase().indexOf(tvlAdVideoUtils.INSTAGRAM_CREATIVE_POST_PHOTO) !== -1) ?
                vm.facebookInstagramAdVideo.postId :
                extractFacebookVideoIdFromUrl(videoUrl);

            FacebookBusiness.getPostAccessibility({accountId: vm.accountId, postCreativeId: videoId, postType: postType})
                .$promise
                .then(function (result) {
                    vm.ui.facebookAdVideoAccessible = result.access_granted === true;
                })
                .finally(function () {
                    vm.ui.checkingFacebookVideoAccessibility = false;
                });
        }

        function checkYoutubeTargetAndDisplayUrlDomains() {
            vm.areCorrectTargetAndDisplayYoutubeAdVideoUrlDomains = false;
            vm.ui.isValidYoutubeAdVideo = false;
            if (vm.youtubeAdVideo.targetUrl !== '' && vm.youtubeAdVideo.displayUrl !== '' && tvlUrl.domainsMatch(vm.youtubeAdVideo.targetUrl, vm.youtubeAdVideo.displayUrl)) {
                vm.areCorrectTargetAndDisplayYoutubeAdVideoUrlDomains = true;
                UrlUtil.info({url: vm.youtubeAdVideo.targetUrl})
                    .$promise
                    .then(function(response) {
                        vm.targetUrlStatusCode = response.statusCode;
                        validateYoutubeAdVideo();
                    });
            }
        }

        function checkFacebookTargetAndDisplayUrlDomains() {
            if (vm.facebookInstagramAdVideo.targetUrl !== '' &&
                vm.facebookInstagramAdVideo.displayUrl !== '' &&
                tvlUrl.domainsMatch(vm.facebookInstagramAdVideo.targetUrl, vm.facebookInstagramAdVideo.displayUrl)) {
                return true;
            }
            return false;
        }

        function checkFacebookLinkUrlAndDisplayUrlDomains() {
            if (vm.facebookInstagramAdVideo.linkUrl !== '' &&
                vm.facebookInstagramAdVideo.displayUrl !== '' &&
                tvlUrl.domainsMatch(vm.facebookInstagramAdVideo.linkUrl, vm.facebookInstagramAdVideo.displayUrl)) {
                return true;
            }
            return false;
        }

        function checkYoutubeVideoUrl(url) {
            if (vm.tvlUrl.isValidYoutubeVideoUrl(url)) {
                validateYoutubeVideoDuration();
                vm.showYoutubeAdVideoPreview = true;
            } else {
                vm.showYoutubeAdVideoPreview = false;
            }
        }

        function createOrUpdateAdVideo(adVideo) {
            if (!adVideo.id) {
                adVideo.id = tvlUuid.new();
            }

            if (vm.isYoutubeNetwork() && vm.ui.isValidYoutubeAdVideo) {
                $uibModalInstance.close({adVideo: adVideo, accessible: true});
            } else if (vm.validFacebookInstagramAdVideo(adVideo)) {
                $uibModalInstance.close({adVideo: adVideo, accessible: vm.ui.facebookAdVideoAccessible, bothNetworks: vm.createInBothNetworks});
            }
        }

        /*
         * Facebook story ads are working only on mobile devices.
         * If we detect any other device with this format, we show a warning.
         * @returns {boolean}
         */
        function nonMobileDeviceFound () {
            for (var prop in vm.devices) {
                if (prop === 'mobile') {
                    continue;
                }
                if (vm.devices[prop] === true) {
                    return true;
                }
            }
            return false;
        }

        /*
         * relocate where appropriate (maybe a service?)
         */
        function extractFacebookVideoIdFromUrl(url) {
            var matches = url.match(/facebook\.com\/(?:[0-9a-z\_\-\.]+\/videos\/)?([0-9]+)/i);
            if (matches && matches.length >= 2) {
                return matches[1];
            }
            return null;
        }

        function isValidFacebookInstagramLinkUrl(url) {
            if (url) {
                return tvlUrl.isValid(url) && false === tvlUrl.isFacebookUrl(url);
            }
        }

        /**
         * Following methods have same implementation, this will change in the future after applying more advanced
         * validation rules
         */
        function isValidFacebookInstagramTargetUrl(url) {
            if (url) {
                return tvlUrl.isValid(url);
            }
        }

        function isValidFacebookInstagramDisplayUrl(url) {
            if (url) {
                return tvlUrl.isValid(url) && false === tvlUrl.isFacebookUrl(url);
            }
            return true;
        }

        function isValidSecuredTrackingUrl(url) {
            if (url) {
                return tvlUrl.isValidSecured(url);
            }
            return true;
        }

        function isValidTrackingUrl(url) {
            if (url) {
                return tvlUrl.isValid(url);
            }
            return true;
        }

        /**
         * Return whether the given url is a valid Adwords
         * display URL.
         *
         * @param url {String}
         * @return {Boolean}
         */
        function isValidYoutubeDisplayUrl(url) {
            return url !== undefined ? url.length > 0
              && url.length <= vm.DISPLAY_URL_MAX_CHARACTERS_ALLOWED
              && tvlUrl.isValid(url) : false;
        }

        function isValidYoutubeTargetUrl(url) {
            if (url) {
                return tvlUrl.isValid(url);
            }
        }

        /**
         * Checks the HTTP response status for youtube target url
         * @returns {boolean}
         */
        function hasHttpErrorYoutubeTargetUrl() {
            /**
             * The HTTP query has not been performed yet
             */
            if (!vm.targetUrlStatusCode) {
                return false;
            }

            /**
             * The HTTP response status is OK
             */
            if (vm.targetUrlStatusCode === 200) {
                return false;
            }

            /**
             * The HTTP response status is different from 200
             */
            return true;
        }

        /**
         * Callback function invoked whenever the companion banner changes.
         *
         * This basically Base64-encodes the image and stores the result in the
         * model.
         */
        function onCompanionBannerChange($files, $file, $newFiles, $duplicateFiles, $invalidFiles, $event) {
            if ($file === null) {
                vm.youtubeAdVideo.companionBanner = null;
            } else {
                var imageUrl = '';
                Upload.base64DataUrl($files).then(onUploadFileSuccess);
            }

            function onUploadFileSuccess(urls) {
                imageUrl = urls[0];
                ImageAsset.create({}, {'image': imageUrl})
                    .$promise
                    .then(onImageAssetCreationSuccess);
            }

            function onImageAssetCreationSuccess(response) {
                if (response.id) {
                    ImageAsset.get({'id': response.id})
                        .$promise
                        .then(onGetImageAssetSuccess);
                }
            }

            function onGetImageAssetSuccess(imageInfo) {
                if (imageInfo.colorSpace !== 'CMYK') {
                    vm.youtubeAdVideo.companionBanner = imageUrl;
                    vm.colorSpaceError = false;
                } else {
                    vm.youtubeAdVideo.companionBanner = null;
                    vm.colorSpaceError = true;
                }
            }
        }

        function onFacebookInstagramVideoUrlChange(newVal, oldVal) {
            if (newVal !== oldVal) {
                checkFacebookInstagramVideoUrl(newVal);
            }
        }

        function onYoutubeTargetOrDisplayUrlChange(newVal, oldVal) {
            if (newVal !== oldVal && tvlConstantUtils.isNetworkYoutube(adVideoInfo.network)) {
                checkYoutubeTargetAndDisplayUrlDomains();
            }
        }

        function onYoutubeVideoFormatChange(newVal, oldVal) {
            if (newVal !== oldVal && vm.tvlUrl.isValidYoutubeVideoUrl(vm.youtubeAdVideo.videoUrl)) {
                validateYoutubeVideoDuration();

                /*
                 This is needed to show video duration error when video is being edited
                 and the format changes to bumper
                 */
                if (vm.youtubeAdVideo.videoUrl && !vm.youtubeVideoForm.youtubeVideoUrl.$dirty) {
                    vm.youtubeVideoForm.youtubeVideoUrl.$setDirty();
                }
            }

            /**
             * Rebuild the ad-video to make sure the fields are set correctly.
             */
            vm.youtubeAdVideo = tvlAdVideoUtils.buildAd(vm.youtubeAdVideo);
        }

        function onFacebookInstagramAdVideoGoalChange(newVal, oldVal) {
            if (newVal !== oldVal) {
                if (vm.ui.adVideoSource === vm.tvlAdVideoUtils.FACEBOOK_VIDEO_SOURCE_FROM_INSTAGRAM_POST) {
                    vm.ui.adVideoSource = vm.tvlAdVideoUtils.FACEBOOK_VIDEO_SOURCE_FROM_POST;
                }
                vm.facebookInstagramAdVideo.targetUrl = '';
                vm.facebookInstagramAdVideo.displayUrl = '';
                vm.facebookInstagramAdVideo.linkUrl = '';
                vm.facebookInstagramAdVideo.linkTitle = '';
                vm.facebookInstagramAdVideo.linkDescription = '';
                vm.facebookInstagramAdVideo.trackingUrl = '';
                vm.facebookInstagramAdVideo.facebookObjective = '';
                vm.facebookInstagramAdVideo.facebookOptimizationGoal = null;
                vm.facebookInstagramAdVideo.callToActionButton = null;
                vm.facebookOptimizationGoal = null;
                vm.ui.facebookVideoTargetUrlOption = false;
                vm.availableFacebookPosts = null;
                vm.afterFacebookPosts = null;
                vm.facebookPage = null;
                vm.facebookPost = null;
                vm.ui.isFacebookPostMultiShare = false;
                vm.postIdToSearch = null;
                vm.postNotFound = false;
                vm.sugestedPost = null;
                vm.postFoundNotValid = null;
                vm.createInBothNetworks = false;
                //todo check if we really need this field for Facebook
                vm.videosFound.facebookOrInstagram = false;
                vm.facebookAdVideo = null;
                vm.facebookInstagramAdVideo.videoId = null;
                vm.facebookInstagramAdVideo.pageId = null;
                vm.facebookInstagramAdVideo.videoUrl = '';
                vm.facebookInstagramAdVideo.postType = '';

                if (newVal === vm.tvlAdVideoUtils.GOAL_ACTION) {
                    vm.facebookInstagramAdVideo.facebookOptimizationGoal = vm.tvlAdVideoUtils.FACEBOOK_OPTIMIZATION_GOAL_POST_ENGAGEMENT;
                    getFacebookCallToActionButtonValues(vm.tvlAdVideoUtils.GOAL_IMPRESSIONS);
                } else {
                    getFacebookCallToActionButtonValues(newVal);
                }

            }
        }

        function onfacebookInstagramAdVideoVideoFormatChange(newVal, oldVal) {
            if (newVal !== oldVal) {
                vm.ui.facebookVideoTargetUrlOption = false;
                vm.createInBothNetworks = false;
                //todo check if we really need this field for Facebook
                vm.videosFound.facebookOrInstagram = false;
                vm.facebookPage = null;
                vm.facebookPost = null;
                vm.ui.isFacebookPostMultiShare = false;
                vm.postIdToSearch = null;
                vm.postNotFound = false;
                vm.sugestedPost = null;
                vm.postFoundNotValid = null;
                vm.facebookAdVideo = null;
                vm.facebookInstagramAdVideo.videoId = null;
                vm.facebookInstagramAdVideo.pageId = null;
                vm.facebookInstagramAdVideo.callToActionButton = null;
                vm.facebookInstagramAdVideo = tvlAdVideoUtils.buildAd(vm.facebookInstagramAdVideo);
            }
        }

        function onFacebookPositionsChange(newVal, oldVal) {
            if (newVal !== oldVal) {
                vm.ui.isValidFacebookPositions = true;

                newVal.forEach(function(position) {
                    if (typeof position.mustInclude !== 'undefined') {
                        vm.ui.isValidFacebookPositions = isIncludedPosition(newVal, position.mustInclude);
                    }
                });
            }
        }

        function isIncludedPosition(positions, searchPosition)
        {
            var included = false;

            positions.forEach(function(position) {
                if (position.value === searchPosition) {
                    included = true;
                }
            });

            return included;
        }

        function onYoutubeVideoUrlChange(newVal, oldVal) {
            if (newVal !== oldVal) {
                checkYoutubeVideoUrl(newVal);
            }
        }

        function validateYoutubeVideoDuration() {
            vm.isValidYoutubeVideoDuration = true;
            if (tvlAdVideoUtils.videoDurationCheckRequired(vm.youtubeAdVideo.format)) {
                var videoId = tvlCampaignUtils.getVideoIdFromUrl(tvlConstantUtils.NETWORK_YOUTUBE, vm.youtubeAdVideo.videoUrl);

                tvlYoutube.getVideoById({part: 'contentDetails', videoId: videoId})
                    .then (function(response) {
                        if (response.data.items.length > 0) {
                            vm.currentVideoDuration = moment.duration(response.data.items[0].contentDetails.duration).asSeconds();
                            [vm.minVideoDuration, vm.maxVideoDuration] = tvlAdVideoUtils.getVideoDurationLimits(vm.youtubeAdVideo.format);

                            vm.isValidYoutubeVideoDuration = true;
                            if (vm.minVideoDuration && vm.currentVideoDuration < vm.minVideoDuration) {
                                vm.isValidYoutubeVideoDuration = false;
                            }
                            if (vm.maxVideoDuration && vm.currentVideoDuration > vm.maxVideoDuration) {
                                vm.isValidYoutubeVideoDuration = false;
                            }
                        } else {
                            vm.isValidYoutubeVideoDuration = false;
                        }
                    })
                    .catch (function() {
                        vm.isValidYoutubeVideoDuration = false;
                    });
            }
        }

        /**
         * todo Steiner: at least half of this function could be done with ng-messages and validations directives
         * @param adVideo
         * @returns {boolean}
         */
        function validFacebookInstagramAdVideo(adVideo) {

            if (adVideo.isCpv()) {
                if (!adVideo.facebookOptimizationGoal) {
                    return false;
                }

                if (adVideo.facebookOptimizationGoal === vm.tvlAdVideoUtils.FACEBOOK_OPTIMIZATION_GOAL_THRUPLAY &&
                    !adVideo.facebookObjective) {
                    return false;
                }
            }

            if (adVideo.isCpm()) {
                if (!adVideo.facebookOptimizationGoal) {
                    return false;
                }
            }

            if (!vm.ui.isValidFacebookPositions) {
                return false;
            }

            //todo Steiner: check if we still use this
            if (adVideo.isInstagramStory()) {
                return validInstagramStoriesAdVideo(adVideo);
            }

            if (adVideo.isFacebookExternal()) {
                return validFacebookExternalAdVideo(adVideo);
            }

            if (adVideo.isFacebookExternalCarousel()) {
                return validFacebookExternalCarouselAdVideo(adVideo);
            }

            var videoUrl = adVideo.videoUrl;
            if (! vm.tvlUrl.isValidFacebookInstagramVideoUrl(videoUrl)) {
                return false;
            }

            var trackingUrl = adVideo.trackingUrl;
            if (! vm.isValidTrackingUrl(trackingUrl)) {
                return false;
            }

            if (adVideo.isCpc() && false === vm.ui.isFacebookPostMultiShare) {
                if (! vm.isValidFacebookInstagramLinkUrl(adVideo.linkUrl)) {
                    return false;
                }
                if (! vm.isValidFacebookInstagramDisplayUrl(adVideo.displayUrl)) {
                    return false;
                }

                if (adVideo.linkUrl !== '' &&
                    adVideo.displayUrl !== '' &&
                    ! tvlUrl.domainsMatch(adVideo.linkUrl, adVideo.displayUrl)) {
                    return false;
                }

                if (adVideo.callToActionButton === undefined || !adVideo.callToActionButton) {
                    return false;
                }
            }

            return true;
        }

        //todo check if we still use this
        function validInstagramStoriesAdVideo(adVideo) {
            if (!adVideo.videoId || !adVideo.pageId) {
                return false;
            }

            if (adVideo.isCpc()) {
                if (! vm.isValidFacebookInstagramLinkUrl(adVideo.linkUrl)) {
                    return false;
                }
            }

            if (adVideo.isCpv() || adVideo.isCpm()) {
                if (! vm.isValidFacebookInstagramTargetUrl(adVideo.targetUrl)) {
                    return false;
                }
            }

            return true;
        }

        function validFacebookExternalAdVideo(adVideo) {
            if (!adVideo.videoId || !adVideo.pageId) {
                return false;
            }

            if ((adVideo.isCpv() || adVideo.isCpm()) && true === vm.ui.facebookVideoTargetUrlOption) {
                if (! vm.isValidFacebookInstagramTargetUrl(adVideo.targetUrl)) {
                    return false;
                }
                if (! vm.isValidFacebookInstagramDisplayUrl(adVideo.displayUrl)) {
                    return false;
                }
                if (! checkFacebookTargetAndDisplayUrlDomains) {
                    return false;
                }
                if (adVideo.callToActionButton === undefined || !adVideo.callToActionButton) {
                    return false;
                }
            }

            var trackingUrl = adVideo.trackingUrl;
            if (! vm.isValidTrackingUrl(trackingUrl)) {
                return false;
            }

            if (adVideo.isCpc()) {
                if (! vm.isValidFacebookInstagramLinkUrl(adVideo.linkUrl)) {
                    return false;
                }
                if (! vm.isValidFacebookInstagramDisplayUrl(adVideo.displayUrl)) {
                    return false;
                }

                if (adVideo.linkUrl !== '' &&
                    adVideo.displayUrl !== '' &&
                    ! tvlUrl.domainsMatch(adVideo.linkUrl, adVideo.displayUrl)) {
                    return false;
                }

                if (adVideo.callToActionButton === undefined || !adVideo.callToActionButton) {
                    return false;
                }
            }

            return true;
        }

        function validFacebookExternalCarouselAdVideo(adVideo) {

            //parent Card

            //see More Url is mandatory
            if (! tvlUrl.isValidFacebookInstagramUrl(adVideo.seeMoreUrl)) {
                return false;
            }

            //see more Link and Display must have same domain
            if (adVideo.seeMoreUrl !== '' &&
                adVideo.seeMoreDisplayLink !== '' &&
                !tvlUrl.domainsMatch(adVideo.seeMoreUrl, adVideo.seeMoreDisplayLink)) {
                return false;
            }

            // CTA BUTTON is mandatory
            if (adVideo.callToActionButton === undefined || !adVideo.callToActionButton) {
                return false;
            }

            //Creative Cards

            //Carousel min cards is 2 max 10 inRangeMethod max is max+1
            if (adVideo.creatives && !_.inRange(adVideo.creatives.length, 2, 11)) {
                return false;
            }

            if (adVideo.creatives) {
                var isValid = true;
                _.forEach(adVideo.creatives, function (creative) {
                    if (!creative.videoId || false === tvlUrl.isValidFacebookInstagramUrl(creative.link)) {
                        isValid = false;
                        return false;
                    }
                });

                if (isValid === false) {
                    return false;
                }
            }

            //todo check if we really need this field for Facebook
            // field is forced here
            vm.videosFound.facebookOrInstagram = true;
            return true;
        }

        /**
         * YouTube ad-video validation.
         */
        function validateYoutubeAdVideo() {
            vm.ui.isValidYoutubeAdVideo = false;
            vm.ui.validationErrors.youtubeAdVideo = null;

            if (! vm.videosFound.youtube) {
                return;
            }

            if (! areFormatsCompatible()) {
                return;
            }

            if (! tvlUrl.isValidYoutubeVideoUrl(vm.youtubeAdVideo.videoUrl)) {
                return;
            }

            if (! vm.isValidTrackingUrl(vm.youtubeAdVideo.trackingUrl)) {
                return;
            }

            if (! vm.isValidYoutubeVideoDuration) {
                return;
            }

            if (vm.youtubeAdVideo.isInStreamFamily()) {
                /**
                 * In-Stream family ads.
                 */
                if (! vm.isValidYoutubeTargetUrl(vm.youtubeAdVideo.targetUrl)) {
                    return;
                }

                if (! vm.isValidYoutubeDisplayUrl(vm.youtubeAdVideo.displayUrl)) {
                    return;
                }

                if (! vm.areCorrectTargetAndDisplayYoutubeAdVideoUrlDomains && tvlConstantUtils.isNetworkYoutube(adVideoInfo.network)) {
                    vm.ui.validationErrors.youtubeAdVideo = 'Target and display URLs must have the same domain name.';
                    return;
                }

                if (hasHttpErrorYoutubeTargetUrl()) {
                    return;
                }

                if (vm.youtubeAdVideo.isTrueViewForAction()) {
                    /**
                     * TrueView for action.
                     */
                    if (! vm.isConversionEnabled) {
                        return;
                    }

                    var callToAction = vm.youtubeAdVideo.callToAction;
                    if (! callToAction || callToAction.length < 1 || callToAction.length > tvlAdVideoUtils.TRUEVIEW_CALL_TO_ACTION_MAX_LENGTH) {
                        return;
                    }

                    if (! tvlAdVideoUtils.isValidYoutubeHeadline(vm.youtubeAdVideo)) {
                        return;
                    }
                }

            } else if (vm.youtubeAdVideo.isVideoDiscovery()) {
                /**
                 * Discovery ads.
                 */
                if (! tvlAdVideoUtils.isValidYoutubeHeadline(vm.youtubeAdVideo)) {
                    return;
                }
                if (! tvlAdVideoUtils.isValidYoutubeDescription(vm.youtubeAdVideo.firstDescription)) {
                    return;
                }
                if (! tvlAdVideoUtils.isValidYoutubeDescription(vm.youtubeAdVideo.secondDescription)) {
                    return;
                }
            }

            vm.ui.isValidYoutubeAdVideo = true;
        }

        function getAvailableFacebookPosts(accountId, pageId, goal) {
            vm.ui.loadingFacebookPosts = true;

            if (goal === vm.tvlAdVideoUtils.GOAL_ACTION) {
                goal = vm.tvlAdVideoUtils.GOAL_IMPRESSIONS;
            }

            var params = {
                accountId: accountId,
                pageId: pageId,
                goal: goal,
                after: vm.afterFacebookPosts
            };

            if (_.isEqual(vm.beforePagePostRequest, params)) {
                return;
            }

            vm.beforePagePostRequest = params;

            return FacebookBusiness.getAccessiblePosts(params)
                .$promise
                .then(onGetPostsSuccess)
                .catch(resourceResponse.error)
                .finally(onGetPostsFinally);

            function onGetPostsSuccess(response) {
                vm.availableFacebookPosts = [...vm.availableFacebookPosts, ...response.data.posts];
                vm.afterFacebookPosts = response.data.after;
            }

            function onGetPostsFinally() {
                vm.ui.loadingFacebookPosts = false;
            }
        }

        function getAvailableInstagramPosts(accountId, pageId, both) {
            vm.ui.loadingInstagramPosts = true;

            var params = {
                accountId: accountId,
                pageId: pageId,
                photoAndVideo: both,
                userFbToken: vm.accessToken,
            };

            if (window.FB) {
                FB.getLoginStatus(function(response) {
                    if (response && !response.error && response.status === 'connected') {
                        params.userFbToken =  response.authResponse.accessToken;
                    } else {
                        toastr.error(
                            'Please logout from Facebook login and try again'
                        );
                    }
                });
            }

            return FacebookBusiness.getAccessibleInstagramAccount(params)
                .$promise
                .then(function(response) {
                    vm.instagramAccount = response.data;

                    var params = {
                        accountId: accountId,
                        userFbToken: vm.accessToken,
                        instagramId: vm.instagramAccount.id,
                        photoAndVideo: both,
                    };

                    return FacebookBusiness.getInstagramAccountMedia(params)
                        .$promise
                        .then(onGetPostsSuccess)
                        .catch(resourceResponse.error)
                        .finally(onGetPostsFinally);

                    function onGetPostsSuccess(response) {
                        vm.availableInstagramPosts = response.data;
                    }


                })
                .catch(resourceResponse.error)
                .finally(onGetPostsFinally);

            function onGetPostsFinally() {
                vm.ui.loadingInstagramPosts = false;
            }
        }

        vm.loadMoreFacebookPosts = function(){
            if (false === vm.ui.loadingFacebookPosts) {
                getAvailableFacebookPosts(vm.accountId, vm.facebookPage.id, vm.facebookInstagramAdVideo.goal);
            }
        };

        vm.isFacebookSelectablePost = function(post) {
            var isElegible = post.is_elegible !== 'ineligible';
            var attachmentType = _.get(post, 'attachment.type', null);
            if (!attachmentType) {
                attachmentType = _.get(post, 'media_type', null);
                //clean ig_ prefix from ig posts media type
                attachmentType = attachmentType.substr(3);
            }
            var isValidAttachment = null !== attachmentType &&
                tvlAdVideoUtils.getFacebookPostTypesByGoal(vm.facebookInstagramAdVideo.goal).includes(attachmentType);
            var isValidPostForOptimizationGoal =  vm.isFacebookPostTypeSelectableByOptimizationGoal(attachmentType);
            return isElegible && isValidAttachment && isValidPostForOptimizationGoal;
        };

        vm.isFacebookPostTypeSelectableByOptimizationGoal = function (attachmentType) {
            if(vm.facebookInstagramAdVideo.goal && (tvlAdVideoUtils.isCpmGoal(vm.facebookInstagramAdVideo.goal) ||
                tvlAdVideoUtils.isCpvGoal(vm.facebookInstagramAdVideo.goal))) {
                // var attachmentType = _.get(post, 'attachment.type', null);
                // if (!attachmentType) {
                //     attachmentType = _.get(post, 'media_type', null);
                //     //clean ig_ prefix from ig posts media type
                //     attachmentType = attachmentType.substr(3);
                // }
                return null !== attachmentType &&
                    tvlAdVideoUtils.getFacebookPostTypesByOptimizationGoal(vm.facebookInstagramAdVideo.facebookOptimizationGoal).includes(attachmentType);
            }
            return true;
        };

        vm.isFacebookElegiblePost = function(post){
            return post.is_elegible !== 'ineligible';
        };

        function getAvailableFacebookAdVideos() {
            vm.ui.loadingFacebookAdVideos = true;

            switch (vm.ui.adVideoSource) {
                case tvlAdVideoUtils.FACEBOOK_VIDEO_SOURCE_FROM_MEDIA:
                    return FacebookBusiness.getAdVideos({
                        accountId: vm.accountId,
                        adAccountId: vm.adAccountId,
                        adPosition: vm.facebookInstagramAdVideo.facebookPositions
                    })
                        .$promise
                        .then(onGetAdVideosSuccess)
                        .catch(onGetAdVideosError)
                        .finally(onGetAdVideosFinally);
                case tvlAdVideoUtils.FACEBOOK_VIDEO_SOURCE_FROM_MEDIA_PHOTO:
                    return FacebookBusiness.getAdImages({
                        accountId: vm.accountId,
                        adAccountId: vm.adAccountId,
                        adPosition: vm.facebookInstagramAdVideo.facebookPositions
                    })
                        .$promise
                        .then(onGetAdVideosSuccess)
                        .catch(onGetAdVideosError)
                        .finally(onGetAdVideosFinally);
                    //forced fields for carrousel Ads
                default: vm.ui.loadingFacebookAdVideos = false;
                         vm.ui.facebookAdVideoAccessible = true;
            }

            function onGetAdVideosSuccess(adVideos) {
                if (vm.facebookInstagramAdVideo.type === tvlAdVideoUtils.FACEBOOK_CREATIVE_POST_PHOTO) {
                    adVideos = tvlAdVideoUtils.normalizeFacebookAdImagesData(adVideos);
                }

                vm.availableFacebookAdVideos = adVideos;
                vm.ui.facebookAdVideoAccessible = true;
            }

            function onGetAdVideosError() {
                toastr.error(
                    'Our apologies, we have been unable to find ad videos for this Facebook account.'
                );
            }

            function onGetAdVideosFinally() {
                vm.ui.loadingFacebookAdVideos = false;
            }
        }

        function onFacebookPageChange(newVal, oldVal) {
            if (newVal === oldVal) {
                return;
            }
            vm.availableFacebookPosts = [];
            vm.availableInstagramPosts = [];
            vm.instagramAccount = null;
            resetFacebookLinkInfo();
            getAssociatedInstragtamId();

            vm.beforePagePostRequest = null;

            if (vm.facebookPage) {
                if (tvlAdVideoUtils.isFacebookAnyAdVideoFormat(vm.facebookInstagramAdVideo.format)) {
                    vm.availableFacebookPosts = [];

                    if (vm.ui.adVideoSource === tvlAdVideoUtils.FACEBOOK_VIDEO_SOURCE_FROM_POST) {
                        getAvailableFacebookPosts(vm.accountId, vm.facebookPage.id, vm.facebookInstagramAdVideo.goal);
                    } else {
                        var both = (vm.facebookInstagramAdVideo.goal !== 'views');
                        getAvailableInstagramPosts(vm.accountId, vm.facebookPage.id, both);
                    }

                } else if (tvlAdVideoUtils.isFacebookExternalAdVideoFormat(vm.facebookInstagramAdVideo.format) || tvlAdVideoUtils.isFacebookCarouselExternalAdVideoFormat(vm.facebookInstagramAdVideo.format)) {
                    vm.facebookInstagramAdVideo.videoUrl = '';
                    vm.facebookInstagramAdVideo.postType = '';
                    vm.ui.isFacebookPostMultiShare = false;
                    vm.facebookInstagramAdVideo.pageId = vm.facebookPage.id;
                    getAvailableFacebookAdVideos();
                }
                else {
                    vm.facebookInstagramAdVideo.pageId = vm.facebookPage.id;
                }
                vm.facebookPost = null;
                vm.postIdToSearch = null;
                vm.postNotFound = false;
                vm.sugestedPost = null;
                vm.postFoundNotValid = null;
                vm.facebookInstagramAdVideo.videoUrl = '';
                vm.facebookInstagramAdVideo.postType = '';
                vm.facebookAdVideo = null;
                vm.ui.isFacebookPostMultiShare = false;
            }
        }

        function onFacebookPostChange(newVal, oldVal) {
            if (newVal === oldVal) {
                return;
            }

            resetFacebookLinkInfo();

            if (vm.facebookPost) {
                if (vm.facebookPost.sugested_id) {
                    vm.postNotFound = true;
                    vm.sugestedPost = vm.facebookPost.promotable_id.split('_')[1];
                    return;
                }

                var attachementType = (vm.facebookPost.attachment && vm.facebookPost.attachment.type) ? vm.facebookPost.attachment.type : null;
                vm.ui.isFacebookPostMultiShare = (attachementType && attachementType === tvlAdVideoUtils.FACEBOOK_CREATIVE_POST_MULTI_SHARE);

                var facebookDomainUrlWithProtocol = 'https://www.facebook.com/';
                vm.facebookInstagramAdVideo.postId = vm.facebookPost.id;

                if (vm.ui.adVideoSource === vm.tvlAdVideoUtils.FACEBOOK_VIDEO_SOURCE_FROM_INSTAGRAM_POST) {
                    vm.facebookInstagramAdVideo.postType = vm.facebookPost.media_type;
                    vm.facebookInstagramAdVideo.videoUrl = vm.facebookPost.media_url;
                    vm.facebookInstagramAdVideo.thumbnailUrl = vm.facebookPost.thumbnail_url;
                    vm.facebookInstagramAdVideo.pageId = vm.facebookPage.id;
                    vm.facebookInstagramAdVideo.InstagramBusinessId = vm.instagramAccount.id;
                    vm.facebookInstagramAdVideo.name = vm.facebookPost.caption;
                    vm.ui.isFacebookPostMultiShare = false;
                } else {
                    vm.facebookInstagramAdVideo.postType = vm.facebookPost.attachment.type;
                    vm.facebookInstagramAdVideo.videoUrl = (vm.facebookPost.attachment.target.id) ?
                        facebookDomainUrlWithProtocol + vm.facebookPost.attachment.target.id :
                        facebookDomainUrlWithProtocol + vm.facebookPost.id;
                }


            }
        }

        function onFacebookPostIdChange(newVal, oldVal) {
            if (newVal === oldVal) {
                return;
            }
            vm.postFoundNotValid = null;
            vm.postNotFound = false;
            vm.sugestedPost = null;
            var goal = (vm.facebookInstagramAdVideo.goal === vm.tvlAdVideoUtils.GOAL_ACTION) ?
                vm.tvlAdVideoUtils.GOAL_IMPRESSIONS:
                vm.facebookInstagramAdVideo.goal;

            var params = {
                accountId: vm.accountId,
                pageId: vm.facebookPage.id,
                postId: vm.postIdToSearch,
                goal: goal
            };
            //Post ids is greater than 14 length?, ask to the api just when length matched
            if (vm.postIdToSearch.length > 14) {
                vm.facebookPost = null;
                vm.ui.isFacebookPostMultiShare = false;
                vm.postFoundNotValid = null;
                return FacebookBusiness.getAccessiblePosts(params)
                    .$promise
                    .then(onGetPostSuccess)
                    .catch(onGetPostError)
                    .finally(onGetPostFinally);
            }

            function onGetPostSuccess(post) {
                vm.postNotFound = false;

                var type = vm.getFacebookTypePost(post['data'][0][0]);
                if (type.hasError) {
                    vm.facebookPost = null;
                    vm.ui.isFacebookPostMultiShare = false;
                    vm.facebookInstagramAdVideo.videoUrl = '';
                    vm.facebookInstagramAdVideo.postType = '';
                    vm.facebookAdVideo = null;
                    vm.postFoundNotValid = type.description;
                } else {
                    vm.facebookPost = post['data'][0][0];
                }
                if (!post['data'][0][0]['sugested_id']) {
                    vm.availableFacebookPosts = _.unionBy(vm.availableFacebookPosts, post['data'][0], 'id');
                }
            }
            function onGetPostFinally() {
            }
            function onGetPostError() {
                vm.postNotFound = true;
                vm.sugestedPost = null;
                vm.facebookInstagramAdVideo.videoUrl = '';
                vm.facebookInstagramAdVideo.postType = '';
                vm.facebookAdVideo = null;
            }
        }

        function onFacebookAdVideoChange(newVal, oldVal) {
            if (newVal === oldVal) {
                return;
            }

            if(vm.facebookAdVideo) {
                vm.facebookInstagramAdVideo.videoId = vm.facebookAdVideo.id;
                vm.facebookInstagramAdVideo.imageUrl = vm.facebookAdVideo.picture;
                vm.facebookInstagramAdVideo.name = vm.facebookAdVideo.name;
                //todo check if we really need this field for Facebook
                vm.videosFound.facebookOrInstagram = true;
            }
        }

        function onFacebookObjectiveChange(newVal, oldVal)
        {
            if (newVal === oldVal || !newVal) {
                return;
            }
            vm.facebookInstagramAdVideo.facebookObjective = vm.facebookObjective.value;
        }

        function facebookOptimizationGoalChange(newVal, oldVal)
        {
            if (newVal === oldVal) {
                return;
            }

            vm.facebookInstagramAdVideo.callToActionButton = null;
            vm.facebookInstagramAdVideo.facebookObjective = null;
            vm.facebookObjective = null;

            if (newVal && newVal.value && newVal.value === tvlAdVideoUtils.FACEBOOK_OPTIMIZATION_GOAL_POST_ENGAGEMENT) {
                //this goal is not compatible with multi_share types so we recreate the post dropdown.
                vm.facebookPost = null;
                vm.facebookInstagramAdVideo.postId = null;
                vm.showFacebookInstagramAdVideoPreview = false;
                //todo check if we really need this field for Facebook
                vm.videosFound.facebookOrInstagram = false;
                vm.ui.isFacebookPostMultiShare = false;
                getFacebookCallToActionButtonValues(tvlAdVideoUtils.FACEBOOK_OPTIMIZATION_GOAL_POST_ENGAGEMENT);
            } else {
                getFacebookCallToActionButtonValues(vm.tvlAdVideoUtils.GOAL_IMPRESSIONS);
            }

            vm.facebookInstagramAdVideo.facebookFrequencyCapIntervalDays = (newVal && newVal.value && tvlAdVideoUtils.FACEBOOK_OPTIMIZATION_GOAL_REACH === newVal.value) ?
                tvlAdVideoUtils.FACEBOOK_MIN_FREQUENCY_CAP_INTERVAL_DAYS :
                null;
            vm.facebookInstagramAdVideo.facebookFrequencyCapImpressions = (newVal && newVal.value && tvlAdVideoUtils.FACEBOOK_OPTIMIZATION_GOAL_REACH === newVal.value) ?
                tvlAdVideoUtils.FACEBOOK_MIN_FREQUENCY_CAP_IMPRESSIONS :
                null;

            vm.facebookInstagramAdVideo.facebookOptimizationGoal = (vm.facebookOptimizationGoal) ? vm.facebookOptimizationGoal.value: null;
        }

        function onGoalChange() {
            if (tvlAdVideoUtils.isCpvGoal(vm.ui.selectedGoal)) {
                vm.youtubeAdVideo.format = tvlAdVideoUtils.ADVIDEO_FORMAT_ADWORDS_IN_STREAM;
            } else if (tvlAdVideoUtils.isCpmGoal(vm.ui.selectedGoal)) {
                vm.youtubeAdVideo.format = tvlAdVideoUtils.ADVIDEO_FORMAT_ADWORDS_IN_STREAM_TARGET_CPM;
            } else if (tvlAdVideoUtils.isCpaGoal(vm.ui.selectedGoal)) {
                vm.youtubeAdVideo.format = tvlAdVideoUtils.ADVIDEO_FORMAT_ADWORDS_TRUEVIEW_FOR_ACTION;
            } else {
                throw 'Unsupported goal!';
            }
        }

        function onFacebookAdVideoSourceChange(newVal, oldVal) {

            if (newVal === oldVal) {
                return;
            }
            vm.availableFacebookPages = facebookPages;
            vm.facebookPage = null;
            vm.facebookPost = null;
            vm.ui.isFacebookPostMultiShare = false;
            vm.postIdToSearch = null;
            vm.postNotFound = false;
            vm.sugestedPost = null;
            vm.postFoundNotValid = null;
            vm.facebookAdVideo = null;
            vm.facebookInstagramAdVideo.videoUrl = '';
            vm.facebookInstagramAdVideo.postType = '';
            vm.availableFacebookPosts = null;
            vm.instagramAccount = null;

            if (vm.ui.adVideoSource === vm.tvlAdVideoUtils.FACEBOOK_VIDEO_SOURCE_FROM_INSTAGRAM_POST) {
                window.checkLoginState();
            }
        }

        function facebookVideoTargetUrlChange() {
            if (vm.ui.facebookVideoTargetUrlOption === false) {
                vm.facebookInstagramAdVideo.targetUrl = '';
                vm.facebookInstagramAdVideo.displayUrl = '';
                vm.facebookInstagramAdVideo.linkUrl = '';
                vm.facebookInstagramAdVideo.linkTitle = '';
                vm.facebookInstagramAdVideo.linkDescription = '';
                vm.facebookInstagramAdVideo.trackingUrl = '';
                vm.facebookInstagramAdVideo.callToActionButton = null;
            }
        }

        // Selected Network checks
        function isYoutubeNetwork() {
            return tvlConstantUtils.isNetworkYoutube(vm.videoNetwork);
        }
        function isFacebookNetwork() {
            return tvlConstantUtils.isNetworkFacebook(vm.videoNetwork);
        }
        function isInstagramNetwork() {
            return tvlConstantUtils.isNetworkInstagram(vm.videoNetwork);
        }

        /**
         * We don't allow TrueView for action formats after any of the other formats are created.
         * @returns {boolean}
         */
        function areFormatsCompatible() {
            if (vm.youtubeAdVideo.isTrueViewForAction() && (vm.youtubeNonTrueViewForActionAdExists || !vm.isConversionEnabled)) {
                return false;
            }
            return true;
        }

        function isFacebookPhotoPost(postType) {
            return postType.toLowerCase().indexOf(tvlAdVideoUtils.FACEBOOK_CREATIVE_POST_PHOTO) !== -1 || postType.toLowerCase().indexOf(tvlAdVideoUtils.INSTAGRAM_CREATIVE_POST_PHOTO) !== -1;
        }

        function isFacebookVideoPost(postType) {
            return postType.toLowerCase().indexOf(tvlAdVideoUtils.FACEBOOK_CREATIVE_POST_VIDEO) !== -1 || postType.toLowerCase().indexOf(tvlAdVideoUtils.INSTAGRAM_CREATIVE_POST_VIDEO) !== -1;
        }
        function isFacebookSharePost(postType) {
            return postType.toLowerCase() === tvlAdVideoUtils.FACEBOOK_CREATIVE_POST_SHARE;
        }
        function isFacebookMultiSharePost(postType) {
            return postType.toLowerCase() === tvlAdVideoUtils.FACEBOOK_CREATIVE_POST_MULTI_SHARE;
        }

        vm.getFacebookTypePost = function(post) {
            var postType = _.get(post, 'attachment.type', null);

            if (!postType) {
                postType = _.get(post, 'media_type', null);
                //clean ig_ prefix from ig posts media type
                postType = postType.substr(3);
            }


            var type = {
                icon: 'fa-ban',
                description: 'Post type (' + postType + ') not supported yet.',
                hasError: true
            };

            if (false === vm.isFacebookElegiblePost(post)) {
                type.description = 'Post can not be used as ad.';
                type.hasError = true;

                return type;
            }

            //for facebook cpm optimization goals
            if (false === vm.isFacebookPostTypeSelectableByOptimizationGoal(postType) && tvlAdVideoUtils.isCpmGoal(vm.facebookInstagramAdVideo.goal)) {
                type.description = 'Post type (' + postType + ') not supported for this ad delivery.';
                type.hasError = true;

                return type;
            }

            if (false === vm.isFacebookSelectablePost(post)) {
                type.description = 'Post type (' + postType + ') not supported yet for this objective.';
                type.hasError = true;

                return type;
            }

            switch (true) {
                case isFacebookPhotoPost(postType):
                    type = {
                        icon: 'fa-camera',
                        description: 'Photo',
                        hasError: false
                    };
                    break;
                case isFacebookVideoPost(postType):
                    type = {
                        icon: 'fa-video-camera',
                        description: 'Video',
                        hasError: false
                    };
                    break;
                case isFacebookSharePost(postType):
                    type = {
                        icon: 'fa-share-square',
                        description: 'Share',
                        hasError: false
                    };
                    break;
                case isFacebookMultiSharePost(postType):
                    type = {
                        icon: 'fa-columns',
                        description: 'Carousel',
                        hasError: false
                    };
                    break;
            }

            return type;
        };

        function facebookPositionsTypeChange() {
            vm.facebookInstagramAdVideo.facebookPositions = [];
            vm.facebookInstagramAdVideo.instagramPositions = [];
        }

        function onFacebookAssetSourceChange(newVal, oldVal) {
            if (newVal === oldVal) {
                return;
            }
            vm.facebookAdVideo = null;
        }

        function showFacebookPrimaryText() {

            return (tvlAdVideoUtils.isFacebookExternalAdVideoFormat(vm.facebookInstagramAdVideo.format) &&
                vm.facebookPage !== null &&
                !vm.ui.loadingFacebookAdVideos &&
                vm.availableFacebookAdVideos.length > 0) ||
                tvlAdVideoUtils.isCpcGoal(vm.facebookInstagramAdVideo.goal) &&
                vm.ui.isFacebookPostMultiShare === false &&
                false === tvlAdVideoUtils.isFacebookCarouselExternalAdVideoFormat(vm.facebookInstagramAdVideo.format);
        }

        function getAssociatedInstragtamId() {

            return FacebookBusiness.getAssociatedIGId({accountId: vm.accountId, pageId: vm.facebookPage.id})
                .$promise
                .then(onGetSuccess)
                .catch(resourceResponse.error)
                .finally(onGetFinally);

            function onGetSuccess(response) {
                vm.InstagramId = response.IgId;
                vm.facebookInstagramAdVideo.instagramId = response.IgId;
            }

            function onGetFinally() {
                // vm.ui.loadingFacebookPosts = false;
            }

        }

        function resetFacebookLinkInfo() {
            vm.facebookInstagramAdVideo.description = '';
            vm.facebookInstagramAdVideo.targetUrl = '';
            vm.facebookInstagramAdVideo.displayUrl = '';
            vm.facebookInstagramAdVideo.linkUrl = '';
            vm.facebookInstagramAdVideo.linkTitle = '';
            vm.facebookInstagramAdVideo.linkDescription = '';
            vm.facebookInstagramAdVideo.trackingUrl = '';
            vm.facebookInstagramAdVideo.callToActionButton = null;
        }

        function onFacebookAdSourceClick(format, sourceType) {

            if (format && vm.facebookInstagramAdVideo) {
                vm.facebookInstagramAdVideo.format = format;
            }

            if (sourceType && vm.facebookInstagramAdVideo ) {
                $timeout(function() {
                    vm.facebookInstagramAdVideo.type = sourceType;
                }, 0);
            }

            updateFacebookOptimizationGoalOptionsForCpm();
        }

        function updateFacebookOptimizationGoalOptionsForCpm() {
            vm.facebookOptimizationGoal = null;
            vm.facebookOptimizationGoalOptionsForCpm = tvlAdVideoUtils.getFacebookOptimizationGoalOptionsForCpm(vm.ui.adVideoSource);
        }

        function isAdVideoFacebookPhoto() {
            return (vm.facebookInstagramAdVideo && vm.facebookInstagramAdVideo.type === vm.tvlAdVideoUtils.FACEBOOK_CREATIVE_POST_PHOTO);
        }
        function isAdVideoFacebookVideo() {
            return (vm.facebookInstagramAdVideo && vm.facebookInstagramAdVideo.type === vm.tvlAdVideoUtils.FACEBOOK_CREATIVE_POST_VIDEO);
        }

        /**
         * Disable modal controls when necessary.
         * @returns {boolean}
         */
        function isModalBlocked() {

            return vm.ui.loadingFacebookAdVideos || vm.ui.assetsUploadInProgress;
        }
    }
}());
