const Highcharts = require('highcharts/highstock.src');
require('highcharts/modules/map.src')(Highcharts);
Highcharts.maps["custom/world"] = require('@highcharts/map-collection/custom/world.geo');
Highcharts.maps["countries/ar/ar-all"] = require('@highcharts/map-collection/countries/ar/ar-all.geo');
Highcharts.maps["countries/au/au-all"] = require('@highcharts/map-collection/countries/au/au-all.geo');
Highcharts.maps["countries/be/be-all"] = require('@highcharts/map-collection/countries/be/be-all.geo');
Highcharts.maps["countries/bo/bo-all"] = require('@highcharts/map-collection/countries/bo/bo-all.geo');
Highcharts.maps["countries/br/br-all"] = require('@highcharts/map-collection/countries/br/br-all.geo');
Highcharts.maps["countries/cl/cl-all"] = require('@highcharts/map-collection/countries/cl/cl-all.geo');
Highcharts.maps["countries/co/co-all"] = require('@highcharts/map-collection/countries/co/co-all.geo');
Highcharts.maps["countries/cr/cr-all"] = require('@highcharts/map-collection/countries/cr/cr-all.geo');
Highcharts.maps["countries/do/do-all"] = require('@highcharts/map-collection/countries/do/do-all.geo');
Highcharts.maps["countries/ec/ec-all"] = require('@highcharts/map-collection/countries/ec/ec-all.geo');
Highcharts.maps["countries/es/es-all"] = require('@highcharts/map-collection/countries/es/es-all.geo');
Highcharts.maps["countries/fr/custom/fr-all-mainland"] = require('@highcharts/map-collection/countries/fr/custom/fr-all-mainland.geo');
Highcharts.maps["countries/gb/custom/gb-countries"] = require('@highcharts/map-collection/countries/gb/custom/gb-countries.geo');
Highcharts.maps["countries/gt/gt-all"] = require('@highcharts/map-collection/countries/gt/gt-all.geo');
Highcharts.maps["countries/ht/ht-all"] = require('@highcharts/map-collection/countries/ht/ht-all.geo');
Highcharts.maps["countries/jm/jm-all"] = require('@highcharts/map-collection/countries/jm/jm-all.geo');
Highcharts.maps["countries/mx/mx-all"] = require('@highcharts/map-collection/countries/mx/mx-all.geo');
Highcharts.maps["countries/nl/nl-all"] = require('@highcharts/map-collection/countries/nl/nl-all.geo');
Highcharts.maps["countries/pa/pa-all"] = require('@highcharts/map-collection/countries/pa/pa-all.geo');
Highcharts.maps["countries/pe/pe-all"] = require('@highcharts/map-collection/countries/pe/pe-all.geo');
Highcharts.maps["countries/pe/pe-all"] = require('@highcharts/map-collection/countries/pe/pe-all.geo');
Highcharts.maps["countries/pr/pr-all-all"] = require('@highcharts/map-collection/countries/pr/pr-all-all.geo');
Highcharts.maps["countries/pr/pr-all"] = require('@highcharts/map-collection/countries/pr/pr-all.geo');
Highcharts.maps["countries/pt/pt-all"] = require('@highcharts/map-collection/countries/pt/pt-all.geo');
Highcharts.maps["countries/py/py-all"] = require('@highcharts/map-collection/countries/py/py-all.geo');
Highcharts.maps["countries/ru/ru-all"] = require('@highcharts/map-collection/countries/ru/ru-all.geo');
Highcharts.maps["countries/us/us-all"] = require('@highcharts/map-collection/countries/us/us-all.geo');
Highcharts.maps["countries/uy/uy-all"] = require('@highcharts/map-collection/countries/uy/uy-all.geo');
Highcharts.maps["countries/ca/ca-all"] = require('@highcharts/map-collection/countries/ca/ca-all.geo');
Highcharts.maps["countries/in/in-all"] = require('@highcharts/map-collection/countries/in/in-all.geo');
Highcharts.maps["countries/jp/jp-all"] = require('@highcharts/map-collection/countries/jp/jp-all.geo');
Highcharts.maps["countries/kr/kr-all"] = require('@highcharts/map-collection/countries/kr/kr-all.geo');
Highcharts.maps["countries/sg/sg-all"] = require('@highcharts/map-collection/countries/sg/sg-all.geo');
Highcharts.maps["countries/de/de-all"] = require('@highcharts/map-collection/countries/de/de-all.geo');
Highcharts.maps["countries/it/it-all"] = require('@highcharts/map-collection/countries/it/it-all.geo');
Highcharts.maps["countries/lb/lb-all"] = require('@highcharts/map-collection/countries/lb/lb-all.geo');
Highcharts.maps["countries/il/il-all"] = require('@highcharts/map-collection/countries/il/il-all.geo');
Highcharts.maps["countries/za/za-all"] = require('@highcharts/map-collection/countries/za/za-all.geo');
Highcharts.maps["countries/kr/kr-all"] = require('@highcharts/map-collection/countries/kr/kr-all.geo');
Highcharts.maps["countries/ae/ae-all"] = require('@highcharts/map-collection/countries/ae/ae-all.geo');

(function() {
    angular
        .module('tvl.common')
        .directive('tvlMap', tvlMap);

    /** @ngInject */
    function tvlMap($timeout) {
        var directive = {
            restrict: 'EA',
            scope : {
                map: '@',
                mapData: '=',
                mapTitle: '@?',
                labelName: '@',
                enableRegionLabels: '='
            },
            template: '<div class="text-center" id="map" style="height: 100%; width: 100%"></div>',
            link: link
        };
        return directive;

        function link($scope, element, attrs) {

            $scope.$on('loadViewsMap', function() {
                var maxValue = 0;
                angular.forEach($scope.mapData, function(data){
                    maxValue = Math.max(maxValue, data['value']);
                });
                $timeout(function() {
                    $(function() {
                        Highcharts.Map('map', {
                            title: {
                                text: $scope.mapTitle
                            },

                            mapNavigation: {
                                enabled: true,
                                enableButtons: false
                            },

                            legend: {
                                layout: 'vertical',
                                align: 'left',
                                verticalAlign: 'bottom'
                            },

                            colorAxis: {
                                min: 0,
                                max: maxValue,
                                stops: [
                                    [0, '#EFEFFF'],
                                    [0.5, Highcharts.getOptions().colors[0]],
                                    [1, Highcharts.Color(Highcharts.getOptions().colors[0]).brighten(-0.5).get()]
                                ]
                            },

                            credits: {
                                enabled: false
                            },

                            exporting: {
                                enabled: false
                            },

                            series: [{
                                data: $scope.mapData,
                                mapData: Highcharts.maps[$scope.map],
                                joinBy: 'hc-key',
                                name: $scope.labelName,
                                dataLabels: {
                                    enabled: $scope.enableRegionLabels,
                                    format: '{point.name}'
                                }
                            }]
                        });
                    });
                }, 700);
            });
        }
    }
})();
