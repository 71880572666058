(function() {
    'use strict'

    angular.module('tvl.common')
        .directive('tvlSearcher', tvlSearcher);

    tvlSearcher.$inject = ['$q', 'Channel', 'Video'];

    /**
     * A search input component which conducts searches on TVL as well as
     * external platforms.
     *
     * It allows searching for channels and videos. Additional types of items
     * might be added in the future.
     *
     * This is just the input component. Results are not displayed by this
     * directive. This must be handled with custom code or with another
     * directive.
     *
     * Communication is done via events:
     * - Whenever a search is completed, the `tvl.searcher.searchComplete`
     * event will fire containing a hash of results organized by platform.
     * - If one of the searches fails, the `tvl.searcher.searchError` event
     * will fire with the error.
     * - If the user manually clears the search, the `tvl.searcher.searchCleared`
     * event will be emitted.
     *
     * The search term can be externally modified by binding to the `term`
     * scope property. Changes to this property will automatically trigger a
     * new search.
     *
     * Platforms is an array of all platforms where we want to search, if it is not
     * given we will search in all of these (theviralab, youtube, facebook)
     *
     * @ngInject
     */
    function tvlSearcher($q, Channel, Video) {
        return {
            restrict: 'EA',
            replace: true,
            scope: {
                type: '=?',
                term: '=?',
                platforms: '=?',
                required: '=?'
            },
            templateUrl: 'common/searcher.html',
            link: function ($scope, element, attrs) {
                if (['channel', 'video'].indexOf($scope.type) < 0) {
                    throw 'Invalid search type "' + $scope.type + '"';
                }

                $scope.searchTarget = 'theviralab';
                $scope.searchTerm = null;
                $scope.unregisterFns = [];
                $scope.ui = {
                    searching: false
                };

                $scope.clearSearch = clearSearch;
                $scope.search = search;

                //////

                activate();

                function activate() {
                    if (typeof $scope.type === 'undefined') {
                        $scope.type = "channel";
                    }

                    if (typeof $scope.required === 'undefined') {
                        $scope.required = true;
                    }

                    if (typeof $scope.platforms === 'undefined') {
                        $scope.platforms = ['theviralab', 'youtube', 'facebook'];
                    }

                    $scope.unregisterFns.push($scope.$watch('term', onTermChanged));
                    $scope.unregisterFns.push($scope.$on('tvl.searcher.launchSearch', search));
                    $scope.$on('$destroy', onDestroy);
                }

                function clearSearch() {
                    $scope.searchTerm = null;
                    $scope.$emit('tvl.searcher.searchCleared');
                }

                function search(event, params) {
                    var promise = null;
                    var platform = null;
                    var isFirstPage = true;

                    if (!$scope.searchTerm) {
                        return $q.when($scope.searchTerm);
                    }

                    if ($scope.type === 'channel') {
                        promise = runChannelSearch(params);
                    } else {
                        promise = runVideoSearch(params);
                    }

                    if (!params) {
                        $scope.ui.searching = true;
                    } else {
                        platform = params.platform;
                        isFirstPage = false;
                    }

                    return promise.then(onSearchSuccess.bind(null, isFirstPage))
                        .catch(onSearchError.bind(null, platform, isFirstPage))
                        .finally(onSearchFinally);
                }

                function onSearchSuccess(isFirstPage, results) {
                    // sort keys in the order they should be presented on screen
                    var sortedResults = {};
                    ['theviralab', 'youtube', 'facebook'].forEach(function (key) {
                        if (results.hasOwnProperty(key)) {
                            sortedResults[key] = results[key];
                        }
                    });

                    var response = {
                        results: sortedResults,
                        isFirstPage: isFirstPage
                    };

                    $scope.$emit('tvl.searcher.searchComplete', response);
                }

                function onSearchError(platform, isFirstPage, error) {
                    var response = {
                        error: error,
                        platform: platform,
                        isFirstPage: isFirstPage
                    };

                    $scope.$emit('tvl.searcher.searchError', response);
                }

                function onSearchFinally() {
                    $scope.ui.searching = false;
                }

                /**
                 * Normalize and return search results from Theviralab.
                 *
                 * @param {Object} data
                 * @result {Object}
                 */
                function processTheviralabResults(data) {
                    return {
                        items: data.items,
                        total: data.totalItems,
                        nextPage: data.currentPage < data.endPage ? data.currentPage + 1 : null,
                        resultsPerPage: data.itemsPerPage
                    };
                }

                /**
                 * Normalize and return search results from YouTube.
                 *
                 * @param {Object} data
                 * @result {Object}
                 */
                function processYoutubeResults(data) {
                    return {
                        items: data.items,
                        total: data.pageInfo.totalResults,
                        nextPage: data.nextPageToken,
                        resultsPerPage: data.pageInfo.resultsPerPage
                    };
                }

                /**
                 * Normalize and return search results from Facebook.
                 *
                 * @param {Object} data
                 * @result {Object}
                 */
                function processFacebookResults(data) {
                    data.items.forEach(function (item) {
                        item.id = item.channelId;
                    });
                    return processYoutubeResults(data);
                }

                /**
                 * Launch channel searches on platforms, and return a
                 * promise that will be fulfilled when all searches finish.
                 *
                 * @return {Promise}
                 */
                function runChannelSearch(params) {
                    var promises = {};

                    var platforms = params && params.platform ? [params.platform] : $scope.platforms;
                    var page = params && params.page ? params.page : null;

                    // theviralab
                    if (platforms.indexOf('theviralab') > -1) {
                        promises.theviralab = searchTvlChannels(page);
                    }

                    // youtube
                    if (platforms.indexOf('youtube') > -1) {
                        promises.youtube = searchYoutubeChannels(page);
                    }

                    return $q.all(promises);
                }

                function searchTvlChannels(page) {
                    var params = {term: $scope.searchTerm};
                    if ($scope.platforms.indexOf('facebook') === -1) {
                        params.platform = 'youtube';
                    } else if ($scope.platforms.indexOf('youtube') === -1) {
                        params.platform = 'facebook';
                    }

                    if (page) {
                        params.page = page;
                    }

                    return Channel.get(params).$promise.then(processTheviralabResults);
                }

                function searchYoutubeChannels(page) {
                    var params = {term: $scope.searchTerm, platform: 'youtube'};

                    if (page) {
                        params.pageToken = page;
                    }

                    return Channel.searchPlatformChannels(params).$promise.then(processYoutubeResults);
                }

                /**
                 * Launch video searches on platforms, and return a
                 * promise that will be fulfilled when all searches finish.
                 *
                 * @return {Promise}
                 */
                function runVideoSearch(params) {
                    var promises = {};

                    var platforms = params && params.platform ? [params.platform] : $scope.platforms;
                    var page = params && params.page ? params.page : null;

                    // theviralab
                    if (platforms.indexOf('theviralab') > -1) {
                        promises.theviralab = searchTvlVideos(page);
                    }

                    // youtube
                    if (platforms.indexOf('youtube') > -1) {
                        promises.youtube = searchYoutubeVideos(page);
                    }

                    // facebook (Allows to search only one video by id)
                    if (platforms.indexOf('facebook') > -1) {
                        promises.facebook = searchFacebookVideos(page);
                    }

                    return $q.all(promises);
                }

                function searchTvlVideos(page) {
                    var params = {term: $scope.searchTerm};
                    if ($scope.platforms.indexOf('facebook') === -1) {
                        params.platform = 'youtube';
                    } else if ($scope.platforms.indexOf('youtube') === -1) {
                        params.platform = 'facebook';
                    }

                    if (page) {
                        params.page = page;
                    }

                    return Video.get(params).$promise.then(processTheviralabResults);
                }

                function searchYoutubeVideos(page) {
                    var params = {term: $scope.searchTerm, platform: 'youtube'};

                    if (page) {
                        params.pageToken = page;
                    }

                    return Video.searchPlatformVideos(params).$promise.then(processYoutubeResults);
                }

                function searchFacebookVideos(page) {
                    var params = {term: $scope.searchTerm, platform: 'facebook'};

                    if (page) {
                        params.pageToken = page;
                    }

                    return Video.searchPlatformVideos(params).$promise.then(processFacebookResults);
                }

                /**
                 * Callback function invoked whenever the exposed search term
                 * scope variable is modified.
                 *
                 * This will launch a search with the given term.
                 */
                function onTermChanged(newVal, oldVal) {
                    if (newVal !== undefined && newVal !== null && newVal.length > 0) {
                        $scope.searchTerm = newVal;
                        return search();
                    }
                }

                function onDestroy() {
                    angular.forEach($scope.unregisterFns, function (unregisterFn) {
                        unregisterFn();
                    });
                }
            }
        };
    }
})();
