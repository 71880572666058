(function() {
    angular
        .module('tvl.common')
        .directive('tvlListDetailContainer', tvlListDetailContainer);

    /** @ngInject */
    function tvlListDetailContainer() {
        var directive = {
            restrict: 'EA',
            transclude: true,
            templateUrl: "common/list-detail-container.html"
        };
        return directive;
    }
})();
