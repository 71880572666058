(function() {
    'use strict';

    angular
        .module('tvl.common')
        .directive('validHttps', validHttps);

    validHttps.$inject = ['tvlUrl'];

    /* @ngInject */
    function validHttps(tvlUrl) {
        return {
            restrict: 'A',
            require: 'ngModel',
            replace: false,
            link: function(scope, elm, attrs, ctrl) {
                ctrl.$validators.validHttps = function(modelValue, viewValue) {
                    var isValid = false;

                    if (modelValue === '') {
                        modelValue = null;
                    }

                    if (null === modelValue || isValidUrl(modelValue)) {
                        isValid = true;
                    }

                    /**
                     * @param modelValue
                     *
                     * @return {Boolean}
                     */
                    function isValidUrl(modelValue) {
                        return null !== modelValue && tvlUrl.isValidSecured(modelValue);
                    }

                    return isValid;
                };


            }
        };
    }
})();
