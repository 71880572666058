(function() {
    'use strict';

    angular
        .module('tvl.brandSafety')
        .service('tvlRemoveListModal', tvlRemoveListModal);

    tvlRemoveListModal.$inject = ['$uibModal'];

    /**
     * A service to interact with the list removal modal.
     *
     * @ngInject
     */
    function tvlRemoveListModal($uibModal) {
        return {
            openRemoveModal: openRemoveModal
        };

        /**
         * Open the list removal modal.
         *
         * This function returns a promise that will be resolved with the list to be removed
         *
         * This function also expects two parameters:
         *  - The list to be removed.
         *  - The tag to let the modal know what entity is being removed.
         *
         * @param list
         * @param tag
         * @return {Promise}
         */
        function openRemoveModal(list, tag) {
            var modal = $uibModal.open({
                templateUrl: 'brand-safety/remove-list-modal.html',
                controller: 'RemovalConfirmationModalController as vm',
                bindToController: true,
                    resolve: {
                        list: function () {
                            return list;
                        },
                        tag: function () {
                            return tag;
                        },

                    }
            });

            return modal.result;
        }
    }
}());
