(function () {
    'use strict';

    angular
        .module('tvl.campaign')
        .directive('tvlRuleCondition', tvlRuleCondition);

    tvlRuleCondition.$inject = ['RecursionHelper', 'tvlRules'];

    /** @ngInject */
    function tvlRuleCondition(RecursionHelper, tvlRules) {
        var directive = {
            compile: compile,
            link: link,
            restrict: 'E',
            templateUrl: 'campaign/rules/rule-condition.html',
            scope: {
                condition: '='
            }
        };
        return directive;

        function compile(element) {
            return RecursionHelper.compile(element, link);
        }

        function link(scope, element, attrs, controller, transcludeFn) {
            scope.tvlRules = tvlRules;
            scope.withIcon = scope.$eval($(element[0]).attr('with-icon'));
            if (scope.condition.type !== 'compound') {
                scope.conditionDefinition = tvlRules.getCondition(scope.condition.type);
            }
        }
    }
})();
