(function() {
    'use strict';

    angular
        .module('tvl.common')
        .directive('tvlCurrency', tvlCurrency);

    tvlCurrency.$inject = [];

    /* @ngInject */
    function tvlCurrency() {
        var directive = {
            link: link,
            restrict: 'EA',
            templateUrl: 'common/currency.html',
            scope: {
                currency: '=',
                value: '='
            }
        };
        return directive;

        function link(scope, element, attrs) {
            scope.symbol = getCurrencySymbol(scope.currency);
            scope.pos = getCurrencySymbolPosition(scope.currency);

            scope.$watch('currency', function(newValue, oldValue) {
                if (newValue !== oldValue) {
                    scope.symbol = getCurrencySymbol(scope.currency);
                    scope.pos = getCurrencySymbolPosition(scope.currency);
                }
            })
        }

        /**
         * Get the symbol belonging to the given
         * ISO 4217 currency code.
         *
         * @param {string} currency
         * @return {string}
         */
        function getCurrencySymbol(currency) {
            switch (currency.toLowerCase()) {
                case 'usd':
                case 'mxn':
                case 'cop':
                case 'ars':
                    return '$';
                case 'eur':
                    return '€';
                default:
                    return '';
            }
        }

        /**
         * Return whether the currency symbol goes
         * to the left (-1) or to the right (1) of the
         * number it is writen next to.
         *
         * @param {string} currency
         * @return {Number}
         */
        function getCurrencySymbolPosition(currency) {
            switch (currency.toLowerCase()) {
                case 'usd':
                case 'mxn':
                case 'cop':
                case 'ars':
                    return -1;
                case 'eur':
                    return 1;
            }
        }
    }
})();
