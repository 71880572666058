(function(){
    "use strict";

    angular
        .module("tvl.campaign")
        .directive("tvlKeywordsListPreview", tvlKeywordsListPreview);

    /* @ngInject */
    function tvlKeywordsListPreview() {

        return {
            link: link,
            restrict: 'EA',
            templateUrl: 'campaign/keywords-list-preview.html',
            scope: {
                keywords: '='
            }
        };

        function link(scope, element, attrs) {

            activate();

            function activate() {
                updateKeywordsLists();
                updateKeywordsItems();
            }

            /**
             * Get list elements from the keywords array
             */
            function updateKeywordsLists() {
                scope.keywordsLists = _.map(_.filter(scope.keywords, function(keyword){
                    return keyword.hasOwnProperty('list');
                }), 'list');
            }

            /**
             * Get ad-hoc keywords from array
             */
            function updateKeywordsItems() {
                var items = _.filter(scope.keywords, function(keyword){
                    return keyword.hasOwnProperty('items');
                });
                scope.keywordsItems = (items.length > 0 && items[0].hasOwnProperty('items')) ? items[0].items : [];
            }
        }
    }
})();
