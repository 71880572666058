(function() {
    'use strict';

    angular
    .module('tvl.campaign')
    .service('tvlRules', tvlRules);

    tvlRules.$inject = [];

    /**
     * A service with utility functions related to campaign rules and events.
     *
     * @ngInject
     */
    function tvlRules() {
        return {
            getCondition: getCondition,
            getConditionParameter: getConditionParameter,
            getAction: getAction,
            getActionParameter: getActionParameter,
            getAllConditions: getAllConditions,
            getAllActions: getAllActions
        };

        /**
         * Get a condition by its type.
         *
         * @param {String} type
         * @return {Object}
         * @throws Throws error if no condition exists with the given type.
         */
        function getCondition(type) {
            if (type === 'compound') {
                // compound conditions exist, but are not included in the set
                // of available conditions as they cannot be directly selected
                return {type: 'compound', icon: 'fa fa-fw fa-sitemap'};
            }
            var condition  = _.find(getAllConditions(), {type: type});
            if (!condition) {
                throw "No condition exists with type " + type;
            }
            return condition;
        }

        /**
         * Get a parameter by its name for the given condition.
         *
         * @param {String} conditionType
         * @param {String} parameter
         * @return {Object}
         * @throws Throws error if condition has no such parametr.
         */
        function getConditionParameter(conditionType, parameter) {
            var param = _.find(getCondition(conditionType).params, {name: parameter});
            if (!param) {
                throw "Condition " + conditionType + " has no parameter " + parameter;
            }
            return param;
        }

        /**
         * Get an action by its type.
         *
         * @param {String} type
         * @return {Object}
         */
        function getAction(type) {
            var action  = _.find(getAllActions(), {type: type});
            if (!action) {
                throw "No action exists with type " + type;
            }
            return action;
        }

        /**
         * Get a parameter by its name for the given action.
         *
         * @param {String} actionType
         * @param {String} parameter
         * @return {Object}
         * @throws Throws error if action has no such parametr.
         */
        function getActionParameter(actionType, parameter) {
            var param = _.find(getAction(actionType).params, {name: parameter});
            if (!param) {
                throw "Action " + actionType + " has no parameter " + parameter;
            }
            return param;
        }

        /**
         * Return all available conditions.
         *
         * @return {Array}
         */
        function getAllConditions() {
            return [
                {
                    type: 'itsRainy',
                    category: 'Weather',
                    name: 'It\'s rainy',
                    description: 'Triggers when local weather is rainy.',
                    icon: 'wi wi-fw wi-rain',
                    params: [
                        {
                            name: 'rainfallThreshold',
                            displayName: 'Minimum rainfall threshold',
                            unit: 'mm',
                            type: 'number',
                            required: false
                        }
                    ]
                },
                {
                    type: 'itsWindy',
                    category: 'Weather',
                    name: 'It\'s windy',
                    description: 'Triggers when local weather is windy, with an optional minimum wind speed.',
                    icon: 'wi wi-fw wi-cloudy-gusts',
                    params: [
                        {
                            name: 'windSpeed',
                            displayName: 'Minimum wind speed',
                            unit: 'km/h',
                            type: 'number',
                            required: false
                        }
                    ]
                },
                {
                    type: 'temperatureAbove',
                    category: 'Weather',
                    name: 'Temperature above value',
                    description: 'Triggers when local temperature exceeds the specified value.',
                    icon: 'wi wi-fw wi-thermometer',
                    params: [
                        {
                            name: 'temperature',
                            displayName: 'Minimum temperature',
                            unit: 'ºC',
                            type: 'number',
                            required: true
                        }
                    ]
                },
                {
                    type: 'temperatureBelow',
                    category: 'Weather',
                    name: 'Temperature below value',
                    description: 'Triggers when local temperature is lower than the specified value.',
                    icon: 'wi wi-fw wi-thermometer-exterior',
                    params: [
                        {
                            name: 'temperature',
                            displayName: 'Maximum temperature',
                            unit: 'ºC',
                            type: 'number',
                            required: true
                        }
                    ]
                }
            ];
        }

        /**
         * Return all available actions.
         *
         * @return {Array}
         */
        function getAllActions() {
            return [
                {
                    type: 'modifyBid',
                    name: 'Modify bid',
                    description: 'Increase or decrease the bid.',
                    icon: 'fa fa-fw fa-dollar',
                    params: [
                        {
                            name: 'amount',
                            displayName: 'Amount',
                            unit: '%',
                            type: 'number',
                            required: true
                        }
                    ]
                },
                // {
                //     type: 'modifyBudget',
                //     name: 'Modify daily budget',
                //     description: 'Increase or decrease the daily budget.',
                //     icon: 'fa fa-fw fa-credit-card',
                //     params: [
                //         {
                //             name: 'amount',
                //             displayName: 'Amount',
                //             unit: '%',
                //             type: 'number',
                //             required: true
                //         }
                //     ]
                // },
                {
                    type: 'pausePlacement',
                    name: 'Pause placement',
                    description: 'Halt delivery of ads on a placement while this rule is in effect, and keep them running when it is not.',
                    icon: 'fa fa-fw fa-pause',
                    params: []
                },
                {
                    type: 'startPlacement',
                    name: 'Start placement',
                    description: 'Enable delivery of ads on a placement while this rule is in effect, and pause them when it is not.',
                    icon: 'fa fa-fw fa-play',
                    params: []
                }
            ];
        }
    }
}());
