(function() {
    'use strict';

    angular
        .module('tvl.campaign')
        .directive('tvlIncrement', tvlIncrement);

    tvlIncrement.$inject = [];

    /* @ngInject */
    function tvlIncrement() {
        // Usage:
        //
        // Creates:
        //
        var directive = {
            link: link,
            restrict: 'EA',
            templateUrl: 'campaign/increment.html',
            scope: {
                dataset: '=',
                property: '@',
                first: '=',
                last: '='
            }
        };
        return directive;

        function link(scope, element, attrs) {
            scope.getBadgeClass = getBadgeClass;
            scope.getChevronDirection = getChevronDirection;
            scope.getIncrement = getIncrement;

            scope.upIsGood = !attrs.upIsGood || attrs.upIsGood === 'true';
            scope.unregisterDatasetWatcher = scope.$watch('dataset', function (newValue) {
                if (newValue && newValue.constructor !== Array) {
                    throw 'Provided dataset is invalid, expected an array';
                }
            });
            scope.$on('$destroy', onDestroy);

            function getBadgeClass() {
                var increment = getIncrement();
                if (increment === 0){
                    return 'info';
                } else if (increment > 0 && scope.upIsGood ||
                    increment < 0 && ! scope.upIsGood) {
                    return 'success';
                }
                return 'danger';
            }

            function getChevronDirection() {
                var increment = getIncrement();
                if (increment > 0) {
                    return 'up';
                } else if (increment < 0) {
                    return 'down';
                }
                return null;
            }

            function getIncrement() {
                var last = 0;
                var previous = 0;

                if (scope.dataset) {
                    if (scope.dataset.length > 1) {
                        last = scope.dataset[scope.dataset.length - 1];
                        previous = scope.dataset[scope.dataset.length - 2];
                    } else if (scope.dataset.length > 0) {
                        last = previous = scope.dataset[0];
                    }

                    if (scope.dataset.length && scope.property) {
                        last = last[scope.property];
                        previous = previous[scope.property];
                    }
                }

                return previous > 0 ? (last - previous) / previous * 100 : 0;
            }

            function onDestroy() {
                scope.unregisterDatasetWatcher();
            }
        }
    }
})();
