(function() {
    'use strict';

    angular
        .module('tvl.common')
        .directive('tvlVideoPreview', tvlVideoPreview);

    tvlVideoPreview.$inject = ['tvlYoutube', 'FacebookBusiness', 'tvlAdVideoUtils'];

    /** @ngInject */
    function tvlVideoPreview(tvlYoutube, FacebookBusiness, tvlAdVideoUtils) {
        return {
            restrict: 'E',
            replace: true,
            scope: {
                platform: '@',
                videoUrl: '=',
                thumbnailUrl: '=',
                videoName: '=',
                videoFound: '=',
                accountId: '=',
                postType: '=',
                isInstagram: '='
            },
            templateUrl: "common/video-preview.html",
            link: function($scope, element, attrs) {
                $scope.videoPreview = {
                    title: '',
                    thumbnail: '',
                    description: '',
                    channelTitle: '',
                    channelUrl: ''
                };
                $scope.searchingVideoPreview = false;

                function init() {
                    switch ($scope.platform) {
                        case 'youtube':
                            searchVideoInYoutube();
                            break;
                        case 'facebook':
                            searchVideoInFacebookOrInstagram();
                            break;
                    }
                    $scope.unregisterVideoUrlWatcher = $scope.$watch('videoUrl', onVideoUrlChange);
                    getChannelIconByPlatformAndType();
                    $scope.$on('$destroy', onDestroy);
                }

                init();

                function searchVideoInYoutube() {
                    if (!$scope.searchingVideoPreview) {
                        clearData();
                        $scope.searchingVideoPreview = true;
                        var videoId = getVideoIdFromUrl($scope.videoUrl);
                        tvlYoutube.getVideoById({part: 'snippet', videoId: videoId})
                            .then (function(response) {
                                if (response.data.items.length > 0) {
                                    $scope.videoPreview.title = response.data.items[0].snippet.title;
                                    $scope.videoPreview.thumbnail = response.data.items[0].snippet.thumbnails.medium.url;
                                    $scope.videoPreview.description = response.data.items[0].snippet.description;
                                    $scope.videoPreview.channelTitle = response.data.items[0].snippet.channelTitle;
                                    $scope.videoPreview.channelUrl = '/channel/'+response.data.items[0].snippet.channelId;
                                    $scope.videoFound = true;
                                } else {
                                    $scope.videoFound = false;
                                }
                            })
                            .catch (function() {
                                $scope.videoFound = false;
                            })
                            .finally (function() {
                                $scope.searchingVideoPreview = false;
                            });
                    }
                }

                function searchVideoInFacebookOrInstagram() {
                    if (!$scope.searchingVideoPreview) {
                        clearData();
                        $scope.searchingVideoPreview = true;
                        var isIGPost = (
                            ($scope.postType.toLowerCase().indexOf(tvlAdVideoUtils.INSTAGRAM_CREATIVE_POST_VIDEO) !== -1) ||
                            ($scope.postType.toLowerCase().indexOf(tvlAdVideoUtils.INSTAGRAM_CREATIVE_POST_PHOTO) !== -1)
                        );

                        var videoId = null;
                        if (!isIGPost) {
                            videoId = ($scope.postType.toLowerCase().indexOf(tvlAdVideoUtils.FACEBOOK_CREATIVE_POST_SHARE) !== -1) ?
                                getFacebookPostIdFromUrl($scope.videoUrl) :
                                getVideoIdFromUrl($scope.videoUrl);
                        }

                        if (videoId && !isIGPost) {
                            FacebookBusiness.getPostInfoByIdAndType({
                                accountId: $scope.accountId,
                                postCreativeId: videoId,
                                postType: $scope.postType
                            })
                                .$promise
                                .then(function (response) {
                                    if (response && response.data) {

                                        var title = (!response.data.name) ? response.data.title : response.data.name;
                                        var description = (response.data.description) ? response.data.description : response.data.message;
                                        $scope.videoPreview.title = title;
                                        $scope.videoPreview.thumbnail = response.data.picture;
                                        $scope.videoPreview.description = description;
                                        $scope.videoPreview.channelTitle = response.data.from.name;
                                        $scope.videoPreview.channelUrl = '/channel/' + response.data.from.id;
                                        $scope.videoPreview.postType = $scope.postType;
                                        $scope.videoFound = true;
                                        getChannelIconByPlatformAndType();
                                    } else {
                                        $scope.videoFound = false;
                                    }
                                })
                                .finally(function () {
                                    $scope.searchingVideoPreview = false;
                                });
                        } else if (isIGPost) {
                            $scope.videoPreview.title = $scope.videoName;
                            $scope.videoPreview.thumbnail = $scope.thumbnailUrl;
                            $scope.videoPreview.thumbnail = $scope.thumbnailUrl;
                            $scope.videoPreview.postType = $scope.postType;

                            $scope.videoFound = true;
                            $scope.searchingVideoPreview = false;
                        } else {
                            $scope.videoFound = false;
                            $scope.searchingVideoPreview = false;
                        }
                    }
                }

                function clearData() {
                    $scope.videoPreview = {
                        title: '',
                        thumbnail: '',
                        description: '',
                        channelTitle: '',
                        channelUrl: ''
                    };
                }

                function getVideoIdFromUrl(url) {
                    switch ($scope.platform) {
                        case 'youtube':
                            var regex = /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/ ]{11})/i;
                            break;
                        case 'facebook':
                            var regex = /(?:facebook\.com\/(?:.*\/videos\/)?)(?:(\d*))/i;
                            break;
                    }
                    return url.match(regex)[1];
                }

                function getFacebookPostIdFromUrl(url) {

                    if ($scope.platform === 'facebook') {
                        var regex = /(?:facebook\.com\/)(?:([0-9_]*))/i;
                        return url.match(regex)[1];
                    }

                    return null;
                }

                function isValidYoutubeVideoUrl(videoUrl) {
                    var longUrlRegex = new RegExp(/^((?:http|https):\/\/(?:www\.)?youtube.com\/watch\?v=([A-Z0-9_-]{11})$)/i);
                    var shortUrlRegex = new RegExp(/^((?:http|https):\/\/youtu.be\/([A-Z0-9_-]{11})$)/i);
                    return isValidUrl(videoUrl, [longUrlRegex, shortUrlRegex]);
                }

                function isValidFacebookInstagramVideoUrl(videoUrl) {
                    var regularUrlRegex = new RegExp(/^((?:http|https):\/\/www\.facebook\.com\/(?:.*\/videos\/)?\d*)/i);
                    return isValidUrl(videoUrl, [regularUrlRegex]);
                }

                function isValidUrl(videoUrl, patterns) {
                    if (typeof videoUrl === 'undefined' || videoUrl.length < 1) {
                        return false;
                    }

                    for (var i = 0; i < patterns.length; i++) {
                        if (patterns[i].test(videoUrl)) {
                            return true;
                        }
                    }

                    return false;
                }

                function onVideoUrlChange(newValue, oldValue) {
                    if (newValue !== oldValue) {
                        if ($scope.platform === 'youtube' && isValidYoutubeVideoUrl(newValue)) {
                            searchVideoInYoutube();
                        } else if ($scope.platform === 'facebook' && isValidFacebookInstagramVideoUrl(newValue)) {
                            searchVideoInFacebookOrInstagram();
                        }
                    }
                }

                function getChannelIconByPlatformAndType() {
                    if ($scope.platform === 'facebook') {
                        switch ($scope.postType) {
                            case 'video':
                                $scope.channelIcon = 'fa-video-camera';
                                break;
                            case 'photo':
                                $scope.channelIcon = 'fa-camera';
                                break;
                            case 'share':
                                $scope.channelIcon = 'fa-share-square';
                                break;
                            case 'multi_share':
                                $scope.channelIcon = 'fa-columns';
                                break;
                        }
                    } else {
                        $scope.channelIcon = 'fa-tv';
                    }
                }

                function onDestroy() {
                    $scope.unregisterVideoUrlWatcher();
                }
            }
        };
    }
})();
