(function() {
    'use strict';

    angular
        .module('tvl.campaign')
        .controller('MergeCampaignModalController', MergeCampaignModalController);

    MergeCampaignModalController.$inject = [
        '$scope',
        '$uibModalInstance',
        'toastr',
        'Campaign',
        'sourceCampaign'
    ];

    /* @ngInject */
    function MergeCampaignModalController(
        $scope,
        $uibModalInstance,
        toastr,
        Campaign,
        sourceCampaign
    ) {
        var vm = this;
        vm.searchResults = [];
        vm.sourceCampaign = sourceCampaign;
        vm.targetCampaign = null;
        vm.ui = {merging: false};

        vm.customerAccountId = vm.sourceCampaign.customerAccount.id;

        vm.cancel = cancel;
        vm.mergeCampaign = mergeCampaign;
        vm.searchCampaigns = searchCampaigns;

        activate();

        ////////////////

        function activate() {
            //
        }

        function cancel() {
            return $uibModalInstance.dismiss();
        }

        function mergeCampaign() {
            var mergeParams = {
                sourceId: vm.sourceCampaign.id,
                destinationId: vm.targetCampaign.id
            };
            vm.ui.merging = true;
            return Campaign.merge(mergeParams)
                .$promise
                .then(onMergeSuccess)
                .catch(onMergeError)
                .finally(onMergeFinally);

            function onMergeSuccess(mergedCampaign) {
                return $uibModalInstance.close(mergedCampaign);
            }

            function onMergeError(response) {
                var reason = 'Our apologies, we have been unable to merge your campaigns. Please, try again later.';

                if ('undefined' !== typeof response.data.reason) {
                    reason = response.data.reason;
                }

                toastr.error(reason);
            }

            function onMergeFinally() {
                vm.ui.merging = false;
            }
        }

        function searchCampaigns(term) {
            let queryParams = {
                'accounts[]': vm.customerAccountId,
                searchTerm: term,
            };

            Campaign.get(queryParams)
                .$promise
                .then(onSearchSuccess);

            /**
             * Exclude the source campaign from the results if present.
             */
            function onSearchSuccess(data) {
                vm.searchResults = data.items.filter(function (campaign) {
                    return campaign.id !== vm.sourceCampaign.id;
                });
            }
        }
    }
})();
