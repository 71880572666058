(function() {
    'use strict';

    angular
        .module('tvl.common')
        .controller('EditNoteModalController', EditNoteModalController);

    EditNoteModalController.$inject = ['$uibModalInstance', 'toastr', 'endpointPromise', 'params', 'note'];

    /* @ngInject */
    function EditNoteModalController($uibModalInstance, toastr, endpointPromise, params, note) {
        var vm = this;

        vm.note = note;

        vm.cancel = cancel;
        vm.updateNote = updateNote;

        activate();

        ////////////////

        function activate() {}

        function cancel() {
            return $uibModalInstance.dismiss();
        }

        /**
         * Create the rule and resolve this modal.
         */
        function updateNote() {
            endpointPromise(Object.assign(params, {content: vm.note.content}))
                .$promise
                .then(onUpdateNoteSuccess)
                .catch(onUpdateNoteError);
        }

        /**
         * On update note success callback.
         *
         * @param response
         */
        function onUpdateNoteSuccess(response) {
            $uibModalInstance.close(vm.note);
            toastr.success('Note has been created.');
        }

        /**
         * On update note error callback.
         *
         * @param response
         */
        function onUpdateNoteError(response) {
            if (response.data && response.data.errors) {
                toastr.error(response.data.errors[0].message);
            } else {
                toastr.error('Our apologies, we have been unable to update the note. Please, try again in a few moments.');
            }
        }
    }
}());
