import {captureException} from '@sentry/browser';

(function () {
    'use strict';

    angular
        .module('tvl.common')
        .config(exceptionConfig);

    exceptionConfig.$inject = ['$provide'];

    function exceptionConfig($provide) {
        $provide.decorator('$exceptionHandler', extendExceptionHandler);
    }

    extendExceptionHandler.$inject = ['$delegate'];

    function extendExceptionHandler($delegate) {
        return function (exception, cause) {
            $delegate(exception, cause);

            if ('undefined' !== typeof Sentry) {
                captureException(exception);
            } else {
                console.warn('Sentry is not defined.');
            }
        };
    }
}());
