(function() {
    'use strict';

    angular
        .module('tvl.campaign')
        .directive('tvlKeywordsSelectorList', tvlKeywordsSelectorList);

    tvlKeywordsSelectorList.$inject = ['tvlSweetAlert', '$uibModal'];

    /* @ngInject */
    function tvlKeywordsSelectorList(tvlSweetAlert, $uibModal) {
        var directive = {
            link: link,
            restrict: 'EA',
            templateUrl: 'campaign/keywords-selector-list.html',
            scope: {
                keywords: '=',
                readonly: '=?'
            }
        };

        return directive;

        function link(scope, element, attrs) {
            scope.getKeywordsLists = getKeywordsLists;
            scope.removeKeywordList = removeKeywordList;
            scope.showRemoveCustomKeywordsModal = showRemoveCustomKeywordsModal;
            scope.showListDetails = showListDetails;

            activate();

            function activate() {
                if (scope.readonly === undefined || scope.readonly === false || scope.readonly === 'false') {
                    scope.readonly = false;
                } else {
                    scope.readonly = true;
                }

                // ensure that the ad-hoc keyword list is present in the model,
                // otherwise create it
                scope.customIndex = getCustomIndex();
                if (scope.customIndex === -1) {
                    scope.customIndex = scope.keywords.length;
                    scope.keywords[scope.customIndex] = {items: []};
                }
            }

            function isCustom(content) {
                return content.items && !content.hasOwnProperty('account') && !content.list;
            }

            function isList(content) {
                return content.list;
            }

            function getCustomIndex() {
                return _.findIndex(scope.keywords, function (keyword) {
                    return isCustom(keyword);
                });
            }

            function getKeywordsLists() {
                return _.filter(scope.keywords, isList);
            }

            function removeKeywordList(item) {
                var index = scope.keywords.indexOf(item);
                if (index > -1) {
                    scope.keywords.splice(index, 1);
                }
            }

            function removeCustomKeywords(index) {
                if (index > -1) {
                    scope.keywords[index] = {'items': []};
                }
            }

            /**
             * Shows the remove all the custom keywords confirmation modal and removes the selected custom if confirmed
             *
             * @return {Promise}
             */
            function showRemoveCustomKeywordsModal(index) {
                var title = 'Remove all custom keywords?';
                var msg = 'Are you sure you want to remove all custom keywords of this campaign?';
                return tvlSweetAlert
                    .confirm(title, msg, 'Yes, remove all', 'No')
                    .then(function(){
                        removeCustomKeywords(index);
                    });
            }

            function showListDetails(items, name) {
                var modal = $uibModal.open({
                    size: 'lg',
                    templateUrl: 'campaign/keywords-details-modal.html',
                    controller: 'KeywordsDetailsModalController as vm',
                    bindToController: true,
                    resolve: {
                        items: function () {
                            return items;
                        },
                        name: function () {
                            return name;
                        }
                    }
                });

                return modal.result;
            }
        }
    }
})();
