const moment = require('moment');

(function() {
    'use strict';

    angular
        .module('tvl.common')
        .directive('tvlNotes', tvlNotes);

    /* @ngInject */
    function tvlNotes() {
        var directive = {
            bindToController: true,
            controller: TvlNotesController,
            controllerAs: 'vm',
            restrict: 'E',
            scope: {
                notes: '=',
                item: '=',
                onEditClicked: '='
            },
            templateUrl: 'common/notes.html'
        };
        return directive;
    }

    TvlNotesController.$inject = ['$filter', '$scope', 'tvlSession'];

    /* @ngInject */
    function TvlNotesController($filter, $scope, tvlSession) {
        const UPDATE_TIME_WINDOW_MINUTES = 5;

        var vm = this;
        vm.user = null;
        vm.editNote = editNote;
        vm.userCanEdit = userCanEdit;

        activate();

        function activate() {
            tvlSession.getSession()
                .then(function(user) {
                    vm.user = user;
                });

            $scope.$on('$destroy', onDestroy);
        }

        /**
         * Check if note can be edited.
         *
         * @param note
         *
         * @returns {boolean}
         */
        function userCanEdit(note) {
            return isUserLoggedTheOwner(note) && false === updateTimeWindowExpired(note);
        }

        /**
         * Check if user logged is the owner of the note.
         *
         * @param note
         *
         * @returns {boolean}
         */
        function isUserLoggedTheOwner(note) {
            var isTheOwner = false;

            if (note.createdBy === vm.user.email) {
                isTheOwner = true;
            }

            return isTheOwner;
        }

        /**
         * Check if update time windows is expired.
         *
         * @param note
         *
         * @returns {boolean}
         */
        function updateTimeWindowExpired(note) {
            var isExpired = false;
            var now = moment.utc();
            var createdAt = moment.utc(note.createdAt);

            now = now.subtract(UPDATE_TIME_WINDOW_MINUTES, 'minutes');

            if (now > createdAt) {
                isExpired = true;
            }

            return isExpired;
        }

        /**
         * Call when click on edit note.
         *
         * @param note
         */
        function editNote(note) {
            vm.onEditClicked(note);
        }

        /**
         * Callback to be invoked when this controller is being destroyed.
         */
        function onDestroy() {
        }
    }
})();
