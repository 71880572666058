(function() {
    'use strict';

    angular
        .module('tvl.common')
        .directive('fileTypes', fileTypes);

    fileTypes.$inject = [];

    /* @ngInject */
    function fileTypes() {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function(scope, elm, attrs, ctrl) {
                ctrl.$validators.fileTypes = function(modelValue, viewValue) {
                    var isValid = false;

                    if (null === modelValue || undefined === modelValue || isValidFileType(modelValue)) {
                        isValid = true;
                    }

                    /**
                     * @param modelValue
                     *
                     * @return {boolean}
                     */
                    function isValidFileType(modelValue) {
                        return null !== modelValue && attrs.fileTypes.indexOf(modelValue.type) >= 0;
                    }

                    return isValid;
                };


            }
        };
    }
})();
