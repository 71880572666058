(function() {
    'use strict';

    angular
        .module('tvl.common')
        .factory('UserPermissionsChecker', UserPermissionsChecker);

    UserPermissionsChecker.$inject = [];

    /**
     * This service checks if a specific user has a permission.
     * For now, it only returns information of the current logged user,
     * but this may change as required.
     *
     * @ngInject
     */
    function UserPermissionsChecker() {
        /**
         * Same as User hasPermission logic.
         *
         * @param user
         * @param permission
         *
         * @returns {boolean|*}
         */
        const userHasPermission = function(user, permission) {
            var userPermissionFound = hasPermissionInPermissions(user.permissions, permission);

            return _.isBoolean(userPermissionFound) && userPermissionFound;
        };

        /**
         * Same as User hasAccountPermission logic. If the account not exists or its permissions are empty we will
         * look at user permissions instead account permissions.
         *
         * @param user
         * @param accountId
         * @param permission
         */
        const userHasAccountPermission = function(user, accountId, permission) {
            var hasPermission = false;
            var accountPermissionFound = _.find(user.accountsPermissions, function(accountPermissions) {
                return accountPermissions.account.id === accountId;
            });

            if (_.isNil(accountPermissionFound)) {
                hasPermission = userHasPermission(user, permission);
            } else {
                hasPermission = hasPermissionInPermissions(accountPermissionFound.permissions, permission);
            }

            return hasPermission;
        };

        /**
         * This method check if permissions exists in array. The permission must be of type Campaign.view.some.
         *
         * @param permissions
         * @param permission
         *
         * @returns {*}
         */
        const hasPermissionInPermissions = function(permissions, permission) {
            var splittedPermission = _.split(permission, '.');
            var resource = splittedPermission.shift();
            var hasPermission = false;
            var toSearch = resource;
            var permissionFound = null;

            do {
                toSearch = toSearch + '.' + splittedPermission.shift();

                permissionFound = _.get(permissions, toSearch);
                hasPermission = (_.isBoolean(permissionFound) && permissionFound) ||
                    (_.isObject(permissionFound) && toSearch === permission);

            } while (false === hasPermission && splittedPermission.length > 0);

            return hasPermission;
        };

        const userIsAuthorizedToViewCampaignContentStats = function(user, accountId) {
            return userHasAccountPermission(user, accountId, 'Account.view_content_stats');
        };

        return {
            userHasPermission: userHasPermission,
            userHasAccountPermission: userHasAccountPermission,
            userIsAuthorizedToViewCampaignContentStats: userIsAuthorizedToViewCampaignContentStats
        };
    }
})();
