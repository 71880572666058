const moment = require('moment');

(function () {
    'use strict';

    angular
        .module('tvl.campaign')
        .controller('CampaignListController', CampaignListController);

    CampaignListController.$inject = [
        '$scope',
        '$state',
        '$stateParams',
        'toastr',
        'tvlSweetAlert',
        'tvlCampaignUtils',
        'tvlAdVideoUtils',
        'tvlTooltipUtils',
        'tvlSession',
        'Campaign',
        'Placement',
        'Company',
        'Account',
        'User',
        'countries',
        'sessionInformation',
        'operatingSystemService',
        'tvlConstantUtils',
    ];

    /* @ngInject */
    function CampaignListController(
        $scope,
        $state,
        $stateParams,
        toastr,
        tvlSweetAlert,
        tvlCampaignUtils,
        tvlAdVideoUtils,
        tvlTooltipUtils,
        tvlSession,
        Campaign,
        Placement,
        Company,
        Account,
        User,
        countries,
        sessionInformation,
        operatingSystemService,
        tvlConstantUtils,
    ) {
        var vm = this;
        vm.status = null;
        vm.operatingSystemService = operatingSystemService;
        vm.unregisterFns = [];

        vm.filters = {
            status: null,
            scheduled: null,
            networks: []
        };

        vm.tvlAdVideoUtils = tvlAdVideoUtils;

        tvlSession.getSession().then(function(user) {
            $scope.loggedUser = user;
            vm.userIsGlobal = $scope.loggedUser.isGlobal;
            init();
        });

        var user = $scope.user;
        $scope.userModel = {
            id: _.get(user, 'id', null),
            isGlobal: _.get(user, 'isGlobal', false),
            companyFullAccess: _.get(user, 'companyFullAccess', false),
            accounts: _.get(user, 'accounts', []),
        };

        $scope.userModel.companies = [];
        $scope.companies = [];
        $scope.accounts = [];

        if ($scope.userModel.companies.length === 0) {
            cleanVisibleAccountsAndAddMainCompany();
        }

        $scope.onCompanyChange = onCompanyChange;
        $scope.onVisibilityCompanyChange = onVisibilityCompanyChange;
        $scope.shouldDisableCompanyFullAccessOptionAccounts = shouldDisableCompanyFullAccessOptionAccounts;
        $scope.onChangeCompanyFullAccess = onChangeCompanyFullAccess;
        $scope.refreshCompanyList = refreshCompanyList;
        $scope.refreshAccountList = refreshAccountList;
        $scope.shouldDisableVisibleAccounts = shouldDisableVisibleAccounts;
        $scope.onIsGlobalChange = onIsGlobalChange;
        $scope.onAccountChange = onAccountChange;
        $scope.onVisibilityCompanyRemove = onVisibilityCompanyRemove;
        $scope.applyFilters = applyFilters;
        $scope.goToNewFront = goToNewFront;

        vm.campaigns = {items: []};
        vm.counters = {};
        vm.countries = {};
        vm.placementsStatus = {};
        vm.searchTerm = $stateParams.search || null;
        vm.sessionInformation = sessionInformation;
        vm.sortBy = 'endDate';
        vm.sortDirection = 'asc';

        // Current tab
        var currentTab = $stateParams.tab || null;
        if (['live', 'pending', 'scheduled', 'finished'].indexOf(currentTab) === -1) {
            currentTab = 'live';
        }

        vm.ui = {
            openCampaign: null,
            gettingCampaigns: false,
            gettingCounters: false,
            tab: currentTab,
            hideFilters: true,
            isSortingAllowed: (currentTab !== 'finished'),
        };

        vm.canEditPlacementStatus = canEditPlacementStatus;
        vm.getCampaignsByStatusesSet = getCampaignsByStatusesSet;
        vm.getCampaignProgress = getCampaignProgress;
        vm.getDisplayAccountName = getDisplayAccountName;
        vm.getDisplayDatePeriod = getDisplayDatePeriod;
        vm.getFinishedCampaigns = getFinishedCampaigns;
        vm.getLiveCampaigns = getLiveCampaigns;
        vm.getPendingCampaigns = getPendingCampaigns;
        vm.getScheduledCampaigns = getScheduledCampaigns;
        vm.isCpmAdVideo = tvlAdVideoUtils.isCpmAdVideo;
        vm.isCpvAdVideo = tvlAdVideoUtils.isCpvAdVideo;
        vm.isCpcAdVideo = tvlAdVideoUtils.isCpcAdVideo;
        vm.isCpaAdVideo = tvlAdVideoUtils.isCpaAdVideo;
        vm.isUserAuthorizedToCreateCampaigns = isUserAuthorizedToCreateCampaigns;
        vm.placementHeaderApplies = tvlCampaignUtils.placementHeaderApplies;
        vm.search = search;
        vm.showPlacementStatusChangeModal = showPlacementStatusChangeModal;
        vm.sortTable = sortTable;
        vm.switchCampaignSummaryStatus = switchCampaignSummaryStatus;
        vm.changePlacementStatus = changePlacementStatus;
        vm.thumbnailCellApplies = tvlCampaignUtils.thumbnailCellApplies;
        vm.getThumbnailCellRowspan = tvlCampaignUtils.getThumbnailCellRowspan;
        vm.getCountryCellRowspan = tvlCampaignUtils.getCountryCellRowspan;
        vm.countryCellApplies = tvlCampaignUtils.countryCellApplies;
        vm.isInGoogleDisplay = tvlCampaignUtils.isCampaignPresentInGoogleDisplay;
        vm.hasPermissionForCampaign = hasPermissionForCampaign;
        vm.getAdVideoThumbnail = tvlAdVideoUtils.getAdVideoThumbnail;
        vm.getAdVideoTitle = tvlAdVideoUtils.getAdVideoTitle;
        vm.getNetworkIconsForPlacement = tvlConstantUtils.getNetworkIconsForPlacement;
        vm.getNetworkIconsForCampaign = tvlCampaignUtils.getNetworkIconsForCampaign;
        vm.availableSubNetworks = tvlCampaignUtils.getAvailableSubNetworks();
        vm.getNetworkTooltip = tvlTooltipUtils.getNetworkTooltip;
        vm.getFormatTooltip = tvlTooltipUtils.getFormatTooltip;
        vm.disableFilterButton = true;

        activate();

        ////////////////

        function activate() {
            // convert countries into key-value items
            vm.countries = _.zipObject(_.map(countries, 'code'), _.map(countries, 'name'));

            // initial fetch
            switch (vm.ui.tab) {
                case 'pending':
                    getPendingCampaigns();
                    break;
                case 'scheduled':
                    getScheduledCampaigns();
                    break;
                case 'finished':
                    getFinishedCampaigns();
                    break;
                case 'live':
                default:
                    getLiveCampaigns();
            }

            // fetch counters from the API
            getCounters();

            vm.unregisterFns = [
                $scope.$watch('vm.filters.networks', onNetworkFilterChange),
                $scope.$watch('vm.campaigns.currentPage', onCurrentPageChange),
                $scope.$watch('vm.companyFilterObject', onCompanyChange),
            ];

            $scope.$on('$destroy', onDestroy);

        }

        function applyFilters(){
            getCampaigns(1);
        }

        //COMPANY AND ACCOUNT FILTER
        function init() {
            refreshAccountList();
            if(vm.userIsGlobal){
                getCompaniesByAccounts();
                refreshCompanyList();
                $scope.userModel.companies = getCompaniesByAccounts([], 'accounts');
                $scope.userModel.companies = getCompaniesByAccounts($scope.userModel.companies , 'accountsWithContentStatsAccess');
            }
        }

        function goToNewFront(event, campaignId){
            window.location.href = '/campaigns/'+ campaignId;

            event.stopPropagation();
        }

        /**
         * @param companies
         * @param toSearch
         * @return {*}
         */
        function getCompaniesByAccounts(companies, toSearch) {
            _.forEach(_.get($scope.userModel, toSearch, []), function (account) {
                var companyIndex = _.findIndex(companies, function (o) {
                    return o.id === account.company.id;
                });
                if (-1 === companyIndex) {
                    var company = getEmptyVisibiltyCompany(account.company);
                    company[toSearch].push(account);
                    companies.push(company);
                } else {
                    company = companies[companyIndex];
                    company[toSearch].push(account);
                    companies[companyIndex] = company;
                }
            });

            return companies;
        }

        function onCompanyChange(newVal, oldVal) {
            vm.companyFilter = [];
            vm.companyFilterObject = $scope.userModel.company;
            if($scope.userModel.company === null){
                $scope.userModel.company = undefined;
            }
            if(newVal != oldVal){
                if(vm.companyFilterObject != undefined){
                    vm.companyFilterObject.forEach(function(item){
                        vm.companyFilter.push(item.id);
                    });
                }
                refreshAccountList();
            }
            cleanVisibleAccountsAndAddMainCompany();
            vm.disableFilterButton = false;
        }

        function refreshCompanyList(search) {
            if (vm.userIsGlobal) {
                var params = {
                    term: search || null,
                }

                return Company.get(params).$promise
                    .then(onGetCompaniesSuccess, onGetCompaniesError);

                function onGetCompaniesSuccess(companies) {
                    $scope.companies = companies.items;
                    if ($scope.companies.length < 2) {
                        refreshAccountList(null, $scope.userModel.companies[0]);
                    }
                }

                function onGetCompaniesError(error) {
                    toastr.error('Our apologies, we have been unable to recover your companies. Please, try again later.');
                }
            }
        }

        function getEmptyVisibiltyCompany(company) {
            return {
                name: company ? company.name : null,
                id: company ? company.id : null,
                accounts: [],
            }
        }

        function refreshAccountList(search) {
            var id = null;
            if (vm.companyFilter) {
                id = vm.companyFilter;
            }
            var params = {
                term: search || null,
                'company[]': id || null,
                items_per_page: 80,
            };

            return Account.get(params).$promise
                .then(onGetAccountsSuccess, onGetAccountsError);

            function onGetAccountsSuccess(accounts) {
                $scope.accounts = accounts.items;
                if(accounts.items.length < 1){
                    accounts.items.push({id:'no accounts', name:"You don\'t have accounts assigned"})
                }
            }

            function onGetAccountsError(error) {
                toastr.error('Our apologies, we have been unable to recover your accounts. Please, try again later.');
            }
        }

        function onNetworkFilterChange(oldVal, newVal) {
            if (newVal !== oldVal) {
                vm.disableFilterButton = false;
            }
        }

        function onAccountChange(company) {
            vm.accountsFilter = [];
            company.accounts.forEach(function(item){
                vm.accountsFilter.push(item.id);
            });
            vm.disableFilterButton = false;
        }

        function onChangeCompanyFullAccess() {
            if($scope.userModel.companyFullAccess) {
                cleanVisibleAccountsAndAddMainCompany();
            }
        }

        function shouldDisableCompanyFullAccessOptionAccounts() {
            return $scope.userModel.isGlobal ||
                UserRolesChecker.isCompanyManager($scope.userModel) ||
                UserRolesChecker.isClient($scope.userModel);
        }

        function onIsGlobalChange() {
            if ($scope.userModel.isGlobal) {
                cleanVisibleAccountsAndAddMainCompany();
                $scope.userModel.companyFullAccess = true;
            }
        }

        function shouldDisableVisibleAccounts() {
            return $scope.userModel.companyFullAccess ||
                $scope.userModel.isGlobal;
        }

        function cleanVisibleAccountsAndAddMainCompany() {
            cleanVisibleAccounts();
            $scope.userModel.companies.push(getEmptyVisibiltyCompany($scope.userModel.company));
        }

        function cleanVisibleAccounts() {
            $scope.userModel.accounts = [];
            $scope.userModel.accountsWithContentStatsAccess = [];
            $scope.userModel.companies = [];
        }

        function onVisibilityCompanyChange(company, $index) {
            company.accounts = [];
            $scope.userModel.companies[$index].name = company.name;
            $scope.userModel.companies[$index].id = company.id;
            $scope.userModel.companies[$index].accounts = [];
            $scope.userModel.companies[$index].accountsWithContentStatsAccess = [];
        }

        function onVisibilityCompanyRemove($index) {
            if ($scope.userModel.companies.length <= 1) {
                tvlSweetAlert.error(
                    'Can not perform action',
                    'You can not remove the last company.'
                );

                return;
            }

            var company = $scope.userModel.companies[$index];
            if (company.accounts.length > 0) {
                onVisibilityCompanyRemoveWithAccounts($index);
            } else {
                $scope.userModel.companies.splice($index, 1);
            }
        }

        function onVisibilityCompanyRemoveWithAccounts($index) {
            var company = $scope.userModel.companies[$index];
            var title = 'Remove all visible accounts of '+ company.name +'?';
            var msg = 'Are you sure you want to remove all visible accounts of '+ company.name +'?';

            return tvlSweetAlert
                .confirm(title, msg, 'Yes, remove all', 'No')
                .then(function(){
                    $scope.userModel.companies.splice($index, 1);
                });
        }

        //END COMPANY AND ACCOUNT FILTER

        function getPlacementsFirstAd(placement) {
            var ad = null;
            if (placement.hasOwnProperty('adGroups') && placement.adGroups.length > 0) {
                var adGroup = placement.adGroups[0];
                if (adGroup.hasOwnProperty('ads') && adGroup.ads.length > 0) {
                    ad = placement.adGroups[0].ads[0];
                }
            }
            if (ad === null) {
                ad = placement.adVideo;
            }
            return ad;
        }

        /**
         * Compare two placements by the following attributes (in order):
         *  - whether their key metric is CPV or CPM
         *  - video
         *  - country
         *  - network
         *
         * @param {Object} a
         * @param {Object} b
         * @return {Number}
         */
        function comparePlacements(a, b) {
            /* CPV / CPM */

            var firstAd = getPlacementsFirstAd(a);
            var secondAd = getPlacementsFirstAd(b);

            if (!firstAd || !secondAd) {
                // Force campaign rendering even if there are placements without ads
                return 0;
            }

            if (tvlAdVideoUtils.isCpvAdVideo(firstAd) && tvlAdVideoUtils.isCpmAdVideo(secondAd)) {
                return -1;
            }
            if (tvlAdVideoUtils.isCpmAdVideo(firstAd) && tvlAdVideoUtils.isCpvAdVideo(secondAd)) {
                return 1;
            }

            if (firstAd.isVideo && secondAd.isVideo) {
                /* video */
                if (firstAd.video.videoId < secondAd.video.videoId) {
                    return -1;
                }
                if (firstAd.video.videoId > secondAd.video.videoId) {
                    return 1;
                }
            }

            /* country */
            if (a.country < b.country) {
                return -1;
            }
            if (a.country > b.country) {
                return 1;
            }

            /* network */
            if (a.network < b.network) {
                return -1;
            }
            if (a.network > b.network) {
                return 1;
            }

            return 0;
        }

        function onCampaignUpdateSuccess() {
            getCampaigns();
            toastr.success('Your campaign has been successfully updated.');
        }

        function onCampaignUpdateError() {
            toastr.error('Our apologies! We have been unable to update your campaign. Please, try again in a few minutes.');
        }

        /**
         * Return the progress of the given campaign as
         * a completion percentage of its cost over its budget.
         *
         * Rejected placements will not be taken into account.
         *
         * @param {Object} campaign
         * @return {Number}
         */
        function getCampaignProgress(campaign) {
            // Ignore placements with rejected status.
            var cost = 0;
            var budget = 0;
            for (var i in campaign.placements) {
                if (campaign.placements[i].status !== 'rejected' && campaign.placements[i].status !== 'canceled') {
                    cost += campaign.placements[i].cost;
                    budget += campaign.placements[i].budget;
                }
            }

            if (budget === 0) {
                return 0;
            }

            return Math.round(cost / budget * 100);
        }

        /**
         * Retrieve campaigns from the API.
         *
         * @return {Promise}
         */
        function getCampaigns(page) {
            var params = {
                page: page ? page : vm.campaigns.currentPage,
                items_per_page: vm.campaigns.itemsPerPage,
                searchTerm: vm.searchTerm,
                'companies[]': vm.companyFilter,
                'status[]': vm.filters.status,
                'networks[]': vm.filters.networks,
                'accounts[]': vm.accountsFilter,
                sortBy: vm.sortBy,
                sortDirection: vm.filters.status.includes('finished') ? 'desc' : vm.sortDirection,
            };

            if($scope.userModel.company !== undefined){
                params['company'] = $scope.userModel.company.id;
            }

            if (vm.filters.scheduled !== null) {
                params['scheduled'] = vm.filters.scheduled ? 1 : 0;
            }
            vm.ui.gettingCampaigns = true;
            return Campaign.get(params)
                .$promise
                .then(onGetCampaignSuccess)
                .finally(onGetCampaignsFinally);

            function onGetCampaignSuccess(campaigns) {
                campaigns.items = preprocessCampaigns(campaigns.items);

                if (campaigns.currentPage < 1) {
                    campaigns.currentPage = 1;
                }

                vm.campaigns = campaigns;

                if ($state.current.name.indexOf('campaign.list') >= 0) {
                    // update query params
                    $state.transitionTo(
                        'campaign.list',
                        {page: campaigns.currentPage, tab: vm.ui.tab, search: vm.searchTerm || undefined},
                        {notify: false}
                    );
                }
            }

            function onGetCampaignsFinally() {
                vm.ui.gettingCampaigns = false;
            }
        }

        /**
         * Fetch campaign counters from the API.
         *
         * @return {Promise}
         */
        function getCounters() {
            vm.ui.gettingCounters = true;

            var params = {};
            if (vm.searchTerm) {
                params = {name: vm.searchTerm};
            }

            return Campaign.getCounters(params)
                .$promise
                .then(onGetCountersSuccess)
                .finally(onGetCountersFinally);

            function onGetCountersSuccess(counters) {
                vm.counters = counters;
            }

            function onGetCountersFinally() {
                vm.ui.gettingCounters = false;
            }
        }

        /**
         * Return a string with the names of all the accounts
         * involved in the given campaign.
         *
         * @param {Object} campaign
         * @return {string}
         */
        function getDisplayAccountName(campaign) {
            return campaign.customerAccount.name;
        }

        /**
         * Return the string with the campaign date period
         * to be printed on the list.
         *
         * This string depends on whether the start and end
         * dates of the campaign are after or before the
         * current date.
         *
         * @param {Object} campaign
         * @return {string}
         */
        function getDisplayDatePeriod(campaign) {
            var start = moment(campaign.startDate);
            var end = moment(campaign.endDate);
            var now = moment();

            if (now.isSameOrAfter(start, 'day')) {
                var started = 'Started';
            } else {
                var started = 'Starts';
            }

            if (now.isAfter(end, 'day')) {
                var ended = 'ended';
            } else {
                var ended = 'ends';
            }

            var startAndEndDateWithGMT = started + " on " + start.format('MMM DD, YYYY') + " and " + ended + " on " + end.format('MMM DD, YYYY');
            startAndEndDateWithGMT += ' ('+ campaign.customerTimezoneGmt  +') '+ campaign.customerTimezone;

            return startAndEndDateWithGMT;
        }

        function hasPermissionForCampaign(campaign, permission) {
            return vm.sessionInformation.hasAccountPermission(
                campaign.customerAccount.id,
                permission
            );
        }

        function isUserAuthorizedToCreateCampaigns() {
            return vm.sessionInformation.hasPermission('Campaign.create');
        }

        /**
         * Callback for pagination changes.
         */
        function onCurrentPageChange(newVal, oldVal) {
            if (newVal !== oldVal && newVal > 0 && oldVal !== undefined) {
                getCampaigns(newVal);
            }
        }

        /**
         * Callback for the $destroy event.
         */
        function onDestroy() {
            for (var i in vm.unregisterFns) {
                vm.unregisterFns[i]();
            }
        }

        /**
         * Return the given campaigns with all their placements
         * sorted by video, country and network.
         *
         * @param {Array} campaigns
         * @return {Array}
         */
        function preprocessCampaigns(campaigns) {
            for (var i in campaigns) {
                var campaign = campaigns[i];
                campaign.placements = campaign.placements.sort(comparePlacements);
            }
            return campaigns;
        }

        /**
         * Launch the search.
         *
         * @return {Promise}
         */
        function search() {
            return getCampaigns(1)
                .then(getCounters);
        }

        /**
         * Updates the id of the current open summary view of a Campaign.
         *
         * @param {String} campaignId
         */
        function switchCampaignSummaryStatus(campaignId) {
            vm.ui.openCampaign = (campaignId === vm.ui.openCampaign) ? null : campaignId;
        }

        /**
         * Updates the data of the Placement corresponding to the given id
         *
         * @param {String} placementId
         * @param {Object} data
         * @return {Promise}
         */
        function putPlacement(placementId, data) {
            data['id'] = placementId;
            var placement = new Placement(data);
            return placement.$save();
        }

        /**
         * Show the placement status switch confirmation modal and
         * change the placement status if the user confirms.
         *
         * @param {Object} placement
         * @param {Number} placementIndex
         * @param {Number} campaignIndex
         * @return {Promise}
         */
        function showPlacementStatusChangeModal(placement, placementIndex, campaignIndex, newValue, oldValue) {
            var title = null;
            var msg = null;
            switch (newValue) {
                case 'paused':
                    title = 'Pause placement?';
                    msg = 'If you pause this placement it will also be paused on the network it is running. Are you sure?';
                    break;
                case 'active':
                    title = 'Enable placement?';
                    msg = 'If you enable this placement it will also be enabled on the network it is running, and it might consume budget. Are you sure?';
                    break;
                case 'canceled':
                    title = 'Cancel placement?';
                    msg = 'If you cancel this placement it will also be canceled on the network it is running and your ads will stop showing. Are you sure?';
                    break;
                case 'finished':
                    title = 'Cancel placement?';
                    msg = 'If you finish this placement it will also be finished on the network it is running and your ads will stop showing. This action is not reversible. Are you sure?';
                    break;
            }

            return tvlSweetAlert
                .confirm(title, msg, 'Yes, proceed', 'No, leave it as it is')
                .then(
                    changePlacementStatus.bind(this, placement, placementIndex, campaignIndex, newValue),
                    onModalCanceled
                );

            function onModalCanceled() {
                placement.status = oldValue
            }
        }

        /**
         * Show cancel campaign modal and save campaign if user accepts
         *
         * @param {Object} campaign
         * @return {Promise}
         */
        function showCancelCampaignModal(campaign) {
            var title = "Cancel campaign?";
            var msg = "If you cancel this campaign it will also be canceled on the network it is running and none of your ads will show. Are you sure?";
            return tvlSweetAlert
                .confirm(title, msg, 'Yes, proceed', 'No, leave it as it is')
                .then(
                    function () {
                        campaign.$save(onCampaignUpdateSuccess, onCampaignUpdateError)
                    },
                    null
                );
        }

        /**
         * change the status of a given Placement.
         *
         * @param {Object} placement
         * @param {Number} placementIndex
         * @param {Number} campaignIndex
         */
        function changePlacementStatus(placement, placementIndex, campaignIndex, newValue) {

            placement.updating = true;

            var data = {
                status: newValue
            };

            putPlacement(placement.id, data)
                .then(onPutPlacementSuccess)
                .catch(onPutPlacementError)
                .finally(onPutPlacementFinally);

            function onPutPlacementSuccess(placement) {
                vm.campaigns.items[campaignIndex].placements[placementIndex] = placement;
                return placement;
            }

            function onPutPlacementError() {
                toastr.error('Our apologies! We have been unable to update your placement. Please, try again in a few minutes.');
            }

            function onPutPlacementFinally() {
                placement.updating = false;
                getCampaign(vm.campaigns.items[campaignIndex].id);
            }
        }

        /**
         * Get a single campaign from the API.
         *
         * @param {Number} id
         * @return {Promise}
         */
        function getCampaign(id) {
            return Campaign.get({id: id})
                .$promise
                .then(onGetCampaignSuccess);

            function onGetCampaignSuccess(updatedCampaign) {
                var idx = _.findIndex(vm.campaigns.items, {id: id});
                vm.campaigns.items[idx] = updatedCampaign;
            }
        }

        function sortTable(column) {
            vm.sortBy = column;
            vm.sortDirection = vm.sortDirection === 'desc' ? 'asc' : 'desc';

            getCampaigns(vm.campaigns.currentPage);
        }

        function canEditPlacementStatus(placement, campaign) {
            return placement.status !== 'creating' &&
                placement.status !== 'finished' &&
                placement.status !== 'canceled' &&
                !placement.updating &&
                hasPermissionForCampaign(campaign, 'Campaign.update');
        }

        /**
         * Get campaigns by status
         *
         * @param {Array} set
         * @param {String} setName
         * @return {Promise}
         */
        function getCampaignsByStatusesSet(set, setName) {
            vm.filters.status = set;
            vm.ui.tab = setName;
            return getCampaigns(1);
        }

        /**
         * Shortcut function for retrieving all campaigns under the Finished
         * tab.
         *
         * @return {Promise}
         */
        function getFinishedCampaigns() {
            resetFilters();
            vm.ui.tab = 'finished';
            vm.ui.isSortingAllowed = false;
            return getCampaignsByStatusesSet(['finished', 'canceled'], 'finished');
        }

        /**
         * Shortcut function for retrieving all campaigns under the Live tab.
         *
         * @return {Promise}
         */
        function getLiveCampaigns() {
            resetFilters();
            vm.ui.tab = 'live';
            vm.filters.scheduled = false;
            vm.ui.isSortingAllowed = true;
            return getCampaignsByStatusesSet(['active', 'paused'], 'live');
        }

        /**
         * Shortcut function for retrieving all campaigns under the Pending
         * tab.
         *
         * @return {Promise}
         */
        function getPendingCampaigns() {
            resetFilters();
            vm.ui.tab = 'pending';
            vm.ui.isSortingAllowed = true;
            return getCampaignsByStatusesSet(['pending_authorization', 'pending', 'creating', 'rejected'], 'pending');
        }

        /**
         * Shortcut function for retrieving all campaigns under the Scheduled
         * tab.
         *
         * @return {Promise}
         */
        function getScheduledCampaigns() {
            resetFilters();
            vm.ui.tab = 'scheduled';
            vm.filters.scheduled = true;
            vm.ui.isSortingAllowed = true;
            return getCampaignsByStatusesSet(['active', 'paused'], 'scheduled');
        }

        function resetFilters() {
            vm.filters.status = null;
            vm.filters.scheduled = null;
        }
    }
})();
