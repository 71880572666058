const moment = require('moment');

(function() {
    'use strict';

    angular
        .module('tvl.brandSafety', [
            'ngAnimate',
            'ngSanitize',
            'ngFileUpload',
            'ngMessages',
            'ui.router',
            'ui.bootstrap',
            'ui.select',
            'toastr',
            'NgSwitchery',
            'ngTable',
            'angular-loading-bar',
            'checklist-model',
            'rzModule',
            'ngCountTo',
            'tvl.common'
        ])
        .constant('moment', moment)
        .constant('MAX_KEYWORD_LIST_SIZE', 10000)
        .constant('MAX_CONTENT_LIST_SIZE', 19900)
        .config(configure);

    configure.$inject = [
        '$locationProvider',
        '$urlRouterProvider',
        '$stateProvider',
        '$httpProvider',
        'cfpLoadingBarProvider'
    ];

    /* @ngInject */
    function configure(
        $locationProvider,
        $urlRouterProvider,
        $stateProvider,
        $httpProvider,
        cfpLoadingBarProvider
    ) {
        $locationProvider.html5Mode(true);
        cfpLoadingBarProvider.includeSpinner = false;
        $stateProvider.state({
            name: 'brand-safety',
            abstract: true,
            templateUrl: 'base.html',
        }).state({
            name: 'brand-safety.placement-analysis',
            url: '/brand-safety/placements?page&language&age[]&gender[]&location[]&interest[]&minSubscribers&maxSubscribers&minViewRate&maxViewRate&minLikePct&maxLikePct',
            controller: 'BrandSafetyAnalysisController as vm',
            templateUrl: 'brand-safety/analysis.html',
            reloadOnSearch: false,
            resolve: {
                /* @ngInject */
                languages: function(Constants) {
                    return Constants.getLanguages().$promise;
                }
            }
        }).state({
            name: 'brand-safety.list-creator',
            url: '/brand-safety/create',
            controller: 'ListCreatorController as vm',
            templateUrl: 'brand-safety/list-creator.html',
            resolve: {
                /* @ngInject */
                languages: function(Constants) {
                    return Constants.getLanguages().$promise;
                },
                /* @ngInject */
                placementLists: function(ContentsList) {
                    return ContentsList.get().$promise;
                },
                /* @ngInject */
                keywordLists: function(KeywordsList) {
                    return KeywordsList.get().$promise;
                }
            }
        }).state({
            name: 'brand-safety.contents-lists',
            url: '/brand-safety/channel-lists?page&listId',
            controller: 'BrandSafetyListsController as vm',
            templateUrl: 'brand-safety/lists.html',
            reloadOnSearch: false,
            resolve: {
                /* @ngInject */
                lists: function(ContentsList) {
                    return ContentsList.get({}).$promise;
                },
                /* @ngInject */
                languages: function(Constants) {
                    return Constants.getLanguages().$promise;
                },
                loggedUser: function (tvlSession) {
                    return tvlSession.getSession();
                }
            }
        }).state({
            name: 'brand-safety.keywords-lists',
            url: '/brand-safety/keyword-lists?page',
            controller: 'BrandSafetyKeywordsListsController as vm',
            templateUrl: 'brand-safety/keywords-lists.html',
            reloadOnSearch: false,
            resolve: {
                /* @ngInject */
                lists: function(KeywordsList) {
                    return KeywordsList.get({}).$promise;
                }
            }
        }).state({
            name: 'brand-safety.country-lists',
            url: '/brand-safety/channels/:listId',
            controller: 'BrandSafetyCountryListsController as vm',
            templateUrl: 'brand-safety/country-lists.html',
            reloadOnSearch: false,
            resolve: {
                /* @ngInject */
                list: function($stateParams, ContentsList) {
                    if ($stateParams.listId) {
                        return ContentsList.get({id: $stateParams.listId}).$promise;
                    }

                    return null;
                }
            }
        });
    }

})();
