const moment = require('moment');

(function() {
    'use strict';

    angular
        .module('tvl.control-center')
        .service('tvlControlCenterService', tvlControlCenterService);

    tvlControlCenterService.$inject = ['tvlSession', 'ControlCenterIssues', 'toastr', 'tvlSweetAlert'];

    /**
     * @ngInject
     */
    function tvlControlCenterService(tvlSession, ControlCenterIssues, toastr, tvlSweetAlert) {
        /**
         * Get issues from back.
         *
         * @param successCallback
         * @param errorCallback
         */
        function getIssues(successCallback, errorCallback) {
            ControlCenterIssues.get()
                .$promise
                .then(successCallback)
                .catch(errorCallback);
        }

        /**
         * Used to format issue dates.
         *
         * @param date
         *
         * @returns {string}
         */
        function formatFullDate(date) {
            return formatDate(date) + ' ' + formatTime(date);
        }

        /**
         * Used to format issue dates.
         *
         * @param date
         *
         * @returns {string}
         */
        function formatDate(date) {
            var momentDate = moment.utc(date);

            return momentDate.format('YYYY-MM-DD');
        }

        /**
         * Used to format issue dates.
         *
         * @param date
         *
         * @returns {string}
         */
        function formatTime(date) {
            var momentDate = moment.utc(date);

            return momentDate.format('HH:mm:ss');
        }

        /**
         * Resolve issue
         *
         * @param {object} issue
         * @param {function} successCallback
         * @param {function} errorCallback
         * @param {function} preCallback
         *
         * @returns {Promise}
         */
        function resolveIssue(issue, successCallback, errorCallback, preCallback) {
            tvlSession.getSession()
                .then(onGetSessionSuccess)
                .catch(onGetSessionError);

            function onGetSessionSuccess(data) {

                tvlSweetAlert
                    .confirm(
                        'Resolve issue',
                        'The issue #'+issue.supportName+' will be reopened by an occurrence, be sure that it is resolved.',
                        {
                            confirmButtonText: 'Yes, resolve it',
                            cancelButtonText: 'Cancel'
                        }
                    ).then(resolve);

                function resolve() {
                    preCallback();
                    ControlCenterIssues.resolve({id: issue.id, resolvedBy: data.email})
                        .$promise
                        .then(successCallback)
                        .catch(errorCallback);
                }
            }

            function onGetSessionError(data) {
                toastr.error('Authorization error, please try again later.');
                errorCallback(data);
            }
        }

        /**
         * Issue detail
         *
         * @param {string} issueId
         * @param {function} successCallback
         * @param {function} errorCallback
         *
         * @returns {Promise}
         */
        function issueDetail(issueId, successCallback, errorCallback) {
            ControlCenterIssues.get({id: issueId})
                .$promise
                .then(successCallback)
                .catch(errorCallback);
        }

        /**
         * Copy support name to clipboard creating a DOM element.
         *
         * @param issue
         */
        function copyIssueSupportNameToClipboard(issue)
        {
            // create temp element
            var copyElement = document.createElement("span");
            copyElement.appendChild(document.createTextNode(issue.supportName));
            copyElement.id = 'tempCopyToClipboard';
            angular.element(document.body.append(copyElement));

            // select the text
            var range = document.createRange();
            range.selectNode(copyElement);
            window.getSelection().removeAllRanges();
            window.getSelection().addRange(range);

            // copy & cleanup
            document.execCommand('copy');
            window.getSelection().removeAllRanges();
            copyElement.remove();

            toastr.info('Copied to clipboard!');
        }

        return {
            formatFullDate: formatFullDate,
            formatDate: formatDate,
            formatTime: formatTime,
            resolveIssue: resolveIssue,
            issueDetail: issueDetail,
            getIssues: getIssues,
            copyIssueSupportNameToClipboard: copyIssueSupportNameToClipboard
        };
    }
}());
