(function() {
    'use strict';

    angular
        .module('tvl.administration')
        .controller('AccountsController', AccountsController)
        .controller('AccountRemovalConfirmationModalController', AccountRemovalConfirmationModalController);

    AccountsController.$inject = [
        '$scope',
        '$state',
        '$uibModal',
        '$window',
        'Upload',
        'tvlCurrencies',
        'Account',
        'Company',
        'tvlSession',
        'resourceResponse'
    ];

    /* @ngInject */
    function AccountsController(
        $scope,
        $state,
        $uibModal,
        $window,
        Upload,
        tvlCurrencies,
        Account,
        Company,
        tvlSession,
        resourceResponse
    ) {
        /**
         * We have disabled account renaming feature, as the reports actually are depending to accounts names.
         * Hope we will fix it soon.
         */
        var vm = this;
        vm.accounts = [];
        vm.searchTerm = null;
        vm.currentPage = 1;
        vm.totalItems = null;
        vm.itemsPerPage = 20;

        vm.createAccount = createAccount;
        vm.getAccounts = getAccounts;
        vm.selectAccount = selectAccount;
        vm.searchAccounts = searchAccounts;

        vm.loggedUser = null;
        vm.isLoading = true;

        activate();

        ////////////////

        function activate() {
            tvlSession.getSession().then(function(user) {
                vm.loggedUser = user;

                getAccounts();
            });
        }

        function createAccount() {
            return showCreationModal().then(onCreateSuccess);

            function onCreateSuccess(account) {
                resetSearchTerm();
                resetPagination();

                return getAccounts();
            }
        }

        function searchAccounts() {
            resetPagination();
            getAccounts();
        }

        function resetPagination() {
            vm.currentPage = 1;
        }

        function resetSearchTerm() {
            vm.searchTerm = null;
        }

        function getAccounts() {
            vm.isLoading = true;

            var params = {
                page: vm.currentPage,
                term: vm.searchTerm || null,
                items_per_page: vm.itemsPerPage
            };

            return Account.get(params).$promise
                .then(onGetAccountsSuccess, resourceResponse.error);

            function onGetAccountsSuccess(accounts) {
                vm.accounts = accounts.items;
                vm.totalItems = accounts.totalItems;
                vm.currentPage = accounts.currentPage;
                vm.itemsPerPage = accounts.itemsPerPage;
                vm.isLoading = false;
            }
        }

        function selectAccount(account) {
            $state.go('admin.accountsEdit', {id: account.id});
        }

        function showCreationModal() {
            return $uibModal.open({
                controller: 'AccountsNewModalController',
                templateUrl: 'accounts/accounts-new.modal.html',
                backdrop: 'static'
            }).result;
        }
    }

    AccountRemovalConfirmationModalController.$inject = ['$scope', '$uibModalInstance', 'account'];

    /** @ngInject */
    function AccountRemovalConfirmationModalController($scope, $uibModalInstance, account) {
        var vm = this;
        vm.account = account;

        vm.confirm = confirm;
        vm.cancel = cancel;

        function confirm() {
            return $uibModalInstance.close(account);
        }

        function cancel() {
            return $uibModalInstance.dismiss();
        }
    }


}());
