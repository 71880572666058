(function() {
    'use strict';

    angular
        .module('tvl.campaign')
        .controller('PlacementBenchmarkReportsModalController', PlacementBenchmarkReportsModalController);

    PlacementBenchmarkReportsModalController.$inject = [
        '$scope',
        '$uibModalInstance',
        'DTOptionsBuilder',
        'reports',
        'showTvlCosts',
        'tvlConstantUtils',
        'tvlAdVideoUtils',
    ];

    /* @ngInject */
    function PlacementBenchmarkReportsModalController(
        $scope,
        $uibModalInstance,
        DTOptionsBuilder,
        reports,
        showTvlCosts,
        tvlConstantUtils,
        tvlAdVideoUtils
    ) {

        var vm = this;
        vm.placementBenchmarkReports = reports;
        vm.ui = {
            showTvlCosts: showTvlCosts
        };
        vm.tvlConstantUtils = tvlConstantUtils;
        vm.tvlAdVideoUtils = tvlAdVideoUtils;

        function activate() {
            vm.dtOptions = DTOptionsBuilder.newOptions()
                .withOption('scrollY', true)
                .withOption('scrollX', true)
                .withOption('scrollCollapse', true)
                .withOption('paging', false)
                .withOption('bFilter', false)
                .withOption('bInfo', false)
                .withFixedColumns({
                    leftColumns: 2
                })
                .withBootstrap();
        }

        function cancel() {
            return $uibModalInstance.dismiss();
        }

        ////////////////

        vm.cancel = cancel;

        activate();
    }
}());
