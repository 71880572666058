(function() {
    'use strict';

    googleDisplayAdUtils.$inject = [];

    /**
     * @returns {googleDisplayAdUtils}
     */
    function googleDisplayAdUtils() {

        var self = this;

        self.BIDDING_TYPE_MANUAL_CPM_KEY = 'MANUAL_CPM';
        self.BIDDING_TYPE_MANUAL_CPM_NAME = 'Viewable CPM';

        self.BIDDING_TYPE_TARGET_CPA_KEY = 'TARGET_CPA';
        self.BIDDING_TYPE_TARGET_CPA_NAME = 'Target CPA';

        self.BIDDING_TYPE_TARGET_SPEND_KEY = 'TARGET_SPEND';
        self.BIDDING_TYPE_TARGET_SPEND_NAME = 'Maximize Clicks';

        self.BIDDING_TYPE_MANUAL_CPC_KEY = 'MANUAL_CPC';
        self.BIDDING_TYPE_MANUAL_CPC_NAME = 'Manual CPC';

        /**
         * Returns available bidding types.
         *
         * @return {[{name: string, key: string}, {name: string, key: string}]}
         */
        self.getBiddingTypesOptions = function() {
            return [
                {
                    key: self.BIDDING_TYPE_MANUAL_CPM_KEY,
                    name: self.BIDDING_TYPE_MANUAL_CPM_NAME,
                },
                {
                    key: self.BIDDING_TYPE_TARGET_CPA_KEY,
                    name: self.BIDDING_TYPE_TARGET_CPA_NAME,
                },
                {
                    key: self.BIDDING_TYPE_TARGET_SPEND_KEY,
                    name: self.BIDDING_TYPE_TARGET_SPEND_NAME,
                },
                {
                    key: self.BIDDING_TYPE_MANUAL_CPC_KEY,
                    name: self.BIDDING_TYPE_MANUAL_CPC_NAME,
                }
            ];
        };

        self.getGoalByBiddingType = function(biddingType) {
            switch (biddingType) {
                case self.BIDDING_TYPE_MANUAL_CPM_KEY:
                    return 'cpm';
                case self.BIDDING_TYPE_TARGET_CPA_KEY:
                    return 'cpa';
                case self.BIDDING_TYPE_TARGET_SPEND_KEY:
                    return 'cpc';
                case self.BIDDING_TYPE_MANUAL_CPC_KEY:
                    return 'cpc';
                default:
                    throw 'Unsupported Bidding Type';
            }
        };

        self.getBiddingTypesObjectByType =  function (biddingType) {
            var data;
            switch (biddingType) {
                case self.BIDDING_TYPE_MANUAL_CPM_KEY:
                    data = {
                        key: self.BIDDING_TYPE_MANUAL_CPM_KEY,
                        name: self.BIDDING_TYPE_MANUAL_CPM_NAME,
                    };
                    break;
                case self.BIDDING_TYPE_TARGET_CPA_KEY:
                    data = {
                        key: self.BIDDING_TYPE_TARGET_CPA_KEY,
                        name: self.BIDDING_TYPE_TARGET_CPA_NAME,
                    };
                    break;
                case self.BIDDING_TYPE_TARGET_SPEND_KEY:
                    data = {
                        key: self.BIDDING_TYPE_TARGET_SPEND_KEY,
                        name: self.BIDDING_TYPE_TARGET_SPEND_NAME,
                    };
                    break;
                case self.BIDDING_TYPE_MANUAL_CPC_KEY:
                    data = {
                        key: self.BIDDING_TYPE_MANUAL_CPC_KEY,
                        name: self.BIDDING_TYPE_MANUAL_CPC_NAME,
                    };
                    break;

                default:
                    throw 'Unsupported Bidding Type';
            }
            return data;
        }

        /**
         *
         * @param key
         *
         * @return {object|null}
         */
        self.getNameForBiddingTypeKey = function(key) {
            var found = _.find(self.getBiddingTypesOptions(), function(biddingType) {
                return biddingType.key === key;
            });

            return _.isNil(found) ? '' : found.name;
        };

        return self;
    }

    angular
        .module('tvl.common')
        .service('googleDisplayAdUtils', googleDisplayAdUtils);

}());
