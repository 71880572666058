(function() {
    'use strict';

    angular
        .module('tvl.campaign')
        .controller('CampaignFinanceSettingsModalController', CampaignFinanceSettingsModalController);

    CampaignFinanceSettingsModalController.$inject = [
        '$scope',
        '$uibModalInstance',
        'tvlCampaignUtils',
        'campaign',
        'tvlUtils',
        'user',
        'Campaign',
        'resourceResponse',
        'tvlSweetAlert',
    ];

    /* @ngInject */
    function CampaignFinanceSettingsModalController(
        $scope,
        $uibModalInstance,
        tvlCampaignUtils,
        campaign,
        tvlUtils,
        user,
        Campaign,
        resourceResponse,
        tvlSweetAlert
    ) {
        var vm = this;
        vm.campaign = campaign;

        vm.inverseAdwordsExchangeRate = vm.campaign.adwordsExchangeRate ? 1 / vm.campaign.adwordsExchangeRate : 0;
        vm.inverseFacebookExchangeRate = vm.campaign.facebookExchangeRate ? 1 / vm.campaign.facebookExchangeRate : 0;
        vm.step = 0.000001;
        vm.tvlCampaignUtils = tvlCampaignUtils;
        vm.isCpaCampaign = false;
        vm.customerAccountId = vm.campaign.customerAccount.id;
        vm.hasAccountPermission = hasAccountPermission;
        vm.loading = false;
        vm.user = user;

        /**
         * Callback for inverse Adwords exchange rate changes.
         */
        function oninverseAdwordsExchangeRateChange(newVal, oldVal) {
            if (newVal === oldVal) {
                return;
            }
            vm.campaign.adwordsExchangeRate = tvlUtils.roundNumber(1 / vm.inverseAdwordsExchangeRate, 8);
        }

        /**
         * Callback for inverse Facebook exchange rate changes.
         */
        function oninverseFacebookExchangeRateChange(newVal, oldVal) {
            if (newVal === oldVal) {
                return;
            }
            vm.campaign.facebookExchangeRate = tvlUtils.roundNumber(1 / vm.inverseFacebookExchangeRate, 8);
        }

        /**
         * Callback for the $destroy event.
         */
        function onDestroy() {
            vm.unregisterInverseAdwordsExchangeRateWatcher();
            vm.unregisterInverseFacebookExchangeRateWatcher();
        }

        function activate() {
            vm.unregisterInverseAdwordsExchangeRateWatcher = $scope.$watch(
                'vm.inverseAdwordsExchangeRate',
                oninverseAdwordsExchangeRateChange
            );
            vm.unregisterInverseFacebookExchangeRateWatcher = $scope.$watch(
                'vm.inverseFacebookExchangeRate',
                oninverseFacebookExchangeRateChange
            );
            $scope.$on('$destroy', onDestroy);

            isCpaCampaign();
        }

        function isCpaCampaign() {
            for (var i = 0; i < vm.campaign.placements.length; i++) {
                var ad = vm.campaign.placements[i].adGroups[0].ads[0];
                if (ad.isCpa) {
                    vm.isCpaCampaign = true;
                    return;
                }
            }
        }

        function cancel() {
            return $uibModalInstance.dismiss();
        }

        function update() {
            var result = {};

            if (vm.hasAccountPermission('Campaign.update_adwordsExchangeRate')) {
                result.adwordsExchangeRate = vm.campaign.adwordsExchangeRate ? parseFloat(tvlUtils.roundNumber(vm.campaign.adwordsExchangeRate, 8)) : 0;
            }
            if (vm.hasAccountPermission('Campaign.update_facebookExchangeRate')) {
                result.facebookExchangeRate = vm.campaign.facebookExchangeRate ? parseFloat(tvlUtils.roundNumber(vm.campaign.facebookExchangeRate, 8)) : 0;
            }

            vm.loading = true;

            var title = "Warning!";
            var msg = "You will update the campaign. Are you sure?.";
            var opts = {
                closeOnConfirm: true,
                confirmButtonText: "Update it",
                cancelButtonText: "Go back"
            };

            return tvlSweetAlert.confirm(title, msg, opts)
                .then(function () {
                    Campaign.updatePartial({id: vm.campaign.id}, result).$promise.then(
                        function(){
                            vm.loading = false;
                            $uibModalInstance.close(result);
                        },
                        function (response) {
                            resourceResponse.error(response);
                            vm.loading = false;
                        }
                    );
                }, function () {
                    vm.loading = false;
                });
        }

        function hasAccountPermission(permission) {
            return vm.user.hasAccountPermission(vm.customerAccountId, permission);
        }

        ////////////////

        vm.cancel = cancel;
        vm.update = update;

        activate();
    }
}());
