(function() {
    'use strict';

    angular
        .module('tvl.campaign')
        .controller('WizardEventsController', WizardEventsController);

    WizardEventsController.$inject = [
        '$scope',
        '$uibModal',
        'tvlUtils',
        'tvlForm',
        'tvlRules'
    ];

    /* @ngInject */
    function WizardEventsController(
        $scope,
        $uibModal,
        tvlUtils,
        tvlForm,
        tvlRules
    ) {
        var vm = this;
        vm.tvlRules = tvlRules;
        vm.parent = $scope.$parent;
        vm.campaignForm = vm.parent.campaignForm;
        vm.unregisterFns = [];

        vm.openNewRuleModal = openNewRuleModal;
        vm.removeRule = removeRule;

        activate();

        ////////////////

        function activate() {
            vm.unregisterFns = [
                $scope.$on('tvlValidate', function(event, step) {
                    if (step.key === 'events') {
                        vm.parent.$emit('tvlValidationEnd', {'valid' : validate()});
                    }
                })
            ];

            $scope.$on('$destroy', onDestroy);
        }

        /**
         * Show the new rule modal.
         *
         * If resolved with success the rule will be added to the list.
         *
         * @return {Promise}
         */
        function openNewRuleModal() {
            var locationIds = [];
            vm.campaignForm.targeting.locations.forEach(function (location) {
                locationIds.push(location);
            });
            var modal = $uibModal.open({
                templateUrl: 'campaign/wizard/create-rule-modal.html',
                controller: 'CreateRuleModalController as vm',
                bindToController: true,
                resolve: {
                    placements: function () {
                        return [];
                    },
                    locations: function () {
                        return locationIds;
                    }
                }
            });
            return modal.result.then(function (rule) {
                vm.campaignForm.rules.push(rule);
            });
        }

        /**
         * Remove the rule at the given position.
         *
         * @param Number
         */
        function removeRule(idx) {
            vm.campaignForm.rules.splice(idx, 1);
        }

        function validate() {
            // this step is optional, so no need to validate.
            return true;
        }

        function onDestroy() {
            for (var i in vm.unregisterFns) {
                vm.unregisterFns[i]();
            }
        }
    }
}());
