const moment = require('moment');

(function() {
    angular.module('tvl.common', [
        'ngResource',
        'oitozero.ngSweetAlert',
        'ui.bootstrap',
        'LocalStorageModule',
        'jtt_youtube',
        'pusher-angular',
        'filterNumber',
        'angularMoment',
        'ngFileSaver'
    ]).constant('moment', moment)
        .constant('Pusher', Pusher)
        .config(configure);

    configure.$inject = ['localStorageServiceProvider'];

    function configure(
        localStorageServiceProvider
    ) {
        localStorageServiceProvider
            .setPrefix('tvl')
            .setStorageType('sessionStorage');
    }

})();
