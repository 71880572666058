const moment = require('moment');

(function () {
    'use strict';

    angular
        .module('tvl.campaign')
        .controller('CampaignShowController', CampaignShowController);

    CampaignShowController.$inject = [
        '$scope',
        '$filter',
        '$q',
        '$state',
        '$uibModal',
        '$stateParams',
        'toastr',
        'tvlSweetAlert',
        'tvlCampaignUtils',
        'tvlAdVideoUtils',
        'tvlTooltipUtils',
        'tvlForm',
        'tvlUrl',
        'campaignValidation',
        'tvlRules',
        'tvlModelChangesUpdater',
        'Account',
        'Constants',
        'Campaign',
        'CampaignNotes',
        'Placement',
        'Targeting',
        'User',
        'Channel',
        'Video',
        'Interest',
        'Topic',
        'Location',
        'Rule',
        'campaign',
        'countries',
        'FacebookBusiness',
        'AdVideo',
        'operatingSystemService',
        'loggedUser',
        'resourceResponse',
        'tvlConstantUtils',
    ];

    /* @ngInject */
    function CampaignShowController(
        $scope,
        $filter,
        $q,
        $state,
        $uibModal,
        $stateParams,
        toastr,
        tvlSweetAlert,
        tvlCampaignUtils,
        tvlAdVideoUtils,
        tvlTooltipUtils,
        tvlForm,
        tvlUrl,
        campaignValidation,
        tvlRules,
        tvlModelChangesUpdater,
        Account,
        Constants,
        Campaign,
        CampaignNotes,
        Placement,
        Targeting,
        User,
        Channel,
        Video,
        Interest,
        Topic,
        Location,
        Rule,
        campaign,
        countries,
        FacebookBusiness,
        AdVideo,
        operatingSystemService,
        loggedUser,
        resourceResponse,
        tvlConstantUtils
    ) {
        var vm = this;
        vm.resource = Campaign;
        vm.resourceName = 'Campaign';
        vm.STATUS_CREATING = 'creating';
        vm.STATUS_PENDING = 'pending';
        vm.STATUS_ACTIVE = 'active';
        vm.STATUS_PAUSED = 'paused';
        vm.STATUS_REJECTED = 'rejected';

        vm.tvlAdVideoUtils = tvlAdVideoUtils;

        vm.ADWORDS_MAX_ALLOWED_KEYWORDS = 10000;

        vm.FREQUENCY_CAP_VIEWS = 'views';
        vm.FREQUENCY_CAP_IMPRESSIONS = 'impressions';
        vm.FREQUENCY_CAP_MAX_VALUE = 2147483647;

        vm.tvlRules = tvlRules;
        vm.campaign = campaign;
        vm.campaignChangelog = null;
        vm.campaignNotes = null;
        vm.campaignRulesLog = null;
        vm.campaignRules = [];
        vm.dirty = false;
        vm.networkCount = 0;
        vm.networks = {
            adwords: false,
            facebook: false,
            instagram: false,
        };
        vm.inventoryTypes = [
            'Expanded inventory',
            'Standard inventory',
            'Limited inventory'
        ];
        vm.originalCampaign = angular.copy(campaign);

        vm.ui = {
            savingCampaign: false,
            datePickersOpened: {},
            looadingChangelog: true,
            loadingRulesLog: true,
            loadingCampaignNotes: true,
            // ui vars from campaign-details directive
            // @todo
            maxAgeShown: null,
            minAgeShown: null,
            tab: $stateParams.tab || null,
            showSensitiveContent: false,
            showFrequencyCapForm: false,
            frequencyCapViewsIndex: null,
            frequencyCapImpressionsIndex: null,
        };
        // //todo Google does not allow this field anymore but we have running campaigns with it.
        if (vm.campaign.contentExclusion && vm.campaign.contentExclusion.games) {
            vm.campaign.contentExclusion.games = false;
        }
        if (vm.campaign.contentExclusion) {
            if (vm.campaign.contentExclusion.tragedyAndConflict ||
                vm.campaign.contentExclusion.sensitiveSocialIssues ||
                vm.campaign.contentExclusion.sexuallySuggestiveContent ||
                vm.campaign.contentExclusion.sensationalAndShocking ||
                vm.campaign.contentExclusion.profanityAndRoughLanguage) {
                vm.ui.showSensitiveContent = true;
            }
        }

        vm.tabsNames = [
            'overview',
            'targeting',
            'placements',
            'rules',
            'warnings',
            'audit',
            'notes',
            'error-log'
        ];
        vm.activeTabIndex = (vm.ui.tab) ? vm.tabsNames.indexOf(vm.ui.tab) : 0;
        vm.activateTab = activateTab;

        vm.unregisterEditWatcherFns = [];
        vm.unregisterChangelogCurrentPageWatcher = null;
        vm.unregisterRulesLogCurrentPageWatcher = null;
        vm.unregisterCampaignNotesCurrentPageWatcher = null;
        vm.unregisterUsersAndAccountsWatcher = null;
        vm.unsavedChanges = {
            'placement': {},
            'campaign': {},
            'targeting': {},
            'adVideo': {},
        };
        vm.userAccess = {
            users: [],
            accounts: [],
            usersAndAccounts: []
        };
        vm.userAccessHasChanged = false;
        vm.disableUserSelect = false;
        vm.userCanEditCampaignSettings = false;
        vm.userCanEditCampaignFinanceSettings = false;
        vm.userCanAudit = false;
        vm.userCanViewAutomanaged = false;
        vm.userCanEdit = false;
        vm.userCanCampaignNotes = false;
        vm.userCanSeeErrorFlag = false;
        vm.locationTypeSelected = null;
        vm.isScheduleDisabledOnUpdatesCached = null;
        vm.onLocationSelected = onLocationSelected;
        vm.onLocationRemoved = onLocationRemoved;

        vm.canEditPlacementStartDate = canEditPlacementStartDate;
        vm.canEditCampaignStartDate = canEditCampaignStartDate;
        vm.canEditPlacementStatus = canEditPlacementStatus;
        vm.closeNewUserPanel = closeNewUserPanel;
        vm.createNewTag = createNewTag;
        vm.discardEdits = discardEdits;
        vm.groupByUsersOrAccounts = groupByUsersOrAccounts;
        vm.onNewTag = onNewTag;
        vm.refreshAccountAndUserList = refreshAccountAndUserList;
        vm.save = save;
        vm.showChangeAdwordsBudgetModal = showChangeAdwordsBudgetModal;
        vm.showChangeFacebookBudgetModal = showChangeFacebookBudgetModal;
        vm.showChangeInstagramBudgetModal = showChangeInstagramBudgetModal;
        vm.showChangeTotalBudgetModal = showChangeTotalBudgetModal;
        vm.showMergeModal = showMergeModal;
        vm.showCopyModal = showCopyModal;
        vm.showNewUserModal = showNewUserModal;
        vm.showPlacementSettingsModal = showPlacementSettingsModal;
        vm.showCampaignSettingsModal = showCampaignSettingsModal;
        vm.showCampaignFinanceSettingsModal = showCampaignFinanceSettingsModal;
        vm.showDiscardEditsModal = showDiscardEditsModal;
        vm.showHourlyScheduleModal = showHourlyScheduleModal;
        vm.showUpdateAdModal = showUpdateAdModal;
        vm.showUpdatePreviewModal = showUpdatePreviewModal;
        vm.updateUserAccess = updateUserAccess;
        vm.getThumbnailCellRowspan = tvlCampaignUtils.getThumbnailCellRowspan;
        vm.countryCellApplies = tvlCampaignUtils.countryCellApplies;
        vm.getCountryCellRowspan = tvlCampaignUtils.getCountryCellRowspan;
        vm.thumbnailCellApplies = tvlCampaignUtils.thumbnailCellApplies;
        vm.isInGoogleDisplay = tvlCampaignUtils.isCampaignPresentInGoogleDisplay(vm.originalCampaign);
        vm.openDatePicker = openDatePicker;
        vm.showEditEndDateConfirmModalIfStatusChanges = showEditEndDateConfirmModalIfStatusChanges;
        vm.removeRule = removeRule;
        vm.openNewRuleModal = openNewRuleModal;
        vm.openNewCampaignNoteModal = openNewCampaignNoteModal;
        vm.openEditCampaignNoteModal = openEditCampaignNoteModal;
        vm.onPlacementStatusChange = onPlacementStatusChange;
        vm.onCampaignStatusChange = onCampaignStatusChange;
        vm.canTargetLocation = canTargetLocation;
        vm.getKeywordCount = getKeywordCount;
        vm.getPlacementIndexForVideo = getPlacementIndexForVideo;
        vm.getCampaignNotes = getCampaignNotes;
        vm.isPlacementEditable = isPlacementEditable;
        vm.isAdEditable = isAdEditable;
        vm.operatingSystemService = operatingSystemService;
        vm.frequencyCapDiscardValues = null;
        vm.onFrequencyCapValueChanged = onFrequencyCapValueChanged;
        vm.switchShowFrequencyCapForm = switchShowFrequencyCapForm;
        vm.getNetworkTooltip = tvlTooltipUtils.getNetworkTooltip;
        vm.getFormatTooltip = tvlTooltipUtils.getFormatTooltip;
        vm.isScheduleDisabledOnUpdates = isScheduleDisabledOnUpdates;

        // functions and vars from campaign-details directive
        // @todo move somewhere else

        vm.availableInterests = [];
        vm.availableTopics = [];
        vm.availableLocations = [];
        vm.countries = {};

        vm.devices = [{
            value: 'Computer',
            name: 'Desktop'
        }, {
            value: 'Tablet',
            name: 'Tablets'
        }, {
            value: 'Mobile',
            name: 'Mobiles'
        }];

        vm.genders = [{
            value: 1,
            name: 'Male'
        }, {
            value: 2,
            name: 'Female'
        }, {
            value: 3,
            name: 'Both'
        }];

        vm.genderIndex = null;
        vm.languages = {};
        vm.uniqueVideos = {};
        vm.onAfterSaveGender = onAfterSaveGender;
        vm.searchInterests = searchInterests;
        vm.searchTopics = searchTopics;
        vm.checkLocationsOverlap = checkLocationsOverlap;
        vm.searchLocations = searchLocations;
        vm.isThumbnailUpdated = isThumbnailUpdated;
        vm.appliesToAdwords = tvlCampaignUtils.appliesToAdwords;
        vm.appliesToGoogleDisplayFormats = tvlCampaignUtils.appliesToGoogleDisplayFormats;
        vm.appliesToDesktops = tvlCampaignUtils.appliesToDesktops;
        vm.appliesToFacebook = tvlCampaignUtils.appliesToFacebook;
        vm.appliesToInstagram = tvlCampaignUtils.appliesToInstagram;
        vm.appliesToMobiles = tvlCampaignUtils.appliesToMobiles;
        vm.appliesToTablets = tvlCampaignUtils.appliesToTablets;
        vm.getNumberOfAdwordsVideos = tvlCampaignUtils.getNumberOfAdwordsVideos;
        vm.getNumberOfFacebookVideos = tvlCampaignUtils.getNumberOfFacebookVideos;
        vm.getNumberOfInstagramVideos = tvlCampaignUtils.getNumberOfInstagramVideos;
        vm.getUniqueVideos = tvlCampaignUtils.getUniqueVideos;
        vm.getBudgetForCountryAndMedia = tvlCampaignUtils.getBudgetForCountryAndMedia;

        vm.editable = false;
        vm.adEditable = false;
        vm.company = null;
        vm.getAccountInfo = getAccountInfo;
        vm.loggedUser = loggedUser;
        vm.customerAccountId = campaign.customerAccount.id;

        vm.getNetworkIconsForPlacement = tvlConstantUtils.getNetworkIconsForPlacement;
        vm.nameSelectCampaign = 'All Items';
        vm.nameSelectUser = 'All Users';
        vm.selectCampaigns = [{id: vm.nameSelectCampaign, title: vm.nameSelectCampaign}];
        vm.selectUsers = [{id: vm.nameSelectUser, title: vm.nameSelectUser}];

        vm.minDate = moment();
        $scope.minDate = vm.minDate;
        $scope.maxDate = vm.campaign.endDate;

        activate();
        getAccountInfo();

        ////////////////

        function activate() {
            tvlModelChangesUpdater.subscribe(vm.campaign, 'campaign', function (campaign) {
                onCampaignReloaded(campaign);
            });

            calculatePlacementsCost();
            checkIfUserCanAudit();
            checkIfUserCanViewAutomanaged();
            checkIfUserCanSeeCampaignError();
            checkIfUserCanEdit();
            checkIfUserCanEditCampaignSettings();
            checkIfUserCanEditCampaignFinanceSettings();
            checkIfUserCanCampaignNotes(vm.loggedUser);
            if (vm.userCanAudit) {
                getCampaignChangeLogFilters();
                getCampaignChangelog(1);
            }

            vm.unregisterCampaignNotesCurrentPageWatcher = $scope.$watch(
                'vm.campaignNotes.currentPage',
                onCampaignNotesCurrentPageChange
            );
            vm.unregisterChangelogCurrentPageWatcher = $scope.$watch(
                'vm.campaignChangelog.currentPage',
                onChangelogCurrentPageChange
            );
            vm.unregisterRulesLogCurrentPageWatcher = $scope.$watch(
                'vm.campaignRulesLog.currentPage',
                onRulesLogCurrentPageChange
            );
            vm.unregisterUsersAndAccountsWatcher = $scope.$watch(
                'vm.userAccess.usersAndAccounts',
                onUsersAndAccountsChange,
                true
            );
            getCampaignRulesLog(1);
            resetUserAccess();
            initializeSelectedKeywords();
            initializeContents().finally(registerEditWatchers);
            checkPlacementNetworks();
            initPlacementsDateFields();

            backupFrequencyCapDiscardValues();
            initShowFrequencyCapForm();

            vm.countries = _.zipObject(_.map(countries, 'code'), _.map(countries, 'name'));

            vm.currentTargetingNumber = campaignValidation.countNumberElements(
                vm.campaign,
                vm.includedContents,
                vm.excludedContents
            );

            $scope.$on('$destroy', onDestroy);

            // activate block from campaign-details directive
            // @todo move somewhere else
            vm.startDate = new Date(vm.campaign.startDate);
            vm.endDate = new Date(vm.campaign.endDate);
            vm.uniqueVideos = vm.getUniqueVideos(vm.campaign);
            var campaignGender = _.find(vm.genders, function (gender) {
                if (vm.campaign.gender) {
                    return gender.name.toLowerCase() === vm.campaign.gender;
                }
            });
            if (campaignGender) {
                vm.genderIndex = campaignGender.value;
            }

            getLanguages();

            if (vm.campaign.isLegacyMultiAdGroup && null === vm.campaign.dailyBudgetLimit) {
                let totalDailyBudgetFromPlacements = 0;
                    _.forEach( vm.campaign.placements, function(placement) {
                        totalDailyBudgetFromPlacements += placement.dailyBudgetLimit;
                    });

                vm.campaign.dailyBudgetLimit = totalDailyBudgetFromPlacements;
            }
        }
        vm.shouldShowCopyButton = function () {
            if (areThumbnailsUpdated() === true) {
                return false;
            }

            return vm.userCanEdit
                && !vm.campaign.isImported
                && vm.networkCount === 1
                && vm.campaign.status !== 'canceled';
        };

        vm.shouldShowAddPlacementsButton = function () {

            if (vm.campaign.isImported || vm.networkCount > 1 || ! campaignEndDateIsEqualOrGreaterThanToday()) {
                return false;
            }

            return vm.userCanEdit;
        };

        function calculatePlacementsCost() {
            vm.cost = 0;
            vm.campaign.placements.forEach(function(placement){
                vm.cost += placement.cost;
            });
        }

        //get common targeting data for all placements from normalized campaign
        function initializeTargeting() {
            vm.targeting = {
                keywords: vm.campaign.keywords,
                excludedKeywords: vm.campaign.excludedKeywords,
                topics: vm.campaign.topics,
                excludedTopics: vm.campaign.excludedTopics,
                interests: vm.campaign.interests,
                gender: vm.campaign.gender,
                minAge: vm.campaign.minAge,
                maxAge: vm.campaign.maxAge,
                contentExclusion: vm.campaign.contentExclusion,
                devices: vm.campaign.devices,
                includeUnknownAges: vm.campaign.includeUnknownAges,
                includeUnknownGender: vm.campaign.includeUnknownGender,
                languages: vm.campaign.languages,
                includedContents: vm.includedContents,
                excludedContents: vm.excludedContents
            };
        }

        function initializeContents() {
            vm.includedContents = tvlCampaignUtils.placementsToContents(vm.campaign.placements, true);
            vm.excludedContents = tvlCampaignUtils.placementsToContents(vm.campaign.placements, false);

            var selectedIncludedVideoIds = _.map(_.filter(vm.includedContents, {
                type: 'video'
            }), 'contentId');
            var selectedExcludedVideoIds = _.map(_.filter(vm.excludedContents, {
                type: 'video'
            }), 'contentId');
            var selectedIncludedChannelIds = _.map(_.filter(vm.includedContents, {
                type: 'channel'
            }), 'contentId');
            var selectedExcludedChannelIds = _.map(_.filter(vm.excludedContents, {
                type: 'channel'
            }), 'contentId');

            return $q.all({
                'includedVideos': getSelectedVideos(selectedIncludedVideoIds),
                'excludedVideos': getSelectedVideos(selectedExcludedVideoIds),
                'includedChannels': getSelectedChannels(selectedIncludedChannelIds),
                'excludedChannels': getSelectedChannels(selectedExcludedChannelIds)
            }).then(function (result) {
                var keys = ['includedVideos', 'excludedVideos', 'includedChannels', 'excludedChannels'];
                keys.forEach(function (k) {
                    var contentsKey = k.indexOf('included') >= 0 ? 'includedContents' : 'excludedContents';
                    result[k].forEach(function (item) {
                        var found = vm[contentsKey].find(function (i) {
                            return i.contentId === item.id;
                        });
                        if (found) {
                            found.media = {
                                title: item.title,
                                thumbnail: item.thumbnail,
                            };
                        }
                    });
                });
            });
        }

        function initializeSelectedKeywords() {
            if (!hasKeywordsItems(vm.campaign.keywords)) {
                vm.campaign.keywords.push({
                    items: []
                });
            }
            if (!hasKeywordsItems(vm.campaign.excludedKeywords)) {
                vm.campaign.excludedKeywords.push({
                    items: []
                });
            }

            vm.selectedKeywords = {
                included: cleanKeywords(vm.campaign.keywords),
                excluded: cleanKeywords(vm.campaign.excludedKeywords)
            };
        }

        function hasKeywordsItems(keywords) {
            var hasCustom = false;
            keywords.forEach(function (keyword) {
                if (keyword.items && keyword.items.length > 0) {
                    hasCustom = true;
                }
            });

            return hasCustom;
        }

        /**
         * Returns each keyword with list or items keys, not both.
         *
         * @param keywords
         * @returns {*}
         */
        function cleanKeywords(keywords) {
            keywords.forEach(function (keyword, key) {
                if ('items' in keyword && !keyword.items) {
                    keywords[key] = {
                        'list': keyword.list
                    };
                }
                if ('list' in keyword && !keyword.list) {
                    keywords[key] = {
                        'items': keyword.items
                    };
                }
            });

            return keywords;
        }

        function activateTab(tabName) {
            $state.transitionTo(
                'campaign.show', {
                    id: vm.campaign.id,
                    tab: tabName
                }
            );
        }

        function canEditPlacementStartDate(startDate) {
            var today = moment().startOf('day');
            startDate = moment(startDate).startOf('day');
            return startDate.isAfter(today);
        }

        function canEditCampaignStartDate(startDate) {

            return canEditPlacementStartDate(startDate);
        }

        function campaignEndDateIsEqualOrGreaterThanToday() {
            var today = moment();
            var endDate = moment(vm.campaign.endDate);

            return endDate.endOf('day').diff(today.endOf('day'), 'days', true) >= 0;
        }

        function canEditPlacementStatus(placement) {
            return placement.status !== 'creating' &&
                placement.status !== 'finished' &&
                placement.status !== 'canceled' &&
                !placement.updating &&
                vm.userCanEdit;
        }

        function getSelectedVideos(ids) {

            ids = _.chunk(ids, 50);
            var media = [];
            var promises = [];

            var searchPlatformVideos = function (videoIds) {

                return Video.searchPlatformVideos({
                    'id[]': videoIds,
                    platform: 'youtube'
                })
                    .$promise
                    .then(function (result) {

                        angular.forEach(result.items, function (item) {
                            if (item) {
                                media = media.concat(item);
                            }
                        });

                    });
            };

            if (ids.length > 0) {
                angular.forEach(ids, function (videoIds) {
                    promises.push(searchPlatformVideos(videoIds));
                });
            }

            return $q.all(promises)
                .then(function () {
                    var selected = media;

                    return selected;
                })
                .catch(function () {
                    return media;
                });
        }

        function getSelectedChannels(ids) {

            ids = _.chunk(ids, 50);
            var media = [];
            var promises = [];

            var searchPlatformChannels = function (channelIds) {

                return Channel.searchPlatformChannels({
                    'id[]': channelIds,
                    platform: 'youtube'
                })
                    .$promise
                    .then(function (result) {

                        angular.forEach(result.items, function (item) {
                            if (item) {
                                media = media.concat(item);
                            }
                        });

                    });
            };

            if (ids.length > 0) {
                angular.forEach(ids, function (channelIds) {
                    promises.push(searchPlatformChannels(channelIds));
                });
            }

            return $q.all(promises)
                .then(function () {
                    var selected = media;

                    return selected;
                })
                .catch(function () {
                    return media;
                });
        }

        /**
         * This method should be part of a common component
         */
        function openDatePicker($event, elementOpened) {
            $event.preventDefault();
            $event.stopPropagation();
            vm.ui.datePickersOpened[elementOpened] = !vm.ui.datePickersOpened[elementOpened];
        }

        /**
         * Change the total budget of a network, or of the whole campaign
         * if no network is given.
         *
         * @param {string|null} network
         * @param {Number} newBudget
         * @return {Promise}
         */
        function changeTotalBudget(network, newBudget) {
            var promises = [];
            promises.push(
                updateCampaign({
                    id: vm.campaign.id,
                    budget: newBudget,
                    network: network
                })
            );
            return $q.all(promises);
        }

        function checkIfUserCanAudit() {
            vm.userCanAudit = vm.loggedUser.hasAccountPermission(
                vm.customerAccountId,
                'Campaign.view_audit'
            );
        }

        function checkIfUserCanViewAutomanaged() {
            vm.userCanViewAutomanaged = vm.loggedUser.hasPermission(
                'Campaign.view_automanaged_flag'
            );
        }

        function checkIfUserCanEdit() {
            var userCanEdit = vm.loggedUser.hasAccountPermission(vm.customerAccountId, 'Campaign.update');

            vm.userCanEdit =
                userCanEdit &&
                vm.campaign.customerAccount.enabled;

            vm.adEditable = vm.userCanEdit;
            vm.editable = vm.userCanEdit;
        }

        function checkIfUserCanSeeCampaignError() {
            vm.userCanSeeErrorFlag = vm.loggedUser.hasAccountPermission(
                vm.customerAccountId,
                'Campaign.view_error_flag'
            );
        }

        function checkIfUserCanEditCampaignSettings() {
            var userCanUpdate = vm.loggedUser.hasAccountPermission(
                vm.customerAccountId,
                'Campaign.update'
            );

            vm.userCanEditCampaignSettings = userCanUpdate;

            if (false === vm.campaign.customerAccount.enabled) {
                vm.userCanEditCampaignSettings = false;
            }
        }

        function checkIfUserCanEditCampaignFinanceSettings() {
            var userCanUpdateAdwordsExchangeRate = vm.loggedUser.hasAccountPermission(
                vm.customerAccountId,
                'Campaign.update_adwordsExchangeRate'
            );
            var userCanUpdateFacebookExchangeRate = vm.loggedUser.hasAccountPermission(
                vm.customerAccountId,
                'Campaign.update_facebookExchangeRate'
            );

            vm.userCanEditCampaignFinanceSettings =
                userCanUpdateAdwordsExchangeRate ||
                userCanUpdateFacebookExchangeRate;

            if (false === vm.campaign.customerAccount.enabled) {
                vm.userCanEditCampaignFinanceSettings = false;
            }
        }

        /**
         * Check if user has permissions to view and add notes to campaign.
         *
         * @param user
         */
        function checkIfUserCanCampaignNotes(user) {
            vm.userCanCampaignNotes = vm.loggedUser.hasAccountPermission(
                vm.customerAccountId,
                'Campaign.notes'
            );
        }

        /**
         * Determine on which networks this campaign has at least one
         * placement.
         */
        function checkPlacementNetworks() {
            vm.networkCount = 0;
            vm.networks = {
                adwords: false,
                facebook: false,
                instagram: false
            };
            for (var i in campaign.placements) {
                vm.networks[campaign.placements[i].network] = true;
            }

            for (var k in vm.networks) {
                if (vm.networks[k]) {
                    vm.networkCount++;
                }
            }
        }

        function closeNewUserPanel() {
            if (vm.userAccess.usersAndAccounts.length > 0) {
                var lastTagIndex = vm.userAccess.usersAndAccounts.length - 1;
                if (!vm.userAccess.usersAndAccounts[lastTagIndex].id) {
                    vm.userAccess.usersAndAccounts.splice(lastTagIndex, 1);
                }
            }

            vm.newUserEmail = null;
            vm.showNewEmail = false;
            vm.disableUserSelect = false;
        }

        function createNewTag(newUser) {
            if (!newUser.id) {
                vm.newUserEmail = newUser.email;
                if (tvlForm.validateEmail(newUser.email)) {
                    vm.showNewEmail = true;
                    vm.disableUserSelect = true;
                } else {
                    vm.showNewEmailError = true;
                }
            }
        }

        /**
         * Revert this campaigns' state to its original form and discard
         * all uncommitted changes.
         */
        function discardEdits() {
            unregisterEditWatchers();
            vm.campaign = angular.copy(vm.originalCampaign);
            vm.endDate = new Date(vm.originalCampaign.endDate);
            vm.dirty = false;
            vm.campaignRules = [];
            vm.unsavedChanges = {
                'placement': {},
                'campaign': {},
                'targeting': {},
            };
            discardFrequencyCapChanges();
            initPlacementsDateFields();
            initializeSelectedKeywords();
            resetUserAccess();
            initializeContents().finally(registerEditWatchers);
        }

        function groupByUsersOrAccounts(item) {
            if (item.email) {
                return 'Users';
            } else {
                return 'Accounts';
            }
        }

        function onNewTag(email) {
            if (!tvlForm.validateEmail(email)) {
                return null;
            }
            return {
                id: null,
                name: null,
                email: email
            };
        }

        function refreshAccountAndUserList(searchTerm) {
            var promises = [];
            promises.push(Account.get({
                'term': searchTerm
            }).$promise);
            promises.push(User.get({
                'term': searchTerm
            }).$promise);
            $q.all(promises).then(onAccountAndUserSearchSuccess);

            function onAccountAndUserSearchSuccess(data) {
                vm.accountAndUserList = [];
                angular.forEach(data, function (response) {
                    vm.accountAndUserList = vm.accountAndUserList.concat(cleanResponse(response));
                });
            }

            function cleanResponse(response) {
                // We filter customer account since has been selected previously and user can not link that account again
                var items = response.items.filter(function (item) {
                    if (vm.campaign.customerAccount) {
                        return item.id !== vm.campaign.customerAccount.id;
                    }
                    return true;
                });

                // remove the selected users
                items = _.differenceWith(items, vm.campaign.users, function (mixItem, userItem) {
                    return mixItem.id === userItem.id
                });

                // remove the selected accounts
                items = _.differenceWith(items, vm.campaign.accounts, function (mixItem, accountItem) {
                    return mixItem.id === accountItem.id
                });

                return items;
            }
        }

        /**
         * Fetch the campaign from the API.
         *
         * @return {Promise}
         */
        function getCampaign() {
            return Campaign.get({
                id: vm.campaign.id
            }).$promise;
        }

        /**
         * Return the total budget of a single network.
         *
         * @param {string} network
         * @return {Number}
         */
        function getNetworkBudget(network) {
            return _.chain(vm.campaign.placements)
                .filter({
                    network: network
                })
                .map('budget')
                .reduce(function (sum, n) {
                    return sum + n;
                })
                .value();
        }

        /**
         * Return the total cost of a single network.
         *
         * @param {string} network
         * @return {Number}
         */
        function getNetworkCost(network) {
            return _.chain(vm.campaign.placements)
                .filter({
                    network: network
                })
                .map('cost')
                .reduce(function (sum, n) {
                    return sum + n;
                })
                .value();
        }

        function getKeywordCount(keywords) {
            var count = 0;
            keywords.forEach(function (keywordObject) {
                if (keywordObject.hasOwnProperty('items')) {
                    count += keywordObject.items.length;
                } else {
                    count += keywordObject.list.nItems;
                }

            });

            return count;
        }

        /**
         * Callback for changelog pagination changes.
         */
        function onChangelogCurrentPageChange(newVal, oldVal) {
            if (newVal !== oldVal && newVal > 0) {
                getCampaignChangelog(newVal);
            }
        }

        /**
         * Callback for campaign notes pagination changes.
         *
         * @param newVal
         * @param oldVal
         */
        function onCampaignNotesCurrentPageChange(newVal, oldVal) {
            if (newVal !== oldVal && newVal > 0) {
                getCampaignNotes(newVal);
            }
        }

        /**
         * Callback for rules log pagination changes.
         */
        function onRulesLogCurrentPageChange(newVal, oldVal) {
            if (newVal !== oldVal && newVal > 0) {
                getCampaignRulesLog(newVal);
            }
        }

        function onUsersAndAccountsChange(newValue, oldValue) {
            if (newValue !== oldValue &&
                _.xor(newValue, _.concat(vm.campaign.users, vm.campaign.accounts)).length !== 0
            ) {
                vm.userAccessHasChanged = true;
            }
        }

        /**
         * Callback to be invoked when this controller is being destroyed.
         */
        function onDestroy() {
            unregisterEditWatchers();
            vm.unregisterChangelogCurrentPageWatcher();
            vm.unregisterRulesLogCurrentPageWatcher();
            vm.unregisterUsersAndAccountsWatcher();
            vm.unregisterCampaignNotesCurrentPageWatcher();
            tvlModelChangesUpdater.unsubscribe(vm.campaign, 'campaign');
        }

        /**
         * Set the dirty flag whenever an editable targeting field is changed.
         *
         * @param {String} fieldName
         * @param {Object} newVal
         * @param {Object} oldVal
         */
        function onEditableTargetingFieldChanged(fieldName, newVal, oldVal) {
            if (newVal === oldVal) {
                return;
            }

            vm.dirty = true;

            vm.campaign.placements.forEach(function (placement) {
                var newValCopy = angular.copy(newVal);
                if (fieldName == 'includedContents' || fieldName == 'excludedContents' || fieldName == 'keywords' || fieldName == 'excludedKeywords') {
                    newVal.forEach(function (content, index) {
                        if (content.list) {
                            newValCopy[index] = {list: content.list.id, type: 'list'};
                        }
                    });
                }
                markFieldAsChanged('targeting', placement.targeting.id, fieldName, newValCopy);
            });

        }

        /**
         * Set the dirty flag whenever an editable field is changed.
         *
         * @param {String} fieldName
         * @param {Object} newVal
         * @param {Object} oldVal
         */
        function onEditableFieldChanged(fieldName, newVal, oldVal) {
            if (newVal === oldVal) {
                return;
            }

            vm.dirty = true;
            if (fieldName) {
                markFieldAsChanged('campaign', vm.campaign.id, fieldName, newVal);
            }
        }

        function initPlacementsDateFields() {
            for (var i in vm.campaign.placements) {
                vm.campaign.placements[i].startDate = new Date(vm.campaign.placements[i].startDate);
                vm.campaign.placements[i].endDate = new Date(vm.campaign.placements[i].endDate);
            }
        }

        /**
         * Register all watchers that listen to changes
         * on editable fields.
         */
        function registerEditWatchers() {
            initializeTargeting();

            vm.unregisterEditWatcherFns = [
                $scope.$watch('vm.campaign.name', onEditableFieldChanged.bind(null, 'name'), true),
                $scope.$watch('vm.targeting.minAge', onEditableTargetingFieldChanged.bind(null, 'minAge'), true),
                $scope.$watch('vm.targeting.maxAge', onEditableTargetingFieldChanged.bind(null, 'maxAge'), true),
                $scope.$watch('vm.targeting.gender', onEditableTargetingFieldChanged.bind(null, 'gender'), true),
                $scope.$watch('vm.targeting.languages', onEditableTargetingFieldChanged.bind(null, 'languages'), true),
                $scope.$watch('vm.targeting.interests', onEditableTargetingFieldChanged.bind(null, 'interests'), true),
                $scope.$watch('vm.startDate', onEditableFieldChanged.bind(null, 'startDate'), true),
                $scope.$watch('vm.endDate', onEditableFieldChanged.bind(null, 'endDate'), true),
                $scope.$watch('vm.targeting.topics', onEditableTargetingFieldChanged.bind(null, 'topics'), true),
                $scope.$watch('vm.targeting.excludedTopics', onEditableTargetingFieldChanged.bind(null, 'excludedTopics'), true),
                $scope.$watch('vm.targeting.devices', onEditableTargetingFieldChanged.bind(null, 'devices'), true),
                $scope.$watch('vm.campaign.users', onEditableFieldChanged.bind(null, 'users'), true),
                $scope.$watch('vm.campaign.dailyBudgetLimit', onEditableFieldChanged.bind(null, 'dailyBudgetLimit'), true),
                $scope.$watch('vm.campaign.multiAdgroupFixedAmount', onEditableFieldChanged.bind(null, 'multiAdgroupFixedAmount'), true),
                $scope.$watch('vm.campaign.accounts', onEditableFieldChanged.bind(null, 'accounts'), true),
                $scope.$watch('vm.campaign.includeContentStats', onEditableFieldChanged.bind(null, 'includeContentStats'), true),
                $scope.$watch('vm.campaign.simulated', onEditableFieldChanged.bind(null, 'simulated'), true),
                $scope.$watch('vm.campaign.automanaged', onEditableFieldChanged.bind(null, 'automanaged'), true),
                $scope.$watch('vm.campaign.status', onEditableFieldChanged.bind(null, 'status'), true),
                $scope.$watch('vm.campaign.adwordsExchangeRate', onEditableFieldChanged.bind(null, 'adwordsExchangeRate'), true),
                $scope.$watch('vm.campaign.facebookExchangeRate', onEditableFieldChanged.bind(null, 'facebookExchangeRate'), true),
                $scope.$watchCollection('vm.campaign.rules', onEditableFieldChanged.bind(null, 'rules'), true),
                $scope.$watch('vm.selectedKeywords.included', onEditableTargetingFieldChanged.bind(null, 'keywords'), true),
                $scope.$watch('vm.selectedKeywords.excluded', onEditableTargetingFieldChanged.bind(null, 'excludedKeywords'), true),
                $scope.$watch('vm.targeting.contentExclusion', onEditableTargetingFieldChanged.bind(null, 'contentExclusion'), true),
                $scope.$watchCollection('vm.campaign.locations', campaignLocationsChanged, true),
                $scope.$watch('vm.campaign.frequencyCapViews', onEditableFieldChanged.bind(null, 'frequencyCapViews'), true),
                $scope.$watch('vm.campaign.frequencyCapViewsTime', onEditableFieldChanged.bind(null, 'frequencyCapViewsTime'), true),
                $scope.$watch('vm.campaign.frequencyCapImpressions', onEditableFieldChanged.bind(null, 'frequencyCapImpressions'), true),
                $scope.$watch('vm.campaign.frequencyCapImpressionsTime', onEditableFieldChanged.bind(null, 'frequencyCapImpressionsTime'), true),
                $scope.$watch('vm.targeting.includeUnknownAges', onEditableTargetingFieldChanged.bind(null, 'includeUnknownAges'), true),
                $scope.$watch('vm.targeting.includeUnknownGender', onEditableTargetingFieldChanged.bind(null, 'includeUnknownGender'), true),
                $scope.$watch('vm.campaign.inventoryType', onEditableFieldChanged.bind(null, 'inventoryType'), true),
                $scope.$watchCollection('vm.targeting.includedContents', onEditableTargetingFieldChanged.bind(null, 'includedContents'), true),
                $scope.$watchCollection('vm.targeting.excludedContents', onEditableTargetingFieldChanged.bind(null, 'excludedContents'), true),
                /*
                 * This watcher will be used when adding/removing new placements
                 * is implemented.
                 */
                // $scope.$watchCollection('vm.campaign.placements', onPlacementsChanged),
            ];

            for (var i in vm.campaign.placements) {
                vm.unregisterEditWatcherFns.push(
                    $scope.$watch('vm.campaign.placements[' + i + ']', onPlacementChanged, true)
                );

                vm.unregisterEditWatcherFns.push(
                    $scope.$watch('vm.campaign.placements[' + i + '].newAdVideo', onAdVideoChanged, true)
                );
            }
        }

        function campaignLocationsChanged(newValue, oldValue) {
            if (newValue === oldValue) {
                return;
            }

            var countriesWithoutLocations = _.clone(vm.campaign.countries);
            _.forEach(vm.campaign.countries, function (country) {
                _.forEach(newValue, function (location) {
                    if (location.country === country) {
                        var index = countriesWithoutLocations.indexOf(country);
                        if (index > -1) {
                            countriesWithoutLocations.splice(index, 1);
                        }
                    }
                });
            });

            if (countriesWithoutLocations.length > 0) {
                getCountriesByIds(countriesWithoutLocations)
                    .then(onGetCountriesSuccess)
                    .catch(onGetCountriesError);
            } else {
                changePlacementsLocations(newValue);
            }

            function onGetCountriesSuccess(data) {
                vm.campaign.locations = _.concat(vm.campaign.locations, _.toArray(data.items));
                changePlacementsLocations(vm.campaign.locations);
            }

            function onGetCountriesError(reason) {
                toastr.error('Our apologies, we have been unable to retrieve locations for you. Please, try again in a few moments.');
            }
        }

        function changePlacementsLocations(locations) {
            _.forEach(vm.campaign.placements, function (placement) {
                placement.targeting.locations = _.filter(locations, ['country', placement.country]);
            });
        }

        function getCountriesByIds(ids) {
            var params = {
                'ids[]': ids
            };
            return Location.get(params).$promise;
        }

        /**
         * Handle a change in a placement.
         *
         * This function will set the modified fields as dirty so that only
         * those are sent to the API when the user commits all changes.
         *
         * @param {Object} newVal
         * @param {Object} oldVal
         */
        function onPlacementChanged(newVal, oldVal) {
            if (!newVal && !oldVal) {
                return;
            }

            var id = newVal.id;
            var network = newVal.network;

            if (newVal.startDate.getTime() !== oldVal.startDate.getTime()) {
                var startDate = moment(newVal.startDate).utc().format('YYYY-MM-DD HH:mm:ss');
                markFieldAsChanged('placement', id, 'startDate', startDate);
            }

            if (newVal.endDate.getTime() !== oldVal.endDate.getTime()) {

                var endDate = moment(newVal.endDate).hour(23).minute(59).seconds(59).utc().format('YYYY-MM-DD HH:mm:ss');
                var onConfirm = function () {
                    markFieldAsChanged('placement', id, 'endDate', endDate);
                };

                var onCancel = function () {
                    newVal.endDate = oldVal.endDate;
                };

                showEditEndDateConfirmModalIfStatusChanges(newVal, onConfirm, onCancel);

            }

            if (newVal.budget !== oldVal.budget) {
                markFieldAsChanged('placement', id, 'budget', newVal.budget);
            }

            if (newVal.dailyBudgetLimit !== oldVal.dailyBudgetLimit) {
                markFieldAsChanged('placement', id, 'dailyBudgetLimit', newVal.dailyBudgetLimit);
            }

            if (newVal.automanaged !== oldVal.automanaged) {
                markFieldAsChanged('placement', id, 'automanaged', newVal.automanaged);
            }

            if (newVal.facebookCampaignId !== oldVal.facebookCampaignId) {
                markFieldAsChanged('placement', id, 'facebookCampaignId', newVal.facebookCampaignId);
            }

            if (newVal.adwordsCampaignId !== oldVal.adwordsCampaignId) {
                markFieldAsChanged('placement', id, 'adwordsCampaignId', newVal.adwordsCampaignId);
            }

            if (newVal.adwordsAdGroupId !== oldVal.adwordsAdGroupId) {
                markFieldAsChanged('placement', id, 'adwordsAdGroupId', newVal.adwordsAdGroupId);
            }

            if (newVal.fixedCpv !== oldVal.fixedCpv) {
                markFieldAsChanged('placement', id, 'fixedCpv', newVal.fixedCpv);
            }

            if (newVal.biddingMaxCpv !== oldVal.biddingMaxCpv) {
                markFieldAsChanged('placement', id, 'biddingMaxCpv', newVal.biddingMaxCpv);
            }

            if (newVal.fixedCpm !== oldVal.fixedCpm) {
                markFieldAsChanged('placement', id, 'fixedCpm', newVal.fixedCpm);
            }

            if (newVal.biddingMaxCpm !== oldVal.biddingMaxCpm) {
                markFieldAsChanged('placement', id, 'biddingMaxCpm', newVal.biddingMaxCpm);
            }

            if (newVal.fixedCpc !== oldVal.fixedCpc) {
                markFieldAsChanged('placement', id, 'fixedCpc', newVal.fixedCpc);
            }

            if (newVal.biddingMaxCpc !== oldVal.biddingMaxCpc) {
                markFieldAsChanged('placement', id, 'biddingMaxCpc', newVal.biddingMaxCpc);
            }

            if (newVal.fixedCpa !== oldVal.fixedCpa) {
                markFieldAsChanged('placement', id, 'fixedCpa', newVal.fixedCpa);
            }

            if (newVal.biddingMaxCpa !== oldVal.biddingMaxCpa) {
                markFieldAsChanged('placement', id, 'biddingMaxCpa', newVal.biddingMaxCpa);
            }

            if (newVal.status !== oldVal.status) {
                markFieldAsChanged('placement', id, 'status', newVal.status);
            }

            if (angular.equals(newVal.locations, oldVal.locations) === false) {
                markFieldAsChanged('placement', id, 'locations', newVal.locations);
            }

            if (angular.equals(newVal.timeSlots, oldVal.timeSlots) === false) {
                markFieldAsChanged('placement', id, 'timeSlots', newVal.timeSlots);
            }

            if (angular.equals(newVal.targeting.locations, oldVal.targeting.locations) === false) {
                markFieldAsChanged('targeting', newVal.targeting.id, 'locations', newVal.targeting.locations);
            }

            /*
             * Additional editable placement attributes should be checked here.
             */
            if (angular.equals(newVal.markup, oldVal.markup) === false) {
                markFieldAsChanged('placement', id, 'markup', newVal.markup);
            }
        }

        /**
         * Marks ad video as changed to be PUT to ad video endpoint.
         *
         * @param newVal
         * @param oldVal
         */
        function onAdVideoChanged(newVal, oldVal) {
            if (newVal === undefined) {
                return;
            }
            markFieldAsChanged('adVideo', newVal.id, null, newVal);
        }

        /**
         * Flag a placement or campaign field as dirty, also store the change
         * in the pending changes set.
         *
         * Pass a null field to update the whole document.
         *
         * @param {String} type
         * @param {String} id
         * @param {String} field
         * @param {*} value
         */
        function markFieldAsChanged(type, id, field, value) {
            if (!vm.unsavedChanges[type].hasOwnProperty(id)) {
                vm.unsavedChanges[type][id] = {};
            }
            if (field === null) {
                vm.unsavedChanges[type][id] = value;
            } else {
                vm.unsavedChanges[type][id][field] = value;
            }
            vm.dirty = true;
        }

        function reloadCampaign() {
            getCampaign().then(function (campaign) {
                onCampaignReloaded(campaign);
            });
        }

        function onCampaignReloaded(campaign) {

            $state.go($state.current, {}, {reload: true});
            // clear watchers so that they don't fire when overwriting the campaign
            unregisterEditWatchers();

            vm.campaign = campaign;
            vm.originalCampaign = angular.copy(campaign);
            vm.dirty = false;
            vm.unsavedChanges = {
                'placement': {},
                'campaign': {},
                'targeting': {},
            };

            checkIfUserCanEdit();
            resetUserAccess();
            backupFrequencyCapDiscardValues();
            initShowFrequencyCapForm();
            initializeSelectedKeywords();
            initializeContents().finally(registerEditWatchers);

            // update placement networks
            checkPlacementNetworks();

            // reinitialize placement dates
            initPlacementsDateFields();

            return campaign;
        }

        function resetUserAccess() {
            vm.userAccess.users = vm.campaign.users;
            vm.userAccess.accounts = vm.campaign.accounts;
            vm.userAccess.usersAndAccounts = _.concat(vm.campaign.users, vm.campaign.accounts);
            vm.userAccessHasChanged = false;
        }

        function save() {
            if (!campaignValidation.validateUpdateTargeting(
                vm.campaign,
                vm.targeting.includedContents,
                vm.targeting.excludedContents,
                vm.currentTargetingNumber
            )) {
                return false;
            }

            if (vm.unsavedChanges['campaign'].hasOwnProperty(vm.campaign.id) &&
                vm.unsavedChanges['campaign'][vm.campaign.id].hasOwnProperty('endDate') &&
                !campaignValidation.validateEndDate(vm.endDate)
            ) {
                return false;
            }

            vm.ui.savingCampaign = true;

            var promises = [];

            for (var type in vm.unsavedChanges) {
                for (var objectId in vm.unsavedChanges[type]) {
                    var data = vm.unsavedChanges[type][objectId];
                    data.id = objectId;
                    if (type === 'campaign') {
                        promises.push(updateCampaign(data));
                    } else if (type === 'placement') {
                        promises.push(updatePlacement(data));
                    } else if (type === 'targeting') {
                        promises.push(updateTargeting(data));
                    } else if (type === 'adVideo') {
                        promises.push(updateAdVideo(data));
                    }
                }
            }

            return $q.all(promises)
                .then(onSaveCampaignSuccess)
                .then(reloadCampaign)
                .catch(resourceResponse.error)
                .finally(onSaveCampaignFinally);

            function onCreateRuleSuccess(campaign) {
                vm.campaign = campaign;
                reloadCampaign();
            }

            function onCreateRuleError(error) {
                toastr.error('Our apologies, we have been unable to create the rules.');
            }

            function onSaveCampaignSuccess() {
                toastr.info('Good! Your changes have been saved and will be applied soon.');
                //if we have rules to save, we save them now
                vm.campaignRules.forEach(function (rule) {
                    var ruleLocations = [];
                    if (rule.locations) {
                        rule.locations.forEach(function (location) {
                            ruleLocations.push(location.id);
                        });
                        rule.locations = ruleLocations;
                    }
                    Rule.createRule({
                        id: vm.campaign.id
                    }, rule)
                        .$promise
                        .then(onCreateRuleSuccess, onCreateRuleError);
                });

                if (vm.getKeywordCount(vm.campaign.keywords) > vm.ADWORDS_MAX_ALLOWED_KEYWORDS) {
                    toastr.warning('The number of keywords exceeds the limit allowed by AdWords Manager. Some keywords will not be sent.');
                }

                if (vm.getKeywordCount(vm.campaign.excludedKeywords) > vm.ADWORDS_MAX_ALLOWED_KEYWORDS) {
                    toastr.warning('The number of excluded keywords exceeds the limit allowed by AdWords Manager. Some excluded keywords will not be sent.');
                }
            }

            function onSaveCampaignFinally() {
                vm.ui.savingCampaign = false;
                vm.campaignRules = [];
            }
        }

        /**
         * Show the placement settings modal
         *
         * @return {Promise}
         */
        function showPlacementSettingsModal(placement) {
            var modal = $uibModal.open({
                templateUrl: 'campaign/show/placement-settings-modal.html',
                controller: 'PlacementSettingsModalController as vm',
                bindToController: true,
                resolve: {
                    placement: placement,
                    campaign: campaign,
                    loggedUser: vm.loggedUser
                }
            });
        }

        /**
         * Show the campaign settings modal.
         *
         * @return {Promise}
         */
        function showCampaignSettingsModal() {
            var modal = $uibModal.open({
                templateUrl: 'campaign/show/campaign-settings-modal.html',
                controller: 'CampaignSettingsModalController as vm',
                bindToController: true,
                resolve: {
                    campaign: angular.copy(vm.campaign),
                    user: vm.loggedUser
                }
            });
            return modal.result.then(onCampaignSettingsChanged);

            function onCampaignSettingsChanged(settings) {
                vm.campaign = _.merge(vm.campaign, settings);
            }
        }

        /**
         * Show the campaign finance settings modal.
         *
         * @return {Promise}
         */
        function showCampaignFinanceSettingsModal() {
            var modal = $uibModal.open({
                templateUrl: 'campaign/show/campaign-finance-settings-modal.html',
                controller: 'CampaignFinanceSettingsModalController as vm',
                bindToController: true,
                resolve: {
                    campaign: angular.copy(vm.campaign),
                    user: vm.loggedUser
                }
            });
            return modal.result.then(onCampaignFinanceSettingsChanged);

            function onCampaignFinanceSettingsChanged(settings) {
                reloadCampaign();
            }
        }

        /**
         * Launch the budget change flow for Adwords.
         *
         * @return {Promise}
         */
        function showChangeAdwordsBudgetModal() {
            return showChangeTotalBudgetModal('adwords');
        }

        /**
         * Launch the budget change flow for Facebook.
         *
         * @return {Promise}
         */
        function showChangeFacebookBudgetModal() {
            return showChangeTotalBudgetModal('facebook');
        }

        /**
         * Launch the budget change flow for Instagram.
         *
         * @return {Promise}
         */
        function showChangeInstagramBudgetModal() {
            return showChangeTotalBudgetModal('instagram');
        }

        /**
         * Launch the budget change flow the whole campaign, or a single
         * network if one is given.
         *
         * @param {string|null} network
         * @return {Promise}
         */
        function showChangeTotalBudgetModal(network) {
            var opts = {
                closeOnConfirm: false,
                inputType: 'number',
                showCancelButton: true,
            };
            return tvlSweetAlert
                .prompt(
                    'New budget',
                    'Enter the new total budget:',
                    opts,
                    validateNewBudget.bind(this, network)
                )
                .catch(onModalCanceled)
                .then(onNewTotalBudget)
                .then(changeTotalBudget.bind(this, network))
                .then(onChangeTotalBudgetSuccess)
                .catch(onChangeTotalBudgetError);

            function onModalCanceled() {
                // just break the promise chain
                $q.reject();
            }

            function onNewTotalBudget(value) {
                var currentTotal = network ? getNetworkBudget(network) : vm.campaign.budget;
                var newTotal = value;
                var diff = newTotal - currentTotal;

                var target = network ? tvlCampaignUtils.getNetworkDisplayName(network) : 'total';

                var title = 'Are you sure?';
                var msg = 'You are about to modify this campaign\'s ' + target + ' budget,' +
                    ' from ' + $filter('number')(currentTotal, 2) + ' to ' + $filter('number')(newTotal, 2) + '.' +
                    ' That is an increase of ' + $filter('number')(diff, 2) + '. Is this correct?';

                /*
                 * show warning only if more than one placement will be
                 * affected by the change
                 */
                var moreThanOnePlacement = network ?
                    (_.filter(vm.campaign.placements, {
                        network: network
                    }).length > 2) :
                    vm.campaign.placements.length > 1;
                if (moreThanOnePlacement) {
                    msg += '\n\nThe new budget will be proportionally distributed between all the existing placements.';
                }

                var opts = {
                    closeOnConfirm: false,
                    showLoaderOnConfirm: true,
                    confirmButtonText: 'Yes, it is correct'
                };
                return tvlSweetAlert.confirm(title, msg, opts)
                    .then(function () {
                        return Number.parseFloat(value);
                    });
            }

            function onChangeTotalBudgetSuccess() {
                reloadCampaign();
                tvlSweetAlert.success('Success!', 'Budget has been successfully updated');
            }

            function onChangeTotalBudgetError() {
                reloadCampaign();
                tvlSweetAlert.error(
                    'Our apologies',
                    'We have been unable to modify your campaign.\n\n' +
                    'Please, try again in a few minutes.'
                );
            }
        }

        /**
         * Show the merge campaign modal.
         *
         * @return {Promise}
         */
        function showMergeModal() {
            var modal = $uibModal.open({
                templateUrl: 'campaign/show/merge-campaign-modal.html',
                controller: 'MergeCampaignModalController as modal',
                bindToController: true,
                resolve: {
                    sourceCampaign: vm.campaign
                }
            });
            return modal.result.then(onMergeSuccess);

            function onMergeSuccess(mergedCampaign) {
                toastr.success('Your campaigns have been successfully merged.');
                $state.go('campaign.show', {
                    id: mergedCampaign.id
                });
            }
        }

        function showCopyModal() {
            var campaign = {...vm.campaign};
            var campaignLocations = {...vm.campaign.locations};

            campaign.locations = [];

            var modal = $uibModal.open({
                templateUrl: 'campaign/show/copy-campaign-modal.html',
                controller: 'CopyCampaignModalController',
                resolve: {
                    sourceCampaign: campaign,
                    oldCampaignLocations: campaignLocations,
                },
                backdrop: 'static'
            });
            return modal.result.then(onSuccess);

            function onSuccess(copiedCampaign) {
                toastr.success('Your campaign have been successfully copied.');
                $state.go('campaign.show', {
                    id: copiedCampaign.id
                });
            }
        }

        /**
         * Show the new user modal.
         *
         * @return {Promise}
         */
        function showNewUserModal() {
            var modal = $uibModal.open({
                templateUrl: 'campaign/show/new-user-modal.html',
                controller: 'NewUserModalController as vm',
                bindToController: true,
                resolve: {
                    user: {
                        email: vm.newUserEmail
                    },
                    company: vm.company
                }
            });

            if (vm.newUserEmail) {
                vm.closeNewUserPanel();
            }

            modal.result.then(addNewUserToCampaign);

            function addNewUserToCampaign(user) {
                vm.userAccess.usersAndAccounts.push(user);
            }
        }

        /**
         * Show the discard edits confirmation modals and, if confirmed,
         * discards all unsaved changes made to this campaign.
         *
         * @return {Promise}
         */
        function showDiscardEditsModal() {
            var title = 'Discard changes?';
            var msg = 'Your changes to this campaign have not yet been committed. Are you sure you want to discard them?';
            return tvlSweetAlert
                .confirm(title, msg, 'Yes, discard them', 'No')
                .then(discardEdits);
        }

        function onHourlyScheduleModalSaved(placement, data) {
            if (data.timeSlots.length) {
                placement.timeSlots = data.timeSlots;
            } else {
                placement.timeSlots = null;
            }
        }

        function showHourlyScheduleModal(placement) {
            var modal = $uibModal.open({
                templateUrl: 'campaign/hourly-schedule-modal.html',
                controller: 'HourlyScheduleModalController as vm',
                bindToController: true,
                resolve: {
                    timeSlots: function () {
                        return placement.timeSlots ? angular.copy(placement.timeSlots) : [];
                    }
                }
            });

            return modal.result.then(onHourlyScheduleModalSaved.bind(null, placement));
        }

        /**
         * Show the new ad video modal.
         *
         * @param placement
         * @return {Promise}
         */
        function showUpdatePreviewModal(placement) {
            showUpdateAdModal(placement, true)
        }

        /**
         * Show the new ad video modal.
         *
         * @param placement
         * @return {Promise}
         */
        function showUpdateAdModal(placement, updatePreview=false) {

            var templateUrl = 'campaign/show/update-advideo-modal.html';
            var controller = 'UpdateAdVideoModalController as vm';
            var placementData = null;
            var ad = placement.adGroups[0].ads[0];

            if (ad.isDisplay) {
                templateUrl = 'campaign/wizard/google-display-image-ad-modal.html';
                controller = 'GoogleDisplayImageAdModalController as vm';
            }

            if (tvlAdVideoUtils.isFacebookAnyAdVideoFormat(ad.format) ||
                tvlAdVideoUtils.isFacebookExternalAdVideoFormat(ad.format)) {

                placementData = placement;

                templateUrl = 'campaign/show/update-facebook-advideo-modal-on-network.html';
                controller = 'UpdateFacebookAdVideoModalOnNetworkController as vm';
            }

            if (updatePreview) {
                templateUrl = 'campaign/show/update-facebook-advideo-modal.html';
                controller = 'UpdateFacebookAdVideoModalController as vm';
            }


            var modal = $uibModal.open({
                templateUrl: templateUrl,
                controller: controller,
                bindToController: true,
                resolve: {
                    adInfo: angular.copy(getAdInfo(placement)),
                    update: true,
                    placement: placementData
                }
            });

            return modal.result.then(updateCampaignAdVideo);

            /**
             * Processes the result of UpdateAdVideoModalController.
             *
             * @param result
             */
            function updateCampaignAdVideo(result) {
                var adVideo = result.adVideo;
                delete adVideo.image;
                var placementIndex = vm.getPlacementIndexForVideo(adVideo);
                vm.campaign.placements[placementIndex].newAdVideo = angular.copy(adVideo);
            }

            /**
             * @param placement
             * @returns {*}
             */
            function getAdInfo(placement) {
                if (placement.newAdVideo !== undefined) {
                    throw 'Placement has no ad-video!';
                }
                var adInfo = placement.adGroups[0].ads[0];

                if (tvlAdVideoUtils.isFacebookAnyAdVideoFormat(adInfo.format) ||
                    tvlAdVideoUtils.isFacebookExternalAdVideoFormat(adInfo.format)) {
                    adInfo.videoTitle = adInfo.video.title;
                    adInfo.videoThumbnail= adInfo.video.thumbnail;
                    adInfo.customerAccountId= vm.campaign.customerAccount.id;
                    adInfo.facebookAdAccountId= placement.facebookAccountId;

                    return adInfo;
                }

                return {
                    videoUrl: (adInfo.isVideo) ? tvlUrl.getYoutubeVideoUrlById(adInfo.video.videoId) : '',
                    targetUrl: adInfo.targetUrl,
                    displayUrl: adInfo.displayUrl,
                    finalUrl: adInfo.finalUrl,
                    // image: adInfo.image,
                    imageName: adInfo.imageName,
                    imageContent: adInfo.imageContent,
                    headline: adInfo.headline,
                    firstDescription: adInfo.firstDescription,
                    secondDescription: adInfo.secondDescription,
                    landingPage: adInfo.landingPage,
                    trackingUrl: adInfo.trackingUrl,
                    format: adInfo.format,
                    id: adInfo.id,
                    companionBanner: adInfo.companionBanner,
                    networkName: adInfo.networkName,
                    callToAction: adInfo.callToAction,
                    goal: adInfo.goal,
                    biddingType: adInfo.biddingStrategyType
                };
            }
        }

        /**
         * @param adVideo
         * @returns {string}
         */
        function getPlacementIndexForVideo(adVideo) {
            for (var index in vm.campaign.placements) {
                var placement = vm.campaign.placements[index];
                if (placement.adGroups[0].ads[0].id === adVideo.id) {
                    return index;
                }
            }
        }

        function updateUserAccess() {
            vm.campaign.users = _.filter(
                vm.userAccess.usersAndAccounts,
                function (item) {
                    return item.hasOwnProperty('email');
                }
            );
            vm.campaign.accounts = _.filter(
                vm.userAccess.usersAndAccounts,
                function (item) {
                    return !item.hasOwnProperty('email');
                }
            );
            vm.userAccessHasChanged = false;
        }

        /**
         * Unregister all watchers that listen to changes
         * on editable fields.
         */
        function unregisterEditWatchers() {
            for (var i in vm.unregisterEditWatcherFns) {
                vm.unregisterEditWatcherFns[i]();
            }
        }

        function updateCampaign(data) {
            if (data.hasOwnProperty('users')) {
                data['users'] = _.map(vm.campaign.users, 'id');
            }

            if (data.hasOwnProperty('accounts')) {
                data['accounts'] = _.map(vm.campaign.accounts, 'id');
            }

            if (data.hasOwnProperty('rules')) {
                data['rules'] = preprocessRulesForUpdate(vm.campaign.rules);
            }

            if (data.startDate) {
                data.startDate = moment(data.startDate).hour(0).minute(0).seconds(0).format('YYYY-MM-DD HH:mm:ss');
            }

            if (data.endDate) {
                data.endDate = moment(data.endDate).hour(23).minute(59).seconds(59).format('YYYY-MM-DD HH:mm:ss');
            }

            var campaign = new Campaign(data);
            return campaign.$save();
        }

        function updatePlacement(data) {
            if (data.startDate) {
                data.startDate = moment(data.startDate).hour(0).minute(0).seconds(0).format('YYYY-MM-DD HH:mm:ss');
            }

            if (data.endDate) {
                data.endDate = moment(data.endDate).hour(23).minute(59).seconds(59).format('YYYY-MM-DD HH:mm:ss');
            }

            if (data.locations) {
                data.locations = _.map(data.locations, 'id');
            }

            var placement = new Placement(data);
            return placement.$save();
        }

        /**
         * @param data
         * @returns {*}
         */
        function updateAdVideo(data) {
            var adVideo = new AdVideo(data);
            return adVideo.$save();
        }

        function updateTargeting(data) {
            if (data.hasOwnProperty('interests')) {
                data['interests'] = _.map(vm.targeting.interests, 'id');

                if (vm.appliesToFacebook(vm.campaign) || vm.appliesToInstagram(vm.campaign)) {
                    var selectedInterests = [];
                    _.forEach(vm.targeting.interests, function (interest) {
                        if (interest.network && interest.network === 'facebook') {
                            selectedInterests.push(interest);
                        }
                    });
                    data['interestsFromFacebook'] = _.map(selectedInterests, 'networkId');
                }

                if (vm.appliesToAdwords(vm.campaign)) {
                    var selectedInterests = [];
                    _.forEach(vm.targeting.interests, function (interest) {
                        if (interest.network && interest.network === 'adwords') {
                            selectedInterests.push(interest);
                        }
                    });
                    data['interestsFromGoogle'] = _.map(selectedInterests, 'networkId');
                }

                data['interests'] = data['interests'].filter((e) => (e != null && e !== ''));
            }

            if (data.hasOwnProperty('topics')) {
                data['topics'] = _.map(vm.targeting.topics, 'id');
            }

            if (data.hasOwnProperty('locations')) {
                data['locations'] = _.map(data['locations'], 'id');
            }

            if (data.hasOwnProperty('excludedTopics')) {
                data['excludedTopics'] = _.map(vm.targeting.excludedTopics, 'id');
            }

            if (data.hasOwnProperty('keywords')) {
                data['keywords'] = tvlCampaignUtils.mapKeywords(vm.selectedKeywords.included);
            }

            if (data.hasOwnProperty('excludedKeywords')) {
                data['excludedKeywords'] = tvlCampaignUtils.mapKeywords(vm.selectedKeywords.excluded);
            }

            var targeting = new Targeting(data);
            return targeting.$save();
        }

        function getCampaignChangelog(page) {

            vm.ui.loadingChangelog = true;

            var params = {
                id: vm.campaign.id,
                page: page ? page : vm.campaignChangelog.currentPage
            };

            return Campaign.getChangelog(params)
                .$promise
                .then(onGetCampaignChangelogSuccess)
                .finally(onGetCampaignChangelogFinally);

            function onGetCampaignChangelogSuccess(changelog) {
                vm.campaignChangelog = changelog;
            }

            function onGetCampaignChangelogFinally() {
                vm.ui.loadingChangelog = false;
            }
        }

        function getCampaignChangeLogFilters()
        {
            var params = {
                id: vm.campaign.id,
            };

            return Campaign.getChangelogFilters(params)
                .$promise
                .then(function(response) {
                    vm.selectUsers = getSelectFilters(response.users, vm.selectUsers);
                    vm.selectCampaigns = getSelectFilters(response.names, vm.selectCampaigns);
                });
        }

        function getSelectFilters(data, array){
            data.forEach(function getSelectItems(item){
                array.push({id: item, title: item});
            });

            return array;
        }

        /**
         * Loads campaign notes.
         *
         * @param page
         *
         * @returns {Promise<any> | Promise<T> | *}
         */
        function getCampaignNotes(page) {
            if (typeof page === 'undefined') {
                page = 1;
            }

            if (null !== vm.campaignNotes && vm.campaignNotes.currentPage) {
                page = vm.campaignNotes.currentPage;
            }

            vm.ui.loadingCampaignNotes = true;

            var params = {
                id: vm.campaign.id,
                page: page
            };

            return CampaignNotes.getNotes(params)
                .$promise
                .then(onGetCampaignNotesSuccess)
                .finally(onGetCampaignNotesFinally);

            function onGetCampaignNotesSuccess(notes) {
                vm.campaignNotes = notes;
                vm.campaign.totalNotes = notes.totalItems;
            }

            function onGetCampaignNotesFinally() {
                vm.ui.loadingCampaignNotes = false;
            }
        }

        function getCampaignRulesLog(page) {

            vm.ui.loadingRulesLog = true;

            var params = {
                id: vm.campaign.id,
                page: page ? page : vm.campaignRulesLog.currentPage
            };

            return Campaign.getRulesLog(params)
                .$promise
                .then(onGetCampaignRulesLogSuccess)
                .finally(onGetCampaignRulesLogFinally);

            function onGetCampaignRulesLogSuccess(rulesLog) {
                vm.campaignRulesLog = rulesLog;
            }

            function onGetCampaignRulesLogFinally() {
                vm.ui.loadingRulesLog = false;
            }
        }

        /**
         * Validate that the given budget is valid.
         *
         * If a network is given, it will be validated for just that network.
         *
         * @param {string} network
         * @param {Number} budget
         */
        function validateNewBudget(network, budget) {
            var parsed = Number.parseFloat(budget);

            if (isNaN(parsed)) {
                throw 'Please, enter a value';
            }

            if (parsed <= 0) {
                throw 'The budget must be greater than 0';
            }

            var cost = network ? getNetworkCost(network) : vm.campaign.cost;
            if (budget < cost) {
                throw 'New budget cannot be less than the campaign\'s current cost';
            }
        }

        function showEditEndDateConfirmModalIfStatusChanges(placement, onConfirm, onCancel) {

            var today = new Date();
            if (['canceled', 'finished'].includes(placement.status)) {
                if (placement.endDate.getTime() >= today.getTime()) {
                    return showEditEndDateConfirmModal(onConfirm, onCancel);
                } else if (moment(placement.endDate).format('YYYY-MM-DD') == moment(today).format('YYYY-MM-DD')) {
                    return showEditEndDateConfirmModal(onConfirm, onCancel);
                }
            }
            // since confirm modal is not shown we need to execute onConfirm anyway
            return onConfirm();
        }

        function showEditEndDateConfirmModal(onConfirm, onCancel) {
            var title = "Warning!";
            var msg = "After this change this placement ads will start running.";
            var opts = {
                closeOnConfirm: true,
                confirmButtonText: "I'm aware",
                cancelButtonText: "Go back"
            };
            return tvlSweetAlert.confirm(title, msg, opts)
                .then(onConfirm, onCancel);
        }

        function getLanguages() {
            return Constants.getLanguages()
                .$promise
                .then(function (languages) {
                    vm.languages = {};
                    angular.forEach(languages, function (language) {
                        vm.languages[language.code] = language.name;
                    });
                });
        }

        /**
         * This function hooks into x-editable callbacks to handle the
         * outrageous fact that x-editable radio buttons only work when the
         * value is a number.
         *
         * Daaaaaaaamn.
         *
         * @param {Number} value
         */
        function onAfterSaveGender(value) {
            switch (value) {
                case 1:
                    vm.targeting.gender = 'male';
                    break;
                case 2:
                    vm.targeting.gender = 'female';
                    break;
                case 3:
                    vm.targeting.gender = 'both';
                    break;
            }
        }

        function searchInterests(searchTerm) {
            var advertisesInBothNetworks = vm.appliesToAdwords(vm.campaign) && (vm.appliesToFacebook(vm.campaign) || vm.appliesToInstagram(vm.campaign));

            var params = {
                searchTerm: searchTerm,
                network: advertisesInBothNetworks ? null : (vm.appliesToAdwords(vm.campaign) ? 'adwords' : 'facebook'),
                enabled: 1
            };

            var campaignNetworks = {};
            for (const placements of vm.campaign.placements) {
                for (const subnetwork of placements.subnetworks) {
                    if ('adwords_display' === subnetwork) {
                        campaignNetworks['googleDisplay'] = true;
                    }

                    if ('adwords_video' === subnetwork) {
                        campaignNetworks['youtube'] = true;
                    }
                }
            }

            params['campaignNetworks'] = campaignNetworks;

            var customerAccount = vm.campaign.customerAccount;
            if (customerAccount.adwordsConfiguration && customerAccount.adwordsConfiguration.accountId) {
                params['clientId'] = vm.campaign.customerAccount.adwordsConfiguration.accountId;
            }

            return Interest.get(params).$promise
                .then(onGetInterestsSuccess)
                .catch(onGetInterestsError);

            function onGetInterestsSuccess(data) {
                vm.availableInterests = data.items;
            }

            function onGetInterestsError(reason) {
                toastr.error('Our apologies, we have been unable to retrieve interests for you. Please, try again in a few moments.');
            }
        }

        function searchTopics(searchTerm) {
            var advertisesInBothNetworks = vm.appliesToAdwords(vm.campaign) && (vm.appliesToFacebook(vm.campaign) || vm.appliesToInstagram(vm.campaign));

            var params = {
                searchTerm: searchTerm,
                network: advertisesInBothNetworks ? null : (vm.appliesToAdwords(vm.campaign) ? 'adwords' : 'facebook'),
                enabled: 1
            };

            return Topic.get(params).$promise
                .then(onGetTopicsSuccess)
                .catch(onGetTopicsError);

            function onGetTopicsSuccess(data) {
                vm.availableTopics = data.items;
            }

            function onGetTopicsError(reason) {
                toastr.error('Our apologies, we have been unable to retrieve topics for you. Please, try again in a few moments.');
            }
        }

        function searchLocations(searchTerm) {
            var parentIds = _.uniq(_.map(_.filter(vm.campaign.locations, {
                type: 'country'
            }), 'id'));

            var params = {
                'parentIds[]': parentIds,
                'name': searchTerm
            };

            return Location.get(params).$promise
                .then(onGetLocationsSuccess)
                .catch(onGetLocationsError);

            function onGetLocationsSuccess(data) {
                var locations = _.toArray(data.items);

                if (null !== vm.locationTypeSelected) {
                    locations = getFilteredLocationsByType(locations, vm.locationTypeSelected);
                }
                locations = keepTargeteableLocations(locations);

                vm.availableLocations = locations;
            }

            function onGetLocationsError(reason) {
                toastr.error('Our apologies, we have been unable to retrieve locations for you. Please, try again in a few moments.');
            }
        }

        function keepTargeteableLocations(locations) {
            var targeteableLocations = [];

            for (var key in locations) {
                var location = locations[key];

                if (canTargetLocation(location)) {
                    targeteableLocations.push(location)
                }
            }

            return targeteableLocations;
        }

        function getFilteredLocationsByType(locations, type) {
            var filteredLocations = [];

            for (var key in locations) {
                var location = locations[key];

                if (type === location.type) {
                    filteredLocations.push(location);
                }
            }

            return filteredLocations;
        }

        /**
         * Check locations overlap.
         */
        function checkLocationsOverlap() {
            vm.locationTypeSelected = null;
            var isFacebookNetwork = vm.appliesToFacebook(vm.campaign) || vm.appliesToInstagram(vm.campaign);

            if (isFacebookNetwork && vm.campaign.locations.length > 0) {
                vm.locationTypeSelected = vm.campaign.locations[0].type;
                vm.availableLocations = getFilteredLocationsByType(vm.availableLocations, vm.locationTypeSelected);
            }
        }

        function onLocationSelected(item, model) {
            checkLocationsOverlap();
        }

        function onLocationRemoved(item, model) {
            checkLocationsOverlap();
        }

        /**
         * Show the new rule modal.
         *
         * If resolved with success the rule will be added to the list.
         *
         * @return {Promise}
         */
        function openNewRuleModal() {
            var placementsIds = _.map(vm.campaign.placements, 'id');
            var modal = $uibModal.open({
                templateUrl: 'campaign/wizard/create-rule-modal.html',
                controller: 'CreateRuleModalController as vm',
                bindToController: true,
                resolve: {
                    placements: function () {
                        return placementsIds;
                    },
                    locations: function () {
                        return [];
                    }
                }
            });
            return modal.result.then(function (rule) {
                vm.campaign.rules.push(rule);
                vm.campaignRules.push(rule);
                vm.dirty = true;
            });
        }

        /**
         * Opens new note modal.
         *
         * @returns {*}
         */
        function openNewCampaignNoteModal() {
            var modal = $uibModal.open({
                templateUrl: 'campaign/note/create-note-modal.html',
                controller: 'CreateNoteModalController as vm',
                bindToController: true,
                resolve: {
                    endpointPromise: function () {
                        return CampaignNotes.createNote;
                    },
                    params: function () {
                        return {id: vm.campaign.id};
                    }
                }
            });

            return modal.result.then(function (note) {
                vm.getCampaignNotes();
            });
        }

        /**
         * Opens edit note modal.
         *
         * @param {object} note
         *
         * @returns {*}
         */
        function openEditCampaignNoteModal(note) {
            var modal = $uibModal.open({
                templateUrl: 'campaign/note/edit-note-modal.html',
                controller: 'EditNoteModalController as vm',
                bindToController: true,
                resolve: {
                    endpointPromise: function () {
                        return CampaignNotes.updateNote;
                    },
                    params: function () {
                        return {id: vm.campaign.id, noteId: note.id};
                    },
                    note: function () {
                        return note;
                    }
                }
            });

            return modal.result.then(function (note) {
                vm.getCampaignNotes();
            });
        }

        /**
         * Remove the rule at the given position.
         *
         * @param Number
         */
        function removeRule(idx) {
            vm.campaign.rules.splice(idx, 1);
        }

        function preprocessRulesForUpdate(rules) {
            var processedRules = [];
            rules.forEach(function (rule) {
                var locations = [];
                if (rule.id) {
                    rule.locations.forEach(function (location) {
                        locations.push(location.id);
                    });
                    rule.locations = locations;
                    processedRules.push(rule);
                }
            });

            return processedRules;
        }

        /**
         * Callback function invoked whenever the status of a placement is
         * changed through the status badge directive.
         */
        function onPlacementStatusChange(placement, newValue, oldValue) {
            placement.status = newValue;
        }

        /**
         * Callback function invoked whenever the status of a campaign is
         * changed through the status badge directive.
         */
        function onCampaignStatusChange(campaign, newValue, oldValue) {
            campaign.status = newValue;
        }

        /**
         * Return whether the given location can be targeted with the current
         * campaign configuration.
         *
         * A location can be targeted if it is available in all the networks
         * where the campaign will run.
         *
         * @param {Object} location
         * @return {Boolean}
         */
        function canTargetLocation(location) {
            if (location === undefined) {
                return false;
            }

            var network = location.network;
            var appliesToNetwork = tvlCampaignUtils.appliesToNetwork(network, vm.campaign);

            var networkCountries = _.uniq(_.map(_(vm.campaign.locations).filter({
                network: network
            }).value(), 'country'));

            var countryFound = networkCountries.indexOf(location.country) >= 0;

            return countryFound && appliesToNetwork;
        }

        /**
         * Checks if the placement can be updated
         * @param placement
         * @returns {boolean}
         */
        function isPlacementEditable(placement) {
            if (!vm.editable) {
                return false;
            }
            return (
                placement.status === vm.STATUS_PENDING ||
                placement.status === vm.STATUS_ACTIVE ||
                placement.status === vm.STATUS_PAUSED ||
                placement.status === vm.STATUS_REJECTED
            );
        }

        /**
         * Checks if the placement videoAd can be updated
         * @param placement
         * @returns {boolean}
         */
        function isAdEditable(placement) {
            if (!vm.adEditable) {
                return false;
            }

            return (
                placement.status === vm.STATUS_PENDING ||
                placement.status === vm.STATUS_ACTIVE ||
                placement.status === vm.STATUS_PAUSED ||
                placement.status === vm.STATUS_REJECTED
            );
        }

        /**
         * Checks if the campaign has set the frequency cap
         */
        function frequencyCapExists() {
            return (
                (vm.campaign.frequencyCapViews !== null && vm.campaign.frequencyCapViewsTime !== null) ||
                (vm.campaign.frequencyCapImpressions !== null && vm.campaign.frequencyCapImpressionsTime !== null)
            );
        }

        /**
         * Determines if the frequency cap form has to be rendered
         */
        function initShowFrequencyCapForm() {
            vm.ui.showFrequencyCapForm = frequencyCapExists();
        }

        /**
         * Hide/show frequency cap's form
         */
        function switchShowFrequencyCapForm() {
            if (vm.ui.showFrequencyCapForm) {
                removeFrequencyCapValues();
                vm.ui.showFrequencyCapForm = false;
            } else {
                vm.ui.showFrequencyCapForm = true;
            }
        }

        /**
         * Changes frequency cap value
         */
        function onFrequencyCapValueChanged(type) {
            var value = (type === vm.FREQUENCY_CAP_VIEWS)
                ? vm.campaign.frequencyCapViews
                : vm.campaign.frequencyCapImpressions;
            value = parseInt(value);
            if (isNaN(value)) {
                value = 0;
            }
            if (value > vm.FREQUENCY_CAP_MAX_VALUE) {
                value = vm.FREQUENCY_CAP_MAX_VALUE;
            } else if (value < 0) {
                value = 0;
            }

            if (type === vm.FREQUENCY_CAP_VIEWS) {
                vm.campaign.frequencyCapViews = value;
                if (vm.campaign.frequencyCapViews === 0) {
                    vm.campaign.frequencyCapViews = null;
                    vm.campaign.frequencyCapViewsTime = null;
                } else if (vm.campaign.frequencyCapViewsTime === null) {
                    vm.campaign.frequencyCapViewsTime = 'day';
                }
            } else {
                vm.campaign.frequencyCapImpressions = value;
                if (vm.campaign.frequencyCapImpressions === 0) {
                    vm.campaign.frequencyCapImpressions = null;
                    vm.campaign.frequencyCapImpressionsTime = null;
                } else if (vm.campaign.frequencyCapImpressionsTime === null) {
                    vm.campaign.frequencyCapImpressionsTime = 'day';
                }
            }
        }

        /**
         * Backup frequency cap values to discard changes if needed
         */
        function backupFrequencyCapDiscardValues() {
            vm.frequencyCapDiscardValues = {
                'views': vm.campaign.frequencyCapViews,
                'viewsTime': vm.campaign.frequencyCapViewsTime,
                'impressions': vm.campaign.frequencyCapImpressions,
                'impressionsTime': vm.campaign.frequencyCapImpressionsTime
            };
        }

        /**
         * Remove frequency cap values
         */
        function removeFrequencyCapValues() {
            vm.campaign.frequencyCapViews = null;
            vm.campaign.frequencyCapViewsTime = null;
            vm.campaign.frequencyCapImpressions = null;
            vm.campaign.frequencyCapImpressionsTime = null;
        }

        /**
         * Discard frequency cap changes
         */
        function discardFrequencyCapChanges() {
            vm.campaign.frequencyCapViews = vm.frequencyCapDiscardValues['views'];
            vm.campaign.frequencyCapViewsTime = vm.frequencyCapDiscardValues['viewsTime'];
            vm.campaign.frequencyCapImpressions = vm.frequencyCapDiscardValues['impressions'];
            vm.campaign.frequencyCapImpressionsTime = vm.frequencyCapDiscardValues['impressionsTime'];
            initShowFrequencyCapForm();
        }

        function getAccountInfo() {

            var params = {
                id: vm.campaign.customerAccount.id,
            };

            return Account.get(params)
                .$promise
                .then(onGetAccountInfoSuccess)
                .finally(onGetAccountInfoFinally);

            function onGetAccountInfoSuccess(data) {
                vm.company = data.company;
            }

            function onGetAccountInfoFinally() {
            }
        }

        // note: this affects only facebook campaigns
        function isScheduleDisabledOnUpdates(placement) {
            // to avoid calculation this everytime we have a "cache" of this var
            // otherwise, the code below will be executed multiple times as it is called from ng-disabled
            if (vm.isScheduleDisabledOnUpdatesCached !== null) {
                return vm.isScheduleDisabledOnUpdatesCached;
            }

            if (placement.network !== 'facebook') {
                vm.isScheduleDisabledOnUpdatesCached = false;
                return false;
            }

            let timeSlots = placement.timeSlots;

            vm.isScheduleDisabledOnUpdatesCached = Object.keys(timeSlots).length === 0;

            return vm.isScheduleDisabledOnUpdatesCached;
        }

        /**
         * Determines if the campaign thumbnails are updated in order to disable creating campaign copies.
         * @returns {boolean}
         */
        function areThumbnailsUpdated() {
            for (var i = 0; i < vm.campaign.placements.length; i++) {
                var placement = vm.campaign.placements[i];
                var creativeType = _.get(placement, 'adVideo.video.creativeType');
                if (creativeType === 'tvl_preview') {
                    return true;
                }
            }
            return false;
        }

        /**
         * Determines if the campaign thumbnails are updated in order to disable creating campaign copies.
         * @returns {boolean}
         */
        function isThumbnailUpdated(placement) {
            var creativeType = _.get(placement, 'adVideo.video.creativeType');
            if (creativeType === 'tvl_preview') {
                return true;
            }
            return false;
        }
    }
})();
