(function () {
    'use strict';

    tvlWizardInitialPrices.$inject = [
        'toastr',
        'Campaign',
        'Account',
        'Company',
        'tvlAdVideoUtils',
        'googleDisplayAdUtils',
    ];

    /**
     * Wizard's data service.
     */
    function tvlWizardInitialPrices(toastr, Campaign, Account, Company, tvlAdVideoUtils, googleDisplayAdUtils) {
        var self = this;

        self.getInitialPricingValues = getInitialPricingValues;

        /**
         * If this step is accessed for the first time - set the minBiddingValues from customer-account.
         * Otherwise keep previously entered pricing
         *
         * @returns object
         */
        function getInitialPricingValues(campaignForm, networkPrices, customerAccount) {
            /**
             * MaxCPA values we set automatically in the Backend,
             * so here we are not using them anymore.
             */
            var youtubeMinBiddingStep = customerAccount.adwordsMinBiddingStep || null;
            var googleDisplayMinBiddingStep = customerAccount.adwordsMinBiddingStep || null;
            var facebookMinBiddingStep = customerAccount.facebookMinBiddingStep || null;
            var facebookMinBiddingStepForCpm = customerAccount.facebookMinBiddingStepForCpm || null;
            var instagramMinBiddingStep = customerAccount.instagramMinBiddingStep || null;
            var instagramMinBiddingStepForCpm = customerAccount.instagramMinBiddingStepForCpm || null;

            var isRebate = customerAccount.campaignRebate !== null;

            if (campaignForm.ads.youtube) {
                if (youtubeGoalExists(campaignForm, tvlAdVideoUtils.GOAL_VIEWS)) {
                    if (!networkPrices.youtubeMaxCpv) {
                        networkPrices.youtubeMaxCpv = youtubeMinBiddingStep;
                    }
                    if (!networkPrices.youtubeFixedCpv && isRebate) {
                        networkPrices.youtubeFixedCpv = youtubeMinBiddingStep;
                    }
                }
                if (youtubeGoalExists(campaignForm, tvlAdVideoUtils.GOAL_IMPRESSIONS)) {
                    if (!networkPrices.youtubeMaxCpm) {
                        networkPrices.youtubeMaxCpm = youtubeMinBiddingStep;
                    }
                    if (!networkPrices.youtubeFixedCpm && isRebate) {
                        networkPrices.youtubeFixedCpm = youtubeMinBiddingStep;
                    }
                }
                if (youtubeGoalExists(campaignForm, tvlAdVideoUtils.GOAL_ACTION)) {
                    if (!networkPrices.youtubeMaxCpa) {
                        networkPrices.youtubeMaxCpa = youtubeMinBiddingStep;
                    }
                    if (!networkPrices.youtubeFixedCpa && isRebate) {
                        networkPrices.youtubeFixedCpa = youtubeMinBiddingStep;
                    }
                }
            }

            if (campaignForm.ads.googleDisplay) {
                if (googleDisplayBiddingTypeExists(campaignForm, googleDisplayAdUtils.BIDDING_TYPE_MANUAL_CPM_KEY)) {
                    if (!networkPrices.googleDisplayMaxCpm) {
                        networkPrices.googleDisplayMaxCpm = googleDisplayMinBiddingStep;
                    }
                    if (!networkPrices.googleDisplayFixedCpm && isRebate) {
                        networkPrices.googleDisplayFixedCpm = googleDisplayMinBiddingStep;
                    }
                }
                if (googleDisplayBiddingTypeExists(campaignForm, googleDisplayAdUtils.BIDDING_TYPE_TARGET_CPA_KEY)) {
                    if (!networkPrices.googleDisplayMaxCpa) {
                        networkPrices.googleDisplayMaxCpa = googleDisplayMinBiddingStep;
                    }
                    if (!networkPrices.googleDisplayFixedCpa && isRebate) {
                        networkPrices.googleDisplayFixedCpa = googleDisplayMinBiddingStep;
                    }
                }
                if (googleDisplayBiddingTypeExists(campaignForm, googleDisplayAdUtils.BIDDING_TYPE_MANUAL_CPC_KEY) ||
                    googleDisplayBiddingTypeExists(campaignForm, googleDisplayAdUtils.BIDDING_TYPE_TARGET_SPEND_KEY)) {
                    if (!networkPrices.googleDisplayMaxCpc) {
                        networkPrices.googleDisplayMaxCpc = googleDisplayMinBiddingStep;
                    }
                    if (!networkPrices.googleDisplayFixedCpc && isRebate) {
                        networkPrices.googleDisplayFixedCpc = googleDisplayMinBiddingStep;
                    }
                }
            }

            if (campaignForm.ads.facebook) {
                if (facebookGoalExists(campaignForm, tvlAdVideoUtils.GOAL_VIEWS)) {
                    if (!networkPrices.facebookMaxCpv) {
                        networkPrices.facebookMaxCpv = facebookMinBiddingStep;
                    }
                    if (!networkPrices.facebookFixedCpv && isRebate) {
                        networkPrices.facebookFixedCpv = facebookMinBiddingStep;
                    }
                }
                if (facebookGoalExists(campaignForm, tvlAdVideoUtils.GOAL_CLICKS)) {
                    if (!networkPrices.facebookMaxCpc) {
                        networkPrices.facebookMaxCpc = facebookMinBiddingStep;
                    }
                    if (!networkPrices.facebookFixedCpc && isRebate) {
                        networkPrices.facebookFixedCpc = facebookMinBiddingStep;
                    }
                }
                if (facebookGoalExists(campaignForm, tvlAdVideoUtils.GOAL_IMPRESSIONS) ||
                    facebookGoalExists(campaignForm, tvlAdVideoUtils.GOAL_INTERACTIONS)) {
                    if (!networkPrices.facebookMaxCpm) {
                        networkPrices.facebookMaxCpm = facebookMinBiddingStep;
                    }
                    if (!networkPrices.facebookFixedCpm && isRebate) {
                        networkPrices.facebookFixedCpm = facebookMinBiddingStepForCpm;
                    }
                }
                if (facebookGoalExists(campaignForm, tvlAdVideoUtils.GOAL_ACTION)) {
                    if (!networkPrices.facebookMaxCpa) {
                        networkPrices.facebookMaxCpa = facebookMinBiddingStep;
                    }
                    if (!networkPrices.facebookFixedCpa && isRebate) {
                        networkPrices.facebookFixedCpa = facebookMinBiddingStep;
                    }
                }
            }
            if (campaignForm.ads.instagram) {
                if (instagramGoalExists(campaignForm, tvlAdVideoUtils.GOAL_VIEWS)) {
                    if (!networkPrices.instagramMaxCpv) {
                        networkPrices.instagramMaxCpv = instagramMinBiddingStep;
                    }
                    if (!networkPrices.instagramFixedCpv && isRebate) {
                        networkPrices.instagramFixedCpv = instagramMinBiddingStep;
                    }
                }
                if (instagramGoalExists(campaignForm, tvlAdVideoUtils.GOAL_CLICKS)) {
                    if (!networkPrices.instagramMaxCpc) {
                        networkPrices.instagramMaxCpc = instagramMinBiddingStep;
                    }
                    if (!networkPrices.instagramFixedCpc && isRebate) {
                        networkPrices.instagramFixedCpc = instagramMinBiddingStep;
                    }
                }
                if (instagramGoalExists(campaignForm, tvlAdVideoUtils.GOAL_IMPRESSIONS)) {
                    if (!networkPrices.instagramMaxCpm) {
                        networkPrices.instagramMaxCpm = instagramMinBiddingStep;
                    }
                    if (!networkPrices.instagramFixedCpm && isRebate) {
                        networkPrices.instagramFixedCpm = instagramMinBiddingStepForCpm;
                    }
                }
                if (instagramGoalExists(campaignForm, tvlAdVideoUtils.GOAL_ACTION)) {
                    if (!networkPrices.instagramMaxCpa) {
                        networkPrices.instagramMaxCpa = instagramMinBiddingStep;
                    }
                    if (!networkPrices.instagramFixedCpa && isRebate) {
                        networkPrices.instagramFixedCpa = instagramMinBiddingStep;
                    }
                }
            }

            return networkPrices;
        }

        function youtubeGoalExists(campaignForm, goal) {
            for (var i in campaignForm.ads.youtube) {
                if (campaignForm.ads.youtube[i]['goal'] === goal) {
                    return true;
                }
            }
            return false;
        }

        function googleDisplayBiddingTypeExists(campaignForm, biddingType) {
            for (var i in campaignForm.ads.googleDisplay) {
                if (campaignForm.ads.googleDisplay[i]['biddingStrategyType'] === biddingType) {
                    return true;
                }
            }
            return false;
        }

        function facebookGoalExists(campaignForm, goal) {
            for (var i in campaignForm.ads.facebook) {
                if (campaignForm.ads.facebook[i]['goal'] === goal) {
                    return true;
                }
            }
            return false;
        }

        function instagramGoalExists(campaignForm, goal) {
            for (var i in campaignForm.ads.instagram) {
                if (campaignForm.ads.instagram[i]['goal'] === goal) {
                    return true;
                }
            }
            return false;
        }

        return self;
    }

    angular
        .module('tvl.common')
        .service('tvlWizardInitialPrices', tvlWizardInitialPrices);

}());
