(function() {
    angular
        .module('tvl.common')
        .directive('tvlAnnouncement', tvlAnnouncement);

    /** @ngInject */
    function tvlAnnouncement($uibModal) {
        return {
            restrict: 'E',
            replace    : true,
            scope      : {
                summary: "@",
                message: "@"
            },
            templateUrl: "common/announcement.html",
            link: function($scope, element, attrs) {

                $scope.$modalInstance = null;

                $scope.showModal = function() {
                    $scope.$modalInstance = $uibModal.open({
                        animation: true,
                        templateUrl: 'common/announcementModal.html',
                        scope: $scope
                    });
                };

                $scope.dismiss = function () {
                    $scope.$modalInstance.dismiss('cancel');
                };

            }
        };
    }
})();
