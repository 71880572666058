(function() {
    'use strict';

    angular
        .module('tvl.campaign')
        .controller('WizardAdsController', WizardAdsController);

    WizardAdsController.$inject = [
        '$scope',
        '$uibModal',
        'toastr',
        'Account',
        'tvlUrl',
        'tvlAdVideoUtils',
        'tvlSweetAlert',
        'FacebookBusiness',
        'googleDisplayAdUtils',
        'tvlConstantUtils',
        'tvlWizardData',
    ];

    /* @ngInject */
    function WizardAdsController(
        $scope,
        $uibModal,
        toastr,
        Account,
        tvlUrl,
        tvlAdVideoUtils,
        tvlSweetAlert,
        FacebookBusiness,
        googleDisplayAdUtils,
        tvlConstantUtils,
        tvlWizardData
    ) {
        var vm = this;

        vm.googleDisplayAdUtils = googleDisplayAdUtils;
        vm.tvlAdVideoUtils = tvlAdVideoUtils;
        vm.userEmail = $scope.userEmail;

        vm.parent = $scope.$parent;
        vm.campaignForm = vm.parent.campaignForm;
        vm.wizardData = vm.parent.wizardData;

        vm.isConversionEnabled = false;
        vm.availableFacebookPages = [];
        vm.availableFacebookAdVideos = [];
        vm.unregisterFns = [];
        vm.ui = {
            loadingFacebookAdVideos: false
        };

        vm.showNewAdModal = showNewAdModal;
        vm.showNewGoogleDisplayImageAdModal = showNewGoogleDisplayImageAdModal;

        function waitForCampaignData() {
            if (tvlWizardData.isLoadingCompleted() === false) {
                setTimeout(function(){
                    waitForCampaignData();
                }, 500);
            } else {
                activate();
            }
        }
        waitForCampaignData();

        ////////////////

        function activate() {
            vm.unregisterFns = [
                $scope.$watch('campaignForm.ads', function () {
                    if ($scope.currentStep.idx==4) {
                        if (vm.campaignForm.networks.youtube) {
                            if (vm.campaignForm.ads.youtube.length === 0) {
                                $scope.continueAvailable = false;
                                return;
                            }
                        }
                        if (vm.campaignForm.networks.facebook) {
                            if (vm.campaignForm.ads.facebook.length === 0) {
                                $scope.continueAvailable = false;
                                return;
                            }
                        }
                        if (vm.campaignForm.networks.instagram) {
                            if (vm.campaignForm.ads.instagram.length === 0) {
                                $scope.continueAvailable = false;
                                return;
                            }
                        }
                        $scope.continueAvailable = true;
                    }
                }, true),
                $scope.$on('tvlValidate', function(event, step) {
                    if (step.key === 'ads') {
                        vm.parent.$emit('tvlValidationEnd', {'valid' : validate()});
                    }
                }),
            ];

            loadFacebookSDK();

            // Check if is youtube and has conversion tracking.
            if (vm.campaignForm.networks.youtube) {
                getAccountAdwordsConversions();
            }

            if (vm.campaignForm.networks.facebook || vm.campaignForm.networks.instagram) {
                // pre-fetch FB pages that we can access
                getAvailableFacebookPages();
            }
        }

        $scope.filterInStreamYoutubeAdVideos = function(elem) {
            return tvlAdVideoUtils.isAdVideoInStreamFamily(elem.format);
        };

        $scope.filterInstagramPostAdVideos = function(elem) {
            return tvlAdVideoUtils.isFacebookAnyAdVideoFormat(elem.format) || tvlAdVideoUtils.isFacebookExternalAdVideoFormat(elem.format);
        };

        $scope.filterInstagramStoriesAdVideos = function(elem) {
            return tvlAdVideoUtils.isInstagramStoryAdVideoFormat(elem.format);
        };

        $scope.removeYoutubeAdVideo = function(id) {
            removeAdVideoById(vm.campaignForm.ads.youtube, id);
        };

        $scope.removeGoogleDisplayImageAd = function(id) {
            removeAdVideoById(vm.campaignForm.ads.googleDisplay, id);
        };

        $scope.removeFacebookAdVideo = function(id) {
            removeAdVideoById(vm.campaignForm.ads.facebook, id);
        };

        $scope.removeInstagramAdVideo = function(id) {
            removeAdVideoById(vm.campaignForm.ads.instagram, id);
        };

        function removeAdVideoById(adVideos, id) {
            return tvlSweetAlert
                .confirm('Remove Ad?', 'Are you sure you would like to remove this Ad?', 'Yes', 'No')
                .then(
                    onModalAccepted
                );

            function onModalAccepted() {
                for (var index = 0; index < adVideos.length; index++) {
                    var adVideo = adVideos[index];
                    if (adVideo.id === id) {
                        // if this video was one of those without permissions, remove
                        // it from the array too
                        var missingPermsIdx = vm.parent.videosWithMissingPermissions.indexOf(adVideo.videoUrl);
                        if (missingPermsIdx >= 0) {
                            vm.parent.videosWithMissingPermissions.splice(missingPermsIdx, 1);
                        }

                        var keyToSearch = adVideo.platform;
                        if (adVideo.network === tvlConstantUtils.NETWORK_GOOGLE_DISPLAY) {
                            keyToSearch = adVideo.network;
                        }

                        removeMediaDistributionByKeyAndId(keyToSearch, adVideo.id);

                        adVideos.splice(index, 1);
                    }
                }
            }
        }

        /**
         * Remove from media distribution.
         *
         * @param keyToSearch
         * @param id
         */
        function removeMediaDistributionByKeyAndId(keyToSearch, id) {
            if (keyToSearch in vm.campaignForm.adDistribution && id in vm.campaignForm.adDistribution[keyToSearch]) {
                delete vm.campaignForm.adDistribution[keyToSearch][id];
            }
        }

        function loadFacebookSDK() {
            // only loads the SDK is the campaign uses facebook
            if (vm.campaignForm.networks.facebook !== true) {
                return;
            }

            window.fbAsyncInit = function() {
                FB.init({
                    appId      : '1684999091822149',
                    cookie     : true,
                    xfbml      : true,
                    version    : 'v10.0'
                });
            };

            (function(d, s, id){
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) {return;}
                js = d.createElement(s); js.id = id;
                js.src = "https://connect.facebook.net/en_US/sdk.js";
                fjs.parentNode.insertBefore(js, fjs);
            }(document, 'script', 'facebook-jssdk'));
        }

        // Validation

        function validate() {
            $scope.validFacebook = true;
            $scope.validInstagram = true;
            $scope.validYoutube = true;

            if (vm.campaignForm.networks.youtube) {
                $scope.validYoutube = $scope.validYoutubeAdVideos();
            }

            if (vm.campaignForm.networks.facebook) {
                $scope.validFacebook = $scope.validFacebookAdVideos();
            }

            if (vm.campaignForm.networks.instagram) {
                $scope.validInstagram = $scope.validInstagramAdVideos();
            }

            return $scope.validYoutube && $scope.validFacebook && $scope.validInstagram;
        }

        $scope.validYoutubeAdVideos = function () {

            if (vm.campaignForm.ads.youtube.length === 0) {
                return false;
            }
            for (var i = 0; i < vm.campaignForm.ads.youtube.length; i++) {
                if (! $scope.validYoutubeAdVideo(vm.campaignForm.ads.youtube[i])) {
                    return false;
                }
            }
            return true;
        };

        /**
         * YouTube ad-video validation.
         */
        $scope.validYoutubeAdVideo = function (adVideo) {
            var videoUrl = adVideo.videoUrl;
            if (! tvlUrl.isValidYoutubeVideoUrl(videoUrl)) {
                return false;
            }

            var trackingUrl = adVideo.trackingUrl;
            if (! $scope.isValidTrackingUrl(trackingUrl)) {
                return false;
            }

            if (tvlAdVideoUtils.isAdVideoInStreamFamily(adVideo.format)) {
                /**
                 * In-Stream family ads.
                 */
                var targetUrl = adVideo.targetUrl;
                if (! $scope.isValidYoutubeTargetUrl(targetUrl)) {
                    return false;
                }

                var displayUrl = adVideo.displayUrl;
                if (! $scope.isValidYoutubeDisplayUrl(displayUrl)) {
                    return false;
                }
            } else if (tvlAdVideoUtils.isVideoDiscoveryAdVideoFormat(adVideo.format)) {
                /**
                 * Discovery ads.
                 */
                var headline = adVideo.headline;
                if (headline.length === 0) {
                    return false;
                }

                var firstDescription = adVideo.firstDescription;
                if (firstDescription.length === 0) {
                    return false;
                }

                var secondDescription = adVideo.secondDescription;
                if (secondDescription.length === 0) {
                    return false;
                }
            }

            return true;
        };

        $scope.validFacebookAdVideos = function () {
            if (vm.campaignForm.ads.facebook.length === 0) {
                return false;
            }
            for (var i = 0; i < vm.campaignForm.ads.facebook.length; i++) {
                var adVideo = vm.campaignForm.ads.facebook[i];
                if (adVideo.isFacebookAny() && !$scope.validFacebookInstagramFeedAdVideo(adVideo)) {
                    return false;
                } else if (adVideo.isFacebookExternal() && !$scope.validFacebookExternalAdVideo(adVideo)) {
                    return false;
                }
            }

            return true;
        };

        $scope.validInstagramAdVideos = function () {
            if (vm.campaignForm.ads.instagram.length === 0) {
                return true;
            }
            for (var i = 0; i < vm.campaignForm.ads.instagram.length; i++) {
                var adVideo = vm.campaignForm.ads.instagram[i];
                if (adVideo.isFacebookAny() && !$scope.validFacebookInstagramFeedAdVideo(adVideo)) {
                    return false;
                } else if (adVideo.isInstagramStory() && !$scope.validInstagramStoryAdVideo(adVideo)) {
                    return false;
                }
            }
            return true;
        };

        $scope.validFacebookInstagramFeedAdVideo = function (adVideo) {
            var videoUrl = adVideo.videoUrl;
            if (! tvlUrl.isValidFacebookInstagramVideoUrl(videoUrl)) {
                return false;
            }

            var trackingUrl = adVideo.trackingUrl;
            if (! $scope.isValidTrackingUrl(trackingUrl)) {
                return false;
            }

            if (adVideo.goal === tvlAdVideoUtils.GOAL_CLICKS &&
                adVideo.postType !== tvlAdVideoUtils.FACEBOOK_CREATIVE_POST_MULTI_SHARE) {
                if (! $scope.isValidFacebookInstagramLinkUrl(adVideo.linkUrl)) {
                    return false;
                }
            }

            return true;
        };

        $scope.validFacebookExternalAdVideo = function (adVideo) {
            if (!adVideo.videoId || !adVideo.pageId) {
                return false;
            }
            var trackingUrl = adVideo.trackingUrl;
            if (! $scope.isValidTrackingUrl(trackingUrl)) {
                return false;
            }

            if (adVideo.isCpc()) {
                if (! $scope.isValidFacebookInstagramLinkUrl(adVideo.linkUrl)) {
                    return false;
                }
            }

            return true;
        };

        $scope.validInstagramStoryAdVideo = function (adVideo) {
            if (!adVideo.videoId || !adVideo.pageId) {
                return false;
            }

            if (adVideo.isCpc()) {
                if (! $scope.isValidFacebookInstagramLinkUrl(adVideo.linkUrl)) {
                    return false;
                }
            }

            if (adVideo.isCpv() || adVideo.isCpm()) {
                if (! $scope.isValidFacebookInstagramTargetUrl(adVideo.targetUrl)) {
                    return false;
                }
            }

            return true;
        };

        $scope.isValidTrackingUrl = function (url) {
            if (url) {
                return tvlUrl.isValid(url);
            }
            return true;
        };

        $scope.isValidFacebookInstagramLinkUrl = function (url) {

            if (url) {
                return tvlUrl.isValid(url);
            }

            /**
             * Probably we should in the future implement some validations here
             */
        };

        /**
         * Following methods have same implementation, this will change in the future after applying more advanced
         * validation rules
         */
        $scope.isValidFacebookInstagramTargetUrl = function (url) {
            if (url) {
                url = url.replace(/^((?:http|https):\/\/)/i, '');
                return url.length > 0;
            }
        };

        $scope.isValidYoutubeTargetUrl = function (url) {
            if (url) {
                url = url.replace(/^((?:http|https):\/\/)/i, '');
                return url.length > 0;
            }
        };

        /**
         * Return whether the given url is a valid Adwords
         * display URL.
         *
         * @param url {String}
         * @return {Boolean}
         */
        $scope.isValidYoutubeDisplayUrl = function(url) {
            url = $scope.getUrlWithoutProtocol(url);
            return url !== undefined ? url.length > 0/* && url.length <= 35*/ : false;
        };

        /**
         * Return url without protocol
         *
         * @param url {String}
         */
        $scope.getUrlWithoutProtocol = function(url) {
            return url.replace(/^((?:http|https):\/\/)/i, '');
        };

        $scope.$on('$destroy', function () {
            for(var i in vm.unregisterFns) {
                vm.unregisterFns[i]();
            }
        });

        /**
         * Show the new ad video modal.
         *
         * @return {Promise}
         */
        function showNewAdModal(network, adVideo) {
            var modal = $uibModal.open({
                templateUrl: 'campaign/wizard/advideo-modal.html',
                controller: 'AdVideoModalController as vm',
                bindToController: true,
                backdrop: 'static',
                resolve: {
                    adVideoInfo: {
                        network: network,
                        adVideo: angular.copy(adVideo)
                    },
                    isConversionEnabled: vm.isConversionEnabled,
                    userEmail: function() {
                        return vm.userEmail;
                    },
                    facebookPages: function () {
                        return vm.availableFacebookPages;
                    },
                    facebookAdVideos: function() {
                        return vm.availableFacebookAdVideos;
                    },
                    areFacebookAndInstagramSelected: function() {
                        return vm.campaignForm.networks.facebook && vm.campaignForm.networks.instagram;
                    },
                    youtubeTrueViewForActionAdExists: function() {
                        if (vm.campaignForm.ads.youtube.length) {
                            for (var i in vm.campaignForm.ads.youtube) {
                                if (tvlAdVideoUtils.isTrueViewForActionAdVideoFormat(vm.campaignForm.ads.youtube[i].format)) {
                                    return true;
                                }
                            }
                        }
                        return false;
                    },
                    youtubeNonTrueViewForActionAdExists: function() {
                        if (!vm.campaignForm.ads.youtube.length) {
                            return false;
                        }
                        for (var i in vm.campaignForm.ads.youtube) {
                            if (tvlAdVideoUtils.isTrueViewForActionAdVideoFormat(vm.campaignForm.ads.youtube[i].format)) {
                                return false;
                            }
                        }
                        return true;
                    },
                    accountId: function() {
                        return vm.wizardData.customerAccount.id;
                    },
                    facebookAdAccountId: function() {
                        if (vm.wizardData.customerAccount.facebookConfiguration) {
                            return vm.wizardData.customerAccount.facebookConfiguration.accountId;
                        }
                        return null;
                    },
                    devices: function() {
                        return vm.campaignForm.devices;
                    },
                },
            });

            return modal.result.then(addAdVideoToCampaign);

            function addAdVideoToCampaign(result) {
                var adVideo = result.adVideo;
                var isVideoAccessible = result.accessible;
                var bothNetworks = result.bothNetworks;
                var networks = [network];

                if (bothNetworks) {
                    networks = ['facebook', 'instagram'];
                }

                networks.forEach(function (network) {
                    var index = _.findIndex(vm.campaignForm.ads[network], function(video) {
                        return video.id === adVideo.id;
                    });

                    if (index >= 0) {
                        vm.campaignForm.ads[network][index] = adVideo;
                    } else {
                        vm.campaignForm.ads[network].push(adVideo);
                    }
                    if ((network === 'facebook' || network === 'instagram') && !isVideoAccessible && vm.parent.videosWithMissingPermissions.indexOf(adVideo.videoUrl) < 0) {
                        vm.parent.videosWithMissingPermissions.push(adVideo.videoUrl);
                    }
                });
            }
        }

        function showNewGoogleDisplayImageAdModal() {
            var modal = $uibModal.open({
                templateUrl: 'campaign/wizard/google-display-image-ad-modal.html',
                controller: 'GoogleDisplayImageAdModalController as vm',
                bindToController: true,
                resolve: {
                    adInfo: null,
                    update: false
                },
            });

            return modal.result.then(addAdVideoToCampaign);

            function addAdVideoToCampaign(result) {
                angular.forEach(result, function(ad) {
                    vm.campaignForm.ads['googleDisplay'].push(ad);
                });
            }
        }

        function getAvailableFacebookPages() {
            vm.ui.loadingFacebookAdVideos = true;

            var params = {
                accountId: vm.wizardData.customerAccount.id,
            };

            return FacebookBusiness.getAccessiblePages(params)
                .$promise
                .then(function (response) {
                    vm.availableFacebookPages = response.data;
                    vm.ui.loadingFacebookAdVideos = false;
                });
        }

        /**
         * Checks if account has conversions enabled.
         */
        function getAccountAdwordsConversions() {
            var customerId = vm.wizardData.customerAccount.adwordsConfiguration.accountId;

            var params = {googleAccountId: customerId};

            return Account.conversions(params).$promise
                .then(onGetConversionsSuccess, onGetConversionsError);

            function onGetConversionsSuccess(response) {
                if (response.data && response.data.length > 0) {
                    vm.isConversionEnabled = true;
                }
            }
            function onGetConversionsError(errors) {
                // Errors must be reported inside the api.
            }
        }
    }
})();
