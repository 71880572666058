(function() {
    'use strict';

    angular
        .module('tvl.common')
        .factory('tvlSession', tvlSession);

    tvlSession.$inject = [
        '$q',
        'User',
        'UserRolesChecker',
        'UserPermissionsChecker',
        'localStorageService',
        'resourceResponse',
        'ApiKeys'
    ];

    /**
     * This service returns information of the current session.
     * For now, it only returns information of the current logged user,
     * but this may change as required.
     *
     * @ngInject
     */
    function tvlSession(
        $q,
        User,
        UserRolesChecker,
        UserPermissionsChecker,
        localStorageService,
        resourceResponse,
        ApiKeys
) {

        return {
            getSession: getSession,
            refresh: refresh,
            saveApiKeys: saveApiKeys,
            getApiKeyFromSessionStorage: getApiKeyFromSessionStorage,
        };

        ////////////////

        function saveApiKeyToSessionStorage(name, value) {
            localStorageService.set(name, value);
        }

        function saveApiKeys(data) {
            saveApiKeyToSessionStorage('YOUTUBE_API_KEY', data.YOUTUBE_API_KEY);
            saveApiKeyToSessionStorage('WEATHER_API_KEY', data.WEATHER_API_KEY);
            saveApiKeyToSessionStorage('WEATHER_API_URL', data.WEATHER_API_URL);
        }

        function getApiKeyFromSessionStorage(name) {
            var value = localStorageService.get(name);
            var deferred = $q.defer();
            var returnedValue = _.isNil(value) ? null : value;

            if (null === returnedValue) {
                return ApiKeys.get().$promise.then(function(response) {
                    //TODO move these to build webpack.
                    saveApiKeys(response.data);

                    return getApiKeyFromSessionStorage(name);
                }, resourceResponse.error);
            } else {
                deferred.resolve(returnedValue);

                return deferred.promise;
            }
        }

        /**
         * Returns information about the current session.
         *
         * @returns {Promise}
         */
        function getSession() {
            var user = getSessionFromSessionStorage();

            if (user == undefined) {
                return User.me()
                    .$promise
                    .then(onGetSessionSuccess);
            } else {
                var deferred = $q.defer();
                deferred.resolve(user);
                return deferred.promise;
            }

            function onGetSessionSuccess(data) {
                fillUser(data);

                localStorageService.set('user', data);

                return data;
            }
        }

        function getSessionFromSessionStorage() {
            var user = localStorageService.get('user');

            if (isNotEmptyVar(user)) {
                fillUser(user);
            }

            return user;
        }

        function isNotEmptyVar(variable) {
            return 'undefined' !== typeof variable && null !== variable;
        }

        /**
         * Refreshing session.
         *
         * @returns {*}
         */
        function refresh() {
            return getSession();
        }

        function fillUser(user)
        {
            user.isClient = function() { return UserRolesChecker.isClient(this); };
            user.isAdmin = function() { return UserRolesChecker.isAdmin(this); };
            user.isTrader = function() { return UserRolesChecker.isTrader(this); };
            user.isSupport = function() { return UserRolesChecker.isSupport(this); };
            user.isCompanyManager = function() { return UserRolesChecker.isCompanyManager(this); };

            user.hasAccessToCompaniesSection = user.isAdmin() || user.isSupport();

            user.isAuthorizedToViewCampaignContentStats = function(accountId) {
                return UserPermissionsChecker.userHasAccountPermission(
                    this,
                    accountId,
                    'Account.view_content_stats'
                );
            };

            /**
             * Same as User hasPermission logic.
             *
             * @param permission
             *
             * @returns {boolean|*}
             */
            user.hasPermission = function(permission) {
                return UserPermissionsChecker.userHasPermission(this, permission);
            };

            /**
             * Same as User hasAccountPermission logic. If the account not exists or its permissions are empty we will
             * look at user permissions instead account permissions.
             *
             * @param accountId
             * @param permission
             */
            user.hasAccountPermission = function(accountId, permission) {
                return UserPermissionsChecker.userHasAccountPermission(this, accountId, permission);
            };
        }
    }
})();
