import {Video} from "video-metadata-thumbnails";

(function() {
    'use strict';

    angular
        .module('tvl.campaign')
        .directive('tvlFacebookAssetsUpload', tvlFacebookAssetsUpload);

    /** @ngInject */
    function tvlFacebookAssetsUpload() {
        return {
            bindToController: true,
            controller: TvlFacebookAssetsUploadController,
            controllerAs: 'vm',
            restrict: 'E',
            scope: {
                facebookAdVideo: '=',
                isVideo: '=',
                isImage: '=',
                facebookAccountCredentials: '=',
                getAvailableFacebookAdVideos: '=',
                assetsUploadInProgress: '=',

            },
            templateUrl: 'campaign/wizard/directives/fb-asset-upload-input.html'
        };
    }

    TvlFacebookAssetsUploadController.$inject = ['$scope', 'tvlUrl', 'tvlUuid', 'Upload','tvlAdVideoUtils', 'toastr'];

    /* @ngInject */
    function TvlFacebookAssetsUploadController($scope, tvlUrl, tvlUuid, Upload, tvlAdVideoUtils, toastr) {

        var vm = this;
        vm.maxFacebookVideoSize = '500 MB';
        vm.maxFacebookPhotoSize = '30 MB';
        vm.errorFacebookAsset = null; //used in template not in controller
        vm.uploadFacebookAssets = uploadFacebookAssets;

        async function uploadFacebookAssets(file) {
            if (!file || !file[0]) {
                return;
            }
            vm.assetsUploadInProgress = true;

            var thumbnail = null;

            if (vm.isVideo) {
                var video = new Video(file[0]);
                var metadata = await video.getMetadata();
                var midDuration = parseInt(metadata.duration / 2);
                var thumbnails = await video.getThumbnails({
                    quality: 0.6,
                    start: midDuration,
                    end: midDuration,
                });
                thumbnail = thumbnails[0].blob;
            }
            Upload.upload({
                url: '/api/facebook-business/assets',
                data: {
                    asset: file[0],
                    thumbnail: thumbnail,
                    accountId: vm.facebookAccountCredentials.accountId,
                    facebookAdAccountId: vm.facebookAccountCredentials.adAccountId,
                },
            }).then(onCreateSuccess, onCreateError).finally(function(){
                vm.assetsUploadInProgress = false;
                vm.displayAsset = null;
            });

            function onCreateSuccess(response) {
                vm.getAvailableFacebookAdVideos();
                vm.facebookAdVideo = tvlAdVideoUtils.normalizeFacebookAdImagesData(response.data)[0];
                toastr.success('Your asset has been uploaded successfully!');
            }

            function onCreateError() {
                toastr.error('There was an error while uploading your asset to Facebook. Please try again later.');
            }
        }
    }
})();