(function () {
    'use strict';

    angular.module('tvl.index', [
        'tvl.common',
        'tvl.login',
        'tvl.static',
        'tvl.charts',
        'tvl.campaign',
        'tvl.user',
        'tvl.administration',
        'tvl.control-center',
        'tvl.brandSafety',
        'tvl.group',
    ])
        .config(configure)
        .run(run);

    configure.$inject = [
        '$locationProvider',
        '$httpProvider',
        '$urlRouterProvider',
    ];

    /* @ngInject */
    function configure(
        $locationProvider,
        $httpProvider,
        $urlRouterProvider
    ) {
        $httpProvider.interceptors.push('tvlAuthInterceptor');
        $locationProvider.html5Mode(true);
        $urlRouterProvider.otherwise('login');
    }

    run.$inject = [
        '$rootScope',
        '$state',
        'toastr',
        'editableOptions',
        'tvlSession',
        'tvlAuthorization',
        '$anchorScroll',
        'jwt'
    ];

    /* @ngInject */
    function run(
        $rootScope,
        $state,
        toastr,
        editableOptions,
        tvlSession,
        tvlAuthorization,
        $anchorScroll,
        jwt
    ) {
        $rootScope.currentYear = (new Date()).getFullYear();
        $rootScope.bodyClass = '';
        $rootScope.$on('$stateChangeStart', onStateChangeStart);
        $rootScope.$on('$stateChangeSuccess', onStateChangeSuccess);
        // xeditable Bootstrap 3 theme
        editableOptions.theme = 'bs3';

        function onStateChangeStart(event, to, toParams, from, fromParams) {
            var publicStates = [
                'login.login',
                'static.terms-of-use',
                'static.privacy',
                'login.check-email',
                'login.reset-password',
                'login.resetting',
                'charts.weather-calendar',
            ];
            var isPublic = publicStates.indexOf(to.name) >= 0;

            if (false === isPublic && null === jwt.getTokenFromSessionStorage()) {
                $state.go('login.login');
            }

            // TODO old admin module redirection.
            // tvlSession.getSession().then(function (data) {
            //     if (!tvlAuthorization.isUserAuthorized(data, to)) {
            //         $state.go('admin.exchangeRates.list');
            //         toastr.error('You are not authorized to carry out this action.');
            //     }
            // });

            // TODO old campaign module redirection.
            // tvlSession.getSession().then(function (data) {
            //     if (!tvlAuthorization.isUserAuthorized(data, to)) {
            //         $state.go('campaign.list');
            //         toastr.error('You are not authorized to carry out this action.');
            //     }
            // });
        }

        function onStateChangeSuccess(event, to, toParams, from, fromParams) {
            // forcing scrolling to top since autoscroll directive was not working for ui-view
            $anchorScroll();
        }
    }
})();
