const moment = require('moment');

(function() {
    'use strict';

    angular
        .module('tvl.campaign')
        .constant('popoverPadding', 150)
        .directive('tvlStatusBadge', tvlStatusBadge);

    tvlStatusBadge.$inject = ['$window', 'popoverPadding', 'tvlCampaignUtils'];

    /* @ngInject */
    function tvlStatusBadge($window, popoverPadding, tvlCampaignUtils) {
        // Usage:
        //
        // Creates:
        //
        return {
            link: link,
            restrict: 'E',
            templateUrl: 'campaign/status-badge.html',
            replace: true,
            scope: {
                item: '=?',
                status: '=?',
                systemStatus: '=?',
                editable: '=',
                onChange: '&'
            }
        };

        function link(scope, element, attrs) {

            if (scope.item) {
                scope.status = scope.item.status;
                scope.systemStatus = scope.item.systemStatus;
                scope.startDate = scope.item.startDate || null;
                scope.updating = scope.item.updating || false;
            }

            scope.class = getClass(scope.status);
            scope.text = getText(scope.status);
            scope.availableStatuses = getAvailableStatuses(scope.status);
            scope.canEdit = scope.editable && !scope.updating && scope.availableStatuses.length > 0;

            scope.getActionText = getActionText;
            scope.getActionIcon = getActionIcon;
            scope.getPopoverPlacement = getPopoverPlacement;

            activate();

            function activate() {
                scope.unregisterWatcher = scope.$watch('status', function(newStatus, oldStatus) {
                    if (newStatus!=oldStatus) {
                        scope.status = newStatus;
                        scope.class = getClass(scope.status);
                        scope.text = getText(scope.status);
                        scope.availableStatuses = getAvailableStatuses(scope.status);
                    }
                });

                scope.$on('$destroy', function() {
                    scope.unregisterWatcher();
                });
            }

            function getClass(status) {
                switch (status) {
                    case 'active':
                        if (tvlCampaignUtils.isScheduled(scope)) {
                            return 'light-green';
                        }
                        return 'dark-green';
                    case 'pending':
                        return 'warning';
                    case 'pending_authorization':
                        return 'light-orange';
                    case 'finished':
                        return 'primary';
                    case 'rejected':
                        return 'dark-red';
                    case 'creating':
                        return 'dark-grey';
                    case 'paused':
                        return 'grey';
                    case 'canceled':
                        return 'inverse';
                    default:
                        return 'default';
                }
            }

            function getText(status) {
                switch (status) {
                    case 'active':
                        if (tvlCampaignUtils.isScheduled(scope)) {
                            return 'Scheduled';
                        }
                        return 'Active';
                    case 'pending':
                        return 'Pending approval';
                    case 'pending_authorization':
                        return 'Pending authorization';
                    case 'finished':
                        return 'Finished';
                    case 'creating':
                        return 'Creating';
                    case 'paused':
                        return 'Paused';
                    case 'canceled':
                        return 'Canceled';
                    case 'rejected':
                        return 'Rejected';
                    default:
                        return 'Unknown';
                }
            }

            function getActionText(status) {
                switch (status) {
                    case 'active':
                        return 'Activate';
                    case 'finished':
                        return 'Finish';
                    case 'paused':
                        return 'Pause';
                    case 'canceled':
                        return 'Cancel';
                    default:
                        return 'Unknown';
                }
            }

            function getActionIcon(status) {
                switch (status) {
                    case 'active':
                        return 'play';
                    case 'finished':
                        return 'flag-checkered';
                    case 'paused':
                        return 'pause';
                    case 'canceled':
                        return 'ban';
                }
            }

            function getAvailableStatuses(status) {
                switch (status) {
                    case 'active':
                        return ['paused', 'canceled', 'finished'];
                    case 'pending':
                        return ['canceled'];
                    case 'pending_authorization':
                        return ['canceled'];
                    case 'finished':
                        if (isAllowedPlacementActivation()) {
                            return ['active', 'paused'];
                        }
                        return [];
                    case 'creating':
                        return [];
                    case 'paused':
                        return ['active', 'canceled', 'finished'];
                    case 'canceled':
                        return ['active'];
                    case 'rejected':
                        return ['canceled', 'finished'];
                    default:
                        return [];
                }
            }

            /**
             * Check if placement can be activated after it's finished.
             * @returns {boolean}
             */
            function isAllowedPlacementActivation() {
                /*
                 * Budget is spent.
                 */
                var cost = scope.item.cost;
                var budget = scope.item.budget;
                if (budget <= cost) {
                    return false;
                }

                /*
                 * Placement has been expired.
                 */
                var placementEndDate = normalizeDate(scope.item.endDate);
                var currentDate = normalizeDate(moment());
                if (placementEndDate <= currentDate) {
                    return false;
                }

                return true;
            }

            function normalizeDate(momentDate) {
                var normalized = moment.utc(momentDate);
                return normalized.format('YYYY-MM-DD');
            }

            function getPopoverPlacement() {
                var domRect = element[0].getBoundingClientRect();
                if (domRect.x < ($window.innerWidth - domRect.width - popoverPadding)) {
                    return 'top';
                } else {
                    return 'left';
                }
            }
        }
    }
})();
