const moment = require('moment');

(function() {
    'use strict';

    angular
        .module('tvl.common')
        .directive('tvlVideo', tvlVideo);

    tvlVideo.$inject = ['$window'];

    /* @ngInject */
    function tvlVideo($window) {
        // Usage:
        //
        // Creates:
        //
        var directive = {
            link: link,
            restrict: 'EA',
            scope: {
                video: '=',
                onClick: '=?',
                onDelete: '=?',
                size: '@?'
            },
            templateUrl: 'common/video.html',
        };
        return directive;

        function link(scope, element, attrs) {
            // default to small size if not specified
            if (scope.size === undefined) {
                scope.size = 'small';
            }

            /**
             * @todo this sucks, we should change this.
             * this is how it was used in the old directive, <smallvideo>.
             */
            scope.publishedAtDate = moment.utc(scope.video.publishedAt).format('YYYY-MM-DD');
            scope.url = '/video/'+scope.video.videoId;

            scope.click = function ($event) {
                if (scope.onClick) {
                    scope.onClick(scope.video);
                    $event.stopPropagation();
                    $event.preventDefault();
                } else {
                    $window.location.href = scope.url;
                }
            }

            scope.delete = function ($event) {
                if (!scope.onDelete) {
                    console.warn('no onDelete callback provided to tvlVideo');
                    return;
                }

                scope.onDelete(scope.video);

                $event.stopPropagation();
                $event.preventDefault();
            }
        }
    }
})();
