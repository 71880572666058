(function() {
    'use strict';

    angular
        .module('tvl.brandSafety')
        .controller('CreateChannelModalController', CreateChannelModalController);

    CreateChannelModalController.$inject = [
        '$uibModalInstance',
        'toastr',
        'BrandSafetyChannel',
        'channels',
    ];

    function CreateChannelModalController($uibModalInstance, toastr, BrandSafetyChannel, channels) {
        var vm = this;

        vm.createForm = {
            channelUrl: null,
        };
        vm.selectedChannels = channels;

        vm.creating = false;
        vm.exists = false;
        vm.isAlreadySelected = false;

        vm.createChannel = createChannel;
        vm.showChannel = showChannel;
        vm.cancelChannelCreation = cancelChannelCreation;

        function createChannel() {

            var cleanChannelId = getChannelId();
            if (!cleanChannelId) {
                return false;
            }

            vm.creating = true;

            var data = {
                'channelId': cleanChannelId
            };
            BrandSafetyChannel.create(data)
                .$promise
                .then(onCreateSuccess, onCreateError);

            function onCreateSuccess(response) {
                vm.creating = false;
                if (response.hasOwnProperty('channelId')) {
                    $uibModalInstance.close(response);
                    toastr.success('Channel has been created successfully!');
                } else if (response.hasOwnProperty('id') && response.id === 'channel_already_exists') {
                    vm.exists = true;
                } else {
                    toastr.error('Our apologies, we have been unable to create this channel. Please, try again later.');
                }
            }

            function onCreateError(response) {
                try {
                    toastr.error(response.data.error.exception[0].message);
                } catch (e) {
                    toastr.error('Our apologies, we have been unable to create this channel. Please, try again later.');
                }
                vm.creating = false;
            }
        }

        function showChannel() {
            var cleanChannelId = getChannelId();
            if (!cleanChannelId) {
                return false;
            }

            var data = {
                'channelId': cleanChannelId
            };
            BrandSafetyChannel.get(data)
                .$promise
                .then(onGetSuccess, onGetError);

            function onGetSuccess(response) {
                if (response.hasOwnProperty('channelId')) {
                    $uibModalInstance.close(response);
                } else {
                    toastr.error('Our apologies, we have been unable to get this channel. Please, try again later.');
                }
            }

            function onGetError(response) {
                try {
                    toastr.error(response.data.error.exception[0].message);
                } catch (e) {
                    toastr.error('Our apologies, we have been unable to get this channel. Please, try again later.');
                }
                vm.creating = false;
            }
        }

        function getChannelId() {
            if (vm.selectedChannels.includes(vm.createForm.channelUrl)) {
                /* Channel is already selected in the user's channels list */
                vm.isAlreadySelected = true;
                return false;
            }
            var channelIdRegexPattern = /(\bUC[a-zA-Z0-9_-]{22}\b)/;
            var matches = channelIdRegexPattern.exec(vm.createForm.channelUrl);
            if (!matches || matches.length < 1) {
                toastr.error('Invalid YouTube chanel url!');
                return false;
            }
            return matches[matches.length - 1];
        }

        function cancelChannelCreation() {
            return $uibModalInstance.dismiss();
        }
    }

})();
