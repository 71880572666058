(function() {
    'use strict';

    angular
        .module('tvl.login')
        .controller('ResettingController', ResettingController);

    ResettingController.$inject = [
        '$rootScope',
        '$scope',
        '$stateParams',
        '$state',
        'resourceResponse',
        'User',
        'toastr',
        'Logout',
        'jwt'
    ];

    function ResettingController(
        $rootScope,
        $scope,
        $stateParams,
        $state,
        resourceResponse,
        User,
        toastr,
        Logout,
        jwt
    ) {
        var vm = this;

        $rootScope.bodyClass = 'login';
        vm.resettingModel = {
            passwordFirst: null,
            passwordSecond: null,
        };
        vm.passwordMinLength = 8;

        vm.resetToken = $stateParams.token;

        vm.viewName = 'login.change' === $state.$current.name ? 'change' : 'recovery';

        $scope.$watchGroup(['vm.resettingModel.passwordFirst','vm.resettingModel.passwordSecond'], function() {

            var val1 = vm.resettingModel.passwordFirst;
            var val2 = vm.resettingModel.passwordSecond;

            if(val1 !== null && val2 !== null && val1 === val2) {
                $scope.resetPassword.confirmPassword.$invalid = false;
            } else {
                $scope.resetPassword.confirmPassword.$invalid = true;
                $scope.resetPassword.$invalid = true;
            }
        });

        vm.submit = function() {
            var passwordData = Object.assign({}, vm.resettingModel);

            if ('login.change' === $state.$current.name) {
                User.changePassword({}, {'password': passwordData.passwordFirst, 'confirmPassword': passwordData.passwordSecond})
                    .$promise
                    .then(function() {
                        toastr.success('Your password has been updated.');
                        Logout.create({}).$promise
                            .then(function(response) {
                                jwt.clearSession();
                                $state.go('login.login');
                            }, resourceResponse.error);
                    }, resourceResponse.error);
            } else {
                User.resetUserPassword({resetToken: vm.resetToken}, {'password': passwordData.passwordFirst, 'confirmPassword': passwordData.passwordSecond})
                    .$promise
                    .then(function() {
                        toastr.success('Your password has been updated.');
                        setTimeout(function () {
                            $state.go('login.login');
                        }, 1000);
                    }, resourceResponse.error);
            }
        };
    }
})();
