(function () {
    'use strict';

    angular
        .module('tvl.campaign')
        .controller('UpdateFacebookAdVideoModalOnNetworkController', UpdateFacebookAdVideoModalOnNetworkController);

    UpdateFacebookAdVideoModalOnNetworkController.$inject = [
        '$scope',
        '$uibModalInstance',
        'tvlAdVideoUtils',
        'FacebookBusiness',
        'tvlUuid',
        'tvlUrl',
        'toastr',
        'Upload',
        'adInfo',
        'resourceResponse',
        'placement'
    ];

    /* @ngInject */
    function UpdateFacebookAdVideoModalOnNetworkController(
        $scope,
        $uibModalInstance,
        tvlAdVideoUtils,
        FacebookBusiness,
        tvlUuid,
        tvlUrl,
        toastr,
        Upload,
        adInfo,
        resourceResponse,
        placement
    ) {

        var vm = this;
        // $scope.updateAdVideo = updateAdVideo;
        $scope.cancel = cancel;
        // $scope.onImageChange = onImageChange;

        $scope.maxFileSize = 1000000; //1MB
        $scope.validImageTypes = ['image/jpeg', 'image/png']; //jpg png
        $scope.facebookAdVideoModel = tvlAdVideoUtils.buildAd(adInfo);
        $scope.imageSource = null;


        vm.postIdToSearch = null;
        vm.facebookPage = null;
        vm.tvlAdVideoUtils = tvlAdVideoUtils;
        vm.facebookInstagramAdVideo = new tvlAdVideoUtils.FacebookAnyAdVideo();

        vm.showPostsSelector = showPostsSelector;
        vm.showIgPostsSelector = showIgPostsSelector;
        vm.showImageSelector = showImageSelector;
        vm.showVideoSelector = showVideoSelector;
        vm.isAdVideoFacebookPhoto = isAdVideoFacebookPhoto;
        vm.isAdVideoFacebookVideo = isAdVideoFacebookVideo;
        vm.getAvailableFacebookAdVideos = getAvailableFacebookAdVideos;
        vm.isModalBlocked = isModalBlocked;
        vm.checkFacebookInstagramVideoUrl = checkFacebookInstagramVideoUrl;
        vm.showFacebookOptionsForCPCGoal = showFacebookOptionsForCPCGoal;
        vm.updateAdVideo = updateAdVideo;
        vm.isValidFacebookInstagramLinkUrl = isValidFacebookInstagramLinkUrl;
        vm.isValidFacebookInstagramDisplayUrl = isValidFacebookInstagramDisplayUrl;
        vm.checkFacebookLinkUrlAndDisplayUrlDomains = checkFacebookLinkUrlAndDisplayUrlDomains;

        vm.facebookAccountCredentials = {
            accountId: adInfo.customerAccountId,
            adAccountId: adInfo.facebookAdAccountId
        };


        //todo if external foto select foto if video select video
        vm.ui = {
            postSearch: false,
            loadingFacebookPages: true,
            adVideoSource: ($scope.facebookAdVideoModel.isFacebookAny()) ? tvlAdVideoUtils.FACEBOOK_VIDEO_SOURCE_FROM_POST : tvlAdVideoUtils.FACEBOOK_VIDEO_SOURCE_FROM_MEDIA_PHOTO,
            showUploadAssetsSection: false,
            assetsUploadInProgress: false,
        };


        activate();

        function activate() {
            $scope.imageSource = adInfo.videoThumbnail;
            $scope.facebookAdVideoModel.title = adInfo.videoTitle;
            $scope.facebookAdVideoModel.type = adInfo.video.type;
            $scope.facebookAdVideoModel.videoId = adInfo.video.videoId;
            $scope.facebookAdVideoModel.videoUrl = adInfo.video.videoUrl;
            $scope.facebookAdVideoModel.postType = adInfo.video.creativeType;
            $scope.facebookAdVideoModel.postId = adInfo.video.postId;
            $scope.facebookAdVideoModel.pageId = adInfo.video.pageId;
            $scope.facebookAdVideoModel.thumbnailUrl = adInfo.videoThumbnail;
            $scope.facebookAdVideoModel.imageContent = null;

            vm.facebookInstagramAdVideo = $scope.facebookAdVideoModel;
            vm.facebookInstagramAdVideo.facebookOptimizationGoal = placement.facebookOptimizationGoal;

            if (vm.facebookInstagramAdVideo.type === tvlAdVideoUtils.FACEBOOK_CREATIVE_POST_VIDEO) {
                vm.ui.adVideoSource = tvlAdVideoUtils.FACEBOOK_VIDEO_SOURCE_FROM_MEDIA;
            }

            vm.availableFacebookPages = [];
            vm.accountId = adInfo.customerAccountId;
            vm.adAccountId = adInfo.facebookAdAccountId;

            vm.unregisterFns = [
                $scope.$watch('vm.facebookInstagramAdVideo.videoUrl', onFacebookInstagramVideoUrlChange),
                $scope.$watch('vm.ui.adVideoSource', onFacebookAdVideoSourceChange),
                $scope.$watch('vm.facebookPage', onFacebookPageChange),
                $scope.$watch('vm.facebookPost', onFacebookPostChange),
                $scope.$watch('vm.ui.showUploadAssetsSection', onFacebookAssetSourceChange),
                $scope.$watch('vm.facebookAdVideo', onFacebookAdVideoChange),
                $scope.$watch('vm.postIdToSearch', onFacebookPostIdChange
            )];

            getAvailableFacebookPages();
            getFacebookCallToActionButtonValues(vm.facebookInstagramAdVideo.goal === tvlAdVideoUtils.GOAL_CONVERSIONS ? tvlAdVideoUtils.GOAL_IMPRESSIONS : vm.facebookInstagramAdVideo.goal);
            // checkFacebookInstagramVideoUrl(vm.facebookInstagramAdVideo.videoUrl);
        }

        function isValidFacebookInstagramLinkUrl(url) {
            if (url) {
                return tvlUrl.isValid(url) && false === tvlUrl.isFacebookUrl(url);
            }
        }

        function isValidFacebookInstagramDisplayUrl(url) {
            if (url) {
                return tvlUrl.isValid(url) && false === tvlUrl.isFacebookUrl(url);
            }
            return true;
        }

        function checkFacebookLinkUrlAndDisplayUrlDomains() {
            if (vm.facebookInstagramAdVideo.linkUrl !== '' &&
                vm.facebookInstagramAdVideo.displayUrl !== '' &&
                tvlUrl.domainsMatch(vm.facebookInstagramAdVideo.linkUrl, vm.facebookInstagramAdVideo.displayLink)) {
                return true;
            }
            return false;
        }

        function getFacebookCallToActionButtonValues(goal) {
            FacebookBusiness.getCallToActionButtonValues({adGoal:goal})
                .$promise
                .then(onGetFacebookCallToActionButtonValuesSuccess)
                .catch(onGetFacebookCallToActionButtonValuesError);

            function onGetFacebookCallToActionButtonValuesSuccess (response) {
                vm.facebookCallToActionButtonValues = response.data.map(function(callToAction) {
                    return {
                        value: callToAction,
                        name:  _.startCase(_.toLower(callToAction)).replace('_', ' ')
                    };
                });
            }

            function onGetFacebookCallToActionButtonValuesError (error) {
                toastr.error('Our apologies, we have been unable to get the available Facebook call to action buttons. Please, try again later.');
            }
        }

        function showFacebookOptionsForCPCGoal() {
            return vm.facebookInstagramAdVideo.goal === 'clicks' &&
                !vm.facebookInstagramAdVideo.isFacebookExternalCarousel();
        }

        function onFacebookInstagramVideoUrlChange(newVal, oldVal) {
            if (newVal !== oldVal) {
                checkFacebookInstagramVideoUrl(newVal);
            }
        }

        function isAdVideoFacebookPhoto() {
            return (vm.facebookInstagramAdVideo && vm.facebookInstagramAdVideo.type === vm.tvlAdVideoUtils.FACEBOOK_CREATIVE_POST_PHOTO);
        }
        function isAdVideoFacebookVideo() {
            return (vm.facebookInstagramAdVideo && vm.facebookInstagramAdVideo.type === vm.tvlAdVideoUtils.FACEBOOK_CREATIVE_POST_VIDEO);
        }

        function onFacebookAssetSourceChange(newVal, oldVal) {
            if (newVal === oldVal) {
                return;
            }

            vm.facebookAdVideo = null;
        }

        function onFacebookPostChange(newVal, oldVal) {
            if (newVal === oldVal) {
                return;
            }

            // resetFacebookLinkInfo();
            //
            // if (vm.facebookPost) {
            //     if (vm.facebookPost.sugested_id) {
            //         vm.postNotFound = true;
            //         vm.sugestedPost = vm.facebookPost.promotable_id.split('_')[1];
            //         return;
            //     }
            //
            //     var attachementType = (vm.facebookPost.attachment && vm.facebookPost.attachment.type) ? vm.facebookPost.attachment.type : null;
            //     vm.ui.isFacebookPostMultiShare = (attachementType && attachementType === tvlAdVideoUtils.FACEBOOK_CREATIVE_POST_MULTI_SHARE);
            //
            if (vm.facebookPost) {
                var facebookDomainUrlWithProtocol = 'https://www.facebook.com/';
                vm.facebookInstagramAdVideo.postId = vm.facebookPost.id;

                vm.facebookInstagramAdVideo.pageId = vm.facebookPage.id;
                vm.facebookInstagramAdVideo.postType = vm.facebookPost.attachment.type;
                vm.facebookInstagramAdVideo.videoUrl = (vm.facebookPost.attachment.target.id) ?
                    facebookDomainUrlWithProtocol + vm.facebookPost.attachment.target.id :
                    facebookDomainUrlWithProtocol + vm.facebookPost.id;
                vm.facebookInstagramAdVideo.thumbnailUrl = vm.facebookPost.picture;
            }

        }

        function showPostsSelector() {

            return vm.facebookInstagramAdVideo.isFacebookAny();

            // return true;
        }

        function showIgPostsSelector() {
            return (vm.facebookInstagramAdVideo.isFacebookAny() && !vm.facebookInstagramAdVideo.isCpv());
        }


        function showImageSelector() {
            return (vm.facebookInstagramAdVideo.isFacebookExternal() && !vm.facebookInstagramAdVideo.isCpv() && (vm.facebookInstagramAdVideo.type === tvlAdVideoUtils.FACEBOOK_CREATIVE_POST_PHOTO));
        }

        function showVideoSelector() {
            return vm.facebookInstagramAdVideo.isFacebookExternal() && (vm.facebookInstagramAdVideo.type === tvlAdVideoUtils.FACEBOOK_CREATIVE_POST_VIDEO);
        }

        function onFacebookPostIdChange(newVal, oldVal) {
            if (newVal === oldVal) {
                return;
            }
            vm.postFoundNotValid = null;
            vm.postNotFound = false;
            vm.sugestedPost = null;

            var goal = (vm.facebookInstagramAdVideo.goal === vm.tvlAdVideoUtils.GOAL_ACTION) ?
                vm.tvlAdVideoUtils.GOAL_IMPRESSIONS:
                vm.facebookInstagramAdVideo.goal;

            var params = {
                accountId: vm.accountId,
                pageId: vm.facebookPage.id,
                postId: vm.postIdToSearch,
                goal: goal
            };

            // Post ids is greater than 14 length?, ask to the api just when length matched
            if (vm.postIdToSearch.length > 14) {
                vm.facebookPost = null;
                vm.ui.isFacebookPostMultiShare = false;
                vm.postFoundNotValid = null;
                return FacebookBusiness.getAccessiblePosts(params)
                    .$promise
                    .then(onGetPostSuccess)
                    .catch(onGetPostError)
                    .finally(onGetPostFinally);
            }

            function onGetPostSuccess(post) {
                vm.postNotFound = false;

                var type = vm.getFacebookTypePost(post['data'][0][0]);
                if (type.hasError) {
                    vm.facebookPost = null;
                    vm.ui.isFacebookPostMultiShare = false;
                    vm.facebookInstagramAdVideo.videoUrl = '';
                    vm.facebookInstagramAdVideo.postType = '';
                    vm.facebookAdVideo = null;
                    vm.postFoundNotValid = type.description;
                } else {
                    vm.facebookPost = post['data'][0][0];
                }
                if (!post['data'][0][0]['sugested_id']) {
                    vm.availableFacebookPosts = _.unionBy(vm.availableFacebookPosts, post['data'][0], 'id');
                }
            }
            function onGetPostFinally() {
            }
            function onGetPostError() {
                vm.postNotFound = true;
                vm.sugestedPost = null;
                vm.facebookInstagramAdVideo.videoUrl = '';
                vm.facebookInstagramAdVideo.postType = '';
                vm.facebookAdVideo = null;
            }
        }

        function onFacebookAdVideoSourceChange(newVal, oldVal) {
            if (newVal === oldVal) {
                return;
            }
        }

        function onFacebookPageChange(newVal, oldVal) {
            if (newVal === oldVal) {
                return;
            }
            vm.availableFacebookPosts = [];
            vm.availableInstagramPosts = [];
            vm.instagramAccount = null;
            vm.InstagramId = null;
            getAssociatedInstragtamId();

            vm.beforePagePostRequest = null;

            if (vm.facebookPage) {
                if (tvlAdVideoUtils.isFacebookAnyAdVideoFormat(vm.facebookInstagramAdVideo.format)) {
                    vm.availableFacebookPosts = [];

                    if (vm.ui.adVideoSource === tvlAdVideoUtils.FACEBOOK_VIDEO_SOURCE_FROM_POST) {
                        getAvailableFacebookPosts(vm.accountId, vm.facebookPage.id, vm.facebookInstagramAdVideo.goal == tvlAdVideoUtils.GOAL_CONVERSIONS ? tvlAdVideoUtils.GOAL_IMPRESSIONS : vm.facebookInstagramAdVideo.goal);
                    }

                } else if (
                    tvlAdVideoUtils.isFacebookExternalAdVideoFormat(vm.facebookInstagramAdVideo.format) ||
                    tvlAdVideoUtils.isFacebookCarouselExternalAdVideoFormat(vm.facebookInstagramAdVideo.format)
                ) {
                    vm.facebookInstagramAdVideo.videoUrl = '';
                    vm.facebookInstagramAdVideo.postType = '';
                    vm.ui.isFacebookPostMultiShare = false;
                    vm.facebookInstagramAdVideo.pageId = vm.facebookPage.id;
                    getAvailableFacebookAdVideos();
                } else {
                    vm.facebookInstagramAdVideo.pageId = vm.facebookPage.id;
                }
                vm.facebookPost = null;
                vm.postIdToSearch = null;
                vm.postNotFound = false;
                vm.sugestedPost = null;
                vm.postFoundNotValid = null;
                vm.facebookInstagramAdVideo.videoUrl = '';
                vm.facebookInstagramAdVideo.postType = '';
                vm.facebookAdVideo = null;
                vm.ui.isFacebookPostMultiShare = false;
            }




        }


        function onFacebookAdVideoChange(newVal, oldVal) {
            if (newVal === oldVal) {
                return;
            }

            if(vm.facebookAdVideo) {
                vm.facebookInstagramAdVideo.videoId = vm.facebookAdVideo.id;
                vm.facebookInstagramAdVideo.imageUrl = vm.facebookAdVideo.picture;
                vm.facebookInstagramAdVideo.thumbnailUrl = vm.facebookAdVideo.permalink_url;
                vm.facebookInstagramAdVideo.name = vm.facebookAdVideo.name;
                //todo check if we really need this field for Facebook
                vm.videosFound.facebookOrInstagram = true;
            }
        }



        vm.isFacebookElegiblePost = function(post){
            return post.is_elegible !== 'ineligible';
        };

        function getAvailableFacebookAdVideos() {
            vm.ui.loadingFacebookAdVideos = true;

            switch (vm.ui.adVideoSource) {
                case tvlAdVideoUtils.FACEBOOK_VIDEO_SOURCE_FROM_MEDIA:
                    return FacebookBusiness.getAdVideos({
                        accountId: vm.accountId,
                        adAccountId: vm.adAccountId,
                        adPosition: vm.facebookInstagramAdVideo.facebookPositions
                    })
                        .$promise
                        .then(onGetAdVideosSuccess)
                        .catch(onGetAdVideosError)
                        .finally(onGetAdVideosFinally);
                case tvlAdVideoUtils.FACEBOOK_VIDEO_SOURCE_FROM_MEDIA_PHOTO:
                    return FacebookBusiness.getAdImages({
                        accountId: vm.accountId,
                        adAccountId: vm.adAccountId,
                        adPosition: vm.facebookInstagramAdVideo.facebookPositions
                    })
                        .$promise
                        .then(onGetAdVideosSuccess)
                        .catch(onGetAdVideosError)
                        .finally(onGetAdVideosFinally);
                //forced fields for carrousel Ads
                default: vm.ui.loadingFacebookAdVideos = false;
                    vm.ui.facebookAdVideoAccessible = true;
            }

            function onGetAdVideosSuccess(adVideos) {
                if (vm.facebookInstagramAdVideo.type === tvlAdVideoUtils.FACEBOOK_CREATIVE_POST_PHOTO) {
                    adVideos = tvlAdVideoUtils.normalizeFacebookAdImagesData(adVideos);
                }

                vm.availableFacebookAdVideos = adVideos;
                vm.ui.facebookAdVideoAccessible = true;
            }

            function onGetAdVideosError() {
                toastr.error(
                    'Our apologies, we have been unable to find ad videos for this Facebook account.'
                );
            }

            function onGetAdVideosFinally() {
                vm.ui.loadingFacebookAdVideos = false;
            }
        }

        function getAvailableFacebookPosts(accountId, pageId, goal) {
            vm.ui.loadingFacebookPosts = true;

            var params = {
                accountId: accountId,
                pageId: pageId,
                goal: goal,
                after: vm.afterFacebookPosts
            };

            if (_.isEqual(vm.beforePagePostRequest, params)) {
                return;
            }

            vm.beforePagePostRequest = params;

            return FacebookBusiness.getAccessiblePosts(params)
                .$promise
                .then(onGetPostsSuccess)
                .catch(resourceResponse.error)
                .finally(onGetPostsFinally);

            function onGetPostsSuccess(response) {
                vm.availableFacebookPosts = [...vm.availableFacebookPosts, ...response.data.posts];
                vm.afterFacebookPosts = response.data.after;
            }

            function onGetPostsFinally() {
                vm.ui.loadingFacebookPosts = false;
            }
        }

        vm.loadMoreFacebookPosts = function(){
            if (false === vm.ui.loadingFacebookPosts) {
                getAvailableFacebookPosts(vm.accountId, vm.facebookPage.id, vm.facebookInstagramAdVideo.goal == tvlAdVideoUtils.GOAL_CONVERSIONS ? tvlAdVideoUtils.GOAL_IMPRESSIONS : vm.facebookInstagramAdVideo.goal);
            }
        };

        vm.getFacebookTypePost = function(post) {
            var postType = _.get(post, 'attachment.type', null);

            if (!postType) {
                postType = _.get(post, 'media_type', null);
                //clean ig_ prefix from ig posts media type
                postType = postType.substr(3);
            }


            var type = {
                icon: 'fa-ban',
                description: 'Post type (' + postType + ') not supported yet.',
                hasError: true
            };

            if (false === vm.isFacebookElegiblePost(post)) {
                type.description = 'Post can not be used as ad.';
                type.hasError = true;

                return type;
            }

            //for facebook cpm optimization goals
            if (false === vm.isFacebookPostTypeSelectableByOptimizationGoal(postType) && tvlAdVideoUtils.isCpmGoal(vm.facebookInstagramAdVideo.goal)) {
                type.description = 'Post type (' + postType + ') not supported for this ad delivery.';
                type.hasError = true;

                return type;
            }

            if (false === vm.isFacebookSelectablePost(post)) {
                type.description = 'Post type (' + postType + ') not supported yet for this objective.';
                type.hasError = true;

                return type;
            }

            switch (true) {
                case isFacebookPhotoPost(postType):
                    type = {
                        icon: 'fa-camera',
                        description: 'Photo',
                        hasError: false
                    };
                    break;
                case isFacebookVideoPost(postType):
                    type = {
                        icon: 'fa-video-camera',
                        description: 'Video',
                        hasError: false
                    };
                    break;
                case isFacebookSharePost(postType):
                    type = {
                        icon: 'fa-share-square',
                        description: 'Share',
                        hasError: false
                    };
                    break;
                case isFacebookMultiSharePost(postType):
                    type = {
                        icon: 'fa-columns',
                        description: 'Carousel',
                        hasError: false
                    };
                    break;
            }

            return type;
        };

        vm.isFacebookPostTypeSelectableByOptimizationGoal = function (attachmentType) {
            if(vm.facebookInstagramAdVideo.goal && (tvlAdVideoUtils.isCpmGoal(vm.facebookInstagramAdVideo.goal) ||
                tvlAdVideoUtils.isCpvGoal(vm.facebookInstagramAdVideo.goal))) {
                // var attachmentType = _.get(post, 'attachment.type', null);
                // if (!attachmentType) {
                //     attachmentType = _.get(post, 'media_type', null);
                //     //clean ig_ prefix from ig posts media type
                //     attachmentType = attachmentType.substr(3);
                // }

                return null !== attachmentType &&
                    tvlAdVideoUtils.getFacebookPostTypesByOptimizationGoal(vm.facebookInstagramAdVideo.facebookOptimizationGoal).includes(attachmentType);
            }
            return true;
        };

        function isFacebookPhotoPost(postType) {
            return postType.toLowerCase().indexOf(tvlAdVideoUtils.FACEBOOK_CREATIVE_POST_PHOTO) !== -1 || postType.toLowerCase().indexOf(tvlAdVideoUtils.INSTAGRAM_CREATIVE_POST_PHOTO) !== -1;
        }

        function isFacebookVideoPost(postType) {
            return postType.toLowerCase().indexOf(tvlAdVideoUtils.FACEBOOK_CREATIVE_POST_VIDEO) !== -1 || postType.toLowerCase().indexOf(tvlAdVideoUtils.INSTAGRAM_CREATIVE_POST_VIDEO) !== -1;
        }

        function isFacebookSharePost(postType) {
            return postType.toLowerCase() === tvlAdVideoUtils.FACEBOOK_CREATIVE_POST_SHARE;
        }

        function isFacebookMultiSharePost(postType) {
            return postType.toLowerCase() === tvlAdVideoUtils.FACEBOOK_CREATIVE_POST_MULTI_SHARE;
        }

        vm.isFacebookSelectablePost = function(post) {
            var isElegible = post.is_elegible !== 'ineligible';
            var attachmentType = _.get(post, 'attachment.type', null);
            if (!attachmentType) {
                attachmentType = _.get(post, 'media_type', null);
                //clean ig_ prefix from ig posts media type
                attachmentType = attachmentType.substr(3);
            }
            var isValidAttachment = null !== attachmentType &&
                tvlAdVideoUtils.getFacebookPostTypesByGoal(vm.facebookInstagramAdVideo.goal).includes(attachmentType);
            var isValidPostForOptimizationGoal =  vm.isFacebookPostTypeSelectableByOptimizationGoal(attachmentType);
            return isElegible && isValidAttachment && isValidPostForOptimizationGoal;
        };

// INSTAGRAM POSTS, de momento out
        // function getAvailableInstagramPosts(accountId, pageId, both) {
        //     vm.ui.loadingInstagramPosts = true;
        //
        //     var params = {
        //         accountId: accountId,
        //         pageId: pageId,
        //         photoAndVideo: both,
        //         userFbToken: vm.accessToken,
        //     };
        //
        //     if (window.FB) {
        //         FB.getLoginStatus(function(response) {
        //             if (response && !response.error && response.status === 'connected') {
        //                 params.userFbToken =  response.authResponse.accessToken;
        //             } else {
        //                 toastr.error(
        //                     'Please logout from Facebook login and try again'
        //                 );
        //             }
        //         });
        //     }
        //
        //     return FacebookBusiness.getAccessibleInstagramAccount(params)
        //         .$promise
        //         .then(function(response) {
        //             vm.instagramAccount = response.data;
        //
        //             var params = {
        //                 accountId: accountId,
        //                 userFbToken: vm.accessToken,
        //                 instagramId: vm.instagramAccount.id,
        //                 photoAndVideo: both,
        //             };
        //
        //             return FacebookBusiness.getInstagramAccountMedia(params)
        //                 .$promise
        //                 .then(onGetPostsSuccess)
        //                 .catch(resourceResponse.error)
        //                 .finally(onGetPostsFinally);
        //
        //             function onGetPostsSuccess(response) {
        //                 vm.availableInstagramPosts = response.data;
        //             }
        //
        //
        //         })
        //         .catch(resourceResponse.error)
        //         .finally(onGetPostsFinally);
        //
        //     function onGetPostsFinally() {
        //         vm.ui.loadingInstagramPosts = false;
        //     }
        // }

        function getAvailableFacebookPages() {
            var params = {accountId: vm.accountId};
            vm.ui.loadingFacebookPages = true;


            return FacebookBusiness.getAccessiblePages(params)
                .$promise
                .then(function (response) {
                    vm.ui.loadingFacebookPages = false;
                    vm.availableFacebookPages = response.data;
                });
        }

        function checkFacebookInstagramVideoUrl(url) {
            if (tvlUrl.isValidFacebookInstagramVideoUrl(url)) {
                vm.showFacebookInstagramAdVideoPreview = true;
                vm.facebookInstagramAdVideo.isInstagram = false;
                if (
                    vm.facebookInstagramAdVideo.postType === tvlAdVideoUtils.INSTAGRAM_CREATIVE_POST_PHOTO ||
                    vm.facebookInstagramAdVideo.postType === tvlAdVideoUtils.INSTAGRAM_CREATIVE_POST_VIDEO
                ) {
                    vm.facebookInstagramAdVideo.isInstagram = true;
                }
                checkFacebookVideoAccessibility(url);
            } else {
                vm.showFacebookInstagramAdVideoPreview = false;
            }
        }

        /*
        * relocate where appropriate
        */
        function checkFacebookVideoAccessibility(videoUrl) {

            vm.ui.checkingFacebookVideoAccessibility = true;
            var postType = (!vm.facebookInstagramAdVideo.postType) ?
                tvlAdVideoUtils.FACEBOOK_CREATIVE_POST_VIDEO :
                vm.facebookInstagramAdVideo.postType;

            // true if share or multi_share
            var videoId = (postType.toLowerCase().indexOf(tvlAdVideoUtils.FACEBOOK_CREATIVE_POST_SHARE) !== -1 || postType.toLowerCase().indexOf(tvlAdVideoUtils.INSTAGRAM_CREATIVE_POST_VIDEO) !== -1 || postType.toLowerCase().indexOf(tvlAdVideoUtils.INSTAGRAM_CREATIVE_POST_PHOTO) !== -1) ?
                vm.facebookInstagramAdVideo.postId :
                extractFacebookVideoIdFromUrl(videoUrl);

            FacebookBusiness.getPostAccessibility({accountId: vm.accountId, postCreativeId: videoId, postType: postType})
                .$promise
                .then(function (result) {
                    vm.ui.facebookAdVideoAccessible = result.access_granted === true;
                })
                .finally(function () {
                    vm.ui.checkingFacebookVideoAccessibility = false;
                });
        }

        /*
        * relocate where appropriate (maybe a service?)
        */
        function extractFacebookVideoIdFromUrl(url) {
            var matches = url.match(/facebook\.com\/(?:[0-9a-z\_\-\.]+\/videos\/)?([0-9]+)/i);
            if (matches && matches.length >= 2) {
                return matches[1];
            }
            return null;
        }

//MODAL DE THUMBS
        // function onImageChange($files, $file) {
        //     $scope.facebookAdVideoModel.imageContent = null;
        //     $scope.uploadedImage = null;
        //
        //     if ($file) {
        //         $scope.uploadedImage = {
        //             name: $file.name,
        //             size: $file.size,
        //             type: $file.type,
        //             imageContent: null
        //         };
        //         Upload.base64DataUrl($files).then(onUploadFileUpdateSuccess);
        //     }
        //
        //     function onUploadFileUpdateSuccess(urls) {
        //         $scope.facebookAdVideoModel.imageContent = urls[0];
        //         $scope.imageSource = urls[0];
        //     }
        //
        // }
        //
        // function updateAdVideo(adVideo) {
        //     if (!adVideo.id) {
        //         adVideo.id = tvlUuid.new();
        //     }
        //     //To avoid errors in placements.html
        //     // if we add more fields to this update modal we need to rethink this
        //     delete adVideo.videoUrl;
        //
        //     $uibModalInstance.close({adVideo: adVideo, accessible: true});
        // }
//MODAL DE THUMBS

        function getAssociatedInstragtamId() {

            return FacebookBusiness.getAssociatedIGId({accountId: vm.accountId, pageId: vm.facebookPage.id})
                .$promise
                .then(onGetSuccess)
                .catch(resourceResponse.error)
                .finally(onGetFinally);

            function onGetSuccess(response) {
                vm.InstagramId = response.IgId;
                vm.facebookInstagramAdVideo.instagramId = response.IgId;
            }

            function onGetFinally() {
            }

        }


        function cancel() {
            return $uibModalInstance.dismiss();
        }

        function updateAdVideo(adVideo) {
            $uibModalInstance.close({adVideo: buildAdVideoPutBody(adVideo)});
        }

        function buildAdVideoPutBody(adVideo) {

            if (adVideo.isFacebookAny()) {
                return {
                    'callToActionType': adVideo.callToActionType,
                    'description': adVideo.description,
                    'format': adVideo.format,
                    'goal': adVideo.goal,
                    'id': adVideo.id,
                    // "imageContent": null,
                    'instagramId': adVideo.instagramId, // need to retrieve...
                    'linkDescription': adVideo.linkDescription,
                    'linkTitle': adVideo.linkTitle,
                    'linkUrl': adVideo.linkUrl,
                    'displayLink': adVideo.displayLink,
                    'network': adVideo.network,
                    'pageId': adVideo.pageId,
                    'platform': adVideo.platform,
                    'postId': adVideo.postId,
                    'postType': adVideo.postType,
                    'targetUrl': adVideo.targetUrl,
                    'trackingUrl': adVideo.trackingUrl,
                    'videoUrl': adVideo.videoUrl,
                    'thumbnail': adVideo.thumbnailUrl
                };

            } else if (adVideo.isFacebookExternal()) {
                // todo check this return
                return {
                    'id': adVideo.id,
                    'videoId': adVideo.videoId,
                    'pageId': adVideo.pageId,
                    'platform': adVideo.platform,
                    'instagramId': adVideo.instagramId,
                    'format': adVideo.format,
                    'type': adVideo.type,
                    'imageUrl': adVideo.imageUrl,
                    'name': adVideo.name,
                    'network': adVideo.network,
                    'trackingUrl': adVideo.trackingUrl,
                    'displayLink': adVideo.displayLink,
                    'linkDescription': adVideo.linkDescription,
                    'linkTitle': adVideo.linkTitle,
                    'callToActionType': adVideo.callToActionType,
                    'linkUrl': adVideo.linkUrl,
                    'thumbnail': adVideo.thumbnailUrl
                };
            }


        }

        /**
         * Disable modal controls when necessary.
         * @returns {boolean}
         */
        function isModalBlocked() {

            return vm.ui.loadingFacebookAdVideos || vm.ui.loadingFacebookPages || vm.ui.assetsUploadInProgress;
        }

    }
}());