const tld = require('tld-browserified/tld.js');

(function(tld) {
    angular
        .module('tvl.common')
        .service('tvlUrl', tvlUrl);

    function tvlUrl() {
        return {
            domainsMatch: domainsMatch,
            isValid: isValid,
            isValidSecured: isValidSecured,
            isValidFacebookInstagramVideoUrl: isValidFacebookInstagramVideoUrl,
            isFacebookUrl: isFacebookUrl,
            checkUrlByPatterns: checkUrlByPatterns,
            isValidYoutubeVideoUrl: isValidYoutubeVideoUrl,
            getYoutubeVideoUrlById: getYoutubeVideoUrlById,
            isValidFacebookInstagramUrl: isValidFacebookInstagramUrl,
        };

        /**
         * Returns complete youtube url for given video id.
         * @param youtubeVideoId
         * @returns {*}
         */
        function getYoutubeVideoUrlById(youtubeVideoId) {
            return 'http://www.youtube.com/watch?v=' + youtubeVideoId;
        }

        /**
         * Return whether all the given URLs have the same domain.
         *
         * This function accepts an arbitrary amount of URLs to be tested.
         *
         * @param {...String} url
         * @return {Boolean}
         */
        function domainsMatch(url) {
            var domain = null;
            for (var i = 0; i < arguments.length; i++) {
                var url = arguments[i];
                var thisDomain = tld.getDomain(url);
                if (! thisDomain) {
                    return false;
                } else if (! domain) {
                    domain = thisDomain;
                } else if (domain !== thisDomain) {
                    return false;
                }
            }
            return true;
        }

        /**
         * Return whether a string is a valid url or not
         *
         * @param {...String} url
         * @return {Boolean}
         */
        function isValid(url) {
            return /^(?:(?:https?|ftp):\/\/)?(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(url);
        }

        /**
         * Return whether a string is a valid secured url or not
         *
         * @param {...String} url
         * @return {Boolean}
         */
        function isValidSecured(url) {
            return /^(?:https:\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(url);
        }

        /**
         * Return whether a string is a valid url for Facebook or Instagram networks
         *
         * @param videoUrl
         * @returns {boolean}
         */
        function isValidFacebookInstagramVideoUrl(videoUrl) {
            var regularUrlRegex = new RegExp(/^((?:http|https):\/\/www\.facebook\.com\/(?:.*\/videos\/)?\d*)/i);
            var containsIg = videoUrl.includes('instagram');

            return checkUrlByPatterns(videoUrl, [regularUrlRegex]) || containsIg;
        }

        /**
         * Return whether a string is a valid url for Facebook or Instagram networks
         *
         * @param url
         * @returns {boolean}
         */
        function isFacebookUrl(url) {
            var regularUrlRegex = new RegExp(/^((?:http|https):\/\/(www\.)?facebook\.(com|es))/i);
            return checkUrlByPatterns(url, [regularUrlRegex]);
        }

        /**
         * Return whether a string is a valid url applying the patterns given
         *
         * @param videoUrl
         * @param patterns
         * @returns {boolean}
         */
        function checkUrlByPatterns(videoUrl, patterns) {
            if (typeof videoUrl === 'undefined' || videoUrl.length < 1) {
                return false;
            }

            for (var i = 0; i < patterns.length; i++) {
                if (patterns[i].test(videoUrl)) {
                    return true;
                }
            }
            return false;
        }

        /**
         * Return whether a string is a valid url for Youtube network
         *
         * @param videoUrl
         * @returns {boolean}
         */
        function isValidYoutubeVideoUrl(videoUrl) {
            var longUrlRegex = new RegExp(/^((?:http|https):\/\/(?:www\.)?youtube.com\/watch\?v=([A-Z0-9_-]{11})$)/i);
            var shortUrlRegex = new RegExp(/^((?:http|https):\/\/youtu.be\/([A-Z0-9_-]{11})$)/i);
            return checkUrlByPatterns(videoUrl, [longUrlRegex, shortUrlRegex]);
        }

        function isValidFacebookInstagramUrl(videoUrl) {
            if (videoUrl) {
                return isValid(videoUrl) && false === isFacebookUrl(videoUrl);
            }
            return false;
        }
    }
})(tld);
