const moment = require('moment');

(function() {
    angular
        .module('tvl.common')
        .service('tvlDateUtils', tvlDateUtils);

    function tvlDateUtils() {

        return {
            countDaysInDatesRange: countDaysInDatesRange,
            addDaysToDate: addDaysToDate
        };

        /**
         * Returns the number of days between two dates
         *
         * @param Date dateFrom
         * @param Date dateTo
         */
        function countDaysInDatesRange(dateFrom, dateTo) {
            return moment(dateTo).diff(dateFrom, 'days');
        }

        /**
         * Adds days to given date
         * @param Date initialDate
         * @param int numDays
         * @returns {Date}
         */
        function addDaysToDate(initialDate, numDays) {
            return new Date(moment(initialDate).add(numDays, 'days'));
        }
    }
})();
