(function () {
    'use strict';

    angular
        .module('tvl.campaign')
        .directive('tvlCampaignDetails', tvlCampaignDetails);

    tvlCampaignDetails.$inject = ['$log', 'tvlSweetAlert', 'tvlCampaignUtils', 'Constants', 'Interest', 'Topic', 'tvlConstantUtils'];

    /* @ngInject */
    function tvlCampaignDetails($log, tvlSweetAlert, tvlCampaignUtils, Constants, Interest, Topic, tvlConstantUtils) {
        // Usage:
        //
        // Creates:
        //
        var directive = {
            link: link,
            restrict: 'EA',
            templateUrl: 'campaign/campaign-details.html',
            scope: {
                campaign: '=',
                includedContents: '=',
                excludedContents: '=',
                keywords: '='
            }
        };
        return directive;

        function link(scope, element, attrs) {
            scope.editable = attrs.hasOwnProperty('editable');
            scope.availableInterests = [];
            scope.availableTopics = [];
            scope.devices = [
                {value: 'Computer', name: 'Desktop'},
                {value: 'Tablet', name: 'Tablets'},
                {value: 'Mobile', name: 'Mobiles'}
            ];
            scope.genders = [
                {value: 1, name: 'Male'},
                {value: 2, name: 'Female'},
                {value: 3, name: 'Both'}
            ];
            scope.genderIndex = null;
            scope.languages = {};
            scope.uniqueVideos = {};
            scope.getLanguages = getLanguages;
            scope.onAfterSaveGender = onAfterSaveGender;
            scope.searchInterests = searchInterests;
            scope.searchTopics = searchTopics;
            scope.appliesToAdwordsFormats = tvlCampaignUtils.appliesToAdwordsFormats;
            scope.appliesToGoogleDisplayFormats = tvlCampaignUtils.appliesToGoogleDisplayFormats;
            scope.appliesToFacebook = tvlCampaignUtils.appliesToFacebook;
            scope.appliesToDesktops = tvlCampaignUtils.appliesToDesktops;
            scope.appliesToMobiles = tvlCampaignUtils.appliesToMobiles;
            scope.appliesToTablets = tvlCampaignUtils.appliesToTablets;
            scope.getMergedUsersAndAccounts = getMergedUsersAndAccounts;
            scope.getNumberOfAdwordsVideos = tvlCampaignUtils.getNumberOfAdwordsVideos;
            scope.getNumberOfGoogleDisplay = tvlCampaignUtils.getNumberOfGoogleDisplay;
            scope.getNumberOfFacebookVideos = tvlCampaignUtils.getNumberOfFacebookVideos;
            scope.getNumberOfInstagramVideos = tvlCampaignUtils.getNumberOfInstagramVideos;
            scope.getUniqueVideos = tvlCampaignUtils.getUniqueVideos;
            scope.getBudgetForCountryAndMedia = tvlCampaignUtils.getBudgetForCountryAndMedia;
            scope.tvlConstantUtils = tvlConstantUtils;

            scope.ui = {
                hasCpv: scope.campaign.youtubeMaxCpv > 0 || scope.campaign.instagramMaxCpv > 0 || scope.campaign.facebookMaxCpv > 0,
                hasCpm: scope.campaign.youtubeMaxCpm > 0 || scope.campaign.googleDisplayMaxCpm > 0,
                hasCpc: scope.campaign.facebookMaxCpc > 0 || scope.campaign.instagramMaxCpc > 0
            };

            var isMobile = scope.campaign.devices.includes('Mobile');

            scope.facebookIcons = tvlCampaignUtils.getNetworkIconsForBudgetsInWizard(
                scope.campaign.budgets, tvlConstantUtils.NETWORK_FACEBOOK, isMobile
            );
            scope.youtubeIcons = tvlCampaignUtils.getNetworkIconsForBudgetsInWizard(
                scope.campaign.budgets, tvlConstantUtils.NETWORK_ADWORDS, isMobile
            );
            scope.googleDisplayIcons = tvlCampaignUtils.getNetworkIconsForBudgetsInWizard(
                scope.campaign.budgets, tvlConstantUtils.NETWORK_GOOGLE_DISPLAY, isMobile
            );

            scope.campaign.budget = 0;
            scope.campaign.budgets.forEach(function(budget) {
                scope.campaign.budget += budget.amount;
            });

            activate();

            function activate() {
                scope.startDate = new Date(scope.campaign.startDate);
                scope.endDate = new Date(scope.campaign.endDate);
                scope.uniqueVideos = scope.getUniqueVideos(scope.campaign);
                scope.genderIndex = _.find(scope.genders, function(gender) {
                    return gender.name.toLowerCase() === scope.campaign.gender;
                }).value;
                getLanguages();
                scope.$on('$destroy', onDestroy);
            }

            function getLanguages() {
                return Constants.getLanguages()
                    .$promise
                    .then(function (languages) {
                        scope.languages = {};
                        angular.forEach(languages, function(language) {
                            scope.languages[language.code] = language.name;
                        });
                    });
            }

            function getMergedUsersAndAccounts() {
                return _.concat(scope.campaign.users, scope.campaign.accounts);
            }

            /**
             * This function hooks into x-editable callbacks to handle the
             * outrageous fact that x-editable radio buttons only work when the
             * value is a number.
             *
             * Daaaaaaaamn.
             *
             * @param {Number} value
             */
            function onAfterSaveGender(value) {
                switch (value) {
                    case 1:
                        scope.campaign.gender = 'male';
                        break;
                    case 2:
                        scope.campaign.gender = 'female';
                        break;
                    case 3:
                        scope.campaign.gender = 'both';
                        break;
                }
            }

            function searchInterests(searchTerm) {
                var advertisesInBothNetworks = scope.appliesToAdwords(scope.campaign) && (scope.appliesToFacebook(scope.campaign) || scope.appliesToInstagram(scope.campaign));

                var params = {
                    searchTerm: searchTerm,
                    network: advertisesInBothNetworks ? null : (scope.appliesToAdwords(scope.campaign) ? 'adwords' : 'facebook'),
                    enabled: 1
                };

                return Interest.get(params).$promise
                    .then(onGetInterestsSuccess)
                    .catch(onGetInterestsError);

                function onGetInterestsSuccess(data) {
                    scope.availableInterests = data.items;
                }

                function onGetInterestsError(reason) {
                    toastr.error('Our apologies, we have been unable to retrieve interests for you. Please, try again in a few moments.');
                }
            }

            function searchTopics(searchTerm) {
                var advertisesInBothNetworks = scope.appliesToAdwords(scope.campaign) && (scope.appliesToFacebook(scope.campaign) || scope.appliesToInstagram(scope.campaign));

                var params = {
                    searchTerm: searchTerm,
                    network: advertisesInBothNetworks ? null : (scope.appliesToAdwords(scope.campaign) ? 'adwords' : 'facebook'),
                    enabled: 1
                };

                return Topic.get(params).$promise
                    .then(onGetTopicsSuccess)
                    .catch(onGetTopicsError);

                function onGetTopicsSuccess(data) {
                    scope.availableTopics = data.items;
                }

                function onGetTopicsError(reason) {
                    toastr.error('Our apologies, we have been unable to retrieve topics for you. Please, try again in a few moments.');
                }
            }

            function onDestroy() {
                // unregister watchers and other events here
            }
        }
    }
})();
