(function () {
    angular
        .module('tvl.campaign')
        .directive('tvlFacebookAssetSelectInput', tvlFacebookAssetSelectInput);

    /** @ngInject */
    function tvlFacebookAssetSelectInput() {
        return {
            link: link,
            restrict: 'E',
            replace: true,
            scope: {
                facebookAdVideo: '=',
                isVideo: '=',
                isImage: '=',
                facebookPage: '=',
                isLoading: '=',
                isModalBlocked: '=',
                availableAssets: '='
            },
            templateUrl: 'campaign/wizard/directives/fb-asset-select-input.html',
        };

        function link(scope, element, attrs) {
        }
    }
})();