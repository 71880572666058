(function() {
    'use strict';

    angular
        .module('tvl.campaign')
        .directive('tvlKeywordsSelector', tvlKeywordsSelector);

    tvlKeywordsSelector.$inject = ['tvlKeywordsSelectorModal'];

    /* @ngInject */
    function tvlKeywordsSelector(tvlKeywordsSelectorModal) {
        var directive = {
            link: link,
            restrict: 'EA',
            templateUrl: 'campaign/keywords-selector.html',
            scope: {
                selected: '=',
                allowExclude: '=',
                readonly: '=?'
            }
        };

        return directive;

        function link(scope, element, attrs) {
            scope.activeTab = 'included';
            scope.openSelectorModal = openSelectorModal;

            function onSelectorModalSaved(items) {
                if (scope.selected[scope.activeTab] === undefined) {
                    scope.selected[scope.activeTab] = [];
                }
                scope.selected[scope.activeTab] = scope.selected[scope.activeTab].concat(items);
            }

            function openSelectorModal() {
                tvlKeywordsSelectorModal
                    .openSelectorModal(scope.selected[scope.activeTab])
                    .then(onSelectorModalSaved);
            }
        }
    }
})();
