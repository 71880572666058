(function() {
    'use strict';

    angular
        .module('tvl.common')
        .directive('ngIndeterminate', function($compile) {
            return {
                restrict: 'A',
                scope: {
                    isIndeterminate: '&ngIndeterminate'
                },
                link: function($scope, element, attributes) {
                    element.prop('indeterminate', $scope.isIndeterminate);
                }
            };
        });

})();
