(function() {
    'use strict';

    angular
        .module('tvl.common')
        .directive('tvlChannel', tvlChannel);

    tvlChannel.$inject = ['$window', 'moment'];

    /* @ngInject */
    function tvlChannel($window, moment) {
        // Usage:
        //
        // Creates:
        //
        var directive = {
            templateUrl: 'common/channel.html',
            link: link,
            restrict: 'EA',
            scope: {
                channel: '=',
                onClick: '=?',
                onDelete: '=?',
                size: '@?'
            }
        };
        return directive;

        function link(scope, element, attrs) {
            // default to small size if not specified
            if (scope.size === undefined) {
                scope.size = 'small';
            }

            // Facebook Channels do not have a publishedAt property, so we can use
            // createdAt for now.
            var timeProperty = scope.channel.publishedAt || scope.channel.createdAt;
            if (timeProperty && timeProperty.hasOwnProperty('date')) {
                scope.channel.publishedAt = moment(timeProperty.date).format();
            } else if (timeProperty) {
                scope.channel.publishedAt = timeProperty;
            } else {
                scope.channel.publishedAt = null;
            }

            scope.url = '/channel/'+scope.channel.channelId;

            scope.click = function ($event) {
                if (scope.onClick) {
                    scope.onClick(scope.channel);
                    $event.stopPropagation();
                    $event.preventDefault();
                } else {
                    $window.location.href = scope.url;
                }
            };

            scope.delete = function ($event) {
                if (!scope.onDelete) {
                    console.warn('no onDelete callback provided to channel widget');
                    return;
                }

                scope.onDelete(scope.channel);

                $event.stopPropagation();
                $event.preventDefault();
            }
        }
    }
})();
