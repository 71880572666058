(function() {
    'use strict';

    angular
        .module('tvl.campaign')
        .controller('ContentSelectorModalController', ContentSelectorModalController);

    ContentSelectorModalController.$inject = [
        '$scope',
        '$q',
        '$timeout',
        '$uibModalInstance',
        'toastr',
        'tvlCampaignUtils',
        'tvlUrl',
        'tvlYoutube',
        'tvlSession',
        'Channel',
        'Video',
        'ContentsList',
        'lists',
        'currentSelection',
        'availableCountries'
    ];

    /* @ngInject */
    function ContentSelectorModalController(
        $scope,
        $q,
        $timeout,
        $uibModalInstance,
        toastr,
        tvlCampaignUtils,
        tvlUrl,
        tvlYoutube,
        tvlSession,
        Channel,
        Video,
        ContentsList,
        lists,
        currentSelection,
        availableCountries
    ) {
        var vm = this;
        vm.alreadySelected = {
            urls: [],
            media: [],
            lists: []
        };
        vm.lists = lists;
        vm.contentSearchResults = null;
        vm.contentSearchType = null;
        vm.contentSearchTerm = null;
        vm.listToImport = '';
        vm.selectedMedia = [];
        vm.selectedMediaIds = [];
        vm.selectedLists = [];
        vm.availableCountries = availableCountries;
        vm.applicableCountries = null;
        vm.appliesToAllCountries = true;
        vm.ui = {adding: false, searching: false};
        vm.contentsForm = {};
        vm.isAdmin = false;
        vm.searchTerm = null;

        vm.addItems = addItems;
        vm.cancel = cancel;
        vm.clearImportList = clearImportList;
        vm.onMediaSelectionChange = onMediaSelectionChange;
        vm.isListSelected = isListSelected;
        vm.selectList = selectList;
        vm.removeListFromSelection = removeListFromSelection;

        activate();

        function activate() {
            processCurrentSelection(currentSelection);
            getSessionInfo();
            vm.unregisterFns = [
                $scope.$on('tvl.searcher.searchComplete', onSearchComplete),
                $scope.$on('tvl.searcher.searchError', onSearchError),
                $scope.$on('tvl.searcher.searchCleared', onSearchCleared),
                $scope.$watch('vm.contentSearchType', onContentSearchTypeChange),
                $scope.$watch('vm.lists.currentPage', onCurrentPageChange),
                $scope.$watch('vm.appliesToAllCountries', onAppliesToAllCountriesChange),
                $scope.$watch('vm.searchTerm', onSearchTermChange),
            ];
            $scope.$on('$destroy', onDestroy);
        }

        /**
         * @todo refactor into campaignUtils maybe
         */
        function isMedia(content) {
            if (content.type) {
                return content.type === 'channel' || content.type === 'video';
            }
            return content.media && content.media.title;
        }

        function isUrl(content) {
            if (content.type) {
                return content.type === 'url';
            }
            return !(content.list || content.media);
        }

        function isList(content) {
            if (content.type) {
                return content.type === 'list';
            }
            return content.list && content.list.id;
        }
        /**
         * end of todo
         */

        function processCurrentSelection(currentSelection) {
            _.forEach(currentSelection, function(item) {
                if (isList(item)) {
                    vm.alreadySelected.lists.push(item.list.id);
                } else if (isMedia(item)) {
                    vm.alreadySelected.media.push(item.contentId);
                } else if (isUrl(item)) {
                    vm.alreadySelected.urls.push(item.contentId);
                }
            });
        }

        function importUrlList() {
            var videoId = null;
            var channelId = null;
            var youtubeUserId = null;
            var items = {
                channels: [],
                users: [],
                videos: [],
                urls: []
            };
            var media = [];
            var promises = [];

            // Trim the URL-s list and remove empty lines, also split URL-s by whitespaces (if any) and take only first elements
            var lines = _.chain(vm.listToImport.split('\n'))
                .map(function(value) {
                    return value.toString().trim().split(' ')[0];
                })
                .filter(function(value) {
                    // skip URLs which were already selected when opening the modal
                    return value !== '' && vm.alreadySelected.urls.indexOf(value) < 0;
                })
                .value();

            angular.forEach(lines, function(line) {
                videoId = tvlCampaignUtils.getVideoIdFromUrl('youtube', line);
                if (!videoId) {
                    channelId = tvlCampaignUtils.getChannelIdFromUrl('youtube', line);
                    if (channelId) {
                        items.channels.push(channelId);
                    } else {
                        youtubeUserId = tvlCampaignUtils.getUsernameFromUrl(line);
                        if (youtubeUserId) {
                            items.users.push(youtubeUserId);
                        } else if (tvlUrl.isValid(line)) {
                            items.urls.push(line);
                        }
                    }
                } else {
                    items.videos.push(videoId);
                }
            });

            items.channels = _.chunk(items.channels, 50);
            items.videos = _.chunk(items.videos, 50);

            var searchPlatformChannels = function(channelIds) {
                return Channel.searchPlatformChannels({
                        'id[]': channelIds,
                        platform: 'youtube'
                    })
                    .$promise
                    .then(function(result) {

                        angular.forEach(result.items, function(item) {
                            if (item) {
                                media = media.concat({
                                    contentId: item['channelId'],
                                    type: 'channel',
                                    media: item
                                });
                            }
                        });

                    });
            };

            var searchPlatformVideos = function(videoIds) {
                return Video.searchPlatformVideos({
                        'id[]': videoIds,
                        platform: 'youtube'
                    })
                    .$promise
                    .then(function(result) {

                        angular.forEach(result.items, function(item) {
                            if (item) {
                                media = media.concat({
                                    contentId: item['videoId'],
                                    type: 'video',
                                    media: item
                                });
                            }
                        });
                    });
            };

            var searchYoutubeUsers = function(username) {
                return tvlYoutube.getChannelByUsername(username)
                    .then(function(result) {
                        var item = result['data']['items'][0] || false;
                        if (item) {
                            media = media.concat({
                                contentId: item['id'],
                                type: 'channel',
                                media: {
                                    title: item['snippet']['title'],
                                    thumbnail: item['snippet']['thumbnails']['default']['url']
                                }
                            });
                        }
                    });
            };

            if (items.channels.length > 0) {
                angular.forEach(items.channels, function(channelIds) {
                    promises.push(searchPlatformChannels(channelIds));
                });
            }

            if (items.videos.length > 0) {
                angular.forEach(items.videos, function(videoIds) {
                    promises.push(searchPlatformVideos(videoIds));
                });
            }

            if (items.users.length > 0) {
                angular.forEach(items.users, function(username) {
                    promises.push(searchYoutubeUsers(username));
                });
            }

            return $q.all(promises)
                .then(function() {
                    var selected = media;

                    if (items.urls.length > 0) {
                        items.urls = items.urls.map(function (url) {
                            return {type: 'url', contentId: url};
                        });
                        selected = _.uniq(_.concat(selected, items.urls));
                    }
                    
                    if (((lines.length - items.urls.length) === media.length) && (vm.selectedLists.length > 0)) {
                        toastr.success('All media content has been successfully imported.');
                    } else {
                        toastr.warning(media.length + ' of ' + lines.length + ' media items have been successfully imported.');
                    }

                    clearImportList();

                    return selected;
                })
                .catch(function() {
                    console.error('Failed to resolve all URL import promises');
                    toastr.error('Our apologies, we have been unable to import your media items. Please, try again in a few minutes.');
                });
        }

        function getSelectedMedia() {
            return vm.selectedMedia.map(function (media) {
                if (media.hasOwnProperty('videoId')) {
                    return {type: 'video', media: media, contentId: media.id};
                } else {
                    return {type: 'channel', media: media, contentId: media.id};
                }
            });
        }

        function getSelectedLists() {
            return vm.selectedLists.map(function(list) {
                return {type: 'list', contentId: null, list: list};
            });
        }

        /**
         * Resolve the modal with the selected items.
         */
        function addItems() {
            vm.ui.adding = true;
            return $q.all({
                imported: importUrlList(),
                media: getSelectedMedia(),
                lists: getSelectedLists(),
            }).then(function (data) {
                var allItems = data.imported
                    .concat(data.media, data.lists)
                    .map(function (item) {
                        // include info about which countries apply for this targeting
                        item.countries = vm.applicableCountries.length ? vm.applicableCountries : null;
                        return item;
                    });
                $uibModalInstance.close(allItems);
            }).catch(function(err) {
                toastr.error('Oops! Something happened while adding your items. Please, try again later.');
                vm.ui.adding = false;
            });
        }

        /**
         * Dismiss the modal.
         */
        function cancel() {
            return $uibModalInstance.dismiss();
        }

        function clearImportList() {
            vm.listToImport = null;
        }

        function isListSelected(list) {
            return _.find(vm.selectedLists, {
                id: list.id
            }) !== undefined;
        }

        function selectList(list) {
            if (!isListSelected(list) && vm.alreadySelected.lists.indexOf(list.id) < 0) {
                vm.selectedLists.push(list);
            }
        }

        function removeListFromSelection(list) {
            var idx = _.findIndex(vm.selectedLists, {
                id: list.id
            });
            vm.selectedLists.splice(idx, 1);
        }

        function search(type) {
            vm.contentSearchResults = null;
            vm.ui.searching = true;
            $scope.$broadcast('tvl.searcher.launchSearch', type);
        }

        /**
         * Callback function invoked when a search is successfully completed.
         */
        function onSearchComplete(event, data) {
            vm.ui.searching = false;
            vm.contentSearchResults = data.results.youtube;
        }

        /**
         * Callback function invoked when a search fails.
         */
        function onSearchError(event, data) {
            vm.ui.searching = false;
            toastr.error('Our apologies, we have been unable to complete your search. Please, try again in a few minutes.');
        }

        /**
         * Callback function invoked when the search box is cleared by the
         * user.
         */
        function onSearchCleared(event) {
            vm.contentSearchResults = null;
        }

        /**
         * Callback function invoked when the type of search is modified.
         */
        function onContentSearchTypeChange(newVal, oldVal) {
            if (newVal !== null) {
                $timeout(function() {
                    search(newVal);
                }, 0);
            }
        }

        /**
         * Callback function invoked when pagination for the lists is modified.
         */
        function onCurrentPageChange(newVal, oldVal) {
            if (newVal !== oldVal) {
                vm.lists.currentPage = newVal;
                getLists(newVal);
            }
        }

        function onGetListsSuccess(lists) {
            vm.lists = lists;
        }

        function onGetListsError(error) {
            toastr.error('Our apologies, we have been unable to retrieve your contents lists. Please, try again later.');
        }

        function onSearchTermChange(newValue, oldValue) {
            if (newValue !== oldValue) {
                getLists(1);
            }
        }

        function getLists(page) {
            var params = {
                page: page,
                items_per_page: 10,
                processingFile: false,
                enabled: true
            };

            if (vm.searchTerm) {
                params.searchTerm = vm.searchTerm;
            }

            vm.currentPage = page;
            return ContentsList.get(params)
                .$promise
                .then(onGetListsSuccess, onGetListsError);
        }

        function onMediaSelectionChange(media) {
            // do nothing if this item was already selected before opening the modal
            if (vm.alreadySelected.media.indexOf(media.id) >= 0) {
                return;
            }

            var idx = vm.selectedMediaIds.indexOf(media.id);
            if (idx >= 0) {
                // we are assuming that, since items and IDs are inserted at the same time,
                // they will be at the same position within the array
                vm.selectedMediaIds.splice(idx, 1);
                vm.selectedMedia.splice(idx, 1);
            } else {
                vm.selectedMediaIds.push(media.id);
                vm.selectedMedia.push(media);
            }
        }

        function onAppliesToAllCountriesChange(newVal, oldVal) {
            if (newVal) {
                vm.applicableCountries = [];
            }
        }

        /**
         * Detects if user is admin.
         */
        function getSessionInfo() {
            tvlSession.getSession().then(onGetSessionSuccess);
            function onGetSessionSuccess(data) {
                vm.isAdmin = data.isAdmin();
            }
        }

        function onDestroy() {
            for (var i in vm.unregisterFns) {
                vm.unregisterFns[i]();
            }
        }
    }
})();
