(function() {
    'use strict';

    angular
        .module('tvl.common')
        .directive('imageSizes', imageSizes);

    imageSizes.$inject = ['$q'];

    /* @ngInject */
    function imageSizes($q) {
        return {
            restrict: 'A',
            require: 'ngModel',
            replace: false,
            link: function(scope, elm, attrs, ctrl) {
                ctrl.$asyncValidators.imageSizes = function(modelValue, viewValue) {
                    var deferred = $q.defer();

                    check(modelValue);

                    /**
                     * @param modelValue
                     *
                     * @return {boolean|void}
                     */
                    function check(modelValue) {
                        if (null === modelValue || 'undefined' === typeof modelValue) {
                            deferred.resolve();
                            return;
                        }

                        return isValidSize(modelValue);
                    }

                    /**
                     * @param modelValue
                     */
                    function isValidSize(modelValue) {
                        var reader = new FileReader();
                        reader.onload = function(e)
                        {
                            var img = new Image();
                            img.onload = function() {
                                var imageSizes = JSON.parse(attrs.imageSizes);
                                var imageWidth = this.width;
                                var imageHeight = this.height;

                                var matchedWidths = _.filter(
                                    imageSizes,
                                    function(o) {
                                        return parseInt(imageWidth) === parseInt(o.width);
                                    }
                                );

                                var matchedHeight = _.filter(
                                    matchedWidths,
                                    function(o) {
                                        return parseInt(imageHeight) === parseInt(o.height);
                                    }
                                );

                                if (matchedHeight.length > 0) {
                                    deferred.resolve();
                                } else {
                                    deferred.reject();
                                }
                            };
                            img.src = reader.result;
                        };
                        reader.readAsDataURL(modelValue);
                    }

                    return deferred.promise;
                };


            }
        };
    }
})();
