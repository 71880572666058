(function() {
    'use strict';

    angular
        .module('tvl.brandSafety')
        .directive('tvlChannelNamesSelector', tvlChannelNamesSelector);

    tvlChannelNamesSelector.$inject = ['tvlYoutube'];

    /* @ngInject */
    function tvlChannelNamesSelector(tvlYoutube) {
        var directive = {
            link: link,
            restrict: 'EA',
            templateUrl: 'brand-safety/directive/channel-names-selector.html',
            scope: {
                disabled: '=',
                items: '=',
                clearSelectionToggle: '=',
            }
        };

        return directive;

        function link(scope, element, attrs) {
            /*
             * While "items" is an array, we need a string to draw it in html input.
             * So we use "displayItems" as mask for "items" variable,
             * and when users update "displayItems", we propagate changes to "items" array.
             */
            scope.displayItems = (scope.items && scope.items.length > 0) ? scope.items.join("\n") : '';
            scope.invalidItems = null;

            activate();

            function activate() {
                scope.itemsWatcher = scope.$watch('displayItems', onInputChange);
                scope.clearWatcher = scope.$watch('clearSelectionToggle', onClearSelectionToggleChange);
                scope.$on('$destroy', onDestroy);
            }

            /**
             * As "items" variable depends on "displayItems", we use displayItems to clear items.
             * @param newValue
             * @param oldValue
             */
            function onClearSelectionToggleChange(newValue, oldValue) {
                if (newValue === oldValue) {
                    return;
                }

                /*
                 * As "items" variable depends on "displayItems", we update "displayItems" to clear "items",
                 * and switch the toggle off again.
                 */
                scope.displayItems = '';
                scope.clearSelectionToggle = false;
            }

            function onInputChange(newValue, oldValue) {
                if (newValue === oldValue) {
                    return;
                }

                // Remove whitespaces and split string into array, then empty elements...
                var items = newValue.replace('\r', '').split('\n').map(function (e) {
                    return e.split(',').map(function (i) {
                        return i.trim();
                    });
                });
                scope.items = items.flat();
                scope.items = scope.items.filter(function (value) {
                    return (value && value != '');
                });
            }

            function onDestroy() {
                scope.clearWatcher();
                scope.itemsWatcher();
            }
        }
    }
})();
