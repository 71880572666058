const moment = require('moment');

(function () {
    'use strict';

    angular
        .module('tvl.campaign', [
            'ngAnimate',
            'ngSanitize',
            'ngMessages',
            'ngFileUpload',
            'ngTable',
            'ui.router',
            'ui.bootstrap',
            'ui.select',
            'chart.js',
            'angularMoment',
            'toastr',
            'NgSwitchery',
            'oitozero.ngSweetAlert',
            'angular-loading-bar',
            'checklist-model',
            'xeditable',
            'jtt_youtube',
            'rzModule',
            'fcsa-number',
            'datePicker',
            'daterangepicker',
            'angular-jqcloud',
            'RecursionHelper',
            'dndLists',
            'datatables',
            'datatables.fixedcolumns',
            'datatables.bootstrap',
            'tvl.common'
        ])
        .constant('moment', moment)
        .config(configure)
        .run(run);

    configure.$inject = [
        '$locationProvider',
        '$urlRouterProvider',
        '$stateProvider',
        '$httpProvider',
        'cfpLoadingBarProvider'
    ];

    /* @ngInject */
    function configure(
        $locationProvider,
        $urlRouterProvider,
        $stateProvider,
        $httpProvider,
        cfpLoadingBarProvider
    ) {
        $locationProvider.html5Mode(true);
        cfpLoadingBarProvider.includeSpinner = false;
        $stateProvider.state({
            name: 'campaign',
            abstract: true,
            templateUrl: 'base.html',
            resolve: {
                session: function (tvlSession) {
                    return tvlSession.getSession().$promise;
                }
            }
        }).state({
            name: 'campaign.list',
            url: '/campaigns/list?page&tab&search',
            controller: 'CampaignListController as vm',
            templateUrl: 'campaign/list.html',
            reloadOnSearch: false,
            resolve: {
                /* @ngInject */
                countries: function (Constants) {
                    return Constants.getCountries().$promise;
                },
                sessionInformation: function (tvlSession) {
                    return tvlSession.getSession();
                }
            }
        }).state({
            name: 'campaign.wizard',
            url: '/campaigns/create',
            controller: 'CampaignWizardController as parent',
            templateUrl: 'campaign/wizard/wizard.html'
        }).state({
            name: 'campaign.wizard.ads',
            url: '/ads',
            controller: 'WizardAdsController as vm',
            templateUrl: 'campaign/wizard/ads.html'
        }).state({
            name: 'campaign.wizard.information',
            url: '/information',
            controller: 'WizardInformationController as vm',
            templateUrl: 'campaign/wizard/information.html'
        }).state({
            name: 'campaign.wizard.media-distribution',
            url: '/media-distribution',
            controller: 'WizardMediaDistributionController as vm',
            templateUrl: 'campaign/wizard/media-distribution.html'
        }).state({
            name: 'campaign.wizard.network-distribution',
            url: '/network-distribution',
            controller: 'WizardNetworkDistributionController as vm',
            templateUrl: 'campaign/wizard/network-distribution.html'
        }).state({
            name: 'campaign.wizard.review',
            url: '/review',
            controller: 'WizardReviewController as vm',
            templateUrl: 'campaign/wizard/review.html'
        }).state({
            name: 'campaign.wizard.schedule',
            url: '/schedule',
            controller: 'WizardScheduleController as vm',
            templateUrl: 'campaign/wizard/schedule.html'
        }).state({
            name: 'campaign.wizard.targeting',
            url: '/targeting',
            controller: 'WizardTargetingController as vm',
            templateUrl: 'campaign/wizard/targeting.html'
        }).state({
            name: 'campaign.wizard.events',
            url: '/events',
            controller: 'WizardEventsController as vm',
            templateUrl: 'campaign/wizard/events.html'
        }).state({
            name: 'campaign.wizard.user-access',
            url: '/user-access',
            controller: 'WizardUserAccessController as vm',
            templateUrl: 'campaign/wizard/user-access.html'
        }).state({
            name: 'campaign.show',
            url: '/campaigns/show/{id:[0-9a-f]{24}}?tab',
            controller: 'CampaignShowController as vm',
            templateUrl: 'campaign/show/show.html',
            reloadOnSearch: false,
            resolve: {
                /* @ngInject */
                countries: function (Constants) {
                    return Constants.getCountries().$promise;
                },
                campaign: getCampaign,
                loggedUser: function (tvlSession) {
                    return tvlSession.getSession();
                }
            }
        }).state({
            name: 'campaign.report',
            url: '/campaigns/report/{id:[0-9a-f]{24}}',
            controller: 'CampaignReportController as vm',
            templateUrl: 'campaign/report.html',
            resolve: {
                /* @ngInject */
                campaign: getCampaign
            }
        }).state({
            name: 'campaign.benchmark-report',
            url: '/campaigns/benchmark-report?company[]&name&account[]&startDate&endDate&location[]&subnetwork[]&adFormat[]&minAge&maxAge&gender[]&language[]&device[]',
            controller: 'CampaignBenchmarkReportController as vm',
            templateUrl: 'campaign/benchmarks/report.html',
            reloadOnSearch : false
        }).state({
            name: 'campaign.financial-report',
            url: '/campaigns/financial-report?company[]&name&account[]&startDate&endDate&displayCurrency&location[]&subnetwork[]&adFormat[]&businessModel[]&status[]',
            controller: 'CampaignFinancialReportController as vm',
            templateUrl: 'campaign/financial/report.html',
            reloadOnSearch : false
        }).state({
            name: 'campaign.accounts-reports',
            url: '/campaigns/network-accounts/campaigns',
            controller: 'NetworkAccountCampaignsDashboard as vm',
            templateUrl: 'campaign/network-accounts-campaigns-dashboard/network-accounts-campaigns-dashboard.html',
            resolve: {
                /* @ngInject */
                forImport: function(){
                    return false;
                }
            }
        }).state({
            name: 'campaign.import',
            url: '/campaigns/import',
            controller: 'NetworkAccountCampaignsDashboard as vm',
            templateUrl: 'campaign/network-accounts-campaigns-dashboard/network-accounts-campaigns-dashboard.html',
            resolve: {
                /* @ngInject */
                forImport: function(){
                    return true;
                }
            }
        });
    }

    run.$inject = ['$rootScope'];

    /* @ngInject */
    function run($rootScope) {
        $rootScope.bodyClass = '';
    }

    function getCampaign($state, $stateParams, toastr, Campaign) {
        return Campaign.get({id: $stateParams.id}).$promise
            .catch(function (res) {
                if (res.status === 404) {
                    toastr.error('The requested campaign does not exist.');
                }
                $state.go('campaign.list');
            });
    }
})();
