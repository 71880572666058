const moment = require('moment');

(function () {
    'use strict';

    angular
        .module('tvl.common')
        .directive('tvlErrorLog', tvlErrorLog);

    /* @ngInject */
    function tvlErrorLog() {
        var directive = {
            bindToController: true,
            controller: TvlErrorLogController,
            controllerAs: 'vm',
            restrict: 'E',
            scope: {
                changelog: '=',
                item: '=',
                resource: '=',
                resourceId: '=',
                resourceName: '=',
                selectUsers: '=',
                selectCampaigns: '=',
            },
            templateUrl: 'common/error-log.html'
        };
        return directive;
    }

    TvlErrorLogController.$inject = ['$filter', '$scope', 'NgTableParams', 'Campaign', 'ErrorLog', 'Account', 'Company', 'User'];

    /* @ngInject */
    function TvlErrorLogController($filter, $scope, NgTableParams, Campaign, ErrorLog, Account, Company, User) {
        var vm = this;
        var errorMessagesTranslations = {
            'campaign_creation': 'Campaign creation has failed. Support team are going to verify this issue and you will be notified when it is fixed or if it requires an action from your side. Please consider that it would take some hours, if you cannot wait for an answer create the campaign on the network directly.',
            'daily_budget': 'Last Daily Budget setup (__VALUE__) could not be saved in network. Please verify it on network, if the current value is correct, you can mark this issue as solved; on the contray change the value again vía Theviralab. If error persists, contact support@theviralab.com',
            'status': 'We had problems setting up new campaign status (__VALUE__). Please verify it on network, if the current value is correct, you can mark this issue as solved. On the contrary, please align campaign status in network with TVL status (you need to make changes in network - only for this case). After this, status inconsistency error would be fixed and you will be able to made more changes in TVL if it is necessary. If error persists, contact support@theviralab.com',
            'bidding_amount': 'Last Bidding setup (__VALUE__) could not be saved in network. Please verify it manually in network, if the current value is correct, you can mark this issue as solved. In case the value is incorrect, please try again changing the value vía Theviralab. If error persists, please contact support@theviralab.com',
            'start_date': 'New start date could not be saved in network. Please try again changing this value vía Theviralab and mark this issue as solved. If error persists, please contact support@theviralab.com',
            'end_date': 'New end date could not be saved in network. Please, verify that the new end day is not in past, if not try again changing this value vía TheViralab and mark this issue as solved. If error persists, please contact support@theviralab.com',
            'ad_schedule': 'New schedule could not be saved in network. Please try again changing this value vía Theviralab and mark this issue as solved. If error persists, please contact support@theviralab.com',
            'devices': 'New devices setup could not be saved in network. Please try again changing this value vía Theviralab and mark this issue as solved. If error persists, please contact support@theviralab.com',
            'locations': 'New locations setup could not be saved in network. Please try again changing this value vía Theviralab and mark this issue as solved. If error persists, please contact support@theviralab.com',
            'gender': 'New gender setup could not be saved in network. Please try again changing this value vía Theviralab and mark this issue as solved. If error persists, please contact support@theviralab.com',
            'languages': 'New languages setup could not be saved in network. Please try again changing this value vía Theviralab and mark this issue as solved. If error persists, please contact support@theviralab.com',
            'interests': 'There was a problem setting the new interests in network. Since at this moment, it is not possible to make a validation from TVL to know what interest were correctly setup, please check campaign setup in network and set them manually and then, mark this issue as solved. Interest: (__VALUE__)',
            'ad_display': 'Ad update has failed. Please try again, if error persists, please contact support@theviralab.com',
            'bidding_strategy_type': 'Bidding strategy type cannot be applied. Please try again changing this value vía Theviralab and mark this issue as solved. If error persists, please contact support@theviralab.com',
            'age': 'New ages setup could not be saved in network. Please try again changing this value vía Theviralab and mark this issue as solved. If error persists, please contact support@theviralab.com',
            'keywords': 'It was not possible to save keywords setup. Please set them manually in network and then, mark this issue as solved.',
            'content_exclusions': 'It was not possible to save content exclusions setup. Please set them manually in network and then, mark this issue as solved.',
            'topics': 'It was not possible to save topics setup. Please set them manually in network and then, mark this issue as solved.',
            'frequency_cap': 'New frequency_cap setup could not be saved in network. Please try again changing this value vía Theviralab and mark this issue as solved. If error persists, please contact support@theviralab.com',
            'dates': 'New end date could not be saved in network. Please try again changing this value vía Theviralab and mark this issue as solved. If error persists, please contact support@theviralab.com',
            'multi_adgroup_campaign_status': 'New status (__VALUE__) could not be applied in network. Please verify it manually in network, if the current value is correct, you can mark this issue as solved. In case the value is incorrect, please try again changing the value vía Theviralab. If error persists, please contact support@theviralab.com',
            'delivery_method': 'It was not possible to save delivery method change. Please set it manually in network and then, mark this issue as solved.',
            'ad_video': 'Ad update has failed. Please try again, if error persists, please contact support@theviralab.com',
            'min_age': 'New minimum age setup could not be saved in network. Please try again changing this value vía Theviralab and mark this issue as solved. If error persists, please contact support@theviralab.com',
            'max_age': 'New maximum age setup could not be saved in network. Please try again changing this value vía Theviralab and mark this issue as solved. If error persists, please contact support@theviralab.com',
            'time_slots': 'New time slots setup could not be saved in network. Please try again changing this value vía Theviralab and mark this issue as solved. If error persists, please contact support@theviralab.com',
            'inventory_type': 'New inventory setup could not be saved in network. Please try again changing this value vía Theviralab and mark this issue as solved. If error persists, please contact support@theviralab.com',
            'max_cpv': 'It was not possible to set bidding (max cpv) campaign in Google Ads. Please set it manually in network and then, mark this issue as solved.',
            'max_cpm': 'It was not possible to set bidding (max cpm) campaign in Google Ads Please set it manually in network and then, mark this issue as solved.',
            'target_cpm': 'It was not possible to set the new bidding (Target CPM) calculated by platform in network. Please set it in network and then, mark this issue as solved.',
            'target_cpa': 'It was not possible to set the Target CPA in network. Please set it manually in network and then, mark this issue as solved.',
            'contents': 'The new content settings could not be successfully saved to the network.  Please, verify this setup in network directly.',
            'general_targeting': 'There was an error setting up the targeting for this Ad, please contact support.',
        };

        activate();

        function activate() {
            initTable();
        }

        function initTable() {
            vm.tableParams = new NgTableParams(
                {
                    count: 25,
                    sorting: {loggedAt: 'desc'},
                    filter: {campaign: vm.nameSelectCampaign, username: vm.nameSelectUser},
                },
                {
                    counts: [25, 50, 75, 100],
                    getData: getDataTableData
                }
            );

            vm.tableParams.reload();
        }

        function getDataTableData(params) {
            if (params.filter().campaign == 'All Items') {
                vm.searchCampaign = undefined;
            } else {
                vm.searchCampaign = params.filter().campaign;
            }

            if (params.filter().username == 'All Users') {
                vm.searchUser = undefined;
            } else {
                vm.searchUser = params.filter().username;
            }

            vm.changelogParams = {
                id: vm.resourceId,
                page: params.page(),
                items_per_page: params.count()
            };

            return getErrorLogs().then(function (data) {
                if(data && data.items.length > 0) getChangelogs(vm.changelog.totalItems, data);
                params.total(data.totalItems);
                vm.changelog.totalItems = data.totalItems;
                return data;
            }).then(onResourceGetSuccess);
        }

        function getErrorLogs(){
            vm.changelogsPromise = vm.resource.getErrorLog(vm.changelogParams).$promise;
            return vm.changelogsPromise;
        }

        function getChangelogs(totalItems, dataErrorLogs){
            vm.paramsChangelogs = {
                id: vm.resourceId,
                items_per_page: totalItems,
            }

            vm.resource.getChangelog(vm.paramsChangelogs).$promise.then(function(data) {
                return getErrorLogStatusTranslation(data, dataErrorLogs);
            });
        }

        function getErrorLogStatusTranslation(data, dataErrorLogs) {
            vm.statusTranslations = [];
            if(dataErrorLogs.items.length > 0) {
                dataErrorLogs.items.forEach(function(error){
                    if(error.operation === 'status' && error.operationValue !== 'enabled') {
                        const item = data.items.find(obj => (new Date(obj.loggedAt).getTime() < new Date(error.date).getTime()) && (obj.data.status || obj.data.systemStatus) && (error.placementName === obj.objectName));
                        const containsId = vm.statusTranslations.some(obj => obj.id === error.id);

                        if (!containsId) {
                            vm.statusTranslations.push({
                                id: error.id,
                                status: item.data.status ? item.data.status[0] : item.data.systemStatus,
                                date: error.date,
                            })
                        }
                    }
                });
            }

        };

        $scope.updateErrorLogStatus = function(errorLog) {
            vm.updateErrorLogStatusParams = {
                id: errorLog.id,
                is_solved: errorLog.isSolved,
            };

            return updateErrorLogStatusPromise().then(function (data) {
                return data;
            }).then(onResourceGetSuccess);
        }

        function updateErrorLogStatusPromise() {
            return ErrorLog.updateStatus(vm.updateErrorLogStatusParams).$promise;
        }

        function onResourceGetSuccess(changelogs) {
            vm.dataset = changelogs.items;

            return vm.dataset;
        }

        $scope.errorOperationToMessage = function(operationType, operationSource, operation, operationValue, errorLogId) {
            if (operationType === 'campaign_creation') {
                return errorMessagesTranslations['campaign_creation'];
            }

            let message = errorMessagesTranslations[operation];

            if (operationValue === null) {
                message = message.replace('(__VALUE__)', '');
            } else {
                if (operation === 'status') {
                    switch (operationValue){
                        case 'enabled':
                            operationValue = 'active';
                            break;
                        case 'paused':
                            if(vm.statusTranslations && vm.statusTranslations.length > 0){
                                const statusTranslation = vm.statusTranslations.find(obj => obj.id === errorLogId);
                                operationValue = statusTranslation.status;
                            }
                            break;
                    }
                }

                message = message.replace('__VALUE__', operationValue);
            }

            return message;
        }
    }
})();
