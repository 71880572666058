(function() {
    angular
        .module('tvl.campaign')
        .service('operatingSystemService', operatingSystemService);

    operatingSystemService.$inject = [];

    function operatingSystemService() {
        return {
            availableOperatingSystems: {
                all: {
                    name: 'All',
                    value: null,
                    icon: null,
                },
                android: {
                    name: 'Android',
                    value: 'Android',
                    icon: 'android'
                },
                ios: {
                    name: 'iOS',
                    value: 'iOS',
                    icon: 'apple'
                }
            },
            getAvailableOperatingSystems: getAvailableOperatingSystems,
            findOperatingSystemByValue: findOperatingSystemByValue
        };

        function getAvailableOperatingSystems() {
            return this.availableOperatingSystems;
        }

        function findOperatingSystemByValue(value) {
            var found = null;

            for (var key in this.availableOperatingSystems) {
                var item = this.availableOperatingSystems[key];

                if (value === item.value) {
                    found = item;
                }
            }

            return found;
        }
    }
})();
