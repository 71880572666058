(function() {
    'use strict';

    angular
        .module('tvl.common')
        .constant('navHeaderConstants', {
            defaultLogo: '/static/img/logo.png'
        })
        .directive('navHeader', function() {
            return {
                templateUrl: 'common/nav-header.html',
                restrict: 'E',
                controller: function(
                    $scope,
                    $window,
                    tvlSession,
                    navHeaderConstants,
                    Notification,
                    resourceResponse,
                    Logout,
                    jwt
                ) {
                    $scope.loggedUser = null;
                    $scope.platformLogo = null;
                    $scope.unreadNotifications = null;
                    $scope.headwayCanBeInitialized = false;
                    $scope.logout = logout;
                    $scope.counter = null;

                    tvlSession.getSession().then(function(user) {
                        $scope.loggedUser = user;
                        $scope.platformLogo = getPlatformLogo();

                        $scope.headwayCanBeInitialized = null !== $scope.loggedUser &&
                            $scope.loggedUser.hasPermission('Menu.view_news');

                        if ($scope.headwayCanBeInitialized) {
                            initHeadway();
                        }

                        Notification.get({read: false})
                            .$promise
                            .then(function (response) {
                                $scope.unreadNotifications = response.totalItems;
                            }, resourceResponse.error);

                        //TODO change those lines to use angular events! (ng-click)
                        $scope.counter = $("#tvl-notification-counter");
                        var action = $("#tvl-notification-action");
                        var wrapper = $("#tvl-notification-widget-wrapper");
                        window.addEventListener("message", messageHandler, false);

                        action.click(function() {
                            toggleWidget(wrapper);
                        });

                        subscribe();
                    });

                    function getPlatformLogo() {
                        return _.get(
                            $scope.loggedUser,
                            'company.customization.logos.platform.positive',
                            navHeaderConstants.defaultLogo
                        );
                    }

                    function initHeadway() {
                        if (process.env.APP_ENV === 'prod') {
                            //Headway is not working as documentation says, we need a timeout to init it.
                            setTimeout(function () {
                                Headway.init();
                            }, 500);
                        }
                    }

                    function logout() {
                        Logout.create({}).$promise
                            .then(function(response) {
                                jwt.clearSession();
                                $window.location.href = '/login';
                            }, resourceResponse.error);
                    }

                    /**
                     * toggleWidget
                     * @param  {HTMLElement} wrapper
                     * @param  {String} url
                     */
                    function toggleWidget(wrapper) {
                        if (wrapper.is(":visible")) {
                            wrapper.stop().fadeOut();
                        } else {
                            wrapper.stop().fadeIn();
                        }

                    }

                    /**
                     * This function subscribes to Pusher channel and adds a handler for notification
                     * event
                     */
                    function subscribe() {
                    }

                    /**
                     * Increase notification counter
                     */
                    function incCounter() {
                        var result = parseInt($scope.counter.text()) + 1;
                        if (!isNaN(result)) {
                            $scope.counter.text(result.toString());
                        }
                    }

                    /**
                     * Decrease notification counter
                     */
                    function decCounter() {
                        var result = parseInt($scope.counter.text()) - 1;
                        if (!isNaN(result) && result >= 0) {
                            $scope.counter.text(result.toString());
                        }
                    }

                    /**
                     * Notification widget messageHandler
                     * @param {MessageEvent} message
                     */
                    function messageHandler(message) {
                        if (message.data === 'decCounter') {
                            decCounter();
                        }
                    }
                }
            };
        });

})();
