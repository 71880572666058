/**
 * Uuid generator. RFC4122 compliant (almost)
 */

(function() {
    'use strict';

    angular
        .module('tvl.common')
        .factory('tvlUuid', tvlUuid);

    function tvlUuid() {
        return {
            new: function() {
                function generatePart(separator) {
                    var part = (Math.random().toString(16)+"000000000").substr(2,8);
                    return separator ? "-" + part.substr(0,4) + "-" + part.substr(4,4) : part ;
                }
                return generatePart() + generatePart(true) + generatePart(true) + generatePart();
            },
            
            empty: function() {
              return '00000000-0000-0000-0000-000000000000';
            }
        };
    }

})();
