(function() {
    'use strict';

    angular
        .module('tvl.administration')
        .controller('AuditAccountModalController', AuditAccountModalController);

    AuditAccountModalController.$inject = ['$scope', '$uibModalInstance', 'Account', 'account'];

    /** @ngInject */
    function AuditAccountModalController($scope, $uibModalInstance, Account, account) {
        var vm = this;

        vm.resource = Account;
        vm.resourceName = 'Account';
        vm.data = null;
        vm.currentPage = 1;

        vm.unregisterCurrentPageWatcher = $scope.$watch(
            'modal.currentPage',
            getAccountChangelog
        );

        $scope.$on('$destroy', onDestroy);

        function onDestroy() {
            vm.unregisterCurrentPageWatcher();
        }

        vm.account = account;
        vm.ui = {
            loadingChangelog: true,
        };
        vm.cancel = cancel;

        getAccountChangelog(vm.currentPage);

        function cancel() {
            return $uibModalInstance.dismiss();
        }

        function getAccountChangelog(page) {

            vm.ui.loadingChangelog = true;

            var params = {
                id: vm.account.id,
                page: page ? page : vm.currentPage
            };

            return Account.getChangelog(params)
                .$promise
                .then(onGetAccountChangelogSuccess)
                .finally(onGetAccountChangelogFinally);

            function onGetAccountChangelogSuccess(changelog) {
                vm.data = changelog;
            }

            function onGetAccountChangelogFinally() {
                vm.ui.loadingChangelog = false;
            }
        }
    }

}());
