(function() {
    'use strict';

    angular
        .module('tvl.brandSafety')
        .controller('BrandSafetyKeywordsListsController', BrandSafetyKeywordsListsController);

    BrandSafetyKeywordsListsController.$inject = [
        'tvlRemoveListModal',
        'tvlCreateListModal',
        '$scope',
        '$uibModal',
        '$stateParams',
        '$state',
        '$window',
        'toastr',
        'KeywordsList',
        'MAX_KEYWORD_LIST_SIZE',
        'lists'
    ];

    /* @ngInject */
    function BrandSafetyKeywordsListsController(
        tvlRemoveListModal,
        tvlCreateListModal,
        $scope,
        $uibModal,
        $stateParams,
        $state,
        $window,
        toastr,
        KeywordsList,
        MAX_KEYWORD_LIST_SIZE,
        lists
    ) {
        var vm = this;
        vm.lists = lists;
        vm.currentPage = $stateParams.page || 1;
        vm.selectedList = null;
        vm.editForm = {};
        vm.ui = {gettingDetails: false, saving: false};
        vm.MAX_KEYWORD_LIST_SIZE = MAX_KEYWORD_LIST_SIZE;
        vm.searchTerm = null;

        activate();

        vm.clearSelection = clearSelection;
        vm.removeList = removeList;
        vm.saveList = saveList;
        vm.selectList = selectList;
        vm.createList = createList;
        vm.searchLists = searchList;

        ////////////////

        function activate() {
            $scope.unregisterFns = [
                $scope.$watch('vm.lists.currentPage', onCurrentPageChange),
            ];
            $scope.$on('$destroy', onDestroy);
            updateQueryParams();
        }

        /**
         * Populate query params with the current page on the list .
         */
        function updateQueryParams() {
            $state.transitionTo(
                'brand-safety.keywords-lists',
                {
                    page: vm.currentPage
                },
                {notify: false}
            );
        }

        function clearSelection() {
            vm.selectedList = null;
            vm.editForm = {};
        }

        function getList(id) {
            return KeywordsList.get({id: id}).$promise;
        }

        function onGetListsSuccess(lists) {
            vm.lists = lists;
        }

        function onGetListsError(error) {
            toastr.error('Our apologies, we have been unable to retrieve your keywords lists. Please, try again later.');
        }

        function getLists(page, search) {
            var params = {
                page: page,
                searchTerm: search || null
            };
            vm.currentPage = page;
            updateQueryParams();
            return KeywordsList.get(params)
                .$promise
                .then(onGetListsSuccess, onGetListsError);
        }

        function onGetListSuccess(list) {
            $window.scrollTo(0, 0);
            vm.selectedList = list;
            vm.detailsFormTitle = 'Edit ' + list.name;
            vm.editForm = {
                id: list.id,
                name: list.name,
                items: list.items,
            };
            return list;
        }

        function onGetListFinally() {
            vm.ui.gettingDetails = false;
        }

        function selectList(list) {
            if (vm.selectedList && vm.selectedList.id === list.id) {
                return;
            }

            vm.ui.gettingDetails = true;
            return getList(list.id)
                .then(onGetListSuccess)
                .finally(onGetListFinally);
        }

        function saveList() {
            vm.ui.saving = true;
            var list = new KeywordsList(vm.editForm);
            return list.$save()
                .then(onSaveSuccess, onSaveError)
                .then(onSaveFinally);

            function onSaveSuccess(list) {
                toastr.success('List ' + list.name + ' has been successfully saved.');
                clearSelection();
                getLists(vm.lists.currentPage);
            }

            function onSaveError(error) {
                toastr.error('Our apologies, we have been unable to save this list. Please, try again later.');
            }

            function onSaveFinally() {
                vm.ui.saving = false;
            }
        }


        function showListCreationModal() {
            return tvlCreateListModal.openCreationModal(KeywordsList, 'keywords list');
        }

        /**
         * Show the list creation modal and redirect the user to the lists view
         * upon success.
         */
        function createList() {
            return showListCreationModal().then(onSaveListSuccess);
        }

        function onSaveListSuccess(list) {
            toastr.success('List ' + list.name + ' has been successfully created.');
            onGetListSuccess(list);
            getLists();
        }

        function showRemovalConfirmationModal(list) {
            return tvlRemoveListModal.openRemoveModal(list, 'keywords list')
        }

        function deleteList(list) {
            return KeywordsList.delete({id: list.id}).$promise;
        }

        function removeList() {
            var list = vm.editForm;

            return showRemovalConfirmationModal(list)
                .then(
                    function() {
                        deleteList(list)
                            .then(onDeleteSuccess, onDeleteError)
                            .finally(getLists.bind(null, vm.currentPage))
                    },
                    cancelRemove
                );

            function onDeleteSuccess() {
                clearSelection();
                toastr.success('List ' + list.name + ' has been successfully removed.');
            }

            function onDeleteError(error) {
                if (error.status === 403) {
                    toastr.error('You are not authorized to remove this list. This may happen if this list has been used in at least one campaign.');
                } else {
                    toastr.error('Our apologies, we have been unable to remove this list. Please, try again later.');
                }
            }

            function cancelRemove() {

            }
        }

        function onCurrentPageChange(newVal, oldVal) {
            if (newVal !== oldVal) {
                vm.currentPage = newVal;
                getLists(newVal, vm.searchTerm);
            }
        }

        function searchList(search) {
            return getLists(1, search);
        }

        /**
         * Callback function invoked before this controller is destroyed.
         */
        function onDestroy() {
            angular.forEach($scope.unregisterFns, function (fn) {fn();});
        }
    }
}());
