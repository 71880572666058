const moment = require('moment');

(function() {
    'use strict';

    angular
        .module('tvl.campaign')
        .directive('tvlRuleActivations', tvlRuleActivations);

    tvlRuleActivations.$inject = ['$log', '$q', '$timeout', 'toastr', 'tvlUi', 'Rule'];

    /**
     * A directive that displays in a chart the number of hours that rules have
     * been active for a given location.
     *
     * @ngInject
     */
    function tvlRuleActivations($log, $q, $timeout, toastr, tvlUi, Rule) {
        var directive = {
            link: link,
            restrict: 'E',
            templateUrl: 'campaign/rule-activations.html',
            replace: true,
            scope: {
                rules: '=',
                entity: '=',
            }
        };
        return directive;

        function link(scope, element, attrs) {
            // if available locations for a given rule is less or equal than
            // this, they will be automatically selected
            scope.MAX_LOCATIONS_FOR_AUTO_INCLUDE = 5;
            scope.selectedRule = null;
            scope.locations = [];
            scope.selectedLocations = [];
            scope.chartOptions = null;
            scope.chartColors = null;
            scope.chartSeries = [];
            scope.sourceData = {};
            scope.fetchingData = false;

            scope.onSelectedLocationsChange = onSelectedLocationsChange;
            scope.onSelectedRuleChange = onSelectedRuleChange;

            activate();

            function activate() {
                initializeChartOptions();
                initializeChartColors();

                if (scope.rules && scope.rules.length > 0) {
                    scope.selectedRule = scope.rules[0];
                    onSelectedRuleChange();
                } else {
                    $log.warn('tvlRuleActivations directive received no rules');
                }
            }

            function initializeChartOptions() {
                scope.chartOptions = {
                    maintainAspectRatio: false,
                    responsive: true,
                    scales: {
                        xAxes: [{
                            ticks: {
                                callback: function (value, index, values) {
                                    return moment(value).format('MMM DD, YYYY');
                                }
                            }
                        }],
                        yAxes: [{
                            ticks: {
                                min: 0,
                                max: 24,
                                stepSize: 6
                            },
                            scaleLabel: {
                                display: true,
                                labelString: 'Hours of activation'
                            }
                        }]
                    },
                    legend: {
                        display: true,
                        position: 'bottom'
                    }
                };
            }

            function initializeChartColors() {
                scope.chartColors = tvlUi.getThemeColors();
            }

            function getRuleLocations(rule) {
                function onGetLocationsSuccess(locations) {
                    scope.locations = locations;

                    if (locations.length <= scope.MAX_LOCATIONS_FOR_AUTO_INCLUDE) {
                        scope.selectedLocations = locations;
                    } else {
                        scope.selectedLocations = [];
                    }
                    onSelectedLocationsChange();
                }

                function onGetLocationsError(error) {
                    showErrorToast();
                }

                function onGetLocationsFinally() {
                    scope.fetchingData = false;
                }

                scope.fetchingData = true;
                return Rule.getLocations({id: rule.id})
                    .$promise
                    .then(onGetLocationsSuccess, onGetLocationsError)
                    .finally(onGetLocationsFinally);
            }

            function getRuleStats(rule, locations) {
                function onGetStatsSuccess(stats) {
                    // this hack removes angular-specific attributes such as
                    // `$promise` or `$resolved`, which would produce empty
                    // dates in our chart
                    scope.sourceData = JSON.parse(angular.toJson(stats));
                    return stats;
                }

                function onGetStatsError(error) {
                    showErrorToast();
                }

                function onGetStatsFinally() {
                    scope.fetchingData = false;
                }

                scope.fetchingData = true;
                var promises = {};
                _.forEach(locations, function (location) {
                    promises[location.id] = Rule.getStats({id: rule.id, location: location.id}).$promise;
                });

                return $q.all(promises).then(onGetStatsSuccess, onGetStatsError).finally(onGetStatsFinally);
            }

            function onSelectedLocationsChange() {
                /*
                 * Find which locations no longer exist, and remove them from
                 * the stored data.
                 */
                var currentLocations = Object.keys(scope.sourceData);
                var selectedLocations = _.map(scope.selectedLocations, 'id');

                var removedLocations = _.difference(currentLocations, selectedLocations);
                _.forEach(removedLocations, function (location) {
                    delete scope.sourceData[location];
                });
                var addedLocations = _.difference(selectedLocations, currentLocations);

                /*
                 * Now retrieve stats for those locations which have just been
                 * added.
                 */
                if (addedLocations.length) {
                    var promise = getRuleStats(scope.selectedRule, scope.selectedLocations)
                } else {
                    var deferred = $q.defer();
                    var promise = deferred.promise;
                    deferred.resolve(scope.sourceData);
                }

                /*
                 * And finally plot the data.
                 */
                promise.then(function () {
                    populateChart(scope.sourceData);
                });
            }

            function onSelectedRuleChange() {
                // ask for locations of this rule
                getRuleLocations(scope.selectedRule);
            }

            function getLocationNameFromKey(value, key) {
                return _.find(scope.locations, {id: key}).name;
            }

            function populateChart(sourceData) {
                /*
                 * Some sanity check, ensure that all series have the same
                 * number of data points.
                 */
                var uniqueSizes = _.chain(sourceData)
                    .map(function (value) {return value.length;})
                    .uniq()
                    .size()
                    .value();

                if (uniqueSizes > 1) {
                    throw 'Not all rule stats have the same data points, thus stats cannot be plotted';
                } else if (uniqueSizes === 0) {
                    scope.chartLabels = [];
                    scope.chartData = [];
                    scope.chartSeries = [];
                } else {
                    scope.chartLabels = Object.keys(Object.values(sourceData)[0]);
                    scope.chartData = _.map(sourceData, Object.values);
                    scope.chartSeries = _.map(sourceData, getLocationNameFromKey);
                }
            }

            function showErrorToast() {
                toastr.error('Our apologies, we have been unable to fetch stats for this campaign\´s rules. Please, try again later.');
            }
        }
    }
}());
