(function() {
    angular
        .module('tvl.common')
        .service('tvlUtils', tvlUtils);

    function tvlUtils() {
        return {
            round: round,
            roundToTwoDecimalPlaces: roundToTwoDecimalPlaces,
            getMinNumberWithSameFractionDigits: getMinNumberWithSameFractionDigits,
            roundNumber: roundNumber,
            countDecimals: countDecimals,
        };

        /**
         * Round a number to an arbitrary number of decimals.
         *
         * @param {Number} number
         * @param {Number} precision
         * @return {Number}
         */
        function round(number, precision) {
            var factor = Math.pow(10, precision);
            return Math.round(number * factor) / factor;
        }

        /**
         * Return given input rounded to two decimal places
         *
         * @return {number}
         */
        function roundToTwoDecimalPlaces(number) {
            return round(number, 2);
        }

        function getFractionDigits(num) {
            return (num.toString().split('.')[1] || []).length;
        }

        /**
         * Return minimum number with same fractions digits as given number
         *
         * @return {number}
         */
        function getMinNumberWithSameFractionDigits(number) {
            var fractionDigits = getFractionDigits(number);
            if (fractionDigits>0) {
                return 1/Math.pow(10, fractionDigits);
            }
            return 1;
        }

        /**
         * Round a number 'number' with precision 'scale'
         * @param num
         * @param scale
         * @returns {number}
         */
        function roundNumber(num, scale) {
            if(!("" + num).includes("e")) {
                return +(Math.round(num + "e+" + scale)  + "e-" + scale);
            } else {
                var arr = ("" + num).split("e");
                var sig = ""
                if(+arr[1] + scale > 0) {
                    sig = "+";
                }
                return +(Math.round(+arr[0] + "e" + sig + (+arr[1] + scale)) + "e-" + scale);
            }
        }

        /**
         * Count decimals of value
         * @param value
         * @returns {number}
         */
        function countDecimals(value) {
            if(Math.floor(value) === value) return 0;
            return value.toString().split(".")[1].length || 0;
        }
    }
})();
