(function() {
    'use strict';

    angular
        .module('tvl.common')
        .factory('jwt', jwt);

    jwt.$inject = [
        'localStorageService'
    ];

    function jwt(
        localStorageService
    ) {
        var token = null;

        return {
            saveTokenToSessionStorage: saveTokenToSessionStorage,
            getTokenFromSessionStorage: getTokenFromSessionStorage,
            clearSession: clearSession,
        };

        ////////////////

        function saveTokenToSessionStorage(jwt) {
            token = jwt;
        }

        function clearSession() {
            token = null;
            localStorageService.clearAll();
        }

        function getTokenFromSessionStorage() {
            return token;
        }
    }
})();
