(function() {
    'use strict';

    angular
        .module('tvl.campaign')
        .controller('CampaignFinancialReportController', CampaignFinancialReportController);

    CampaignFinancialReportController.$inject = [
        '$scope',
        '$state',
        '$stateParams',
        '$uibModal',
        '$filter',
        'toastr',
        'DTOptionsBuilder',
        'tvlCampaignUtils',
        'tvlAdVideoUtils',
        'tvlUi',
        'tvlCurrencies',
        'tvlSession',
        'Campaign',
        'Account',
        'Location',
        'resourceResponse',
        'reportDownloader',
        'Company',
        'tvlConstantUtils'
    ];

    /* @ngInject */
    function CampaignFinancialReportController(
        $scope,
        $state,
        $stateParams,
        $uibModal,
        $filter,
        toastr,
        DTOptionsBuilder,
        tvlCampaignUtils,
        tvlAdVideoUtils,
        tvlUi,
        tvlCurrencies,
        tvlSession,
        Campaign,
        Account,
        Location,
        resourceResponse,
        reportDownloader,
        Company,
        tvlConstantUtils
    ) {
        var vm = this;
        vm.tvlAdVideoUtils = tvlAdVideoUtils;
        vm.accountOptions = null;
        vm.companyOptions = null;
        vm.countryOptions = null;
        vm.campaignFinancialReports = null;
        vm.currencyOptions = _.values(tvlCurrencies.getCurrencies(['USD', 'EUR', 'COP', 'MXN', 'ARS', 'PEN', 'CLP', 'UYU']));
        vm.selectedCurrency = _.find(vm.currencyOptions, {'code': 'USD'});
        vm.selectedCurrencyCode = vm.selectedCurrency.code;
        vm.dateRange = {
            startDate: moment().subtract(3, 'months'),
            endDate: moment()
        };
        vm.maxRangePeriod = {
            time : 12,
            timeUnit : 'months'
        };
        vm.filters = {
            name: null,
            account: [],
            company: [],
            startDate: vm.dateRange.startDate,
            endDate: vm.dateRange.endDate,
            location: [],
            subnetwork: [],
            adFormat: [],
            businessModel: [],
            status: []
        };
        vm.datepickerOptions = {
            opens: 'right',
            locale: {
                format: "MMM DD, YYYY"
            },
            maxDate: vm.filters.endDate
        };
        vm.businessModels = tvlCampaignUtils.getCampaignBusinessModels();
        vm.subnetworks = tvlCampaignUtils.getAvailableSubNetworks();
        vm.statuses = tvlCampaignUtils.getNotCanceledStatuses();
        vm.ui = {
            gettingCampaignFinancialReports: false,
            isUserAccountant: false,
            isUserTrader: false,
            isUserSupport: false
        };
        vm.dtOptions = DTOptionsBuilder.newOptions()
            .withOption('scrollY', '400px')
            .withOption('scrollX', true)
            .withOption('scrollCollapse', true)
            .withOption('paging', false)
            .withOption('bFilter', false)
            .withOption('bInfo', false)
            .withFixedColumns({
                leftColumns: 3
            })
            .withBootstrap();

        vm.applyFilters = applyFilters;
        vm.getAccounts = getAccounts;
        vm.getCompanies = getCompanies;
        vm.getCountryFlag = getCountryFlag;
        vm.downloading = false;
        vm.reportDownload = reportDownload;
        vm.showCampaignPlacementFinancialReportsModal = showCampaignPlacementFinancialReportsModal;
        vm.sortTable = sortTable;
        vm.tvlConstantUtils = tvlConstantUtils;

        activate();

        ////////////////

        function activate() {
            checkFilters();

            tvlSession.getSession()
                .then(function (user) {
                    vm.ui.isUserAccountant = user.roles.indexOf('ROLE_FINANCE') >= 0;
                    vm.ui.isUserTrader = user.roles.indexOf('ROLE_TRADING_OPS') >= 0;
                });

            vm.unregisterDateRangeWatcher = $scope.$watch(
                'vm.dateRange',
                onDateRangeChange
            );
            getAccounts();
            getCompanies();
            getCountries();

            vm.isValidPeriodRangeForExcelreport = checkPeriodRangeForExcelReport(vm.dateRange);

            $scope.$on('$destroy', onDestroy);
        }

        function checkFilters() {
            var searchFinancialReports = true;
            if (
                $state.params.name === undefined &&
                $state.params['account[]'] === undefined &&
                $state.params['company[]'] === undefined &&
                $state.params.startDate === undefined &&
                $state.params.endDate === undefined &&
                $state.params['location[]'] === undefined &&
                $state.params['subnetwork[]'] === undefined &&
                $state.params['adFormat[]'] === undefined &&
                $state.params['businessModel'] === undefined &&
                $state.params['status'] === undefined &&
                $state.params.displayCurrency === undefined
            ) {
                searchFinancialReports = false;
            }

            if ($stateParams.startDate !== undefined && $stateParams.endDate !== undefined) {
                vm.dateRange = {
                    startDate: moment($stateParams.startDate),
                    endDate: moment($stateParams.endDate)
                };
            }

            vm.filters = {
                name: $stateParams.name,
                account: $stateParams['account[]'] !== undefined ? (_.isArray($stateParams['account[]']) ? $stateParams['account[]'] : [$stateParams['account[]']]) : [],
                company: $stateParams['company[]'] !== undefined ? (_.isArray($stateParams['company[]']) ? $stateParams['company[]'] : [$stateParams['company[]']]) : [],
                startDate: vm.dateRange.startDate,
                endDate: vm.dateRange.endDate,
                location: $stateParams['location[]'] !== undefined ? (_.isArray($stateParams['location[]']) ? $stateParams['location[]'] : [$stateParams['location[]']]) : [],
                subnetwork: $stateParams['subnetwork[]'] !== undefined ? (_.isArray($stateParams['subnetwork[]']) ? $stateParams['subnetwork[]'] : [$stateParams['subnetwork[]']]) : [],
                adFormat: $stateParams['adFormat[]'] !== undefined ? (_.isArray($stateParams['adFormat[]']) ? $stateParams['adFormat[]'] : [$stateParams['adFormat[]']]) : [],
                businessModel: $stateParams['businessModel[]'] !== undefined ? (_.isArray($stateParams['businessModel[]']) ? $stateParams['businessModel[]'] : [$stateParams['businessModel[]']]) : [],
                status: $stateParams['status[]'] !== undefined ? (_.isArray($stateParams['status[]']) ? $stateParams['status[]'] : [$stateParams['status[]']]) : []
            };

            if ($stateParams.displayCurrency) {
                vm.selectedCurrency = _.find(vm.currencyOptions, {'code': $stateParams.displayCurrency});
                vm.selectedCurrencyCode = vm.selectedCurrency.code;
            }

            if (searchFinancialReports) {
                getCampaignFinancialReports();
            }
        }

        function applyFilters() {
            getCampaignFinancialReports();
        }

        function getAccounts(search) {
            var params = {
                term: search || null
            };
            Account.get(params).$promise
                .then(onGetAccountsSuccess);

            function onGetAccountsSuccess(accounts) {
                vm.accountOptions = _.map(accounts.items, 'name');
            }
        }

        function getCompanies(search) {
            var params = {
                term: search || null
            }

            Company.get(params).$promise
                .then(onGetCompaniesSuccess);

            function onGetCompaniesSuccess(companies) {
                vm.companyOptions = _.map(companies.items, 'name');
            }
        }

        function extractStatuses() {
            var ret = [];
            if (vm.filters.status.length > 0) {
                _.forEach(vm.filters.status, function (status) {
                    ret.push(status.value);
                });
            }

            return ret;
        }

        /**
         * Retrieve campaign financial reports from the API.
         *
         * @return {Promise}
         */
        function getCampaignFinancialReports() {
            var params = {
                name: vm.filters.name,
                'account[]': vm.filters.account,
                'company[]': vm.filters.company,
                startDate: vm.filters.startDate.format('YYYY-MM-DD'),
                endDate: vm.filters.endDate.format('YYYY-MM-DD'),
                displayCurrency: vm.selectedCurrency.code,
                'location[]': vm.filters.location,
                'subnetwork[]': vm.filters.subnetwork,
                'adFormat[]': vm.filters.adFormat,
                'businessModel[]' : vm.filters.businessModel,
                'status[]': extractStatuses()
            };

            if (vm.sortBy && vm.sortDirection) {
                params.sortBy = vm.sortBy;
                params.sortDirection = vm.sortDirection;
            }

            vm.ui.gettingCampaignFinancialReports = true;
            return Campaign.getFinancialReports(params)
                .$promise
                .then(onGetReportsSuccess, onGetReportsError)
                .finally(onGetReportsFinally);

            function onGetReportsSuccess(campaignFinancialReports) {
                vm.campaignFinancialReports = campaignFinancialReports;
                vm.selectedCurrencyCode = vm.selectedCurrency.code;

                // update query params
                $state.transitionTo(
                    'campaign.financial-report',
                    params,
                    {notify: false}
                );
            }

            function onGetReportsError(reason) {
                toastr.error('Our apologies, we have been unable to retrieve your data. Please, try again later.');
            }

            function onGetReportsFinally() {
                vm.ui.gettingCampaignFinancialReports = false;
            }
        }

        function getCountries() {
            Location.getCountries()
                .$promise
                .then(onGetCountriesSuccess)
                .catch(resourceResponse.error);

            function onGetCountriesSuccess(response) {
                vm.countryOptions = response.data;
            }
        }

        function getCountryFlag(country) {
            return 'flag-icon-' + country.toLowerCase();
        }

        function reportDownload(format) {
            var url = '/api/campaigns/financial_reports?' +
                'format=' + format +
                '&startDate=' + vm.filters.startDate.format('YYYY-MM-DD') +
                '&endDate=' + vm.filters.endDate.format('YYYY-MM-DD') +
                '&displayCurrency=' + vm.selectedCurrency.code;

            if (vm.filters.name) {
                url += '&name=' + vm.filters.name;
            }

            if (vm.filters.account.length > 0) {
                _.forEach(vm.filters.account, function (account) {
                    url += '&account[]=' + account;
                });
            }

            if (vm.filters.company.length > 0) {
                _.forEach(vm.filters.company, function (company) {
                    url += '&company[]=' + company;
                });
            }

            if (vm.filters.location.length > 0) {
                _.forEach(vm.filters.location, function (location) {
                    url += '&location[]=' + location;
                });
            }

            if (vm.filters.subnetwork.length > 0) {
                _.forEach(vm.filters.subnetwork, function (subnetwork) {
                    url += '&subnetwork[]=' + subnetwork;
                });
            }

            if (vm.filters.adFormat.length > 0) {
                _.forEach(vm.filters.adFormat, function (adFormat) {
                    url += '&adFormat[]=' + adFormat;
                });
            }

            if (vm.filters.businessModel.length > 0) {
                _.forEach(vm.filters.businessModel, function (businessModel) {
                    url += '&businessModel[]=' + businessModel;
                });
            }

            if (vm.filters.status.length > 0) {
                _.forEach(vm.filters.status, function (status) {
                    url += '&status[]=' + status.value;
                });
            }

            vm.downloading = true;
            reportDownloader.download(url).then(function () {
                vm.downloading = false;
            });
        }

        function onDateRangeChange(newVal, oldVal) {
            if (newVal !== oldVal) {
                vm.isValidPeriodRangeForExcelreport = checkPeriodRangeForExcelReport(newVal);
                vm.filters.startDate = newVal.startDate;
                vm.filters.endDate = newVal.endDate;
            }
        }

        /**
         *
         * @param newVal
         * @returns {boolean}
         */
        function checkPeriodRangeForExcelReport(newVal) {

            var endDate = newVal.endDate.startOf('day');
            var startDate = newVal.startDate.startOf('day');
            var diff = endDate.diff(startDate, 'months', true);

            return diff <= 3;
        }

        function showCampaignPlacementFinancialReportsModal(placementFinancialReports) {
            $uibModal.open({
                size: 'xl',
                templateUrl: 'campaign/financial/placement-financial-reports-modal.html',
                controller: 'PlacementFinancialReportsModalController as vm',
                bindToController: true,
                resolve: {
                    reports: function () {
                        return placementFinancialReports;
                    },
                    isUserAccountant: function() {
                        return vm.ui.isUserAccountant;
                    },
                    isUserTrader: function() {
                        return vm.ui.isUserTrader;
                    },
                    isUserSupport: function() {
                        return vm.ui.isUserSupport;
                    },
                    currency: function() {
                        return vm.selectedCurrencyCode;
                    }
                }
            });
        }

        function sortTable(column) {
            if (vm.sortBy === column) {
                vm.sortDirection = vm.sortDirection === 'desc' ? 'asc' : 'desc';
            } else {
                vm.sortBy = column;
                vm.sortDirection = 'desc';
            }

            getCampaignFinancialReports();
        }

        /**
         * Callback for the $destroy event.
         */
        function onDestroy() {
            vm.unregisterDateRangeWatcher();
        }
    }
})();
