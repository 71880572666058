(function () {
    'use strict';

    angular
        .module('tvl.campaign')
        .controller('UpdateAdVideoModalController', UpdateAdVideoModalController);

    UpdateAdVideoModalController.$inject = [
        '$scope',
        '$uibModalInstance',
        'toastr',
        'tvlCampaignUtils',
        'tvlAdVideoUtils',
        'tvlUrl',
        'tvlUuid',
        'tvlYoutube',
        'UrlUtil',
        'Upload',
        'ImageAsset',
        'adInfo'
    ];

    /* @ngInject */
    function UpdateAdVideoModalController(
        $scope,
        $uibModalInstance,
        toastr,
        tvlCampaignUtils,
        tvlAdVideoUtils,
        tvlUrl,
        tvlUuid,
        tvlYoutube,
        UrlUtil,
        Upload,
        ImageAsset,
        adInfo
    ) {
        var vm = this;

        vm.tvlUrl = tvlUrl;
        vm.tvlAdVideoUtils = tvlAdVideoUtils;

        vm.isTrackingUrlRequired = (adInfo.hasOwnProperty('trackingUrl') && adInfo['trackingUrl'] !== '');
        vm.youtubeAdVideo = tvlAdVideoUtils.buildAd(adInfo);
        vm.showYoutubeAdVideoPreview = true;

        /* Video duration validations */
        vm.isValidYoutubeVideoDuration = false;
        vm.minVideoDuration = null;
        vm.maxVideoDuration = null;
        vm.currentVideoDuration = null;

        vm.ui = {
            validationErrors: {
                youtubeAdVideo: null
            },
            showTargetUrlField: tvlAdVideoUtils.isAdVideoInStreamFamily(vm.youtubeAdVideo.format),
            showDisplayUrlField: tvlAdVideoUtils.isAdVideoInStreamFamily(vm.youtubeAdVideo.format),
            showCompanionBannerField: tvlAdVideoUtils.isAdVideoInStreamFamily(vm.youtubeAdVideo.format),
            isValidYoutubeAdVideo: false,
        };
        vm.unregisterFns = [];
        vm.videosFound = {
            youtube: false
        };
        vm.dirty = false;
        vm.areCorrectTargetAndDisplayYoutubeAdVideoUrlDomains = true;

        vm.cancel = cancel;
        vm.checkYoutubeVideoUrl = checkYoutubeVideoUrl;
        vm.checkYoutubeTargetAndDisplayUrlDomains = checkYoutubeTargetAndDisplayUrlDomains;
        vm.hasHttpErrorYoutubeTargetUrl = hasHttpErrorYoutubeTargetUrl;
        vm.updateAdVideo = updateAdVideo;
        vm.isValidTrackingUrl = isValidTrackingUrl;

        vm.isTrackingUrlRemoveAttempt = isTrackingUrlRemoveAttempt;

        vm.isValidYoutubeDisplayUrl = isValidYoutubeDisplayUrl;
        vm.isValidYoutubeTargetUrl = isValidYoutubeTargetUrl;
        vm.onCompanionBannerChange = onCompanionBannerChange;

        activate();

        ////////////////

        function activate() {
            checkYoutubeVideoUrl(vm.youtubeAdVideo.videoUrl);
            checkYoutubeTargetAndDisplayUrlDomains();

            vm.unregisterFns = [
                $scope.$watch('vm.youtubeAdVideo.videoUrl', onYoutubeVideoUrlChange),
                $scope.$watch('vm.youtubeAdVideo.targetUrl', onYoutubeTargetOrDisplayUrlChange),
                $scope.$watch('vm.youtubeAdVideo.displayUrl', onYoutubeTargetOrDisplayUrlChange),

                /**
                 * Subscribe all fields
                 */
                $scope.$watch('vm.youtubeAdVideo.videoUrl', onEditableFieldChanged.bind(null, 'videoUrl'), true),
                $scope.$watch('vm.youtubeAdVideo.companionBanner', onEditableFieldChanged.bind(null, 'companionBanner'), true),
                $scope.$watch('vm.youtubeAdVideo.targetUrl', onEditableFieldChanged.bind(null, 'targetUrl'), true),
                $scope.$watch('vm.youtubeAdVideo.displayUrl', onEditableFieldChanged.bind(null, 'displayUrl'), true),
                $scope.$watch('vm.youtubeAdVideo.headline', onEditableFieldChanged.bind(null, 'headline'), true),
                $scope.$watch('vm.youtubeAdVideo.firstDescription', onEditableFieldChanged.bind(null, 'firstDescription'), true),
                $scope.$watch('vm.youtubeAdVideo.secondDescription', onEditableFieldChanged.bind(null, 'secondDescription'), true),
                $scope.$watch('vm.youtubeAdVideo.landingPage', onEditableFieldChanged.bind(null, 'landingPage'), true),
                $scope.$watch('vm.youtubeAdVideo.trackingUrl', onEditableFieldChanged.bind(null, 'trackingUrl'), true),
                $scope.$watch('vm.youtubeAdVideo.callToAction', onEditableFieldChanged.bind(null, 'callToAction'), true),

                $scope.$watchGroup([
                    'vm.isValidYoutubeVideoDuration',
                    'vm.areCorrectTargetAndDisplayYoutubeAdVideoUrlDomains',
                    'vm.youtubeAdVideo.videoUrl',
                    'vm.youtubeAdVideo.targetUrl',
                    'vm.youtubeAdVideo.displayUrl',
                    'vm.youtubeAdVideo.companionBanner',
                    'vm.youtubeAdVideo.trackingUrl',
                    'vm.youtubeAdVideo.headline',
                    'vm.youtubeAdVideo.firstDescription',
                    'vm.youtubeAdVideo.secondDescription',
                    'vm.youtubeAdVideo.callToAction',
                ], validateYoutubeAdVideo),
            ];
        }

        function cancel() {
            return $uibModalInstance.dismiss();
        }

        function checkYoutubeTargetAndDisplayUrlDomains() {
            vm.areCorrectTargetAndDisplayYoutubeAdVideoUrlDomains = false;
            if (vm.youtubeAdVideo.targetUrl && vm.youtubeAdVideo.displayUrl && tvlUrl.domainsMatch(vm.youtubeAdVideo.targetUrl, vm.youtubeAdVideo.displayUrl)) {
                vm.areCorrectTargetAndDisplayYoutubeAdVideoUrlDomains = true;
                UrlUtil.info({url: vm.youtubeAdVideo.targetUrl})
                    .$promise
                    .then(function(response) {
                        vm.targetUrlStatusCode = response.statusCode;
                        validateYoutubeAdVideo();
                    });
            }
        }

        function checkYoutubeVideoUrl(url) {
            if (vm.tvlUrl.isValidYoutubeVideoUrl(url)) {
                validateYoutubeVideoDuration();
                vm.showYoutubeAdVideoPreview = true;
            } else {
                vm.showYoutubeAdVideoPreview = false;
            }
        }

        function updateAdVideo(adVideo) {
            if (!adVideo.id) {
                adVideo.id = tvlUuid.new();
            }
            if (vm.ui.isValidYoutubeAdVideo) {
                $uibModalInstance.close({adVideo: adVideo, accessible: true});
            }
        }

        function isValidTrackingUrl(url) {
            if (url) {
                return tvlUrl.isValid(url);
            }
            return true;
        }

        /**
         * Google Ads Agent can't remove tracking url.
         * So, once set, we don't allow to remove it.
         * @returns {boolean | *}
         */
        function isTrackingUrlRemoveAttempt() {
            return (vm.isTrackingUrlRequired && !vm.youtubeAdVideo.trackingUrl.length);
        }

        /**
         * Return whether the given url is a valid Adwords
         * display URL.
         *
         * @param url {String}
         * @return {Boolean}
         */
        function isValidYoutubeDisplayUrl(url) {
            return url !== undefined ? url.length > 0
                && url.length <= tvlAdVideoUtils.DISPLAY_URL_MAX_CHARACTERS_ALLOWED
                && tvlUrl.isValid(url) : false;
        }

        function isValidYoutubeTargetUrl(url) {
            if (url) {
                return tvlUrl.isValid(url);
            }
        }

        /**
         * Checks the HTTP response status for youtube target url
         * @returns {boolean}
         */
        function hasHttpErrorYoutubeTargetUrl() {
            /**
             * The HTTP query has not been performed yet
             */
            if (!vm.targetUrlStatusCode) {
                return false;
            }

            /**
             * The HTTP response status is OK
             */
            if (vm.targetUrlStatusCode === 200) {
                return false;
            }

            /**
             * The HTTP response status is different from 200
             */
            return true;
        }

        /**
         * Callback function invoked whenever the companion banner changes.
         *
         * This basically Base64-encodes the image and stores the result in the
         * model.
         */
        function onCompanionBannerChange($files, $file, $newFiles, $duplicateFiles, $invalidFiles, $event) {
            vm.dirty = false;
            if ($file === null) {
                vm.youtubeAdVideo.companionBanner = null;
            } else {
                var imageUrl = '';
                Upload.base64DataUrl($files).then(onUploadFileSuccess);
            }

            function onUploadFileSuccess(urls) {
                imageUrl = urls[0];
                ImageAsset.create({}, {'image': imageUrl})
                    .$promise
                    .then(onImageAssetCreationSuccess);
            }

            function onImageAssetCreationSuccess(response) {
                if (response.id) {
                    ImageAsset.get({'id': response.id})
                        .$promise
                        .then(onGetImageAssetSuccess);
                }
            }

            function onGetImageAssetSuccess(imageInfo) {
                if (imageInfo.colorSpace !== 'CMYK') {
                    vm.youtubeAdVideo.companionBanner = imageUrl;
                    vm.colorSpaceError = false;
                } else {
                    vm.youtubeAdVideo.companionBanner = null;
                    vm.colorSpaceError = true;
                }
                vm.dirty = true;
            }
        }

        function onYoutubeTargetOrDisplayUrlChange(newVal, oldVal) {
            if (newVal !== oldVal) {
                checkYoutubeTargetAndDisplayUrlDomains();
            }
        }

        function onEditableFieldChanged(fieldName, newVal, oldVal) {
            if (newVal === oldVal || oldVal === undefined) {
                return;
            }

            vm.dirty = true;
        }

        function onYoutubeVideoUrlChange(newVal, oldVal) {
            if (newVal !== oldVal) {
                checkYoutubeVideoUrl(newVal);
            }
        }

        // Todo (ika): Repeated code, see "advideo-modal.controller.js: validateYoutubeVideoDuration()".
        function validateYoutubeVideoDuration() {
            vm.isValidYoutubeVideoDuration = true;
            if (tvlAdVideoUtils.videoDurationCheckRequired(vm.youtubeAdVideo.format)) {
                var videoId = tvlCampaignUtils.getVideoIdFromUrl('youtube', vm.youtubeAdVideo.videoUrl);

                tvlYoutube.getVideoById({part: 'contentDetails', videoId: videoId})
                    .then (function(response) {
                        if (response.data.items.length > 0) {
                            vm.currentVideoDuration = moment.duration(response.data.items[0].contentDetails.duration).asSeconds();
                            [vm.minVideoDuration, vm.maxVideoDuration] = tvlAdVideoUtils.getVideoDurationLimits(vm.youtubeAdVideo.format);

                            vm.isValidYoutubeVideoDuration = true;
                            if (vm.minVideoDuration && vm.currentVideoDuration < vm.minVideoDuration) {
                                vm.isValidYoutubeVideoDuration = false;
                            }
                            if (vm.maxVideoDuration && vm.currentVideoDuration > vm.maxVideoDuration) {
                                vm.isValidYoutubeVideoDuration = false;
                            }
                        } else {
                            vm.isValidYoutubeVideoDuration = false;
                        }
                    })
                    .catch (function() {
                        vm.isValidYoutubeVideoDuration = false;
                    });
            }
        }

        vm.validateYoutubeAdVideo = validateYoutubeAdVideo;

        /**
         * Validate Youtube ad video
         */
        function validateYoutubeAdVideo() {
            vm.ui.isValidYoutubeAdVideo = false;
            vm.ui.validationErrors.youtubeAdVideo = null;

            if (vm.tvlUrl.isValidYoutubeVideoUrl(vm.youtubeAdVideo.videoUrl) === false) {
                return;
            }

            if (vm.isValidTrackingUrl(vm.youtubeAdVideo.trackingUrl) === false) {
                return;
            }
            if (vm.isTrackingUrlRemoveAttempt()) {
                return;
            }
            if (vm.isValidYoutubeVideoDuration === false) {
                return;
            }

            /**
             * InStream family ad videos.
             */
            if (vm.youtubeAdVideo.isInStreamFamily()) {
                if (vm.isValidYoutubeTargetUrl(vm.youtubeAdVideo.targetUrl) === false) {
                    return;
                }
                if (vm.isValidYoutubeDisplayUrl(vm.youtubeAdVideo.displayUrl) === false) {
                    return;
                }
                if (vm.areCorrectTargetAndDisplayYoutubeAdVideoUrlDomains === false) {

                    vm.ui.validationErrors.youtubeAdVideo = 'Target and display URLs must have the same domain name.';
                    return;
                }
                if (hasHttpErrorYoutubeTargetUrl()) {
                    return;
                }

                if (vm.youtubeAdVideo.isTrueViewForAction()) {
                    if (!vm.youtubeAdVideo.callToAction || vm.youtubeAdVideo.callToAction.length > vm.tvlAdVideoUtils.TRUEVIEW_CALL_TO_ACTION_MAX_LENGTH) {
                        return;
                    }
                    if (!vm.youtubeAdVideo.headline ||
                        vm.youtubeAdVideo.headline.length > vm.tvlAdVideoUtils.TRUEVIEW_HEADLINE_MAX_LENGTH ||
                        vm.tvlAdVideoUtils.compliesAdwordsPolicy(vm.youtubeAdVideo.headline) === false) {
                        return;
                    }
                }
            }

            /**
             * Discovery ad videos.
             */
            if (vm.youtubeAdVideo.isVideoDiscovery()) {
                if (!vm.youtubeAdVideo.headline ||
                    vm.youtubeAdVideo.headline.length > vm.tvlAdVideoUtils.DISCOVERY_HEADLINE_MAX_LENGTH ||
                    vm.tvlAdVideoUtils.compliesAdwordsPolicy(vm.youtubeAdVideo.headline) === false) {
                    return;
                }
                if (!vm.youtubeAdVideo.firstDescription ||
                    vm.youtubeAdVideo.firstDescription.length > vm.tvlAdVideoUtils.YOUTUBE_DESCRIPTION_MAX_CHARACTERS ||
                    vm.tvlAdVideoUtils.compliesAdwordsPolicy(vm.youtubeAdVideo.firstDescription) === false) {
                    return;
                }
                if (!vm.youtubeAdVideo.secondDescription ||
                    vm.youtubeAdVideo.secondDescription.length > vm.tvlAdVideoUtils.YOUTUBE_DESCRIPTION_MAX_CHARACTERS ||
                    vm.tvlAdVideoUtils.compliesAdwordsPolicy(vm.youtubeAdVideo.secondDescription) === false) {
                    return;
                }
            }

            vm.ui.isValidYoutubeAdVideo = true;
        }
    }
}());
