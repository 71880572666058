(function () {
    'use strict';

    angular
        .module('tvl.static', [
        ])
        .config(configure);

    configure.$inject = [
        '$locationProvider',
        '$stateProvider',
    ];

    /* @ngInject */
    function configure(
        $locationProvider,
        $stateProvider
    ) {
        $locationProvider.html5Mode(true);

        $stateProvider.state({
            name: 'static',
            abstract: true,
            templateUrl: 'static-base.html',
        }).state({
            name: 'static.terms-of-use',
            url: '/terms-of-use',
            templateUrl: 'blank-page/terms-of-use.html',
        }).state({
            name: 'static.privacy',
            url: '/privacy',
            templateUrl: 'blank-page/privacy.html',
        });
    }
})();
