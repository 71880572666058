(function() {
    'use strict';

    angular
        .module('tvl.common')
        .directive('tvlItemizedInput', tvlItemizedInput)
        .directive('tvlListMaxItems', tvlListMaxItems);

    tvlItemizedInput.$inject = [];

    /**
     * An itemized input component "tvl-itemized-input":
     *
     * Can be applied as an attribute flag to HTML input elements
     * with an array of strings in the ng-model value
     *
     * Formats:
     *   Shows the array of strings as a multi-line text
     *   where the elements of the array are placed on separated lines.
     *
     * When modified inside the view:
     *   Converts every line of text into a new element of array (except the empty strings)
     *   and updates automatically the ng-model value of the HTML input element.
     *
     * @ngInject
     */
    function tvlItemizedInput() {
        return {
            require: 'ngModel',
            link: link
        };

        function link(scope, el, attrs, ngModelCtrl) {
            ngModelCtrl.$parsers.push(function(viewValue) {
                var result = [];
                var rawInputArray = viewValue.split("\n");
                for (var i = 0; i < rawInputArray.length; i++) {
                    if (rawInputArray[i] !== '') {
                        result.push(rawInputArray[i]);
                    }
                }
                return result;
            });
            ngModelCtrl.$formatters.push(function(modelValue){
                if (modelValue !== undefined && Array.isArray(modelValue)) {
                    return modelValue.join("\n");
                }
                return null;
            });
        }
    }

    tvlListMaxItems.$inject = [];

    /**
     * A directive that restricts the maximum amount of items that are allowed
     * within an array-like model. This is conveniently used alongside the
     * `tvl-itemized-input` directive.
     *
     * If used within a form, the validation rule will be identified as
     * `maxItems`.
     *
     * @usage
     * ```html
     * <textarea ng-model="myModel" tvl-itemized-input tvl-list-max-items="20"></textarea>
     * ```
     *
     * ```js
     * $scope.myModel = ['foo', 'bar'];
     * ```
     *
     * @ngInject
     */
    function tvlListMaxItems() {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: link,
            scope: {
                tvlListMaxItems: '='
            }
        };

        function link(scope, el, attrs, ngModelCtrl) {
            ngModelCtrl.$validators.maxItems = function (modelValue, viewValue) {
                return Array.isArray(modelValue) && modelValue.length <= scope.tvlListMaxItems;
            };
        }
    }
})();
