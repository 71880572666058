(function() {
    'use strict';

    angular
        .module('tvl.campaign')
        .controller('KeywordsDetailsModalController', KeywordsDetailsModalController);

    KeywordsDetailsModalController.$inject = [
        '$uibModalInstance',
        'items',
        'name'
    ];

    /* @ngInject */
    function KeywordsDetailsModalController(
        $uibModalInstance,
        items,
        name
    ) {
        var vm = this;
        vm.items = items;
        vm.name = name;
        vm.cancel = cancel;

        activate();

        function activate() {

        }

        /**
         * Dismiss the modal.
         */
        function cancel() {
            return $uibModalInstance.dismiss();
        }
    }
})();
