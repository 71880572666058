(function() {
    'use strict';

    angular
        .module('tvl.campaign')
        .controller('WizardScheduleController', WizardScheduleController);

    WizardScheduleController.$inject = [
        '$scope',
        '$timeout',
        '$uibModal',
        'tvlCampaignUtils',
        'tvlAdVideoUtils',
        'tvlConstantUtils',
        'tvlDateUtils',
        'ExchangeRate',
        'FacebookBusiness',
        'resourceResponse'
    ];

    /* @ngInject */
    function WizardScheduleController(
        $scope,
        $timeout,
        $uibModal,
        tvlCampaignUtils,
        tvlAdVideoUtils,
        tvlConstantUtils,
        tvlDateUtils,
        ExchangeRate,
        FacebookBusiness,
        resourceResponse
    ) {
        var vm = this;

        vm.tvlAdVideoUtils = tvlAdVideoUtils;
        vm.facebookLimits = {};
        vm.parent = $scope.$parent;
        vm.campaignForm = vm.parent.campaignForm;
        vm.customerAccount = vm.parent.wizardData.customerAccount;
        vm.startDate = vm.campaignForm.startDate;

        vm.ui = {
            loading: true,
            facebookMinBudget: {
                showMessage: false,
                minCpv: 0,
                minCpc: 0
            }
        };
        vm.unregisterFns = [];
        vm.usdExchangeRate = null;

        vm.showHourlyScheduleModal = showHourlyScheduleModal;

        if (vm.campaignForm.networks.facebook || vm.campaignForm.networks.instagram) {
            FacebookBusiness.getMinimumBudgets({
                adAccountId: vm.customerAccount.facebookConfiguration.accountId,
                currency: vm.customerAccount.currency,
                accountId: vm.customerAccount.id
            }).$promise.then(function (result) {
                vm.facebookLimits = result.data;
            }, resourceResponse.error);
        }

        activate();

        ////////////////

        function activate() {
            vm.unregisterFns = [
                $scope.$on('tvlValidate', function(event, step) {
                    if (step.key === 'schedule') {
                        vm.parent.$emit('tvlValidationEnd', {'valid' : validate()});
                    }
                }),
                $scope.$on('tvlInit', function(event, step) {
                    if (step.key === 'schedule') {
                        init();
                    }
                })
            ];
        }

        /**
         * Calls exchange-rate's endpoint to translate the current displayCurrency into USD
         * and then initializes the placementSchedule data
         */
        function init() {
            vm.displayCurrency = vm.customerAccount.currency;
            if (vm.displayCurrency.toUpperCase() === 'USD') {
                vm.usdExchangeRate = 1;
                initializePlacementSchedules();
            } else {
                ExchangeRate.getCurrencyPair({originCurrency: vm.displayCurrency, destinationCurrency: 'USD'})
                    .$promise
                    .then(function(response) {
                        vm.usdExchangeRate = parseFloat(response.rate);
                        initializePlacementSchedules();
                    });
            }
        }

        /**
         * Creates the date-range sliders for each placement
         * and calculates available ranges by the Facebook's minimum budget
         */
        function initializePlacementSchedules() {

            /**
             * Receives two dates and returns an array of dates between them
             *
             * @param Date dateFrom
             * @param Date dateTo
             * @returns {Array}
             */
            function getStepsArray(dateFrom, dateTo) {
                var steps = [];
                var originalEndDate = moment(dateTo).toDate();
                var currDate = moment(dateFrom);
                while (moment(dateTo).isSameOrAfter(currDate, 'day')) {
                    steps.push(new Date(currDate.toDate().toDateString()));
                    currDate = currDate.clone().add(1, 'days');
                }

                // We need to preserve the exact date and time
                // from the dateTo (aka endDate)
                // so steps.pop() removes the last occurrence in the array.
                steps.pop();

                // Then we add the original endDate to the array.
                steps.push(originalEndDate);

                return steps;
            }

            /**
             * Get display network name
             * @param {Object} adVideo
             * @param {bool} isMobile
             * @returns {string}
             */
            function getNetworkDisplayName(adVideo, isMobile) {
                var icons = tvlAdVideoUtils.getNetworkIconsForAdVideo(adVideo, isMobile);
                var titles = [];
                icons.forEach(function(icon) {
                    titles.push(icon.title);
                });

                return titles.join(' & ');
            }

            var currentDate = new Date();
            if (currentDate > vm.startDate) {
                vm.startDate = currentDate;
            }

            var stepsArray = getStepsArray(vm.startDate, vm.campaignForm.endDate);
            var campaignTotalDays = stepsArray.length - 1;

            var sliderMasterOptions = {
                floor: vm.startDate,
                ceil: vm.campaignForm.endDate,
                stepsArray: stepsArray,
                hideLimitLabels: true,
                minRange: 0,
                maxRange: campaignTotalDays,
                pushRange: true,
                draggableRange: true,
                translate: null
            };

            /*
             * first add new placements that don't yet exist in the schedule
             * array.
             */
            var currentShedule = angular.copy(vm.campaignForm.placementSchedule) || [];
            vm.campaignForm.placementSchedule = [];

            for (var network in vm.campaignForm.adDistribution) {
                var adVideos = vm.campaignForm.adDistribution[network];
                for (var adVideoId in adVideos) {
                    var countries = adVideos[adVideoId];
                    for (var country in countries) {
                        var operatingSystems = vm.parent.getSelectedOperatingSystems();

                        for (var operatingSystem in operatingSystems) {
                            var adVideoTotalBudget = adVideos[adVideoId][country][operatingSystem];
                            var match = _.find(
                                currentShedule,
                                {
                                    network: network,
                                    adVideoId: adVideoId,
                                    country: country,
                                    operatingSystem: operatingSystem
                                }
                            );

                            var matchSteps = (match && match.budget === adVideoTotalBudget) ? match.sliderOptions.stepsArray : false;

                            // Check steps array and if the dates are different, ignore the object
                            if (match &&
                                matchSteps &&
                                stepsArray.length === matchSteps.length &&
                                stepsArray[0].getTime() === matchSteps[0].getTime() &&
                                matchSteps[stepsArray.length - 1].getTime() === matchSteps[matchSteps.length - 1].getTime()
                            ) {
                                vm.campaignForm.placementSchedule.push(match);
                            } else {
                                var adVideo = findAdVideoByIdAndNetwork(adVideoId, network);

                                var schedule = {
                                    network: network,
                                    networkDisplayName: getNetworkDisplayName(adVideo, vm.campaignForm.devices.mobile),
                                    adVideoId: adVideoId,
                                    adVideoVideoId: adVideo.videoId,
                                    adVideoTotalBudget: adVideoTotalBudget,
                                    adVideo: adVideo,
                                    country: country,
                                    format: adVideo.format,
                                    adVideoUrl: adVideo.videoUrl,
                                    budget: adVideos[adVideoId][country][operatingSystem],
                                    operatingSystem: operatingSystem,
                                    startDate: vm.startDate,
                                    endDate: vm.campaignForm.endDate,
                                    sliderOptions: angular.copy(sliderMasterOptions),
                                    isValidDate: function () {
                                        var isValid = true;

                                        if (this.isAnyFacebookNetwork()) {
                                            isValid = vm.parent.isDatesDiffAtLeast24Hours(this.endDate, this.startDate);
                                        }

                                        return isValid;
                                    },
                                    isStartDateInRange: function () {
                                        return moment(this.startDate).isBetween(moment(), vm.campaignForm.endDate, 'minutes', '[]')
                                    },
                                    isEndDateInRange: function () {
                                        return moment(this.endDate).isBetween(moment(), vm.campaignForm.endDate, 'minutes', '[]')
                                    },
                                    isValidBudget: function() {
                                        var isValid = true;

                                        if (schedule.isAnyFacebookNetwork()) {
                                            isValid = this.isValidFacebookDailyBudget();
                                        }

                                        return isValid;
                                    },
                                    isValidStartDate: function () {
                                        var isValid = true;

                                        if (this.isAnyFacebookNetwork()) {
                                            isValid = vm.parent.ensureDateLessThan180Days(this.startDate);
                                        }

                                        return isValid;
                                    },
                                    isValidFacebookDailyBudget: function() {
                                        var minDailyBudget = null;

                                        if (tvlAdVideoUtils.isCpcAdVideo(this.adVideo)) {

                                            /**
                                             * Workaround to comply with Business Requirements ASAP
                                             * We are forcing in the backend Ad Billing method to impressions
                                             * for Facebook click Campaigns.
                                             *
                                             * This should be an option. User should be able to choose between
                                             * clicks or impressions, but we do not have time to implement this.
                                             *
                                             *So hopefully one day, this will be something like:
                                             *
                                             * if advideo.billing === impressions {
                                             * minDailyBudget = (advideo.billing === impressions)
                                             * ? vm.facebookLimits.min_daily_budget_imp / 100
                                             * : vm.facebookLimits.min_daily_budget_high_freq / 100
                                             * */

                                            // minDailyBudget = vm.facebookLimits.min_daily_budget_high_freq / 100;
                                            minDailyBudget = vm.facebookLimits.min_daily_budget_imp / 100;

                                        } else if(tvlAdVideoUtils.isCpvAdVideo(this.adVideo)) {
                                            minDailyBudget = vm.facebookLimits.min_daily_budget_video_views / 100;
                                        } else if(tvlAdVideoUtils.isCpmAdVideo(this.adVideo) || tvlAdVideoUtils.isCpaAdVideo(this.adVideo)) {
                                            minDailyBudget = vm.facebookLimits.min_daily_budget_imp / 100;
                                        }

                                        if (null === minDailyBudget) {
                                            return false;
                                        }

                                        this.minDailyBudget = minDailyBudget;

                                        var adVideoMaxDaysForBudget = parseInt(this.adVideoTotalBudget / minDailyBudget);

                                        // Set the new end-date value and reduce the range
                                        var isValid = true;
                                        if (adVideoMaxDaysForBudget < this.getTotalRunningDays()) {
                                            isValid = false;
                                        }

                                        return isValid;
                                    },
                                    isCpc: function() {
                                        return tvlAdVideoUtils.isCpcAdVideo(this.adVideo);
                                    },
                                    getTotalRunningDays: function() {
                                        return moment(this.endDate).diff(moment(this.startDate), 'days');
                                    },
                                    isAnyFacebookNetwork: function () {
                                        return tvlConstantUtils.isNetworkFacebook(this.network) || tvlConstantUtils.isNetworkInstagram(this.network);
                                    }
                                };
                                schedule.sliderOptions.translate = function (value) {
                                    return moment(value).format('ll');
                                };

                                vm.campaignForm.placementSchedule.push(schedule);
                            }
                        }
                    }
                }
            }

            /*
             * then remove schedules of placements that no longer exist
             */
            var rightPlacementSchedules = [];
            for (var i in vm.campaignForm.placementSchedule) {
                var network = vm.campaignForm.placementSchedule[i].network;
                var adVideoId = vm.campaignForm.placementSchedule[i].adVideoId;
                var country = vm.campaignForm.placementSchedule[i].country;
                var operatingSystem = vm.campaignForm.placementSchedule[i].operatingSystem;

                if (
                    vm.campaignForm.adDistribution.hasOwnProperty(network) &&
                    vm.campaignForm.adDistribution[network].hasOwnProperty(adVideoId) &&
                    vm.campaignForm.adDistribution[network][adVideoId].hasOwnProperty(country) &&
                    vm.campaignForm.adDistribution[network][adVideoId][country].hasOwnProperty(operatingSystem) &&
                    /*
                     * if a user goes back and removes a placement from the budget distribution, it will
                     * remain in the object but with a null value. ideally it should be removed, but until
                     * that is done we check that case here so that we can properly update the schedule list
                     */
                    vm.campaignForm.adDistribution[network][adVideoId][country][operatingSystem]
                ) {
                    rightPlacementSchedules.push(vm.campaignForm.placementSchedule[i]);
                }
            }
            vm.campaignForm.placementSchedule = rightPlacementSchedules;

            vm.ui.loading = false;

            // notify the slider that it must be redrawn
            // (see https://github.com/angular-slider/angularjs-slider#my-slider-is-not-rendered-correctly-on-load)
            $timeout(function () {
                $scope.$broadcast('rzSliderForceRender');
            });
        }

        function findAdVideoByIdAndNetwork(adVideoId, network) {
            var ads = vm.campaignForm.ads[network];
            for (var idx in ads) {
                if (ads[idx].id === adVideoId) {
                    return ads[idx];
                }
            }
        }

        function onHourlyScheduleModalSaved(schedule, data) {
            if (data.timeSlots.length) {
                schedule.timeSlots = data.timeSlots;
            } else {
                schedule.timeSlots = null;
            }
        }

        function showHourlyScheduleModal(schedule) {
            var modal = $uibModal.open({
                templateUrl: 'campaign/hourly-schedule-modal.html',
                controller: 'HourlyScheduleModalController as vm',
                bindToController: true,
                resolve: {
                    timeSlots: function() {
                        return schedule.timeSlots ? angular.copy(schedule.timeSlots) : [];
                    }
                }
            });

            return modal.result.then(onHourlyScheduleModalSaved.bind(null, schedule));
        }

        /**
         * Checks if any placement is invalid.
         *
         * @returns boolean
         */
        function validPlacementsSchedule() {
            var currentIndex = 0;
            var anyInValidDate = false;
            var placements = vm.campaignForm.placementSchedule;

            while (currentIndex < placements.length && false === anyInValidDate) {
                var currentItem = placements[currentIndex];

                anyInValidDate = false === currentItem.isValidDate() ||
                    false === currentItem.isValidBudget() ||
                    false === currentItem.isValidStartDate();

                currentIndex++;
            }

            return false === anyInValidDate;
        }

        // Validation

        /**
         * Validate the placement schedule step.
         *
         * This method currently performs no validation.
         */
        function validate() {
            return validPlacementsSchedule();
        }

        $scope.$on('$destroy', function () {
            for(var i in vm.unregisterFns) {
                vm.unregisterFns[i]();
            }
        });
    }
})();
