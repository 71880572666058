(function() {
    'use strict';

    angular
        .module('tvl.campaign')
        .controller('WizardReviewController', WizardReviewController);

    WizardReviewController.$inject = [
        '$scope',
        'tvlUtils',
        'tvlCampaignUtils',
        'tvlAdVideoUtils',
        'tvlConstantUtils',
        'Campaign',
        'AccountCampaignNames',
        'googleDisplayAdUtils'
    ];

    /* @ngInject */
    function WizardReviewController(
        $scope,
        tvlUtils,
        tvlCampaignUtils,
        tvlAdVideoUtils,
        tvlConstantUtils,
        Campaign,
        AccountCampaignNames,
        googleDisplayAdUtils
    ) {
        var vm = this;

        vm.tvlAdVideoUtils = tvlAdVideoUtils;

        vm.parent = $scope.$parent;
        vm.campaignForm = vm.parent.campaignForm;
        vm.networkPrices = vm.parent.wizardData.networkPrices;
        vm.customerAccount = vm.parent.wizardData.customerAccount;

        vm.campaignStub = null;
        vm.unregisterFns = [];
        vm.adVideoBumperDetected = false;
        vm.fixedLowerThanMaxDetected = false;
        vm.fixedLowerThanMinDetected = false;
        vm.ui = {
            showCustomNamesForm: false,
            campaignNamesAreLoading: false
        };
        vm.accountAdwordsCampaigns = null;
        vm.accountFacebookCampaigns = null;

        activate();

        ////////////////

        function activate() {
            // Last step don't need to be validated
            // $scope.$on('validate', function(event, step) {
            //     if (step.key === 'review') {
            //         vm.parent.$emit('validationEnd', {'valid' : validate()});
            //     }
            // });

            vm.unregisterFns = [
                $scope.$on('tvlInit', function(event, step) {
                    var campaign = null;
                    if (step.key === 'review') {
                        vm.parent.campaign = prepareCampaignForSubmit();
                        campaign = angular.copy(vm.parent.campaign);
                        vm.campaignStub = prepareCampaignStructureForReview(campaign);

                        if (vm.parent.importCampaignId) {
                            buildDataForPlacementCreation();
                        }
                    }
                })
            ];

            getAccountCampaignNames();
        }

        function buildDataForPlacementCreation() {
            vm.parent.createPlacements = [];
            for (var network in vm.campaignForm.adDistribution) {
                for (var adVideoId in vm.campaignForm.adDistribution[network]) {
                    for (var country in vm.campaignForm.adDistribution[network][adVideoId]) {

                        var operatingSystems = vm.parent.getSelectedOperatingSystems();
                        for (var operatingSystem in operatingSystems) {

                            var schedule = _.find(
                                vm.campaignForm.placementSchedule, {
                                    network: network,
                                    adVideoId: adVideoId,
                                    country: country,
                                    operatingSystem: operatingSystem
                                }
                            );
                            if (typeof schedule === 'undefined') {
                                continue;
                            }

                            var locations = _(vm.campaignForm.targeting.locations).filter({
                                country: country,
                                network: vm.parent.isGoogleNetwork(network) ? 'adwords' : 'facebook'
                            }).value();

                            var ad = _.find(vm.campaignForm.ads[network], {id: adVideoId});
                            var placementCustomName = _.find(vm.parent.campaign.customNames, {id: ad.id});

                            let isFacebook = vm.campaignForm.networks[tvlConstantUtils.NETWORK_FACEBOOK] || vm.campaignForm.networks[tvlConstantUtils.NETWORK_INSTAGRAM];

                            var placement = {
                                network: tvlConstantUtils.platformToNetwork(network),
                                facebookPositions: getPositionsValue(ad.facebookPositions),
                                instagramPositions: getPositionsValue(ad.instagramPositions),
                                facebookObjective: ad.facebookObjective,
                                facebookOptimizationGoal: ad.facebookOptimizationGoal,
                                facebookFrequencyCapImpressions: ad.facebookFrequencyCapImpressions,
                                facebookFrequencyCapIntervalDays: ad.facebookFrequencyCapIntervalDays,
                                // adVideoId: adVideoId,
                                country: country,
                                locations: locations,
                                operatingSystem: operatingSystems[operatingSystem].value,
                                amount: vm.campaignForm.adDistribution[network][adVideoId][country][operatingSystem],
                                biddingAmount: vm.campaignForm.initialBiddingValues[network][country.toLowerCase()][translateGoalToPaymentModel(ad.goal)],
                                startDate: isFacebook ? moment(schedule.startDate).format('YYYY-MM-DD HH:mm') : moment(schedule.startDate).hour(0).minute(0).seconds(0).format('YYYY-MM-DD HH:mm'),
                                endDate: isFacebook ? moment(schedule.endDate).format('YYYY-MM-DD HH:mm') : moment(schedule.endDate).hour(23).minute(59).seconds(0).format('YYYY-MM-DD HH:mm'),
                                timeSlots: schedule.timeSlots, // Todo: review this
                                includedContents: getContentsForCountry(vm.campaignForm.targeting.includedContents, country),
                                excludedContents: getContentsForCountry(vm.campaignForm.targeting.excludedContents, country),
                                budget: schedule.budget,
                                customNames: placementCustomName,
                            };

                            addFixedAndMaxPrices(placement);

                            var adVideo = null;
                            if (network === 'adwords' || network === 'youtube') {
                                adVideo = ad.normalize();
                            } else if (network === 'googleDisplay') {
                                adVideo = ad;
                            } else if (network === 'facebook') {
                                adVideo = convertFacebookInstagramAdToAdVideo(ad, tvlConstantUtils.NETWORK_FACEBOOK);
                            } else if (network === 'instagram') {
                                adVideo = convertFacebookInstagramAdToAdVideo(ad, tvlConstantUtils.NETWORK_INSTAGRAM);
                            } else {
                                continue;
                            }

                            var newPlacement = {...placement};
                            newPlacement.adVideo = adVideo;
                            vm.parent.createPlacements.push(newPlacement);
                        }
                    }
                }
            }
        }

        /**
         * Return the contents from the given list which are applicable to
         * the specified country, provided as its ISO code.
         *
         * @param {Array} contents
         * @param {String} country
         * @return {Array}
         */
        function getContentsForCountry(contents, country) {
            return contents
                .filter(function (content) {
                    return content.countries === null || content.countries.indexOf(country) >= 0;
                })
                .map(function (content) {
                    return tvlCampaignUtils.mapContent(content);
                });
        }

        /**
         *
         * @param {Array} positions
         * @returns {Array}
         */
        function getPositionsValue(positions) {
            if (typeof positions === 'undefined') {
                return [];
            }

            return positions
                .map(function(position) {
                    return position.value;
                });
        }

        function translateGoalToPaymentModel(goal) {
            if (goal == 'views') {
                return 'cpv';
            }
            if (goal == 'impressions') {
                return 'cpm';
            }
            if (goal == 'clicks') {
                return 'cpc';
            }
            if (goal == 'action') {
                return 'cpa';
            }
            return goal;
        }

        function prepareCampaignForSubmit() {
            var campaign = new Campaign({});
            campaign.name = vm.campaignForm.name;
            if (vm.campaignForm.hasOwnProperty('fee') && vm.campaignForm.fee !== null) {
                campaign.fee = vm.campaignForm.fee / 100;
            }
            campaign.displayCurrency = vm.customerAccount.currency;

            campaign.enableHoursModification = vm.campaignForm.enableHoursModification;

            if (campaign.enableHoursModification === true) {
                campaign.startDate = moment(vm.campaignForm.startDate).seconds(0).format('YYYY-MM-DD HH:mm:ss');
                campaign.endDate = moment(vm.campaignForm.endDate).seconds(0).format('YYYY-MM-DD HH:mm:ss');
            } else {
                // if user doesn't modify the hours
                // use the default start and end time: 00:00:00 => 23:59:59
                campaign.startDate = moment(vm.campaignForm.startDate).hour(0).minute(0).seconds(0).format('YYYY-MM-DD HH:mm:ss');
                campaign.endDate = moment(vm.campaignForm.endDate).hour(23).minute(59).seconds(59).format('YYYY-MM-DD HH:mm:ss');
            }

            campaign.startDateObj = vm.campaignForm.startDate;
            campaign.endDateObj = vm.campaignForm.endDate;

            var targeting = vm.campaignForm.targeting;
            campaign.minAge = targeting.minAge;
            campaign.maxAge = targeting.maxAge;
            campaign.gender = targeting.gender;
            campaign.languages = targeting.languages;
            campaign.includeUnknownAges = targeting.includeUnknownAges;
            campaign.includeUnknownGender = targeting.includeUnknownGender;

            campaign.simulated = vm.campaignForm.simulated;

            campaign.devices = [];

            if (vm.campaignForm.devices.desktop) {
                campaign.devices.push('Computer');
                if(vm.campaignForm.networks.youtube || vm.campaignForm.networks.googleDisplay || vm.campaignForm.networks.adwords) {
                    campaign.devices.push('TV Screen');
                }
            }

            if (vm.campaignForm.devices.mobile) {
                campaign.devices.push('Tablet');
                campaign.devices.push('Mobile');
            }

            campaign.interests = _.map(vm.campaignForm.targeting.selectedInterests, 'id');
            campaign.interests = campaign.interests.filter((e) => (e != null && e !== ''));

            if (vm.campaignForm.networks.instagram || vm.campaignForm.networks.facebook) {
                campaign.interestsFromFacebook = _.map(vm.campaignForm.targeting.selectedInterests, 'networkId');
            } else {
                campaign.interestsFromGoogle = _.map(vm.campaignForm.targeting.selectedInterests, 'networkId');
            }

            campaign.topics = _.map(vm.campaignForm.targeting.selectedTopics, 'id');
            campaign.excludedTopics = _.map(vm.campaignForm.targeting.selectedExcludedTopics, 'id');

            campaign.keywords = _.map(
                vm.campaignForm.targeting.selectedKeywords.included,
                function (keywordTargeting) {
                    if (keywordTargeting.hasOwnProperty('list') && keywordTargeting['list']) {
                        return {list: keywordTargeting.list.id};
                    }
                    return keywordTargeting;
                }
            );
            campaign.excludedKeywords = _.map(
                vm.campaignForm.targeting.selectedKeywords.excluded,
                function (keywordTargeting) {
                    if (keywordTargeting.hasOwnProperty('list') && keywordTargeting['list']) {
                        return {list: keywordTargeting.list.id};
                    }
                    return keywordTargeting;
                }
            );

            campaign.frequencyCapViews = vm.campaignForm.frequencyCapViews;
            campaign.frequencyCapViewsTime = vm.campaignForm.frequencyCapViewsTime;
            campaign.frequencyCapImpressions = vm.campaignForm.frequencyCapImpressions;
            campaign.frequencyCapImpressionsTime = vm.campaignForm.frequencyCapImpressionsTime;
            campaign.countries = vm.campaignForm.countries;

            addFixedAndMaxPrices(campaign);

            campaign.budgets = [];
            for (var network in vm.campaignForm.adDistribution) {
                for (var adVideoId in vm.campaignForm.adDistribution[network]) {
                    for (var country in vm.campaignForm.adDistribution[network][adVideoId]) {

                        var operatingSystems = vm.parent.getSelectedOperatingSystems();

                        for (var operatingSystem in operatingSystems) {

                            var schedule = _.find(
                                vm.campaignForm.placementSchedule, {
                                    network: network,
                                    adVideoId: adVideoId,
                                    country: country,
                                    operatingSystem: operatingSystem
                                }
                            );
                            var locations = _(vm.campaignForm.targeting.locations).filter({
                                country: country,
                                network: vm.parent.isGoogleNetwork(network) ? 'adwords' : 'facebook'
                            }).value();

                            var ad = _.find(vm.campaignForm.ads[network], {id: adVideoId});

                            if (typeof schedule !== 'undefined') {

                                var momentScheduleStartDate = moment(schedule.startDate).seconds(0);
                                var momentScheduleEndDate = moment(schedule.endDate).seconds(0);

                                if (campaign.enableHoursModification === false) {
                                    var momentScheduleStartDate = moment(schedule.startDate).hour(0).minute(0).seconds(0);
                                    var momentScheduleEndDate = moment(schedule.endDate).hour(23).minute(59).seconds(59);
                                }

                                campaign.budgets.push({
                                    network: tvlConstantUtils.platformToNetwork(network),
                                    facebookPositions: getPositionsValue(ad.facebookPositions),
                                    instagramPositions: getPositionsValue(ad.instagramPositions),
                                    facebookObjective: ad.facebookObjective,
                                    facebookOptimizationGoal: ad.facebookOptimizationGoal,
                                    facebookFrequencyCapImpressions: ad.facebookFrequencyCapImpressions,
                                    facebookFrequencyCapIntervalDays: ad.facebookFrequencyCapIntervalDays,
                                    adVideoId: adVideoId,
                                    country: country,
                                    locations: locations,
                                    operatingSystem: operatingSystems[operatingSystem].value,
                                    amount: vm.campaignForm.adDistribution[network][adVideoId][country][operatingSystem],
                                    startDate: momentScheduleStartDate.format('YYYY-MM-DD HH:mm:ss'),
                                    endDate: momentScheduleEndDate.format('YYYY-MM-DD HH:mm:ss'),
                                    timeSlots: schedule.timeSlots,
                                    includedContents: getContentsForCountry(vm.campaignForm.targeting.includedContents, country),
                                    excludedContents: getContentsForCountry(vm.campaignForm.targeting.excludedContents, country),
                                    biddingAmount: vm.campaignForm.initialBiddingValues[network][country.toLowerCase()][translateGoalToPaymentModel(ad.goal)],
                                });
                            }
                        }
                    }
                }
            }

            // Custom names block
            campaign.customNames = [];
            for (var network in vm.campaignForm.adDistribution) {
                var displayNetwork = tvlConstantUtils.platformToNetwork(network);

                for (var adIndex in vm.campaignForm.ads[network]) {
                    var ad = vm.campaignForm.ads[network][adIndex];
                    var videoId = null;

                    if (tvlAdVideoUtils.isAdwordsImageAdDisplayFormat(ad.format)) {
                        videoId = (ad['imageName']) ? ad['imageName'] : null;
                    } else {
                        videoId = ad.videoId ? ad.videoId : tvlCampaignUtils.getVideoIdFromUrl(network, ad.videoUrl);
                    }

                    var adId = ad['id'];
                    var format = ad['format'];
                    var thumbnail = ad.imageUrl;
                    var goal = ad.goal;

                    var adDistribution = vm.campaignForm.adDistribution[network][adId];
                    for (var country in adDistribution) {
                        var row = adDistribution[country];
                        for (var os in row) {
                            var key = videoId + '_' + displayNetwork + '_' + country + '_' + os;
                            campaign.customNames.push({
                                'id': adId,
                                'videoId': videoId,
                                'network': displayNetwork,
                                'format': format,
                                'country': country,
                                'os': os,
                                'key': key.toLowerCase(),
                                'campaign_name': null,
                                'adgroup_name': null,
                                'ad_name': null,
                                'thumbnail': thumbnail,
                                'goal': goal
                            });
                        }
                    }
                }
            }

            campaign.users = [];
            campaign.accounts = [];

            // we need this to grab the applied campaign fee
            campaign.customerAccount = vm.customerAccount.id;
            campaign.customerAccountName = vm.customerAccount.name;

            for (var i = 0; i < vm.campaignForm.selectedUsersOrAccounts.length; i++) {
                var obj = vm.campaignForm.selectedUsersOrAccounts[i];
                if (obj.email) {
                    campaign.users.push(obj.id);
                } else {
                    campaign.accounts.push(obj.id);
                }
            }

            campaign.adVideos = [];

            // Youtube
            _.forEach(vm.campaignForm.ads.youtube, function(ad) {
                if (ad.isBumper()) {
                    vm.adVideoBumperDetected = (campaign.frequencyCapViews !== null && campaign.frequencyCapViews !== '');
                }
                campaign.adVideos.push(ad.normalize());
            });

            _.forEach(vm.campaignForm.ads.googleDisplay, function(ad) {
                campaign.adVideos.push(ad);
            });

            // Facebook
            _.forEach(vm.campaignForm.ads.facebook, function(ad){
                campaign.adVideos.push(convertFacebookInstagramAdToAdVideo(ad, tvlConstantUtils.NETWORK_FACEBOOK));
            });

            // Instagram
            _.forEach(vm.campaignForm.ads.instagram, function(ad){
                campaign.adVideos.push(convertFacebookInstagramAdToAdVideo(ad, tvlConstantUtils.NETWORK_INSTAGRAM));
            });

            campaign.inventoryType = vm.campaignForm.targeting.inventoryType;
            campaign.contentExclusion = vm.campaignForm.targeting.contentExclusion;
            campaign.rules = vm.campaignForm.rules;

            return campaign;
        }

        /**
         * Adds fixed and max prices to campaign or placement.
         * Modifies the given object.
         *
         * @param campaign
         */
        function addFixedAndMaxPrices(campaign) {
            if (vm.campaignForm.networks.youtube) {
                if (vm.parent.youtubeGoalExists(vm.tvlAdVideoUtils.GOAL_VIEWS)) {
                    campaign.youtubeMaxCpv = vm.networkPrices.youtubeMaxCpv;
                    campaign.youtubeFixedCpv = vm.networkPrices.youtubeFixedCpv;
                    if (campaign.youtubeFixedCpv) {
                        vm.fixedLowerThanMaxDetected = campaign.youtubeFixedCpv < campaign.youtubeMaxCpv;
                        vm.fixedLowerThanMinDetected =
                            campaign.youtubeFixedCpv < vm.customerAccount['adwordsMinBiddingStep'];
                    }
                }
                if (vm.parent.youtubeGoalExists(vm.tvlAdVideoUtils.GOAL_IMPRESSIONS)) {
                    campaign.youtubeMaxCpm = vm.networkPrices.youtubeMaxCpm;
                    campaign.youtubeFixedCpm = vm.networkPrices.youtubeFixedCpm;
                    if (campaign.youtubeFixedCpm) {
                        vm.fixedLowerThanMaxDetected = campaign.youtubeFixedCpm < campaign.youtubeMaxCpm;
                        vm.fixedLowerThanMinDetected =
                            campaign.youtubeFixedCpm < vm.customerAccount['adwordsMinBiddingStep'];
                    }
                }
                if (vm.parent.youtubeGoalExists(vm.tvlAdVideoUtils.GOAL_ACTION)) {
                    campaign.youtubeMaxCpa = vm.networkPrices.youtubeMaxCpa;
                    campaign.youtubeFixedCpa = vm.networkPrices.youtubeFixedCpa;
                    if (campaign.youtubeFixedCpa) {
                        vm.fixedLowerThanMaxDetected = campaign.youtubeFixedCpa < campaign.youtubeMaxCpa;
                        vm.fixedLowerThanMinDetected = campaign.youtubeFixedCpa < vm.customerAccount['adwordsMinBiddingStep'];
                    }
                }
            }

            if (vm.campaignForm.networks.googleDisplay) {
                if (vm.parent.googleDisplayBiddingTypeExists(googleDisplayAdUtils.BIDDING_TYPE_MANUAL_CPM_KEY)) {
                    campaign.youtubeMaxCpm = vm.networkPrices.googleDisplayMaxCpm;
                    campaign.youtubeFixedCpm = vm.networkPrices.googleDisplayFixedCpm;
                    if (campaign.googleDisplayFixedCpm) {
                        vm.fixedLowerThanMaxDetected = campaign.youtubeFixedCpm < campaign.youtubeMaxCpm;
                        vm.fixedLowerThanMinDetected =
                            campaign.youtubeFixedCpm < vm.customerAccount['adwordsMinBiddingStep'];
                    }
                }
                if (vm.parent.googleDisplayBiddingTypeExists(googleDisplayAdUtils.BIDDING_TYPE_TARGET_CPA_KEY)) {
                    campaign.youtubeMaxCpa = vm.networkPrices.googleDisplayMaxCpa;
                    campaign.youtubeFixedCpa = vm.networkPrices.googleDisplayFixedCpa;
                    if (campaign.googleDisplayFixedCpa) {
                        vm.fixedLowerThanMaxDetected = campaign.youtubeFixedCpa < campaign.youtubeMaxCpa;
                        vm.fixedLowerThanMinDetected = campaign.youtubeFixedCpa < vm.customerAccount['adwordsMinBiddingStep'];
                    }
                }
                if (vm.parent.googleDisplayBiddingTypeExists(googleDisplayAdUtils.BIDDING_TYPE_TARGET_SPEND_KEY) ||
                    vm.parent.googleDisplayBiddingTypeExists(googleDisplayAdUtils.BIDDING_TYPE_MANUAL_CPC_KEY)) {
                    campaign.youtubeMaxCpc = vm.networkPrices.googleDisplayMaxCpc;
                    campaign.youtubeFixedCpc = vm.networkPrices.googleDisplayFixedCpc;
                    if (campaign.googleDisplayFixedCpc) {
                        vm.fixedLowerThanMaxDetected = campaign.youtubeFixedCpc < campaign.youtubeMaxCpc;
                        vm.fixedLowerThanMinDetected = campaign.youtubeFixedCpc < vm.customerAccount['adwordsMinBiddingStep'];
                    }
                }
            }

            var cpvUsed = isCpvUsed();
            var cpcUsed = isCpcUsed();
            var cpmUsed = isCpmUsed();
            var cpaUsed = isCpaUsed();

            if (vm.campaignForm.networks.facebook) {
                if (cpvUsed) {
                    campaign.facebookMaxCpv = vm.networkPrices.facebookMaxCpv;
                    campaign.facebookFixedCpv = vm.networkPrices.facebookFixedCpv;
                }
                if (cpcUsed) {
                    campaign.facebookMaxCpc = vm.networkPrices.facebookMaxCpc;
                    campaign.facebookFixedCpc = vm.networkPrices.facebookFixedCpc;
                }
                if (cpmUsed) {
                    campaign.facebookMaxCpm = vm.networkPrices.facebookMaxCpm;
                    campaign.facebookFixedCpm = vm.networkPrices.facebookFixedCpm;

                    if (campaign.facebookFixedCpm) {
                        vm.fixedLowerThanMaxDetected = campaign.facebookFixedCpm < campaign.facebookMaxCpm;
                        vm.fixedLowerThanMinDetected =
                            campaign.facebookFixedCpm < vm.customerAccount['facebookMinBiddingStepForCpm'];
                    }
                }
                if (cpaUsed) {
                    campaign.facebookMaxCpa = vm.networkPrices.facebookMaxCpa;
                    campaign.facebookFixedCpa = vm.networkPrices.facebookFixedCpa;

                    if (campaign.facebookFixedCpa) {
                        vm.fixedLowerThanMaxDetected = campaign.facebookFixedCpa < campaign.facebookMaxCpa;
                        vm.fixedLowerThanMinDetected =
                            campaign.facebookFixedCpa < vm.customerAccount['facebookMinBiddingStepForCpa'];
                    }
                }
            }

            if (vm.campaignForm.networks.instagram) {
                if (cpvUsed) {
                    campaign.instagramMaxCpv = vm.networkPrices.instagramMaxCpv;
                    campaign.instagramFixedCpv = vm.networkPrices.instagramFixedCpv;
                }
                if (cpcUsed) {
                    campaign.instagramMaxCpc = vm.networkPrices.instagramMaxCpc;
                    campaign.instagramFixedCpc = vm.networkPrices.instagramFixedCpc;
                }
                if (cpmUsed) {
                    campaign.instagramMaxCpm = vm.networkPrices.instagramMaxCpm;
                    campaign.instagramFixedCpm = vm.networkPrices.instagramFixedCpm;

                    if (campaign.instagramFixedCpm) {
                        vm.fixedLowerThanMaxDetected = campaign.instagramFixedCpm < campaign.instagramMaxCpm;
                        vm.fixedLowerThanMinDetected =
                            campaign.instagramFixedCpm < vm.customerAccount['instagramMinBiddingStepForCpm'];
                    }
                }
                if (cpaUsed) {
                    campaign.instagramMaxCpa = vm.networkPrices.instagramMaxCpa;
                    campaign.instagramFixedCpa = vm.networkPrices.instagramFixedCpa;

                    if (campaign.instagramFixedCpa) {
                        vm.fixedLowerThanMaxDetected = campaign.instagramFixedCpa < campaign.instagramMaxCpa;
                        vm.fixedLowerThanMinDetected =
                            campaign.instagramFixedCpa < vm.customerAccount['instagramMinBiddingStepForCpa'];
                    }
                }
            }
        }

        /**
         * Map Facebook and Instagram adVideos
         * @param ad
         * @param network
         * @returns {{id: *, videoUrl: *, platform: *, format: string, trackingUrl: *, goal: (string|*), description: *, postId: (*|null), instagramId: *, postType: *}|{id: *, videoId: *, platform: *, format: string, trackingUrl: *, goal: (string|*), description: *, pageId: *, instagramId: *, type: *, name: *, imageUrl: *, displayUrl: *, linkDescription: (*|string), linkTitle: (*|string), callToActionType: (Document.carouselForm.callToActionButton|null|*)}|{id: *, platform: *, format: string, goal: (string|*), pageId: *, instagramId: *, type: *, name: *, primaryText: *, seeMoreUrl: *, seeMoreDisplayLink: *, callToActionType: (Document.carouselForm.callToActionButton|null|*), addLastSlide: *, orderSlidesByPerformance: *, creatives: Array}|*}
         */
        function convertFacebookInstagramAdToAdVideo(ad, network) {
            var data = null;
            switch (ad.format) {
                case tvlAdVideoUtils.ADVIDEO_FORMAT_FACEBOOK_ANY:
                    data = {
                        id               : ad.id,
                        videoUrl         : ad.videoUrl,
                        platform         : network,
                        format           : tvlAdVideoUtils.ADVIDEO_FORMAT_FACEBOOK_ANY,
                        trackingUrl      : ad.trackingUrl,
                        goal             : ad.goal,
                        description      : ad.description,
                        postId           : ad.postId,
                        instagramId      : ad.instagramId,
                        postType         : ad.postType,
                    };
                    if (ad.postType === tvlAdVideoUtils.INSTAGRAM_CREATIVE_POST_PHOTO || ad.postType === tvlAdVideoUtils.INSTAGRAM_CREATIVE_POST_VIDEO) {
                        data['thumbnailUrl']        = ad.thumbnailUrl;
                        data['pageId']              = ad.pageId;
                        data['InstagramBusinessId'] = ad.InstagramBusinessId;
                    }
                    if (ad.goal === tvlAdVideoUtils.GOAL_VIEWS || ad.goal === tvlAdVideoUtils.GOAL_IMPRESSIONS) {
                        data['targetUrl']           = ad.targetUrl;
                    } else if (ad.goal === tvlAdVideoUtils.GOAL_CLICKS) {
                        data['linkUrl']             = ad.linkUrl;
                        data['displayUrl']          = ad.displayUrl;
                        data['linkDescription']     = ad.linkDescription;
                        data['linkTitle']           = ad.linkTitle;
                        data['callToActionType']    = ad.callToActionButton;
                    }
                    break;
                case tvlAdVideoUtils.ADVIDEO_FORMAT_FACEBOOK_EXTERNAL_MEDIA:
                    if (ad.goal === tvlAdVideoUtils.GOAL_CLICKS) {
                        ad.targetUrl = ad.linkUrl;
                    }

                    data = {
                        id               : ad.id,
                        videoId          : ad.videoId,
                        platform         : network,
                        format           : tvlAdVideoUtils.ADVIDEO_FORMAT_FACEBOOK_EXTERNAL_MEDIA,
                        trackingUrl      : ad.trackingUrl,
                        goal             : ad.goal,
                        description      : ad.description,
                        pageId           : ad.pageId,
                        instagramId      : ad.instagramId,
                        type             : ad.type,
                        name             : ad.name,
                        imageUrl         : ad.imageUrl,
                        displayUrl       : ad.displayUrl,
                        targetUrl        : ad.targetUrl,
                        linkDescription  : ad.linkDescription,
                        linkTitle        : ad.linkTitle,
                        callToActionType : ad.callToActionButton
                    };

                    break;
                case tvlAdVideoUtils.ADVIDEO_FORMAT_FACEBOOK_CAROUSEL_EXTERNAL_MEDIA:
                    data = {
                        id                       : ad.id,
                        platform                 : network,
                        format                   : tvlAdVideoUtils.ADVIDEO_FORMAT_FACEBOOK_CAROUSEL_EXTERNAL_MEDIA,
                        goal                     : ad.goal,
                        pageId                   : ad.pageId,
                        instagramId              : ad.instagramId,
                        type                     : ad.type,
                        name                     : ad.name,
                        primaryText              : ad.primaryText,
                        seeMoreUrl               : ad.seeMoreUrl,
                        seeMoreDisplayLink       : ad.seeMoreDisplayLink,
                        callToActionType         : ad.callToActionButton,
                        addLastSlide             : ad.addLastSlide,
                        orderSlidesByPerformance : ad.orderSlidesByPerformance,
                        creatives                : convertFacebookAdCreatives(ad.creatives),
                    };
                    break;
                default:
                    throw 'Unsupported FB/IG ad format "' + ad.format + '"';
            }
            return data;
        }

        function convertFacebookAdCreatives(creatives) {
            var filteredCreatives = [];
            _.forEach(creatives, function(creative){
                var data;
                data = {
                    videoId: creative.videoId,
                    type: creative.type,
                    imageUrl: creative.imageUrl,
                    name: creative.headLine,
                    description: creative.description,
                    link: creative.link
                };
                filteredCreatives.push(data);
            });

            return filteredCreatives;
        }

        function isCpvUsed() {
            return isBidTypeUsed('cpv');
        }

        function isCpcUsed() {
            return isBidTypeUsed('cpc');
        }

        function isCpmUsed() {
            return isBidTypeUsed('cpm');
        }

        function isCpaUsed() {
            return isBidTypeUsed('cpa');
        }

        function isBidTypeUsed(bidType) {
            var adVideo, isUsed;
            for (var network in vm.campaignForm.ads) {
                for (var i in vm.campaignForm.ads[network]) {
                    adVideo = vm.campaignForm.ads[network][i];
                    isUsed = false;
                    switch (bidType) {
                        case 'cpv':
                            isUsed = tvlAdVideoUtils.isCpvAdVideo(adVideo);
                            break;
                        case 'cpc':
                            isUsed = tvlAdVideoUtils.isCpcAdVideo(adVideo);
                            break;
                        case 'cpm':
                            isUsed = tvlAdVideoUtils.isCpmAdVideo(adVideo);
                            break;
                        case 'cpa':
                            isUsed = tvlAdVideoUtils.isCpaAdVideo(adVideo);
                            break;
                        default:
                            break;
                    }
                    if (isUsed) {
                        return true;
                    }
                }
            }
            return false;
        }

        /**
         * Build a campaign-like structure to be fed into the campaign details directive.
         * This is a display only data, we don't send it to the API.
         */
        function prepareCampaignStructureForReview(campaignStub) {

            // let's fake some placements
            campaignStub.placements = [];
            _.forEach(campaignStub.budgets, function(budget) {
                var placements = _.chain(campaignStub.adVideos)
                    .filter({
                        id: budget.adVideoId,
                        platform: tvlConstantUtils.networkToPlatform(budget.network)
                    })
                    .map(function(adVideo) {
                        var videoId = null;
                        var isVideo = true;
                        var isDisplay = false;

                        if (tvlAdVideoUtils.isAdwordsImageAdDisplayFormat(adVideo.format)) {
                            isVideo = false;
                            isDisplay = true;
                        }

                        if (isVideo) {
                            switch (true) {
                                case tvlAdVideoUtils.isFacebookCarouselExternalAdVideoFormat(adVideo.format):
                                    videoId = adVideo.creatives[0]['videoId'];
                                    break;
                                case  tvlAdVideoUtils.isInstagramStoryAdVideoFormat(adVideo.format):
                                    videoId = adVideo.videoId;
                                    break;
                                case tvlAdVideoUtils.isFacebookExternalAdVideoFormat(adVideo.format):
                                    videoId = adVideo.videoId;
                                    break;
                                default:
                                    videoId = tvlCampaignUtils.getVideoIdFromUrl(adVideo.platform, adVideo.videoUrl);
                            }
                        }

                        var stub =  {
                            country: budget.country,
                            network: budget.network,
                            budget: budget.amount,
                            operatingSystem: budget.operatingSystem,
                            adVideoId: budget.adVideoId,
                            adVideo: {
                                id: adVideo.id,
                                format: adVideo.format,
                                isVideo: isVideo,
                                isDisplay: isDisplay,
                                video: {
                                    videoId: videoId,
                                    title: null,
                                    thumbnail: adVideo.imageUrl
                                }
                            }
                        };

                        if (budget.network === tvlConstantUtils.NETWORK_FACEBOOK) {
                            stub.facebookPublisherPlatforms = [];

                            if (budget.facebookPositions.length > 0) {
                                stub.facebookPublisherPlatforms.push(tvlConstantUtils.NETWORK_FACEBOOK);
                            }

                            if (budget.instagramPositions.length > 0) {
                                stub.facebookPublisherPlatforms.push(tvlConstantUtils.NETWORK_INSTAGRAM);
                            }

                            if (budget.facebookPositions.length === 0 && budget.instagramPositions.length === 0) {
                                stub.facebookPublisherPlatforms.push(tvlConstantUtils.NETWORK_FACEBOOK);

                                if (campaignStub.devices.includes('Mobile')) {
                                    stub.facebookPublisherPlatforms.push(tvlConstantUtils.NETWORK_INSTAGRAM);
                                }
                            }
                        }

                        if (isDisplay) {
                            stub.adVideo.imageContent = adVideo.imageContent;
                            stub.adVideo.imageName = adVideo.imageName;
                        }

                        return stub;
                    })
                    .value();
                campaignStub.placements = _.concat(campaignStub.placements, placements);
            });

            campaignStub.operatingSystems = vm.campaignForm.operatingSystems;

            // now users and accounts
            campaignStub.users = _.filter(
                vm.campaignForm.selectedUsersOrAccounts,
                function(item) {
                    return item.hasOwnProperty('email');
                }
            );
            campaignStub.accounts = _.filter(
                vm.campaignForm.selectedUsersOrAccounts,
                function(item) {
                    return !item.hasOwnProperty('email');
                }
            );

            campaignStub.locations = vm.campaignForm.targeting.locations;
            campaignStub.interests = vm.campaignForm.targeting.selectedInterests;
            campaignStub.topics = vm.campaignForm.targeting.selectedTopics;
            campaignStub.excludedTopics = vm.campaignForm.targeting.selectedExcludedTopics;

            campaignStub.includedContents = [];
            campaignStub.excludedContents = [];
            /**
             *   This list is printed into the template, but there is other data sent to the API.
             *   Here we can modify the format of contents to show more data to users.
             */
            angular.forEach(vm.campaignForm.targeting.includedContents, function(content) {
                var mappedContent = tvlCampaignUtils.mapContent(content);
                if (content.username) {
                    mappedContent['username'] = content.username;
                }
                campaignStub.includedContents.push(mappedContent);
            });
            angular.forEach(vm.campaignForm.targeting.excludedContents, function(content) {
                var mappedContent = tvlCampaignUtils.mapContent(content);
                if (content.username) {
                    mappedContent['username'] = content.username;
                }
                campaignStub.excludedContents.push(mappedContent);
            });
            return campaignStub;
        }

        /**
         * Download account campaigns list to use in custom names validation
         */
        function getAccountCampaignNames() {
            vm.ui.campaignNamesAreLoading = true;
            var network;
            var accountId = vm.customerAccount.id;
            var isAdwords = vm.campaignForm.networks[tvlConstantUtils.NETWORK_YOUTUBE];
            var isFacebook = vm.campaignForm.networks[tvlConstantUtils.NETWORK_FACEBOOK] || vm.campaignForm.networks[tvlConstantUtils.NETWORK_INSTAGRAM];
            if (isAdwords && isFacebook) {
                network = 'both';
            } else if (isAdwords) {
                network = tvlConstantUtils.NETWORK_ADWORDS;
            } else {
                network = tvlConstantUtils.NETWORK_FACEBOOK;
            }

            var params = {
                'id': accountId,
                'network': network
            };
            AccountCampaignNames.get(params)
                .$promise
                .then(onGetAccountCampaignNamesSuccess);

            function onGetAccountCampaignNamesSuccess(response) {
                if (response[tvlConstantUtils.NETWORK_ADWORDS]) {
                    vm.accountAdwordsCampaigns = response[tvlConstantUtils.NETWORK_ADWORDS]['campaigns'];
                }
                if (response[tvlConstantUtils.NETWORK_FACEBOOK]) {
                    vm.accountFacebookCampaigns = response[tvlConstantUtils.NETWORK_FACEBOOK]['campaigns'];
                }
                vm.ui.campaignNamesAreLoading = false;
            }
        }

        /**
         * Validates network names and disables the final submit buttons if validation has been failed.
         */
        vm.validateNetworkNames = function(customNamesForm) {
            vm.parent.ui.networkNamesError = false;

            var campaignNames = [];

            _.forEach(vm.parent.campaign.customNames, function(customNames) {
                var isValid = (
                    typeof customNames.campaign_name !== 'undefined' &&
                    typeof customNames.adgroup_name !== 'undefined' &&
                    typeof customNames.ad_name !== 'undefined'
                );

                if (customNames.campaign_name) {
                    var campaign_name = customNames.campaign_name.toLowerCase();

                    campaignNames.push(campaign_name);

                    var matchFound = [];
                    if (vm.accountAdwordsCampaigns) {
                        matchFound = vm.accountAdwordsCampaigns.filter(function(item){
                            return item === campaign_name;
                        });
                    }
                    if (matchFound.length === 0 && vm.accountFacebookCampaigns) {
                        matchFound = vm.accountFacebookCampaigns.filter(function(item){
                            return item === campaign_name;
                        });
                    }
                    if (matchFound.length > 0) {
                        customNamesForm.campaign_name.$setValidity('nameExists', false);
                        isValid = false;
                    } else {
                        customNamesForm.campaign_name.$setValidity('nameExists', true);
                    }
                }

                if (!isValid) {
                    vm.parent.ui.networkNamesError = true;
                }
            });

            var grouppedCampaignNames = _.groupBy(campaignNames, function (campaignName) {return campaignName});
            var duplicatedCampaignNames = _.uniq(_.flatten(_.filter(grouppedCampaignNames, function (campaignName) {return campaignName.length > 1})));

            vm.parent.ui.duplicatedNetworkNamesError = duplicatedCampaignNames.length > 0;
        };

        $scope.$on('$destroy', function() {
            for (var i in vm.unregisterFns) {
                vm.unregisterFns[i]();
            }
        });
    }
})();
