(function() {
    'use strict';

    angular
        .module('tvl.campaign')
        .controller('WizardMediaDistributionController', WizardMediaDistributionController);

    WizardMediaDistributionController.$inject = [
        '$scope',
        'tvlUtils',
        'tvlCampaignUtils',
        'tvlAdVideoUtils',
        'tvlWizardInitialPrices',
    ];

    /* @ngInject */
    function WizardMediaDistributionController(
        $scope,
        tvlUtils,
        tvlCampaignUtils,
        tvlAdVideoUtils,
        tvlWizardInitialPrices
    ) {
        var vm = this;
        vm.tvlAdVideoUtils = tvlAdVideoUtils;
        vm.parent = $scope.$parent;
        vm.campaignForm = vm.parent.campaignForm;
        vm.grossBudget = getGrossBudget();

        vm.wizardData = vm.parent.wizardData;
        vm.displayCurrency = vm.parent.wizardData.customerAccount.currency;
        vm.networkPrices = vm.parent.wizardData.networkPrices;

        vm.unregisterFns = [];

        vm.getMediaDistributionSumColspan = getMediaDistributionSumColspan;
        vm.distributeBudgetByNetwork = distributeBudgetByNetwork;

        activate();

        ////////////////

        function activate() {
            vm.unregisterFns = [
                $scope.$on('tvlValidate', function(event, step) {
                    if (step.key === 'mediaDistribution') {
                        vm.parent.$emit('tvlValidationEnd', {'valid' : validate()});
                    }
                }),
                // check that media distribution adds up to the budget of each network on each change
                $scope.$watch('campaignForm.adDistribution', validate, true),
            ];

            vm.mediaDistributionCols = {
                facebook: getMediaDistributionCols('facebook'),
                instagram: getMediaDistributionCols('facebook'),
                youtube: getMediaDistributionCols('youtube'),
                googleDisplay: getMediaDistributionCols('googleDisplay'),
            };

            if (vm.parent.importCampaignId) {
                vm.networkPrices = tvlWizardInitialPrices.getInitialPricingValues(
                    vm.campaignForm,
                    vm.networkPrices,
                    vm.parent.wizardData.customerAccount
                );
            }
        }

        function getGrossBudget() {
            var budget = 0;

            if ( vm.campaignForm.fee !== null && vm.campaignForm.originalGrossBudget) {
                budget = vm.campaignForm.originalGrossBudget;
            } else if (vm.campaignForm.budget) {
                budget = vm.campaignForm.budget;
            }

            return budget;
        }

        /**
         * @returns {int}
         */
        function getMediaDistributionSumColspan() {
            var colspan = vm.campaignForm.countries.length + 2;

            var selectedOperatingSystems = vm.parent.getSelectedOperatingSystems();

            for (var selectedOperatingSystem in selectedOperatingSystems) {
                colspan = colspan + 1;
            }

            return colspan;
        }

        $scope.getMediaDistributionSumForNetwork = function (network) {
            var sum = 0;
            if (vm.campaignForm.adDistribution.hasOwnProperty(network)) {
                var networkDistribution = vm.campaignForm.adDistribution[network];
                for (var videoId in networkDistribution) {
                    var videoDistribution = networkDistribution[videoId];
                    for (var country in videoDistribution) {

                        var selectedOperatingSystems = vm.parent.getSelectedOperatingSystems();

                        for (var selectedOperatingSystem in selectedOperatingSystems) {
                            var distribution = videoDistribution[country][selectedOperatingSystem];

                            if ('undefined' === typeof distribution) {
                                distribution = 0;
                            }

                            sum += distribution;
                        }
                    }
                }
            }
            return tvlUtils.roundToTwoDecimalPlaces(+(sum));
        };

        function getMediaDistributionCols(network) {
            var countriesOs = {};
            var selectedOs = vm.parent.getSelectedOperatingSystems();

            var countries = _.uniq(_.map(_(vm.campaignForm.targeting.locations).filter({
                network: vm.parent.isGoogleNetwork(network) ? 'adwords' : 'facebook'
            }).value(), 'country'));

            _.forEach(countries, function (country) {
                for (var osKey in selectedOs) {
                    var os = JSON.parse(JSON.stringify(selectedOs[osKey]));
                    os.country = country;
                    os.osKey = osKey;
                    countriesOs[osKey+country] = os;
                }
            });

            return countriesOs;
        }

        function distributeBudgetByNetwork(network) {

            var ads = vm.campaignForm.ads[network];
            var distributionCols = vm.mediaDistributionCols[network];
            var distributionKeys = Object.keys(distributionCols);
            var distributionCount = distributionKeys.length;

            var adsCount = ads.length;
            var totalCount = adsCount * distributionCount;

            var totalBudgetForNetwork = vm.campaignForm.media[network];
            var averageBudget = tvlUtils.round(totalBudgetForNetwork / totalCount, 2);
            //sometimes we get no rounded results in view so we force again here
            var firstBudget = tvlUtils.round(totalBudgetForNetwork - (averageBudget * (totalCount - 1)),2);

            if ('undefined' === typeof vm.campaignForm.adDistribution[network]) {
                vm.campaignForm.adDistribution[network] = {};
            }
            for (var ad in ads) {
                for (var countryOs in distributionCols) {
                    var adsId = ads[ad].id;
                    var osCountry = distributionCols[countryOs].country;
                    var osKey = distributionCols[countryOs].osKey;

                    if ('undefined' === typeof vm.campaignForm.adDistribution[network][adsId]) {
                        vm.campaignForm.adDistribution[network][adsId] = {};
                    }

                    if ('undefined' === typeof vm.campaignForm.adDistribution[network][adsId][osCountry]) {
                        vm.campaignForm.adDistribution[network][adsId][osCountry] = {};
                    }

                    var currentBudget = averageBudget;

                    if (firstBudget) {
                        currentBudget = firstBudget;
                        firstBudget = null;
                    }
                    vm.campaignForm.adDistribution[network][adsId][osCountry][osKey] = currentBudget;
                }
            }
        }

        // Validation

        function validate() {

            if (vm.parent.importCampaignId && !vm.grossBudget) {
                return false;
            }

            var totalBudget = Number.parseFloat(vm.campaignForm.budget);
            var totalSum = 0;
            for (var n in vm.campaignForm.adDistribution) {
                $scope.validMediaDistribution[n] = false;

                var totalNetworkBudget = vm.campaignForm.media[n];
                var assignedSum = $scope.getMediaDistributionSumForNetwork(n);

                if (Math.abs(assignedSum - totalNetworkBudget) > 0.01) {
                    $scope.validMediaDistribution[n] = false;
                    $scope.validMediaDistribution.total = false;
                    return false;
                }

                totalSum += assignedSum;
                $scope.validMediaDistribution[n] = true;
            }

            if (Math.abs(totalSum - totalBudget) > 0.01) {
                $scope.validMediaDistribution.total = false;
                return false;
            }

            $scope.validMediaDistribution.total = true;
            return true;
        }

        $scope.$on('$destroy', function () {
            for(var i in vm.unregisterFns) {
                vm.unregisterFns[i]();
            }
        });

        /**
         * Get heading for networks
         * @param {string} network
         * @returns {string}
         */
        $scope.getHeading = function(network) {
            var icons = tvlCampaignUtils.getNetworkIconsForCampaignInWizard(
                vm.campaignForm, network
            );

            var titles = [];
            icons.forEach(function(icon) {
                titles.push(icon.title);
            });

            return titles.join(' & ');
        }
    }
})();
