(function () {
    'use strict';

    angular
        .module('tvl.campaign')
        .directive('tvlRuleConditionSelector', tvlRuleConditionSelector);

    tvlRuleConditionSelector.$inject = ['RecursionHelper'];

    /** @ngInject */
    function tvlRuleConditionSelector(RecursionHelper) {
        var directive = {
            compile: compile,
            link: link,
            restrict: 'E',
            templateUrl: 'campaign/rules/rule-condition-selector.html',
            controller: tvlRuleConditionSelectorController,
            controllerAs: 'vm',
            bindToController: true,
            scope: {
                condition: '=',
                allowCompound: '=?'
            }
        };
        return directive;

        function compile(element) {
            return RecursionHelper.compile(element, link);
        }

        function link(scope, element, attrs, controller, transcludeFn) {
            scope.allowCompound = scope.$eval($(element[0]).attr('allow-compound'));
        }
    }

    tvlRuleConditionSelectorController.$inject = ['tvlRules'];

    /** @ngInject */
    function tvlRuleConditionSelectorController(tvlRules) {
        var vm = this;
        vm.conditions = tvlRules.getAllConditions();

        vm.appendChild = appendChild;
        vm.convertIntoAnd = convertIntoAnd;
        vm.convertIntoOr = convertIntoOr;

        /**
         * Append a new child condition to the current compound condition.
         */
        function appendChild() {
            vm.condition.conditions.push(null);
        }

        /**
         * Convert the current non-compound condition into an AND compound
         * condition.
         */
        function convertIntoAnd() {
            convertInto('and');
        }

        /**
         * Convert the current non-compound condition into an OR compound
         * condition.
         */
        function convertIntoOr() {
            convertInto('or');
        }

        /**
         * Convert the current non-compound condition into a compound condition
         * with the specified operator.
         *
         * @param {String} operator
         */
        function convertInto(operator) {
            var originalCondition = angular.copy(vm.condition);
            var convertedCondition = {
                type: 'compound',
                operator: operator,
                conditions: [originalCondition, null]
            };
            vm.condition = convertedCondition;
        }
    }
})();
