(function() {
    'use strict';

    angular
        .module('tvl.common')
        .controller('CreateNoteModalController', CreateNoteModalController);

    CreateNoteModalController.$inject = ['$uibModalInstance', 'toastr', 'endpointPromise', 'params'];

    /* @ngInject */
    function CreateNoteModalController($uibModalInstance, toastr, endpointPromise, params) {
        var vm = this;

        vm.note = {};

        vm.cancel = cancel;
        vm.createNote = createNote;

        activate();

        ////////////////

        function activate() {}

        function cancel() {
            return $uibModalInstance.dismiss();
        }

        /**
         * Create the rule and resolve this modal.
         */
        function createNote() {
            endpointPromise(Object.assign(params, vm.note))
                .$promise
                .then(onCreateNoteSuccess)
                .catch(onCreateNoteError);
        }

        /**
         * On create note success callback.
         *
         * @param response
         */
        function onCreateNoteSuccess(response) {
            $uibModalInstance.close(vm.note);
            toastr.success('Note has been created.');
        }

        /**
         * On create note error callback.
         *
         * @param response
         */
        function onCreateNoteError(response) {
            if (response.data && response.data.errors) {
                toastr.error(response.data.errors[0].message);
            } else {
                toastr.error('Our apologies, we have been unable create note. Please, try again in a few moments.');
            }
        }
    }
}());
