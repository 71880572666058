(function() {
    'use strict';

    angular
        .module('tvl.common')
        .filter('tvlIsAllDataZero', tvlIsAllDataZeroFilter);

    function tvlIsAllDataZeroFilter() {
        return filter;

        function filter(dataset) {
            var isAllDataZero = true;
            angular.forEach(dataset, function(data) {
                if (data > 0) {
                    isAllDataZero = false;
                }
            });

            return isAllDataZero;
        }
    }
})();
