const moment = require('moment');

(function() {

    angular
        .module('tvl.common')
        .directive('tvlWeatherCalendarWidget', tvlWeatherCalendarWidget);

    tvlWeatherCalendarWidget.$inject = ['Location', 'tvlWeather'];

    /**
     * @ngInject
     */
    function tvlWeatherCalendarWidget(Location, tvlWeather) {
        var directive = {
            link: link,
            restrict: 'E',
            templateUrl: "common/weather-calendar-widget.html",
            scope: {
                entity: '='
            }
        };

        return directive;

        function link(scope) {

            activate();

            function activate() {
                scope.startDate = moment(scope.entity.startDate);
                var initSundayDate = moment(scope.entity.startDate);
                initSundayDate.date(1);
                removeTime(initSundayDate);
                var realStartDate = moment(scope.entity.startDate);
                scope.month = realStartDate.clone().startOf('month');
                // default init month
                scope.month.selected = scope.month;
                scope.endDate = moment(scope.entity.endDate);
                scope.months = [];
                // fill months array when campaign was run
                while (scope.endDate > realStartDate) {
                    scope.months.push(realStartDate.clone().date(1));
                    realStartDate.add(1,'month');
                }
                buildMonth(scope, initSundayDate, scope.month);
                scope.city = {};
                scope.cities = [];
            }

            // get all cities where campaign was run
            if (!scope.entity.locations) {
                scope.entity.locations = [];
            }
            for (var i = 0; i < scope.entity.locations.length; i++) {
                Location.getCities({id: scope.entity.locations[i].id})
                    .$promise
                    .then(onGetCitiesSuccess)
                    .catch(onGetCitiesError)
                    .finally(onGetCitiesFinally);

                function onGetCitiesSuccess(cities) {
                    for (i in cities.items) {
                        scope.cities.push(cities.items[i]);
                    }
                    // Default init city
                    scope.city.selected = scope.cities[0];
                    // when setting default city ask for its weather data to historical from startDate to endDate for default location
                    getWeatherData(scope, scope.startDate.format('Y-MM-DD'), scope.endDate.format('Y-MM-DD'));
                }

                function onGetCitiesError(error) {
                }

                function onGetCitiesFinally() {
                }
            }

            scope.onSelectedMonth= function (selectedItem) {
                scope.month.selected = selectedItem;
                var selected = selectedItem.clone();
                var endDate = selectedItem.clone().endOf('month').endOf('week');
                removeTime(selected);
                buildMonth(scope, selected, selectedItem);
                getWeatherData(scope, selected.format('Y-MM-DD'), endDate.format('Y-MM-DD'));
            };

            scope.onSelectedLocation = function (selectedItem) {
                scope.city.selected = selectedItem;
                var startDate = scope.month.selected.clone().day(0);
                var endDate = scope.month.selected.clone().endOf('month').endOf('week');
                getWeatherData(scope, startDate.format('Y-MM-DD'), endDate.format('Y-MM-DD'));
            };

            scope.select = function(day) {
                scope.selected = day.date;
            };
        }

        function removeTime(date) {
            return date.day(0).hour(0).minute(0).second(0).millisecond(0);
        }

        function buildMonth(scope, start, month) {
            scope.weeks = [];
            var done = false, date = start.clone(), monthIndex = date.month(), count = 0;
            while (!done) {
                scope.weeks.push({ days: buildWeek(date.clone(), month) });
                date.add(1, "w");
                done = count++ > 2 && monthIndex !== date.month();
                monthIndex = date.month();
            }
        }

        function buildWeek(date, month) {
            var days = [];
            for (var i = 0; i < 7; i++) {
                days.push({
                    name: date.format("dd").substring(0, 1),
                    number: date.date(),
                    isCurrentMonth: date.month() === month.month(),
                    isToday: date.isSame(new Date(), "day"),
                    date: date
                });
                date = date.clone();
                date.add(1, "d");
            }
            return days;
        }

        function getWeatherData(scope, startDate, endDate) {
            function onGetHistoricalWeatherSuccess(weather) {
                for (var i in scope.weeks) {
                    for (var j in scope.weeks[i].days) {
                        for (var k in weather) {
                            if (weather[k].date === scope.weeks[i].days[j].date.format('Y-MM-DD')) {
                                scope.weeks[i].days[j].weather = weather[k];
                            }
                        }
                    }
                }
            }

            tvlWeather.getHistoricalWeather(
                scope.city.selected.coordinates.lat,
                scope.city.selected.coordinates.lng,
                startDate,
                endDate
            ).then(onGetHistoricalWeatherSuccess);
        }
    }
})();
