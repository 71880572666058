(function() {
	angular
		.module('filterNumber',[])
		.filter('formatNumber',function() {
			return function(number, decimals) {
				abs = Math.abs(number);
				if (abs >= Math.pow(10, 12)) {
        			number = (number / Math.pow(10, 12)).toFixed(1) + " T";
				} else if ((abs < Math.pow(10, 12)) && (abs >= Math.pow(10, 9))) {
					number = (number / Math.pow(10, 9)).toFixed(1) + " G";
				} else if ((abs < Math.pow(10, 9)) && (abs >= Math.pow(10, 6))) {
					number = (number / Math.pow(10, 6)).toFixed(1) + " M";
				} else if ((abs < Math.pow(10, 6)) && (abs >= Math.pow(10, 3))) {
					number = (number / Math.pow(10, 3)).toFixed(1) + " K";
				} else if(number % 1 != 0) {
					decimals = decimals || 2;
					number = (number / Math.pow(10, 0)).toFixed(decimals);
				}

				return number;
			}
		})
		//TODO refactor the above method.
		.filter('formatNumberWithoutDecimals',function() {
			return function(number) {
				abs = Math.abs(number);
				if (abs >= Math.pow(10, 12)) {
					number = (number / Math.pow(10, 12)).toFixed(0) + " T";
				} else if ((abs < Math.pow(10, 12)) && (abs >= Math.pow(10, 9))) {
					number = (number / Math.pow(10, 9)).toFixed(0) + " G";
				} else if ((abs < Math.pow(10, 9)) && (abs >= Math.pow(10, 6))) {
					number = (number / Math.pow(10, 6)).toFixed(0) + " M";
				} else if ((abs < Math.pow(10, 6)) && (abs >= Math.pow(10, 3))) {
					number = (number / Math.pow(10, 3)).toFixed(0) + " K";
				} else if(number % 1 != 0) {
					decimals = decimals || 2;
					number = (number / Math.pow(10, 0)).toFixed(0);
				}

				return number;
			}
		});
})();
