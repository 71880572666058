(function() {
    'use strict';

    angular
        .module('tvl.administration')
        .directive('usersForm', UserForm);

    UserForm.$inject = [
        '$state',
        '$uibModal',
        '$window',
        'toastr',
        'Upload',
        'Account',
        'Company',
        'User',
        'languages',
        'roles',
        'orderByFilter',
        'tvlSession',
        'UserPermissionsChecker',
        'UserRolesChecker',
        'resourceResponse',
        'Permissions',
        'UserRoles',
        'tvlSweetAlert',
        'ResetPassword'
    ];

    /** @ngInject */
    function UserForm(
        $state,
        $uibModal,
        $window,
        toastr,
        Upload,
        Account,
        Company,
        User,
        languages,
        roles,
        orderBy,
        tvlSession,
        UserPermissionsChecker,
        UserRolesChecker,
        resourceResponse,
        Permissions,
        UserRoles,
        tvlSweetAlert,
        ResetPassword
    ) {
        return {
            restrict: "E",
            scope: {
                user: '=',
                onSaveSuccess: '&',
                onSaveError: '&',
                onSaving: '&',
                onFormValid: '&',
                onFormInvalid: '&'
            },
            templateUrl: "users/users-form.html",
            link: function ($scope, element, attrs) {
                var user = $scope.user;

                $scope.CHECKBOX_ENABLED = 'enabled';
                $scope.CHECKBOX_DISABLED = 'disabled';
                $scope.CHECKBOX_MIXED = 'mixed';

                $scope.COMPANY_ENABLED = 'enabled';
                $scope.COMPANY_DISABLED = 'disabled';

                $scope.showPermissions = false;
                $scope.activeTab = 0;

                $scope.companies = [];
                $scope.accounts = [];
                $scope.languages = languages;
                $scope.loggedUser = null;
                $scope.isPasswordPrefilled = null !== _.get(user, 'id', null);
                $scope.permissionsMatrix = null;
                $scope.permissionsResource = {};

                $scope.userModel = {
                    id: _.get(user, 'id', null),
                    name: _.get(user, 'name', ''),
                    email: _.get(user, 'email', ''),
                    enabled: _.get(user, 'enabledOnDocument', false),
                    password: _.get(user, 'password', null),
                    company: _.get(user, 'company', null),
                    locale: _.get(user, 'locale', ''),
                    apiKey: _.get(user, 'apiKey', null),
                    roles: _.get(user, 'roles', []),
                    isGlobal: _.get(user, 'isGlobal', false),
                    companyFullAccess: _.get(user, 'companyFullAccess', false),
                    accounts: _.get(user, 'accounts', []),
                    accountsWithContentStatsAccess: getUserAccountsWithContentStatsAccess(),
                    thumbnailUrl: _.get(user, 'thumbnailUrl', null),
                    newsletterFrequency: {
                        formatReport: _.get(user, 'newsletterFrequency.formatReport', []),
                        endOfCampaignReport: _.get(user, 'newsletterFrequency.endOfCampaignReport', false),
                        campaignReport: _.get(user, 'newsletterFrequency.campaignReport', false),
                        timezone: _.get(user, 'newsletterFrequency.timezone', ''),
                        timeSlot: _.get(user, 'newsletterFrequency.timeSlot', '')
                    },
                    permissions: angular.copy(_.get(user, 'permissions', {})),
                };

                tvlSession.getSession().then(function(user) {
                    $scope.loggedUser = user;
                    init();
                });

                $scope.userModel.companies = getCompaniesByAccounts([], 'accounts');
                $scope.userModel.companies = getCompaniesByAccounts($scope.userModel.companies , 'accountsWithContentStatsAccess');
                $scope.companies = [];

                if ($scope.userModel.companies.length === 0) {
                    cleanVisibleAccountsAndAddMainCompany();
                }

                $scope.onCompanyChange = onCompanyChange;
                $scope.refreshCompanyList = refreshCompanyList;
                $scope.refreshAccountList = refreshAccountList;
                $scope.shouldShowIsGlobalOption = shouldShowIsGlobalOption;
                $scope.shouldDisableIsGlobalOption = shouldDisableIsGlobalOption;
                $scope.isClientSelected = isClientSelected;
                $scope.shouldDisableCompanyFullAccessOptionAccounts = shouldDisableCompanyFullAccessOptionAccounts;
                $scope.onAccountChange = onAccountChange;
                $scope.shouldDisableVisibleAccounts = shouldDisableVisibleAccounts;
                $scope.onIsGlobalChange = onIsGlobalChange;
                $scope.shouldDisableAccountsWithContentStatsAccess = shouldDisableAccountsWithContentStatsAccess;
                $scope.onThumbnailChange = onThumbnailChange;
                $scope.onRoleChange = onRoleChange;
                $scope.saveUser = saveUser;
                $scope.onChangeCompanyFullAccess = onChangeCompanyFullAccess;
                $scope.isNewUser = isNewUser;
                $scope.shouldHideCompanyDisabledInfo = shouldHideCompanyDisabledInfo;
                $scope.shouldDisableUserEnabledOption = shouldDisableUserEnabledOption;
                $scope.hasPermissionOverPermissions = hasPermissionOverPermissions;

                $scope.onVisibilityCompanyChange = onVisibilityCompanyChange;
                $scope.onVisibilityCompanyRemove = onVisibilityCompanyRemove;
                $scope.onVisibilityCompanyAdd = onVisibilityCompanyAdd;
                $scope.checkPermissionParent = checkPermissionParent;
                $scope.checkPermissionChild = checkPermissionChild;
                $scope.permissionTouched = permissionTouched;
                $scope.hasCustomRole = hasCustomRole;
                $scope.clearPermissions = clearPermissions;
                $scope.sendResetPasswordEmail = sendResetPasswordEmail;

                // $scope.$watch('userForm.roles', function(newVal, oldVal) {
                //     $scope.onRoleChange();
                // });

                function init() {
                    refreshCompanyList();
                    refreshAccountList();
                    refreshRoles();
                    getPermissionsMatrix();
                }

                /**
                 * @param companies
                 * @param toSearch
                 * @return {*}
                 */
                function getCompaniesByAccounts(companies, toSearch) {
                    _.forEach(_.get($scope.userModel, toSearch, []), function (account) {
                        var companyIndex = _.findIndex(companies, function (o) {
                            return o.id === account.company.id;
                        });
                        if (-1 === companyIndex) {
                            var company = getEmptyVisibiltyCompany(account.company);
                            company[toSearch].push(account);
                            companies.push(company);
                        } else {
                            company = companies[companyIndex];
                            company[toSearch].push(account);
                            companies[companyIndex] = company;
                        }
                    });
                    return companies;
                }

                function refreshCompanyList(search) {
                    var params = {
                        term: search || null
                    };
                    return Company.get(params).$promise
                        .then(onGetCompaniesSuccess, onGetCompaniesError);

                    function onGetCompaniesSuccess(companies) {
                        $scope.companies = companies.items;
                    }

                    function onGetCompaniesError(error) {
                        toastr.error('Our apologies, we have been unable to recover your companies. Please, try again later.');
                    }
                }

                function onCompanyChange() {
                    refreshAccountList(null, $scope.userModel.company);
                    cleanVisibleAccountsAndAddMainCompany();
                    cleanRoles();
                    clearPermissions();
                    refreshRoles();
                    refreshEnabled();
                }

                function onVisibilityCompanyChange(company, $index) {
                    company.accounts = [];
                    $scope.userModel.companies[$index].name = company.name;
                    $scope.userModel.companies[$index].id = company.id;
                    $scope.userModel.companies[$index].accounts = [];
                    $scope.userModel.companies[$index].accountsWithContentStatsAccess = [];
                }

                function onVisibilityCompanyRemove($index) {
                    if ($scope.userModel.companies.length <= 1) {
                        tvlSweetAlert.error(
                            'Can not perform action',
                            'You can not remove the last company.'
                        );

                        return;
                    }

                    var company = $scope.userModel.companies[$index];
                    if (company.accounts.length > 0) {
                        onVisibilityCompanyRemoveWithAccounts($index);
                    } else {
                        $scope.userModel.companies.splice($index, 1);
                    }
                }

                function onVisibilityCompanyRemoveWithAccounts($index) {
                    var company = $scope.userModel.companies[$index];
                    var title = 'Remove all visible accounts of '+ company.name +'?';
                    var msg = 'Are you sure you want to remove all visible accounts of '+ company.name +'?';

                    return tvlSweetAlert
                        .confirm(title, msg, 'Yes, remove all', 'No')
                        .then(function(){
                            $scope.userModel.companies.splice($index, 1);
                        });
                }

                function onVisibilityCompanyAdd() {
                    var companyToAdd = getEmptyVisibiltyCompany();
                    if ($scope.userModel.companies.length === 0) {
                        companyToAdd = getEmptyVisibiltyCompany($scope.userModel.company);
                    }

                    $scope.userModel.companies.push(companyToAdd);

                    setTimeout(function() {
                        $scope.activeTab = $scope.userModel.companies.length - 1;
                    }, 0);
                }

                function getEmptyVisibiltyCompany(company) {
                    return {
                        name: company ? company.name : null,
                        id: company ? company.id : null,
                        accounts: [],
                        accountsWithContentStatsAccess: []
                    }
                }

                function refreshAccountList(search, company) {
                    var id = null;
                    if (company) {
                        id = company.id;
                    }
                    var params = {
                        term: search || null,
                        'company[]': id || null,
                        items_per_page: 1000

                    };
                    return Account.get(params).$promise
                        .then(onGetAccountsSuccess, onGetAccountsError);

                    function onGetAccountsSuccess(accounts) {
                        $scope.accounts = accounts.items;
                    }

                    function onGetAccountsError(error) {
                        toastr.error('Our apologies, we have been unable to recover your accounts. Please, try again later.');
                    }
                }

                function sendResetPasswordEmail() {
                    User.sendPasswordResetEmail({
                        username: $scope.userModel.email,
                    }).$promise
                        .then(onSendResetEmailSuccess, onSendResetEmailError);

                    function onSendResetEmailSuccess() {
                        toastr.success('An email has been sent to ' + $scope.userModel.email + ' to reset password');
                    }

                    function onSendResetEmailError(error) {
                        if(error.status === 401) {
                            toastr.error(error.data.errors[0].message + ' Email could not be sent to ' + $scope.userModel.email);
                        } else {
                            toastr.error(error.data.errors[0].message + ' Email could not be sent to ' + $scope.userModel.email + ', try again later');
                        }
                    }
                }

                function onChangeCompanyFullAccess() {
                    if($scope.userModel.companyFullAccess) {
                        cleanVisibleAccountsAndAddMainCompany();
                    }
                }

                function cleanRoles() {
                    $scope.userModel.roles = [];
                    $scope.userModel.companyFullAccess = false;
                    $scope.userModel.isGlobal = false;
                }

                function refreshRoles() {
                    $scope.roles = _.clone(roles, true);
                    if (false === _.isEmpty($scope.userModel.roles)) {
                        $scope.roles = getCompatibleRoles($scope.userModel.roles);
                    }

                    var userCanViewRoleSupport = UserPermissionsChecker.userHasPermission(
                        $scope.loggedUser, 'User.view_roles_ROLE_SUPPORT'
                    );

                    if (false === isMainCompanySelected() || false === userCanViewRoleSupport) {
                        $scope.roles = _.remove($scope.roles, function(n) {
                            return 'ROLE_SUPPORT' !== n.value;
                        });
                    }

                    var userCanViewRoleAdmin = UserPermissionsChecker.userHasPermission(
                        $scope.loggedUser, 'User.view_roles_ROLE_ADMIN'
                    );
                    if (false === isMainCompanySelected() || false === userCanViewRoleAdmin) {
                        $scope.roles = _.remove($scope.roles, function(n) {
                            return 'ROLE_ADMIN' !== n.value;
                        });
                    }

                    var userCanViewRoleBrandSafetyAdmin = UserPermissionsChecker.userHasPermission(
                        $scope.loggedUser, 'User.view_roles_ROLE_BRAND_SAFETY_ADMIN'
                    );

                    if (false === isMainCompanySelected() || false === userCanViewRoleBrandSafetyAdmin) {
                        $scope.roles = _.remove($scope.roles, function(n) {
                            return 'ROLE_BRAND_SAFETY_ADMIN' !== n.value;
                        });
                    }

                    if(null !== $scope.userModel.company && false === isMainCompanySelected()){
                        $scope.roles = _.remove($scope.roles, function(n){
                            return 'ROLE_FINANCE' !== n.value;
                        });
                    }
                }

                /**
                 * @param selectedRoles
                 * @returns {[]}
                 */
                function getCompatibleRoles(selectedRoles) {
                    var compatibleRoles = _.filter(roles, function(role) {
                        return selectedRoles.indexOf(role.value) >= 0;
                    });

                    _.forEach(roles, function (role) {
                        // avoid if role already added
                        if (selectedRoles.indexOf(role.value) >= 0) {
                            return;
                        }
                        
                        // if "compatible_with" key exists but empty then add it 
                        if (_.isEmpty(role.compatible_with) && _.isArray(role.compatible_with)) {
                            compatibleRoles.push(role);
                            return;
                        }

                        // if "compatible_with" is null then its not allowed to use with any other role.
                        if (_.isNull(role.compatible_with)) {
                            return;
                        }

                        // If role is compatible with all selected roles then we can use it.
                        if (checkIfRoleIsCompatibleWithSelected(role, selectedRoles)) {
                            compatibleRoles.push(role);
                        }
                    });

                    return compatibleRoles;
                }

                /**
                 * @param role
                 * @param selectedRoles
                 *
                 * @returns {boolean}
                 */
                function checkIfRoleIsCompatibleWithSelected(role, selectedRoles) {
                    var isCompatible = false;
                    var rolesCount = 0;

                    // Search if selected role is compatible with roles.
                    _.forEach(selectedRoles, function(selectedRole) {

                        if (role.compatible_with.indexOf(selectedRole) >= 0) {
                            rolesCount++;
                        }
                    });

                    // If role is compatible with all selected roles then we can use it.
                    if (rolesCount === selectedRoles.length) {
                        isCompatible = true;
                    }
                    return isCompatible;
                }

                function refreshEnabled() {
                    if (false === isNewUser() && false === isCompanyEnabled()) {
                        $scope.userModel.enabled = _.get(user, 'enabledOnDocument', false);
                    }
                }

                function cleanVisibleAccounts() {
                    $scope.userModel.accounts = [];
                    $scope.userModel.accountsWithContentStatsAccess = [];
                    $scope.userModel.companies = [];
                }

                function shouldShowIsGlobalOption() {
                    return $scope.loggedUser.isGlobal;
                }

                function shouldDisableIsGlobalOption() {
                    return UserRolesChecker.isClient($scope.userModel) ||
                        UserRolesChecker.isAdmin($scope.userModel) ||
                        false === isMainCompanySelected() ||
                        UserRolesChecker.isSupport($scope.userModel);
                }

                function isClientSelected() {
                    return UserRolesChecker.isClient($scope.userModel);
                }

                function shouldDisableCompanyFullAccessOptionAccounts() {
                    return $scope.userModel.isGlobal ||
                        UserRolesChecker.isCompanyManager($scope.userModel) ||
                        UserRolesChecker.isClient($scope.userModel);
                }

                function isMainCompanySelected() {
                    return _.get($scope.userModel, 'company.isMainCompany', false);
                }

                function shouldDisableUserEnabledOption() {
                    return !isCompanyEnabled();
                }

                function shouldHideCompanyDisabledInfo() {
                    return isCompanyEnabled();
                }

                function isCompanyEnabled() {
                    return $scope.COMPANY_ENABLED ===_.get($scope.userModel, 'company.status', 'disabled');
                }

                function onAccountChange(company) {
                    clearNonVisibleAccountsInContentStats(company);
                }

                function clearNonVisibleAccountsInContentStats(company) {
                    var accounts = [];

                    for (var i = 0; i < company.accountsWithContentStatsAccess.length; i++) {
                        var accountWithAccess = company.accountsWithContentStatsAccess[i];

                        for (var x = 0; x < company.accounts.length; x++) {
                            if (company.accounts[x].id === accountWithAccess.id) {
                                accounts.push(accountWithAccess);
                            }
                        }
                    }

                    company.accountsWithContentStatsAccess = accounts;
                }

                function shouldDisableVisibleAccounts() {
                    return $scope.userModel.companyFullAccess ||
                        $scope.userModel.isGlobal;
                }

                function onIsGlobalChange() {
                    if ($scope.userModel.isGlobal) {
                        cleanVisibleAccountsAndAddMainCompany();
                        $scope.userModel.companyFullAccess = true;
                    }
                }

                function getUserAccountsWithContentStatsAccess() {
                    var accounts = [];
                    var accountPermissions = _.get(user, 'accountsPermissions', []);

                    for (var i = 0; i < accountPermissions.length; i++) {
                        var accountPermission = accountPermissions[i];

                        const hasContentStatsPermission = UserPermissionsChecker.userHasAccountPermission(
                            user,
                            accountPermission.account.id,
                            'Account.view_content_stats'
                        );

                        if (hasContentStatsPermission && UserRolesChecker.isClient(user)) {
                            accounts.push(accountPermission.account);
                        }
                    }

                    return accounts;
                }

                function shouldDisableAccountsWithContentStatsAccess() {
                    return false === UserRolesChecker.isClient($scope.userModel) ||
                        $scope.userModel.companyFullAccess ||
                        $scope.userModel.isGlobal;
                }

                $scope.$on("UsersForm.Save", function(evt, data){
                    $scope.saveUser();
                });

                function isNewUser() {
                    return _.isNil(user);
                }

                function saveUser() {
                    $scope.onSaving();

                    var userData = {
                        id: $scope.userModel.id,
                        name: $scope.userModel.name,
                        email: $scope.userModel.email,
                        company: _.get($scope.userModel, 'company.id', null),
                        locale: $scope.userModel.locale,
                        thumbnailUrl: $scope.userModel.thumbnailUrl,
                        roles: $scope.userModel.roles,
                        isGlobal: $scope.userModel.isGlobal,
                        companyFullAccess: $scope.userModel.companyFullAccess,
                        accountIds: getAccountPermissionsData($scope.userModel.companies),
                        thumbnail: $scope.userModel.thumbnail,
                        newsletterFrequency: $scope.userModel.newsletterFrequency
                    };

                    userData.enabled = true;
                    if (false === isNewUser()) {
                        userData.password = $scope.userModel.password;
                        userData.enabled = $scope.userModel.enabled;
                        userData.apiKey = $scope.userModel.apiKey;
                    }

                    if (hasCustomRole()) {
                        userData.customPermissions = {};
                        userData.roles = [];
                        _.forEach($scope.userModel.permissions, function(permissions, resourceName) {
                            _.forEach(permissions, function(hasPermission, permissionName) {
                                if (hasPermission) {
                                    if (!userData.customPermissions[resourceName]) {
                                        userData.customPermissions[resourceName] = {};
                                    }
                                    userData.customPermissions[resourceName][permissionName] = true;
                                }
                            });
                        });
                    }

                    var user = new User(userData);

                    return user.$save()
                        .then(onSaveSuccess, onSaveError);

                    function onSaveSuccess(user) {
                        toastr.success('User ' + user.name + ' has been successfully saved.');
                        $scope.onSaveSuccess({user: user});
                    }

                    function onSaveError(error) {
                        resourceResponse.error(error);
                        $scope.onSaveError({error: error});
                    }
                }

                /**
                 * Deep diff between two object, using lodash
                 * @param  {Object} object Object compared
                 * @param  {Object} base   Object to compare with
                 * @return {Object}        Return a new object who represent the diff
                 */
                function difference(object, base) {
                    function changes(object, base) {
                        return _.transform(object, function(result, value, key) {
                            if (!_.isEqual(value, base[key])) {
                                result[key] = (_.isObject(value) && _.isObject(base[key])) ? changes(value, base[key]) : value;
                            }
                        });
                    }

                    return changes(object, base);
                }

                /**
                 * @param sourceCompanies
                 * @return {{}}
                 */
                function getAccountPermissionsData(sourceCompanies) {
                    var accounts = {};

                    _.forEach(sourceCompanies, function(company) {
                        accounts = _.merge(accounts, getAccountsWithContentStats(company));
                    });

                    return accounts;
                }

                /**
                 * @param company
                 * @return {{}}
                 */
                function getAccountsWithContentStats(company) {
                    var accounts = {};
                    var sourceAccounts = company.accounts;
                    for (var i = 0; i < sourceAccounts.length; i++) {
                        var accountId = sourceAccounts[i].id;
                        var permissionContent = {};

                        var accountWithContentStats = _.find(company.accountsWithContentStatsAccess, function (o) {
                            return o.id === accountId;
                        });
                        if (accountWithContentStats) {
                            permissionContent = {'Account': {'view_content_stats': true}};
                        }

                        accounts[accountId] = permissionContent;
                    }

                    return accounts;
                }

                function onThumbnailChange($files, $file) {
                    if ($file === null) {
                        $scope.userModel.thumbnail = null;
                    } else {
                        Upload.base64DataUrl($files).then(onUploadFileSuccess);
                    }

                    function onUploadFileSuccess(urls) {
                        $scope.userModel.thumbnail = urls[0];
                    }
                }

                function onRoleChange() {
                    $scope.userModel.companyFullAccess = false;
                    $scope.userModel.isGlobal = false;

                    if (_.isEmpty($scope.userModel.roles)) {
                        cleanVisibleAccounts();
                    }

                    if (false === UserRolesChecker.isClient($scope.userModel)) {
                        cleanVisibleAccountsAndAddMainCompany();
                    }

                    if (UserRolesChecker.isCompanyManager($scope.userModel)) {
                        $scope.userModel.companyFullAccess = true;
                        cleanVisibleAccountsAndAddMainCompany();
                        $scope.userModel.isGlobal = false;
                    }

                    if (UserRolesChecker.isAdmin($scope.userModel) || UserRolesChecker.isSupport($scope.userModel)) {
                        $scope.userModel.companyFullAccess = true;
                        cleanVisibleAccountsAndAddMainCompany();
                        $scope.userModel.isGlobal = true;
                    }

                    clearPermissions();
                    refreshRoles();
                }

                function cleanVisibleAccountsAndAddMainCompany() {
                    cleanVisibleAccounts();
                    $scope.userModel.companies.push(getEmptyVisibiltyCompany($scope.userModel.company));
                }

                $scope.$watchGroup(['userForm.$dirty', 'userForm.$valid'], function () {
                    if ($scope.userForm.$valid && $scope.userForm.$dirty) {
                        $scope.onFormValid();
                    } else {
                        $scope.onFormInvalid();
                    }
                }, true);

                /**
                 * Get permissions matrix from api.
                 */
                function getPermissionsMatrix() {
                    if ($scope.loggedUser.hasPermission('User.view_user_permissions')) {
                        Permissions.get()
                            .$promise
                            .then(function(response) {
                                $scope.permissionsMatrix = response.data;
                                getUserRolesPermissions();
                            });
                    }
                }

                function checkPermissionParent(resourceName, parent) {
                    if (false === _.get($scope.userModel.permissions, resourceName+'.'+parent.key, false)) {
                        _.forEach(parent.children, function(child) {
                            $scope.userModel.permissions[resourceName][child.key] = false;
                        });
                    }
                }

                function checkPermissionChild(resourceName, child, parent) {
                    if (_.get($scope.userModel.permissions, resourceName+'.'+child.key, false)) {
                        $scope.userModel.permissions[resourceName][parent.key] = true;
                    }
                }

                function permissionTouched() {
                    $scope.userModel.roles = [];
                }

                function getUserRolesPermissions() {
                    var cleanRoles = _.map($scope.userModel.roles, function clean(role) {
                        return role.substr(5);
                    });

                    if (_.isEmpty(cleanRoles)) {
                        return;
                    }

                    UserRoles.mixPermissions({'roles[]': cleanRoles})
                        .$promise
                        .then(function (response) {
                            $scope.userModel.permissions = response.toJSON();
                        });
                }

                function hasCustomRole() {
                    var checked = 0;
                    var dontHasRoles = _.isEmpty($scope.userModel.roles);

                    _.forEach($scope.userModel.permissions, function(permissions, resourceName) {
                        _.forEach(permissions, function(hasPermission, permissionName) {
                            if (hasPermission) {
                                checked += 1;
                            }
                        });
                    });

                    var hasPermissions = checked > 0;

                    return hasPermissions && dontHasRoles;
                }

                function clearPermissions() {
                    $scope.userModel.permissions = {};
                    refreshRoles();
                    getUserRolesPermissions();
                }

                function hasPermissionOverPermissions() {
                    return $scope.loggedUser.hasPermission('User.update_user_permissions');
                }
            }
        };
    }
}());
