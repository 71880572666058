const moment = require('moment');
const Highcharts = require('highcharts/highstock.src');

(function() {
    'use strict';

    angular
        .module('tvl.campaign')
        .controller('CampaignReportController', CampaignReportController);

    CampaignReportController.$inject = [
        '$scope',
        '$rootScope',
        '$timeout',
        '$q',
        '$sce',
        '$uibModal',
        'tvlSweetAlert',
        'toastr',
        'tvlUi',
        'tvlSession',
        'tvlYoutube',
        'tvlCampaignUtils',
        'tvlAdVideoUtils',
        'Campaign',
        'Placement',
        'PlacementStat',
        'ContentStat',
        'ContentStatPerformance',
        'Video',
        'Channel',
        'campaign',
        'operatingSystemService',
        'PlacementAverageStats',
        'reportDownloader'
    ];

    /* @ngInject */
    function CampaignReportController(
        $scope,
        $rootScope,
        $timeout,
        $q,
        $sce,
        $uibModal,
        tvlSweetAlert,
        toastr,
        tvlUi,
        tvlSession,
        tvlYoutube,
        tvlCampaignUtils,
        tvlAdVideoUtils,
        Campaign,
        Placement,
        PlacementStat,
        ContentStat,
        ContentStatPerformance,
        Video,
        Channel,
        campaign,
        operatingSystemService,
        PlacementAverageStats,
        reportDownloader
    ) {
        var vm = this;

        vm.MIN_CITIES_FOR_POWERPOINT_REPORTS_MODAL = 5;
        vm.CAMPAIGN_REPORT_URL = '/api/campaigns/_CAMPAIGNID_/report?format=_FORMAT_&startDate=_STARTDATE_&endDate=_ENDDATE_&viewType=_VIEWTYPE_';
        vm.CAMPAIGN_NONVIDEO_REPORT_URL = '/api/campaigns/nonvideo/report?campaignId=_CAMPAIGNID_&format=_FORMAT_&startDate=_STARTDATE_&endDate=_ENDDATE_&viewType=_VIEWTYPE_';
        vm.CAMPAIGN_IS_REPORT_ALLOWED_URL = '/api/campaigns/_CAMPAIGNID_/is_report_download_allowed';

        vm.tvlAdVideoUtils = tvlAdVideoUtils;

        vm.entity = {};
        vm.availableFilters = {
            networks: [],
            countries: [],
            placements: []
        };
        vm.campaign = campaign;
        vm.operatingSystemService = operatingSystemService;
        vm.entity = campaign;
        vm.barCharts = {};
        vm.doughnutCharts = {};
        vm.lineCharts = {};
        vm.mapCharts = {};
        vm.chartInitFns = {
            line: {
                quartiles: getQuartilesLineChartData,
                tenAndThirtySecondViews: getTenAndThirtySecondViewsLineChartData,
                analyticsViews: getAnalyticsViewsLineChartData,
                analyticsEngagement: getAnalyticsEngagementLineChartData
            }
        };
        vm.filtering = {
            network: null,
            country: null,
            operatingSystem: null,
            placement: null
        };
        vm.networks = {
            adwords: {
                name: 'Google',
                key: 'adwords',
                icon: 'google'
            },
            facebook: {
                name: 'Facebook',
                key: 'facebook',
                icon: 'facebook'
            },
            instagram: {
                name: 'Instagram',
                key: 'instagram',
                icon: 'instagram'
            }
        };
        vm.placement = null;
        vm.placementStats = null;
        vm.contentStats = null;
        vm.tvlUi = tvlUi;
        vm.lastRefreshTime = null;
        vm.ui = {
            refreshingData: false,
            filteringShown: false,
            gettingVideo: false,
            gettingPage: false,
            updatingStats: false,
            analyticsDataAvailable: false,
            showActiveViewStats: false,
            showCpaMetrics: false,
            downloading: false,
            isFacebookExternalAsset: isFacebookExternalAsset,
            showVideoChannelInfo: showVideoChannelInfo,
        };
        vm.userCanRefreshData = false;
        vm.userCanSwitchToTraderView = false;
        vm.user = null;
        vm.viewType = 'customer';
        vm.traderViewActive = false;
        vm.video = null;
        vm.image = {
            imageContent: ""
        };
        vm.quartileDeviceChartData = {};
        vm.demographicData = {
            "18-24": {},
            "25-34": {},
            "35-44": {},
            "45-54": {},
            "55-64": {},
            "65 or more": {},
            "Undetermined": {}
        };
        vm.reactions = tvlUi.getFacebookReactions();
        vm.interestsCloudData = [];
        vm.interestsCloudColors = tvlUi.getThemeColorsForWordsCloud();
        vm.datepicker = getDefaultDatepicker();
        vm.datepickerOptions = {
            opens: 'left',
            locale: {
                format: "MMM DD, YYYY"
            },
            minDate: vm.datepicker.startDate,
            maxDate: vm.datepicker.endDate
        };
        vm.startDate = vm.datepicker.startDate;
        vm.endDate = vm.datepicker.endDate;
        vm.showStats = true;
        vm.tvlCampaignUtils = tvlCampaignUtils;
        vm.powerpointReportModalCities = [];
        vm.isPowerpointSettingsModalRequired = false;
        vm.biddingVsEffectiveChartTitle = 'bCPA vs eCPA';

        vm.chooseOperatingSystem = chooseOperatingSystem;
        vm.chooseCountry = chooseCountry;
        vm.chooseNetwork = chooseNetwork;
        vm.choosePlacement = choosePlacement;
        vm.clearFilters = clearFilters;
        vm.getAvailableCountries = getAvailableCountries;
        vm.getAvailableOperatingSystems = getAvailableOperatingSystems;
        vm.getAvailableNetworks = getAvailableNetworks;
        vm.getAvailablePlacements = getAvailablePlacements;
        vm.getBudgetConsumption = getBudgetConsumption;
        vm.getCountryFlag = getCountryFlag;
        vm.getDemographicData = getDemographicData;
        vm.getIncrement = getIncrement;
        vm.getLocations = getLocations;
        vm.getMetric = getMetric;
        vm.getQuartileDeviceData = getQuartileDeviceData;
        vm.refresh = refresh;
        vm.thereIsGenderDistribution = thereIsGenderDistribution;
        vm.openPowerpointSettingsModal = openPowerpointSettingsModal;
        vm.getContentStats = getContentStats;
        vm.openDownloadTraderReportModal = openDownloadTraderReportModal;
        vm.reportDownload = reportDownload;
        vm.drawBiddingVsEffectiveChart = drawBiddingVsEffectiveChart;

        vm.contentAnalysisChartsOptions = {
            'percentage': {
                scales: {
                    xAxes: [{
                        ticks: {
                            beginAtZero: true,
                            min: 0,
                            max: 100,
                            callback: function(value) {
                                return value + "%"
                            }
                        },
                        scaleLabel: {
                            display: true,
                            labelString: "Percentage"
                        },
                        gridLines: {
                            display: false
                        }
                    }],
                    yAxes: [{
                        display: true,
                        gridLines: {
                            display: false
                        }
                    }]
                },
                legend: {
                    display: true
                },
                responsive: true,
                maintainAspectRatio: false
            },
            'default': {
                scales: {
                    xAxes: [{
                        ticks: {
                            beginAtZero: true,
                        },
                        gridLines: {
                            display: false
                        }
                    }],
                    yAxes: [{
                        display: true,
                        gridLines: {
                            display: false
                        }
                    }]
                },
                legend: {
                    display: true
                },
                responsive: true,
                maintainAspectRatio: false
            }
        };

        vm.contentAnalysisCharts = {
            impressions: {
                data: [],
                labels: [],
                series: ['Theviralab dynamic list', 'Your static list'],
                colors: tvlUi.getThemeColors(),
                options: vm.contentAnalysisChartsOptions['default'],
            },
            views: {
                data: [],
                labels: [],
                series: ['Theviralab dynamic list', 'Your static list'],
                colors: tvlUi.getThemeColors(),
                options: vm.contentAnalysisChartsOptions['default'],
            },
            reached: {
                data: [],
                labels: [],
                series: ['Theviralab dynamic list', 'Your static list'],
                colors: tvlUi.getThemeColors(),
                options: vm.contentAnalysisChartsOptions['percentage']
            },
            cpv: {
                data: [],
                labels: [],
                series: ['Theviralab dynamic list', 'Your static list'],
                colors: tvlUi.getThemeColors(),
                options: vm.contentAnalysisChartsOptions['default'],
            },
            ctr: {
                data: [],
                labels: [],
                series: ['Theviralab dynamic list', 'Your static list'],
                colors: tvlUi.getThemeColors(),
                options: vm.contentAnalysisChartsOptions['default'],
            },
            vtr: {
                data: [],
                labels: [],
                series: ['Theviralab dynamic list', 'Your static list'],
                colors: tvlUi.getThemeColors(),
                options: vm.contentAnalysisChartsOptions['percentage'],
            }
        };

        activate();

        ////////////////

        function activate() {
            // This is needed to auto play and pause an Instagram stories video
            // when we click on its thumbnail.
            $('#InstagramStoryVideoModal').on('shown.bs.modal', function() {
                $('#InstagramStoryVideo')[0].play();
            });
            $('#InstagramStoryVideoModal').on('hidden.bs.modal', function() {
                $('#InstagramStoryVideo')[0].pause();
            });

            resetCharts();
            checkUserPermissions();

            vm.entity = getAggregatedStats(campaign);
            vm.unregisterDatepickerWatcher = $scope.$watch('vm.datepicker', onDateRangeChanged);
            vm.unregisterFilteringWatcher = $scope.$watch('vm.filtering', onFilteringChange, true);
            vm.unregisterPlacementWatcher = $scope.$watch('vm.placement', onPlacementChange);
            vm.unregisterViewTypeWatcher = $scope.$watch('vm.traderViewActive', onTraderViewActiveChange, true);
            $scope.$on('$destroy', onDestroy);

            // automatically select first placement.
            // timeout is needed to avoid errors
            $timeout(function() {
                if (campaign.placements.length > 0) {
                    vm.filtering.network = campaign.placements[0].network;
                    vm.filtering.country = campaign.placements[0].country;
                    vm.filtering.operatingSystem = vm.operatingSystemService.findOperatingSystemByValue(campaign.placements[0].operatingSystem);

                    var ad = campaign.placements[0].adGroups[0].ads[0];

                    if (campaign.isImported) {
                        vm.filtering.placement = {
                            id: campaign.placements[0].id,
                            title: campaign.placements[0].adGroups[0].networkName
                        };
                    } else if (ad.isDisplay) {
                        vm.filtering.placement = {
                            id: campaign.placements[0].id,
                            title: ad.imageName
                        };
                    } else {
                        vm.filtering.placement = {
                            id: campaign.placements[0].id,
                            title: ad.video.title
                        };
                    }

                    vm.placement = campaign.placements[0];
                    vm.interestsCloudData = getInterestsCloudData();
                }
            }, 100);

            updatePowerpointModalLocationsList();

            vm.showCpvInContentStats = shouldShowCpvInContentStats(campaign);
        }

        function drawBiddingVsEffectiveChart() {
            var ad = vm.placement.adGroups[0].ads[0];
            if (tvlAdVideoUtils.isCpmAdVideo(ad)) {
                vm.biddingVsEffectiveChartTitle = 'tCPM vs eCPM';
            } else if (tvlAdVideoUtils.isCpvAdVideo(ad)) {
                vm.biddingVsEffectiveChartTitle = 'bCPV vs eCPV';
            } else if (tvlAdVideoUtils.isCpcAdVideo(ad)) {
                vm.biddingVsEffectiveChartTitle = 'bCPC vs eCPC';
            } else if (tvlAdVideoUtils.isCpaAdVideo(ad)) {
                vm.biddingVsEffectiveChartTitle = 'bCPA vs eCPA';
            }

            vm.biddingVsEffectiveChart = {
                rangeSelector: {
                    selected: 4
                },

                yAxis: {
                    // labels: {
                    //     formatter: function () {
                    //         return (this.value > 0 ? ' + ' : '') + this.value + '%';
                    //     }
                    // },
                    plotLines: [{
                        value: 0,
                        width: 2,
                        color: 'silver'
                    }]
                },

                plotOptions: {
                    series: {
                        // compare: 'value',
                        showInNavigator: true
                    },

                },

                tooltip: {
                    pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ' + campaign.displayCurrency + '<br/>',
                    valueDecimals: 3,
                    split: true
                },

                series: [
                    {
                        name: '',
                        data: []
                    },
                    {
                        name: '',
                        data: []
                    }
                ]
            };

            getAveragePlacementStats(vm.placement.id);
        }

        function getAveragePlacementStats(placementId) {
            return PlacementAverageStats.get({
                id: placementId,
                viewType: vm.viewType
            }).$promise
              .then(onGetAveragePlacementStatsSuccess)
              .finally(onGetAveragePlacementStatsFinally);

            function onGetAveragePlacementStatsSuccess(stats) {
                for (var key in stats) {
                    var myDate = Date.parse(stats[key]['date']);
                    if (!isNaN(myDate)) {
                        var ad = vm.placement.adGroups[0].ads[0];
                        if (tvlAdVideoUtils.isCpmAdVideo(ad)) {
                            vm.biddingVsEffectiveChart.series[0].name = 'Average CPM';
                            vm.biddingVsEffectiveChart.series[0].data.push(
                                [myDate, stats[key]['averageCpm']]
                            );

                            vm.biddingVsEffectiveChart.series[1].name = 'Target CPM';
                            vm.biddingVsEffectiveChart.series[1].data.push(
                                [myDate, stats[key]['biddingMaxCpm']]
                            );
                        } else if (tvlAdVideoUtils.isCpvAdVideo(ad)) {
                            vm.biddingVsEffectiveChart.series[0].name = 'Average CPV';
                            vm.biddingVsEffectiveChart.series[0].data.push(
                                [myDate, stats[key]['averageCpv']]
                            );

                            vm.biddingVsEffectiveChart.series[1].name = 'Bidding CPV';
                            vm.biddingVsEffectiveChart.series[1].data.push(
                                [myDate, stats[key]['biddingMaxCpv']]
                            );

                        } else if (tvlAdVideoUtils.isCpcAdVideo(ad)) {
                            vm.biddingVsEffectiveChart.series[0].name = 'Average CPC';
                            vm.biddingVsEffectiveChart.series[0].data.push(
                                [myDate, stats[key]['averageCpc']]
                            );

                            vm.biddingVsEffectiveChart.series[1].name = 'Bidding CPC';
                            vm.biddingVsEffectiveChart.series[1].data.push(
                                [myDate, stats[key]['biddingMaxCpc']]
                            );
                        } else if (tvlAdVideoUtils.isCpaAdVideo(ad)) {
                            vm.biddingVsEffectiveChart.series[0].name = 'Average CPA';
                            vm.biddingVsEffectiveChart.series[0].data.push(
                                [myDate, stats[key]['averageCpa']]
                            );

                            vm.biddingVsEffectiveChart.series[1].name = 'Bidding CPA';
                            vm.biddingVsEffectiveChart.series[1].data.push(
                                [myDate, stats[key]['biddingMaxCpa']]
                            );
                        }
                    }
                }

                Highcharts.stockChart('bidding-effective-chart-container', vm.biddingVsEffectiveChart);
            }

            function onGetAveragePlacementStatsFinally() {

            }
        }

        function refresh() {
            if (canDoRefresh()) {
                vm.ui.refreshingData = true;
                return Campaign.refreshJob({
                        id: vm.campaign.id
                    })
                    .$promise
                    .then(onRefreshSuccess)
                    .catch(onRefreshError)
                    .finally(onRefreshFinally);
            } else {
                toastr.warning('You have refreshed these stats too recently. Please, wait a few moments before refreshing again.');
            }

            function onRefreshSuccess() {
                vm.lastRefreshTime = moment();
                toastr.info('Your campaign is being updated! Please check back in a few seconds.');
            }

            function onRefreshError() {
                toastr.error('Our apologies! Looks like we have been unable to refresh your data. Please, try again in a few minutes.');
            }

            function onRefreshFinally() {
                vm.ui.refreshingData = false;
            }
        }

        /**
         * Return whether the user can refresh the campaign stats.
         *
         * Users must wait at least 60 seconds between each refresh.
         *
         * @return {Boolean}
         */
        function canDoRefresh() {
            return vm.lastRefreshTime === null ||
                moment().diff(vm.lastRefreshTime, 'seconds') > 60;
        }

        /**
         * Check if the authenticated user is allowed to refresh campaign data,
         * download a pptx report or switch to trader view.
         */
        function checkUserPermissions() {
            tvlSession.getSession()
                .then(function(user) {
                    vm.user = user;

                    vm.userCanRefreshData = vm.user.hasAccountPermission(
                        vm.campaign.customerAccount.id,
                        'Campaign.can_refresh_data'
                    );
                    vm.userCanSwitchToTraderView = vm.user.hasAccountPermission(
                        vm.campaign.customerAccount.id,
                        'Campaign.view_actual_values'
                    ) && vm.user.hasAccountPermission(
                        vm.campaign.customerAccount.id,
                        'Campaign.use_trader_view'
                    );
                });
        }

        function chooseCountry(country) {
            vm.filtering.country = country;
        }

        function chooseOperatingSystem(operatingSystem) {
            vm.filtering.operatingSystem = operatingSystem;
        }

        function chooseNetwork(network) {
            vm.filtering.network = network;
        }

        function choosePlacement(placement) {
            vm.filtering.placement = placement;
            updateShowCpaMetrics();
        }

        function clearFilters() {
            vm.filtering = {
                network: null,
                country: null,
                placement: null
            };
        }

        function generateMapData() {
            var countryCode = vm.placement.country.toLowerCase();
            vm.mapCharts.regionalPerformance.map = tvlUi.getHighmapMapForCountryCode(countryCode);
            vm.mapCharts.regionalPerformance.data = _.chain(vm.placement.regionalPerformance)
                .filter(function(stats) {
                    return stats.countryCode.toLowerCase() === countryCode;
                })
                .map(function(stats) {
                    if (countryCode == 'pr') {
                        return {
                            'hc-key': 'pr-3614',
                            value: stats.impressions
                        }
                    }
                    return {
                        'hc-key': stats.mapKey,
                        value: stats.impressions
                    }
                })
                .value();

            // this sucks, but this is how our map directive currently works
            $timeout(function() {
                $rootScope.$broadcast('loadViewsMap');
            }, 100);
        }

        function getAggregatedStats(entity) {
            return {
                budget: entity.budget,
                cost: entity.cost,
                averageCpc: entity.averageCpc,
                averageCpm: entity.averageCpm,
                averageCpv: entity.averageCpv,
                averageCpa: entity.averageCpa,
                clicks: entity.clicks,
                conversions: entity.conversions,
                ctr: entity.ctr,
                impressions: entity.impressions,
                views: entity.views,
                viewRate: entity.viewRate,
                uniqueCookies: entity.uniqueCookies,
                impressionFrequency: entity.impressionFrequency,
                genderDistribution: entity.genderDistribution,
                deviceDistribution: entity.deviceDistribution,
                quartileDistribution: entity.quartileDistribution,
                demographicDistribution: entity.demographicDistribution,
                activeViewImpressions: entity.activeViewImpressions,
                activeViewMeasurability: entity.activeViewMeasurability,
                activeViewMeasurableImpressions: entity.activeViewMeasurableImpressions,
                activeViewViewability: entity.activeViewViewability
            };
        }

        function getAvailableCountries(network) {
            return _.chain(vm.campaign.placements)
                .filter({
                    network: network
                })
                .map('country')
                .uniq() // likely not needed
                .map(function(c) {
                    return {
                        name: c
                    }; // ideally this includes the real name
                })
                .value();
        }

        function getAvailableNetworks() {
            return _.chain(vm.campaign.placements)
                .map('network')
                .uniq()
                .map(function(n) {
                    return vm.networks[n];
                })
                .value();
        }

        function getAvailableOperatingSystems() {
            return _.chain(vm.campaign.placements)
                .map('operatingSystem')
                .uniq()
                .map(function(n) {
                    return vm.operatingSystemService.findOperatingSystemByValue(n);
                })
                .value();
        }

        function getAvailablePlacements(filtering) {
            return _.chain(vm.campaign.placements)
                .filter({
                    network: filtering.network,
                    country: filtering.country,
                    operatingSystem: filtering.operatingSystem.value
                })
                .map(function(p) {
                    if (vm.campaign.isImported) {
                        return {
                            id: p.id,
                            title: p.adGroups[0].networkName,
                            icon: ''
                        };
                    }

                    var ad = p.adGroups[0].ads[0];
                    if (ad.isDisplay) {
                        return {
                            id: p.id,
                            title: ad.imageName,
                            icon: 'picture-o'
                        };
                    } else if (p.network === 'facebook' || p.network === 'instagram') {
                        return {
                            id: p.id,
                            title: ad.video.title,
                            icon: 'camera'
                        };
                    } else {
                        return {
                            id: p.id,
                            title: ad.video.title,
                            icon: 'video-camera'
                        };
                    }
                })
                .value();
        }

        function getBudgetConsumption() {
            var cost = getMetric('cost');
            var budget = getMetric('budget');

            if (budget === 0) {
                return 0;
            }

            return +(cost / budget * 100).toFixed(2);
        }

        function getCampaignStats() {
            vm.ui.updatingStats = true;
            var params = {
                id: vm.campaign.id,
                country: vm.filtering.country,
                network: vm.filtering.network,
                viewType: vm.viewType,
            };
            return Campaign.getStats(params)
                .$promise
                .then(onGetCampaignStatsSuccess)
                .finally(onGetCampaignStatsFinally);

            function onGetCampaignStatsSuccess(stats) {
                resetCharts();
                vm.entity = getAggregatedStats(stats);
            }

            function onGetCampaignStatsFinally() {
                vm.ui.updatingStats = false;
            }
        }

        function getCountryFlag(country) {
            return 'flag-icon-' + country.toLowerCase();
        }

        /**
         * @deprecated
         */
        function getIncrement(metric) {
            if (vm.placementStats.length === 0) {
                return 0;
            }

            var first = vm.placementStats[0][metric];
            var last = vm.placementStats[vm.placementStats.length - 1][metric];

            return (last - first) / first * 100;
        }

        function getInterestsCloudData() {
            var interestsCloudData = [];
            angular.forEach(vm.placement.interestsPerformance, function(interestPerformance) {
                interestsCloudData.push({
                    text: interestPerformance.interestName,
                    weight: interestPerformance.impressions,
                    color: vm.interestsCloudColors[Math.floor(Math.random() * vm.interestsCloudColors.length)]
                });
            });
            return interestsCloudData;
        }

        function getLocations() {
            return _.chain(vm.campaign.placements)
                .map('country')
                .uniq()
                .value();
        }

        function getContentStats() {
            function getContentThumbnails(contentStats) {
                var ids = [];
                for (var idx in contentStats) {
                    var url = contentStats[idx].url;
                    if (url) {
                        ids.push(tvlCampaignUtils.getChannelIdFromUrl('youtube', url));
                    }
                }

                return tvlYoutube.getChannelsById(ids);
            }

            function setContentThumbnails(sortedChannels) {
                var idx = 0;
                for (idx in vm.contentStats) {
                    var id = 0;
                    for (id in sortedChannels) {
                        // check channel names for associate thumbnails from YouTube API response
                        if (sortedChannels[id]['id'] === vm.contentStats[idx]['channelId']) {
                            vm.contentStats[idx]['thumbnail'] = sortedChannels[id]['snippet']['thumbnails']['default']['url'];
                        }
                    }
                }
            }

            if (vm.placement) {
                return ContentStat.get({id: vm.placement['id'], viewType: vm.viewType})
                    .$promise
                    .then(function(contentStats) {
                        // treat contentStats if they are > 50 before calling YouTube API
                        var param = [contentStats];
                        if (contentStats.length > 50) {
                            var pieces = Math.ceil(contentStats.length / 50);
                            for (var i = 0; i < pieces; i++) {
                                param.push(getContentThumbnails(contentStats.slice(i * 50, 49 + i * 50)));
                            }
                        } else {
                            param.push(getContentThumbnails(contentStats));
                        }
                        return $q.all(param)
                    }).then(function(data) {
                        vm.contentStats = data[0];
                        for (var i = 1; i < data.length; i ++) {
                            setContentThumbnails(data[i].data.items);
                        }
                    });

            }
        }

        /**
         * Gets given metric from placement, placement-stats or campaign entities depending on it's type.
         * @param string metric
         * @returns {*}
         */
        function getMetric(metric) {
            if (vm.placement) {
                if (['budget'].indexOf(metric) >= 0) {
                    return (vm.placement[metric]) ? vm.placement[metric] : 0;
                }
                if (vm.placementStats && vm.placementStats.length) {
                    var calculatedStats = {
                        cost: 0,
                        impressions: 0,
                        views: 0,
                        clicks: 0,
                        conversions: 0,
                        averageCpv: 0,
                        averageCpc: 0,
                        averageCpm: 0,
                        averageCpa: 0,
                        ctr: 0,
                        activeViewImpressions: 0,
                        activeViewMeasurability: 0,
                        activeViewMeasurableImpressions: 0,
                        activeViewViewability: 0,
                        totalFacebookLikes: 0,
                        totalFacebookShares: 0,
                        totalFacebookComments: 0,
                        totalFacebookPostEngagement: 0
                    };

                    vm.placementStats.forEach(function (currentValue) {
                        calculatedStats.cost += currentValue['dailyCost'];
                        calculatedStats.impressions += currentValue['dailyImpressions'];
                        calculatedStats.views += currentValue['dailyViews'];
                        calculatedStats.clicks += currentValue['dailyClicks'];
                        calculatedStats.conversions += currentValue['dailyConversions'];
                        calculatedStats.activeViewImpressions += currentValue['dailyActiveViewImpressions'];
                        calculatedStats.activeViewMeasurableImpressions +=
                            currentValue['dailyActiveViewMeasurableImpressions'];
                        calculatedStats.totalFacebookLikes += currentValue['dailyLikes'];
                        calculatedStats.totalFacebookShares += currentValue['dailyShares'];
                        calculatedStats.totalFacebookComments += currentValue['dailyComments'];
                        calculatedStats.totalFacebookPostEngagement += currentValue['dailyEngagement'];
                    });

                    calculatedStats.averageCpv =
                        (calculatedStats.views) ? calculatedStats.cost / calculatedStats.views : 0;
                    calculatedStats.averageCpc =
                        (calculatedStats.clicks) ? calculatedStats.cost / calculatedStats.clicks : 0;
                    calculatedStats.averageCpm =
                        (calculatedStats.impressions) ? (calculatedStats.cost / calculatedStats.impressions) * 1000 : 0;
                    calculatedStats.averageCpa =
                        (calculatedStats.conversions) ? calculatedStats.cost / calculatedStats.conversions : 0;
                    calculatedStats.ctr =
                        (calculatedStats.impressions) ? (calculatedStats.clicks / calculatedStats.impressions) * 100 : 0;
                    calculatedStats.activeViewMeasurability =
                        (calculatedStats.impressions) ? (calculatedStats.activeViewMeasurableImpressions / calculatedStats.impressions) * 100 : 0;
                    calculatedStats.activeViewViewability =
                        (calculatedStats.impressions) ? (calculatedStats.activeViewImpressions / calculatedStats.impressions) * 100 : 0;

                    return (calculatedStats[metric]) ? calculatedStats[metric] : 0;
                }

                return 0;
            }

            return (vm.entity[metric]) ? vm.entity[metric] : 0;
        }

        function getPlacementAndStats(placementId) {
            return getPlacement(placementId)
                .then(function(placement) {
                    return $q.all([placement, getPlacementStats(placementId)]);
                })
                .then(function(data) {
                    drawBiddingVsEffectiveChart(placementId);
                    return {
                        placement: data[0],
                        stats: data[1]
                    };
                })
        }

        function getPlacement(placementId) {
            return Placement.get({
                    id: placementId,
                    viewType: vm.viewType
                })
                .$promise
                .then(onGetPlacementSuccess);

            function onGetPlacementSuccess(placement) {
                vm.placement = placement;
                vm.entity = placement;
                updateShowCpaMetrics();
                return placement;
            }
        }

        function getPlacementStats(placementId) {
            vm.ui.updatingStats = true;
            var startDate = moment(vm.startDate).format('YYYY-MM-DD');
            var endDate = moment(vm.endDate).format('YYYY-MM-DD');
            return PlacementStat.get({
                    id: placementId,
                    viewType: vm.viewType,
                    startDate: startDate,
                    endDate: endDate
                })
                .$promise
                .then(onGetPlacementStatsSuccess)
                .finally(onGetPlacementStatsFinally);

            function onGetPlacementStatsSuccess(stats) {
                resetCharts();

                vm.placementStats = stats;
                vm.ui.analyticsDataAvailable = stats.length && stats[0].hasOwnProperty('dailyEarnedViews');

                // line charts

                for (var chart in vm.lineCharts) {
                    if (vm.chartInitFns.line.hasOwnProperty(chart)) {
                        vm.lineCharts[chart].data = vm.chartInitFns.line[chart]();
                    } else {
                        vm.lineCharts[chart].data = [_.chain(vm.placementStats).map(chart).value()];
                    }

                    vm.lineCharts[chart].labels = _.chain(vm.placementStats)
                        .map(function(s) {
                            return moment.utc(s.date).format('MMM DD, YYYY');
                        })
                        .value();

                    // We do not want empty values in reach chart
                    if (chart == "reach") {
                        for (data in _.first(vm.lineCharts[chart].data)) {
                            var element = _.first(vm.lineCharts[chart].data)[data];
                            if (element == null) {
                                _.first(vm.lineCharts[chart].data).splice(data, 1);
                                vm.lineCharts[chart].labels.splice(data, 1);
                            }
                        }
                    }
                }

                // bar charts
                var data = [];
                for (var key in vm.placement.quartileViews) {
                    data.push(vm.placement.quartileViews[key]);
                    vm.barCharts.quartileViews.labels.push(key);
                }
                vm.barCharts.quartileViews.data = [data];

                if (vm.placement.hasOwnProperty('contentsPerformance') && vm.placement.contentsPerformance) {
                    var contentsPerformance = vm.placement.contentsPerformance;
                    vm.contentAnalysisCharts.views.data = [
                        [contentsPerformance.dynamicList.views],
                        [contentsPerformance.staticList.views]
                    ];
                    vm.contentAnalysisCharts.impressions.data = [
                        [contentsPerformance.dynamicList.impressions],
                        [contentsPerformance.staticList.impressions],
                    ];
                    vm.contentAnalysisCharts.cpv.data = [
                        [contentsPerformance.dynamicList.cpv],
                        [contentsPerformance.staticList.cpv]
                    ];
                    vm.contentAnalysisCharts.ctr.data = [
                        [contentsPerformance.dynamicList.ctr],
                        [contentsPerformance.staticList.ctr]
                    ];
                    vm.contentAnalysisCharts.vtr.data = [
                        [contentsPerformance.dynamicList.viewRate],
                        [contentsPerformance.staticList.viewRate]
                    ];
                    vm.contentAnalysisCharts.reached.data = [
                        [contentsPerformance.dynamicList.reached],
                        [contentsPerformance.staticList.reached]
                    ];
                }

                ContentStatPerformance.get({id: vm.placement['id'], viewType: vm.viewType})
                    .$promise
                    .then(onContentStatsPerformanceSuccess);

                function onContentStatsPerformanceSuccess(contentStatsPerformance) {
                    var channelUsedLabels = [];
                    var channelUsedRate = [];

                    contentStatsPerformance.forEach(function(element) {
                        var formattedDate = moment.utc(element['date']).format('MMM DD, YYYY');
                        channelUsedLabels.push(formattedDate);
                        channelUsedRate.push(element['itemsRate'].toFixed(2));
                        element['date'] = formattedDate;
                    });

                    vm.contentStatsPerformance = contentStatsPerformance;
                    vm.lineCharts.channelsUsed.labels = channelUsedLabels;
                    vm.lineCharts.channelsUsed.data = [channelUsedRate];
                }

                // map
                if (!vm.campaign.isImported) {
                    generateMapData();
                }

                return stats;
            }

            function onGetPlacementStatsFinally() {
                vm.ui.updatingStats = false;
            }
        }

        function getVideo(videoId) {
            vm.ui.gettingVideo = true;
            return Video.get({
                    id: videoId
                })
                .$promise
                .then(onGetVideoSuccess)
                .finally(onGetVideoFinally);

            function onGetVideoSuccess(video) {
                video.embedHtml = $sce.trustAsHtml(video.embedHtml);
                vm.video = video;
                resetQuartileAndGenderCharts();
            }

            function onGetVideoFinally() {
                vm.ui.gettingVideo = false;
            }
        }

        function onDestroy() {
            vm.unregisterDatepickerWatcher();
            vm.unregisterFilteringWatcher();
            vm.unregisterPlacementWatcher();
            vm.unregisterViewTypeWatcher();
        }

        function onDateRangeChanged(newVal, oldVal) {
            if (newVal === oldVal) {
                return;
            }

            vm.startDate = vm.datepicker.startDate;
            vm.endDate = vm.datepicker.endDate;

            vm.showStats = true;
            if (vm.placement) {
                getPlacementStats(vm.placement.id);
                vm.showStats =
                    vm.startDate.format("YYYY-MM-DD") == vm.placement.startDate &&
                    (vm.endDate.format("YYYY-MM-DD") == moment().format("YYYY-MM-DD") ||
                        vm.endDate.format("YYYY-MM-DD") == vm.placement.endDate);
            }
        }

        function onFilteringChange(newVal, oldVal) {
            if (newVal === oldVal) {
                return;
            }

            vm.placementStats = null;
            vm.contentStats = null;
            vm.video = null;
            vm.image = null;

            if (newVal.placement && (!oldVal.placement || newVal.placement.id !== oldVal.placement.id)) {
                getPlacementAndStats(newVal.placement.id).then(function(data) {
                    var ad = data.placement.adGroups[0].ads[0];

                    if (tvlAdVideoUtils.isInstagramStoryAdVideoFormat(ad.format)
                        || tvlAdVideoUtils.isFacebookExternalAdVideoFormat(ad.format)
                        || tvlAdVideoUtils.isFacebookCarouselExternalAdVideoFormat(ad.format)
                    ) {
                        vm.video = ad.video;
                        vm.video.videoUrl = $sce.trustAsResourceUrl(vm.video.videoUrl);
                        vm.video.publishedAt = vm.video.publishedAt.date;
                    } else if (ad.isDisplay) {
                        vm.image = {
                            imageContent: ad.imageContent
                        };
                    } else if (!ad.isSearch) {
                        getVideo(ad.video.videoId);
                    }
                });
            } else if (newVal.country || newVal.network) {
                if (
                    (newVal.country && (!oldVal.country || newVal.country !== oldVal.country)) ||
                    (newVal.network && (!oldVal.network || newVal.network !== oldVal.network))
                ) {
                    vm.filtering.placement = null;
                    vm.placement = null;
                    vm.datepicker = getDefaultDatepicker();
                    getCampaignStats().then(function() {
                        resetQuartileAndGenderCharts();
                    });
                }
                resetQuartileAndGenderCharts();
            } else {
                vm.filtering.placement = null;
                vm.placement = null;
                vm.datepicker = getDefaultDatepicker();
                vm.entity = getAggregatedStats(vm.campaign);
                resetQuartileAndGenderCharts();
            }

            vm.availableFilters.operatingSystems = vm.getAvailableOperatingSystems();
            vm.availableFilters.countries = vm.getAvailableCountries(vm.filtering.network);
            vm.availableFilters.placements = vm.getAvailablePlacements(vm.filtering);
        }

        function onPlacementChange(newVal, oldVal) {
            if (newVal !== oldVal) {
                if (newVal !== null) {
                    vm.ui.showActiveViewStats = newVal.network === 'adwords' ? true : false;
                } else {
                    vm.ui.showActiveViewStats = tvlCampaignUtils.isCampaignPresentInAdwords(vm.campaign);
                }
            }
        }

        function onTraderViewActiveChange(newVal, oldVal) {
            if (newVal === oldVal) {
                return;
            }

            if (newVal) {
                vm.viewType = 'trader';
            } else {
                vm.viewType = 'customer';
            }


            if (vm.placement) {
                getPlacementAndStats(vm.placement.id);
            } else {
                getCampaignStats().then(function() {
                    resetQuartileAndGenderCharts();
                });
            }
        }

        function resetCharts() {
            vm.barCharts = {
                quartileViews: {
                    data: [],
                    labels: [],
                    series: ['Quartile Time View']
                },
                options: {
                    scales: {
                        yAxes: [{
                            gridLines: {
                                display: false
                            }
                        }],
                        xAxes: [{
                            gridLines: {
                                display: false
                            }
                        }]
                    }
                }
            };
            vm.lineCharts = {
                impressions: {
                    data: [],
                    labels: [],
                    series: ['Total impressions']
                },
                dailyImpressions: {
                    data: [],
                    labels: [],
                    series: ['Daily impressions']
                },
                quartiles: {
                    data: [],
                    labels: [],
                    series: ['First quartile', 'Midpoint', 'Third quartile', 'Complete']
                },
                viewRate: {
                    data: [],
                    labels: [],
                    series: ['View rate']
                },
                views: {
                    data: [],
                    labels: [],
                    series: ['Total views']
                },
                dailyViews: {
                    data: [],
                    labels: [],
                    series: ['Daily views']
                },
                completeViews: {
                    data: [],
                    labels: [],
                    series: ['100% views']
                },
                // tenAndThirtySecondViews: {
                //     data: [],
                //     labels: [],
                //     series: ['10-second views', '30-second views']
                // },
                reach: {
                    data: [],
                    labels: [],
                    series: ['Reach']
                },
                clicks: {
                    data: [],
                    labels: [],
                    series: ['Total clicks']
                },
                dailyClicks: {
                    data: [],
                    labels: [],
                    series: ['Daily clicks']
                },
                conversions: {
                    data: [],
                    labels: [],
                    series: ['Conversions']
                },
                dailyConversions: {
                    data: [],
                    labels: [],
                    series: ['Daily conversions']
                },
                ctr: {
                    data: [],
                    labels: [],
                    series: ['CTR']
                },
                cost: {
                    data: [],
                    labels: [],
                    series: ['Cost']
                },
                averageCpm: {
                    data: [],
                    labels: [],
                    series: ['Average CPM']
                },
                averageCpc: {
                    data: [],
                    labels: [],
                    series: ['Average CPC']
                },
                averageCpv: {
                    data: [],
                    labels: [],
                    series: ['Average CPV']
                },
                averageCpa: {
                    data: [],
                    labels: [],
                    series: ['Average CPA']
                },
                analyticsViews: {
                    data: [],
                    labels: [],
                    series: ['Earned views', 'Owned views']
                },
                analyticsEngagement: {
                    data: [],
                    labels: [],
                    series: ['Likes', 'Shares', 'Subscribers', 'Playlist additions']
                },
                uniqueCookies: {
                    data: [],
                    labels: [],
                    series: ['Unique cookies']
                },
                dailyUniqueCookies: {
                    data: [],
                    labels: [],
                    series: ['Unique cookies']
                },
                impressionFrequency: {
                    data: [],
                    labels: [],
                    series: ['Average impression frequency']
                },
                activeViewImpressions: {
                    data: [],
                    labels: [],
                    series: ['Active View Impressions']
                },
                activeViewMeasurability: {
                    data: [],
                    labels: [],
                    series: ['Active View Measurability']
                },
                activeViewMeasurableImpressions: {
                    data: [],
                    labels: [],
                    series: ['Active View Measurable Impressions']
                },
                activeViewViewability: {
                    data: [],
                    labels: [],
                    series: ['Active View Viewability']
                },
                channelsUsed: {
                    data: [],
                    labels: [],
                    series: ['Used channels']
                },
                colors: tvlUi.getThemeColors(),
                options: {
                    maintainAspectRatio: false,
                    responsive: true,
                    elements: {
                        line: {
                            tension: 0.1
                        }
                    },
                    scales: {
                        yAxes: [{
                            gridLines: {
                                display: false
                            }
                        }],
                        xAxes: [{
                            gridLines: {
                                display: false
                            }
                        }]
                    }
                }
            };
            vm.mapCharts = {
                regionalPerformance: {
                    map: 'custom/world',
                    data: []
                }
            };
            resetQuartileAndGenderCharts();
        }

        function resetQuartileAndGenderCharts() {
            vm.doughnutCharts = {
                genderDistribution: {
                    data: [],
                    labels: [],
                    legend: [],
                    colors: []
                },
                deviceDistribution: {
                    data: [],
                    labels: [],
                    legend: [],
                    colors: tvlUi.getThemeColors()
                },
                options: {
                    elements: {
                        arc: {
                            borderWidth: 1
                        }
                    },
                    maintainAspectRatio: false,
                    responsive: true,
                    animation: {
                        easing: 'easeOutBounce'
                    }
                }
            };

            // device
            vm.quartileDeviceChartData = {
                datasets: [],
                labels: ["00:00"],
                series: [],
                colors: [],
                options: {
                    legend: {
                        display: true,
                        position: 'top'
                    }
                }
            };

            getQuartileDeviceData(vm.entity.quartileDistribution);
            getDemographicData(vm.entity.demographicDistribution);
        }

        /**
         * Return the quartile views line chart data.
         *
         * @return {Array}
         */
        function getQuartilesLineChartData() {
            return [
                _.map(vm.placementStats, 'firstQuartileRate'),
                _.map(vm.placementStats, 'midpointRate'),
                _.map(vm.placementStats, 'thirdQuartileRate'),
                _.map(vm.placementStats, 'completionRate')
            ];
        }

        /**
         * Return the data for the 10- and 30-second views line chart.
         *
         * @return {Array}
         */
        function getTenAndThirtySecondViewsLineChartData() {
            return [
                _.map(vm.placementStats, 'tenSecondViews'),
                _.map(vm.placementStats, 'thirtySecondViews')
            ];
        }

        function getAnalyticsViewsLineChartData() {
            return [
                _.map(vm.placementStats, 'dailyEarnedViews'),
                _.map(vm.placementStats, 'dailyOwnedViews')
            ];
        }

        function getAnalyticsEngagementLineChartData() {
            return [
                _.map(vm.placementStats, 'likes'),
                _.map(vm.placementStats, 'shares'),
                _.map(vm.placementStats, 'subscribers'),
                _.map(vm.placementStats, 'playlistAdditions')
            ];
        }

        /**
         * Sets the quartile-device information given a quartile distribution.
         */
        function getQuartileDeviceData(quartileDistribution) {
            if (quartileDistribution) {
                // Get chart data
                var c = 0;
                angular.forEach(quartileDistribution, function(device, name) {
                    if (name !== 'global') {
                        vm.quartileDeviceChartData.series.push(_.upperFirst(name).replace('_', ' '));
                        vm.quartileDeviceChartData.datasets.push(_.concat([100], _.values(device)));
                        vm.quartileDeviceChartData.colors.push({
                            borderColor: tvlUi.getThemeColorForPosition(c),
                            pointBorderColor: tvlUi.getThemeColorForPosition(c),
                            pointBackgroundColor: tvlUi.getThemeColorForPosition(c),
                            backgroundColor: "rgba(0, 0, 0, 0)"
                        });
                        c += 1;
                    }
                });

                // Get axis' seconds
                if (vm.video != null && vm.video.duration) {
                    var totalSeconds = stringTimeToSeconds(vm.video.duration);
                    var quarterOfTotal = Math.round(totalSeconds / 4);
                    var current = 0;
                    for (var i = 0; i < 4; i++) {
                        current = current + quarterOfTotal <= totalSeconds ? current + quarterOfTotal : totalSeconds;
                        var currentString = secondsToString(current);
                        vm.quartileDeviceChartData.labels.push(currentString);
                    }
                } else {
                    vm.quartileDeviceChartData.labels = ["0%", "25%", "50%", "75%", "100%"];
                }
            }
        }

        /**
         * Converts a mm:ss format time into seconds.
         *
         * @param {String} time
         *
         * @return {Number}
         */
        function stringTimeToSeconds(time) {
            var time = time.split(':');
            var seconds = (+time[0]) * 60 + (+time[1]);
            return seconds;
        }

        /**
         * Converts the provided seconds into a mm:ss format time.
         *
         * @param {Number} seconds
         *
         * @return {String}
         */
        function secondsToString(seconds) {
            var m = Math.floor(seconds % 3600 / 60);
            var s = Math.floor(seconds % 3600 % 60);
            return ((m < 10 ? "0" : "") + m + ":" + (s < 10 ? "0" : "") + s);
        }

        function getDefaultDatepicker() {
            return {
                startDate: moment(campaign.startDate),
                endDate: moment() < moment(campaign.endDate) ? moment() : moment(campaign.endDate)
            }
        }

        /**
         * Sets the demographic information given a demographic distribution.
         */
        function getDemographicData(demographicDistribution) {
            // Generate gender distribution data
            var genderColors = {
                male: '#239DDD',
                female: '#DF068C',
                undetermined: '#000000'
            };
            for (var key in vm.entity.genderDistribution) {
                vm.doughnutCharts.genderDistribution.data.push(vm.entity.genderDistribution[key]['percent']);
                vm.doughnutCharts.genderDistribution.colors.push(genderColors[key]);
                vm.doughnutCharts.genderDistribution.labels.push(key);
                vm.doughnutCharts.genderDistribution.legend.push({
                    key: key,
                    value: vm.entity.genderDistribution[key]['percent']
                });
            }
            for (var key in vm.entity.deviceDistribution) {
                vm.doughnutCharts.deviceDistribution.data.push(vm.entity.deviceDistribution[key]);
                vm.doughnutCharts.deviceDistribution.labels.push(key);
                vm.doughnutCharts.deviceDistribution.legend.push({
                    key: key,
                    value: vm.entity.deviceDistribution[key]
                });
            }

            var totalImpressions = demographicDistribution ? demographicDistribution['Total']['impressions'] : 0;

            if (totalImpressions > 0) {
                angular.forEach(vm.demographicData, function(value, key) {
                    if (demographicDistribution[key] != undefined) {
                        vm.demographicData[key]['impressions'] = demographicDistribution[key].impressions / totalImpressions * 100;
                    } else {
                        vm.demographicData[key]['impressions'] = 0;
                    }
                });
            }
        }

        /**
         * Returns whether there is gender distribution information for a given gender.
         */
        function thereIsGenderDistribution(gender) {
            return vm.entity.genderDistribution != null &&
                (vm.entity.genderDistribution[gender]['percent'] > 0 ||
                    (vm.entity.genderDistribution['male']['percent'] == 0) && (vm.entity.genderDistribution['female']['percent'] == 0));
        }

        /**
         * Collects locations from campaign-rules
         *
         * Todo: Get cities from parent-locations and pass the cities-array to the modal
         */
        function updatePowerpointModalLocationsList() {

            if (vm.campaign.rules && vm.campaign.rules.length !== 0) {
                _.forEach(vm.campaign.rules, function(rule) {
                    if (rule.locations.length === 0) {
                        // All campaign locations
                        _.forEach(vm.campaign.locations, function(item) {
                            vm.powerpointReportModalCities.push(item);
                        });
                    } else {
                        // Rule locations
                        _.forEach(rule.locations, function(item) {
                            vm.powerpointReportModalCities.push(item);
                        });
                    }
                });

                vm.powerpointReportModalCities = _.uniqBy(vm.powerpointReportModalCities, 'id');

                if (vm.powerpointReportModalCities.length > vm.MIN_CITIES_FOR_POWERPOINT_REPORTS_MODAL) {
                    vm.isPowerpointSettingsModalRequired = true;
                }
            }
        }

        function openPowerpointSettingsModal() {
            var modal = $uibModal.open({
                templateUrl: 'campaign/powerpoint-settings-modal.html',
                controller: 'PowerpointSettingsModalController as vm',
                bindToController: true,
                resolve: {
                    campaign: vm.campaign
                }
            });
        }

        /**
         * Return whether CPV data should be displayed in the content analysis
         * section.
         *
         * @return {Boolean}
         */
        function shouldShowCpvInContentStats(campaign) {
            return campaign.youtubeFixedCpv === null &&
                campaign.facebookFixedCpv === null &&
                campaign.instagramFixedCpv === null;
        }

        /**
         * Open warning modal with download link for any format.
         *
         * @param format
         */
        function openDownloadTraderReportModal(format) {
            var promise = tvlSweetAlert.confirm('Trader report', 'You will download campaign report with trader information.', {
                showCancelButton: true,
                confirmButtonText: 'Download',
                cancelButtonText: 'Cancel',
                type: 'warning'
            });

            promise.then(function(){
                vm.reportDownload(format);
            });
        }

        /**
         * Generates report download link.
         *
         * @param format
         * @returns {string}
         */
        function reportDownload(format) {

            var isAllowedUrl = vm.CAMPAIGN_IS_REPORT_ALLOWED_URL.replace('_CAMPAIGNID_', vm.campaign.id);
            reportDownloader.isDownloadAllowed(isAllowedUrl).then(function(response) {
                if (response.data.allowed) {
                    performDownload();
                    return;
                }
                var message = 'We are not able to generate your report right now, we are processing statistics at this moment. Please, try again later...';
                tvlSweetAlert.prompt('Processing statistics!', message, {
                    showCancelButton: false,
                    confirmButtonText: 'Ok',
                    type: 'warning'
                });
            });

            function performDownload() {
                vm.ui.downloading = true;
                let startDate = moment(vm.startDate).format('YYYY-MM-DD');
                let endDate = moment(vm.endDate).format('YYYY-MM-DD');

                var ad = vm.placement.adGroups[0].ads[0];
                let link = (ad.isVideo) ?
                    vm.CAMPAIGN_REPORT_URL :
                    vm.CAMPAIGN_NONVIDEO_REPORT_URL;


                link = link.replace('_CAMPAIGNID_', vm.campaign.id);
                link = link.replace('_FORMAT_', format);
                link = link.replace('_STARTDATE_', startDate);
                link = link.replace('_ENDDATE_', endDate);
                link = link.replace('_VIEWTYPE_', vm.viewType);

                reportDownloader.download(link).then(onDownloadSuccess)
                    .catch(onDownloadError)
                    .finally(onDownloadFinally);

                function onDownloadSuccess() {
                    toastr.info('Your report has been created. Please, check your Downloads folder.');
                }

                function onDownloadError() {
                    toastr.error('Our apologies! Looks like we have been unable to create your report. ' +
                        'Please, try again in a few minutes or contact Technical Support');

                }

                function onDownloadFinally() {
                    vm.ui.downloading = false;
                }
            }
        }

        /**
         * Determines if CPA metrics must be shown or not.
         */
        function updateShowCpaMetrics() {
            vm.ui.showCpaMetrics = false;
            if (vm.placement && vm.placement.adGroups[0].ads[0]) {
                vm.ui.showCpaMetrics = vm.placement.adGroups[0].ads[0].isCpa;
            }
        }

        function showVideoChannelInfo(format) {

            return !isFacebookExternalAsset(format);
        }

        function isFacebookExternalAsset(format) {

            return vm.tvlAdVideoUtils.isFacebookExternalAdVideoFormat(format) ||
                vm.tvlAdVideoUtils.isFacebookCarouselExternalAdVideoFormat(format);
        }

    }
})();

