(function() {
    'use strict';

    angular
        .module('tvl.common')
        .directive('tvlPlatformIcon', tvlPlatformIcon);

    tvlPlatformIcon.$inject = [];

    /* @ngInject */
    function tvlPlatformIcon() {
        var directive = {
            link: link,
            restrict: 'E',
            template: '<i class="fa {{ iconClass }} {{ colorClass }}"></i>',
            scope: {
                channel: '='
            }
        };
        return directive;

        function link(scope, element, attrs) {
            var unregisterWatcher = scope.$watch(
                'channel',
                function (newVal, oldVal) {
                    if (scope.channel) {
                        switch (scope.channel.platform) {
                            case 'youtube':
                                scope.iconClass = 'fa-youtube-play';
                                scope.colorClass = 'tvl-text-yt';
                                break;
                            case 'facebook':
                                scope.iconClass = 'fa-facebook-official';
                                scope.colorClass = 'tvl-text-fb';
                                break;
                            default:
                                // generic icon for unknown platforms
                                scope.icon = 'television';
                                scope.colorClass = null;
                        }
                    }
                    unregisterWatcher();
                }
            );
        }
    }
})();
