(function(){
    "use strict";

    angular
        .module("tvl.control-center")
        .directive("tvlControlCenterIssues", tvlControlCenterIssues);

    tvlControlCenterIssues.$inject = ['tvlControlCenterService', 'toastr'];

    /* @ngInject */
    function tvlControlCenterIssues(tvlControlCenterService, toastr) {

        function link(scope, element, attrs) {
            scope.issues = [];
            scope.loadingIssues = true;
            getIssues();

            /**
             * Load issues by company.
             *
             * TODO change this to filter by accounts array when supported.
             */
            function getIssues() {
                scope.loadingIssues = true;
                tvlControlCenterService.getIssues(onGetIssuesSuccess, onGetIssuesError);

                function onGetIssuesSuccess(responseContent) {
                    scope.issues = responseContent.data;
                    scope.loadingIssues = false;
                }

                function onGetIssuesError(responseContent) {
                    toastr.error('Can not fetch any issues, please try again later.');
                    scope.loadingIssues = false;
                }
            }

            scope.resolveIssue = function(issue) {
                tvlControlCenterService.resolveIssue(
                    issue,
                    onResolveIssueSuccess,
                    onResolveIssueError,
                    preCallback
                );

                function onResolveIssueSuccess(responseContent) {
                    toastr.success('Issue '+issue.supportName+' marked as resolved.');
                    getIssues();
                }

                function onResolveIssueError(responseContent) {
                    toastr.error('Issue '+issue.supportName+' could not be resolved, please try again later.');
                    scope.loadingIssues = false;
                }

                function preCallback() {
                    scope.loadingIssues = true;
                }
            };

            scope.formatFullDate = tvlControlCenterService.formatFullDate;
        }

        return {
            restrict: 'E',
            link: link,
            scope: {
            },
            templateUrl: 'control-center/control-center-issues.html',
        };
    }
})();
