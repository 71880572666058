(function() {
    'use strict';

    angular
        .module('tvl.group')
        .controller('GroupCreateModalController', GroupCreateModalController);

    GroupCreateModalController.$inject = [
        '$rootScope',
        '$scope',
        '$uibModal',
        'Group',
        'Campaign',
        'resourceResponse',
        'toastr',
        '$uibModalInstance',
        '$state'
    ];

    /* @ngInject */
    function GroupCreateModalController(
        $rootScope,
        $scope,
        $uibModal,
        Group,
        Campaign,
        resourceResponse,
        toastr,
        $uibModalInstance,
        $state
    ) {
        $scope.group = {
            campaigns: []
        }
        $scope.creating = false;
        $scope.availableCampaigns = [];
        $scope.cancel = cancel;
        $scope.create = create;
        $scope.refreshCampaignsList = refreshCampaignsList;
        $scope.isLoading = false;

        function create() {
            var groupData = {
                campaigns: $scope.group.campaigns.map(function(item){return item.id})
            }

            var group = new Group(groupData);

            return group.$save()
                .then(onSaveSuccess)
                .catch(resourceResponse.error);

            function onSaveSuccess(response) {
                toastr.info('Your group was created!');
                $state.go('group.show', {
                    id: response.data.id
                });
                $uibModalInstance.close(response.data);
            }
        }

        function cancel() {
            $uibModalInstance.dismiss();
        }

        function refreshCampaignsList(search) {
            $scope.isLoading = true;
            Campaign.get({searchTerm: search}).$promise
                .then(onGetSuccess, resourceResponse.error)
                .finally(function() {
                    $scope.isLoading = false;
                });

            function onGetSuccess(campaigns) {
                $scope.availableCampaigns = campaigns.items;
            }
        }
    }
}());
