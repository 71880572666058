(function() {
    'use strict';

    angular
        .module('tvl.brandSafety')
        .service('tvlCreateChannelModal', tvlCreateChannelModal);

    tvlCreateChannelModal.$inject = ['$uibModal'];

    /**
     * A service to interact with the channel creation modal.
     *
     * @ngInject
     */
    function tvlCreateChannelModal($uibModal) {
        return {
            openCreationModal: openCreationModal
        };

        /**
         * Open the channel creation modal.
         *
         * This function returns a promise that will be resolved with the channel to be created
         *
         * @return {Promise}
         */
        function openCreationModal(channels) {
            var modal = $uibModal.open({
                templateUrl: 'brand-safety/create-channel-modal.html',
                controller: 'CreateChannelModalController as vm',
                bindToController: true,
                backdrop: 'static',
                resolve: {
                    channels: function () {
                        return channels;
                    }
                },
            });

            return modal.result;
        }
    }
}());
