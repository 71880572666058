(function() {
    'use strict';

    angular
        .module('tvl.common')
        .directive('tvlNetworkNameInput', tvlNetworkNameInput);

    tvlNetworkNameInput.$inject = [];

    /* @ngInject */
    function tvlNetworkNameInput() {
        return {
            require: 'ngModel',
            link: link
        };

        function link(scope, element, attrs, ngModelCtrl) {
            ngModelCtrl.$validators.networkName = function (value) {
                return /^([a-zA-Z0-9 \-\_\.\,\~\&]*)$/.test(value);
            };
        }
    }
})();
