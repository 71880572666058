(function() {
    angular
        .module('tvl.common')
        .service('tvlForm', tvlForm);

    tvlForm.$inject = ['toastr'];

    function tvlForm(toastr) {
        return {
            validate: validate,
            validateEmail: validateEmail
        };

        /**
         * Validate all field from a given form
         *
         * @param  {form} form
         * @return {boolean}
         */
        function validate(form) {
            var field = null, firstError = null;
            for (field in form) {
                if (field[0] != '$') {
                    if (firstError === null && form[field].$invalid) {
                        firstError = form[field].$name;
                    }

                    if (form[field].$pristine) {
                        form[field].$dirty = true;
                    }
                }
            }

            if (firstError) {
                angular.element('.ng-invalid[name=' + firstError + ']').focus();
                errorMessage();
                return false;
            }

            return true;
        }

        function errorMessage() {
            toastr.error('Some of the entered values are invalid. Please correct them before proceeding');
        }

        /**
         * It returns true if given string is a valid email, otherwise this method returns false
         *
         * @param  {string} email
         * @return {boolean}
         */
        function validateEmail(email) {
            var result = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return result.test(email);
        }

    }
})();
