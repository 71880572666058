(function() {
    'use strict';

    angular
        .module('tvl.common')
        .filter('tvlCapitalize', tvlCapitalize);

    function tvlCapitalize() {
        return filter;

        function filter(input) {
            if (input) {
                return input.charAt(0).toUpperCase() + input.slice(1);
            }
        }
    }
})();
