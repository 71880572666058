(function() {
    'use strict';

    angular
        .module('tvl.administration')
        .directive('companiesForm', CompaniesForm)
        .constant('companyStatuses', ['enabled', 'disabled']);

    CompaniesForm.$inject = [
        '$state',
        '$uibModal',
        '$window',
        'toastr',
        'Upload',
        'Account',
        'Company',
        'tvlSession',
        'resourceResponse',
        'companyStatuses',
        'NetworkAccounts'
    ];

    /** @ngInject */
    function CompaniesForm(
        $state,
        $uibModal,
        $window,
        toastr,
        Upload,
        Account,
        Company,
        tvlSession,
        resourceResponse,
        companyStatuses,
        NetworkAccounts
    ) {
        return {
            restrict: "E",
            scope: {
                company: '=',
                onSaveSuccess: '&',
                onSaveError: '&',
                onSaving: '&',
                onFormValid: '&',
                onFormInvalid: '&'
            },
            templateUrl: "companies/companies-form.html",
            link: function ($scope, element, attrs) {
                var company = $scope.company;

                $scope.loggedUser = null;

                tvlSession.getSession().then(function(user) {
                    $scope.loggedUser = user;
                    init();
                });

                $scope.companyModel = {
                    id: _.get(company, 'id', null),
                    status: _.get(company, 'status', 'disabled'),
                    name: _.get(company, 'name', ''),
                    description: _.get(company, 'description', ''),
                    website: _.get(company, 'website', ''),
                    networkAccounts: _.get(company, 'networkAccounts', []),
                    businessInfo: {
                        legalName: _.get(company, 'businessInfo.legalName', ''),
                        address: _.get(company, 'businessInfo.address', ''),
                        taxInfo: _.get(company, 'businessInfo.taxInfo', '')
                    },
                    thumbnailNegative: _.get(company, 'customization.logos.platform.negative', null),
                    thumbnail: _.get(company, 'customization.logos.platform.positive', null),
                };

                $scope.showNegativeThumbnail = false;
                $scope.textNegativeThumbnail = 'Show black background thumbnail';
                $scope.statuses = companyStatuses;
                $scope.saveCompany = saveCompany;
                $scope.getStatuses = getStatuses;
                $scope.networkAccounts = null;
                $scope.filterNetworkAccounts = filterNetworkAccounts;
                $scope.onThumbnailNegativeChange = onThumbnailNegativeChange;
                $scope.onThumbnailChange = onThumbnailChange;

                function init() {
                    getNetworkAccounts();
                }

                function getStatuses(search) {
                    var newStatuses = $scope.statuses.slice();

                    if (search && newStatuses.indexOf(search) === -1) {
                        newStatuses.unshift(search);
                    }
                    return newStatuses;
                }

                function getNetworkAccounts() {
                    const params = {
                        page: 1,
                        itemsPerPage: 1000
                    };

                    return NetworkAccounts.getNetworkAccounts(params).$promise
                        .then(onGetNetworkAccountsSuccess, resourceResponse.error);

                    function onGetNetworkAccountsSuccess(data) {
                        $scope.networkAccounts = data.items;
                    }
                }

                function filterNetworkAccounts(search) {
                    if ($scope.networkAccounts !== null) {
                        var newNetworkAccounts = $scope.networkAccounts.slice();

                        if (search !== '') {
                            newNetworkAccounts = newNetworkAccounts.filter(function(network) {
                                return network.name.toLowerCase().indexOf(search.toLowerCase()) !== -1
                            });
                        }

                        return clearAlreadySelectedNetworkAccounts(newNetworkAccounts);
                    }
                }

                function clearAlreadySelectedNetworkAccounts(networkAccounts) {
                    var availableNetworkAccounts = [];

                    networkAccounts.forEach(function(item, index) {
                        var foundItem = _.find(
                            $scope.companyModel.networkAccounts, {
                                network: item.network,
                                accountId: item.accountId
                            }
                        );

                        var alreadySelected = 'undefined' !== typeof foundItem;
                        if (false === alreadySelected) {
                            availableNetworkAccounts.push(item);
                        }
                    });

                    return availableNetworkAccounts;
                }

                function onThumbnailChange($files, $file) {
                    if ($file === null) {
                        $scope.companyModel.thumbnail = null;
                    } else {
                        Upload.base64DataUrl($files).then(onUploadFileSuccess);
                    }

                    function onUploadFileSuccess(urls) {
                        $scope.companyModel.thumbnail = urls[0];
                    }
                }

                function onThumbnailNegativeChange($files, $file) {
                    if ($file === null) {
                        $scope.companyModel.thumbnailNegative = null;
                    } else {
                        Upload.base64DataUrl($files).then(onUploadFileSuccess);
                    }

                    function onUploadFileSuccess(urls) {
                        $scope.companyModel.thumbnailNegative = urls[0];
                    }
                }

                $scope.$on("CompaniesForm.Save", function(evt, data){
                    $scope.saveCompany();
                });

                function saveCompany() {
                    $scope.onSaving();

                    var data = {
                        id: $scope.companyModel.id,
                        status: $scope.companyModel.status,
                        name: $scope.companyModel.name,
                        description: $scope.companyModel.description,
                        website: $scope.companyModel.website,
                        networkAccounts: $scope.companyModel.networkAccounts,
                        businessInfo: {
                            legalName: _.get($scope.companyModel, 'businessInfo.legalName', ''),
                            address: _.get($scope.companyModel, 'businessInfo.address', ''),
                            taxInfo: _.get($scope.companyModel, 'businessInfo.taxInfo', '')
                        },
                        customization: {
                            logos: {
                                platform: {
                                    positive: _.get($scope.companyModel, 'thumbnail', ''),
                                    negative: _.get($scope.companyModel, 'thumbnailNegative', ''),
                                }
                            }
                        }
                    };

                    var company = new Company(data);

                    return company.$save()
                        .then(onSaveSuccess, onSaveError);

                    function onSaveSuccess(company) {
                        toastr.success('Company ' + company.name + ' has been successfully saved.');
                        $scope.onSaveSuccess({company: company});
                    }

                    function onSaveError(error) {
                        resourceResponse.error(error);
                        $scope.onSaveError({error: error});
                    }
                }

                $scope.$watchGroup(['companyForm.$dirty', 'companyForm.$valid'], function () {
                    if ($scope.companyForm.$valid && $scope.companyForm.$dirty) {
                        $scope.onFormValid();
                    } else {
                        $scope.onFormInvalid();
                    }
                }, true);
            }
        };
    }
}());
