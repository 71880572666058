(function() {
    'use strict';

    angular
        .module('tvl.common')
        .directive('selectorRequiredDependant', function() {
        return {
            restrict: 'A',
            require: 'ngModel',
            replace: false,
            link: function(scope, elm, attrs, ctrl) {
                attrs.$observe('selectorRequiredDependant', function(newValue){
                    scope.selectorRequiredDependant = newValue;
                });
                ctrl.$validators.selectorRequiredDependant = function(modelValue, viewValue) {
                    return (false === ctrl.$isEmpty(modelValue) && false === attrs.selectorRequiredDependant) ||
                        (ctrl.$isEmpty(modelValue) && attrs.selectorRequiredDependant);
                };
            }
        };
    });
}());
