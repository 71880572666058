(function() {
    'use strict';

    angular
        .module('tvl.common')
        .factory('tvlSweetAlert', tvlSweetAlert);

    tvlSweetAlert.$inject = ['$q', 'SweetAlert'];

    /**
     * An abstraction over the SweetAlert service that uses promises instead
     * of callbacks, because it's 2017 and callbacks ain't cool no mo'.
     *
     * Most SweetAlert options are set to sensible defaults, but all functions
     * exposed by this service can receive an object with custom options.
     * Options passed in this object will overwrite any defaults.
     *
     * All methods return a promise that will be resolved if the user accepts
     * the dialog, or rejected if he cancels it. In case of prompts, the
     * promise will be resolved with the value entered by the user.
     *
     * @todo Letting the user set defaults with a provider would be neat.
     * @ngInject
     */
    function tvlSweetAlert($q, SweetAlert) {
        var service = {
            confirm: confirm,
            error: error,
            prompt: prompt,
            success: success
        };
        return service;

        ////////////////

        /**
         * Show a confirmation dialog with the given title
         * and text.
         *
         * @param {string} title
         * @param {string} text
         * @param {Object} options
         * @return {Promise}
         */
        function confirm(title, text, options) {
            var defaultOpts = {
                title: title,
                text: text,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
            };
            return dialog(title, text, angular.extend(defaultOpts, options));
        }

        /**
         * Show an error dialog with the given title and text.
         *
         * @param {string} title
         * @param {string} text
         * @param {Object} options
         * @return {Promise}
         */
        function error(title, text, options) {
            options = options || {};
            return dialog(title, text, angular.extend(options, {type: 'error'}));
        }

        /**
         * Ask the user for an arbitrary input.
         *
         * An optional validation function might be provided, in which case the
         * promise returned by this method will only be resolved if the
         * function indicates that the input is valid.
         *
         * @param {string} title
         * @param {string} text
         * @param {Object} options
         * @param {Function} validationFn
         * @return {Promise}
         */
        function prompt(title, text, options, validationFn) {
            var deferred = $q.defer();

            var defaultOpts = {
                title: title,
                text: text,
                type: 'input'
            };
            SweetAlert.swal(angular.extend(defaultOpts, options), onFormSubmitted);

            return deferred.promise;

            function onFormSubmitted(value) {
                if (value === false) {
                    deferred.reject();
                } else {
                    try {
                        if (validationFn) {
                            validationFn(value);
                        }
                        deferred.resolve(value);
                    } catch (e) {
                        SweetAlert.showInputError(e);
                    }
                }
            }
        }

        /**
         * Show a success dialog with the given title and text.
         *
         * @param {string} title
         * @param {string} text
         * @param {Object} options
         * @return {Promise}
         */
        function success(title, text, options) {
            options = options || {};
            return dialog(title, text, angular.extend(options, {type: 'success'}));
        }

        /**
         * Show a dialog with the given title and text.
         *
         * @param {string} title
         * @param {string} text
         * @param {Object} options
         * @return {Promise}
         */
        function dialog(title, text, options) {
            var deferred = $q.defer();

            var defaultOpts = {
                title: title,
                text: text
            };
            SweetAlert.swal(angular.extend(defaultOpts, options), onDialogConfirmed);

            return deferred.promise;

            function onDialogConfirmed(isConfirm) {
                if (isConfirm) {
                    deferred.resolve();
                } else {
                    deferred.reject('dismissed');
                }
            }
        }
    }
})();
